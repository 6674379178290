import React, { Component } from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row,
    Form, FormGroup, Input, Button, Label,
    Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faPen, faSort } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from 'material-ui'

import arrayMove from 'array-move';
import { cloneDeep } from 'lodash';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Loader from '../Components/Loader'
import { copyToClipboard } from '../utils'
import { toast } from 'react-toastify'


const SortableItem = SortableElement(({ itemIndex, each, deleteModule, editModuleDetails, editBtn, isSortingEnabled, copiedModuleIds, addModuleIdToCopiedList }) => (
    <tr key={each._id}>
        <td>
            <Checkbox
                checked={copiedModuleIds.includes(each._id)}
                onCheck={(e, value) => addModuleIdToCopiedList(each._id, value)}
            />
        </td>
        <td>{itemIndex + 1}</td>
        <td>
            {each.moduleTitle}
            < br />
            <span style={{ fontSize: 14 }}>{each.moduleDescription}</span>
        </td>
        <td>{each.content && each.content.moduleType}</td>
        <td>
            {!isSortingEnabled ?
                <>
                    <Button
                        color="primary"
                        outline
                        onClick={() => editBtn(each.moduleId)}
                        style={{ marginRight: 10 }}
                    >Edit Content</Button>

                    <Button
                        onClick={() => {
                            copyToClipboard(each.moduleId)
                            toast("ID copied", {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true
                            })
                        }}
                        outline
                    >
                        <FontAwesomeIcon
                            className="iconButton"
                            style={{ color: 'grey' }}
                            icon={faCopy}
                        />
                    </Button>

                    <Button
                        outline
                        onClick={() => editModuleDetails(each)}
                        style={{ marginLeft: 10 }}
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </Button>

                    <Button
                        outline
                        color="danger"
                        onClick={() => {
                            if (window.confirm("Are you sure you want delete this module")) {
                                deleteModule([each._id])
                            }
                        }}
                        style={{ marginRight: 10, marginLeft: 10 }}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>

                </>
                : <FontAwesomeIcon
                    icon={faSort}
                    style={{ marginLeft: 10, color: 'grey' }}
                />}
        </td>
    </tr>
))

const SortableList = SortableContainer(({ items, editModuleDetails, deleteModule, editBtn, isSortingEnabled, copiedModuleIds, addModuleIdToCopiedList }) => (
    <tbody>
        {
            items.map((each, index) => (
                <SortableItem
                    disabled={!isSortingEnabled}
                    copiedModuleIds={copiedModuleIds}
                    addModuleIdToCopiedList={addModuleIdToCopiedList}
                    isSortingEnabled={isSortingEnabled}
                    each={each}
                    key={each._id}
                    editBtn={editBtn}
                    index={index}
                    itemIndex={index}
                    editModuleDetails={editModuleDetails}
                    deleteModule={deleteModule}
                />
            ))
        }
    </tbody>
))

class PathModules extends Component {

    state = {
        newModuleId: '',
        modulesList: null,
        isLoading: true,

        relatedPosts: [],
        copiedModuleIds: [],
        newModuleTitle: "",
        newModuleDescription: null,
        newModuleType: 'slide',
        newModulePosts: [],
        showAddNewModuleModal: false,
        selectedSubject: 'selectSubject',
        selectedSection: 'selectSection',
        isSortingEnabled: false
    }

    componentDidMount() {
        this.fetchAllModules()
    }

    fetchAllModules() {
        const { unitId, topicId } = this.props.match.params
        axios.get('/admin/learning-path/modules', { params: { unitId, topicId } })
            .then((res) => {
                this.setState({
                    modulesList: res.data.modules,
                    postId: res.data.postId,
                    editingModuleData: null,
                    isLoading: false
                })
            })
            .catch(err => {
                this.setState({ isLoading: false })
                console.log(err)
                alert("Error, please check console")
            })
    }

    addNewModule(newModuleId) {

        const { unitId, topicId } = this.props.match.params

        if (newModuleId) {
            let moduleIds

            if(newModuleId.length > 1) {

                const moduleIdsSplitted = newModuleId.split("\n")
                const allItems = moduleIdsSplitted.map(each => each.replace(/\s/g, ''))
                moduleIds = allItems.filter(each => each)
            } else {
                moduleIds = newModuleId
            }
            
            if (moduleIds.length > 0) {
                this.setState({ isLoading:true }, () => {
                    axios.put('/admin/learning-path/module', { unitId, topicId, moduleIds: moduleIds })
                    .then((res) => {
                        this.setState({ newModuleId: null, isLoading: false ,showAddModulesByIdsModal: false})
                        this.fetchAllModules()
                    })
                    .catch(err => {
                        this.setState({ isLoading: false })

                        if (err && err.response && err.response.status === 400) {
                            alert(err.response.data.message)
                        } else {
                            console.log(err)
                            alert("Error, check console")
                        }
                    })
                })
            }
        } else {
            alert("Please enter  valid module Ids")
        }
    }

    deleteModule(moduleIds) {
        if (moduleIds) {
            const { unitId, topicId } = this.props.match.params
            axios.delete('/admin/learning-path/module', { params: { unitId, topicId, moduleIds } })
                .then(res => {
                    this.setState({ isLoading: false, copiedModuleIds: [] })
                    this.fetchAllModules()
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error, check console")
                })
        }
    }

    createOrEditNewModule () {
        if (this.state.editingModuleData) {
            this.updateModuleDetails()
        } else {
           this.createNewModule ()
        }
    }

    createNewModule() {

        const { newModuleTitle, selectedSection, selectedSubject } = this.state

        if (!newModuleTitle) {
            alert("Please enter module title")
        } else if (!this.state.newModuleType) {
            alert('Please select module type')
        } else if (selectedSubject === 'selectSubject' || selectedSection === 'selectSection') {
            alert('Please select subject and section')
        } else {
            const bodyObject = {
                sectionId: selectedSection,
                moduleTitle: this.state.newModuleTitle,
                moduleDescription: this.state.newModuleDescription,
                moduleType: this.state.newModuleType,
                postsList: [ this.state.postId ],
                order: this.state.modulesList.length
            }

            axios.put('/learn/module', bodyObject)
                .then((res) => {
                    this.setState({
                        newModuleType: null,
                        newModuleTitle: null,
                        newModuleDescription: null,
                        newModulePosts: [],
                        showAddNewModuleModal: false
                    }, () => {
                        this.addNewModule([res.data.addedModuleId])
                    })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Check console")
                })
        }

    }

    updateModuleDetails() {

        const { newModuleTitle, selectedSection, selectedSubject, moduleId, newModuleDescription } = this.state

        if (!newModuleTitle) {
            alert("Please enter module title")
        } else if (selectedSubject === 'selectSubject' || selectedSection === 'selectSection') {
            alert('Please select subject and section')
        } else {

            const bodyObject = {
                moduleId,
                sectionId: selectedSection,
                moduleTitle: newModuleTitle,
                moduleDescription: newModuleDescription,
            }

            axios.post('/learn/module', bodyObject)
                .then(() =>
                    this.fetchAllModules(),
                    this.setState({
                        editingModuleData: false,
                        availableSections: null,
                        selectedSubject: null,
                        selectedSection: null,
                        newModuleType: null,
                        newModuleTitle: null,
                        newModuleDescription: null,
                        moduleId: null
                    })
                )
                .catch(err => {
                    console.log(err)
                    alert("Error, check console")
                })
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {

        const reorderedList = arrayMove(this.state.modulesList, oldIndex, newIndex)

        this.setState({ modulesList: reorderedList }, () => {

            const { unitId, topicId } = this.props.match.params
            
            let modulesWithUpdatedIndex = reorderedList.map((each, index) => ({ _id: each._id, orderNumber: index }))

            axios.post('/admin/learning-path/notes/reorder', { unitId, topicId, modulesWithUpdatedIndex })
                .catch(err => {
                    console.log(err)
                    alert("Error, check console")
                })
        })

    }

    setQueryParamsAndGoToLearnModule = (eachId) => {
        const { unitId, topicId } = this.props.match.params
        this.props.history.push({
            pathname: `/module/${eachId}`,
            search: `topicId=${topicId}&unitId=${unitId}`
        })
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    copyIdsToClipboard() {
        
        let string = ''

        this.state.copiedModuleIds.forEach(item => {
            if (string) {
                string = `${string}\n${item}`
            } else {
                string = item
            }
        })

        var textField = document.createElement('textarea')
        textField.innerHTML = string
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        toast("Copied to clipboard", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        })

    }

    copyAllModuleIds = (value) => {
        if (value){
            let moduleData = cloneDeep(this.state.modulesList)
            moduleData.forEach(each => {
                if (!this.state.copiedModuleIds.includes(each._id)) {
                    this.setState(oldState =>{
                        oldState.copiedModuleIds.push(each._id)
                        return { copiedModuleIds: oldState.copiedModuleIds }
                    })
                }
            })
        } else {
            this.setState({ copiedModuleIds: [] })
        }
    }

    addModuleIdToCopiedList = (moduleId, checkedValue) => {
        if (checkedValue) {
            this.setState(oldState => {
                oldState.copiedModuleIds.push(moduleId)
                return { copiedModuleIds: oldState.copiedModuleIds }
            })        
        } else {
            this.setState(oldState => {
                let filterIds = oldState.copiedModuleIds.filter(item => item !== moduleId)
                return { copiedModuleIds: filterIds }
            }) 
        }
    }

    findSubjectAndSection = (data) =>{
        if(this.state.sectionId) {
            let subjectsClone = cloneDeep(this.props.subjects)

            subjectsClone.forEach(subject=>{
                subject.sections.forEach(section=>{
                    if (section._id === this.state.sectionId) {
                        this.setState({
                             selectedSubject:subject._id, 
                             selectedSection: section._id ,
                             availableSections: subject.sections,
                             newModuleTitle: data.moduleTitle,
                             newModuleDescription: data.moduleDescription,
                             newModuleType:data.content.moduleType,

                            })
                    }
                })
            })
        }
    }

    render() {
        return (
            <>

                { this.state.isLoading && <Loader customStyle={{ background: 'red' }} /> }

                <Container>

                    <Modal isOpen={this.state.showAddNewModuleModal || this.state.editingModuleData }>

                        <ModalHeader>
                            {this.state.showAddNewModuleModal ? "Add New Module" : "Edit Module Details"}
                        </ModalHeader>

                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Input
                                        name="newModuleTitle"
                                        placeholder="Enter Module Title"
                                        value={this.state.newModuleTitle || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        name="newModuleDescription"
                                        placeholder="Enter Module Description (Optional)"
                                        value={this.state.newModuleDescription || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>

                                {
                                    this.state.showAddNewModuleModal ?
                                        <FormGroup>
                                            <Label for="exampleSelect">Select Module Type</Label>
                                            <Input
                                                value={this.state.newModuleType || ''}
                                                name="newModuleType"
                                                onChange={this.handleInputChange}
                                                type="select"
                                                id="exampleSelect"
                                            >
                                                <option value={null}></option>
                                                <option value="slide">Slide</option>
                                                <option value="quiz">Quiz</option>
                                                <option value="video">Video</option>
                                                <option value="cards">Cards</option>
                                                <option value="caNotes">CA Notes</option>
                                                <option value="pdfNote">PDF Note</option>
                                            </Input>
                                        </FormGroup>
                                        : null
                                }

                                {/* <FormGroup>
                                    <div>
                                        {
                                            Array.isArray(this.state.relatedPosts) && this.state.relatedPosts.map((each, index) => {
                                                return (
                                                    <CustomInput
                                                        id={each._id}
                                                        key={each._id}
                                                        value={each._id}
                                                        type="checkbox"
                                                        label={each.name}
                                                        onChange={(event) => {
                                                            this.setState((oldState) => {

                                                                let foundIndex = oldState.newModulePosts.indexOf(each._id)

                                                                if (foundIndex > -1) {
                                                                    oldState.newModulePosts.splice(foundIndex, 1);
                                                                    return { newModulePosts: oldState.newModulePosts }
                                                                } else {
                                                                    oldState.newModulePosts.push(each._id)
                                                                    return { newModulePosts: oldState.newModulePosts }
                                                                }

                                                            })

                                                        }}
                                                        checked={this.state.newModulePosts.includes(each._id)}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </FormGroup> */}

                                <hr />

                                <FormGroup>
                                    <Label for="selectedSubject">Select Subject</Label>
                                    <Input
                                        style={{ marginRight: 10 }}
                                        type="select"
                                        name="selectedSubject"
                                        id="selectedSubject"
                                        value={this.state.selectedSubject || ''}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const subject = this.props.subjects.find(each => each._id === value)
                                            this.setState({ selectedSubject: value, selectedSection:"selectSection", availableSections: subject ? subject.sections : [] })
                                        }}
                                    >
                                        {
                                            [{ _id: 'selectSubject', name: 'Select any subject' }, ...this.props.subjects].map((each, index) => (
                                                <option key={each._id} value={each._id}>
                                                    {each.name}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </FormGroup>

                                {
                                    (Array.isArray(this.state.availableSections) && this.state.availableSections.length > 0) &&
                                    <FormGroup>
                                        <Label for="selectedSection">Select Section</Label>
                                        <Input
                                            style={{ marginRight: 10 }}
                                            type="select"
                                            disabled={this.state.selectedSubject === 'selectSubject'}
                                            name="selectedSection"
                                            id="selectedSection"
                                            value={this.state.selectedSection || ''}
                                            onChange={(e) => {
                                                const value = e.target.value
                                                this.setState({ selectedSection: value })
                                            }}
                                        >
                                            {
                                                [ { _id: 'selectSection', name: "Select any section" }, ...this.state.availableSections ].map((each, index) => (
                                                    <option key={each._id} value={each._id}>
                                                        {each.name}
                                                    </option>
                                                ))
                                            }
                                        </Input>
                                    </FormGroup>
                                }

                                <Button color="primary" block onClick={() => this.createOrEditNewModule()}>
                                    { this.state.editingModuleData ? "Save" :"Create New Module" }
                                </Button>

                                <Button block onClick={() => this.setState({
                                    newModuleType: null,
                                    newModuleTitle: null,
                                    newModuleDescription: null,
                                    newModulePosts: [],
                                    selectedSubject: null,
                                    availableSections: null,
                                    selectedSection: null,
                                    showAddNewModuleModal: false,
                                    editingModuleData:false
                                })}>
                                    Cancel
                                </Button>

                            </Form>
                        </ModalBody>

                    </Modal>

                    <Row style={{ paddingTop: 20, marginBottom: 20 }}>
                        <Col md={4}>
                            <Modal
                                fade={true}
                                isOpen={this.state.showAddModulesByIdsModal}
                            >
                                <ModalHeader>
                                    Enter Module IDs to add
                                </ModalHeader>
                                <ModalBody>
                                    <Input
                                        placeholder="One Module Id per line please"
                                        type="textarea"
                                        rows='10'
                                        name="newModuleId"
                                        value={this.state.newModuleId || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        onClick={() => this.addNewModule(this.state.newModuleId)}
                                        color="primary">
                                            Add Modules
                                        </Button>
                                    <Button
                                        outline
                                        onClick={() => this.setState({ showAddModulesByIdsModal: false, newModuleId: '' })}
                                    >Close</Button>

                                </ModalFooter>
                            </Modal>

                            <Button color="primary" block onClick= { () => {this.setState({showAddModulesByIdsModal: true})}}> + Add Module</Button>
                            
                            {
                                (Array.isArray(this.state.copiedModuleIds) &&
                                    this.state.copiedModuleIds.length > 0) ?
                                    <>
                                        <Button
                                            color='primary'
                                            disabled={this.state.copiedModuleIds.length === 0}
                                            style={{ marginTop: 10 }}
                                            onClick={() => this.copyIdsToClipboard()}
                                        >
                                            Copy Ids
                                        </Button>

                                        <Button
                                            color='danger'
                                            disabled={this.state.copiedModuleIds.length === 0}
                                            style={{ marginTop: 10, marginLeft: 10 }}
                                            onClick={() => {
                                                if (window.confirm(`Are you sure you want to delete the selected ${this.state.copiedModuleIds.length} modules?`)) {
                                                    this.deleteModule(this.state.copiedModuleIds)
                                                }
                                            }}
                                        >
                                            Delete Modules
                                        </Button>
                                    </>
                                    : null
                            }
                            
                            {
                              Array.isArray(this.state.copiedModuleIds) && this.state.copiedModuleIds.length > 0 &&
                                <span style={{ marginLeft: 10 }}>{this.state.copiedModuleIds.length} Selected</span>
                            }

                        </Col>
                        <Col md={8}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color="primary"
                                    onClick={() => this.setState({ showAddNewModuleModal: true })}
                                >
                                    Create Module
                                </Button>
                                <Button
                                    style={{marginLeft:10}}
                                    outline
                                    color='success'
                                    onClick={() => this.setState({ isSortingEnabled: !this.state.isSortingEnabled })}
                                >{this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Table>
                        <thead>
                            <tr>
                                {
                                    Array.isArray(this.state.modulesList) && this.state.modulesList.length > 0 &&
                                    <th>
                                        <Checkbox
                                            onCheck={(e,value) => this.copyAllModuleIds(value)}
                                            checked={this.state.modulesList ? (this.state.copiedModuleIds.length === this.state.modulesList.length) : false}
                                        />
                                    </th>
                                }
                                <th>Index</th>
                                <th>Module Name</th>
                                <th>Module Type</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        {
                            Array.isArray(this.state.modulesList) && this.state.modulesList.length > 0 ?
                                <SortableList
                                    isSortingEnabled={this.state.isSortingEnabled}
                                    onSortEnd={this.onSortEnd}
                                    items={this.state.modulesList}
                                    copiedModuleIds={this.state.copiedModuleIds}
                                    addModuleIdToCopiedList={(moduleId, checkedValue) => this.addModuleIdToCopiedList(moduleId,checkedValue)}
                                    deleteModule={(moduleIdArray) => this.deleteModule(moduleIdArray)}
                                    editModuleDetails={(data) => {
                                        this.setState({
                                            sectionId: cloneDeep(data.sectionId),
                                            moduleId: data.moduleId,
                                            editingModuleData: true
                                        }, () => this.findSubjectAndSection(data))
                                    }}
                                    editBtn={(eachId, moduleName) => this.setQueryParamsAndGoToLearnModule(eachId, moduleName)}
                                />
                                :
                                Array.isArray(this.state.modulesList) && this.state.modulesList.length === 0 ?
                                    <tbody>
                                        <tr style={{ marginTop: 50 }}>
                                            <td>
                                                <p>No modules added</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr style={{ marginTop: 50 }}>
                                            <td>
                                                <p>Loading....</p>
                                            </td>
                                        </tr>
                                    </tbody>

                        }

                    </Table>


                </Container>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        subjects: state.data.subjects,
    }
}

export default connect(mapStateToProps)(PathModules)
