import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import {
    Container, Row, Table, Button,
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap'
import { Link } from 'react-router-dom';

import Loader from '../Components/Loader'

const FIELDS = {
    question: "question",
    description: "description",
    options: "options"
}

function WrongTypeQuestion() {

    const [questions, setQuestions] = useState(null)
    const [field, setField] = useState('question')

    const getQuestions = () => {
        setQuestions(null)
        Axios.get('/wrong-question-types', { params: { field } })
            .then(res => setQuestions(res.data.questions))
            .catch(err => {
                console.log(err)
                alert("Server error. Check console.")
            })
    }

    useEffect(() => {
        getQuestions()
    }, [field])

    console.log(questions)
    
    return (
        <Container fluid>
            
            <Row>
                <UncontrolledDropdown style={{ margin: 20 }}>
                    <DropdownToggle caret color="primary">Selected Field: {field}</DropdownToggle>
                    <DropdownMenu className="addScroll">
                        {
                            Object.keys(FIELDS).map((each) => {
                                return (
                                    <DropdownItem
                                        key={each}
                                        value={each}
                                        onClick={() => { setField(each) }}
                                    >
                                        {each}
                                    </DropdownItem>)
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Row>

            <Row>
                {
                    Array.isArray(questions) && questions.length > 0 ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    {
                                        field === FIELDS.options ?
                                            <>
                                                {
                                                    ["A", "B", "C", "D"].map(eachOption => (
                                                        <th>{`Option ${eachOption} Text & Value`}</th>
                                                    ))
                                                }
                                            </>
                                            : field === FIELDS.description ?
                                                <>
                                                    <th>Explanation</th>
                                                    <th>Type</th>
                                                </>
                                                :
                                                <>
                                                    <th>Question</th>
                                                    <th>Type</th>
                                                </>
                                    }
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    questions.map((question, index) => (
                                        <tr key={question._id}>
                                            <td>{index + 1}</td>
                                            {
                                                field === FIELDS.options ?
                                                    <>
                                                        {
                                                            question.options.map(option => (
                                                                <td>
                                                                    {option.text}<br />
                                                                    <span style={{ fontSize: 10, color: '#5e5e5e' }}>{option.type}</span>
                                                                </td>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    field === FIELDS.description ?
                                                        <>
                                                            <td>{question.description}</td>
                                                            <td>{question.explanationType}</td>
                                                        </>
                                                        :
                                                        <>
                                                            <td>{question.question}</td>
                                                            <td>{question.type}</td>
                                                        </>
                                            }
                                            <td>
                                                <Link
                                                    to={{
                                                        pathname: "/questions/edit",
                                                        search: `questionId=${question._id}`
                                                    }}
                                                    target="_blank"
                                                >
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        style={{ marginRight: 10 }}
                                                    >Edit Question</Button>
                                                </Link>
                                            </td>


                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        :
                        <Loader />
                }

            </Row>
        </Container>
    )
}

export default WrongTypeQuestion
