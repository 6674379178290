import React from 'react'
import Axios from 'axios'
import {
    Col, Row, Container, Button, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, 
} from 'reactstrap';
import uniqid from 'uniqid'
import { connect } from 'react-redux'
import { cloneDeep, isFinite } from 'lodash';
import moment from 'moment';

import { updateQuestions } from '../../actions'
import QuestionForm from '../../Components/SlideQuestionForm'
import Loader from '../../Components/Loader'
import EditVideoContent from './EditVideoContent'
import EditQuizContent from './EditQuizContent'
import EditCardContent from './EditCardContent'
import EditSlideContent from './EditSlideContent'
import EditCurrentAffairsNotes from './EditCurrentAffairsNotes'
import EditPdfNotes from './EditPdfNote'

class LearnModule extends React.Component {

    state = {
        moduleData: null,
        changedData: false,

        isLoading: true,
        isSortingEnabled: false,
        //Slide state

        // Video State
        initialContent: '',
        isVideoListModalOpen: false,
        foldersList: [],
        videosList: [],
        enableEditMode: false,
        // Quiz State
        newQuestionIdsArray: '',
        randomQuestionList: [],
        showAddRandomQuestionsModal: false,

        // Card State
        selectedCardDetails: { },
        showCardModal: false
    }

    componentDidMount() {
        this.getModuleContent()
    }

    setAnswerTextwithQuestions(quizQuestions) {

        const updatedQuestions = quizQuestions.map(question => {
            question.options.forEach(option => {
                if (option.id === question.answer) {
                    question.answerText = option.text
                }
            })
            return question
        })

        return updatedQuestions

    }

    getModuleContent() {

        const { moduleId } = this.props.match.params

        if (moduleId) {

            Axios.get('/learn/admin/module', { params: { moduleId } })
                .then(res => {

                    if (res.data.quizQuestions) {
                        res.data.quizQuestions = this.setAnswerTextwithQuestions(res.data.quizQuestions)
                    }

                    this.setState(res.data)
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Check console")
                })

        } else {
            alert("Module ID missing, please go back and try again")
        }

    }

    saveModuleContent = () => {

        let moduleData = cloneDeep(this.state.moduleData)
        delete moduleData.comments
        delete moduleData.addedBy
        delete moduleData.lastEditedBy
        
        Axios.post('/learn/module', {
            moduleId: this.state.moduleData._id,
            ...moduleData
        }).then(() => {

            this.setState({ changedData: false })

            if (
                this.state.moduleData.content.moduleType === "quiz" ||
                this.state.moduleData.content.moduleType === "caNotes" ||
                this.state.moduleData.content.moduleType === "video"
            ) {
                this.getModuleContent()
            }

        })
            .catch(err => {
                if (err && err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message)
                } else {
                    console.log(err)
                    alert("Error, check console")
                }
            })

    }

    renderNewQuestionModal() {
        return (
            <Modal
                size="lg"
                isOpen={this.state.showNewQuestionForm}
            >
                <ModalHeader>
                    Enter New Question
                </ModalHeader>
                <ModalBody>

                    <QuestionForm
                        questionValue={this.state.newQuestion.question || ''}
                        questionOnChange={(editorState) => {
                            this.setState((oldState) => {
                                oldState.newQuestion.question = editorState
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}

                        options={this.state.newQuestion.options}
                        optionChange={(e, index) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.options[index].text = text
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        optionTypeChange={(index) => {
                            this.setState((oldState) => {

                                if (oldState.newQuestion.options[index].type === "math") {
                                    oldState.newQuestion.options[index].type = "text"
                                } else {
                                    oldState.newQuestion.options[index].type = "math"
                                }

                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        explanation={this.state.newQuestion.explanation || ''}
                        explanationChange={(e) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.explanation = text
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        questionPaperName={this.state.newQuestion.questionPaperName || ''}
                        onQuestionPaperNameChange={(e) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.questionPaperName = text
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        correctAnswer={this.state.newQuestion.correctAnswer || ''}
                        correctAnswerChange={(e) => {
                            let value = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.correctAnswer = value
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        questionType={this.state.newQuestion.type}
                        toggleQuestionType={(type) => {
                            this.setState(oldState => {
                                oldState.newQuestion.type = type
                                return oldState
                            })
                        }}
                        isMathExplanation={this.state.newQuestion.explanationType === "math"}
                        toggleMathExplanation={() => {
                            this.setState((oldState) => {

                                if (oldState.newQuestion.explanationType === "math") {
                                    oldState.newQuestion.explanationType = "text"
                                } else {
                                    oldState.newQuestion.explanationType = "math"
                                }

                                return { newQuestion: oldState.newQuestion }

                            })
                        }}
                        showCalculator={this.state.newQuestion.showCalculator === true}
                        toggleShowCalculator={() => {
                            this.setState((oldState) => {

                                if (oldState.newQuestion.showCalculator === true) {
                                    oldState.newQuestion.showCalculator = false
                                } else {
                                    oldState.newQuestion.showCalculator = true
                                }

                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                    />

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {

                        let newQuestionObject = this.state.newQuestion

                        if (
                            newQuestionObject.question &&
                            newQuestionObject.options[0].text &&
                            newQuestionObject.options[1].text &&
                            newQuestionObject.options[2].text &&
                            newQuestionObject.options[3].text &&
                            newQuestionObject.correctAnswer
                        ) {

                            this.setState((oldState) => {

                                let questionObject = {
                                    key: uniqid(),
                                    type: "question",
                                    ...oldState.newQuestion,
                                    slideType: "question"
                                }

                                const slides = oldState.moduleData.content.slides

                                if (Array.isArray(slides)) {
                                    oldState.moduleData.content.slides.push(questionObject)
                                } else {
                                    oldState.moduleData.content.slides = [questionObject]
                                }

                                return { showNewQuestionForm: false, newQuestion: null, moduleData: oldState.moduleData }

                            })

                        } else {
                            alert("Fill in all requried fields")
                        }


                    }}>
                        Submit
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={(e) => this.setState({ showNewQuestionForm: false, newQuestion: null })}
                    >
                        Cancel
                    </Button>

                </ModalFooter>
            </Modal>
        )
    }

    renderDirectHtmlEditor() {
        return (
            <Modal
                size="lg"
                isOpen={this.state.showHtmlText && this.state.editingHtmlIndex != null}
            >

                <ModalHeader>
                    Edit HTML Content
                </ModalHeader>

                <ModalBody>
                    <Input
                        type="textarea"
                        name="description"
                        id="description"
                        rows="10"
                        value={this.state.htmlCode || ''}
                        placeholder="Edit HTML"
                        onChange={(e) => {
                            const { value } = e.target
                            this.setState({ htmlCode: value })
                        }}
                    />
                </ModalBody>

                <ModalFooter>

                    <Button color="primary"
                        onClick={() => {
                            this.setState((oldState) => {
                                oldState.moduleData.content.slides[oldState.editingHtmlIndex].content = this.state.htmlCode
                                return { moduleData: oldState.moduleData, htmlCode: null, showHtmlText: null, editingHtmlIndex: null, changedData: true }
                            })
                        }}
                    >Save HTML</Button>

                    <Button
                        outline
                        onClick={() => this.setState({ htmlCode: null, showHtmlText: false, editingHtmlIndex: null })}
                        color="secondary">
                        Cancel
                    </Button>


                </ModalFooter>

            </Modal>
        )
    }

    updateCardAndSlideModule = (moduleData) => {
        this.setState({ moduleData })
    }

    updateModuleData = (moduleData) => {
        let obj = { changedData: true }
        if (moduleData) {
            obj.moduleData = moduleData
        }
        this.setState(obj)
    }

    updateModuleDataAndSave = (moduleData) => {
        this.setState({ moduleData, changedData: true }, () => {
            this.saveModuleContent()
        })
    }

    updateQuizQuestionAndModuleData = (moduleData, otherQuestions) => {
        this.setState({ moduleData, quizQuestions: otherQuestions, changedData: true })
    }

    renderModuleContent(content) {
        switch (content.moduleType) {
            case "slide":
                return (
                    <EditSlideContent
                        moduleData={this.state.moduleData}
                        reportedSlide={this.state.reportedSlide}
                        slideIndex={this.props.location.state ? this.props.location.state.slideIndex : null}
                        updateModuleData={(e)=>this.updateModuleData(e)}
                        updateModuleDataAndSave={(e)=>this.updateModuleDataAndSave(e)}
                        updateCardAndSlideModule={(e)=>this.updateModuleData(e)}
                    />
                )
            case "quiz":
                return (
                    <EditQuizContent
                        moduleData={this.state.moduleData}
                        quizQuestions={this.state.quizQuestions}
                        updateModuleData={(e) => this.updateModuleData(e)}
                        updateModuleDataAndSave={(e) => { this.updateModuleDataAndSave(e) }}
                        updateQuizQuestionAndModuleData={(mol, other) => { this.updateQuizQuestionAndModuleData(mol, other) }}
                        moduleId={this.props.match.params.moduleId}
                        search={this.props.location.search}
                    />
                )
            case "video":
                return (
                    <EditVideoContent
                        teachers={this.props.teachers}
                        moduleData= {this.state.moduleData}
                        updateModuleData={(e)=>this.updateModuleData(e)}
                        getModuleContent = {()=> this.getModuleContent()}
                    />
                )
            case "cards":
                return (
                    <EditCardContent
                        moduleData= {this.state.moduleData}
                        updateModuleData={(e)=>this.updateModuleData(e)}
                        updateCardAndSlideModule = { (e)=> this.updateCardAndSlideModule(e)}
                        saveModuleContent = {()=>this.saveModuleContent()}
                    />
                )
            case "caNotes": 
                return (
                    <EditCurrentAffairsNotes
                        history = {this.props.history}
                        moduleData={this.state.moduleData}
                        updateModuleData={(e) => this.updateModuleData(e)}
                        updateCardAndSlideModule={(e) => this.updateCardAndSlideModule(e)}
                        saveModuleContent={() => this.saveModuleContent()}
                        updateModuleDataAndSave={(e)=>this.updateModuleDataAndSave(e)}
                    />
                )
            case "pdfNote":
                return (
                    <EditPdfNotes
                        history={this.props.history}
                        moduleData={this.state.moduleData}
                        updateModuleData={(e) => this.updateModuleData(e)}
                        saveModuleContent={() => this.saveModuleContent()}
                        updateModuleDataAndSave={(e) => this.updateModuleDataAndSave(e)}
                    />
                )
            default:
                return <p>Default</p>
        }
    }


    render() {

        if (this.state.moduleData) {

            const { moduleTitle, moduleDescription, content, addedBy, addedOn, lastEditedBy, lastEditedOn, sectionId } = this.state.moduleData

            return (
                <Container fluid style={{ padding: 50 }} >
                    <Row>
                        <Col md="12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <h5><b>Title</b>: {moduleTitle}</h5>
                                <h6><b>Subtitle</b>: {moduleDescription}</h6>

                                {addedOn ?
                                    <span style={{ fontSize: 12 }}>Added on {moment(addedOn).format("D MMM YYYY, h:mm:ss a")}
                                        {addedBy ? ` by ${addedBy} ` : null}
                                    </span> : null}

                                <br />

                                {lastEditedOn ?
                                    <span style={{ fontSize: 12 }}>Edited on {moment(lastEditedOn).format("D MMM YYYY, h:mm:ss a")}
                                        {lastEditedBy ? ` by ${lastEditedBy} ` : null}
                                    </span> : null}

                                <br />

                                <Button
                                    color="link"
                                    onClick={() => this.props.history.push({ pathname: `/learn/${sectionId}/null` }) }
                                >
                                    Back to all modules
                                </Button>

                            </div>
                            
                            <Button 
                                color="primary"
                                disabled={!this.state.changedData}
                                onClick={this.saveModuleContent}
                            >
                                Save Changes
                            </Button>

                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            {this.renderModuleContent(content)}
                        </Col>
                    </Row>
                </Container>
            )

        } else {
            return (
                <Loader />
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        teachers: state.data.teachers
    }
}

export default connect(mapStateToProps, { updateQuestions })(LearnModule)
