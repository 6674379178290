import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
    Container, Row, Col, Table,
    Input, Label, Form, FormGroup, Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'

import TinyEditor from '../Components/TinyEditor'
import Loader from '../Components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { SITE_URL } from '../utils/constants'

const ContentModal = ({ content, updateContent, onSave, onClose }) => {

    const { endpoint, pageTitle, pageDescription, pageContent } = content

    return (
        <Modal
            size="lg"
            isOpen={true}
            style={{ maxWidth: '90vw' }}
        >
            <ModalHeader>
                Add/Edit Note Content
            </ModalHeader>

            <ModalBody>
                <Form>

                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label>URL Endpoint</Label>
                                <Input
                                    placeholder={"Ex: /arrival-of-dutch"}
                                    type="text"
                                    name={'endpoint'}
                                    value={endpoint}
                                    onChange={(e) => updateContent(e.target.name, e.target.value)}
                                    style={{ marginBottom: 20 }}
                                />
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label>Page Title</Label>
                                <Input
                                    placeholder={"Page Title"}
                                    type="text"
                                    name={'pageTitle'}
                                    value={pageTitle}
                                    onChange={(e) => updateContent(e.target.name, e.target.value)}
                                    style={{ marginBottom: 20 }}
                                />
                            </FormGroup>

                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <Label>Page Description</Label>
                                <Input
                                    placeholder={"Page Description"}
                                    type="text"
                                    name={'pageDescription'}
                                    value={pageDescription}
                                    onChange={(e) => updateContent(e.target.name, e.target.value)}
                                    style={{ marginBottom: 20 }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <TinyEditor
                            height={'65vh'}
                            content={pageContent}
                            onHtmlChange={(newHtml) => updateContent("pageContent", newHtml)}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>

            <ModalFooter>
                <Button outline onClick={onClose}>CLOSE</Button>
                <Button color="primary" onClick={onSave}>SAVE</Button>
            </ModalFooter>

        </Modal>
    )
}

function SiteNotes() {

    const [isLoading, setIsLoading] = useState(true)
    const [notes, setNotes] = useState(null)
    const [modalData, setModalData] = useState(null)

    useEffect(() => {
        getNotes()
    }, [])

    const getNotes = () => {
        axios.get('/site-notes')
            .then(res => {
                setIsLoading(false)
                setNotes(res.data.notes)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
                alert("Server error, check console")
            })
    }

    const saveNote = () => {

        const { endpoint, pageTitle, pageDescription, pageContent } = modalData

        if (!endpoint || !pageTitle || !pageDescription || !pageContent) {
            alert("Please enter valid data for all four fields")
            return true
        }

        setIsLoading(true)

        if (modalData._id) {
            modalData.siteNoteId = modalData._id
        }

        axios.post('/site-notes', modalData)
            .then(() => {
                getNotes()
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
                alert("Server error, check console")
            })

        setModalData(null)


    }

    const deleteNote = (noteId) => {

        setIsLoading(true)

        axios.delete('/site-notes', { params: { noteId } })
            .then(() => getNotes())
            .catch(err => {
                setIsLoading(false)
                console.log(err)
                alert("Server error, check console")
            })
    }


    return (
        <>
            {isLoading && <Loader />}

            <Container fluid>

                {
                    modalData != null ?
                        <ContentModal
                            content={modalData}
                            updateContent={(key, val) => setModalData({ ...modalData, [key]: val })}
                            onSave={saveNote}
                            onClose={() => setModalData(null)}
                        />
                        : null
                }

                <Button
                    outline
                    color="primary"
                    style={{ marginTop: 25, marginBottom: 25 }}
                    onClick={() => setModalData({ endpoint: '', pageTitle: '', pageDescription: '', pageContent: '' })}
                >
                    + Add New Note
                </Button>

                <Row>
                    <Col md="12">

                        {
                            Array.isArray(notes) && notes.length > 0 ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Endpoint</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            notes.map((note, index) => (
                                                <tr
                                                    key={note._id}
                                                    style={{ background: note.activeFlag ? 'transparent' : 'rgba(255,0,0,0.1)' }}
                                                >
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <a 
                                                            target='_blank'
                                                            rel="noopener noreferrer"
                                                            href={`${SITE_URL}/blog/${note.endpoint}-${note._id}`}
                                                        >
                                                            <Button
                                                                color="link"
                                                                style={{ textAlign:'left' }}
                                                            >{note.endpoint}</Button>
                                                        </a>
                                                    </td>
                                                    <td>{note.pageTitle}</td>
                                                    <td>{note.pageDescription}</td>
                                                    <td>

                                                        <Button
                                                            outline
                                                            color="primary"
                                                            style={{ marginRight: 10 }}
                                                            onClick={() => setModalData(note)}
                                                        >
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </Button>

                                                        <Button
                                                            outline
                                                            color="danger"
                                                            onClick={() => {
                                                                if (window.confirm("Are you sure you want to delete this note?")) {
                                                                    deleteNote(note._id)
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                :
                                Array.isArray(notes) && notes.length === 0 ?
                                    <p style={{ marginTop: '40vh', textAlign: 'center' }}>No notes added</p>
                                    :
                                    null
                        }
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default SiteNotes
