export let URL
export let LIVE_EXAM_URL
export let SEARCH_SERVER

const UPSC_SERVER_URL = "https://upsc.keralapsc.app"
const KERALA_PSC_SERVER_URL = "https://data.keralapsc.app"

if (window.location.origin === UPSC_SERVER_URL) {
    URL = 'https://upsc.keralapsc.app' // API Prod URL
    LIVE_EXAM_URL = 'https://sse-server.keralapsc.app' // Live Exam URL
    SEARCH_SERVER = 'https://elasticsearch.keralapsc.app'
} else if (window.location.origin === KERALA_PSC_SERVER_URL) {
    URL = 'https://data.keralapsc.app' // API Prod URL
    LIVE_EXAM_URL = 'https://sse-server.keralapsc.app' // Live Exam URL
    SEARCH_SERVER = 'https://elasticsearch.keralapsc.app'
} else {
    URL = 'http://localhost:8000'
    LIVE_EXAM_URL = 'http://localhost:3002'
    SEARCH_SERVER = `http://localhost:8003`
}

export const districts = [
    { name: "Alappuzha", id:'1' },
    { name: "Ernakulam", id:'2' },
    { name: "Idukki", id:'3' },
    { name: "Kannur", id:'4' },
    { name: "Kasargod", id:'5' },
    { name: "Kollam", id:'6' },
    { name: "Kottayam", id:'7' },
    { name: "Kozhikode", id:'8' },
    { name: "Malappuram", id:'9' },
    { name: "Palakkad", id:'10' },
    { name: "Pathanmthitta", id:'11' },
    { name: "Thiruvananthapuram", id:'12' },
    { name: "Thrissur", id:'13' },
    { name: "Wayanad", id:'14' }
]

export const PER_PAGE_CONTENT = 50;
export const QUESTION_LANGUAGE_REGEX = '[^a-zA-z .*:?/-]+'
export const REMOVE_CHARS_REGEX = /[!^_+="~<.*;':!@#&$}>{()% ,?\\-]/g