import React from 'react'
import { Row } from 'reactstrap';
import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

function MathQuestionViewer({ mathText, delimiter }) {

    const nthIndex = (str, pat, n) => {
        var L = str.length, i = -1;
        while (n-- && i++ < L) {
            i = str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    }

    let indexArray = []

    for (let index = 0; index < mathText.length; index++) {
        
        let found = nthIndex(mathText, delimiter, index)

        if (found >= 0) {
            indexArray.push(found)
        } else if (index === 0) {
            indexArray.push(-1)
        }

    }

    const twoByTwoArray = indexArray.map((element, index) => {
        if ((index + 1) % 2 === 0) {
            let startingIndex = indexArray[index] + delimiter.length
            let endingIndex = startingIndex > indexArray[index + 1] ? startingIndex : indexArray[index + 1]
            return {
                type: "math",
                string: mathText.substring(startingIndex, endingIndex)
                    .split("rgb(255,255,255)")
                    .join("rgb(0,0,0)"),
                fromAndToIndex: [indexArray[index] + delimiter.length, indexArray[index + 1]]
            }
        } else {
            let startingIndex = indexArray[index] + delimiter.length
            let endingIndex = startingIndex > indexArray[index + 1] ? startingIndex : indexArray[index + 1]
            return {
                type: "text",
                string: mathText.substring(startingIndex, endingIndex)
                    .split("rgb(255,255,255)")
                    .join("rgb(0,0,0)"),
                fromAndToIndex: [indexArray[index] + delimiter.length, indexArray[index + 1]]
            }
        }
    })

    return (
        <Row style={{ background: 'none', paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
            {
                twoByTwoArray.map((each, index) => (
                    <div
                        key={each.fromAndToIndex[0] + each.fromAndToIndex[1]}
                        style={{ marginRight: 20 }}
                    >
                        {
                            each.type === "math" ?
                                <div>
                                    <InlineMath>
                                        {each.string}
                                    </InlineMath>
                                </div >
                                :
                                <div
                                    style={{ display: "inline" }}
                                    dangerouslySetInnerHTML={{ __html: each.string }}
                                />
                        }
                    </div>
                ))
            }
        </Row>
    )

}

export default MathQuestionViewer
