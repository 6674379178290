import React, { Component } from 'react'
import axios from 'axios'
import { connect, batch } from 'react-redux'
import { Button, CardBody,CardTitle, Table, Container, Row, Col ,Card } from 'reactstrap';
import { Link } from "react-router-dom";
import {
    updateHomeData,
    updateSubjects,
    login,
    updatePosts,
    updatePacks,
    updateCourseGroups,
    updateAddedQuestionsCountDetails,
    updateLanguages,
    updateSectionQuestionsCount,
    updatePathUnits,
    updateSalesmen,
    updateTeachersList
} from '../actions'
import { isAdmin, isContentTeam, isSalesManagerOrHigher } from '../utils/dashboardRoles';
import Loader from '../Components/Loader'
import Aux from '../Components/Auxiliary'

class Home extends Component {

    state = {
        details: [],
        statistics:[],
        questionAddedCountDetails: [],
        period: 'lastSevenDays'
    }

    fetchAllUnits() {
        return new Promise(async (resolve, reject) => {
            try {

                const res = await axios.get('/home-all-units')

                const { allUnits } = res.data
                this.props.updatePathUnits(allUnits.learningPaths)
                resolve(true)

            } catch (err) {
                console.log(err)
                reject(err)
            }
        })
    }

    fetchPosts() {
        return new Promise(async (resolve, reject) => {
            try {

                const res = await axios.get('/home-posts-with-exams')
                const { postsWithExams } = res.data
                this.props.updatePosts(postsWithExams)
                resolve(true)

            } catch (err) {
                console.log(err)
                reject(err)
            }
        })
    }

    fetchRefreshData() {
        return new Promise(async (resolve, reject) => {
            try {

                const res = await axios.get('/refresh-data')

                const { allSubjects, questionStatus, packs, courseGroups, languageTree, salesmenData, teachers } = res.data

                if (allSubjects) {
                    batch(() => {
                        const { subjects, staticSectionsWithCount } = allSubjects
                        this.props.updateSubjects(subjects)
                        this.props.updateSectionQuestionsCount(staticSectionsWithCount)
                        this.props.updateLanguages(languageTree)
                        this.props.updateHomeData(questionStatus)
                        this.props.updatePacks(packs.PackDetails)
                        this.props.updateCourseGroups(courseGroups)
                        this.props.updateTeachersList(teachers)
                    })
                }
                
                if (salesmenData) {
                    this.props.updateSalesmen(salesmenData)
                }

                resolve(true)

            } catch (err) {
                console.log(err)
                reject(err)
            }
        })
    }

    fetchLoginDetailsAndRefreshData() {

        return new Promise(async (resolve, reject) => {
            try {

                const { data } = await axios.get('/login-data')
                
                this.props.login(data)
                localStorage.setItem('userData', JSON.stringify(data));
                localStorage.setItem('userToken', data.token);

                resolve(true)

            } catch (err) {

                console.log(err)
                reject(err)

                if(err && err.response && err.response.status === 400) {
                    alert("User ID missing. Please logout and login again")
                } else {
                    alert("Error, check console")
                }
                
            }
        })


    }

    async componentDidMount() {
        await this.fetchLoginDetailsAndRefreshData()
        await this.fetchRefreshData()
        await this.fetchAllUnits()
        await this.fetchPosts()
    }

    renderLoader() {

        const { auth, home, salesmen } = this.props

        if (
            (isContentTeam(auth.roleIds) &&
            (!home || (home.statistics.length <= 0)))
        ) {
            return <Loader />
        } else if (
            !(Array.isArray(this.props.posts) && this.props.posts.length > 0)
            || !(Array.isArray(this.props.packs) && this.props.packs.length > 0)
            || !(Array.isArray(this.props.pathUnits) && this.props.pathUnits.length > 0)
        ) {
            return <Loader />
        } else if (isSalesManagerOrHigher(auth.roleIds) && Array.isArray(salesmen) && salesmen.length <= 0) {
            return <Loader />
        } else if (!isContentTeam(auth.roleIds) && isSalesManagerOrHigher(auth.roleIds) && Array.isArray(salesmen) && salesmen.length > 0) {
            return (
                <div style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>
                    <h2 style={{ marginTop: '25vh', marginBottom: 50 }}>Welcome to <br />Challenger Dashboard</h2>
                </div>
            )
        }

    }

    linkToEachCard(keyName, value) {
        if (keyName === 'Uncategorized Questions') {
            return <Link to="/questions?type=Uncategorized"><Button color="primary" block>View All</Button></Link>
        } else if (keyName === 'Total Questions') {
            return <Link to="/questions"><Button color="primary" block>View All</Button></Link>
        } else if (keyName === 'Total Reported Questions') {
            return <Link to="/reported-questions"><Button color={value > 0 ? "danger" : "primary"} block>View All</Button></Link>
        } else if (keyName === 'Users Count') {
            if (isAdmin(this.props.auth.roleIds)) {
                return <Link to="/bots"><Button color="primary" block>View All</Button></Link>
            }
        }
    }

    render() {

        return (
            <Aux>

                {this.renderLoader()}
                <Container>


                    {
                        (this.props.home.topStats && Object.keys(this.props.home.topStats).length > 0) &&
                        <Row style={{ marginTop: 25, marginBottom: 25 }}>
                            {
                                Object.keys(this.props.home.topStats).map(eachKey => (
                                    <Card key={eachKey} style={{ width: '17rem', margin: 5 }}>
                                        <CardBody>
                                            <CardTitle style={{ textAlign: 'center' }}>{eachKey}</CardTitle>
                                            <h1 style={{ textAlign: 'center' }}>
                                                {this.props.home.topStats[eachKey]}
                                            </h1>
                                                {this.linkToEachCard(eachKey, this.props.home.topStats[eachKey])}
                                        </CardBody>
                                    </Card>
                                ))
                            }
                        </Row>
                    }

                    <Row>
                        {
                            (this.props.home.statistics && Object.keys(this.props.home.statistics).length > 0) &&
                            <Col lg="12">
                                <Table responsive style={{ marginTop: '20px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'left' }}>Status</th>
                                            <th>Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(this.props.home.statistics).map((each, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: 'left' }}>{each}</td>
                                                        <td>{this.props.home.statistics[each]}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        }
                    </Row>
                </Container>

            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        home: state.home,
        auth: state.auth,
        subjects: state.data.subjects,
        tags: state.data.tags,
        posts: state.data.posts,
        packs: state.data.packs,
        pathUnits: state.data.pathUnits,
        questionAddedDetails: state.data.questionsAddedDetails,
        salesmen: state.data.salesmen,
        languages: state.data.languages
    }
}

export default connect(mapStateToProps, {
    updateHomeData,
    updateSubjects,
    updatePosts,
    updateAddedQuestionsCountDetails,
    updatePacks,
    updateCourseGroups,
    updateTeachersList,
    updateLanguages,
    updateSectionQuestionsCount,
    updatePathUnits,
    login,
    updateSalesmen
})(Home);