import React, { useState, useEffect } from 'react';
import axios from 'axios'

import { Container, Col, Row, Button } from 'reactstrap'
import 'fabric-webpack'
import TextField from 'material-ui/TextField';
import FileUploader from '../Components/FileUploader'

const fabric = window.fabric;

function ImageEditor(props) {

    const [canvas, setCanvas] = useState({});
    const [objects, setObjects] = useState(0);
    const [mainImage, setImage] = useState(0);

    const [question, setQuestion] = useState("sample question")
    const [answer, setAnswer] = useState("sample answer");

    useEffect(() => {
        setCanvas(new fabric.Canvas('c'));

        // check for question and answer
        if(props.location.state && props.location.state.question){
            setQuestion(props.location.state.question)
            let correctOptionArr = props.location.state.options.filter((item) => item.id === props.location.state.answer)
            setAnswer(correctOptionArr[0].text)
        }
    }, []);

    function removeObj() {
        if (canvas.getActiveObject()) {
            canvas.getActiveObject().remove();
            setObjects(objects - 1);
        }
    }

    function download(e) {
        e.persist();
        canvas.deactivateAll().renderAll();
        e.target.href = canvas.toDataURL();
        e.target.download = "exported.png";
    }

    function addText() {
        var text = new fabric.Textbox(question, {
            top: 520,
            width: 680,
            left: 20,
            height: 200,
            textAlign: 'center',
            fill: '#fff',
            fontSize: 26,
            lineHeight: 1.1,
        });
        canvas.add(text);
        setObjects(objects + 1);
    }

    function addAnswer() {
        var text = new fabric.Textbox(answer, {
            top: 590,
            width: 680,
            left: 20,
            height: 200,
            textAlign: 'center',
            fill: 'yellow',
            fontSize: 28,
            lineHeight: 1.1,
        });
        canvas.add(text);
        setObjects(objects + 1);
    }

    function addBox() {

        var box = new fabric.Rect({
            top: 500, width: 700, height: 200, fill: 'black'
        });
        canvas.add(box);

        box.setGradient('fill', {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: box.height / 2,
            colorStops: {
                0: 'rgba(0,0,0, 0.1)',
                1: 'black'
            }
        });
    }

    function addBottomBoxTopBorder(){
        var box = new fabric.Rect({
            top: 640, width: 700, height: 5, fill: 'red'
        });
        canvas.add(box);
    }

    function addPromoText() {
        const promoText = "ലക്ഷക്കണക്കിന് ഉദ്യോഗാർത്ഥികളുമായി കളിച്ചു കൊണ്ട് പഠിക്കൂ ഉടനെ Challenger അപ്ലിക്കേഷൻ ഡൌൺലോഡ് ചെയ്യൂ "
        var text = new fabric.Textbox(promoText, {
            top: 655,
            left: 20,
            height: 200,
            width:700,
            fill:'black',
            textAlign: 'center',
            fontSize: 15,
            lineHeight: 1.1,
        });
        canvas.add(text);
        canvas.bringToFront(text);
    }

    function addBottomBox(){
        var box = new fabric.Rect({
            top: 645, width: 740, height: 70, fill: 'white', left: -10
        });
        setObjects(objects + 10);
        canvas.add(box);
    }

    function showImageOnCanvas() {
        var imgObj = new Image();
        imgObj.src = mainImage;
        imgObj.onload = function () {
            var image = new fabric.Image(imgObj);
            image.set({
                top:0,
                angle: 0,
                height: 700,
                width: 700,
            });

            fabric.Image.fromURL('https://i.imgur.com/XThBs7P.png', function(oImg) {
                oImg.set({
                    top: 420,
                    left:-10,
                    width : 170,
                    height : 30
                });
                canvas.add(oImg);
            });

            canvas.centerObject(image);
            canvas.add(image);
            addBox();
            addBottomBoxTopBorder()
            setObjects(objects + 1);
            addText();
            addAnswer()
            setObjects(objects + 1);
            addBottomBox()
            addPromoText();
            canvas.renderAll();
        }
    }

    const setImageToState = (file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            setImage(reader.result);
        }

        if (file) {
            reader.readAsDataURL(file);
          }
    }

    useEffect(() => {
        showImageOnCanvas()
    }, [mainImage])

    function handleQuestionInput(e) {
        setQuestion(e.target.value)
    }

    function handleAnswerInput(e) {
        setAnswer(e.target.value)
    }

    function uploadImage(e){

        let finalImage = canvas.toDataURL('png');

        e.preventDefault();
        
        const formData = new FormData();
        formData.append('myImage', finalImage);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        axios.post("/telegram-forward", formData, config)
            .then((response) => {
                console.log('response', response);
            }).catch((error) => {
                console.log('error', error);
            });
    }

    return (
        <div className="ImageEditor">

            <Container fluid>
                <Row>
                    <Col sm="4">
                        <div>
                            <TextField
                                id={"1"}
                                value={question}
                                hintText="Option 1"
                                name="option1"
                                fullWidth={true}
                                onChange={handleQuestionInput}
                            />
                            <TextField
                                id={"1"}
                                value={answer}
                                hintText="Option 1"
                                name="option1"
                                fullWidth={true}
                                onChange={handleAnswerInput}
                            />

                            <FileUploader setImageToState={setImageToState} />

                        </div>

                        <header className="ImageEditor-header">
                            {
                                objects > 0 && (
                                    <Button variant="contained" style={{ marginRight: '10px' }} onClick={removeObj}>Remove Selected</Button>
                                )
                            }

                            <Button variant="contained" style={{ marginRight: '10px', marginTop: '10px' }} onClick={addText}>Add Text</Button>
                            
                            {
                                objects > 0 && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ marginRight: '10px' }}
                                        onClick={download}
                                    >
                                        Download Image
                                    </Button>
                                )
                            }

                        {
                                objects > 0 && (
                                    <Button variant="contained" style={{ marginRight: '10px', marginTop: '10px' }} onClick={uploadImage}>Upload To Telegram</Button>
                                )

                                
                            }

                        </header>
                    </Col>
                    <Col sm="8">
                        <div className="canvasContainer">
                            <canvas id="c" width="700" height="700"></canvas>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );

}

export default ImageEditor