import {
    UPDATE_SUBJECTS,
    UPDATE_TAGS,
    UPDATE_PACKS,
    UPDATE_POSTS,
    UPDATE_LANGUAGES,
    UPDATE_SECTIONS_QUESTIONS_COUNT,UPDATE_QUESTIONS,
    UPDATE_PATH_UNITS,
    UPDATE_SALESMEN,
    UPDATE_ADDED_QUESTIONS,
    UPDATE_COURSE_GROUPS,
    UPDATE_TEACHERS_LIST
} from '../config/types'

const initialState = {
    subjects:[],
    tags:[],
    questionsAddedDetails:[],
    posts:[],
    packs:[],
    languages:[],
    sectionsWithQuestionsCount:[],
    questions: [],
    pathUnits:[],
    salesmen: [],
    courseGroups: [],
    teachers: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "persist/REHYDRATE":
            return action.payload !== undefined ? { ...state,...action.payload.data } : { ...state }
        case UPDATE_SUBJECTS:
            return { ...state, subjects:action.payload }
        case UPDATE_TAGS:
            return { ...state, tags:action.payload }
        case UPDATE_POSTS:
            return { ...state, posts:action.payload }
        case UPDATE_PACKS:
            return { ...state, packs:action.payload }    
        case UPDATE_LANGUAGES:
            return { ...state, languages:action.payload }
        case UPDATE_SECTIONS_QUESTIONS_COUNT:
            return { ...state, sectionsWithQuestionsCount:action.payload }
        case UPDATE_QUESTIONS:
            return {...state, questions: action.payload}
        case UPDATE_PATH_UNITS:
            return {...state, pathUnits: action.payload}
        case UPDATE_SALESMEN:
            return { ...state, salesmen: action.payload }
        case UPDATE_ADDED_QUESTIONS:
            return { ...state, questionsAddedDetails: action.payload }
        case UPDATE_COURSE_GROUPS:
            return { ...state, courseGroups: action.payload }
        case UPDATE_TEACHERS_LIST:
            return { ...state, teachers: action.payload }
        default:
            return { ...state }
    }
}