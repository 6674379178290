import React, { Component } from 'react'
import axios from 'axios'
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import { connect } from 'react-redux'
import arrayMove from 'array-move';
import { cloneDeep } from 'lodash'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { updatePosts } from '../actions'
import { copyToClipboard } from '../utils'; 
import { Checkbox, Card, SelectField, MenuItem } from 'material-ui';
import { Button, Container, Row, Col, Table, Modal, ModalBody, ModalFooter, Form, FormGroup, ModalHeader, Badge, UncontrolledTooltip, ListGroupItem, Input, Collapse, CardBody, CustomInput } from 'reactstrap';
import { isContentManagerOrHigher, isAdmin, isSalesManagerOrHigher} from '../utils/dashboardRoles';
import Loader from './Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'

import '../Pages/Pages.css'

const initialState = {
    showNewPostDialog: false,
    showEditDialog: false,
    showDeleteDialog: false
}

const SortableItem = SortableElement(({ post, listIndex, fetchUsersCount, roleIds, subjectButton, editButton, openAccessControl, deleteButton, toggleUpcoming, toggleApproved, deletePack, isSortingEnabled }) => {
    return (
        <tr key={post._id} style={{ background: !post.active ? 'rgba(255,0,0,0.2)' : null }}>
            <td>{listIndex + 1}</td>
            <td>{post.name}</td>
            <td>{post.exams.length}</td>

            {
                fetchUsersCount ?
                    <>
                        <td>
                            <p className='inlineBlock'>{`${post.enrolledFreeUsersCount} `}</p>
                            <p className='inlineBlock' style={{ marginLeft: 5, color: "grey" }} id={`freeUser_id${listIndex}`}>({post.recentlyActiveFreeUsersCount})</p>
                            <UncontrolledTooltip
                                placement="right"
                                target={`freeUser_id${listIndex}`}
                            >
                                {`${post.recentlyActiveFreeUsersCount} users active in last 7 days`}
                            </UncontrolledTooltip>
                        </td>
                        <td>
                            <p className='inlineBlock'>{`${post.enrolledPremiumUserCount} `}</p>
                            <p className='inlineBlock' style={{ marginLeft: 5, color: "grey" }} id={`premiumUser_id${listIndex}`}>({post.recentlyActivePremiumUsersCount})</p>
                            <UncontrolledTooltip
                                placement="right"
                                target={`premiumUser_id${listIndex}`}
                            >
                                {`${post.recentlyActivePremiumUsersCount} users active in last 7 days`}
                            </UncontrolledTooltip>
                        </td>
                    </>
                    : null
            }

            {!isSortingEnabled ?
                <>
                    {
                        isContentManagerOrHigher(roleIds) &&

                        <td>
                            <Checkbox
                                label="Upcoming"
                                checked={post.upcoming}
                                onCheck={(e, value) => { toggleUpcoming(post._id, value, listIndex) }}
                            />
                        </td>
                    }

                    {
                        isContentManagerOrHigher(roleIds) &&

                        <td>
                            <Checkbox
                                label="Show"
                                checked={post.approved}
                                onCheck={(e, value) => { toggleApproved(post._id, value, listIndex) }}
                            />
                        </td>
                    }

                    {
                        isContentManagerOrHigher(roleIds) &&
                        <td>
                            <Button
                                color="primary"
                                onClick={() => subjectButton(post)}
                            >Subjects</Button>
                        </td>
                    }

                    {
                        isContentManagerOrHigher(roleIds) &&
                        <td>
                            <Button
                                outline
                                color="secondary"
                                onClick={() => openAccessControl(post)}
                            >
                                Access Control
                            </Button>
                        </td>
                    }

                    <td>
                        <Button
                            outline
                            color="secondary"
                            style={{ marginRight: 7 }}
                            onClick={() => {
                                copyToClipboard(post._id)
                                toast("ID copied", {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true
                                    })
                            }}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>

                        <Button
                            color="warning"
                            style={{ marginRight: 7 }}
                            onClick={() => editButton(post)}
                        >
                            Edit
                        </Button>

                        {
                            isAdmin(roleIds) &&
                            <>
                                <Link
                                    to={`/bots?postId=${post._id}`}
                                    target="_blank"
                                >
                                    <Button
                                        style={{ marginRight: 7 }}
                                        outline
                                        color="primary"
                                    >
                                        View Users
                                    </Button>
                                </Link>
                            </>
                        }

                        {
                            isContentManagerOrHigher(roleIds) &&
                                <Button
                                    outline
                                    color="danger"
                                    onClick={() => deleteButton(post)}
                                >
                                    {post.active ? "Delete" : "Undelete"}
                                </Button>
                        }
                    </td>

                </> :
                <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <FontAwesomeIcon
                            icon={faSort}
                            style={{ marginLeft: 10, color: 'grey' }}
                        />

                    </td>
                </>
            }
                  
        </tr>
    )
})

const SortableList = SortableContainer(({ items, fetchUsersCount, roleIds, subjectButton, editButton, openAccessControl, deleteButton, toggleUpcoming, toggleApproved, isSortingEnabled }) => {
    return (
        <tbody>
            {
                items.map((item, index) => (
                    <SortableItem
                        disabled={!isSortingEnabled}
                        isSortingEnabled={isSortingEnabled}
                        key={item._id}
                        index={index}
                        listIndex={index}
                        post={item}
                        toggleUpcoming={toggleUpcoming}
                        toggleApproved={toggleApproved}
                        roleIds={roleIds}
                        subjectButton={subjectButton}
                        editButton={editButton}
                        openAccessControl={openAccessControl}
                        deleteButton={deleteButton}
                        fetchUsersCount={fetchUsersCount}
                    />
                ))
            }
        </tbody>
    )
})
class Post extends Component {

    state = {
        ...initialState,
        posts: [],
        newPostName: '',
        selectedSubjects: [],
        selectedSections: [],
        postList: [],
        isLoading: true,
        showEnterAllDataAlert: false,
        showSubjectsEditDialog: false,
        isSortingEnabled: false,
        fetchUsersCount: false
    }

    componentDidMount() {
        this.getPosts()
    }

    getPosts() {
        this.setState({ isLoading: true }, () => {
            
            const paramsObject = {}
            
            if (this.state.fetchUsersCount) {
                paramsObject.fetchUsersCount = true
            }
            
            axios.get(`/posts/exams`, { params: paramsObject })
                .then((response) => {
                    this.props.updatePosts(response.data.posts)
                    this.setState({ ...initialState, postList: response.data.posts, isLoading: false })
                })
                .catch((err) => {
                    console.error('/posts/exams')
                    console.error(err)
                    alert("Error, Check console")
                    this.setState({ isLoading: false })
                })
        })
    }

    submitNewPost() {
        
        if (this.state.name.trim().length === 0) {
            return alert("Please fill post name")
        }

        let postObject = {
            name: this.state.name,
            emoji: this.state.emoji,
            postCategory: this.state.postCategory,
            syllabusLink: this.state.syllabusLink
        }

        if (this.state.editingPostId) {

            postObject.postId = this.state.editingPostId
            
            axios.post('/post', postObject)
            .then(() => {
                this.setState({ showPostAddOrEditModal: false })
                this.getPosts()
            })
            .catch(err => {
                console.error('post/post')
                console.error(err)

                alert("Server error. Check console")
            })

        } else {

            axios.put('/post', postObject)
                .then(() => {
                    this.setState({ showPostAddOrEditModal: false })
                    this.getPosts()
                })
                .catch(err => {
                    console.error('put/post')
                    console.error(err)
                    alert("Server error. Check console")
                })
        }
    }

    deletePost() {
        axios.delete('/post', { params: { postId: this.state.deleteItemId } })
            .then(res => {
                this.getPosts()
                this.setState({ showDeleteDialog: false })
            })
            .catch(err => {
                console.error('delete/post')
                console.error(err)
            })
    }

    toggleUpcoming(postId, value, postIndex) {
        axios.post('/post/toggleUpcoming', { postId, upcoming: value })
            .then(res => this.getPosts())
            .catch(err => {
                console.error('/post/toggleUpcoming')
                console.error(err)
            })
    }

    toggleApproved(postId, value, postIndex) {
        axios.post('/post/toggleApproved', { postId, approved: value })
            .then(res => this.getPosts())
            .catch(err => {
                console.error('/post/toggleApproved')
                console.error(err)
            })
    }

    onPostsSortEnd = ({ oldIndex, newIndex }) => {

        this.setState(oldState => {
            let reorderedList = arrayMove(oldState.postList, oldIndex, newIndex)
            oldState.postList = reorderedList
            return oldState
        }, () => {

            let posts = cloneDeep(this.state.postList)
            let modulesWithUpdatedIndex = posts.map((each, index) => ({ _id: each._id, index }))

            axios.post('/post/reorder', { modulesWithUpdatedIndex })
                .catch(err => {
                    console.error('/post/reorder')
                    console.error(err)
                    alert("Error, check console")
                })
                
        })
    }

    handleCheckBoxChange(subjectId) {
        this.setState((oldState) => {

            let foundIndex = oldState.selectedSubjects.indexOf(subjectId)

            if (foundIndex > -1) {
                oldState.selectedSubjects.splice(foundIndex, 1);
                return { selectedSubjects: oldState.selectedSubjects }
            } else {
                oldState.selectedSubjects.push(subjectId)
                return { selectedSubjects: oldState.selectedSubjects }
            }
        })
    }

    handleSectionsCheckBoxChange(sectionId, subject) {

        this.setState((oldState) => {

            let foundIndex = oldState.selectedSections.indexOf(sectionId)

            if (foundIndex > -1) {
                oldState.selectedSections.splice(foundIndex, 1);
                return { selectedSections: oldState.selectedSections }
            } else {
                oldState.selectedSections.push(sectionId)
                return { selectedSections: oldState.selectedSections }
            }
        }, () => {

            let count = 0

            subject.sections.forEach(each => {
                if (this.state.selectedSections.includes(each._id)) {
                    count = count + 1
                } else {
                    count = count - 1
                }
            })

            if (count === subject.sections.length) {
                this.setState((oldState) => {
                    oldState.selectedSubjects.push(subject._id)
                    return ({ selectedSubjects: oldState.selectedSubjects })
                })
            } else {
                let filterSubjects = this.state.selectedSubjects.filter(item => item !== subject._id)
                this.setState({ selectedSubjects: filterSubjects })
            }
        })
    }

    handleSectionsSingleCheckBox(value, subject) {

        if (value === true) {

            this.setState((oldState) => {
                oldState.selectedSubjects.push(subject._id)
                return ({ selectedSubjects: oldState.selectedSubjects })
            })

            subject.sections.forEach((each) => {
                this.setState((oldState) => {
                    if (!oldState.selectedSections.includes(each._id)) {
                        oldState.selectedSections.push(each._id)
                        return ({ selectedSections: oldState.selectedSections })
                    }
                })
            })

        } else {

            let foundIndexesArray = []
            let filterSubjects = this.state.selectedSubjects.filter(item => item !== subject._id)

            subject.sections.map(each => {
                let FoundIndex = this.state.selectedSections.indexOf(each._id)
                foundIndexesArray.push(FoundIndex)
            })

            let filterSections = this.state.selectedSections.filter((_, index) => !foundIndexesArray.includes(index))

            this.setState({ selectedSections: filterSections, selectedSubjects: filterSubjects })
        }
    }

    updatePostSubjects() {

        if (this.state.selectedSubjects && Array.isArray(this.state.selectedSubjects)) {

            axios.post('/setPostSubjects', {
                postId: this.state.editingPostId,
                sections: this.state.selectedSections,
                subjects: this.state.selectedSubjects,
            })
                .then(() => {
                    this.setState({ showSubjectsEditDialog: false })
                    this.getPosts()
                })
                .catch(err => {
                    console.error('post/setPostSubjects')
                })

        } else {
            this.setState({ showEnterAllDataAlert: true })
        }

    }

    openAccessEditingModal = (post) => {

        if (Array.isArray(this.state.contentTeam) && this.state.contentTeam.length > 0) {
            this.setState({
                showAccessEditingModal: true,
                accessEditingPostId: post._id,
                allowedMembers: post.allowedMembers || []
            })
        } else {
            axios.get('/team', { params: { teamGroup: "contentTeam" } })
                .then(res => {
                    this.setState({
                        contentTeam: res.data.team,
                        showAccessEditingModal: true,
                        accessEditingPostId: post._id,
                        allowedMembers: post.allowedMembers || []
                    })
                })
                .catch(err => {
                    console.error('get/team')
                    console.error(err)
                    alert("Error. Check console")
                })
        }

    }

    handleAllowedUsersCheckboxChange(userId, index) {

        this.setState((oldState) => {

            let foundIndex = oldState.allowedMembers.indexOf(userId)

            if (foundIndex > -1) {
                oldState.allowedMembers.splice(foundIndex, 1);
                return { allowedMembers: oldState.allowedMembers }
            } else {
                oldState.allowedMembers.push(userId)
                return { allowedMembers: oldState.allowedMembers }
            }

        })
    }

    saveAccessList() {
        axios.post('/post/update-access', { postId: this.state.accessEditingPostId, allowedUsers: this.state.allowedMembers })
            .then(() => {
                this.setState({
                    showAccessEditingModal: false,
                    accessEditingPackId: null,
                    allowedMembers: null
                }, () => this.getPosts())
            })
            .catch(err => {
                console.error('post/post/update-access')
                console.error(err)
                alert("Error, check console")
            })
    }

    render() {

        const deleteActions = [
            <FlatButton
                label="Confirm"
                primary={true}
                onClick={() => this.deletePost()}
            />,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={() => this.setState({ showDeleteDialog: false })}
            />,
        ];

        return (
            <Container fluid>

                {this.state.isLoading && <Loader />}

                {
                    this.state.showAccessEditingModal &&
                    <Modal
                        isOpen={this.state.showAccessEditingModal}
                        size="lg"
                    >
                        <ModalHeader>
                            Select content team allowed to edit this post
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <div>
                                    {
                                        this.state.contentTeam && this.state.contentTeam.map((each, index) => {
                                            return (
                                                <CustomInput
                                                    id={each._id}
                                                    key={each._id}
                                                    value={each._id}
                                                    type="checkbox"
                                                    label={each.name}
                                                    onChange={(event) => this.handleAllowedUsersCheckboxChange(each._id)}
                                                    checked={this.state.allowedMembers.includes(each._id)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.saveAccessList()}
                            >
                                Submit
                            </Button>

                            <Button
                                outline
                                onClick={() => this.setState({ showAccessEditingModal: false, accessEditingPackId: null, allowedMembers: null })}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                }

                <Modal
                    size="lg"
                    isOpen={this.state.showSubjectsEditDialog}
                >
                    <ModalHeader>
                        Select Topics
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <div>
                                    {
                                        this.props.subjects.map((subject, index) => {

                                            const isOpen = this.state.openIndex === index

                                            const count = subject.sections.reduce((acc, item) => {
                                                const foundIndex = this.state.selectedSections.includes(item._id)
                                                if (foundIndex) {
                                                    return acc + 1
                                                } else {
                                                    return acc + 0
                                                }
                                            }, 0)

                                            return (
                                                <ListGroupItem
                                                    key={subject._id}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                        <p style={{ flex: 2 }}>
                                                            {subject.name}
                                                            <Badge style={{ marginLeft: 10 }} pill> {count} Selected</Badge>
                                                        </p>

                                                        <Input
                                                            style={{ flex: 2, marginLeft: 30 }}
                                                            type="checkbox"
                                                            value={subject.sections}
                                                            checked={this.state.selectedSubjects.includes(subject._id)}
                                                            onChange={(e) => this.handleSectionsSingleCheckBox(e.target.checked, subject, subject.sections)}
                                                        />

                                                        <Button
                                                            size="sm"
                                                            style={{ flex: 1 }}
                                                            color={isOpen ? "primary" : "secondary"}
                                                            onClick={() => this.setState({ openIndex: isOpen ? null : index })}
                                                        >
                                                            {isOpen ? "CLOSE" : "OPEN"}
                                                        </Button>
                                                    </div>

                                                    {
                                                        isOpen &&
                                                        <Collapse isOpen={isOpen} style={{ marginTop: 10 }}>
                                                            {
                                                                subject.sections.map((each, sectionIndex) => {
                                                                    return (
                                                                        <Card key={each._id} >
                                                                            <CardBody>
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>

                                                                                    <Input
                                                                                        style={{ flex: 2, margin: 10 }}
                                                                                        type="checkbox"
                                                                                        value={each._id}
                                                                                        onChange={(e) => this.handleSectionsCheckBoxChange(each._id, subject)}
                                                                                        checked={this.state.selectedSections.includes(each._id)}
                                                                                    />

                                                                                    <p style={{ marginLeft: 40 }}>
                                                                                        {each.name}
                                                                                    </p>



                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    )
                                                                })
                                                            }
                                                        </Collapse>
                                                    }

                                                </ListGroupItem>

                                                // <CustomInput
                                                //     onChange={() => this.handleCheckBoxChange(subject._id)}
                                                //     id={subject._id}
                                                //     key={subject._id}
                                                //     value={subject._id}
                                                //     type="checkbox"
                                                //     label={subject.name}
                                                //     checked={this.state.selectedSubjects.includes(subject._id)}
                                                // />
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>
                        </Form>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" block onClick={() => this.updatePostSubjects()}>Submit</Button>
                        <Button color="secondary" block onClick={() => this.setState({ showSubjectsEditDialog: false })}>Cancel</Button>
                    </ModalFooter>

                </Modal>

                {
                    this.state.showPostAddOrEditModal &&
                    <Modal
                        isOpen={this.state.showPostAddOrEditModal}
                        size="lg"
                    >
                        <ModalHeader>
                            Enter Post Details
                        </ModalHeader>

                        <ModalBody>

                            <TextField
                                name="name"
                                type="text"
                                hintText="Post Name"
                                style={{ display: 'block', width: '100%', marginBottom: '15px' }}
                                errorText={this.state.name ? '' : "Enter a valid post name"}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                value={this.state.name}
                            />

                            <TextField
                                name="syllabusLink"
                                type="text"
                                hintText="Link to Post Syllabus (Optional)"
                                style={{ display: 'block', width: '100%', marginBottom: '15px' }}
                                onChange={(e) => this.setState({ syllabusLink: e.target.value })}
                                value={this.state.syllabusLink}
                            />

                            <TextField
                                name="emoji"
                                type="text"
                                hintText="Add Emoji (Optional)"
                                style={{ display: 'block', width: '100%', marginBottom: '15px' }}
                                onChange={(e) => this.setState({ emoji: e.target.value })}
                                value={this.state.emoji}
                            />

                            <SelectField
                                style={{ marginLeft: 10, marginRight: 10 }}
                                value={this.state.postCategory}
                                floatingLabelText="Select post category"
                                onChange={(event, index, postCategory) => this.setState({ postCategory })}
                            >
                                <MenuItem value={"Mains"} primaryText="Mains" />
                                <MenuItem value={"Prelims"} primaryText="Prelims" />
                                <MenuItem value={"Teachers"} primaryText="Teachers" />
                                <MenuItem value={"Uniform Posts"} primaryText="Uniform Posts" />
                                <MenuItem value={"SSC"} primaryText="SSC" />
                                <MenuItem value={"Nurse"} primaryText="Nurse" />
                                <MenuItem value={"Devaswom"} primaryText="Devaswom" />
                                <MenuItem value={"Driver"} primaryText="Driver" />
                                <MenuItem value={"Others"} primaryText="Others" />
                            </SelectField>

                        </ModalBody>

                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.submitNewPost()}
                            >Submit</Button>

                            <Button
                                color="secondary"
                                onClick={() => this.setState({ showPostAddOrEditModal: false })}
                            >Cancel</Button>
                        </ModalFooter>
                    </Modal>
                }

                <Dialog
                    title="Delete Post"
                    actions={deleteActions}
                    modal={true}
                    open={this.state.showDeleteDialog}
                >
                    Are you sure you want to delete this post?
                </Dialog>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            outline
                            style={{ margin: 10 }}
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    name: '',
                                    emoji: '',
                                    syllabusLink: '',
                                    postCategory: '',
                                    showPostAddOrEditModal: true
                                })
                            }}>
                            + Add New Post
                        </Button>

                        <Button
                            outline
                            color='success'
                            style={{ margin: 10 }}
                            onClick={() => this.setState({ isSortingEnabled: !this.state.isSortingEnabled })}
                        >{this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                        </Button>
                    </div>

                    {
                        (isContentManagerOrHigher(this.props.auth.roleIds) || isSalesManagerOrHigher(this.props.auth.roleIds)) &&

                        <Checkbox
                            style={{ marginTop: 10, width: '20%' }}
                            label="Fetch Users Count"
                            checked={this.state.fetchUsersCount}
                            onCheck={(e, value) => this.setState({ fetchUsersCount: value }, () => {
                                this.getPosts()
                            })}
                        />
                    }


                </div>

                {
                    this.props.posts && this.props.posts.length > 0 ?
                        <Row>
                            <Col md="12">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='verticalAlignTop'>No.</th>
                                            <th className='verticalAlignTop'>Name</th>
                                            <th className='verticalAlignTop'>Exams</th>
                                            
                                            {
                                                this.state.fetchUsersCount ?
                                                    <>
                                                        <th className='verticalAlignTop'>Free Users</th>
                                                        <th className='verticalAlignTop'>Premium Users</th>
                                                    </>
                                                    : null
                                            }
                                            
                                            {isContentManagerOrHigher(this.props.auth.roleIds) && <th className='verticalAlignTop'>Upcoming</th>}
                                            {isContentManagerOrHigher(this.props.auth.roleIds) && <th className='verticalAlignTop'>Show in App</th>}
                                            {isContentManagerOrHigher(this.props.auth.roleIds) && <th className='verticalAlignTop'>Subjects</th>}
                                            {isContentManagerOrHigher(this.props.auth.roleIds) && <th className='verticalAlignTop'>Access Control</th>}
                                        <th className='verticalAlignTop'>Actions</th>                                            
                                        </tr>
                                    </thead>
                                    <SortableList
                                        fetchUsersCount={this.state.fetchUsersCount}
                                        isSortingEnabled={this.state.isSortingEnabled}
                                        items={this.state.postList}
                                        roleIds={this.props.auth.roleIds}
                                        onSortEnd={this.onPostsSortEnd}
                                        toggleUpcoming={(postId, value) => this.toggleUpcoming(postId, value)}
                                        toggleApproved={(postId, value) => this.toggleApproved(postId, value)}
                                        subjectButton={(post) => this.setState({
                                            editItem: post,
                                            postInitialName: post.name,
                                            postEditedName: post.name
                                        }, () => {
                                            this.setState({
                                                editingPostId: post._id,
                                                selectedSubjects: post.subjects ? post.subjects : [],
                                                selectedSections: post.sections ? post.sections : [],
                                                showSubjectsEditDialog: true
                                            })
                                        })}
                                        openAccessControl={(post) => this.openAccessEditingModal(post)}
                                        editButton={
                                            (post) => this.setState({
                                                editingPostId: post._id,
                                                name: post.name,
                                                emoji: post.emoji,
                                                syllabusLink: post.syllabusLink,
                                                postCategory: post.postCategory,
                                                showPostAddOrEditModal: true
                                            })
                                        }
                                        deleteButton={(post) => this.setState({ deleteItemId: post._id },
                                            () => this.setState({ showDeleteDialog: true }))}
                                    />

                                </Table>
                            </Col>
                        </Row> :
                        null
                }
                
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        home: state.home,
        subjects: state.data.subjects,
        posts: state.data.posts,
        languages: state.data.languages
    }
}

export default connect(mapStateToProps, { updatePosts })(Post)