import React from 'react'
import { Container, Row, Col, Button, ListGroup, ListGroupItem } from 'reactstrap'
import Axios from 'axios'
import Loader from '../Components/Loader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { copyToClipboard } from '../utils'
import { toast } from 'react-toastify'

class DuplicateQuestions extends React.Component {

    state = { questions: null }

    componentDidMount() {
        this.getDuplicates()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.skip !== this.props.match.params.skip) {
            this.getDuplicates()
        }
    }

    goNext() {
        if (this.props.match.params.skip) {
            const currentCount = parseInt(this.props.match.params.skip)
            const newCount = currentCount + 1
            this.setCount(newCount)
        } else {
            this.setCount(1)
        }
    }

    setCount(count) {
        this.props.history.push({ pathname: `/duplicate-questions/${count}` })
    }

    mergeQuestions() {
        if (window.confirm("Are you sure you want to merge all these questions?")) {
            const questionIdsToMerge = this.state.questions.map(each => each._id)
            Axios.post('/merge-questions', { questionIds: questionIdsToMerge })
                .then(res => {
                    alert("Merged questions successfully")
                    this.goNext()
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Please check console")
                })
        }
    }

    getDuplicates() {
        this.setState({ questions: null }, () => {
            Axios.get('/duplicate-questions', { params: { skip: this.props.match.params.skip } })
                .then(res => {

                    const questions = res.data.questions.map(each => {
                        const answer = each.options.find(e => e.id === each.answer)
                        return { ...each, answerText: answer.text }
                    })

                    this.setState({ questions })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Please check console.")
                })
        })
    }

    render() {
        return (
            <>

                { !this.state.questions && <Loader /> }

                <Container style={{ marginTop: 50, marginBottom: 50 }}>
                    <Row>
                        <Col>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <h4>Repeated Questions</h4>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    {/* <Button
                                        // outline
                                        color="success"
                                        onClick={() => this.mergeQuestions() }
                                        style={{ marginBottom: '1rem', marginRight: 10 }}>
                                        Merge These Questions
                                    </Button> */}
                                    <Button
                                        outline
                                        color="secondary"
                                        onClick={() => this.goNext()}
                                        style={{ marginBottom: '1rem', }}>
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                Array.isArray(this.state.questions) && this.state.questions.length > 0 ?
                                    this.state.questions.map((each, index) => {
                                        return (
                                            <ListGroup key={each._id}>
                                                <ListGroupItem>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <span style={{ fontSize: 18 }}>{index + 1}. {each.question}</span>
                                                            <br />
                                                            <span>Answer: <strong>{each.answerText}</strong></span>
                                                        </div>
                                                        <div>
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                onClick={() => {
                                                                    copyToClipboard(each._id) 
                                                                    toast("ID copied", {
                                                                        position: toast.POSITION.TOP_CENTER,
                                                                        hideProgressBar: true
                                                                        })
                                                                }}
                                                                style={{ color: 'grey', marginLeft: 5, marginRight: 5 }}
                                                                icon={faCopy} />
                                                        </div>
                                                    </div>

                                                </ListGroupItem>
                                            </ListGroup>
                                        )
                                    })
                                    :
                                    Array.isArray(this.state.questions) && this.state.questions.length === 0 ?
                                        <p>No questions found</p>
                                        :
                                        null
                            }
                        </Col>
                    </Row>
                    
                </Container>
            </>
        )
    }
}

export default DuplicateQuestions
