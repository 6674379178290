const initialState = { details: [], statistics: [] }

export default (state = initialState, action) => {
    switch (action.type) {
        case 'update_home_data':
            return {
                ...state,
                details: action.payload.details,
                statistics: action.payload.statistics,
                topStats: action.payload.topStats
            }
        default:
            return state
    }
}