import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
    Table, Button, Input, Label, Form, FormGroup, Container,
    Row, Col, Modal, ModalHeader, ModalBody, ModalFooter,
    DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { unstable_batchedUpdates } from 'react-dom'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useSelector, shallowEqual } from 'react-redux'

import Loader from '../Components/Loader'
import { cloneDeep } from 'lodash'

const CONTENT_TYPE = 'inAppMessages'

const IN_APP_ACTIONS = [
    {
        label: "Open URL",
        value: "openUrl",
    },
    {
        label: "Navigate to Route",
        value: "navigateToRoute",
    },
    {
        label: "Open Purchase Modal",
        value: "openPurchaseModal",
    }
]

function InAppMessages({ history }) {

    const [modalData, setModalData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [inAppMessages, setInAppMessages] = useState(null)
    const posts = useSelector(state => state.data.posts, shallowEqual)

    const getAllInAppMessages = () => {
        axios.get('/misc', { params: { contentType: CONTENT_TYPE } })
        .then(res => {
            if (res.data.requiredContent && Array.isArray(res.data.requiredContent.inAppMessages)) {

                const postDataAppendedInAppMessages = res.data.requiredContent.inAppMessages.map(eachInAppMessage => {
                    const foundPost = posts.find(eachPost => eachPost._id === eachInAppMessage.userPreparingPost)
                    
                    if (foundPost) {
                        eachInAppMessage.postName = foundPost.name
                    }

                    return eachInAppMessage
                })
                
                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setInAppMessages(postDataAppendedInAppMessages)
                })

            } else {
                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setInAppMessages([])
                })
            }
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            alert("Server error while fetching inApp messages")
        })
    }

    useEffect(() => {
        getAllInAppMessages()
    }, [])

    const handleInputChange = (e) => {
        setModalData({ ...modalData, [e.target.name]: e.target.value })
    }

    const submitForm = () => {

        const { imageLink, targetUsers, userPreparingPost, displayCount } = modalData

        if (!imageLink || !targetUsers || !displayCount) {
            return alert("Please enter all required data")
        }

        if (!userPreparingPost || userPreparingPost === 'all') {
            delete modalData.userPreparingPost
        }

        if (modalData._id) {
            // Editing existing in app message
            axios.post('/misc', {
                contentType: CONTENT_TYPE,
                contentId: modalData._id,
                value: modalData,
            })
            .then(res => {
                setModalData(null)
                getAllInAppMessages()
            })
            .catch(err => {
                alert("Server error while editing inApp message")
                console.log(err)
            })

        } else {
            // Adding new 
            axios.put('/misc', { contentType: CONTENT_TYPE, value: modalData })
            .then(res => {
                setModalData(null)
                getAllInAppMessages()
            })
            .catch(err => {
                alert("Server error while create new inApp message")
                console.log(err)
            })
        }

    }

    const deletePack = (deletingPack) => {
        setIsLoading(true)

        axios.post('/misc', {
            contentType: CONTENT_TYPE,
            contentId: deletingPack._id,
            value: {
                ...deletingPack,
                activeFlag: deletingPack.activeFlag ? false : true
            }
        })
            .then(() => {
                getAllInAppMessages()
            })
            .catch(err => {
                alert("Server error while editing inApp message")
                console.log(err)
            })
    }

    return (
        <>
            {isLoading && <Loader />}
            <Container fluid style={{ marginTop: 50 }}>

                {
                    modalData != null ?
                        <Modal isOpen={modalData != null}>
                            <ModalHeader>
                                Enter InApp Message Data
                            </ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label>Image Link</Label>
                                        <Input
                                            placeholder="Enter Image Link"
                                            type="text"
                                            name="imageLink"
                                            value={modalData.imageLink || ''}
                                            onChange={handleInputChange}
                                            style={{ marginBottom: 20 }}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <UncontrolledDropdown>
                                            <Label>In App Action</Label>
                                            <br />
                                            <DropdownToggle caret color="primary">
                                                {
                                                    (modalData && modalData.inAppAction && IN_APP_ACTIONS.find(each => each.value === modalData.inAppAction))
                                                    ?
                                                    IN_APP_ACTIONS.find(each => each.value === modalData.inAppAction).label
                                                    :
                                                    "Select Action"
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    IN_APP_ACTIONS.map((each, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={each.value}
                                                                value={each.value}
                                                                onClick={() => setModalData({ ...modalData, inAppAction: each.value })}
                                                            >
                                                                {each.label}
                                                            </DropdownItem>)
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </FormGroup>

                                    {
                                        modalData.inAppAction === "navigateToRoute" ?
                                            <>
                                                <FormGroup>
                                                    <Label>Route Name</Label>
                                                    <Input
                                                        placeholder="Enter Route Name"
                                                        type="text"
                                                        name="routeName"
                                                        value={modalData.routeName || ''}
                                                        onChange={handleInputChange}
                                                        style={{ marginBottom: 20 }}

                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>Route Param Name (Optional)</Label>
                                                    <Input
                                                        placeholder="Enter Route Param Name"
                                                        type="text"
                                                        name="routeParamName"
                                                        value={modalData.routeParamName || ''}
                                                        onChange={handleInputChange}
                                                        style={{ marginBottom: 20 }}
                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>Route Param Value (Optional)</Label>
                                                    <Input
                                                        placeholder="Enter Route Param Value"
                                                        type="text"
                                                        name="routeParamValue"
                                                        value={modalData.routeParamValue || ''}
                                                        onChange={handleInputChange}
                                                        style={{ marginBottom: 20 }}
                                                    />
                                                </FormGroup>
                                            </>
                                            : modalData.inAppAction === "openUrl" ?
                                                <FormGroup>
                                                    <Label>URL to Open</Label>
                                                    <Input
                                                        placeholder="Enter URL to Open"
                                                        type="text"
                                                        name="urlToOpen"
                                                        value={modalData.urlToOpen || ''}
                                                        onChange={handleInputChange}
                                                        style={{ marginBottom: 20 }}

                                                    />
                                                </FormGroup>
                                                : null
                                    }

                                    <hr />

                                    <FormGroup>
                                        <Label>Target Users</Label>
                                        <Input
                                            type="select"
                                            name="targetUsers"
                                            id="targetUsers"
                                            value={modalData.targetUsers || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select target users</option>
                                            <option value="allUsers">All Users</option>
                                            <option value="freeUsers">Free Users</option>
                                            <option value="premiumUsers">Premium Users</option>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>User Preparing Post</Label>
                                        <Input
                                            type="select"
                                            name="userPreparingPost"
                                            id="userPreparingPost"
                                            value={modalData.userPreparingPost || ''}
                                            onChange={handleInputChange}
                                        >
                                            {
                                                [
                                                    { _id: 'all', name: "All Posts" },
                                                    ...posts,
                                                ].map(each => (
                                                    <option
                                                        value={each._id}
                                                        key={each._id}
                                                    >
                                                        {each.name}
                                                    </option>
                                                ))
                                            }
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Display Per Day</Label>
                                        <Input
                                            placeholder="Enter how many times to show per day to a user"
                                            type="number"
                                            name="displayCount"
                                            value={modalData.displayCount || ''}
                                            onChange={handleInputChange}
                                            style={{ marginBottom: 20 }}
                                        />
                                    </FormGroup>
                                    
                                </Form>

                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={submitForm}>Submit</Button>
                                <Button outline onClick={() => setModalData(null)}>Close</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                <Row>
                    <Col>
                        <Button
                            color={"primary"}
                            onClick={() => setModalData({})}
                        >+ New InApp Message</Button>
                    </Col>
                </Row>

                {
                    (Array.isArray(inAppMessages) && inAppMessages.length > 0) ?
                        <Row style={{ marginTop: 50 }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Image Link</th>
                                        <th>Action</th>
                                        {/*
                                            <th>Route Param Name</th>
                                            <th>Route Param Value</th>
                                        */}
                                        <th>Value</th>
                                        <th>Target Users</th>
                                        <th>Preparing PostID</th> 
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inAppMessages.map((each, index) => (
                                            <tr
                                                key={each._id}
                                                style={{ backgroundColor: each.activeFlag ? 'white' : "rgba(255,0,0,0.3)" }}
                                                
                                            >
                                                <td>{index + 1}</td>
                                                <td>{each.imageLink}</td>
                                                <td>{each.inAppAction}</td>
                                                
                                                {
                                                    (each.inAppAction === IN_APP_ACTIONS[0].value) ?
                                                        <td>{each.urlToOpen}</td>
                                                        : (each.inAppAction === IN_APP_ACTIONS[1].value) ?
                                                            <td>
                                                                {each.routeName}<br/>
                                                                {each.routeParamName}<br/>
                                                                {each.routeParamValue}
                                                            </td>
                                                            : <td>True</td>
                                                }
                                                
                                                <td>{each.targetUsers}</td>
                                                <td>
                                                    {each.postName ? each.postName
                                                        : each.userPreparingPost ? each.userPreparingPost
                                                            : 'All Posts'
                                                    }
                                                </td>
                                                <td>
                                                    <Button
                                                        style={{ marginRight: 10 }}
                                                        outline
                                                        color="primary"
                                                        onClick={ () => setModalData(cloneDeep(each)) }
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            icon={faPen}
                                                        />
                                                    </Button>
                                                    <Button
                                                        style={{ marginRight: 10 }}
                                                        outline
                                                        color="danger"
                                                        onClick={ () => deletePack(each) }
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            icon={faTrash}
                                                        />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </Table>
                        </Row>
                        : null
                }

                
            </Container>
        </>
    )
}

export default InAppMessages
