import { useRef, useEffect } from 'react'
import { store } from '../store'
import { cloneDeep } from 'lodash'

export const copyToClipboard = (copyString) => {
    var textField = document.createElement('textarea')
    textField.innerText = copyString
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
}

export const copyIdsToClipboard = (arrayOfIds) => {

    let string = ''
    const questionIdArray = cloneDeep(arrayOfIds)
    
    questionIdArray.forEach(item => {
        if (string) {
            string = `${string}\n${item}`
        } else {
            string = item
        }
    })

    var textField = document.createElement('textarea')
    textField.innerHTML = string
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()

}

// check if the given id has a valid ObjectId form.
export const isValidObjectId = function (id) {

	// check first if undefined
	if (!id) {
		return false
	}

	// check if id is a valid string
	if (typeof id !== 'string') {
		id = id.toString()
	}

	// simply match the id from regular expression
	if (id.match(/^[0-9a-fA-F]{24}$/)) {
		return true
	} else {
		return false
	}
}

export const addExtraDetailsWithQuestions = (questions) => {
    
    let state = store.getState()

    let stateObj = {
        posts: state.data.posts,
        pathUnits: state.data.pathUnits,
        packs: state.data.packs,
        subjects: [{ name: 'All', _id: "1" }].concat(state.data.subjects),
    }

    if (Array.isArray(questions)) {

        questions = questions.map((eachQuestion) => {

            let returnData = eachQuestion
            returnData.pathNames = []

            // 1. Add Pack - Subject - Topic name with each question
            stateObj.pathUnits.forEach(eachPath => {
                eachPath.units.forEach(eachUnit => {
                    eachUnit.topics.forEach((eachTopic) => {
                        if (
                            Array.isArray(eachTopic.questions) &&
                            eachTopic.questions.includes(eachQuestion._id)
                        ) {

                            const foundPack = stateObj.packs.find(eachPack => eachPack.postId === eachUnit.postId)

                            if (foundPack) {

                                const pushStr = `${foundPack.title} - ${eachUnit.unitName} - ${eachTopic.name}`
    
                                if (!returnData.pathNames.includes(pushStr)) {
    
                                    if (Array.isArray(returnData.pathNames)) {
                                        returnData.pathNames.push(pushStr)
                                    }
    
                                    returnData.packId = foundPack._id
                                    returnData.postId = foundPack.postId
                                    returnData.unitsId = eachUnit._id
                                    returnData.topicId = eachTopic._id
    
                                }
                            }

                        }
                    })
                })
            })

            // 2. Add exam name with each question
            stateObj.posts.forEach(eachPost => {
                eachPost.exams.forEach(eachExam => {

                    if (
                        eachExam.examType === "mock" &&
                        eachExam.active &&
                        Array.isArray(eachExam.questions) &&
                        eachExam.questions.includes(eachQuestion._id)
                    ) {

                        let pushStr = `${eachPost.name} - ${eachExam.description}`

                        if (Array.isArray(returnData.examNames) && !returnData.examNames.includes(pushStr)) {
                            returnData.examNames.push(pushStr)
                        } else {
                            returnData.examNames = [pushStr]
                        }
                        
                    }
                })

            })

            // 3. Add Subject, Section name and subtitle with each question
            returnData.subjectName = "Not found"
            returnData.sectionName = "Not found"

            const foundSubjectIndex = stateObj.subjects.findIndex(eachSubject => eachSubject._id === eachQuestion.subjectId)

            if (foundSubjectIndex >= 0) {
                
                returnData.subjectName = stateObj.subjects[foundSubjectIndex].name

                if (stateObj.subjects[foundSubjectIndex].subtitle) {
                    returnData.subtitle = stateObj.subjects[foundSubjectIndex].subtitle
                }

                const foundSectionIndex = stateObj.subjects[foundSubjectIndex].sections.findIndex(eachSection => eachSection._id === eachQuestion.sectionId)
                if (foundSectionIndex >= 0) {
                    returnData.sectionName = stateObj.subjects[foundSubjectIndex].sections[foundSectionIndex].name
                }
            }
            
            return returnData

        })
    }
    
    return questions

}

export const dropDownPeriods = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last Seven Days", value: "lastSevenDays" },
    { label: "This Month", value: "thisMonth" },
    { label: "Last Month", value: "lastMonth" },
    { label: "All time", value: "allTime" },
    { label: "Custom Time", value: "customTime" },
]

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


export const applyColorToHtmlQuestion = (questionText) => {
    
    const tempHtml = document.createElement('div');
    tempHtml.innerHTML = questionText;

    const elementsToApplyColorTo = [
        'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'td', 'ul', 'ol'
    ]

    elementsToApplyColorTo.forEach(eachElement => {
        tempHtml.querySelectorAll(eachElement).forEach(eachElem => {
            eachElem.style.color = 'rgb(255,255,255)'
        })
    })

    const replacedRgbValues = tempHtml.innerHTML.split("rgb(255, 255, 255)").join("rgb(255,255,255)")
    const returnString = replacedRgbValues.split("#ffffff").join("rgb(255,255,255)")

    return returnString

}

export const fixSpacesBetweenStrings = (string) => {
    if (string && string.replace) {
        return string.replace(/\s{2,}/g, ' ')
    } else {
        return string
    }
}

export const addOrRemoveItem = (array, item) => {
        
    let clonedArray = cloneDeep(array)
    
    const foundIndex = clonedArray.findIndex((each) => each === item)

    if (foundIndex > -1) {
        clonedArray = clonedArray.filter(each => each !== item)
    } else {
        clonedArray.push(item)
    }

    return clonedArray

}

export const msToTime = (duration) => {
    
    var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds

}

export const formatTime = (time) => {

    // Hours, minutes and seconds
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;

}

export const snakeCaseToTitleCase = (string) => {
    const result = string.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

export const camelCaseToTitleCase = (string) => {
    const result = string.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

export const commafy = (num) => {

    if (num) {

        var str = num.toString().split('.')
    
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
    
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
    
        return str.join('.')

    } else {
        return num
    }
    
}