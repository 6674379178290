import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { unstable_batchedUpdates } from 'react-dom'

import Loader from '../Components/Loader'
import { months } from '../utils/constants'
import { isFinite } from 'lodash'

function ByteQuizLeaderboard() {

    const fetchedData = useRef(false)

    const [leaderboard, setLeaderboard] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [skipCount, setSkipCount] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const [filterYear, setFilterYear] = useState("All")
    const [filterMonth, setFilterMonth] = useState("All")

    useEffect(() => {
        fetchByteQuizLeaderBoard()
    }, [])

    useEffect(() => {
        if (fetchedData.current) {
            fetchByteQuizLeaderBoard()
        }
    },[skipCount])

    useEffect(() => {
        if (fetchedData.current) {
            fetchByteQuizLeaderBoard()
        }
    },[filterYear])

    useEffect(() => {
        if (fetchedData.current) {
            fetchByteQuizLeaderBoard()
        }
    },[filterMonth])

    const fetchByteQuizLeaderBoard = () => {

        setIsLoading(true)
        axios.get('/bytequiz/byte-quiz-leaderboard', { params: { skipCount, filterYear, filterMonth } })
        .then(res => {
            unstable_batchedUpdates(() => {
                fetchedData.current = true
                setLeaderboard(res.data.quizLeaderBoard)
                setTotalCount(res.data.totalCount)
                setIsLoading(false)
            })
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
            alert("server error")
        })

    }

    const renderSkipMenu = () => {

        let returnArray = []

        let itemCount = parseInt(totalCount, 10) / 25

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => {
                        unstable_batchedUpdates(() => {
                            setSkipCount(index*25)
                            setIsLoading(true)
                        })
                    }}
                    >
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray

    }


    return (
        <Container fluid>

            { isLoading && <Loader /> }

            <Row style={{flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h3 style={{margin: 20}}>
                    Byte Quiz Leaderboard
                </h3>
                
                <Row>

                    <UncontrolledDropdown style={{ marginRight: 20 }}>
                        <DropdownToggle caret color="primary" id="dropdown-basic">Year: {filterYear}</DropdownToggle>
                        <DropdownMenu className="addScroll">
                            <DropdownItem
                                value={'All'}
                                onClick={() => setFilterYear('All')}
                            >All</DropdownItem>
                            {
                                [2018, 2019, 2020, 2021, 2022, 2023].map(each => (
                                    <DropdownItem
                                        value={each}
                                        onClick={() => setFilterYear(each)}
                                    >{each}</DropdownItem>
                                ))
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown style={{ marginRight: 20 }} disabled={true}>
                        <DropdownToggle caret color="primary" id="dropdown-basic" disabled={!isFinite(filterYear)}> 
                            Month: {months[filterMonth] ? months[filterMonth] : "All"}
                        </DropdownToggle>
                        <DropdownMenu className="addScroll">
                            <DropdownItem
                                value={'All'}
                                onClick={() => setFilterMonth('All')}>
                                All
                            </DropdownItem>
                            {
                                months.map((each, index) => (
                                    <DropdownItem
                                        value={index}
                                        onClick={() => setFilterMonth(index)}
                                    >{months[index]}</DropdownItem>
                                ))
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown style={{ marginRight: 20 }}>
                        <DropdownToggle caret color="success" id="dropdown-basic"> Page{((skipCount / 25) + 1).toFixed(0)}  </DropdownToggle>
                        <DropdownMenu className="addScroll">
                            {renderSkipMenu()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Row>
            </Row>

            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Created By</th>
                                <th>Approved</th>
                                <th>Rejected</th>
                                <th>Waiting Approval</th>
                                <th>Not Submitted</th>
                                <th>Total Quiz Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                leaderboard && leaderboard.length > 0 && leaderboard.map((each, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/bytequiz?userId=${each._id}`}
                                                    target="_blank"
                                                >
                                                    {each.creatorName}
                                                </Link>
                                            </td>
                                            <td>{each.approvedCount}</td>
                                            <td>{each.rejectedCount}</td>
                                            <td>{each.awaitingApprovalCount}</td>
                                            <td>{each.savedCount}</td>
                                            <td>{each.totalQuizCount}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default ByteQuizLeaderboard;
