import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import {
    Col,
    Row,
    Table,
    Nav,
    NavItem,
    NavLink,
    Container,
    TabContent,
    TabPane,
    ListGroup,
    ListGroupItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import Aux from '../Components/Auxiliary'
import Button from 'reactstrap/lib/Button';

const reportTypes = [
    {
        id: "2",
        label: "Wrong Answer",
        message: "Showing: Wrong answers"
    },
    {
        id: "1",
        label: "Typo",
        message: "Showing: Typo in question or answer"
    },
    {
        id: "3",
        label: "Others",
        message: "Showing: Other reports"
    }
]

export default function CurrentAffairsReported(props) {

    const [page, setPage] = useState(0)
    const [totalReportedCount, setTotalReportedCount] = useState(0)
    const [noteEditDetails, setNoteEditDetails] = useState(null)
    const [activeTab, setActiveTab] = useState("2")
    const [currentIndex, setCurrentIndex] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [reportedData, setReportedData] = useState(null)
    const [userDetails, setUserDetails] = useState([])

    const fetchCaReportedDetails = (issueType) => {
        setIsLoading(true)
        axios.get('/reported-ca-notes', { params: { skipCount: page * 50, type: issueType } })
            .then(res => {
                setReportedData(res.data.reportedCaNotes)
                setTotalReportedCount(res.data.totalReportedCount)
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
                alert("Error, please check console")
            })
    }

    const editNotes = () => {
        props.history.push({
            pathname: `/daily-challenges`,
            search: `dailyChallengeId=${noteEditDetails.dailyChallengeId}&noteId=${noteEditDetails.noteId}`,
        })
    }

    const renderSkipMenu = () => {

        let returnArray = []
        let itemCount = totalReportedCount / 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => setPage(index)}>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    const getUser = (index) => {
        let userIds = reportedData[index].userExplanation.map(item => item.userId)

        axios.get(`/get-users-details`, { params: { userIds: userIds, requiredDetails: { name: 1, mobile: 1 } } })
            .then((response) => { setUserDetails(response.data.usersDetails) })
            .catch((error) => {
                console.log(error.response)
                alert("Error. Please check console")
            })
    }

    useEffect(() => {
        fetchCaReportedDetails(parseInt(activeTab))
    }, [activeTab, page])

    useEffect(() => {
        if (noteEditDetails) {
            editNotes()
        }
    }, [editNotes, noteEditDetails])

    return (
        <Container fluid>

            <Row style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
                <h4>Current Affairs Reported</h4>
                <Col md="1">
                    <UncontrolledDropdown>
                        <DropdownToggle caret block color="success" id="dropdown-basic"> Page {page + 1} </DropdownToggle>
                        <DropdownMenu className="addScroll">
                            {renderSkipMenu()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
            </Row>

            <Nav tabs>
                {reportTypes.map((eachType) => {
                    return (
                        <NavItem key={eachType.id}>
                            <NavLink className={(activeTab === eachType.id) ? 'active' : ''}
                                onClick={() => { 
                                    setActiveTab(eachType.id) 
                                    setCurrentIndex(null)
                                }}>
                                {eachType.label}
                            </NavLink>
                        </NavItem>
                    )
                })}
            </Nav>

            <TabContent activeTab={activeTab}>
                {reportTypes.map(eachType => {
                    return (
                        <TabPane
                            key={eachType.id}
                            tabId={eachType.id}
                            style={{ overflowY: 'scroll', paddingBottom: 50 }}
                        >
                            <Row>
                                {reportedData && reportedData.length > 0 ?
                                    <Col>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Note</th>
                                                    <th>Count</th>
                                                    <th>User Explanation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    reportedData.map((item, index) => {
                                                        return (
                                                            <Aux key={item._id}>
                                                                <tr key={item._id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.reportedCaNote.title}</td>
                                                                    <td>{item.count}</td>
                                                                    <td>
                                                                        <Button
                                                                            color="primary"
                                                                            outline
                                                                            disabled={!item.userExplanation}
                                                                            onClick={() => {
                                                                                setUserDetails(null)
                                                                                if (currentIndex === index) {
                                                                                    setCurrentIndex(null)
                                                                                } else {
                                                                                    setCurrentIndex(index)
                                                                                }
                                                                            }}
                                                                            style={{ marginBottom: '1rem' }}
                                                                        >
                                                                            {(currentIndex !== null && currentIndex === index) ? "Hide Explanation" : "Show Explanation"}
                                                                        </Button>
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setNoteEditDetails({ dailyChallengeId: item.currentAffairsId, noteId: item.noteId })
                                                                            }}
                                                                            color='link'
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    (currentIndex === index && Array.isArray(item.userExplanation) && item.userExplanation.length > 0) &&
                                                                    <tr>
                                                                        <td colSpan={11}>
                                                                            <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: 10 }}>
                                                                                <Button outline color='primary' onClick={() => getUser(index)}>Get Users</Button>
                                                                            </div>
                                                                            <ListGroup>
                                                                                {
                                                                                    item.userExplanation.map(item => {
                                                                                        if (userDetails) {
                                                                                            userDetails.forEach(each => {
                                                                                                if (each._id === item.userId) {
                                                                                                    item.name = each.name
                                                                                                    item.mobileNumber = each.mobile
                                                                                                }
                                                                                            })
                                                                                        }

                                                                                        return (
                                                                                            <ListGroupItem key={item._id}>
                                                                                                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                                                                    <div>{item.explanation}</div>
                                                                                                    {item.userReportedDate ? <div>Date: {moment(item.userReportedDate).format("D MMM YYYY, h:mm:ss a")}</div> : null}
                                                                                                    {reportedData ?
                                                                                                        <div style={{ display: 'flex' }}>
                                                                                                            <div>{`Name :`}</div>
                                                                                                            <strong>{item.name},</strong>
                                                                                                            <div style={{ marginLeft: 5 }}>{`Mobile :`}</div>
                                                                                                            <strong>{item.mobileNumber}</strong>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </div>
                                                                                            </ListGroupItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ListGroup>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </Aux>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                    :
                                    !isLoading && Array.isArray(reportedData) && reportedData.length === 0 ?
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <h4 style={{ textAlign: 'center', marginTop: 50 }}>No Data Found</h4>
                                        </div>
                                        : null

                                }
                            </Row>
                        </TabPane>)
                })}
            </TabContent>
        </Container>
    )
}