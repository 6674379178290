import React from 'react'
import Axios from 'axios'
import { Button, Container, Table } from 'reactstrap'
import Loader from '../Components/Loader'

class RedisKeys extends React.Component {

    state = {
        redisKeys:null,
        isLoading: true
    }

    deleteRedisKey(keyName, selectedKeyIndex) {
        if (window.confirm(`Are you sure you want to delete the key named ${keyName}?`)) {
            Axios.delete('/redis-key', { params: { key: keyName } })
            .then(res => {
                this.setState((oldState) => {
                    const itemsAfterRemovingDeletedKey = oldState.redisKeys.filter((each, index) => index !== selectedKeyIndex)
                    return { redisKeys:itemsAfterRemovingDeletedKey, isLoading: false}
                })
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
        }
    }

    getRedisKeys() {
        Axios.get('/redis-keys')
            .then(res => this.setState({ redisKeys:res.data, isLoading: false }))
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })


    }
    componentDidMount() {
        this.getRedisKeys()
    }

    render() {
        
        return (
            <>
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}
                <Container style={{ paddingTop: 50 }}>
                    {Array.isArray(this.state.redisKeys) && this.state.redisKeys.length > 0 ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>Key Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.redisKeys.map((each, index) => {
                                        return (
                                            <tr key={each}>
                                                <td>{each}</td>
                                                <td>
                                                    <Button
                                                        color="outline-danger"
                                                        onClick={() => this.setState({ isLoading: true }, () => this.deleteRedisKey(each, index))}
                                                    > Delete </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        : Array.isArray(this.state.redisKeys) && this.state.redisKeys.length === 0 ?
                            <p style={{ marginTop: 50 }}>Found no redis keys, please check again</p>
                            :
                            null
                    }
                </Container>
            </>
                    
            )}
}


export default RedisKeys