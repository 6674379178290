import React, { Component } from 'react'
import { FlatButton, Dialog, TextField, SelectField, MenuItem, RaisedButton, DatePicker } from 'material-ui'
import axios from 'axios'
import moment from 'moment'
import { connect } from 'react-redux'
import './users.css'
import FileDownload from 'js-file-download'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faAppleAlt, faBell, faPen, faTimes, faCoins, faRupeeSign, faSpinner, faPlay, faTrash, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Input, Modal,
        ModalHeader, ModalBody, ModalFooter, Form,Row, Col,Container, Table,
        DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
    } from 'reactstrap';
import queryString from 'query-string';
import CheckBox from 'material-ui/Checkbox';
import Loader from '../../Components/Loader'
import { Link } from 'react-router-dom'
import  { withRouter } from 'react-router-dom'
import { cloneDeep, groupBy, isObject } from 'lodash';
import UserPackProgress from '../../Components/UserPackProgress'
import { snakeCaseToTitleCase } from '../../utils'
import { isAdmin } from '../../utils/dashboardRoles'

const CancelToken = axios.CancelToken;
let cancel = null;

const ProBadge = ({ paymentDetails }) => {

    if (paymentDetails && paymentDetails.length > 0) {

        const latestPayment = paymentDetails[paymentDetails.length - 1]

        if (latestPayment.paymentType && latestPayment.paymentType) {
            if (latestPayment.paymentType && (latestPayment.paymentType === 'im' || latestPayment.paymentType === "rzp")) {
                return <span className="proBadge realCash"> <FontAwesomeIcon icon={faRupeeSign} /> </span>
            } else if (latestPayment.paymentType && latestPayment.paymentType === 'inApp') {
                return <span className="proBadge realCash"> <FontAwesomeIcon icon={faPlay} /> </span>
            } else if (latestPayment.paymentType && latestPayment.paymentType === 'iosApp') {
                return <span className="proBadge realCash"> <FontAwesomeIcon icon={faAppleAlt} /> </span>
            } else if (latestPayment.paymentType && latestPayment.paymentType === 'direct') {
                return <span className="proBadge"> <FontAwesomeIcon icon={faBuilding} /> </span>
            } else if (latestPayment.paymentType && latestPayment.paymentType === 'trial') {
                return <span className="proBadge"> <FontAwesomeIcon icon={faSpinner} /> </span>
            } else {
                return <span className="proBadge"> <FontAwesomeIcon icon={faCoins} /> </span>
            }
        } else {
            return <span className="proBadge errorCash"> <FontAwesomeIcon icon={faTimes} /> </span>
        }

    } else {
        return <span className="proBadge errorCash"> <FontAwesomeIcon icon={faTimes} /> </span>
    }

}

// const timeSlotArray = [
//     "0:00", "0:30", "1:00", "1:30", "2:00", "2:30", "3:00", "3:30", "4:00", "4:30", "5:00", "5:30", "6:00", "6:30",
//     "7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30",
//     "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30",
//     "22:00", "22:30", "23:00", "23:30",
// ]


const dateArray = []

for (let i = 0; i < 366; i ++) {
    let date = moment().subtract(365 - i, 'days').format('D MMM YYYY')
    dateArray.push({ active: false, date })
}

const level0 = "#ebedf0"
const level1 = "#e7fcbd"
const level2 = "#c6e48b"
const level3 = "#7bc96f"
const level4 = "#239a3b"
const level5 = "#196127"

const TABLE_PANE_GREY = '#868686'

class ViewBots extends Component {

    state = {
        isLoading: false,
        addBotDialog: false, submitError: '',
        mobileSearch: '',
        mobile: null, name: null, district: null,
        formErrors: { mobile: null, name: null, district: null },

        editBotDialog: false, editSubmitError: '',
        editBot: { mobile: null, name: null, district: null },
        editFormErrors: { mobile: null, name: null, district: null },

        botDeleteDialog: false, showBotDeleteSuccessDialog: false,

        notificationDialog: false, notificationSuccessDialog: false,

        allBots: null,

        userCount: 0,

        notificationHeader: '', notificationBody: '',
        showSearchBox: false,
        // userType: 3, premiumUsers: false,

        premiumUsers: false, userType: 3, skipCount: 0,
        referredUsers: false,

        activeTab: '1', enableEditing: false, showAddPaymentDetails: false,

        availablePacks: []

    }

    componentDidMount() {
        
        const { state } = this.props.location

        if (state && state.mobileNumber) {
            this.setState({ mobileSearch: state.mobileNumber }, () => {
                this.fetchBots(true)
            })
        } 

        const { userId } = queryString.parse(this.props.location.search)

        if (userId) {
            this.fetchBots(true)
        }

        let params = queryString.parse(this.props.location.search)
 
        if (params.postId) {
            this.setState({premiumUsers: true}, () => {
                this.fetchBots(true)
            })
        }

    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleMenuChange = (name, value) => { this.setState({ [name]: value }) }

    handleEditInput = (e) => {
        let newBot = this.state.editBot
        newBot[e.target.name] = e.target.value
        this.setState({ editBot: newBot })
    }

    handleScoerChange = (e, name) => {
        let newBot = this.state.editBot
        newBot.scoreData[name] = e.target.value
        this.setState({ editBot: newBot })
    }

    handleEditMenuChange = (name, value) => {
        let newBot = this.state.editBot
        newBot[name] = value
        this.setState({ editBot: newBot })
    }

    submitBot() {

        this.setState({ formErrors: { mobile: '', name: '', district: '' } })

        if (this.state.mobile && this.state.mobile.length === 10 && this.state.name && this.state.district) {
            axios.post(`/auth/register`, { mobile: this.state.mobile, name: this.state.name, district: this.state.district, isBot: true })
                .then((response) => {
                    this.setState({
                        addSuccess: true,
                        mobile: '', name: '', district: '',
                        formErrors: { mobile: '', name: '', district: '' }
                    })
                    this.fetchBots()
                })
                .catch((err) => {
                    this.setState({ submitError: err.response.data.message })
                })
        } else if (!this.state.mobile) {
            let formErrors = this.state.formErrors
            formErrors.mobile = "Please enter a valid mobile number"
            this.setState({ formErrors })
        } else if (this.state.mobile.length !== 10) {
            let formErrors = this.state.formErrors
            formErrors.mobile = "Mobile number should be atleast 10 characters long"
            this.setState({ formErrors })
        } else if (!this.state.name) {
            let formErrors = this.state.formErrors
            formErrors.name = "Please enter a valid user name"
            this.setState({ formErrors })
        } else if (!this.state.district) {
            let formErrors = this.state.formErrors
            formErrors.district = "Please chose a district"
            this.setState({ formErrors })
        } else {
            console.log("Reached Else. What Happened? :o")
        }
    }

    fetchBots = (getCount) => {
        let sendObject = { skipCount: this.state.skipCount, userType: this.state.userType }

        if (this.state.mobileSearch) sendObject.mobileSearch = this.state.mobileSearch
        if (this.state.coinSearch) sendObject.coinSearch = this.state.coinSearch
        if (this.state.nameSearch) sendObject.nameSearch = this.state.nameSearch
        if(this.state.userIdSearch) sendObject.userIdSearch = this.state.userIdSearch
        if (this.state.joiningDate) sendObject.joiningDate = this.state.joiningDate
        if (this.state.premiumUsers) {
            sendObject.premiumUsers = this.state.premiumUsers
            sendObject.userType = 3;
        }
        if (this.state.referredUsers) { sendObject.referredUsers = this.state.referredUsers }

        const {userId} = queryString.parse(this.props.location.search)
        
        if(userId) {
            sendObject.userId = userId
        }
        const params = queryString.parse(this.props.location.search)
        
        if(params.postId) {
            sendObject.postId = params.postId
        }


        if (cancel) {
            cancel()
        }

        this.setState({ allBots: [], isLoading: true }, () => {

            let params = sendObject
            if (getCount) { params.getCount = true }
            axios.get(`/fetchBots`, { params, cancelToken: new CancelToken((c) => { cancel = c }) })
                .then(res => {

                    let setObject = {
                        isLoading: false,
                        botDeleteDialog: false,
                        allBots: res.data.data.bots,
                    }

                    cancel = null

                    if (getCount) {
                        setObject.userCount = res.data.data.userCount
                    }

                    setObject.availablePacks = res.data.data.availablePacks

                    this.setState(setObject)

                }).catch(err => {
                    if (!axios.isCancel(err)) {
                        alert("Error, please check console")
                        console.log(err)
                    }
                })
        })

    }

    editBot() {

        const { _id, mobile, name, district, premiumUser, devicesList, settings } = this.state.editBot
        const { score, coins, level, gamesWon, gamesLost, gamesTied } = this.state.editBot.scoreData

        if (score === undefined || coins === undefined || level === undefined || gamesWon === undefined || gamesLost === undefined || gamesTied === undefined) {
            return alert("Please enter all score details")
        }

        if (_id && mobile && mobile.length === 10 && name && district) {

            let sendObject = {
                botId: _id, mobile, name, district, premiumUser,
                scoreData: this.state.editBot.scoreData, devicesList,
                settings
            }

            axios.post(`/editBot`, sendObject)
                .then(response => {
                    this.fetchBots()
                    this.setState({ editSuccess: true, editSubmitError: '', enableEditing: false, completedBatchModulesArr:'', completedLearnModulesArr: '' })
                })
                .catch(err => {
                    console.log(err)
                    if (err && err.response && err.response.data) {
                        this.setState({ editSubmitError: err.response.data.message, completedBatchModulesArr:'', completedLearnModulesArr: '' });
                    } else {
                        alert("Error. Check console")
                    }
                })

        } else if (!mobile) {
            let editFormErrors = this.state.editFormErrors
            editFormErrors.mobile = "Please enter a valid mobile number"
            this.setState({ editFormErrors, completedBatchModulesArr:'', completedLearnModulesArr: '' })
        } else if (mobile.length !== 10) {
            let editFormErrors = this.state.editFormErrors
            editFormErrors.mobile = "Mobile number should be atleast 10 characters long"
            this.setState({ editFormErrors, completedBatchModulesArr:'', completedLearnModulesArr: '' })
        } else if (!name) {
            let editFormErrors = this.state.editFormErrors
            editFormErrors.name = "Please enter a valid user name"
            this.setState({ editFormErrors, completedBatchModulesArr:'', completedLearnModulesArr: '' })
        } else if (!district) {
            let editFormErrors = this.state.editFormErrors
            editFormErrors.district = "Please chose a district"
            this.setState({ editFormErrors, completedBatchModulesArr:'', completedLearnModulesArr: '' })
        } else {
            console.log("Edit Bot Reached Else. What Happened? :o")
            this.setState({ completedBatchModulesArr:'', completedLearnModulesArr: '' })
        }
    }

    deleteBot() {
        axios.post(`/deleteBot`, { botId: this.state.deleteBotId })
            .then(response => this.fetchBots())
            .catch(err => console.log(err))
    }

    sendNotification() {

        if (this.state.notifyBot && this.state.notificationHeader && this.state.notificationBody) {
            axios.post('/send-notification', { userId: this.state.notifyBot._id, header: this.state.notificationHeader, body: this.state.notificationBody })
                .then(res => {
                    this.setState({ notificationHeader: '', notificationBody: '', notificationDialog: true, notificationSuccessDialog: true })
                })
                .catch(err => {
                    alert('Error sending notification')
                    console.log(err)
                })
        } else {
            alert('Enter notification header and body')
        }

    }

    renderSkipMenu = () => {

        let returnArray = []

        let itemCount = parseInt(this.state.userCount, 10) / 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(<DropdownItem key={index} value={index} onClick={() => this.setState({ skipCount: index }, () => this.fetchBots())} >{index + 1}</DropdownItem>)
        }
        return returnArray

    }

    getTotalAcivity(total) {
        let topMost = Object.keys(total).sort((a, b) => total[b] - total[a])
        return total[topMost[0]]
    }

    getColorAndOpacity(value) {

        if (!value) { return level0 }

        let interval = this.state.totalActivity / 5

        if ((value > 0) && (value < interval * 1)) {
            return level1
        } else if ((value > interval * 1) && (value < interval * 2)) {
            return level2
        } else if ((value > interval * 2) && (value < interval * 3)) {
            return level3
        } else if ((value > interval * 3) && (value < interval * 4)) {
            return level4
        } else if ((value > interval * 4)) {
            return level5
        } else {
            return level0;
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChangeDate = (event, joiningDate) => {
        this.setState({ joiningDate });
    };

    generatePremiumCSV(url) {
        axios.get(url)
            .then(res => FileDownload(res.data, 'report.csv'))
            .catch(err => console.log(err))
    }

    renderTable(sortedSections) {
        return Object.keys(sortedSections).map(eachKey => {
            return (
                <React.Fragment key={eachKey}>
                    <h5 style={{ paddingTop: 10, paddingBottom: 10 }}>{eachKey}</h5>
                    <Table hover >
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Section</th>
                                <th>Correct</th>
                                <th>Wrong</th>
                                <th>Unattended</th>
                                <th>Total Attended</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedSections[eachKey].map((each, index) => (
                                    <tr key={each.sectionId}>
                                        <td>{index + 1}</td>
                                        <td>{each.foundSectionName}</td>
                                        <td>{each.correctAnswers}</td>
                                        <td>{each.wrongAnswers}</td>
                                        <td>{each.unattended}</td>
                                        <td>{each.correctAnswers + each.wrongAnswers + each.unattended}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </React.Fragment>
            )
        })
    }

    getSalesManName(salesmanId) {

        if (this.props.salesmen && this.props.salesmen.length > 0) {
            const found = this.props.salesmen.find(e => e._id === salesmanId)
            if (found) {
                return `${found.name}`
            } else {
                return null
            }
        } else {
            return null
        }
    }

    deleteUserDevice(userId, deviceId) {
        axios.delete('/devicelist-remove', { params: { userId, deviceId } })
            .then(res => {
                this.setState((oldState) => {
                    const removedDeleteDevice = oldState.editBot.devicesList.filter((each) => each.deviceId !== deviceId)
                    oldState.editBot.devicesList = removedDeleteDevice
                    return { editBot: oldState.editBot }
                })
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    fetchCompletedLearnModuleSplittedCount(user_Id) {

        if (!this.state.completedLearnModulesArr) {
            this.setState({isLoading: true})

            let params = {userId:user_Id}

            axios.get(`/fetchBots/learnModulesCounts`, {params})
                .then(res => {
                    this.setState({completedLearnModulesArr: res.data,isLoading:false})
                })

        } else {
            this.setState({completedLearnModulesArr: ""})
        }
    }

    fetchCompletedBatchModuleSplittedCount (user_Id) {

        if (!this.state.completedBatchModulesArr) {
            this.setState({isLoading: true})

            let params = {userId:user_Id}

            axios.get(`/fetchBots/batchModulesCounts`, {params})
                .then(res => {
                    this.setState({completedBatchModulesArr: res.data, isLoading:false})
                })

        } else {
            this.setState({completedBatchModulesArr: ""})
        }
    }

    editUserData (bot){

        let post = ''

        if (bot.preparingPost === "allSubject") {
            post = 'allSubject'
        } else {
            const posts = this.props.posts.find(each => each._id === bot.preparingPost)
            if (posts) {
                post = posts.name
            }
        }

        this.setState({
            postName: post,
            editBot: JSON.parse(JSON.stringify(bot)),
            totalActivity: bot.activityHeatMap ? this.getTotalAcivity(bot.activityHeatMap) : 0,
            editBotDialog: true,
            editSuccess: false
        }, () => {

            setTimeout(() => {

                // 1. Create data for green dots
                const paymentDatesArr = bot.paymentDetails.map(each => moment(each.date).format("D MMM YYYY"))
                let purchasedPacksDatesArr = []
                
                if (Array.isArray(bot.purchasedPacks)) {
                    purchasedPacksDatesArr = bot.purchasedPacks.map(each => moment(each.paymentDate).format("D MMM YYYY"))
                }
                
                const cloneDateArr = cloneDeep(dateArray)
                let dailyAnalyticsDateArr = cloneDeep(bot.dailyAnalyticsDateArr)
        
                cloneDateArr.forEach(each => {
        
                    // 1. By default, simply set date only
                    each.title = `${each.date}`
        
                    // 2. Check for active bot analytics day
                    if (Array.isArray(dailyAnalyticsDateArr)) {
        
                        let foundIndex = -1
                        
                        dailyAnalyticsDateArr.forEach((item, index) => {
        
                            if (each.date === moment(item.date).format("D MMM YYYY")) {
                                each.active = true
                                each.questions = item.questions
                                each.title = `${each.date} - Questions(${each.questions ? each.questions : "0"})`
                                foundIndex = 1
                            }
        
                            if (each.date === moment(bot.joiningDate).format("D MMM YYYY")) {
                                each.joiningDate = true
                                each.title = `Joined on ${each.date} - Questions(${each.questions ? each.questions : "0"})`
                            }
        
                        })
        
                        if (foundIndex >= 0) {
                            dailyAnalyticsDateArr.splice(foundIndex, 1)
                        }
        
                    }
        
                    // 3. Check if the preset date is a payment date
                    if (paymentDatesArr.includes(each.date)) {
                        each.premiumPurchaseDate = true
                        each.title = `Purchased on ${each.date}`
                    }
        
                    // 4. Check if the preset date is a pack payment date
                    if (purchasedPacksDatesArr.includes(each.date)) {
                        each.packPurchaseDate = true
                        each.title = `Purchased on ${each.date}`
                    }
        
                })

                let sortedSections = []

                // 2. Create data for section progress table
                if (bot.sectionWiseAnswerStats) {

                    bot.sectionWiseAnswerStats.forEach((each) => {
                        
                        let foundSubjectName = null
                        let foundSubjectGroupName = null
                        let foundSectionName = null

                        this.props.subjects.forEach((subject) => {
                            subject.sections.forEach((section) => {
                                if (section._id === each.sectionId) {
                                    foundSubjectName = subject.name
                                    foundSubjectGroupName = subject.groupName
                                    foundSectionName = section.name
                                }
                            })
                        })

                        if (foundSectionName) {
                            each.foundSubjectName = foundSubjectName
                            each.foundSubjectGroupName = foundSubjectGroupName
                            each.foundSectionName = foundSectionName
                            sortedSections.push(each)
                        }

                    })

                    sortedSections = sortedSections.sort((a, b) => {
                        if ((a.correctAnswers + a.wrongAnswers + a.unattended) < (b.correctAnswers + b.wrongAnswers + b.unattended)) {
                            return 1
                        } else {
                            return -1
                        }
                    })

                    sortedSections = groupBy(sortedSections, each => each.foundSubjectGroupName)

                }

                this.setState({
                    activeDaysArray: cloneDateArr,
                    sortedSections
                })

            }, 500)

        })
        
    }

    fetchQuestionDistribution(userId, keyName) {
        this.setState({ isLoading: true }, () => {
            axios.get('/question-distribution-stats', { params: { userId, keyName } })
            .then(res => {
                const { premiumQuestionsCount, freeQuestionsCount } = res.data
                this.setState({ isLoading: false, premiumQuestionsCount, freeQuestionsCount, questionKeyName: keyName })
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
                alert("Server error. Check console")
            })
        })
    }

    render() {

        const addActions = [
            <FlatButton
                label="Submit"
                primary={true}
                onClick={() => this.submitBot()}
            />,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={() => this.setState({ addBotDialog: false })}
            />
        ];

        const afterActions = [
            <FlatButton
                label="Close"
                primary={true}
                onClick={() => this.setState({ addBotDialog: false, addSuccess: false })}
            />
        ];


        const { editBot } = this.state

        return (
            <>
                { this.state.isLoading && <Loader />}

                <Container fluid>

                    <div style={{ flex: 1, flexDirection: 'row', padding: 25, alignItems: 'center' }}>
                        <div>
                            <Dialog
                                title="Add New Bot"
                                actions={this.state.addSuccess ? afterActions : addActions}
                                modal={true}
                                open={this.state.addBotDialog}
                            >
                                {
                                    !this.state.addSuccess ?
                                        [
                                            <TextField
                                                key={1}
                                                id={"1"}
                                                hintText="Mobile"
                                                name="mobile"
                                                value={this.state.mobile || ''}
                                                fullWidth={true}
                                                type="number"
                                                errorText={this.state.formErrors.mobile}
                                                onChange={this.handleInput}
                                            />,

                                            <TextField
                                                key={2}
                                                id={"2"}
                                                hintText="Name"
                                                name="name"
                                                value={this.state.name || ''}
                                                fullWidth={true}
                                                errorText={this.state.formErrors.name}
                                                onChange={this.handleInput}
                                            />,

                                            <SelectField
                                                style={{ width: '100%' }}
                                                key={3}
                                                floatingLabelText="Chose District"
                                                value={this.state.district}
                                                onChange={(event, third, value) => this.handleMenuChange("district", value)}
                                                errorText={this.state.formErrors.district}
                                            >
                                                <MenuItem value={"Alappuzha"} primaryText="Alappuzha" />
                                                <MenuItem value={"Ernakulam"} primaryText="Ernakulam" />
                                                <MenuItem value={"Idukki"} primaryText="Idukki" />
                                                <MenuItem value={"Kannur"} primaryText="Kannur" />
                                                <MenuItem value={"Kasargod"} primaryText="Kasargod" />
                                                <MenuItem value={"Kollam"} primaryText="Kollam" />
                                                <MenuItem value={"Kottayam"} primaryText="Kottayam" />
                                                <MenuItem value={"Kozhikode"} primaryText="Kozhikode" />
                                                <MenuItem value={"Malappuram"} primaryText="Malappuram" />
                                                <MenuItem value={"Palakkad"} primaryText="Palakkad" />
                                                <MenuItem value={"Pathanmthitta"} primaryText="Pathanmthitta" />
                                                <MenuItem value={"Thiruvananthapuram"} primaryText="Thiruvananthapuram" />
                                                <MenuItem value={"Thrissur"} primaryText="Thrissur" />
                                                <MenuItem value={"Wayanad"} primaryText="Wayanad" />
                                            </SelectField>,

                                            <p key={4} style={{ color: 'red' }}>{this.state.submitError}</p>
                                        ]
                                        :
                                        [
                                            <h4 key={5}>Success</h4>,
                                            <p key={6}>Added Bot Successfully</p>
                                        ]
                                }
                            </Dialog>

                            {
                                this.state.editBotDialog &&

                                <Modal
                                    title="Edit Bot"
                                    size="lg"
                                    isOpen={this.state.editBotDialog}
                                >
                                    {
                                        !this.state.editSuccess ?
                                            <Container>

                                                <div>
                                                <ModalHeader>
                                                    Edit Bot
                                                </ModalHeader>
                                                <ModalBody>
                                                    <Nav tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                className={this.state.activeTab === '1' ? 'active' : null}
                                                                onClick={() => { this.toggle('1'); }}
                                                            >
                                                                Details
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                className={this.state.activeTab === '2' ? 'active' : null}
                                                                onClick={() => { this.toggle('2'); }}
                                                            >
                                                                Analytics
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                        </NavItem>

                                                        {
                                                            this.state.editBot.paymentDetails &&
                                                            <NavItem>
                                                                <NavLink
                                                                    className={this.state.activeTab === '4' ? 'active' : null}
                                                                    onClick={() => { this.toggle('4'); }}
                                                                >
                                                                    Payments
                                                                </NavLink>
                                                            </NavItem>
                                                        }

                                                        <NavItem>
                                                            <NavLink
                                                                className={this.state.activeTab === '5' ? 'active' : null}
                                                                onClick={() => { this.toggle('5'); }}
                                                            >
                                                                Section Progress
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                className={this.state.activeTab === '6' ? 'active' : null}
                                                                onClick={() => { this.toggle('6'); }}
                                                            >
                                                                Course Progress
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                className={this.state.activeTab === '7' ? 'active' : null}
                                                                onClick={() => { this.toggle('7'); }}
                                                            >
                                                                Devices
                                                            </NavLink>
                                                        </NavItem>

                                                    </Nav>

                                                    <TabContent activeTab={this.state.activeTab}>
                                                        <TabPane tabId="1" style={{ overflowY: 'scroll', height: 400 }}>
                                                            <Row>
                                                                <Col sm="12">

                                                                    <Row>
                                                                        <Col>
                                                                            <TextField
                                                                                disabled={!this.state.enableEditing}
                                                                                floatingLabelText="Mobile"
                                                                                name="mobile"
                                                                                value={editBot.mobile || ''}
                                                                                fullWidth={true}
                                                                                type="number"
                                                                                errorText={this.state.editFormErrors.mobile}
                                                                                onChange={this.handleEditInput}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <TextField
                                                                                disabled={!this.state.enableEditing}
                                                                                floatingLabelText="Name"
                                                                                name="name"
                                                                                value={editBot.name || ''}
                                                                                fullWidth={true}
                                                                                errorText={this.state.editFormErrors.name}
                                                                                onChange={this.handleEditInput}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col>
                                                                            <SelectField
                                                                                disabled={!this.state.enableEditing}
                                                                                floatingLabelText="Choose District"
                                                                                value={editBot.district}
                                                                                onChange={(event, third, value) => this.handleEditMenuChange("district", value)}
                                                                            >
                                                                                <MenuItem value={"Alappuzha"} primaryText="Alappuzha" />
                                                                                <MenuItem value={"Ernakulam"} primaryText="Ernakulam" />
                                                                                <MenuItem value={"Idukki"} primaryText="Idukki" />
                                                                                <MenuItem value={"Kannur"} primaryText="Kannur" />
                                                                                <MenuItem value={"Kasargod"} primaryText="Kasargod" />
                                                                                <MenuItem value={"Kollam"} primaryText="Kollam" />
                                                                                <MenuItem value={"Kottayam"} primaryText="Kottayam" />
                                                                                <MenuItem value={"Kozhikode"} primaryText="Kozhikode" />
                                                                                <MenuItem value={"Malappuram"} primaryText="Malappuram" />
                                                                                <MenuItem value={"Palakkad"} primaryText="Palakkad" />
                                                                                <MenuItem value={"Pathanmthitta"} primaryText="Pathanmthitta" />
                                                                                <MenuItem value={"Thiruvananthapuram"} primaryText="Thiruvananthapuram" />
                                                                                <MenuItem value={"Thrissur"} primaryText="Thrissur" />
                                                                                <MenuItem value={"Wayanad"} primaryText="Wayanad" />
                                                                            </SelectField>
                                                                        </Col>
                                                                        <Col>
                                                                            <SelectField
                                                                                disabled={!this.state.enableEditing}
                                                                                floatingLabelText="Free or Premium"
                                                                                value={editBot.premiumUser}
                                                                                onChange={(event, third, value) => this.handleEditMenuChange("premiumUser", value)}
                                                                            >
                                                                                <MenuItem value={false} primaryText="Free User" />
                                                                                <MenuItem value={true} primaryText="Premium User" />
                                                                            </SelectField>
                                                                        </Col>
                                                                    </Row>

                                                                    {
                                                                        editBot.scoreData !== undefined &&
                                                                        <>
                                                                            <Row>
                                                                                <Col>
                                                                                    <TextField
                                                                                        disabled={!this.state.enableEditing}
                                                                                        floatingLabelText="Score"
                                                                                        value={editBot.scoreData.score}
                                                                                        fullWidth={true}
                                                                                        type="number"
                                                                                        onChange={(e) => this.handleScoerChange(e, "score")}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <TextField
                                                                                        disabled={!this.state.enableEditing}
                                                                                        floatingLabelText="Coins"
                                                                                        value={editBot.scoreData.coins}
                                                                                        fullWidth={true}
                                                                                        type="number"
                                                                                        onChange={(e) => this.handleScoerChange(e, "coins")}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <TextField
                                                                                        disabled={!this.state.enableEditing}
                                                                                        floatingLabelText="Level"
                                                                                        value={editBot.scoreData.level}
                                                                                        fullWidth={true}
                                                                                        type="number"
                                                                                        onChange={(e) => this.handleScoerChange(e, "level")}
                                                                                    />
                                                                                </Col>
                                                                            </Row>

                                                                            <Row>
                                                                                <Col>
                                                                                    <TextField
                                                                                        disabled={!this.state.enableEditing}
                                                                                        floatingLabelText="Games Won"
                                                                                        value={editBot.scoreData.gamesWon}
                                                                                        fullWidth={true}
                                                                                        type="number"
                                                                                        onChange={(e) => this.handleScoerChange(e, "gamesWon")}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <TextField
                                                                                        disabled={!this.state.enableEditing}
                                                                                        floatingLabelText="Games Lost"
                                                                                        value={editBot.scoreData.gamesLost}
                                                                                        fullWidth={true}
                                                                                        type="number"
                                                                                        onChange={(e) => this.handleScoerChange(e, "gamesLost")}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <TextField
                                                                                        disabled={!this.state.enableEditing}
                                                                                        floatingLabelText="Games Tied"
                                                                                        value={editBot.scoreData.gamesTied}
                                                                                        fullWidth={true}
                                                                                        type="number"
                                                                                        onChange={(e) => this.handleScoerChange(e, "gamesTied")}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row style={{marginTop: 20}}>
                                                                                <Col>
                                                                                    <SelectField
                                                                                        disabled={!this.state.enableEditing}
                                                                                        floatingLabelText="Battle Chat Banned"
                                                                                        value={editBot.settings ? editBot.settings.battleChatBanned : false}
                                                                                        onChange={(event, third, value) => {
                                                                                            this.setState((currentState) => {

                                                                                                const { editBot } = currentState

                                                                                                if (editBot && editBot.settings) {
                                                                                                    editBot.settings.battleChatBanned = value
                                                                                                } else {
                                                                                                    editBot.settings = {
                                                                                                        battleChatBanned: value
                                                                                                    }
                                                                                                }

                                                                                                return { editBot }
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <MenuItem value={true} primaryText="Yes" />
                                                                                        <MenuItem value={false} primaryText="No" />
                                                                                    </SelectField>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    }

                                                                    <hr />

                                                                    <p style={{ color: 'red' }}>{this.state.editSubmitError}</p>

                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tabId="2" style={{ overflowY: 'scroll', height: 400, paddingTop: 10 }}>
                                                            <span style={{margin: 0, color: TABLE_PANE_GREY}}>Daily Activity</span>
                                                            <Row style={{marginBottom: 8, paddingTop: 0}}>
                                                                {
                                                                    Array.isArray(this.state.activeDaysArray) && this.state.activeDaysArray.map((each) => {
                                                                        return (
                                                                            <div
                                                                                key={each.date}
                                                                                style={{
                                                                                    background: each.joiningDate ? "#fc9803"
                                                                                                    :each.premiumPurchaseDate ? '#0960b8'
                                                                                                        : each.packPurchaseDate ?  '#a570ff' 
                                                                                                            : each.active ? "#2da44e" : "#ebedf0",
                                                                                    height: 12,
                                                                                    width: 12,
                                                                                    margin: 1,
                                                                                    display: 'inline-block',
                                                                                    borderRadius: 2
                                                                                }}
                                                                                title={each.title}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                            <Row style={{marginBottom: 20, display: 'flex', alignItems: 'center', paddingTop: 0}}>
                                                                <div
                                                                    style={{
                                                                        background: "#fc9803",
                                                                        height: 12,
                                                                        width: 12,
                                                                        marginLeft: 15, 
                                                                        marginRight: 10,                                                       
                                                                        borderRadius: 2
                                                                    }}
                                                                />
                                                                <span>Joining Date</span>

                                                                <div
                                                                    style={{
                                                                        background: "#2da44e",
                                                                        height: 12,
                                                                        width: 12,
                                                                        marginLeft: 30, 
                                                                        marginRight: 10,                                                       
                                                                        borderRadius: 2
                                                                    }}
                                                                />
                                                                <span>Active Date</span>  

                                                                <div
                                                                    style={{
                                                                        background: "#0960b8",
                                                                        height: 12,
                                                                        width: 12,
                                                                        marginLeft: 30, 
                                                                        marginRight: 10,                                                       
                                                                        borderRadius: 2
                                                                    }}
                                                                />
                                                                <span>Premium Purchase</span>  
                                                                
                                                                <div
                                                                    style={{
                                                                        background: "#a570ff",
                                                                        height: 12,
                                                                        width: 12,
                                                                        marginLeft: 30, 
                                                                        marginRight: 10,                                                       
                                                                        borderRadius: 2
                                                                    }}
                                                                />
                                                                <span>Pack Purchase</span>
                                                            </Row>
                                                            <Row>
                                                                <Table style={{marginLeft: 20}}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Prepration Post</td>
                                                                            <td>{this.state.postName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ margin: 0, color: TABLE_PANE_GREY }}>PSCക്ക്  ആദ്യമായിട്ടാണോ?</td>
                                                                            <td>
                                                                                {
                                                                                    editBot.isFirstTimeStudent === true ? "Yes"
                                                                                        : editBot.isFirstTimeStudent === false ? "No"
                                                                                            : "Nil"
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ margin: 0, color: TABLE_PANE_GREY }}>ഇപ്പോൾ ചെയ്യുന്നത്? </td>
                                                                            <td>{editBot.currentStatus ? snakeCaseToTitleCase(editBot.currentStatus) : "Nil"}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Friends</td>
                                                                            <td>
                                                                                {
                                                                                    editBot.friendsCount > 0 ?
                                                                                        <Link
                                                                                            to={`/bots?userId=${this.state.editBot._id}`}
                                                                                            target="_blank"
                                                                                            style={{ margin: 0, fontSize: 20 }}
                                                                                        >
                                                                                            {editBot.friendsCount}
                                                                                        </Link>
                                                                                            :
                                                                                             <p style={{ color: 'grey', margin: 0, fontSize: 20}} to={`#`}>0</p>

                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        
                                                                        <tr>
                                                                            <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Bookmarks</td>
                                                                            <td>{editBot.bookmarksCount}</td>
                                                                        </tr>

                                                                        {
                                                                            editBot.analytics &&
                                                                            <>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Byte Quiz</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.quizAttended ? editBot.analytics.quizAttended : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Exams Attended</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>
                                                                                        <Link
                                                                                            to={`/exams-attended?userId=${this.state.editBot._id}`}
                                                                                            target="_blank"
                                                                                        >
                                                                                            <Button color="link">
                                                                                                {editBot.analytics.totalExamsAttended ? editBot.analytics.totalExamsAttended : 0}
                                                                                            </Button>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Daily Exam</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.dailyExamsAttended ? editBot.analytics.dailyExamsAttended : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Battle</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.battleAttended ? editBot.analytics.battleAttended : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Daily Challenge Submitted</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.dailyChallengesSubmitted ? editBot.analytics.dailyChallengesSubmitted : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Marathon Submitted</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.marathonsPlayed? editBot.analytics.marathonsPlayed : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Questions Reported</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.questionsReported ? editBot.analytics.questionsReported : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Bookmarks Added</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.bookmarksAdded ? editBot.analytics.bookmarksAdded : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Friends Match Started</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.friendsMatchInitiated ? editBot.analytics.friendsMatchInitiated : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Reward Coins Claimed</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.rewaredVideoCoinsClaimed ? editBot.analytics.rewaredVideoCoinsClaimed:  0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Description Seen</td>
                                                                                    <td style={{ margin: 0, fontSize: 20,marginBottom:20  }}>{editBot.analytics.questionDescriptionSeen ? editBot.analytics.questionDescriptionSeen : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Name Change Requests</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.nameChangesRequested ? editBot.analytics.nameChangesRequested : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Daily Coins Collected</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.dailyCoinsClaimed ? editBot.analytics.dailyCoinsClaimed : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY}}>{"All Subjects > Modules"}</td>
                                                                                            <td style={{ margin: 0, fontSize: 20, color: '#2B80F2' }}>
                                                                                                <Button
                                                                                                    color="link"
                                                                                                    onClick={() => this.fetchCompletedLearnModuleSplittedCount(editBot._id)}
                                                                                                >
                                                                                                    {editBot.completedLearnModulesCount}
                                                                                                </Button>


                                                                                                {
                                                                                                    this.state.completedLearnModulesArr ?
                                                                                                        (
                                                                                                            this.state.completedLearnModulesArr.map(data => (
                                                                                                                <Row
                                                                                                                    key={data._id}
                                                                                                                    style={{
                                                                                                                        marginLeft: 10,
                                                                                                                        fontSize: 16,
                                                                                                                        color: TABLE_PANE_GREY
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {snakeCaseToTitleCase(data._id)} : {data.count}
                                                                                                                </Row>
                                                                                                            ))
                                                                                                        )
                                                                                                        : null
                                                                                                }
                                                                                            </td> 
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY}}>Course Modules</td>
                                                                                    <td style={{ margin: 0, fontSize: 20, color: '#2B80F2 ' }}>
                                                                                        <Button
                                                                                            color="link"
                                                                                            onClick={()=> this.fetchCompletedBatchModuleSplittedCount(editBot._id)}
                                                                                        >
                                                                                            {editBot.completedBatchModulesCount}    
                                                                                        </Button>
                                                                                                {
                                                                                                    Array.isArray(this.state.completedBatchModulesArr) ?
                                                                                                        (
                                                                                                            this.state.completedBatchModulesArr.map(data => (
                                                                                                                <Row
                                                                                                                    key={data._id}
                                                                                                                    style={{ marginLeft: 10, fontSize: 16, color: TABLE_PANE_GREY }}
                                                                                                                >
                                                                                                                    {snakeCaseToTitleCase(data._id)}: {data.count}
                                                                                                                </Row>
                                                                                                            ))
                                                                                                        )
                                                                                                        : null
                                                                                                } 
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Video Started Count</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.analytics.videoStartedCount ? editBot.analytics.videoStartedCount : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>General Questions Practiced</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.generalPracticedQuestions ? editBot.generalPracticedQuestions : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>SCERT Questions Practiced</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.scertPracticedQuestions ? editBot.scertPracticedQuestions : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Path Questions Practiced</td>
                                                                                    <td style={{ margin: 0, fontSize: 20}}>{editBot.pathPracticedQuestions ? editBot.pathPracticedQuestions : 0}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Current App Version</td>
                                                                                    <td>{editBot.analytics.currentAppVersion}</td>
                                                                                </tr>
                                                                            </>
                                                                        }
                                                                        
                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Challenge Correct Questions</td>
                                                                                    <td>
                                                                                        <Button
                                                                                            color="link"
                                                                                            onClick={() => this.fetchQuestionDistribution(editBot._id, 'attendedQuestions')}
                                                                                        >
                                                                                            {editBot.challengeCorrectAnswers}
                                                                                        </Button>
                                                                                        {
                                                                                            (this.state.questionKeyName === 'attendedQuestions') &&
                                                                                            isFinite(this.state.premiumQuestionsCount) &&
                                                                                            isFinite(this.state.freeQuestionsCount) ?
                                                                                                <>
                                                                                                    <p style={{ fontSize: 16, color: TABLE_PANE_GREY }}>Premium Questions Count: {this.state.premiumQuestionsCount}</p>
                                                                                                    <p style={{ fontSize: 16, color: TABLE_PANE_GREY }}>Free Questions Count: {this.state.freeQuestionsCount}</p>
                                                                                                </>
                                                                                            :
                                                                                            null
                                                                                        } 
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>Challenge Spaced Repetition</td>
                                                                                    <td>
                                                                                        <Button
                                                                                            color="link"
                                                                                            onClick={() => this.fetchQuestionDistribution(editBot._id, 'wrongAnswers')}
                                                                                        >
                                                                                            {editBot.challengeWrongAnswers}
                                                                                        </Button>
                                                                                        {
                                                                                            (this.state.questionKeyName === 'wrongAnswers') &&
                                                                                            isFinite(this.state.premiumQuestionsCount) &&
                                                                                            isFinite(this.state.freeQuestionsCount) ?
                                                                                                <>
                                                                                                    <p style={{ fontSize: 16, color: TABLE_PANE_GREY }}>Premium Questions Count: {this.state.premiumQuestionsCount}</p>
                                                                                                    <p style={{ fontSize: 16, color: TABLE_PANE_GREY }}>Free Questions Count: {this.state.freeQuestionsCount}</p>
                                                                                                </>
                                                                                            :
                                                                                            null
                                                                                        } 
                                                                                    </td>
                                                                                </tr>

                                                                                {
                                                                                    editBot.premiumModalAnalytics ?
                                                                                        <>
                                                                                            <h6>Premium Modal Open Sources</h6>
                                                                                            {Object.keys(editBot.premiumModalAnalytics).map(eachKey => (
                                                                                                <tr key={eachKey}>
                                                                                                    <td style={{ margin: 0, color: TABLE_PANE_GREY }}>{snakeCaseToTitleCase(eachKey)}</td>
                                                                                                    <td>{editBot.premiumModalAnalytics[eachKey]}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </>
                                                                                    : null
                                                                                }

                                                                    </tbody>
                                                                </Table>                                                              
                                                            </Row>                                    
                                                        </TabPane>

                                                        <TabPane tabId="4" style={{ overflowY: 'scroll', height: 400, borderBottom: 'grey' }} >
                                                            {

                                                                editBot.paymentDetails && editBot.paymentDetails.length > 0 && editBot.paymentDetails.map(each => {
                                                                    return (
                                                                        <Row key={each.date} style={{ border: "1px solid #e0e0e0", borderRadius: 10, marginTop: 20 }}>
                                                                            <Col sm="5" style={{ margin: 10, borderRadius: 10, padding: 10 }}>
                                                                                <p style={{ margin: 0, fontWeight: 'bold', color: TABLE_PANE_GREY }}>{each.paymentType}</p>

                                                                                <Col sm="5" style={{ border: "2px dashed #dadada", borderRadius: 10 }}>
                                                                                    <p style={{ margin: 0, fontSize: 30, color: '#319A19', fontFamily: 'Roboto' }}>₹{each.amount}</p>
                                                                                </Col>

                                                                                <Row style={{ marginTop: 20 }}>
                                                                                    <Col sm="9">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}> Saleman</p>
                                                                                        <p style={{ margin: 0, color: '#0A6DDB' }}>{each.salesman ? this.getSalesManName(each.salesman) : null}</p>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{ marginTop: 20 }}>
                                                                                    <Col sm="9">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}> Purchase Date</p>
                                                                                        <p style={{ margin: 0 }}>{moment(each.date).format("D MMM YYYY, h:mm:ss a")}</p>
                                                                                    </Col>
                                                                                </Row>
                                                                                {
                                                                                    each.purchaseModalSource ?
                                                                                        <Row style={{ marginTop: 20 }}>
                                                                                            <Col sm="9">
                                                                                                <p style={{ margin: 0, color: TABLE_PANE_GREY }}>Purchase Modal Souce</p>
                                                                                                <p style={{ margin: 0 }}>{each.purchaseModalSource}</p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        : null
                                                                                }
                                                                            </Col>
                                                                            <Col sm="6" style={{ margin: 10, borderRadius: 10, padding: 10 }}>
                                                                                <Row>
                                                                                    <Col sm="3">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}>Games</p>
                                                                                        <p style={{ margin: 0, fontSize: 20 }}>{each.totalGames}</p>
                                                                                    </Col>
                                                                                    <Col sm="3"> 
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}>Marathon</p>
                                                                                        <p style={{ margin: 0, fontSize: 20 }}>{each.marathonPlayed}</p>
                                                                                    </Col>
                                                                                    <Col sm="3">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}>Version</p>
                                                                                        <p style={{ margin: 0, fontSize: 20 }}>{each.appVersion}</p>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col sm="9" style={{ marginTop: 30 }}>
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}>Payment Token</p>
                                                                                        <p style={{ margin: 0 }}>"{each.purchaseToken}"</p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })

                                                            }

                                                            {
                                                                editBot.purchasedPacks && editBot.purchasedPacks.length > 0 &&
                                                                <>
                                                                    <hr />
                                                                    <h6>Packs Purchased</h6>
                                                                </>
                                                            }

                                                            {
                                                                editBot.purchasedPacks && editBot.purchasedPacks.length > 0 && editBot.purchasedPacks.map((each) => {
                                                                    return (
                                                                        <Row key={each._id} style={{ border: "1px solid #e0e0e0", borderRadius: 10, marginTop: 20 }}>
                                                                            <Col sm="5" style={{ margin: 10, borderRadius: 10, padding: 10 }}>

                                                                                <p style={{ margin: 0, }}>Payment Type</p>
                                                                                <p style={{ margin: 0, fontWeight: 'bold', color: TABLE_PANE_GREY }}>{each.paymentType}</p>

                                                                                <Col sm="5" style={{ border: "2px dashed #dadada", borderRadius: 10 }}>
                                                                                    <p style={{ margin: 0, fontSize: 30, color: '#319A19', fontFamily: 'Roboto' }}>₹{each.amount}</p>
                                                                                </Col>

                                                                                {
                                                                                    each.salesman ?
                                                                                        <Row style={{ marginTop: 20 }}>
                                                                                            <Col sm="9">
                                                                                                <p style={{ margin: 0, color: TABLE_PANE_GREY }}> Saleman</p>
                                                                                                <p style={{ margin: 0, color: '#0A6DDB' }}>{this.getSalesManName(each.salesman)}</p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        : null
                                                                                }

                                                                                <Row style={{ marginTop: 20 }}>
                                                                                    <Col sm="9">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}> Purchase Date</p>
                                                                                        <p style={{ margin: 0 }}>{moment(each.paymentDate).format("D MMM YYYY, h:mm:ss a")}</p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </TabPane>

                                                        <TabPane tabId="5" style={{ overflowY: 'scroll', height: 400 }}>
                                                            <Row>
                                                                <Col sm="12" style={{ flexDirection: 'row', marginBottom: 30 }}>
                                                                    {
                                                                        isObject(this.state.sortedSections) &&
                                                                        Object.keys(this.state.sortedSections).length > 0 &&
                                                                        this.renderTable(this.state.sortedSections)
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </TabPane>

                                                        <TabPane tabId="6" style={{ overflowY: 'scroll', height: 400 }}>
                                                            {this.state.activeTab === "6" ?
                                                                <UserPackProgress
                                                                    userId={editBot._id}
                                                                    mobile={editBot.mobile}
                                                                    courseProgress={editBot.courseProgress}
                                                                    updateCourseProgress={(courseProgress) => {
                                                                        this.setState(oldState => {
                                                                            oldState.editBot.courseProgress = courseProgress
                                                                            return { editBot: oldState.editBot }
                                                                        })
                                                                    }}
                                                                />
                                                                : null}
                                                        </TabPane>

                                                        <TabPane tabId="7" style={{ overflowY: 'scroll', height: 400 }}>
                                                            {
                                                                editBot.devicesList && editBot.devicesList.length > 0 ? editBot.devicesList.map((each, index) => {
                                                                    // : false
                                                                    return (
                                                                        <Row key={each.deviceId} style={{ borderBottom: "1px solid #e0e0e0",borderRadius: 10, marginTop: 10 }}>

                                                                            <Col sm="5" style={{ margin: 10, borderRadius: 10, padding: 10 }}>

                                                                                <p style={{ margin: 0, fontWeight: 'bold', color: TABLE_PANE_GREY }}>Device ID: {each.deviceId}</p>

                                                                                <Row style={{ marginTop: 10 }}>
                                                                                    <Col sm="9">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}> Device Name</p>
                                                                                        <p style={{ margin: 0 }}>{each.deviceName}</p>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{ marginTop: 20 }}>
                                                                                    <Col sm="9">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}> Last Activity</p>
                                                                                        <p style={{ margin: 0 }}>{moment(each.lastActivity).format("D MMM YYYY, h:mm:ss a")}</p>
                                                                                    </Col>
                                                                                </Row>

                                                                                <Button
                                                                                    disabled={!this.state.enableEditing}
                                                                                    variant="outline-danger"
                                                                                    onClick={() => this.deleteUserDevice(editBot._id, each.deviceId)}
                                                                                > Delete </Button>

                                                                            </Col>

                                                                            <Col sm="6" style={{ margin: 10, borderRadius: 10, padding: 10 }}>
                                                                                <Row>
                                                                                    <Col sm="3">
                                                                                        <p style={{ margin: 0, color: TABLE_PANE_GREY }}>OS: {each.os}</p>
                                                                                    </Col>
                                                                                </Row>

                                                                                <CheckBox
                                                                                    label={'Always Allow This Device'}
                                                                                    style={{ marginTop: '10px' }}
                                                                                    checked={each.approvedOnRequest}
                                                                                    disabled={!this.state.enableEditing}
                                                                                    onClick={() => {
                                                                                        this.setState((oldState) => {
                                                                                            let editingBot = oldState.editBot
                                                                                            editingBot.devicesList[index].approvedOnRequest = !each.approvedOnRequest
                                                                                            return { editBot: editingBot }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </Col>

                                                                        </Row>
                                                                    )
                                                                })
                                                                    :
                                                                    <p>No devices</p>
                                                            }
                                                        </TabPane>

                                                    </TabContent>
                                                </ModalBody>
                                                </div>
                                                <ModalFooter>
                     
                                                    <Link
                                                     to={`/reported-questions?userId=${this.state.editBot._id}`}
                                                     target="_blank"
                                                    > View Reported Questions 
                                                    </Link>

                                                    <Button 
                                                        style={{marginLeft:"1rem"}}
                                                        onClick={() => this.setState({ enableEditing: !this.state.enableEditing })}
                                                    > Toggle Editing 
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </Button>

                                                    <FlatButton
                                                        disabled={!this.state.enableEditing}
                                                        label="Submit"
                                                        primary={true}
                                                        onClick={() => this.editBot()}
                                                    />
                                                    <FlatButton
                                                        label="Cancel"
                                                        primary={true}
                                                        onClick={() => this.setState({ editBotDialog: false , completedBatchModulesArr:'', completedLearnModulesArr: ''})}
                                                    /> 
                                                    
                                                       
                                                    
                                                </ModalFooter>
                                            </Container>
                                            :
                                            <Container>
                                            <ModalBody>
                                                <p>Bot Edited Successfully</p>
                                            </ModalBody>
                                            <ModalFooter>
                                            {this.state.editSuccess &&
                                                    <FlatButton
                                                        label="Close"
                                                        primary={true}
                                                        onClick={() => this.setState({ editBotDialog: false, editSuccess: false, })}
                                                    />
                                            }
                                            </ModalFooter>
                                            </Container>
                                    }
                                </Modal>
                            }

                            <Dialog
                                modal={true}
                                open={this.state.botDeleteDialog}
                            >
                                {
                                    !this.state.showBotDeleteSuccessDialog ?
                                        [
                                            <h4 key={1} style={{ textAlign: 'center' }}>Are you sure want to delete this bot?</h4>,

                                            <RaisedButton
                                                key={2}
                                                label="Delete"
                                                secondary={true}
                                                style={{ width: '100%' }}
                                                onClick={() => this.deleteBot()}
                                            />,

                                            <RaisedButton
                                                key={3}
                                                label="Cancel"
                                                style={{ width: '100%', marginTop: 10 }}
                                                onClick={() => this.setState({ botDeleteDialog: false })}
                                            />
                                        ]
                                        :
                                        [
                                            <h4 key={5}>Success</h4>,
                                            <p key={6}>Deleted Bot Successfully</p>,
                                            <RaisedButton
                                                key={3}
                                                label="Close"
                                                style={{ width: '100%', marginTop: 10 }}
                                                onClick={() => this.setState({ botDeleteDialog: false, showBotDeleteSuccessDialog: false })}
                                            />
                                        ]
                                }

                                {

                                }
                            </Dialog>

                            <Dialog
                                modal={true}
                                open={this.state.notificationDialog}
                            >
                                {
                                    !this.state.notificationSuccessDialog ?
                                        [
                                            <h4 key={8} style={{ textAlign: 'center' }}>Enter notification header and body?</h4>,

                                            <TextField
                                                key={0}
                                                id={"0"}
                                                hintText="Notification Header"
                                                name="notificationHeader"
                                                value={this.state.notificationHeader}
                                                fullWidth={true}
                                                type="text"
                                                // errorText={this.state.editFormErrors.mobile}
                                                onChange={this.handleInput}
                                            />,

                                            <TextField
                                                key={1}
                                                id={"1"}
                                                hintText="Notification Body"
                                                name="notificationBody"
                                                value={this.state.notificationBody}
                                                fullWidth={true}
                                                type="text"
                                                // errorText={this.state.editFormErrors.mobile}
                                                onChange={this.handleInput}
                                            />,

                                            <RaisedButton
                                                key={2}
                                                label="Send Notification"
                                                secondary={true}
                                                style={{ width: '100%' }}
                                                onClick={() => this.sendNotification()}
                                            />,

                                            <RaisedButton
                                                key={3}
                                                label="Cancel"
                                                style={{ width: '100%', marginTop: 10 }}
                                                onClick={() => this.setState({ notificationDialog: false })}
                                            />
                                        ]
                                        :
                                        [
                                            <h4 key={5}>Success</h4>,
                                            <p key={6}>Notification sent successfully</p>,
                                            <RaisedButton
                                                key={7}
                                                label="Close"
                                                style={{ width: '100%', marginTop: 10 }}
                                                onClick={() => this.setState({ notificationDialog: false, notificationSuccessDialog: false })}
                                            />
                                        ]
                                }
                            </Dialog>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <div style={{ marginRight: 20 }}>
                                {!this.state.showSearchBox && <Form onSubmit={(e) => { e.preventDefault(); this.fetchBots() }}>
                                    <Row>
                                        <Col>
                                            <Input name="nameSearch" onChange={this.handleInput} value={this.state.nameSearch || ''} placeholder="Name" />
                                        </Col>

                                        <Col>
                                            <Input name="mobileSearch" onChange={this.handleInput} value={this.state.mobileSearch || ''} placeholder="Mobile" />
                                        </Col>

                                        <Col>
                                            <Input name="coinSearch" onChange={this.handleInput} value={this.state.coinSearch || ''} placeholder="Coins" />
                                        </Col>

                                        <Col>
                                            <Input name="userIdSearch" onChange={this.handleInput} value={this.state.userIdSearch || ''} placeholder="userId" />
                                        </Col>

                                        <Col>
                                            <Button color="primary" type="submit" onClick={(e) => { e.preventDefault(); this.fetchBots() }} style={{ width: '100%' }}> Search </Button>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col style={{ flexDirection: 'row' }}>
                                            <DatePicker
                                                container="inline"
                                                hintText="Joining Date"
                                                onChange={this.handleChangeDate}
                                                defaultDate={this.state.joiningDate}
                                            />
                                        </Col>

                                        <Col>
                                            <CheckBox
                                                label={'Referred Users'}
                                                style={{ marginTop: '10px' }}
                                                checked={this.state.referredUsers}
                                                onClick={() => {
                                                    this.setState({ referredUsers: !this.state.referredUsers })
                                                }}
                                            />
                                        </Col>

                                        {this.state.joiningDate &&
                                            <Col>
                                                <Button variant="outline-danger" onClick={() => this.setState({ joiningDate: null })} >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </Button>
                                            </Col>
                                        }

                                    </Row>


                                </Form>}
                            </div>

                            {
                                isAdmin(this.props.roleIds) &&
                                    <div style={{ marginRight: 20 }}>
                                        <Button
                                            disabled={this.state.userType === 4 && "active"}
                                            style={{ width: '100%' }}
                                            variant="outline-primary"
                                            onClick={() => this.generatePremiumCSV('/generate-csv?premiumUser=' + this.state.premiumUsers)}
                                        > Generate  CSV </Button>
                                    </div>
                            }

                            {
                                isAdmin(this.props.roleIds) &&
                                    <div style={{ marginRight: 20 }}>
                                        <Button
                                            disabled={this.state.userType === 4 && "active"}
                                            style={{ width: '100%' }}
                                            variant="outline-primary"
                                            onClick={() => this.generatePremiumCSV('/generate-pack-csv?premiumUser=' + this.state.premiumUsers)}
                                        > Generate Pack CSV</Button>
                                    </div>
                            }

                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div style={{ marginTop: 15 }}>
                            <span
                                onClick={() => { this.setState({ premiumUsers: false, userType: 3 }, () => this.fetchBots(true)) }}
                                className={"underlineBtn " + (this.state.userType === 3 && !this.state.premiumUsers && "active")}>All Users</span>
                            <span
                                onClick={() => this.setState({ premiumUsers: true, userType: null, skipCount: 0 }, () => this.fetchBots(true))}
                                className={"underlineBtn " + (this.state.premiumUsers && "active")}>Premium Users</span>
                            <span
                                onClick={() => { this.setState({ premiumUsers: false, userType: 4, skipCount: 0 }, () => this.fetchBots(true)) }}
                                className={"underlineBtn " + (this.state.userType === 4 && "active")}>Bots</span>
                        </div>

                        <div style={{ marginRight: 20 }}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="success" id="dropdown-basic"> Page {this.state.skipCount + 1} </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {this.renderSkipMenu()}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>

                    </div>

                    <hr style={{ marginTop: 10 }}></hr>

                    {
                        this.state.allBots && this.state.allBots.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Mobile</th>
                                        <th>Name</th>
                                        <th>Games</th>
                                        {/* <th>Score</th> */}
                                        <th>Coins</th>
                                        <th>Last Active</th>
                                        <th>Joining Date</th>
                                        <th>Source</th>
                                        {this.state.premiumUsers &&<th>Purchase Date</th>}
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        this.state.allBots.map((bot, index) => {

                                            let lastActivity = moment(bot.lastActivity).format("D MMM YYYY, h:mm:ss a")
                                            let joiningDate = moment(bot.joiningDate).format("D MMM YYYY, h:mm:ss a")

                                            return (
                                                <tr key={bot._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{bot.mobile}</td>
                                                    <td>{bot.premiumUser ? <ProBadge paymentDetails={bot.paymentDetails} /> : null} {bot.name} </td>
                                                    <td>{bot.scoreData.gamesLost + bot.scoreData.gamesTied + bot.scoreData.gamesWon}</td>
                                                    {/* <td>{bot.scoreData.score}</td> */}
                                                    <td>{bot.scoreData.coins}</td>
                                                    <td>{lastActivity}</td>
                                                    <td>{joiningDate}</td>
                                                    <td>{typeof bot !== 'undefined' && typeof bot.installReferrer !== 'undefined' && typeof bot.installReferrer.utm_source !== 'undefined' ? <p>{bot.installReferrer.utm_source}</p> : <p> </p>}</td>

                                                    {
                                                        this.state.premiumUsers ?
                                                            <td>
                                                                {
                                                                    Array.isArray(bot.paymentDetails) ?
                                                                        bot.paymentDetails.map((each, index) => (
                                                                            <React.Fragment key={each.date}>
                                                                                Premium: {moment(each.date).format("D MMM YYYY, h:mm:ss a")}
                                                                                { bot.paymentDetails[index + 1] ? <br /> : null}
                                                                            </React.Fragment>
                                                                        ))
                                                                        : null
                                                                }
                                                                <br />
                                                                {
                                                                    (Array.isArray(bot.purchasedPacks) && bot.purchasedPacks.length > 0) ?
                                                                        bot.purchasedPacks.map((each, index) => (
                                                                            <React.Fragment key={each.paymentDate}>
                                                                                Pack: {moment(each.paymentDate).format("D MMM YYYY, h:mm:ss a") }
                                                                                { bot.purchasedPacks[index + 1] ? <br /> : null}
                                                                            </React.Fragment>
                                                                        ))
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                            : null
                                                    }

                                                    <td>
                                                        <Button
                                                            outline
                                                            color="primary"
                                                            style={{ marginRight: 10 }}
                                                            onClick={() => this.editUserData(bot)}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                style={{ color: '#3aa0fffc' }}
                                                                icon={faPen}
                                                            />
                                                        </Button>

                                                        <Button outline style={{ marginRight: 10 }} color="primary"
                                                            onClick={() => this.setState({ notifyBot: bot }, () => {
                                                                this.setState({ notificationDialog: true, notificationSuccessDialog: false })
                                                            })}
                                                        >
                                                            <FontAwesomeIcon className="iconButton" icon={faBell} />
                                                        </Button>

                                                        <Button outline style={{ marginRight: 10 }} color="danger" onClick={() => this.setState({ deleteBotId: bot._id }, () => {
                                                            this.setState({ botDeleteDialog: true, showBotDeleteSuccessDialog: false })
                                                        })}>
                                                            <FontAwesomeIcon className="iconButton" style={{ color: '#db1616' }} icon={faTrash} />
                                                        </Button>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            : 
                            !( Array.isArray(this.state.allBots) ) ?
                                <h3 style={{ textAlign: 'center', alignSelf: 'center' }}>Search Results</h3>
                                :
                                <h3 style={{ textAlign: 'center', alignSelf: 'center' }}>No Results</h3>
                            
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        adminId: state.auth.userId,
        adminName: state.auth.name,
        salesmen: state.data.salesmen,
        subjects: state.data.subjects,
        sectionsWithQuestionsCount: state.data.sectionsWithQuestionsCount,
        posts: state.data.posts,
        roleIds: state.auth.roleIds
    }
}

export default withRouter(connect(mapStateToProps)(ViewBots))
