import axios from 'axios'
import React, { useState, useEffect } from 'react'
import {
    Container, Row, Col, Input, Button,
    UncontrolledDropdown,
    Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup, Form, Label,
    Table, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { isFinite } from 'lodash'

import { snakeCaseToTitleCase } from '../utils'
import Loader from '../Components/Loader'

const courseProgressAwardsKey = 'courseProgressAwards'
const gamesWonAwardsKey = 'gamesWonAwards'
const awardCategories = [courseProgressAwardsKey, gamesWonAwardsKey]


function Awards() {

    const [isLoading, setIsLoading] = useState(true)

    const [awards, setAwards] = useState([])
    const [awardDetails, setAwardDetails] = useState(null)

    const [selectedAwardCategory, setSelectedAwardCategory] = useState(awardCategories[0])

    const getAwards = () => {
        setIsLoading(true)
        axios.get('/awards/admin', { params: { category: selectedAwardCategory } })
            .then(res => {
                setIsLoading(false)
                setAwards(res.data.awards)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })
    }

    useEffect(() => {
        getAwards()
    }, [selectedAwardCategory])

    const onValueChange = (e) => {
        setAwardDetails({ ...awardDetails, [e.target.name]: e.target.value })
    }

    const submitAwardDetails = () => {

        const { editingAwardId, title, subtitle, value, rewardCoins } = awardDetails

        if (!title || !subtitle) {
            return alert("Enter title and subtitle")
        } else if ((!isFinite(parseInt(value)) || parseInt(value) < 1) || (!isFinite(parseInt(rewardCoins)) || parseInt(rewardCoins) < 1)) {
            return alert("Value and reward coins should be positive")
        }

        if (editingAwardId) {
            axios.post('/awards/admin', awardDetails)
            .then(() => {
                setAwardDetails(null)
                getAwards()
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })
        } else {
            axios.put('/awards/admin', { ...awardDetails, awardCategory: selectedAwardCategory })
            .then(() => {
                setAwardDetails(null)
                getAwards()
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })
        }

    }

    const deleteAward = (awardId, currentActiveFlag) => {
        if (window.confirm("Are you sure you want to delete this award?")) {
            axios.delete('/awards/admin', { data: { awardId, newActiveFlag: !currentActiveFlag } })
            .then(() => {
                setAwardDetails(null)
                getAwards()
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })
        }
    }

    return (
        <>

            {isLoading && <Loader />}

            {
                awardDetails != null &&
                <Modal isOpen={true}>
                    <ModalHeader>
                        Enter Award Details
                    </ModalHeader>
                    <ModalBody>
                            <Form>
                                {
                                    ["title", "subtitle", "value", "rewardCoins"].map(fieldName => (
                                        <FormGroup key={fieldName}>
                                            <Label for={fieldName}> {snakeCaseToTitleCase(fieldName)}</Label>
                                            <Input
                                                id={fieldName}
                                                name={fieldName}
                                                onChange={onValueChange}
                                                value={awardDetails[fieldName] || ''}
                                            />
                                        </FormGroup>
                                    ))
                                }
                            </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={submitAwardDetails}
                        >
                            Submit
                        </Button>
                        <Button
                            outline
                            onClick={() => setAwardDetails(null)}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            }

            <Container>

                <Row>
                    <Col style={{ margin: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                            <UncontrolledDropdown>

                                <DropdownToggle color="primary" caret>
                                    {selectedAwardCategory}
                                </DropdownToggle>

                                <DropdownMenu className="addScroll">
                                    {
                                        awardCategories.map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each}
                                                    value={each}
                                                    onClick={() => setSelectedAwardCategory(each)}
                                                >
                                                    {each}
                                                </DropdownItem>)
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <Button outline color="success" onClick={ () => setAwardDetails({}) }>
                                + Add New Award in This Category
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Title</th>
                                <th>Subtitle</th>
                                <th>Value</th>
                                <th>Reward</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                awards.map((award, index) => (
                                    <tr
                                        key={award._id}
                                        style={{ background: award.activeFlag ?  'transparent' : 'rgba(255,0,0,0.1)' }}
                                    >
                                        <td>{index + 1}</td>
                                        <td>{award.title}</td>
                                        <td>{award.subtitle}</td>
                                        <td>{award.value}</td>
                                        <td>{award.rewardCoins} Coins</td>
                                        <td>
                                            <Button
                                                outline
                                                style={{ marginRight: 10 }}
                                                color="primary"
                                                onClick={() => {
                                                    setAwardDetails({
                                                        editingAwardId: award._id,
                                                        ...award
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon className="iconButton" icon={faPen} />
                                            </Button>

                                            <Button
                                                outline
                                                color="danger" 
                                                style={{ marginRight: 10 }}
                                                onClick={ () => deleteAward(award._id, award.activeFlag) }
                                            >
                                                <FontAwesomeIcon className="iconButton" icon={faTrash} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}

export default Awards
