import React from 'react'
import { Col, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import CheckBox from 'material-ui/Checkbox';
import TinyEditor from './TinyEditor'

const QuestionForm = ({ questionValue, questionOnChange, options, optionChange, optionTypeChange, explanation, explanationChange, questionPaperName, onQuestionPaperNameChange,
    correctAnswer, correctAnswerChange, questionType, toggleQuestionType, showCalculator, toggleShowCalculator, isMathExplanation, toggleMathExplanation }) => {

    return (
        <Form>
            <FormGroup>
                <Row>
                    <Col>
                        {
                            questionType === "text" ?
                                <FormGroup>
                                    <Input
                                        placeholder="Question"
                                        type="textarea"
                                        name="question"
                                        value={questionValue}
                                        onChange={(e) => questionOnChange(e.target.value)}
                                    />
                                </FormGroup>
                                :
                                <TinyEditor
                                    content={questionValue}
                                    onHtmlChange={(newHtml) => questionOnChange(newHtml)}
                                />
                        }
                    </Col>
                </Row>

            </FormGroup>

            <Row>

                <Col md="">
                    <FormGroup tag="fieldset">
                        <Input
                            type="select" name="select" id="questionType"
                            value={questionType}
                            onChange={(e) => toggleQuestionType(e.target.value)}
                        >
                            <option value="text">Text</option>
                            <option value="html">HTML</option>
                            <option value="math">Math</option>
                        </Input>
                    </FormGroup>
                </Col>

                {/* <Col md="3">
                    <CheckBox
                        label={'Math Question'}
                        style={{ marginTop: '10px' }}
                        checked={isMathQuestion}
                        onClick={() => }
                    />
                </Col> */}

                <Col md="6">
                    <CheckBox
                        label={'Show Calculator'}
                        style={{ marginTop: '10px' }}
                        checked={showCalculator}
                        onClick={() => toggleShowCalculator()}
                    />
                </Col>
            </Row>

            <hr />

            <Row>

                {
                    ["A", "B", "C", "D"].map((each, index) => {
                        return (
                            <Col md="6" key={each}>
                                <Row>
                                    <Col md="10">
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name={`option${each}`}
                                                placeholder={`Option ${each}`}
                                                value={options[index].text || ''}
                                                onChange={(e) => optionChange(e, index)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="2">
                                        <CheckBox
                                            style={{ marginTop: '10px' }}
                                            checked={options[index].type === "math"}
                                            onClick={() => optionTypeChange(index)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })
                }
            </Row>

            <Row>
                <Col md="9">
                    <FormGroup>
                        <Input
                            placeholder="Explanation"
                            type="textarea"
                            name="explanation"
                            value={explanation}
                            onChange={(e) => explanationChange(e)}
                        />
                    </FormGroup>
                </Col>

                <Col md="3">
                    <Label for="correctAnswerSelect">Correct Answer</Label>
                    <Input
                        type="select" name="select" id="correctAnswerSelect"
                        value={correctAnswer || ''}
                        onChange={(e) => correctAnswerChange(e)}
                    >
                        <option>Correct Answer</option>
                        <option>A</option>
                        <option>B</option>
                        <option>C</option>
                        <option>D</option>
                    </Input>
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <CheckBox
                        label={'Math Explanation'}
                        style={{ marginTop: '10px' }}
                        checked={isMathExplanation}
                        onClick={toggleMathExplanation}
                    />
                </Col>
            </Row>

            <hr />

            <Row>
                <Col md="12">
                    <FormGroup>
                        <Input
                            placeholder="Previous Question Paper Name (Optional)"
                            type="text"
                            name="questionPaperName"
                            value={questionPaperName}
                            onChange={(e) => onQuestionPaperNameChange(e)}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </Form>
    )
}

export default QuestionForm
