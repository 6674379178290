import React, { Component } from 'react'
import axios from 'axios'
import { Button, Card, Container, Row, Col, Badge, ListGroup, ListGroupItem, Collapse, CardBody, Input, FormGroup, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { connect } from 'react-redux'
import { cloneDeep, sortBy } from 'lodash'
import { Link } from 'react-router-dom';

class MockExam extends Component {

    state = {
        openIndex: null,
        mockExamName: '',
        subjects: [],
        selectedPosts: [],
        updatedPostsArray: [],
        languageId: "5b0326f395409b0f800bd785",
        showDialog: false,
        countDetails: [],
        isLoading: false
    }

    componentDidMount() {

        let subjects = cloneDeep(this.props.subjects)
        const activeposts = this.props.posts.filter(post => (post.active === true && post.approved === true))

        subjects.forEach((eachSubject, subjectIndex) => {
            eachSubject.sections.forEach((eachSection, sectionIndex) => {
                const foundSection = this.props.sectionsWithQuestionsCount.find((item) => item._id === eachSection._id)
                if (foundSection) {
                    subjects[subjectIndex].sections[sectionIndex].availableQuestions = foundSection.numOfQuestions
                }
            })
            eachSubject.sections = sortBy(eachSubject.sections, ['name'])
        })

        this.setState({ subjects, posts: activeposts })
    }

    showQuestionMissingAlert(questionNeeded, questionsFound, sectionId) {

        let subjcetName = ''
        let sectionName = ''

        // eslint-disable-next-line array-callback-return
        this.props.subjects.every((subject) => {
            const foundSection = subject.sections.find(section => section._id === sectionId)
            if (foundSection) {
                subjcetName = subject.name
                sectionName = foundSection.name
            } else {
                return true
            }
        })

        alert(`No enough questions in ${subjcetName} > ${sectionName}\nQuestions Needed: ${questionNeeded}\nQuestions Found: ${questionsFound}`)

    }

    submitMockexam = () => {

        let examIdsArray = []

        if (this.state.examIds) {
            let text = this.state.examIds
            let splittedExamIds = text.split("\n")
            examIdsArray = splittedExamIds.map(each => each.replace(/\s/g, ''))
        }

        let sectionIdWithQuestionCount = []

        this.state.subjects.forEach((eachSubject, index) => {
            eachSubject.sections.forEach((eachSection, index) => {
                if (eachSection.numOfQuestions) {
                    let pushObject = {
                        sectionId: eachSection._id,
                        questionCount: eachSection.numOfQuestions
                    }
                    if (eachSubject.languageId) {
                        pushObject.languageId = eachSubject.languageId
                    }
                    sectionIdWithQuestionCount.push(pushObject)
                }
            })
        });

        if (!this.state.mockExamName) {
            alert(`Please enter a mock exam name.`)
        } else if (this.state.selectedPosts.length < 1) {
            alert(`Please select at least one examination post.`)
        } else {

            this.setState({ isLoading: true })

            axios.put('/exam/mock', {
                name: this.state.mockExamName,
                posts: this.state.selectedPosts,
                sectionIdWithQuestionCount,
                examIds: examIdsArray
            })
                .then(res => {
                    const { countDetails, examId } = res.data
                    countDetails.forEach((item, index) => {
                        this.state.subjects.forEach(subject => {
                            const foundSection = subject.sections.find(section => section._id === item.sectionId)
                            if (foundSection) {
                                countDetails[index].subjcetName = subject.name
                                countDetails[index].sectionName = foundSection.name
                            }
                        })
                    })
                    this.setState({ showDialog: true, countDetails, examId, isLoading: false })

                })
                .catch(err => {

                    if (err && err.response && err.response.data) {
                        this.showQuestionMissingAlert(err.response.data.questionNeeded, err.response.data.questionsFound, err.response.data.sectionId)
                    } else {
                        console.log(err)
                        alert('Error. Please check console')
                    }
                    this.setState({ isLoading: false })
                })
        }

    }

    handleCheckBoxChange(postId, index) {

        this.setState((oldState) => {

            let foundIndex = oldState.updatedPostsArray.indexOf(postId)

            if (foundIndex > -1) {
                oldState.updatedPostsArray.splice(foundIndex, 1);
                return { updatedPostsArray: oldState.updatedPostsArray }
            } else {
                oldState.updatedPostsArray.push(postId)
                return { updatedPostsArray: oldState.updatedPostsArray }
            }

        })
    }

    render() {

        let totalSelectedQuestions = 0

        return (
            <Container style={{ marginTop: 50 }}>
                
                <Modal
                    isOpen={this.state.showDialog}
                    size="lg"
                >
                    <ModalHeader>Successfuly Created Mock Exam</ModalHeader>

                    <ModalBody>
                        <div style={{ overflowY: 'scroll', height: 600 }}>
                            <h2> </h2>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Subject</th>
                                        <th>Section</th>
                                        <th>New Questions</th>
                                        <th>Repeated Questions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.countDetails.map((item, index) => {
                                            return (

                                                <tr
                                                    key={item.subjcetName + item.sectionName}
                                                >
                                                    <td>{index + 1}</td>
                                                    <td>{item.subjcetName}</td>
                                                    <td>{item.sectionName}</td>
                                                    <td>{item.nonRepeatCount}</td>
                                                    <td>{item.repeatedCount}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>

                        </div>

                    </ModalBody>

                    <ModalFooter>

                        <Link 
                            to={`/exams/edit?mockExamId=${this.state.examId}`}
                            target="_blank"
                        >
                            <Button color="primary">
                                Go to exam
                            </Button>
                        </Link>

                        <Link to="/exams/" >Close</Link >

                    </ModalFooter>
                </Modal>

                <Row>
                    <Modal isOpen={this.state.showPostsModals}>
                        <ModalHeader>
                            Select Examination Posts
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <div>
                                    {
                                        this.state.posts && this.state.posts.map((post, index) => {
                                            return (
                                                <CustomInput
                                                    id={post._id}
                                                    key={post._id}
                                                    value={post._id}
                                                    type="checkbox"
                                                    label={post.name}
                                                    onChange={(event) => this.handleCheckBoxChange(post._id)}
                                                    checked={this.state.updatedPostsArray.includes(post._id)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                outline
                                color="primary"
                                onClick={() => this.setState({ selectedPosts: this.state.updatedPostsArray, updatedPostsArray: [], showPostsModals: false })}>
                                SUBMIT
                            </Button>
                            <Button
                                outline
                                color="secondary"
                                onClick={() => this.setState({ updatedPostsArray: [], showPostsModals: false })}>
                                CANCEL
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Col md="8">
                        <ListGroup>
                            {
                                this.state.subjects.map((each, subjectIndex) => {

                                    const isOpen = this.state.openIndex === subjectIndex
                                    let totalQuestions = 0
                                    each.sections.forEach(element => {
                                        if (element.numOfQuestions) {
                                            totalQuestions += element.numOfQuestions
                                            totalSelectedQuestions += element.numOfQuestions
                                        }
                                    });

                                    return (
                                        <ListGroupItem action key={each._id} >

                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <p style={{ flex: 2 }}>
                                                    {each.name} {each.subtitle ? <><br /> <span style={{ fontSize: 13 }}>{each.subtitle}</span></> : null}
                                                </p>
                                                <p style={{ flex: 1 }}>{totalQuestions} Questions</p>
                                                <Input
                                                    type="select" name="select" id="languageId" style={{ width: 150, marginRight: 20 }}
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        this.setState((oldState) => {
                                                            oldState.subjects[subjectIndex].languageId = value
                                                            return { subjects: oldState.subjects }
                                                        })
                                                    }}
                                                    value={this.state.subjects[subjectIndex].languageId}
                                                >
                                                    <option value="5b0326f395409b0f800bd785">Malayalam</option>
                                                    <option value="5b0326f395409b0f800bd784">English</option>
                                                </Input>
                                                <Button
                                                    size="sm"
                                                    style={{ flex: 1 }}
                                                    color={isOpen ? "primary" : "secondary"}
                                                    onClick={() => this.setState({ openIndex: isOpen ? null : subjectIndex })}
                                                >
                                                    {isOpen ? "CLOSE" : "OPEN"}
                                                </Button>
                                            </div>

                                            {
                                                isOpen &&
                                                <Collapse isOpen={isOpen} style={{ marginTop: 10 }}>
                                                    {
                                                        each.sections.map((each, sectionIndex) => {
                                                            return (
                                                                <Card key={each._id} >
                                                                    <CardBody>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', }}>

                                                                            <div style={{ flex: 8 }}>
                                                                                {each.name} <Badge pill>{this.state.subjects[subjectIndex].sections[sectionIndex].availableQuestions}</Badge>
                                                                                <p></p>
                                                                            </div>

                                                                            <Input
                                                                                style={{ flex: 2 }}
                                                                                type="number"
                                                                                placeholder="No of questions"
                                                                                value={each.numOfQuestions || ''}
                                                                                onChange={(e) => {
                                                                                    e.stopPropagation()
                                                                                    const value = e.target.value
                                                                                    if (value >= 0) {
                                                                                        this.setState((oldState) => {
                                                                                            if (value > oldState.subjects[subjectIndex].sections[sectionIndex].availableQuestions) {
                                                                                                alert(`Only ${oldState.subjects[subjectIndex].sections[sectionIndex].availableQuestions} questions available`)
                                                                                            } else {
                                                                                                oldState.subjects[subjectIndex].sections[sectionIndex].numOfQuestions = parseInt(value)
                                                                                                return oldState
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                            }

                                        </ListGroupItem>
                                    )
                                })
                            }
                        </ListGroup>
                    </Col>
                    <Col md="4">
                        <ListGroup>

                            <ListGroupItem>
                                <h5>Mock Test Name</h5>
                                <Input
                                    value={this.state.mockExamName}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ mockExamName: value })
                                    }}
                                />
                            </ListGroupItem>

                            <ListGroupItem>
                                <h5>Examination Posts</h5>
                                <p>{this.state.selectedPosts.length} posts selected</p>
                                <Button outline color="secondary" block onClick={() => this.setState({ updatedPostsArray: JSON.parse(JSON.stringify(this.state.selectedPosts)), showPostsModals: true })}>Edit Posts</Button>
                            </ListGroupItem>

                            <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <h5>Total Questions</h5>
                                <h4>{totalSelectedQuestions}</h4>
                            </ListGroupItem>

                            <ListGroupItem>
                                <h5>Exam IDs</h5>
                                <p>Only fetch questions from the below give examID exams</p>
                                <Input
                                    type="textarea"
                                    rows="6"
                                    name="examIds"
                                    id="examIds"
                                    value={this.state.examIds || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ examIds: value })
                                    }}
                                    placeholder={"One exam ID per line please"}
                                />
                            </ListGroupItem>


                        </ListGroup>
                        <Button block color="primary" outline style={{ marginTop: 20 }} onClick={this.submitMockexam} >SUBMIT</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

}

const mapStateToProps = state => {
    return {
        posts: state.data.posts,
        subjects: state.data.subjects,
        sectionsWithQuestionsCount: state.data.sectionsWithQuestionsCount
    }
}

export default connect(mapStateToProps)(MockExam)