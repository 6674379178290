import React from 'react'
import Axios from 'axios'
import axios from 'axios'


import {
    Col, Row, Container, Button, Badge,
    Modal, ModalHeader, ModalBody, ModalFooter, Label,
    Form, FormGroup, Input, Spinner, Nav, NavItem, NavLink,
    TabContent, TabPane
} from 'reactstrap';

import CheckBox from 'material-ui/Checkbox';
import moment from 'moment';
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faFolder, faArrowLeft, faCopy } from '@fortawesome/free-solid-svg-icons'

import { cloneDeep } from 'lodash';
import TinyEditor from '../../Components/TinyEditor';
import { copyToClipboard, isValidObjectId } from '../../utils'
import { toast } from 'react-toastify'

const tabs = [
    { key: '0', title: "Video Info", value: "videoInfo" },
    { key: '1', title: "Comments", value: "comments" }
]

class EditVideoContent extends React.Component {

    state = {
        showHtmlEditor: false,
        isVideoListModalOpen: false,
        foldersList: [],
        videosList: [],
        childFolders: [],
        enableEditMode: false,
        isLoading: false,
        isOpenIndex: true,
        activeTab: '0',
        replyingCommentId: null
    }

    renderVideoExplanationEditor(moduleData) {
        return (
            <Modal
                size="lg"
                style={{ maxWidth: '90%' }}
                isOpen={this.state.showHtmlEditor}
            >
                <ModalHeader>Enter Video Decription Content</ModalHeader>

                <ModalBody>
                    <TinyEditor
                        height={500}
                        content={this.state.newExplanation}
                        onHtmlChange={ (newExplanation) => this.setState({ newExplanation }) }
                    />
                </ModalBody>

                <ModalFooter>

                    <Col>
                        <CheckBox
                            label={'Math Content'}
                            style={{ marginTop: '10px' }}
                            checked={moduleData.content.type === "math"}
                            onClick={() => {

                                const newModuleData = cloneDeep(moduleData)

                                if (newModuleData.content.type === "html") {
                                    newModuleData.content.type = "math"
                                } else {
                                    newModuleData.content.type = "html"
                                }

                                this.props.updateModuleData(newModuleData)

                            }}
                        />
                    </Col>
                    
                    <Button color="secondary" onClick={() => this.setState({ showHtmlEditor: false, newExplanation: '' })}>Cancel</Button>
                    <Button
                        color="primary"
                        onClick={ () => {
                            const moduleData = cloneDeep(this.props.moduleData)
                            moduleData.content.explanation = this.state.newExplanation
                            this.setState({ showHtmlEditor: false, newExplanation: null })
                            this.props.updateModuleData(moduleData)
                        }}
                    >Save</Button>

                </ModalFooter>
            </Modal>
        );
    }

    fetchVideoFolderListAndOpenModal() {
        this.setState({ isLoading: true }, () => {
            Axios.get('vimeo-folder-list')
                .then(res => {
                    this.setState({
                        foldersList: res.data,
                        isVideoListModalOpen: true,
                        showParentFolders: true,
                        isLoading: false
                    })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error. Check console")
                })
        })
    }

    fetchVideosListAndOpenFolder(onPressIndex, projectId, child_folders) {
        this.setState({ isLoading: true, videosList: [], isOpenIndex: onPressIndex === this.state.isOpenIndex ? null : onPressIndex }, () => {
            Axios.get('vimeo-videos-list', { params: { projectId } })
                .then(res => {
                    this.setState({
                        childFolders: child_folders,
                        videosList: res.data,
                        showParentFolders: false,
                        isLoading: false,
                    })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error. Check console")
                })
        })
    }

    selectVideo(videoId, duration) {
        let moduleData = cloneDeep(this.props.moduleData)
        moduleData.content['url'] = videoId
        moduleData.content['duration'] = duration
        this.props.updateModuleData(moduleData)
        this.setState({ isVideoListModalOpen: false })
    }

    renderChildFoldersAndVideos(childContent, index) {

        var seconds = parseInt(childContent.duration)
        let momentFormatType = seconds >= 3600 ? 'HH:mm:ss' : "mm:ss"
        const formattedTime = moment.utc(seconds * 1000).format(momentFormatType)
        const { url } = this.props.moduleData.content

        return (
            <div 
                onClick={() => {
                    if (!childContent.videoId) {
                        this.fetchVideosListAndOpenFolder(index, childContent.folderId, childContent.child_folders)
                    } 
                }}
                style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
                { childContent.videoId ? 
                    <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column'}}
                    >
                        <img 
                            onClick={() => window.open(`https://vimeo.com/${childContent.videoId}`, "_blank")}
                            className='videoImg' style={{ borderRadius: 3, padding: 1, zIndex: 5 }} src={childContent.thumbnail.link} alt="boohoo" 
                        />
                        <span className='hide' style={{ position: 'absolute', top: 25, fontSize: 17, fontWeight: 'bold' }}>watch video</span>
                        <div>
                            
                            <strong>{childContent.titleName} {childContent.folderName}</strong> ({formattedTime})
                            <br />
                            {moment(childContent.createdTime).format('D MMM YYYY')}

                            <Button
                                color='primary'
                                outline
                                disabled={childContent.videoId === url}
                                size="sm"
                                style={{ marginLeft: 5 }}
                                onClick={() => { this.selectVideo(childContent.videoId, formattedTime) }}
                            >
                               {(childContent.videoId === url) ? 'Selected': 'Select'}
                            </Button>
                        </div>
                    </div>
                    :
                    <div className='videoFolder'>
                        <FontAwesomeIcon icon={faFolder} style={{ fontSize: 75, color: '#ADD8E6' }}/>
                        <div>
                            <strong style={{ flex: 1, fontSize: 18, textAlign: 'center' }}>{childContent.folderName}</strong>
                            <p style={{ flex: 1, fontSize: 18, textAlign: 'center' }}>{moment(childContent.createdTime).format('D MMM YYYY')}</p>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderParentFolders(each,index) {
        return (
            <div 
                className='videoFolder'
                onClick={() => {
                    this.fetchVideosListAndOpenFolder(index, each.folderId, each.child_folders)
                }}
                style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
                <FontAwesomeIcon icon={faFolder} style={{ fontSize: 75, color: '#ADD8E6' }}/>
                <div>
                    <strong style={{ flex: 1, fontSize: 18, textAlign: 'center' }}>{each.folderName}</strong>
                    <p style={{ flex: 1, fontSize: 18, textAlign: 'center' }}>{moment(each.createdTime).format('D MMM YYYY')}</p>
                </div>
            </div>
        )
    }

    renderVideoListModal() {
        return (
            <Modal
                size="lg"
                style={{ maxWidth: '90%' }}
                isOpen={this.state.isVideoListModalOpen}
            >
                <ModalHeader toggle={() => this.setState({ isVideoListModalOpen: false })}>
                    Select Video
                </ModalHeader>

                <ModalBody>
                    
                    {!this.state.showParentFolders &&
                        <Button
                            onClick={() => {
                                this.setState({ videosList: [], childFolders: [], showParentFolders: true })
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 12, color: '#ADD8E6' }} />
                        </Button>
                    }

                    <Row>
                        {
                            this.state.isLoading ?
                                <Col style={{ height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Spinner color="info" />
                                </Col>
                                :
                                this.state.showParentFolders ?
                                    this.state.foldersList.map((each, index) => (
                                        <Col
                                            md="2"
                                            sm="3"
                                            key={each.folderId}
                                        >
                                            {this.renderParentFolders(each, index)}
                                        </Col>
                                    ))
                                    :
                                    (this.state.videosList.length > 0 || this.state.childFolders.length > 0) ?

                                        [
                                            ...this.state.childFolders,
                                            ...this.state.videosList
                                        ].map((childContent, index) => {
                                            return (
                                                <Col
                                                    md="2"
                                                    sm="3"
                                                    key={childContent.folderName || childContent.videoId}
                                                >
                                                    {this.renderChildFoldersAndVideos(childContent, index)}
                                                </Col>
                                            )
                                        }) :
                                        <Col>
                                            No videos available..
                                        </Col>
                        }
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={() => this.setState({ isVideoListModalOpen: false, showParentFolders: false, childFolders: [], videosList: [] })}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    handleChangeInput = (e) => {
        let moduleData = cloneDeep(this.props.moduleData)
        moduleData.content[e.target.name] = e.target.value
        this.props.updateModuleData(moduleData)
    }

    handleVideoOutlineChange = (index, key, value) => {
        let moduleData = cloneDeep(this.props.moduleData)
        moduleData.content.videoOutline[index][key] = value
        this.props.updateModuleData(moduleData)
    }

    
    addReplyToComment(commentId, replyText) {

        if (!replyText) {
            return alert("Please enter a valid reply text")
        }

        this.setState({ isLoading: true })

        axios.post(`/learn/reply-video-comment`, {
            moduleId: this.props.moduleData._id,
            commentId: commentId,
            replyText
        })
            .then(res => {
                this.props.getModuleContent()
                this.setState({
                    activeTab: '1',
                    isLoading: false,
                    replyText: null,
                    replyingCommentId: null
                })
            })
            .catch(err => {
                this.setState({ isLoading: false })
                console.log(err)
                alert("Server error, check console")
            })

    }

    deleteComment(commentId) {
        if (window.confirm("Are you sure you want to delete this comment?")) {
            axios.delete(`/learn/delete-video-comment`, {
                params: {
                    moduleId: this.props.moduleData._id,
                    commentId: commentId
                }
            })
                .then(res => {
                    this.props.getModuleContent()
                    this.setState({ activeTab: '1' })
                })
                .catch(err => {
                    console.log(err)
                    alert("Server error, check console")
                })
        }
    }

    renderCommentsTab() {
        
        const { comments } = this.props.moduleData
        
        return (

            comments.map((each, index) => {
                return (
                    <Row key={each._id}>
                        <Col>
                            <p>{each.text}</p>

                            {
                                each.replyText ?
                                    <p> <strong>Reply:</strong> <span style={{ color: 'grey' }}> {each.replyText} </span></p>
                                    : null
                            }

                            <p style={{ color: "gray" }}>
                                {moment(each.dateAndTime).format("YYYY MMMM D")}
                            </p>
                        </Col>
                        <Col>
                            <Button
                                outline
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    copyToClipboard(each.userId)
                                    toast("User ID copied", {
                                        position: toast.POSITION.TOP_CENTER,
                                        hideProgressBar: true
                                    })
                                }}
                            >
                                <FontAwesomeIcon
                                    className="iconButton"
                                    style={{ color: 'grey' }}
                                    icon={faCopy}
                                />
                            </Button>

                            <Button
                                color='primary'
                                outline
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    this.setState({ replyingCommentId: each._id, replyText: each.replyText })
                                }}
                            >
                                {each.replyText ? "Edit Reply" : "Add Reply"}
                            </Button>

                            <Button
                                color='danger'
                                outline
                                onClick={() => {
                                    this.deleteComment(each._id)
                                }}
                            >
                                Delete comment
                            </Button>
                            
                        </Col>
                        <hr style={{ height: 2, color: 'grey', width: '100%' }} />
                    </Row>
                )
            })

        )
    }

    renderVideoInfo() {

        const {
            duration,
            url,
            videoSource,
            videoOutline,
            explanationSlideId,
            relatedQuizModuleId,
            teacherId
        } = this.props.moduleData.content

        return (
            <>
                <Row>
                    <Col md="4">
                        <Button
                            color="primary"
                            outline
                            style={{ marginBottom: 20 }}
                            onClick={() => this.setState({ enableEditMode: !this.state.enableEditMode })}
                        >
                            {this.state.enableEditMode ? 'Disable Edit Mode' : 'Enable Edit Mode'}
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <Row>
                            <h5 style={{ marginLeft: '1rem' }}>Edit Video Module Details</h5>
                        </Row>

                        <Form>

                            <FormGroup tag="fieldset">
                                <Label>Select Video Source</Label>
                                <Input
                                    type="select"
                                    name="videoSource"
                                    id="videoSource"
                                    value={videoSource}
                                    disabled={!this.state.enableEditMode}
                                    onChange={this.handleChangeInput}
                                >
                                    <option value="vimeo">Vimeo Video</option>
                                    <option value="youtube">Youtube Video</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Input
                                    type="text"
                                    name="url"
                                    id="url"
                                    style={{ marginBottom: 15 }}
                                    disabled={!this.state.enableEditMode}
                                    value={url || ''}
                                    placeholder="Enter Video ID"
                                    onChange={this.handleChangeInput}
                                />

                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="duration"
                                        id="duration"
                                        disabled={!this.state.enableEditMode}
                                        value={duration || ''}
                                        placeholder="00:00"
                                        onChange={this.handleChangeInput}
                                    />

                                    <FormGroup tag="fieldset">
                                        <Label>Select Teacher</Label>
                                        <Input
                                            type="select"
                                            name="teacherId"
                                            id="teacherId"
                                            value={teacherId || "notSelected"}
                                            disabled={!this.state.enableEditMode}
                                            onChange={this.handleChangeInput}
                                        >
                                            <option value={"notSelected"}>Not Selected</option>
                                            {
                                                this.props.teachers.map(each => (
                                                    <option
                                                        key={each._id}
                                                        value={each._id}
                                                    >{each.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </FormGroup>

                                </FormGroup>

                                <Row>


                                    <Col md="4">
                                        <Button
                                            color="primary"
                                            outline
                                            disabled={url == null}
                                            onClick={() => {
                                                if (videoSource === "youtube") {
                                                    window.open(`https://youtu.be/${url}`, "_blank")
                                                } else {
                                                    window.open(`https://vimeo.com/${url}`, "_blank")
                                                }
                                            }}
                                        >Watch Video</Button>
                                    </Col>

                                    <Col md="4">
                                        {
                                            videoSource !== "youtube" ?
                                                <Button
                                                    color="primary"
                                                    disabled={!this.state.enableEditMode}
                                                    onClick={() => this.fetchVideoFolderListAndOpenModal()}
                                                >
                                                    {this.state.isLoading ? <Spinner /> : url ? "Change Video" : "Add Video"}
                                                </Button> : null
                                        }
                                    </Col>

                                </Row>
                            </FormGroup>
                        </Form>
                    </Col>

                    <Col md={{ size: 5, offset: 1 }}>
                        <Row style={{ marginBottom: 10 }}>
                            <h5 style={{ marginLeft: '1rem' }}>Video Outline</h5>
                        </Row>

                        <Row style={{ marginBottom: 10 }}>
                            <Col md="4">Time</Col>
                            <Col md="8">Title</Col>
                        </Row>

                        <Row style={{ marginBottom: 10 }}>
                            {
                                (Array.isArray(videoOutline) && videoOutline.length > 0) ? 
                                    videoOutline.map((each, index) => {
                                        return (
                                            <Row
                                                key={each._id}
                                                style={{ marginTop: 5 }}
                                            >

                                                <Col md="4">
                                                    <Input
                                                        placeholder="Example 00:00"
                                                        onChange={ (event) => this.handleVideoOutlineChange(index, "seconds", event.target.value)}
                                                        value={each.seconds}
                                                        disabled={!this.state.enableEditMode}
                                                    />
                                                </Col>
                                                <Col md="6">
                                                    <Input
                                                        placeholder="Enter title"
                                                        onChange={ (event) => this.handleVideoOutlineChange(index, "title", event.target.value)}
                                                        value={each.title}
                                                        disabled={!this.state.enableEditMode}
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <Button
                                                        outline
                                                        color={"danger"}
                                                        disabled={!this.state.enableEditMode}
                                                        onClick={() => {
                                                            let moduleData = cloneDeep(this.props.moduleData)
                                                            moduleData.content.videoOutline.splice(index, 1);
                                                            this.props.updateModuleData(moduleData)
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </Col></Row>
                                        )
                                    })
                                    : <Row>
                                        <Col>
                                            <p style={{ color:'grey'}}> Outline not added </p>
                                        </Col>
                                    </Row>
                            }
                        </Row>

                        <Row style={{ marginBottom: 10 }}>
                            <Col>
                                <Button
                                    color="primary"
                                    disabled={!this.state.enableEditMode}
                                    onClick={ () => {
                                        
                                        let moduleData = cloneDeep(this.props.moduleData)
                                        const itemToAdd = { _id: Math.random(), seconds: '', title: '' }

                                        if (Array.isArray(moduleData.content.videoOutline)) {
                                            moduleData.content.videoOutline.push(itemToAdd)
                                        } else {
                                            moduleData.content.videoOutline = [ itemToAdd ]
                                        }

                                        this.props.updateModuleData(moduleData)

                                    }}
                                >Add New Item</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <hr />

                <Container fluid>

                    <Row style={{ alignContent: 'center' }}>
                        <Col md="4">
                            <FormGroup tag="fieldset">
                                <Label>Video Explanation Slide ID</Label>
                                <Input
                                    type="text"
                                    name="explanationSlideId"
                                    id="explanationSlideId"
                                    disabled={!this.state.enableEditMode}
                                    value={explanationSlideId}
                                    onChange={this.handleChangeInput}
                                />
                            </FormGroup>
                        </Col>

                        {
                            isValidObjectId(this.props.moduleData.content.explanationSlideId) &&
                            <Col md="2">
                                <Link
                                    to={`/module/${this.props.moduleData.content.explanationSlideId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ position: 'absolute', bottom: 0, paddingBottom: 15 }}
                                >
                                    <Button
                                        color="primary"
                                        outline
                                    >
                                        Edit Notes
                                    </Button>
                                </Link>
                            </Col>
                        }

                        {
                            this.props.moduleData.content.explanationSlideTitle ?
                                <Col md="12">
                                    <p>Related Module: {this.props.moduleData.content.explanationSlideTitle}</p>
                                </Col>
                                : null
                        }
                    </Row>

                    <hr />

                    <Row>

                        <Col md="4">
                            <FormGroup tag="fieldset">
                                <Label>Related Quiz Module ID</Label>
                                <Input
                                    type="text"
                                    name="relatedQuizModuleId"
                                    id="relatedQuizModuleId"
                                    disabled={!this.state.enableEditMode}
                                    value={relatedQuizModuleId}
                                    onChange={this.handleChangeInput}
                                />
                            </FormGroup>
                        </Col>

                        {
                            isValidObjectId(this.props.moduleData.content.relatedQuizModuleId) &&
                            <Col md="2">
                                <Link
                                    to={`/module/${this.props.moduleData.content.relatedQuizModuleId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ position: 'absolute', bottom: 0, paddingBottom: 15 }}
                                >
                                    <Button
                                        color="primary"
                                        outline
                                    >
                                        Edit Quiz
                                    </Button>
                                </Link>
                            </Col>
                        }

                        {
                            this.props.moduleData.content.relatedQuizName ?
                                <Col md="12">
                                    <p>Related Quiz: {this.props.moduleData.content.relatedQuizName}</p>
                                </Col>
                                : null
                        }

                    </Row>

                    <hr />

                    <h4>Explanation</h4>
                    <Row>
                        {
                            this.props.moduleData.content.explanation ?
                                <Col>
                                    <div
                                        style={{
                                            marginTop: 20,
                                            padding: 10,
                                            display: 'block',
                                            boxShadow: `0px 0px 5px #c8c8c8`
                                        }}
                                        dangerouslySetInnerHTML={{ __html: this.props.moduleData.content.explanation }}
                                    />

                                    <Button
                                        color="primary"
                                        style={{ margin: 10 }}
                                        onClick={() => this.setState({ newExplanation: this.props.moduleData.content.explanation, showHtmlEditor: true })}
                                    >Edit Explanation</Button>

                                </Col>
                                :
                                <Col>
                                    <p>Explanation not added yet</p>
                                    <Button
                                        color="primary"
                                        style={{ margin: 10 }}
                                        disabled={!this.state.enableEditMode}
                                        onClick={() => this.setState({ newExplanation: null, showHtmlEditor: true })}
                                    >Add Explanation</Button>
                                </Col>
                        }
                    </Row>

                </Container>
            </>

        )
    }

    
    renderCommentReplyModal(moduleData) {
        return (
            <Modal
                isOpen={true}
                size="lg"
            >
                <ModalBody>
                    <FormGroup tag="fieldset">
                        <Label>Enter Reply to Comment</Label>
                        <TinyEditor
                            height={500}
                            content={this.state.replyText}
                            onHtmlChange={(replyText) => this.setState({ replyText })}
                        />
                    </FormGroup>
                </ModalBody>

                <ModalFooter>

                    
                    <Button
                        color="primary"
                        onClick={() => this.addReplyToComment(this.state.replyingCommentId, this.state.replyText) }
                    >Save</Button>
                    
                    <Button
                        color="secondary"
                        onClick={() => this.setState({ replyingCommentId: null })}
                    >Cancel</Button>

                </ModalFooter>
            </Modal>
        );
    }


    render() {
        return (
            <Container fluid>
                
                { this.state.showHtmlEditor && this.renderVideoExplanationEditor(this.props.moduleData) }
                { this.state.isVideoListModalOpen && this.renderVideoListModal() }
                { this.state.replyingCommentId ? this.renderCommentReplyModal() : null }

                <Nav tabs>
                    {
                        tabs.map((each) => {
                            return (
                                <NavItem key={each.key}>
                                    <NavLink
                                        className={this.state.activeTab === each.key ? 'active' : ''}
                                        onClick={() => {
                                            this.setState({ activeTab: each.key })
                                        }}>
                                        {each.title} 

                                        {
                                            each.key === "1" &&
                                            <Badge style={{ marginLeft: 10 }}>{
                                                (this.props.moduleData && Array.isArray(this.props.moduleData.comments)) ?
                                                    this.props.moduleData.comments.length : 0
                                            }</Badge>
                                        }
                                        
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                </Nav>

                <TabContent activeTab={this.state.activeTab} style={{ marginTop: '5vh' }}>

                    <TabPane tabId={'0'} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                        {this.renderVideoInfo()}
                    </TabPane>

                    <TabPane tabId={'1'} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                        {this.renderCommentsTab()}
                    </TabPane>

                </TabContent>
            </Container>
        );
    }
}

export default EditVideoContent;
