import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Input, Table } from 'reactstrap';
import { shuffle, isEqual, random } from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';
import { fixSpacesBetweenStrings } from '../../utils';

import { usePrevious } from '../../utils';

export const generateMatchHtmlQuestion = (
    questionTitle,
    statement1,
    statement2,
    statement3,
    statement4,
    shuffledsecondColumn
) => {

    let html = `
        <style>
            table,
            th,
            td {
                border: 1px solid grey;
            }
        </style>
        
        <p><span style="color: rgb(255,255,255)">${questionTitle}</p>

        <table>
            <tr>
                <td><span style="color: rgb(255,255,255)">${statement1}</span></td>
                <td><span style="color: rgb(255,255,255)">${shuffledsecondColumn[0]} </td>
            </tr>

            <tr>
                <td><span style="color: rgb(255,255,255)">${statement2}</span></td>
                <td><span style="color: rgb(255,255,255)">${shuffledsecondColumn[1]}</td>
            </tr>

            <tr>
                <td><span style="color: rgb(255,255,255)">${statement3}</span></td>
                <td><span style="color: rgb(255,255,255)">${shuffledsecondColumn[2]} </td>
            </tr>
            <tr>
                <td><span style="color: rgb(255,255,255)">${statement4}</span></td>
                <td><span style="color: rgb(255,255,255)">${shuffledsecondColumn[3]} </td>
            </tr>
        </table>
    `

    return html

}

function MatchType({ questionId,  onBlurQuestion, currentBaseQuestion, currentStatements, updateOptions }) {

    const [questionTitle, setQuestionTitle] = useState("")
    
    const [statement1, setStatement1] = useState("")
    const [statement1b, setStatement1b] = useState("")

    const [statement2, setStatement2] = useState("")
    const [statement2b, setStatement2b] = useState("")

    const [statement3, setStatement3] = useState("")
    const [statement3b, setStatement3b] = useState("")

    const [statement4, setStatement4] = useState("")
    const [statement4b, setStatement4b] = useState("")

    const [shuffledsecondColumn, setShuffledsecondColumn] = useState([])
    const prevQuestionId = usePrevious(questionId)

    useEffect(() => {
        if (questionId !== prevQuestionId && currentBaseQuestion && (Array.isArray(currentStatements) && currentStatements.length === 4)) {
            unstable_batchedUpdates(() => {
                setQuestionTitle(currentBaseQuestion)
                setStatement1(currentStatements[0].statementLeftSide)
                setStatement1b(currentStatements[0].statementRightSide)
                setStatement2(currentStatements[1].statementLeftSide)
                setStatement2b(currentStatements[1].statementRightSide)
                setStatement3(currentStatements[2].statementLeftSide)
                setStatement3b(currentStatements[2].statementRightSide)
                setStatement4(currentStatements[3].statementLeftSide)
                setStatement4b(currentStatements[3].statementRightSide)
                setShuffledsecondColumn([
                    currentStatements[0].shuffledOption,
                    currentStatements[1].shuffledOption,
                    currentStatements[2].shuffledOption,
                    currentStatements[3].shuffledOption,
                ])
            })
        }
    }, [questionId, prevQuestionId, currentBaseQuestion, currentStatements])

    const generateOptions = () => {

        if (!statement1 || !statement1b || !statement2 || !statement2b || !statement3 || !statement3b || !statement4 || !statement4b) {
            return alert("Enter all statment Fields")
        }
        // Alter this array, if we require more options in the match the following table.
        let secondColumnNum = [1, 2, 3, 4]

        let shuffledsecondColumnVar = [];

        // switch the order and keep the correct options order in another value.
        let shuffledOptions = shuffle(secondColumnNum)
        // Eg: [4, 2, 1, 3] => then correct answer is A-4, B-2, C-1, D-3

        // now we have shuffled the option, but we lost the correct answer order.
        //lets bring it back to an array.

        let correctOptionsInOrder = []

        for (let i = 0; i < shuffledOptions.length; i++) {
            if (shuffledOptions[i] === 1) {
                shuffledsecondColumnVar.push(statement1b)
                correctOptionsInOrder.push(shuffledOptions.indexOf(i + 1) + 1)
            } else if (shuffledOptions[i] === 2) {
                shuffledsecondColumnVar.push(statement2b)
                correctOptionsInOrder.push(shuffledOptions.indexOf(i + 1) + 1)
            } else if (shuffledOptions[i] === 3) {
                shuffledsecondColumnVar.push(statement3b)
                correctOptionsInOrder.push(shuffledOptions.indexOf(i + 1) + 1)
            } else {
                shuffledsecondColumnVar.push(statement4b)
                correctOptionsInOrder.push(shuffledOptions.indexOf(i + 1) + 1)
            }
        }

        // Create options for the questions
        let CorrectAnswerOption = {
            id: 1,
            isCorrect: true,
            text: createString(correctOptionsInOrder)
        }

        // Now we need 3 combinations of wrong options.
        // use permutations of total options count.
        let permutationsArr = permute(secondColumnNum);

        // select random 3 combinations which is not equals to correct answer combination.
        const wrongOptions = []

        while (wrongOptions.length < 3) {
            const randomNumber = random(0, permutationsArr.length - 1)
            if (!isEqual(permutationsArr[randomNumber], correctOptionsInOrder)) {
                wrongOptions.push(permutationsArr[randomNumber])
                permutationsArr.splice(randomNumber, 1)
            }
        }

        let firstWrongOption = {
            id: 2,
            isCorrect: false,
            text: createString(wrongOptions[0])
        }

        let secondWrongOption = {
            id: 3,
            isCorrect: false,
            text: createString(wrongOptions[1])
        }

        let thirdWrongOption = {
            id: 4,
            isCorrect: false,
            text: createString(wrongOptions[2])
        }

        let allOptions = [CorrectAnswerOption, firstWrongOption, secondWrongOption, thirdWrongOption]

        const statements = [
            {
                id: 1,
                statementLeftSide: statement1,
                statementRightSide: statement1b,
                shuffledOption: shuffledsecondColumnVar[0]
            },
            {
                id: 2,
                statementLeftSide: statement2,
                statementRightSide: statement2b,
                shuffledOption: shuffledsecondColumnVar[1]
            },
            {
                id: 3,
                statementLeftSide: statement3,
                statementRightSide: statement3b,
                shuffledOption: shuffledsecondColumnVar[2]
            },
            {
                id: 4,
                statementLeftSide: statement4,
                statementRightSide: statement4b,
                shuffledOption: shuffledsecondColumnVar[3]
            },
        ]

        const optionsShuffle = shuffle(allOptions)

        updateOptions(questionTitle, statements, optionsShuffle)
        setShuffledsecondColumn(shuffledsecondColumnVar)


    }

    const createString = (array) => {
        let newstring = "";  // if array => [4, 2, 1, 3]
        newstring = `A-${array[0]}, B-${array[1]}, C-${array[2]}, D-${array[3]}`
        // then correct option is => A-4, B-2, C-1, D-3
        return newstring;
    }

    const permute = (permutation) => {

        var length = permutation.length,
            result = [permutation.slice()],
            c = new Array(length).fill(0),
            i = 1, k, p;

        while (i < length) {
            if (c[i] < i) {
                k = i % 2 && c[i];
                p = permutation[i];
                permutation[i] = permutation[k];
                permutation[k] = p;
                ++c[i];
                i = 1;
                result.push(permutation.slice());
            } else {
                c[i] = 0;
                ++i;
            }
        }

        return result
    }

    const hasGeneratedOptions = Array.isArray(shuffledsecondColumn) && shuffledsecondColumn.length > 0

    return (
        <>
            <Row>
                <Col md="12">
                    <Form>
                        <Input
                            id={"questionTitle"}
                            value={questionTitle || ''}
                            placeholder="Main part of question"
                            name="questionTitle"
                            onChange={ (e) => setQuestionTitle(e.target.value) }
                            onBlur={ () => {
                                const spacesFixedQuestion = fixSpacesBetweenStrings(questionTitle)
                                setQuestionTitle(spacesFixedQuestion)
                                onBlurQuestion(spacesFixedQuestion)
                            }}
                            style={{ marginTop: 10, marginBottom: 20, backgroundColor: 'rgba(0, 34, 255,0.1)', borderRadius: 5, padding: 10 }}
                        />
                    </Form>
                </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement1 || ''}
                        placeholder="Statement 1"
                        name="statement1"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement1(spacesFixedValue)
                        }}
                    />
                </Col>

                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement1b || ''}
                        placeholder="Statement 1b"
                        name="statement1b"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement1b(spacesFixedValue)
                        }}
                    />
                </Col>

            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement2 || ''}
                        placeholder="Statement 2"
                        name="statement2"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement2(spacesFixedValue)
                        }}
                    />
                </Col>

                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement2b || ''}
                        placeholder="Statement 2b"
                        name="statement2b"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement2b(spacesFixedValue)
                        }}
                    />
                </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement3 || ''}
                        placeholder="Statement 3"
                        name="statement3"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement3(spacesFixedValue)
                        }}
                    />
                </Col>

                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement3b || ''}
                        placeholder="Statement 3b"
                        name="statement3b"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement3b(spacesFixedValue)
                        }}
                    />
                </Col>

            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement4 || ''}
                        placeholder="Statement 4"
                        name="statement4"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement4(spacesFixedValue)
                        }}
                    />
                </Col>

                <Col md="6">
                    <Input
                        id={"1"}
                        value={statement4b || ''}
                        placeholder="Statement 4b"
                        name="statement4b"
                        onChange={ (e) => {
                            const spacesFixedValue = fixSpacesBetweenStrings(e.target.value)
                            setStatement4b(spacesFixedValue)
                        }}
                    />
                </Col>

            </Row>

            <Button
                color="primary"
                outline
                style={{ marginTop: 20 }}
                onClick={() => generateOptions()}
            >Generate Options</Button>

            <hr style={{ marginTop: 25, marginBottom: 25 }} />

            <Row>
                <Col md="12">
                    <p> <b>Question: </b></p>
                    <p>{questionTitle}</p>

                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>A. {statement1}</td>
                                {hasGeneratedOptions && <td>1. {shuffledsecondColumn[0]}</td>}
                            </tr>
                            <tr>
                                <td>B. {statement2}</td>
                                {hasGeneratedOptions && <td>2. {shuffledsecondColumn[1]}</td>}
                            </tr>
                            <tr>
                                <td>C. {statement3}</td>
                                {hasGeneratedOptions && <td>3. {shuffledsecondColumn[2]}</td>}
                            </tr>
                            <tr>
                                <td>D. {statement4}</td>
                                {hasGeneratedOptions && <td>4. {shuffledsecondColumn[3]}</td>}
                            </tr>
                        </tbody>

                    </Table>

                </Col>
            </Row>
        </>
    )
}

export default MatchType