import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { unstable_batchedUpdates } from 'react-dom'
import { Link } from 'react-router-dom'

import moment from 'moment'


import { Checkbox } from 'material-ui'
import { cloneDeep }from 'lodash'
import axios from 'axios'
import queryString from 'query-string';
import { Container, Table, Button, Row, Col, Label, Input, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { copyToClipboard } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

import Loader from '../Components/Loader'
import { toast } from 'react-toastify'

function ChallengesNoteViewer({ subjects, location, history }) {

    const fetchData = useRef(false)

    const [data, setData] = useState([])
    const [dataCount, setDataCount] = useState([])
    const [subjectId, setSubjectId] = useState("")
    const [sectionId, setSectionId] = useState("")
    const [sectionData, setSection] = useState([])
    const [skipCount, setSkipCount] = useState(0)
    const [questionIdsToCopyArr, setQuestionIdsToCopyArr] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const fetchChallengeHistory = () => {

        setIsLoading(true)

        const { activeTab, period } = queryString.parse(location.search)

        let paramsObject = {
            skipCount: skipCount ? parseInt(skipCount) : 0,
            limit: 50,
            periodType: activeTab,
            period,
        }

        if (subjectId) {
            paramsObject.subjectId = subjectId
        }

        if (subjectId && sectionId) {
            paramsObject.subjectId = subjectId
            paramsObject.sectionId = sectionId
        }


        axios.get('/all-challenge-notes', { params: paramsObject })
            .then(res => {
                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setData(res.data.notes)
                    setDataCount(res.data.notesCount)
                    setQuestionIdsToCopyArr([])
                })
                fetchData.current = true
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Please check console")
            })
    }

    const selectedSubjectOnChangeHandler = (e) => {
        const value = e.target.value
        setSubjectId(value)
    }

    useEffect(() => {

        fetchChallengeHistory()

        if (subjectId !== "selectSubject" && subjectId) {
            const foundSubject = subjects.find(e => e._id === subjectId)
            if (foundSubject) {

                unstable_batchedUpdates(() => {
                    setSectionId("allSections")
                    setSection(foundSubject.sections)
                })

            } else {
                alert("Subject not found")
            }
        }
    }, [subjectId])

    useEffect(() => {
        if (subjectId) {
            fetchChallengeHistory()
        }
    }, [sectionId])

    const selectedSectionOnChangeHandler = (e) => {
        const value = e.target.value
        setSectionId(value)
    }

    useEffect(() => {
        if (fetchData.current){
            setQueryParams(skipCount)
        }
    }, [skipCount])

    const renderSkipMenu = () => {
        let returnArray = []
        let itemCount = parseInt(dataCount, 10) / 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => setSkipCount(index)}
                >
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    const setQueryParams = (index) => {

        const { activeTab, period } = queryString.parse(location.search)

        history.push({
            pathname: "/all-challenge-notes",
            search: `index=${index}&period=${period}&activeTab=${activeTab}`
        })

    }

    useEffect(() => {
        fetchChallengeHistory()
    }, [skipCount])

    const getParamsIndex = () => {
        let params = queryString.parse(location.search)
        if (params && params.index) {
            return parseInt(params.index)
        } else {
            return 0
        }
    }

    const addQuestionIdsToCopyArr = (questionId, checkedValue) => {
        if (checkedValue) {
            setQuestionIdsToCopyArr((currentQuestionIds) => [ ...currentQuestionIds, questionId ])     
        } else {
            const filteredIdsArr = questionIdsToCopyArr.filter(item => item !== questionId)
            setQuestionIdsToCopyArr(filteredIdsArr)
        }
    }

    const checkAllQuestions=(e,value)=>{
        if (value){
                const questionIdsArr = cloneDeep(data)                
                questionIdsArr.forEach(each => {
                    if (!questionIdsToCopyArr.includes(each._id)) {
                        setQuestionIdsToCopyArr((currentQuestionIds) => [ ...currentQuestionIds, each._id ])     
                    }
                })
               
        } else {
            setQuestionIdsToCopyArr([])        
        }
    }

    const copyIdsToClipboard =()=> {
        
        let string = ''
        const questionIdArr = cloneDeep(questionIdsToCopyArr)
        questionIdArr.forEach(item => {
            if (string) {
                string = `${string}\n${item}`
            } else {
                string = item
            }
        })

        var textField = document.createElement('textarea')
        textField.innerHTML = string
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        toast("Copied to clipboard", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        })

    }

    return (

        <Container fluid>
            
            {isLoading && <Loader />}

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                <Row>
                    <Col md="6">
                        <Label for="exampleSelect">Select Subject</Label>
                        <Input
                            value={subjectId ? subjectId : "selectSubject"}
                            type="select"
                            name={"subjectId"}
                            id={"subjectId"}
                            onChange={(e) => { selectedSubjectOnChangeHandler(e) }}
                        >
                            {
                                subjects ?
                                    [
                                        { _id: "selectSubject", name: "Select a subject" }, ...subjects].map((subject, index) => (
                                            <option key={subject._id} value={subject._id}>{subject.name}</option>
                                        ))
                                    :
                                    null
                            }
                        </Input>
                    </Col>
                    
                    <Col md="6">
                        <Label for="sectionId">Select Section</Label>
                        <Input
                            value={sectionId ? sectionId : 'allSections'}
                            type="select"
                            name={"sectionId"}
                            id={"sectionId"}
                            onChange={(e) => { selectedSectionOnChangeHandler(e) }}
                        >
                            {
                                Array.isArray(sectionData) && sectionData.length > 0 ?
                                    [{ _id: "allSections", name: "All Sections" }, ...sectionData].map(section => {
                                        return (
                                            <option
                                                key={section._id}
                                                value={section._id}
                                            >
                                                {section.name}
                                            </option>
                                        )
                                    })
                                    :
                                    [{ _id: "allSections", name: "All Sections" }].map(section => {
                                        return (
                                            <option key={section._id} value={section._id}>
                                                {section.name}
                                            </option>
                                        )
                                    })
                            }
                        </Input>
                    </Col>
                </Row>

                <UncontrolledDropdown>
                    <DropdownToggle caret
                        color="success" id="dropdown-basic"> Page {getParamsIndex() + 1}
                    </DropdownToggle>
                    <DropdownMenu className="addScroll">
                        {renderSkipMenu()}
                    </DropdownMenu>
                </UncontrolledDropdown>

            </div>

            <Row style={{display: 'flex',alignItems:'center', marginBottom: 10}}>
                <Checkbox
                    style={{ display: "inline", width: 50,marginLeft: 15 }}
                    onCheck={(e, value) => checkAllQuestions(e, value)}
                    checked={questionIdsToCopyArr.length === data.length}
                />
                <Button
                    style={{ marginRight: 20 }}
                    color='primary'
                    display="inline"
                    disabled={questionIdsToCopyArr.length === 0}
                    onClick={() => copyIdsToClipboard()}
                >
                    Copy Ids
                </Button>
                { questionIdsToCopyArr.length > 0 ? `${questionIdsToCopyArr.length} IDs selected` : null }
            </Row>

            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Check</th>
                            <th>No</th>
                            <th>Note Title</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((each, index) => {
                                return (
                                    <tr key={each._id}>
                                        
                                        <td>
                                            <Checkbox 
                                                onCheck={(e, value) => addQuestionIdsToCopyArr(each._id, value) } 
                                                checked={questionIdsToCopyArr.includes(each._id)}
                                            />
                                        </td>

                                        <td>{index + 1}</td>

                                        <td>
                                            {each.title}<br />
                                            <p style={{ color: "grey", display: 'flex', justifyContent: 'space-between' }}>
                                                {each.description}
                                            </p>
                                        </td>

                                        <td>
                                            <p style={{ fontSize: 15, color: "grey", display: 'flex', justifyContent: 'space-between' }}>
                                                {moment(each.date).format("D MMM YYYY")}
                                            </p>
                                        </td>

                                        <td>
                                            <Button
                                                outline
                                                color="primary"
                                                style={{ margin: 5 }}
                                            >
                                                <Link
                                                    to={`/daily-challenges?noteId=${each._id}`}
                                                    target="_blank"
                                                >
                                                    Edit
                                                </Link>
                                            </Button>

                                            <Button
                                                onClick={() => copyToClipboard(each._id)}
                                                outline
                                                style={{ margin: 5 }}
                                            >
                                                <FontAwesomeIcon
                                                    className="iconButton"
                                                    style={{ color: 'grey' }}
                                                    icon={faCopy}
                                                />
                                            </Button>
                                        </td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>

                </Table>
            </Row>
        </Container>
    )
}
const mapStateToProps = state => {
    return {
        subjects: state.data.subjects,
    }
}

export default connect(mapStateToProps)(ChallengesNoteViewer)
