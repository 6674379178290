import React, { Component } from 'react'
import { Container, Row, Col, Button, Table,
        DropdownItem,DropdownToggle,DropdownMenu,UncontrolledDropdown } from 'reactstrap'
import Axios from 'axios'
import { connect } from 'react-redux'
import Loader from '../Components/Loader'
import Aux from '../Components/Auxiliary'

class PrelimsAttendedDetails extends Component {

    state = {
        isLoading: false,
        topicAttendedDetails: null,
        postUnitsToFetchId: null,
        postUnitsToFetchName: null
    }

    getResult(postId) {
        this.setState({ isLoading: true }, () => {
            Axios.get("/prelims-attended-count", { params: { postId } })
                .then(res => {
                    if (res.data.topicAttendedCount) {
                        this.setState({
                            topicAttendedDetails: res.data.topicAttendedCount,
                            isLoading: false,
                        })
                    } else {
                        this.setState({ topicAttendedDetails: res.data.messagge, isLoading: false })
                    }
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    if (err && err.response && err.response.data && err.response.data.message) {
                        alert(err.response.data.message)
                    } else {
                        console.log(err)
                        alert("Error, check console")
                    }
                })
        })
    }

    submitButton(postId) {
        if (!postId) {
            alert("Please select a valid crash course pack")
        } else {
            this.getResult(postId)
        }
    }

    render() {
        return (
            <>
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}

                <Container style={{ paddingTop: 50 }}>
                    <Row>
                        <Col md="3">
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="primary" block>
                                    {(this.state.postUnitsToFetchId && this.state.postUnitsToFetchName) ? this.state.postUnitsToFetchName : "Select Post"}
                                </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {
                                        this.props.packs.map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each._id}
                                                    value={each.title}
                                                    onClick={() => this.setState({
                                                        postUnitsToFetchId: each.postId,
                                                        postUnitsToFetchName: each.title
                                                    })}
                                                >
                                                    {each.title}
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col md="3">
                            <Button
                                outline
                                color="primary"
                                disabled={!this.state.postUnitsToFetchId}
                                onClick={() => {
                                    this.submitButton(this.state.postUnitsToFetchId)
                                }}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                    {
                        Array.isArray(this.state.topicAttendedDetails) && this.state.topicAttendedDetails.length > 0 ?
                            <>
                                <Row style={{ marginTop: 25, marginBottom: 25 }}>
                                    <Col md="12" >
                                        {this.state.topicAttendedDetails.map((item, index) => {
                                            return (
                                                <Aux key={item._id}>
                                                    <p style={{ fontWeight: 'bold' }}>{item.unitName}</p>
                                                    <Table>
                                                        <tbody>
                                                            <tr>
                                                                <th>No</th>
                                                                <th>Module Name</th>
                                                                <th>Started</th>
                                                                <th>Finished</th>
                                                            </tr>
                                                            {
                                                                item.topics.map((each, index) => {
                                                                    return (
                                                                        <tr key={each._id}>
                                                                            <td style={{ width:"10%" }}>{index + 1}</td>
                                                                            <td style={{ width:"60%" }}>{each.topicName}</td>
                                                                            <td style={{ width:"15%" }}>{each.startedUsers}</td>
                                                                            <td style={{ width:"15%" }}>{each.completedUsers}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Aux>
                                            )
                                        })
                                        }
                                    </Col>
                                </Row>
                            </>
                            :
                            Array.isArray(this.state.topicAttendedDetails) && this.state.topicAttendedDetails.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                    <h6>Users not attened this crash course yet</h6>
                                </div>
                            :
                            null
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        packs: state.data.packs,
    }
}

export default connect(mapStateToProps)(PrelimsAttendedDetails)
