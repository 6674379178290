import React from 'react'
import { cloneDeep } from 'lodash';
import {
    Col, Row, Container, Table, Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Input, Label, ListGroupItem, ListGroup,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import arrayMove from 'array-move';
import uniqid from 'uniqid'
import CheckBox from 'material-ui/Checkbox';
import { isFinite } from 'lodash'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import TinyEditor from '../../Components/TinyEditor';
import QuestionForm from '../../Components/SlideQuestionForm'

const slideTypes = {
    SLIDE: 'slide',
    DESCRIPTIVE_ANSWER: 'descriptiveAnswer',
    QUESTION: 'question',
    USER_QUESTION: 'userquestion'
}

const contentTypes = {
    MATH: 'math',
    HTML: 'html',
    TEXT: 'text'
}

const SortableList = SortableContainer(({ items, isCards, onEditButton, onDeleteButton, isSortingEnabled }) => {
    return (
        <tbody>
            {
                items.map((item, index) => (
                    <SortableItem
                        disabled={!isSortingEnabled}
                        isSortingEnabled={isSortingEnabled}
                        key={item._id || item.key}
                        index={index}
                        each={item}
                        listIndex={index}
                        onEditButton={onEditButton}
                        onDeleteButton={onDeleteButton}
                    />
                )
            )}
        </tbody>
    );
});

const SortableItem = SortableElement(({ each, index, listIndex, onEditButton, onDeleteButton, isSortingEnabled }) => {
    return (
        <tr key={each._id || each.key}>
            
            <td>{listIndex + 1}</td>

            <td>
                {
                    (
                        each.slideType === slideTypes.QUESTION ||
                        each.slideType === slideTypes.USER_QUESTION
                    ) ?
                        <>
                            {each.question}
                        </>
                        :
                        each.slideType === slideTypes.DESCRIPTIVE_ANSWER
                        ?
                        <div dangerouslySetInnerHTML={{ __html: each.question }} style={{ height: "5rem", overflow: 'hidden', maxWidth: 250 }} />
                        :
                        <div dangerouslySetInnerHTML={{ __html: each.content }} style={{ height: "10rem", overflow: 'hidden', maxWidth: 250 }} />
                }
            </td>

            <td>
                {
                    (
                        each.slideType === slideTypes.QUESTION ||
                        each.slideType === slideTypes.USER_QUESTION ||
                        each.slideType === slideTypes.SLIDE
                    )
                        ?
                        `${each.slideType} (${each.type})`
                        :
                        each.slideType === slideTypes.DESCRIPTIVE_ANSWER ?
                        `${each.slideType} (${each.questionType})`
                            :
                            each.type
                }
            </td>

            <td>
                {!isSortingEnabled ?
                    (
                        <>

                            <Button
                                onClick={() => onEditButton(each, listIndex)}
                                style={{ marginRight: 10 }} color="primary">
                                Edit
                            </Button>

                            <Button
                                onClick={() => {
                                    if (window.confirm('Delete this item?')) {
                                        onDeleteButton(listIndex)
                                    };
                                }}
                                style={{ marginRight: 10 }} color="danger">
                                Delete
                            </Button>

                        </>
                    )
                    : <FontAwesomeIcon
                        icon={faSort}
                        style={{ marginLeft: 10, color: 'grey' }} />
                }
            </td>
        </tr>
    )
});
class EditSlideContent extends React.Component {

    state = {
        isSortingEnabled: false,
        initialContent: ''
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        let moduleData = cloneDeep(this.props.moduleData)

        const reorderedList = arrayMove(moduleData.content.slides, oldIndex, newIndex)
        moduleData.content.slides = reorderedList
        this.props.updateModuleData(moduleData)
    }

    componentDidMount() {
        if (isFinite(this.props.slideIndex)) {
            const { moduleData, slideIndex } = this.props
            this.onEditButton(moduleData.content.slides[slideIndex], slideIndex)
        }
    }

    onEditButton(each, index) {
        this.setState(() => {
            if (each.slideType === slideTypes.QUESTION) {
                const editingQuestion = cloneDeep(each)
                return { editingQuestionIndex: index, editingQuestion }
            } else if (each.slideType === slideTypes.USER_QUESTION) {
                let editingUserQuestion = cloneDeep(each)
                return { editingUserQuestionIndex: index, editingUserQuestion }
            } else if (each.slideType === slideTypes.DESCRIPTIVE_ANSWER) {
                return {
                    selectedDescriptiveSlideIndex: index,
                    initialQuestionContent: each.question,
                    questionType: each.questionType,
                    initialAnswerContent: each.answer,
                    answerType: each.answerType
                }
            } else if (each.type === "html" || each.type === "math") {
                return { selectedSlideIndex: index, initialContent: each.content }
            }
        })
    }

    saveHTML = (html) => {
        const moduleData = cloneDeep(this.props.moduleData)
        moduleData.content.slides[this.state.selectedSlideIndex].content = html
        this.props.updateModuleDataAndSave(moduleData)
        this.setState({ selectedSlideIndex: null, editorState: null, isHtmlEditingState: false })
    }

    renderHtmlSlideEditor() {
        return (
            <Modal
                isOpen={this.state.selectedSlideIndex != null}
                size="lg"
                style={{ maxWidth: '90%' }}
            >
                <ModalBody>
                    <TinyEditor
                        height={(window.innerHeight * 80) / 100}
                        content={this.state.initialContent || ''}
                        onHtmlChange={(newHtml) => this.setState({ initialContent: newHtml })}
                    />
                </ModalBody>

                <ModalFooter>
                    <Col>
                        <CheckBox
                            label={'Math Content'}
                            style={{ marginTop: '10px' }}
                            checked={this.props.moduleData.content.slides[this.state.selectedSlideIndex].type === "math"}
                            onClick={() => {

                                const moduleData = cloneDeep(this.props.moduleData)

                                if (moduleData.content.slides[this.state.selectedSlideIndex].type === "math") {
                                    moduleData.content.slides[this.state.selectedSlideIndex].type = "html"
                                } else {
                                    moduleData.content.slides[this.state.selectedSlideIndex].type = "math"
                                }

                                this.props.updateModuleData(moduleData)

                            }}
                        />

                        <Button
                            style={{ marginRight: '1rem' }}
                            color="primary"
                            onClick={() => {
                                this.saveHTML(this.state.initialContent)
                                this.setState({initialContent: ''})
                            }}
                        >
                            Save
                        </Button>

                    </Col>
                    <Button
                        onClick={() => this.setState({ selectedSlideIndex: null, isHtmlEditingState: false, initialContent: null })}
                        style={{ marginRight: 10 }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    saveDescriptiveData = () => {

        const { initialQuestionContent, questionType, initialAnswerContent, answerType } = this.state

        const moduleData = cloneDeep(this.props.moduleData)
        
        moduleData.content.slides[this.state.selectedDescriptiveSlideIndex].question = initialQuestionContent
        moduleData.content.slides[this.state.selectedDescriptiveSlideIndex].questionType = questionType
        moduleData.content.slides[this.state.selectedDescriptiveSlideIndex].answer = initialAnswerContent
        moduleData.content.slides[this.state.selectedDescriptiveSlideIndex].answerType = answerType

        this.props.updateModuleDataAndSave(moduleData)

        this.setState({ 
            selectedDescriptiveSlideIndex: null,
            editorState: null,
            isHtmlEditingState: false
        })

    }

    renderDescriptiveSlideEditor() {
        return (
            <Modal
                isOpen={this.state.selectedDescriptiveSlideIndex != null}
                size="lg"
                style={{ maxWidth: '90%' }}
            >
                <ModalBody>

                    <ListGroup>
                        <ListGroupItem>

                            <div style={{ display: 'flex', flexDirection:'row', justifyContent: 'space-between' }}>
                                <h4>Enter Question</h4>
                                <UncontrolledDropdown style={{ margin: 10 }}>
                                    <DropdownToggle caret color="primary">
                                        {this.state.questionType}
                                    </DropdownToggle>
                                    <DropdownMenu className="addScroll">
                                        {
                                            ["text", "html", "math"].map((each, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={each}
                                                        value={each}
                                                        onClick={() => this.setState({ questionType: each })}
                                                    >
                                                        {each}
                                                    </DropdownItem>)
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            {
                                this.state.questionType === contentTypes.TEXT ?
                                    <Input
                                        placeholder="Question"
                                        type="textarea"
                                        rows={5}
                                        name="question"
                                        value={this.state.initialQuestionContent || ''}
                                        onChange={(e) => {
                                            let text = e.target.value
                                            this.setState({ initialQuestionContent: text })
                                        }}
                                    />
                                    :
                                    <TinyEditor
                                        height={(window.innerHeight * 70) / 300}
                                        content={this.state.initialQuestionContent || ''}
                                        onHtmlChange={(newHtml) => this.setState({ initialQuestionContent: newHtml })}
                                    />
                            }

                        </ListGroupItem>
                    </ListGroup>

                    <ListGroup style={{ marginTop: 20 }}>
                        <ListGroupItem>

                            <div style={{ display: 'flex', flexDirection:'row', justifyContent: 'space-between' }}>
                                <h4>Enter Answer</h4>
                                <UncontrolledDropdown style={{ margin: 10 }}>
                                    <DropdownToggle caret  color="primary">
                                        {this.state.answerType}
                                    </DropdownToggle>
                                    <DropdownMenu className="addScroll">
                                        {
                                            ["text", "html", "math"].map((each, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={each}
                                                        value={each}
                                                        onClick={() => this.setState({ answerType: each })}
                                                    >
                                                        {each}
                                                    </DropdownItem>)
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            {
                                this.state.answerType === contentTypes.TEXT ?
                                    <Input
                                        placeholder="Answer"
                                        type="textarea"
                                        rows={15}
                                        name="answer"
                                        value={this.state.initialAnswerContent || ''}
                                        onChange={(e) => {
                                            let text = e.target.value
                                            this.setState({ initialAnswerContent: text })
                                        }}
                                    />
                                    :
                                    <TinyEditor
                                        height={(window.innerHeight * 70) / 150}
                                        content={this.state.initialAnswerContent || ''}
                                        onHtmlChange={(newHtml) => this.setState({ initialAnswerContent: newHtml })}
                                    />
                            }

                        </ListGroupItem>
                    </ListGroup>

                </ModalBody>

                <ModalFooter>
                    <Col>
                        <Button
                            style={{ marginRight: '1rem' }}
                            color="primary"
                            onClick={() => {
                                this.saveDescriptiveData()
                                this.setState({ initialQuestionContent: '', initialAnswerContent: '' })
                            }}
                        >
                            Save
                        </Button>
                    </Col>
                    <Button
                        onClick={() => {
                            this.setState({
                                selectedDescriptiveSlideIndex: null,
                                isHtmlEditingState: false,
                                initialQuestionContent: null,
                                initialAnswerContent: ''
                            })
                        }}
                        style={{ marginRight: 10 }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderNewQuestionModal() {
        return (
            <Modal
                size="lg"
                isOpen={this.state.showNewQuestionForm}
            >
                <ModalHeader>
                    Enter New Question
                </ModalHeader>
                <ModalBody>

                    <QuestionForm
                        questionValue={this.state.newQuestion.question || ''}
                        questionOnChange={(editorState) => {
                            this.setState((oldState) => {
                                oldState.newQuestion.question = editorState
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        options={this.state.newQuestion.options}
                        optionChange={(e, index) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.options[index].text = text
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        optionTypeChange={(index) => {
                            this.setState((oldState) => {

                                if (oldState.newQuestion.options[index].type === "math") {
                                    oldState.newQuestion.options[index].type = "text"
                                } else {
                                    oldState.newQuestion.options[index].type = "math"
                                }

                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        explanation={this.state.newQuestion.explanation || ''}
                        explanationChange={(e) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.explanation = text
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        questionPaperName={this.state.newQuestion.questionPaperName || ''}
                        onQuestionPaperNameChange={(e) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.questionPaperName = text
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        correctAnswer={this.state.newQuestion.correctAnswer || ''}
                        correctAnswerChange={(e) => {
                            let value = e.target.value
                            this.setState((oldState) => {
                                oldState.newQuestion.correctAnswer = value
                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                        questionType={this.state.newQuestion.type}
                        toggleQuestionType={(type) => {
                            this.setState(oldState => {
                                oldState.newQuestion.type = type
                                return oldState
                            })
                        }}
                        isMathExplanation={this.state.newQuestion.explanationType === "math"}
                        toggleMathExplanation={() => {
                            this.setState((oldState) => {

                                if (oldState.newQuestion.explanationType === "math") {
                                    oldState.newQuestion.explanationType = "text"
                                } else {
                                    oldState.newQuestion.explanationType = "math"
                                }

                                return { newQuestion: oldState.newQuestion }

                            })
                        }}
                        showCalculator={this.state.newQuestion.showCalculator === true}
                        toggleShowCalculator={() => {
                            this.setState((oldState) => {

                                if (oldState.newQuestion.showCalculator === true) {
                                    oldState.newQuestion.showCalculator = false
                                } else {
                                    oldState.newQuestion.showCalculator = true
                                }

                                return { newQuestion: oldState.newQuestion }
                            })
                        }}
                    />

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {

                        let newQuestionObject = this.state.newQuestion

                        if (
                            newQuestionObject.question &&
                            newQuestionObject.options[0].text &&
                            newQuestionObject.options[1].text &&
                            newQuestionObject.options[2].text &&
                            newQuestionObject.options[3].text &&
                            newQuestionObject.correctAnswer
                        ) {

                            const moduleData = cloneDeep(this.props.moduleData)
                            this.setState((oldState) => {

                                let questionObject = {
                                    key: uniqid(),
                                    type: slideTypes.QUESTION,
                                    ...oldState.newQuestion,
                                    slideType: slideTypes.QUESTION
                                }

                                const slides = moduleData.content.slides

                                if (Array.isArray(slides)) {
                                    moduleData.content.slides.push(questionObject)
                                } else {
                                    moduleData.content.slides = [questionObject]
                                }

                                return { showNewQuestionForm: false, newQuestion: null, moduleData: oldState.moduleData }

                            })
                            this.props.updateCardAndSlideModule(moduleData)

                        } else {
                            alert("Fill in all requried fields")
                        }


                    }}>
                        Submit
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={(e) => this.setState({ showNewQuestionForm: false, newQuestion: null })}
                    >
                        Cancel
                    </Button>

                </ModalFooter>
            </Modal>
        )
    }

    renderNewUserQuestionModal() {

        return (
            <Modal
                size="lg"
                isOpen={this.state.showNewUserQuestionForm}
            >
                <ModalHeader>
                    Enter New User Question
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>

                            {
                                this.state.newUserQuestion.type === "text" ?
                                    <Input
                                        placeholder="Question"
                                        type="textarea"
                                        name="question"
                                        value={this.state.newUserQuestion.question || ''}
                                        onChange={(e) => {
                                            let text = e.target.value
                                            this.setState((oldState) => {
                                                oldState.newUserQuestion.question = text
                                                return oldState
                                            })
                                        }}
                                    />
                                    :
                                    <TinyEditor
                                        content={this.state.newUserQuestion.question || ''}
                                        onHtmlChange={(newHtml) => {
                                            this.setState((oldState) => {
                                                oldState.newUserQuestion.question = newHtml
                                                return oldState
                                            })
                                        }}
                                    />
                            }

                        </FormGroup>

                        <hr />

                        <Row>
                            <Col md="4">
                                <FormGroup tag="fieldset">
                                    <Input
                                        type="select" name="select" id="questionType"
                                        value={this.state.newUserQuestion.type}
                                        onChange={(e) => {
                                            const type = e.target.value
                                            this.setState(oldState => {
                                                oldState.newUserQuestion.type = type
                                                return oldState
                                            })
                                        }}
                                    >
                                        <option value="text">Text</option>
                                        <option value="html">HTML</option>
                                        <option value="math">Math</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md="4">
                                <CheckBox
                                    label={'Show Calculator'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.newUserQuestion.showCalculator === true}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (oldState.newUserQuestion.showCalculator === true) {
                                                oldState.newUserQuestion.showCalculator = false
                                            } else {
                                                oldState.newUserQuestion.showCalculator = true
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>

                            <Col md="4">
                                <CheckBox
                                    label={'Numeric Keypad'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.newUserQuestion.keyboard === "numeric"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (oldState.newUserQuestion.keyboard === "numeric") {
                                                oldState.newUserQuestion.keyboard = "default"
                                            } else {
                                                oldState.newUserQuestion.keyboard = "numeric"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>

                        </Row>

                        <hr />

                        <Row>
                            <Col md="12" style={{ marginBottom: 20 }}>
                                <Input
                                    type="text"
                                    name="correctAnswer"
                                    placeholder="Correct Answer"
                                    id="correctAnswerSelect"
                                    value={this.state.newUserQuestion.correctAnswer || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState((oldState) => {
                                            oldState.newUserQuestion.correctAnswer = value
                                            return { modules: oldState.modules }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Input
                                        placeholder="Explanation"
                                        type="textarea"
                                        name="explanation"
                                        value={this.state.newUserQuestion.explanation || ''}
                                        onChange={(e) => {
                                            let text = e.target.value
                                            this.setState((oldState) => {
                                                oldState.newUserQuestion.explanation = text
                                                return oldState
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <CheckBox
                                    label={'Math Explanation'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.newUserQuestion.explanationType === "math"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (this.state.newUserQuestion.explanationType === "math") {
                                                oldState.newUserQuestion.explanationType = "text"
                                            } else {
                                                oldState.newUserQuestion.explanationType = "math"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Input
                                        placeholder="Previous Question Paper Name (Optional)"
                                        type="text"
                                        name="questionPaperName"
                                        value={this.state.newUserQuestion.questionPaperName || ''}
                                        onChange={(e) => {
                                            let text = e.target.value
                                            this.setState((oldState) => {
                                                oldState.newUserQuestion.questionPaperName = text
                                                return oldState
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>

                </ModalBody>

                <ModalFooter>

                    <Button color="primary" onClick={() => {

                        const newQuestion = this.state.newUserQuestion

                        if (newQuestion.question && newQuestion.correctAnswer) {

                            const moduleData = cloneDeep(this.props.moduleData)

                            const newQuestionObject = {
                                key: uniqid(),
                                type: slideTypes.USER_QUESTION,
                                slideType: slideTypes.USER_QUESTION,
                                ...newQuestion
                            }

                            this.setState((oldState) => {
                                if (Array.isArray(moduleData.content.slides)) {
                                    moduleData.content.slides.push(newQuestionObject)
                                } else {
                                    moduleData.content.slides = [newQuestionObject]
                                }
                                
                                return { showNewUserQuestionForm: false, newUserQuestion: null }
                            })

                            this.props.updateModuleData(moduleData)

                        } else {
                            alert("Fill in all requried fields")
                        }

                    }}>
                        Submit
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={(e) => this.setState({ showNewUserQuestionForm: false, newUserQuestion: null })}
                    >
                        Cancel
                    </Button>


                </ModalFooter>
            </Modal>

        )
    }

    addNewSlide = () => {

        let selectedSlideIndex = 0

        const moduleData = cloneDeep(this.props.moduleData)
        let currentSlides = moduleData.content.slides

        if (Array.isArray(currentSlides)) {
            moduleData.content.slides.push({ slideType: slideTypes.SLIDE, key: uniqid(), type: "html", content: "" })
        } else {
            moduleData.content.slides = [{ slideType: slideTypes.SLIDE, key: uniqid(), type: "html", content: "" }]
        }
        selectedSlideIndex = moduleData.content.slides.length - 1

        this.setState({ editorState: '', selectedSlideIndex })
        this.props.updateModuleData(moduleData)

    }

    addDescriptiveQuestionAnswer = () => {

        let selectedDescriptiveSlideIndex = 0

        const moduleData = cloneDeep(this.props.moduleData)
        let currentSlides = moduleData.content.slides

        const newSlide = {
            slideType: slideTypes.DESCRIPTIVE_ANSWER,
            key: uniqid(),
            content: {
                question: "",
                questionType: "text",
                answer: "",
                answerType: "text"
            }
        }

        if (Array.isArray(currentSlides)) {
            moduleData.content.slides.push(newSlide)
        } else {
            moduleData.content.slides = [newSlide]
        }

        selectedDescriptiveSlideIndex = moduleData.content.slides.length - 1

        this.setState({
            selectedDescriptiveSlideIndex,
            initialQuestionContent: "",
            questionType: "text",
            initialAnswerContent: "",
            answerType: "text"
        })

        this.props.updateModuleData(moduleData)

    }

    addNewQuestion = () => {

        let emptyQuestion = {

            question: null,
            options: [
                { id: "A", text: null },
                { id: "B", text: null },
                { id: "C", text: null },
                { id: "D", text: null },
            ],
            correctAnswer: null,
            type: "text",

        }

        this.setState({ newQuestion: emptyQuestion, showNewQuestionForm: true })
        this.props.updateModuleData()

    }

    addNewUserQuestion = () => {
        let emptyQuestion = { type: 'text', question: '', correctAnswer: null }
        this.setState({ newUserQuestion: emptyQuestion, showNewUserQuestionForm: true })
    }

    renderEditingQuestionModal() {
        return (
            <Modal
                size="lg"
                isOpen={this.state.editingQuestion && this.state.editingQuestionIndex >= 0}
            >

                <ModalHeader>
                    Edit Question
                </ModalHeader>

                <ModalBody>
                    <QuestionForm
                        questionValue={this.state.editingQuestion.question || ''}
                        questionOnChange={(editorState) => {
                            this.setState((oldState) => {
                                oldState.editingQuestion.question = editorState
                                return oldState
                            })
                        }}
                        options={this.state.editingQuestion.options}
                        optionChange={(e, index) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.editingQuestion.options[index].text = text
                                return oldState
                            })
                        }}
                        optionTypeChange={(index) => {
                            this.setState((oldState) => {
                                if (oldState.editingQuestion.options[index].type === "math") {
                                    oldState.editingQuestion.options[index].type = "text"
                                } else {
                                    oldState.editingQuestion.options[index].type = "math"
                                }

                                return oldState
                            })
                        }}
                        explanation={this.state.editingQuestion.explanation || ''}
                        explanationChange={(e) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.editingQuestion.explanation = text
                                return oldState
                            })
                        }}
                        questionPaperName={this.state.editingQuestion.questionPaperName || ''}
                        onQuestionPaperNameChange={(e) => {
                            let text = e.target.value
                            this.setState((oldState) => {
                                oldState.editingQuestion.questionPaperName = text
                                return oldState
                            })
                        }}
                        correctAnswer={this.state.editingQuestion.correctAnswer || ''}
                        correctAnswerChange={(e) => {
                            let value = e.target.value
                            this.setState((oldState) => {
                                oldState.editingQuestion.correctAnswer = value
                                return { modules: oldState.modules }
                            })
                        }}
                        questionType={this.state.editingQuestion.type}
                        htoggleQuestionType={(type) => {
                            this.setState((oldState) => {
                                oldState.editingQuestion.type = type
                                return { editingQuestion: oldState.editingQuestion }
                            })
                        }}

                        toggleQuestionType={(type) => {
                            this.setState(oldState => {
                                oldState.editingQuestion.type = type
                                return oldState
                            })
                        }}

                        isMathExplanation={this.state.editingQuestion && this.state.editingQuestion.explanationType === "math"}
                        toggleMathExplanation={() => {
                            this.setState((oldState) => {

                                if (oldState.editingQuestion.explanationType === "math") {
                                    oldState.editingQuestion.explanationType = "text"
                                } else {
                                    oldState.editingQuestion.explanationType = "math"
                                }

                                return { editingQuestion: oldState.editingQuestion }

                            })
                        }}
                        showCalculator={this.state.editingQuestion.showCalculator}
                        toggleShowCalculator={() => {
                            this.setState((oldState) => {

                                if (oldState.editingQuestion.showCalculator === true) {
                                    oldState.editingQuestion.showCalculator = false
                                } else {
                                    oldState.editingQuestion.showCalculator = true
                                }

                                return { editingQuestion: oldState.editingQuestion }
                            })
                        }}
                    />
                </ModalBody>

                <ModalFooter>

                    <Button color="primary" onClick={() => {

                        const question = this.state.editingQuestion

                        if (
                            question.question &&
                            question.options[0].text &&
                            question.options[1].text &&
                            question.options[2].text &&
                            question.options[3].text &&
                            question.correctAnswer
                        ) {

                            let questionObject = question

                            const moduleData = cloneDeep(this.props.moduleData)
                            moduleData.content.slides[this.state.editingQuestionIndex] = questionObject
                            this.setState({ editingQuestion: null, editingQuestionIndex: null })
                            this.props.updateModuleData(moduleData)

                        } else {
                            alert("Enter all details")
                        }

                    }}>Save</Button>

                    <Button color="secondary" onClick={() => { this.setState({ editingQuestion: null, editingQuestionIndex: null }) }} outline>Cancel</Button>

                </ModalFooter>

            </Modal>
        )
    }

    renderEditingUserQuestionModal() {

        return (
            <Modal
                isOpen={this.state.editingUserQuestion && this.state.editingUserQuestion != null && this.state.editingUserQuestionIndex >= 0}
                size="lg"
            >
                <ModalHeader>Edit User Question</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>

                            <Row>
                                <Col>
                                    {
                                        this.state.editingUserQuestion.type === "text" ?
                                            <Input
                                                placeholder="Question"
                                                type="textarea"
                                                name="question"
                                                value={this.state.editingUserQuestion.question || ''}
                                                onChange={(e) => {
                                                    let text = e.target.value
                                                    this.setState((oldState) => {
                                                        oldState.editingUserQuestion.question = text
                                                        return oldState
                                                    })
                                                }}
                                            />
                                            :
                                            <TinyEditor
                                                content={this.state.editingUserQuestion.question || ''}
                                                onHtmlChange={(newHtml) => {
                                                    this.setState((oldState) => {
                                                        oldState.editingUserQuestion.question = newHtml
                                                        return oldState
                                                    })
                                                }}
                                            />
                                    }
                                </Col>
                            </Row>

                        </FormGroup>

                        <hr />

                        <Row>
                            <Col md="4" >

                                <FormGroup tag="fieldset">
                                    <Input
                                        type="select" name="select" id="questionType"
                                        value={this.state.editingUserQuestion.type}
                                        onChange={(e) => {
                                            const type = e.target.value
                                            this.setState(oldState => {
                                                oldState.editingUserQuestion.type = type
                                                return oldState
                                            })
                                        }}
                                    >
                                        <option value="text">Text</option>
                                        <option value="html">HTML</option>
                                        <option value="math">Math</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md="4">
                                <CheckBox
                                    label={'Show Calculator'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.editingUserQuestion.showCalculator}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (oldState.editingUserQuestion.showCalculator === true) {
                                                oldState.editingUserQuestion.showCalculator = false
                                            } else {
                                                oldState.editingUserQuestion.showCalculator = true
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>

                            <Col md="4">
                                <CheckBox
                                    label={'Numeric Keypad'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.editingUserQuestion.keyboard === "numeric"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (oldState.editingUserQuestion.keyboard === "numeric") {
                                                oldState.editingUserQuestion.keyboard = "default"
                                            } else {
                                                oldState.editingUserQuestion.keyboard = "numeric"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>

                        </Row>


                        <hr />

                        <Row>
                            <Col md="12" style={{ marginBottom: 20 }}>
                                <Input
                                    type="text"
                                    name="correctAnswer"
                                    placeholder="Correct Answer"
                                    id="correctAnswerSelect"
                                    value={this.state.editingUserQuestion.correctAnswer || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState((oldState) => {
                                            oldState.editingUserQuestion.correctAnswer = value
                                            return { modules: oldState.modules }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Input
                                        placeholder="Explanation"
                                        type="textarea"
                                        name="explanation"
                                        value={this.state.editingUserQuestion.explanation || ''}
                                        onChange={(e) => {
                                            let text = e.target.value
                                            this.setState((oldState) => {
                                                oldState.editingUserQuestion.explanation = text
                                                return oldState
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <CheckBox
                                    label={'Math Explanation'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.editingUserQuestion.explanationType === "math"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (this.state.editingUserQuestion.explanationType === "math") {
                                                oldState.editingUserQuestion.explanationType = "text"
                                            } else {
                                                oldState.editingUserQuestion.explanationType = "math"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Input
                                        placeholder="Previous Question Paper Name (Optional)"
                                        type="text"
                                        name="questionPaperName"
                                        value={this.state.editingUserQuestion.questionPaperName || ''}
                                        onChange={(e) => {
                                            let text = e.target.value
                                            this.setState((oldState) => {
                                                oldState.editingUserQuestion.questionPaperName = text
                                                return oldState
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>

                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={() => {

                        const question = this.state.editingUserQuestion

                        if (question.question && question.correctAnswer) {
                            const moduleData = cloneDeep(this.props.moduleData)
                            moduleData.content.slides[this.state.editingUserQuestionIndex] = question
                            this.setState({ editingUserQuestion: null, editingUserQuestionIndex: null })
                            this.props.updateModuleData(moduleData)
                        } else {
                            alert("Enter all details")
                        }

                    }}>Save</Button>

                    <Button
                        outline
                        color="secondary"
                        onClick={() => this.setState({ editingUserQuestion: null, editingUserQuestionIndex: null })}
                    >Cancel</Button>

                </ModalFooter>
            </Modal>
        )
    }

    handleChangeInput = (e) => {
        let moduleData = cloneDeep(this.props.moduleData)
        moduleData.content[e.target.name] = e.target.value
        this.props.updateModuleData(moduleData)
    }

    render() {

        const { content } = this.props.moduleData
        const { relatedQuizModuleId, relatedQuizName } = content

        let { reportedSlide } = this.props

        return (
            <Container fluid
                style={{ display: "flex", flexDirection: "column" }}
            >

                {this.state.selectedSlideIndex != null && this.renderHtmlSlideEditor()}
                {this.state.selectedDescriptiveSlideIndex != null && this.renderDescriptiveSlideEditor()}
                {this.state.showNewQuestionForm && this.renderNewQuestionModal()}
                {((this.state.editingQuestion && this.state.editingQuestionIndex >= 0)) && this.renderEditingQuestionModal()}
                {this.state.showNewUserQuestionForm && this.renderNewUserQuestionModal()}
                {((this.state.editingUserQuestion && this.state.editingUserQuestion != null && this.state.editingUserQuestionIndex >= 0)) && this.renderEditingUserQuestionModal()}

                {content.slides && content.slides.length > 0 ?
                    <>
                        <Button
                            style={{ marginBottom: 10, marginLeft: "auto" }}
                            outline
                            color='success'
                            onClick={() => this.setState({ isSortingEnabled: !this.state.isSortingEnabled })}
                        >{this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                        </Button>

                        <Table hover>

                            <thead>
                                <tr>
                                    <th>Index</th>
                                    <th>Content</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>

                            <SortableList
                                isSortingEnabled={this.state.isSortingEnabled}
                                items={reportedSlide ? reportedSlide : content.slides}
                                onSortEnd={this.onSortEnd}
                                onEditButton={(each, index) => this.onEditButton(each, index)}
                                onDeleteButton={(index) => {
                                    const moduleData = cloneDeep(this.props.moduleData)
                                    moduleData.content.slides.splice(index, 1)
                                    this.props.updateModuleData(moduleData)
                                }}
                            />
                        </Table>
                    </>
                    :
                    <h5 style={{ textAlign: 'center', width: '100%', padding: 50 }}>No slides added</h5>

                }

                <hr />

                {
                    !this.state.reportedSlide &&
                    <Row>
                        <Button style={{ marginRight: 10 }} color="primary" onClick={this.addNewSlide}>Add Text Slide</Button>
                        <Button style={{ marginRight: 10 }} color="primary" onClick={this.addDescriptiveQuestionAnswer}>Add Descriptive QA</Button>
                        <Button style={{ marginRight: 10 }} color="primary" onClick={this.addNewQuestion}>Add Question Slide</Button>
                        <Button style={{ marginRight: 10 }} color="primary" onClick={this.addNewUserQuestion} >Add Input Question Slide</Button>
                    </Row>
                }

                <Row style={{ marginTop: 50 }}>
                    <Col md="4">
                        <FormGroup tag="fieldset">
                            <Label>Related Quiz Module ID</Label>
                            <Input
                                type="text"
                                name="relatedQuizModuleId"
                                id="relatedQuizModuleId"
                                value={relatedQuizModuleId || ''}
                                onChange={this.handleChangeInput}
                            />
                        </FormGroup>
                    </Col>
                    {relatedQuizName ? <Col md="12"><p>Related Quiz: {relatedQuizName}</p> </Col> : null}
                </Row>

            </Container>
        );
    }
}

export default EditSlideContent;