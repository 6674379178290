import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {
    Button, Table, Container, Row, Input,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import { faPen, faTrash, faReply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loader from '../Components/Loader'
import { unstable_batchedUpdates } from 'react-dom';

const CONTENT_TYPE = "teachers"

function Teachers() {

    const [isLoading, setIsLoading] = useState(true)
    const [teachers, setTeachers] = useState([])

    const [showAddTeacherModal, setShowAddTeacherModal] = useState(false)
    const [newTeacherName, setNewTeacherName] = useState('')
    const [editingTeacherId, setEditingTeacherId] = useState(null)

    useEffect(() => {
        getAllTeachers()
    }, [])

    const getAllTeachers = () => {
        axios.get('/misc', { params: { contentType: CONTENT_TYPE } })
            .then(res => {
                if (res.data.requiredContent) {
                    setTeachers(res.data.requiredContent.teachers)
                }
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
                console.error(err)
                alert("Server error. Please check console")
            })
    }

    const addNewTeacher = () => {

        if (!newTeacherName) {
            alert("Enter a valid teacher name")
            return true
        }

        if (editingTeacherId) {

            axios.post('/misc/update-fields', { 
                contentType: CONTENT_TYPE,
                contentId: editingTeacherId,
                fieldsAndValues: { name: newTeacherName }
            })
                .then(() => {
                    setShowAddTeacherModal(false)
                    setNewTeacherName('')
                    getAllTeachers()
                    setEditingTeacherId(null)
                })
                .catch(err => {
                    setIsLoading(false)
                    console.error(err)
                    alert("Server error. Please check console")
                })

        } else {

            axios.put('/misc', { contentType: CONTENT_TYPE, value: { name: newTeacherName } })
                .then(() => {
                    setShowAddTeacherModal(false)
                    setNewTeacherName('')
                    getAllTeachers()
                })
                .catch(err => {
                    setIsLoading(false)
                    console.error(err)
                    alert("Server error. Please check console")
                })

        }
        
    }

    const deleteTeacher = (contentId, value) => {
        axios.post('/misc/update-fields', {
            contentType: CONTENT_TYPE,
            contentId,
            fieldsAndValues: { activeFlag: !value.activeFlag }
        })
            .then(() => getAllTeachers())
            .catch(err => {
                console.error(err)
                alert("Server error. Check console")
            })
    }

    return (
        <>
            {isLoading && <Loader />}
            <Container style={{ marginTop: 50 }}>

                <Modal
                    isOpen={showAddTeacherModal}
                >
                    <ModalHeader>
                        Enter Teacher Details
                    </ModalHeader>

                    <ModalBody>
                        <Input
                            type="text"
                            value={newTeacherName}
                            placeholder={"Enter teacher name"}
                            onChange={(e) => setNewTeacherName(e.target.value)}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={addNewTeacher}
                        >Submit</Button>
                        <Button
                            outline
                            onClick={() => {
                                setShowAddTeacherModal(false)
                                setNewTeacherName('')
                            }}
                        >Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Row>
                    <Button
                        color="primary"
                        onClick={() => {
                            setEditingTeacherId(null)
                            setShowAddTeacherModal(true)
                        }}
                    >
                        Add Teacher
                    </Button>
                </Row>

                <Row style={{ marginTop: 50 }}>
                    <Table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Teacher Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                teachers.map((each, index) => (
                                    <tr style={{ background: !each.activeFlag ? 'rgba(255,0,0,0.1)' : 'transparent' }} key={each._id}>
                                        <td>{index + 1}</td>
                                        <td>{each.name}</td>
                                        <td>
                                            <Button
                                                outline
                                                color="primary"
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    unstable_batchedUpdates(() => {
                                                        setEditingTeacherId(each._id)
                                                        setShowAddTeacherModal(true)
                                                        setNewTeacherName(each.name)
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className="iconButton"
                                                    icon={faPen}
                                                />
                                            </Button>

                                            <Button
                                                style={{ marginRight: 10 }}
                                                outline
                                                color="danger"
                                                onClick={() => deleteTeacher(each._id, each)}
                                            >
                                                <FontAwesomeIcon icon={each.activeFlag ? faTrash : faReply} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Row>

            </Container>
        </>
    )
}

export default Teachers
