import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import {
    Container, Col, Table, Row, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label,
    ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane,
    DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown
} from 'reactstrap'
import { DatePicker } from 'material-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPen, faTrash, faSort } from '@fortawesome/free-solid-svg-icons'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { toast } from 'react-toastify'
import { cloneDeep, isFinite, sortBy } from 'lodash'
import queryString from 'query-string';

import { copyToClipboard } from '../utils';
import Loader from '../Components/Loader'

const noteSchema = {
    title: '',
    description: '',
    subjectId: "selectSubject",
    sectionId: "allSections",
}

const tabs = [
    { key: '0', title: "Daily", value: "daily" },
    { key: '1', title: "Weekly", value: "weekly" },
    { key: '2', title: "Monthly", value: "monthly" },
    { key: '3', title: "Yearly", value: "yearly" }
]

const SortableCardItem = SortableElement(({
    key,
    listIndex,
    each,
    onEditButton,
    onDeleteButton,
    isSortingEnabled,
    editingNoteIndex,
    notesModalOnChangeHandler,
    selectedSubjectOnChangeHandler,
    selectedSectionOnChangeHandler,
    subjects,
    sections
}) => {
    return (
        <tr key={key}>
            <td colSpan={1}>{parseInt(listIndex) + 1}</td>
            <td colSpan={5}>
                {
                    (editingNoteIndex === listIndex && !isSortingEnabled) ?
                        <>
                            <Row>
                                <Col>
                                    <Input
                                        style={{ marginBottom: 20 }}
                                        id='notesTitle'
                                        placeholder="Enter title"
                                        type='text'
                                        onChange={(e) => notesModalOnChangeHandler(e)}
                                        value={each.title}
                                        name='title'
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Input
                                        placeholder="Enter description"
                                        type='textarea'
                                        name='description'
                                        style={{ height: 140 }}
                                        onChange={(e) => notesModalOnChangeHandler(e)}
                                        value={each.description}
                                    />
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col md="6">
                                    <Label for="exampleSelect">Select Subject</Label>
                                    <Input
                                        value={each.subjectId}
                                        type="select"
                                        name={"subjectId"}
                                        id={"subjectId"}
                                        onChange={(e) => selectedSubjectOnChangeHandler(e) }
                                    >
                                        {
                                            subjects ?
                                                [
                                                    { _id: "selectSubject", name: "Select a subject" }, ...subjects].map((subject, index) => (
                                                        <option key={subject._id} value={subject._id}>{subject.name}</option>
                                                    ))
                                                :
                                                null
                                        }
                                    </Input>
                                </Col>
                                <Col>
                                    <Label for="sectionId">Select Section</Label>
                                    <Input
                                        value={each.sectionId || 'allSections'}
                                        type="select"
                                        name={"sectionId"}
                                        id={"sectionId"}
                                        onChange={(e) => selectedSectionOnChangeHandler(e) }
                                    >
                                        {
                                            Array.isArray(sections) && sections.length > 0 ?
                                                [{ _id: "allSections", name: "All Sections" }, ...sections].map(section => {
                                                    return (
                                                        <option
                                                            key={section._id}
                                                            value={section._id}
                                                        >
                                                            {section.name}
                                                        </option>
                                                    )
                                                })
                                                :
                                                [{ _id: "allSections", name: "All Sections" }].map(section => {
                                                    return (
                                                        <option key={section._id} value={section._id}>
                                                            {section.name}
                                                        </option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Label>Related learn module ID</Label>
                                    <Input
                                        id='relatedModuleId'
                                        placeholder="Related learn module ID"
                                        type='text'
                                        onChange={(e) => notesModalOnChangeHandler(e)}
                                        value={each.relatedModuleId}
                                        name='relatedModuleId'
                                    />
                                </Col>
                            </Row>
                        </>
                    :
                    <>
                        <h6 style={{ marginBottom: 10 }}>{each.title}</h6>
                        <hr />
                        <p>{each.description}</p>
                        
                    </>
                }
            </td>
            <td colSpan={1}>
                {!isSortingEnabled ?
                    <>
                    
                        <Button
                            outline
                            color="secondary"
                            icon={faCopy}
                            onClick={() => {
                                copyToClipboard(each._id)
                                toast("ID copied", {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true
                                    })
                            }}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                        <br />
                        <Button
                            outline
                            color="secondary"
                            icon={faCopy}
                            onClick={() => onEditButton(listIndex)}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <br />
                        <Button
                            outline
                            color="danger"
                            icon={faCopy}
                            onClick={() => onDeleteButton(listIndex)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>

                    </>
                    : <FontAwesomeIcon
                        icon={faSort}
                        style={{ marginLeft: 10, color: 'grey' }}
                    />
                }
            </td>
        </tr>
    )
})

const SortableList = SortableContainer(({
    items,
    subjects,
    sections,
    onEditButton,
    editingNoteIndex,
    onDeleteButton,
    isSortingEnabled,
    notesModalOnChangeHandler,
    selectedSubjectOnChangeHandler,
    selectedSectionOnChangeHandler
}) => {
    return (
        <tbody>
            {items.map((item, index) => {
                return (
                    <SortableCardItem
                        disabled={!isSortingEnabled}
                        isSortingEnabled={isSortingEnabled}
                        key={item._id}
                        listIndex={index}
                        each={item}
                        index={index}
                        onEditButton={onEditButton}
                        onDeleteButton={onDeleteButton}
                        editingNoteIndex={editingNoteIndex}
                        notesModalOnChangeHandler={notesModalOnChangeHandler}
                        selectedSubjectOnChangeHandler={selectedSubjectOnChangeHandler}
                        selectedSectionOnChangeHandler={selectedSectionOnChangeHandler}
                        subjects={subjects}
                        sections={sections}
                    />
                )
                }
            )}
        </tbody>
    );
});
class DataEntry extends Component {

    state = {
        data: [],
        isLoading: true,
        dailyChallengeCount: 0,
        showNotesModal: false,
        notesTitle: '',
        notesDescription: '',
        notesDetails: [],
        
        subjectId: "selectSubject",
        sectionId: "allSections",

        showEditQuestionModal: false,
        questionsData: [],
        selectedDailyChallengeId: null,
        newQuestionId: '',
        activeTab: '0',
        isSortingEnabled: false,

        editingNoteIndex: null
    }

    componentDidMount() {
        let newParams = queryString.parse(this.props.location.search)
        let activeTab = (newParams && newParams.activeTab) ? newParams.activeTab : '0'

        this.setState({ activeTab: activeTab }, () => {
            this.fetchDailyChallengeHistory()
        })
    }

    componentDidUpdate(prevProps) {

        let prevParams = queryString.parse(prevProps.location.search)
        let newParams = queryString.parse(this.props.location.search)

        if (prevParams.index !== newParams.index) {
            this.fetchDailyChallengeHistory()
        }

    }

    getParamsIndex = () => {
        let params = queryString.parse(this.props.location.search)
        if (params && params.index) {
            return parseInt(params.index)
        } else {
            return 0
        }
    }

    fetchDailyChallengeHistory() {

        let params = queryString.parse(this.props.location.search)

        let period = 'daily'

        if (this.state.activeTab && this.state.activeTab !== '0') {
            period = tabs.find(each => each.key === this.state.activeTab).value
        }

        let paramsObject = {
            skipCount: (params && params.index )? parseInt(params.index) : 0,
            limit: 10, period
        }

        if (params && params.index) {
        }
        if (this.props.location.state) {
            const { dailyChallengeId } = this.props.location.state
            paramsObject.dailyChallengeId = dailyChallengeId
        }

        if (params.noteId) {
            paramsObject.noteId = params.noteId
        }

        this.setState({ isLoading: true }, () => {
            axios.get('/dailyChallengeHistory', { params: paramsObject })
                .then(res => {

                    let setObject = {
                        data: res.data.dailyChallenges,
                        editNote:res.data.editNoteItem,
                        editNoteId:res.data.editNoteItemId,
                        dailyChallengeCount: res.data.dailyChallengeCount,
                        isLoading: false
                    }

                    if (params.noteId) {
                        
                        let foundIndex = res.data.editNoteItem.findIndex(item => item._id === params.noteId)
                        setObject.showNotesModal = true
                        setObject.editingNoteIndex = foundIndex

                        this.setState(setObject, () => {
                            this.checkNextIndexSubjectIdAndSetSections(setObject.editNote[setObject.editingNoteIndex].subjectId)
                        })
                    } else {
                        this.setState(setObject)
                    }

                })
                .catch(err => {
                    console.log(err)
                    alert("Error, please check console")
                    this.setState({ isLoading: false })
                })
        })

    }

    checkNextIndexSubjectIdAndSetSections(subjectId) {
        if (subjectId && subjectId !== "selectSubject") {
            const foundSubject = this.props.subjects.find(e => e._id === subjectId)
            this.setState({ sections: foundSubject.sections })
        }
    }

    checkNotesExistAndPopModal(onClickedDataIndex) {
        this.setState({
            showNotesModal: true,
            isSortingEnabled: false,
            editNote: this.state.data[onClickedDataIndex].slidesData,
            editNoteId: this.state.data[onClickedDataIndex]._id
        })
    }

    addNewSlide() {
        this.setState((currentState) => {
            if (Array.isArray(currentState.editNote)) {
                currentState.editNote.push(JSON.parse(JSON.stringify(noteSchema)))
            } else {
                currentState.editNote = [JSON.parse(JSON.stringify(noteSchema))]
            }
            return { editNote: currentState.editNote, editingNoteIndex: currentState.editNote.length - 1  }
        }, () => {
            this.checkNextIndexSubjectIdAndSetSections(this.state.editNote[this.state.editingNoteIndex].subjectId)
        })
    }

    checkAllFieldFilled(slidesData) {
        let checkValue = [true]
        slidesData.forEach((item, index) => {
            let checkSubjectAndSectionSelected = false
            if (item.subjectId || item.sectionId) {
                checkSubjectAndSectionSelected = (item.subjectId === "selectSubject") ||
                    (item.subjectId === "allSections") || !item.subjectId || !item.sectionId
            }
            if (item.title.trim().length === 0 || item.description.trim().length === 0 || checkSubjectAndSectionSelected) {
                checkValue = [false, index]
            }
        })
        return checkValue
    }

    submitNoteDetails(isAutoSave) {

        let _id = ''
        let slidesData = []

        if (this.state.editNote) {
            _id = this.state.editNoteId
            slidesData = this.state.editNote
        }

        const checkAllField = this.checkAllFieldFilled(slidesData)

        if (checkAllField[0] && !isAutoSave) {
            axios.post('/current-affairs-notes', { _id, slidesData })
                .then(() => {
                    this.closeModalAndResetState()

                    let params = queryString.parse(this.props.location.search)

                    let index = 0
                    
                    if (params && isFinite(parseInt(params.index))) {
                        index = params.index
                    }

                    this.props.history.push({
                        pathname: "/daily-challenges",
                        search: `index=${index}&activeTab=${this.state.activeTab}`
                    })
                })
                .catch(err => {
                    console.log(err)
                    alert('Error, Check console')
                })
        } else {
            alert('Please fill all the fields')
            this.setState({ editingNoteIndex: checkAllField[1] })
        }
    }

    notesModalOnChangeHandler = (e) => {

        let value = e.target.value
        let name = e.target.name

        this.setState((oldState) => {
            oldState.editNote[this.state.editingNoteIndex][`${name}`] = value
            return { editNote: oldState.editNote }
        })

    }

    selectedSubjectOnChangeHandler = (e) => {

        const value = e.target.value

        if (value !== "selectSubject") {
            
            const foundSubject = this.props.subjects.find(e => e._id === value)

            if (foundSubject) {

                this.setState((oldState) => {
                    oldState.editNote[this.state.editingNoteIndex].subjectId = value
                    
                    const clonedSections = cloneDeep(foundSubject.sections)
                    const sortedSections = sortBy(clonedSections, ['name'])

                    return {
                        subjectId: value,
                        sectionId: "allSections",
                        sections: sortedSections,
                        editNote: oldState.editNote
                    }
                })
               
            } else {
                alert("Subject not found")
            }
        }
    }

    selectedSectionOnChangeHandler = (e) => {
        const value = e.target.value
        this.setState((oldState) => {
            oldState.editNote[this.state.editingNoteIndex].sectionId = value
            return { sectionId: value, editNote: oldState.editNote }
        })
    }

    deleteThisNote(deletingNoteIndex) {
        if (window.confirm("Are you sure you want to delete this note?")) {
                this.setState((oldState) => {
                    
                    oldState.editNote.splice(deletingNoteIndex, 1)

                    if (deletingNoteIndex > 0) {
                        return { editNote: oldState.editNote, editingNoteIndex: null }
                    } else {
                        return { editNote: oldState.editNote, editingNoteIndex: null }
                    }

                })
        }
    }

    closeModalAndResetState() {
        this.setState({
            showNotesModal: false,
            subjectId: "selectSubject",
            sectionId: "allSections",
            sections: [],
            editingNoteIndex: null
        })
    }

    fetchQuestionsAndShowModal(dailyChallengeId) {
        this.setState({ isLoading: true }, () => {

            let requestData = {
                count: 50,
                subjectId: 1,
                questionStatus: 'all',
                activeFlag: 'undeleted',
                showInChallenge: 'all',
                languageId: 1,
                dailyChallengeId
            }

            axios.get('/get-questions', { params: requestData })
                .then(res => {
                    this.setState({
                        questionsData: res.data.data.questions,
                        showEditQuestionModal: true,
                        isLoading: false,
                        selectedDailyChallengeId: dailyChallengeId
                    })
                })
                .catch(err => {
                    if (err.response) {
                        alert(err.response.data.message)
                        this.setState({ isLoading: false })
                    }
                    console.log(err)
                })
        })
    }

    fetchQuestionsAndNavigate(dailyChallengeId) {
        let period = 'daily'

        if (this.state.activeTab && this.state.activeTab !== '0') {
            period = tabs.find(each => each.key === this.state.activeTab).value
        }

        let paramsObj = {
            dailyChallengeId,
            currentAffairsPeriod: period
        }

        this.props.history.push({ pathname: "/questions", state: paramsObj })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
            const { editNote } = this.state
            this.setState((oldState) => {
                const reorderedList = arrayMove(editNote, oldIndex, newIndex)
                oldState.editNote = reorderedList
                return { ...oldState, changedData: true }
            })

    }

    renderSkipMenu = () => {
        let returnArray = []
        let itemCount = parseInt(this.state.dailyChallengeCount, 10) / 10

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => this.setState({ skipCount: index }, () => {
                        // this.fetchDailyChallengeHistory()
                        this.setQueryParams(index)
                    })}
                >
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    addQuestionWithId() {
        this.setState({ isLoading: true }, () => {
            axios.post('/daily-challenge/question', {
                dailyChallengeId: this.state.selectedDailyChallengeId,
                questionId: this.state.newQuestionId
            })
                .then(() => {
                    this.setState({ newQuestionId: '' }, () => {
                        this.fetchQuestionsAndShowModal(this.state.selectedDailyChallengeId)
                    })
                })
                .catch(err => {
                    
                    if (err && err.response && err.response.data && err.response.data.message) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error. Please check console")
                        console.log(err)
                    }

                    this.setState({ isLoading: false })
                    
                })
        })
    }

    editDate(dailyChallengeId, currentDate, newDate, index) {
        this.setState({ isLoading: true }, () => {
            axios.post('/daily-challenge/date', { dailyChallengeId, newDate })
                .then(() => {
                    this.fetchDailyChallengeHistory()
                })
                .catch(err => {
                    
                    if (err && err.response && err.response.data && err.response.data.message) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error. Please check console")
                        console.log(err)
                    }

                    this.setState((oldState) => {
                        oldState.data[index].isoDate = currentDate
                        return { data: oldState.data, isLoading: false }
                    })

                })
        })
    }

    removeQuestionWithId(questionId) {
        if (window.confirm('Are you sure you want delete this question')) {
            this.setState({ isLoading: true }, () => {
                axios.delete('/daily-challenge/question', {
                    params: {
                        dailyChallengeId: this.state.selectedDailyChallengeId,
                        questionId
                    }
                })
                    .then(res => {
                        this.setState(oldState => {
                            let removedQuestionData = oldState.questionsData.filter(item => !(item._id === questionId))
                            return { questionsData: removedQuestionData }
                        })
                        this.fetchDailyChallengeHistory()
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ isLoading: false })
                        alert("Error. Please check console")
                    })
            })
        }
    }

    renderEditQuestionModal() {
        return (
            <Modal
                fade={true}
                size="lg"
                isOpen={this.state.showEditQuestionModal}
            >
                <ModalHeader>
                    Questions
                </ModalHeader>

                <ModalBody>

                    <Input
                        placeholder="Enter Question ID"
                        type="text"
                        name="newQuestionId"
                        value={this.state.newQuestionId || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 10 }}
                    />

                    <Button
                        onClick={() => this.addQuestionWithId()}
                        color="primary"
                    >
                        Add Question
                    </Button>

                    <hr />

                    <ListGroup>
                        <p>Count: {this.state.questionsData.length}</p>
                        {this.state.questionsData.map((item) => {
                            return (
                                <ListGroupItem
                                    key={item._id}
                                    style={{ backgroundColor: item.activeFlag === false ? 'rgba(255,0,0,0.1)' : 'transparent' }}
                                >
                                    <Row>
                                        <Col md='9'>
                                            <p style={{ marginBottom: 0 }}>{item.question}</p>
                                        </Col>
                                        <Col md='1'>
                                            <FontAwesomeIcon
                                                className="iconButton"
                                                onClick={() => { copyToClipboard(item._id) }}
                                                style={{ color: 'grey', marginLeft: 5, marginRight: 5 }}
                                                icon={faCopy}
                                            />
                                        </Col>
                                        <Col md='2'>
                                            <Button
                                                color='danger'
                                                outline
                                                style={{ marginRight: 5 }}
                                                onClick={() => this.removeQuestionWithId(item._id)}
                                            >
                                                Remove
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        onClick={() => this.setState({ showEditQuestionModal: false })}
                    >Close</Button>
                </ModalFooter>
            </Modal>
        )
    }

    setQueryParams = (index) => {
        this.props.history.push({
            pathname: "/daily-challenges",
            search: `index=${index}&activeTab=${this.state.activeTab}`
        })
    }

    renderGroupedCurrentAffairs(periodKey) {
        return (
            <Row>
                <Col md="12">
                    {
                        this.state.data && this.state.data.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Date</th>
                                        <th>Notes</th>
                                        <th>Questions Count</th>
                                        {this.state.activeTab === '0' && <th>Users Attended</th>}
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((dc, index) => {
                                            return (
                                                <tr key={dc._id || dc.displayDate}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {
                                                            dc.displayDate ? dc.displayDate :
                                                                <DatePicker
                                                                    hintText="Set Date"
                                                                    container="inline"
                                                                    okLabel="Set Date"
                                                                    value={dc.isoDate ? new Date(dc.isoDate) : null}
                                                                    onChange={(e, newDate) => this.editDate(dc._id, dc.isoDate, newDate, index)}
                                                                />
                                                        }
                                                    </td>
                                                    <td>{dc.notesCount}</td>
                                                    <td>{dc.questionsCount}</td>
                                                    {this.state.activeTab === '0' && <td>{dc.attendedUsersCount}</td>}

                                                    <td>

                                                        <Button
                                                            outline
                                                            color="primary"
                                                            style={{ marginRight: '1rem' }}
                                                            onClick={() => {
                                                                this.fetchQuestionsAndNavigate(dc._id)
                                                            }}>
                                                            View Questions
                                                        </Button>

                                                        {
                                                            this.state.activeTab === '0' &&
                                                            <>
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    onClick={() => this.checkNotesExistAndPopModal(index)}>
                                                                    Notes
                                                                </Button>

                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    style={{ marginLeft: '1rem' }}
                                                                    onClick={() => this.fetchQuestionsAndShowModal(dc._id)}>
                                                                    Edit Questions
                                                                </Button>
                                                            </>
                                                        }

                                                        {
                                                            (this.state.activeTab === '1' ||
                                                            this.state.activeTab === '2' ||
                                                            this.state.activeTab === '3') ?
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        
                                                                        let activeTab = 'weekly'
                                                                        let period = dc.weekMonthYear

                                                                        if (this.state.activeTab === '2') {
                                                                            activeTab = 'monthly'
                                                                            period = dc.monthAndYear
                                                                        } else if (this.state.activeTab === '3') {
                                                                            activeTab = 'yearly'
                                                                            period = dc.year
                                                                        }

                                                                        this.props.history.push({
                                                                            pathname: "/all-challenge-notes",
                                                                            search: `activeTab=${activeTab}&period=${period}`
                                                                        })

                                                                    }}
                                                                >
                                                                    Notes
                                                                </Button>
                                                            : null

                                                        }

                                                        {
                                                            dc._id ?
                                                                <Button
                                                                    onClick={() => {
                                                                        copyToClipboard(dc._id)
                                                                        toast("ID copied", {
                                                                            position: toast.POSITION.TOP_CENTER,
                                                                            hideProgressBar: true
                                                                            })
                                                                    }}
                                                                    outline
                                                                    style={{ marginLeft: '1rem' }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="iconButton"
                                                                        style={{ color: 'grey' }}
                                                                        icon={faCopy}
                                                                    />
                                                                </Button>
                                                                : null
                                                        }
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            <p style={{ textAlign: 'center' }}>Loading daily challenges...</p>
                    }
                </Col>
            </Row>

        )

    }

    render() {

        const { data, showNotesModal, editNote} = this.state
        let isEditNote = (Array.isArray(editNote) && editNote.length > 0)
        return (
            <>
                {this.state.isLoading && <Loader />}

                <Container fluid>

                    {this.state.showEditQuestionModal && this.renderEditQuestionModal()}

                    {data && data.length > 0 &&
                        <Modal
                            size='lg'
                            style={{ maxWidth: '70vw'}}
                            isOpen={showNotesModal}
                        >
                            <ModalHeader>
                                Add Notes
                            </ModalHeader>

                            <ModalBody>
                                <Container fluid>
                                    <Row>
                                        <Table style={{ marginTop: 10 }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={1}>Index</th>
                                                    <th colSpan={5}>Content</th>
                                                    <th colSpan={1}>Actions</th>
                                                </tr>
                                            </thead>
                                            <SortableList
                                                isSortingEnabled={this.state.isSortingEnabled}
                                                items={isEditNote ? editNote : []}
                                                onSortEnd={this.onSortEnd}
                                                editingNoteIndex={this.state.editingNoteIndex}
                                                notesModalOnChangeHandler={this.notesModalOnChangeHandler}
                                                selectedSubjectOnChangeHandler={this.selectedSubjectOnChangeHandler}
                                                selectedSectionOnChangeHandler={this.selectedSectionOnChangeHandler}
                                                onEditButton={(index) => {
                                                    this.setState({ editingNoteIndex: index }, () => {
                                                        this.checkNextIndexSubjectIdAndSetSections(this.state.editNote[this.state.editingNoteIndex].subjectId)
                                                    })
                                                }}
                                                onDeleteButton={(index) => this.deleteThisNote(index)}
                                                subjects={this.props.subjects}
                                                sections={this.state.sections}
                                            />
                                        </Table>
                                    </Row>

                                    <Row>
                                        {
                                            !this.state.isSortingEnabled &&
                                            <Button
                                                outline
                                                color="primary"
                                                onClick={() => this.addNewSlide()}
                                            >
                                                Add New Note
                                            </Button>
                                        }
                                    </Row>
                                    
                                </Container>
                                <ModalFooter>

                                    {
                                        !this.state.isSortingEnabled &&
                                        <Button
                                            outline
                                            color="primary"
                                            onClick={() => this.submitNoteDetails()}
                                        >
                                            Submit Edit
                                        </Button>
                                    }

                                    <Button
                                        style={{ marginLeft: 10 }}
                                        outline
                                        color='success'
                                        onClick={() => this.setState({ isSortingEnabled: !this.state.isSortingEnabled })}
                                    >
                                        {this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                                    </Button>

                                    {
                                        !this.state.isSortingEnabled &&
                                        <Button outline color="primary" onClick={() => this.closeModalAndResetState()}>
                                            Close
                                        </Button>
                                    }

                                </ModalFooter>
                            </ModalBody>
                        </Modal>
                    }

                    <Row style={{ flex: 1, justifyContent: 'flex-end', margin: 20 }}>
                        <Button
                            outline
                            style={{ marginRight: 10 }}
                            color="primary"
                            onClick={() => this.props.history.push({ pathname: "/current-affairs-reported", })}>
                            Reported
                        </Button>

                        <UncontrolledDropdown>
                            <DropdownToggle caret
                                disabled={this.state.activeTab !== '0'}
                                color="success" id="dropdown-basic"> Page {this.getParamsIndex() + 1} </DropdownToggle>
                            <DropdownMenu className="addScroll">
                                {this.renderSkipMenu()}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Row>

                    <Nav tabs>
                        {tabs.map((each) => {
                            return (
                                <NavItem key={each.key}>
                                    <NavLink className={this.state.activeTab === each.key ? 'active' : ''}
                                        onClick={() => {
                                            this.setState({ skipCount: 0, activeTab: each.key, isLoading: true }, () => {
                                                this.fetchDailyChallengeHistory()
                                                this.setQueryParams(0)
                                            })
                                        }}>
                                        {each.title}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>

                        <TabPane tabId={'0'} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                            {this.renderGroupedCurrentAffairs('0')}
                        </TabPane>

                        <TabPane tabId={'1'} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                            {this.renderGroupedCurrentAffairs('1')}
                        </TabPane>

                        <TabPane tabId={'2'} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                            {this.renderGroupedCurrentAffairs('2')}
                        </TabPane>

                        <TabPane tabId={'3'} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                            {this.renderGroupedCurrentAffairs('3')}
                        </TabPane>

                    </TabContent>

                </Container>

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        subjects: state.data.subjects,
    }
}

export default connect(mapStateToProps, {})(DataEntry)