import React, { useState, useEffect } from 'react'
import { Container, Table } from 'react-bootstrap'
import queryString from 'query-string';
import Axios from 'axios';
import { unstable_batchedUpdates } from 'react-dom';
import moment from 'moment'

import { msToTime } from '../utils'
import Loader from '../Components/Loader'

function ExamsAttended({ location }) {

    const [isLoading, setIsLoading] = useState(true)
    const [examsAttended, setExamsAttended] = useState(null)

    useEffect(() => {
        const { userId } = queryString.parse(location.search)
        getExamsAttended(userId)
    }, [location.search])

    const getExamsAttended = (userId) => {
        Axios.get('/exams-attended', { params: { userId } })
        .then(res => {
            unstable_batchedUpdates(() => {
                setExamsAttended(res.data.attendedExams)
                setIsLoading(false)
            })
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            alert("Server error. Check console")
        })
    }

    return (
        <Container fluid>

            { isLoading && <Loader /> }
            
            {
                (Array.isArray(examsAttended) && examsAttended.length > 0) ?
                    <Table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Exam Name</th>
                                <th>Date</th>
                                <th>Time Taken</th>
                                <th>Correct</th>
                                <th>Wrong</th>
                                <th>Unattended</th>
                                <th>Marks</th>
                                <th>Daily Exam</th>
                                <th>Finished Exam</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                examsAttended.map((exam, index) => (
                                    <tr key={exam._id}>
                                        <td>{index + 1}</td>
                                        <td>{exam.examName}</td>
                                        <td>{moment(exam.attendedDate).format('DD MMM YYYY')}</td>
                                        <td>{msToTime(exam.timeTaken)}</td>
                                        <td>{exam.correctAnswer}</td>
                                        <td>{exam.wrongAnswer}</td>
                                        <td>{exam.unattended}</td>
                                        <td>{exam.marks}</td>
                                        <td>{exam.dailyExamId ? "Yes" : 'No'}</td>
                                        <td>{exam.hasSubmittedExam ? "Yes" : "No"}</td>
                                    </tr>
                                ))
                            }                            
                        </tbody>
                    </Table>
                :
                Array.isArray(examsAttended) ?
                    <h6 style={{ textAlign: 'center', marginTop: 100 }}>No exams attended</h6>
                :
                ''
            }
        </Container>
    )
}

export default ExamsAttended
