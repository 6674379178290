import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
    Container, Row, Col,
    Input, Label, Form, FormGroup, Button,
} from 'reactstrap'

import queryString from 'query-string'

import { questionTypes } from '../Components/AddForm'
import { explanationTypes } from '../utils/constants'

import Loader from '../Components/Loader'

const SITE_URL = 'https://www.keralapsc.app'

const generateSlug = (questionid, text, questionType, explanationType) => {
	
    // eslint-disable-next-line no-useless-escape
	const outString = text.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
	const lowerCase = outString.toLowerCase()
	const removedQuestionMark = lowerCase.replace("?", "")
	const joinedUsingDashes = removedQuestionMark.split(" ").join("-")

    if (questionType === questionTypes.MATH || explanationType === explanationTypes.MATH) {
		return `${SITE_URL}/questions/${joinedUsingDashes}-${questionid}`
	} else {
        return `${SITE_URL}/questions/${joinedUsingDashes}-${questionid}?amp=1`
	}

}

function QuestionLinkGenerator({ location }) {

    const [siteDetails, setSiteDetails] = useState(null)
    const [questionData, setQuestionData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const { questionid } = queryString.parse(location.search)

    useEffect(() => {
        getQuestionDetails()
    }, [])

    const getQuestionDetails = () => {

        setIsLoading(true)

        axios.get('/blog/question-page-details', { params: { questionid } })
            .then(res => {
                setQuestionData(res.data.question)
                setSiteDetails(res.data.siteDetails)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)

                if (err && err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message)
                } else {
                    alert("Server error. Check console.")
                }
            })

    }

    const saveSiteDetails = () => {

        setIsLoading(true)

        axios.post('/blog/question-page-details', { questionid, siteDetails })
            .then(() => getQuestionDetails())
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })

    }

    const updateSiteDetails = (e) => {
        setSiteDetails({ ...siteDetails, [e.target.name]: e.target.value })
    }

    return (
        <>
            {isLoading && <Loader />}
            <Container>

                <Row style={{ marginTop: 50, marginBottom: 50 }}>
                    <Col>
                        <h2>Generate Question Link</h2>
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <Form>
                            <FormGroup>
                                <Label for="pageTitle">Page Title</Label>
                                <Input
                                    placeholder={"Page Title"}
                                    name='pageTitle'
                                    type="text"
                                    id='pageTitle'
                                    value={siteDetails ? siteDetails.pageTitle : ''}
                                    onChange={(e) => updateSiteDetails(e)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="pageDescription">Page Description</Label>
                                <Input
                                    placeholder={"Page Description"}
                                    name='pageDescription'
                                    type="text"
                                    id='pageDescription'
                                    value={siteDetails ? siteDetails.pageDescription : ''}
                                    onChange={(e) => updateSiteDetails(e)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="translation">Translation</Label>
                                <Input
                                    placeholder={"Question Translation"}
                                    name='translation'
                                    type="text"
                                    id='translation'
                                    value={siteDetails ? siteDetails.translation : ''}
                                    onChange={(e) => updateSiteDetails(e)}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                color="primary"
                                onClick={saveSiteDetails}
                            >
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
                <hr />

                <Row>
                    <Col>
                        <h4>Link: </h4>
                        {
                            siteDetails && siteDetails.translation ?
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={generateSlug(questionid, siteDetails.translation, questionData.type, questionData.explanationType)}
                                >
                                    {generateSlug(questionid, siteDetails.translation)}
                                </a> :
                                <p>"Link not generated yet"</p>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default QuestionLinkGenerator
