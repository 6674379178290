import React, { Component } from 'react'
import {
    Container, Row, Col, Input, Button, Badge,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import { DatePicker, Checkbox } from 'material-ui'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChessKing, faCopy } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep, intersection } from 'lodash'

import Loader from '../Components/Loader'
import { copyToClipboard, dropDownPeriods, formatTime } from '../utils'
import { leadsStatus } from '../utils/constants'
import { isAdmin } from '../utils/dashboardRoles'

const AssigningHistoryItem = ({ data }) => {
    const { assignedTo, assigningDate, tags, note, status } = data
    return (
        <Col>
            <p>Assigned To: {assignedTo ? assignedTo.name : ''}</p>
            <p>Assigned Date: {moment(assigningDate).format("YYYY MMMM D, h:mm:ss a")}</p>
            <p>Status: {status}</p>
            <p>Tags: {Array.isArray(tags) && tags.length > 0 ? tags.map((each) => `${each} `) : "None"} </p>
            <p>Lead Note: {note}</p>
            <hr />
        </Col>
    )
}

class AssignedLeads extends Component {

    state = {
        isLoading: false,
        salesmanId: "allSalesmen",
        assignedLeads: null,
        isOpen: false,

        salesmen: this.filterAndSortSalesmen(),
        period: "today",
        periodLabel: "Today",

        fromDate: null,
        toDate: null,

        selectedLeadStatus: 'All',
        isOpenLeadStatus: false,

        selectedLeadsResult: 'All',
        isOpenLeadResult: false,
        selectedPhoneNumber: []
    }

    filterAndSortSalesmen() {
        let salesmen = cloneDeep(this.props.salesmen)
        salesmen = salesmen.filter(salesman => {
            if (salesman.activeFlag && intersection(salesman.roleIds, [7, 8, 9]).length) {
                return true
            }
        })

        salesmen.sort(function SortArray(x, y) {
            if (x.name.toLowerCase() < y.name.toLowerCase()) { return -1 }
            if (x.name.toLowerCase() > y.name.toLowerCase()) { return 1 }
            return 0
        })

        return salesmen
    }

    checkAllLeads(e, value) {
        if (value) {
            this.setState(oldState => {
                oldState.assignedLeads.forEach(each => {
                    each.checked = true
                    if (!oldState.selectedPhoneNumber.includes(each.mobile)) {
                        oldState.selectedPhoneNumber.push(each.mobile)
                    }
                })
                return { selectedPhoneNumber: oldState.selectedPhoneNumber, assignedLeads: oldState.assignedLeads }
            })
        } else {
            this.setState(oldState => {
                oldState.assignedLeads.forEach(each => {
                    each.checked = false
                })
                return { selectedPhoneNumber: [], assignedLeads: oldState.assignedLeads }
            })  
        }
    }

    getLeadsData(reset) {
        this.setState({ isLoading: true }, () => {

            const {
                period, fromDate, toDate, salesmanId, skipCount,
                // selectedLeadsResult: result,
                selectedLeadStatus: status
            } = this.state

            let params = {
                salesmanId,
                // result,
                status,
                skip: reset ? 0 : skipCount,
            }

            if (fromDate && toDate) {
                params.period = { fromDate, toDate }
            } else {
                params.period = period
            }

            Axios.get('/all-assigned-leads', { params })
                .then((res) => {
                    if (res.data.leads.length === 0) {
                        let setObject = { isLoading: false }
                        if (reset) {
                            setObject.skipCount = 0
                            setObject.assignedLeads = []
                            setObject.selectedPhoneNumber = []
                        }
                        this.setState(setObject)
                        alert("No more leads available")
                    } else if (reset) {
                        this.setState({
                            isLoading: false,
                            assignedLeads: res.data.leads,
                            selectedPhoneNumber: [],
                            skipCount: res.data.leads.length
                        })
                    } else {
                        this.setState((oldState) => {
                            const assignedLeads = oldState.assignedLeads.concat(res.data.leads)
                            const newSkipCount = assignedLeads.length
                            return { isLoading: false, assignedLeads, skipCount: newSkipCount }
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                    alert("Server error. Check console")
                })
        })
    }

    getLeadDetails(mobile) {

        this.setState({ isLoading: true })

        Axios.get('/lead-details', { params: { mobile } })
            .then(res => {
                this.setState({
                    leadDetails: res.data,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
                alert("Server error, check console")
            })

    }

    renderLeadDetailsModal(leadDetails) {

        const { name, mobile, premiumUser, bookmarked, assigningHistory, callHistory } = leadDetails

        return (
            <Modal
                size="lg"
                isOpen={true}
            >
                <ModalHeader>Lead Details</ModalHeader>
                <ModalBody>

                    <Row>
                        <Col md="6">
                            <p>Name: {name}</p>
                            <p>Mobile: {mobile}</p>
                            <p>Premium User: {premiumUser ? "True" : "False"}</p>
                            <p>Locked: {bookmarked ? "True" : "False"}</p>
                        </Col>

                        <Col md="6">
                            <h4>Current Lead Details</h4>
                            <AssigningHistoryItem data={leadDetails} />
                        </Col>
                    </Row>

                    <hr />

                    <h4>Assigning History</h4>

                    {
                        Array.isArray(assigningHistory) && assigningHistory.length > 0 ?
                            <Row>
                                {
                                    assigningHistory.map(each => (
                                        <AssigningHistoryItem
                                            key={each._id}
                                            data={each}
                                        />
                                    ))
                                }
                            </Row>
                            : null
                    }

                    <h4>Call History</h4>

                    {
                        Array.isArray(callHistory) && callHistory.length > 0 ?
                            <Row>
                                {
                                    callHistory.map(each => (
                                        <Col key={each._id} md="6">
                                            <p>Caller: <b>{each.callerId.name}</b></p>
                                            {
                                                each.dateTime ?
                                                    <p>Call Date: {moment(each.dateTime).subtract(330, 'minutes').format("DD-MMM-YYYY HH:mm:ss A")}</p>
                                                    : <p>Call Date: Not available</p>
                                            }
                                            <p>Call Duration: <b>{formatTime(each.duration)}</b></p>
                                            <p>Call Type: {each.type}</p>
                                            <p>Interacted: <b>{each.interacted ? <span style={{ color: 'green' }}>True</span> : <span style={{ color: 'red' }}>False</span>}</b></p>
                                            <hr />
                                        </Col>
                                    ))
                                }
                            </Row>
                            :
                            <p style={{ color: 'grey' }}>No calls yet</p>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => this.setState({ leadDetails: null })}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const { isLoading } = this.state

        return (
            <>

                {isLoading && <Loader />}
                { this.state.leadDetails ? this.renderLeadDetailsModal(this.state.leadDetails) : null }

                <Container fluid style={{ paddingTop: 50 }}>

                    <Row style={{ marginBottom: 20 }}>
                        <Col md="12">

                            <Row>
                                <Col style={{ display: 'flex', flexDirection: 'row' }}>

                                    <Dropdown
                                        style={{ marginRight: 10 }}
                                        isOpen={this.state.isOpen}
                                        toggle={() => this.setState({ isOpen: !this.state.isOpen })}
                                    >
                                        <DropdownToggle caret>{this.state.periodLabel}</DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                dropDownPeriods.map((each) => {
                                                    return (
                                                        <DropdownItem
                                                            key={each.value}
                                                            onClick={() => this.setState({
                                                                isOpen: false,
                                                                period: each.value,
                                                                periodLabel: each.label,
                                                            },
                                                                () => {
                                                                    if (each.value !== "customTime") {
                                                                        this.setState({ fromDate: null, toDate: null })
                                                                    } else {
                                                                        this.setState({ showCustomTime: true, isLoading: false })
                                                                    }
                                                                }
                                                            )}
                                                        >
                                                            {each.label}
                                                        </DropdownItem>
                                                    )
                                                })
                                            }
                                        </DropdownMenu>
                                    </Dropdown>

                                    <Dropdown
                                        style={{ marginRight: 10 }}
                                        isOpen={this.state.isOpenLeadStatus}
                                        toggle={() => this.setState({ isOpenLeadStatus: !this.state.isOpenLeadStatus })}
                                    >
                                        <DropdownToggle caret>{this.state.selectedLeadStatus}</DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                leadsStatus.map((each) => {
                                                    return (
                                                        <DropdownItem
                                                            key={each}
                                                            onClick={() => this.setState({ selectedLeadStatus: each, isOpenLeadStatus: false })}
                                                        >
                                                            {each}
                                                        </DropdownItem>
                                                    )
                                                })
                                            }
                                        </DropdownMenu>
                                    </Dropdown>

                                    {/* <Dropdown
                                        style={{ marginRight: 10 }}
                                        isOpen={this.state.isOpenLeadResult}
                                        toggle={() => this.setState({ isOpenLeadResult: !this.state.isOpenLeadResult })}
                                    >
                                        <DropdownToggle caret>{this.state.selectedLeadsResult}</DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                leadsResult.map((each) => {
                                                    return (
                                                        <DropdownItem
                                                            key={each}
                                                            onClick={() => this.setState({ selectedLeadsResult: each, isOpenLeadResult: false })}
                                                        >
                                                            {each}
                                                        </DropdownItem>
                                                    )
                                                })
                                            }
                                            <DropdownItem
                                                onClick={() => this.setState({ selectedLeadsResult: "All", isOpenLeadResult: false })}
                                            >
                                                All
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown> */}

                                    <Col md="4">
                                        <Input
                                            style={{ marginRight: 10 }}
                                            type="select"
                                            name="post"
                                            id="post"
                                            value={this.state.salesmanId || ''}
                                            onChange={(e) => {
                                                const value = e.target.value
                                                if (value !== "allSalesmen") {
                                                    this.setState({ salesmanId: value })
                                                } else {
                                                    this.setState({ salesmanId: null })
                                                }
                                            }}
                                        >
                                            {[{ _id: "allSalesmen", name: "All Sales Agents" }, ...this.state.salesmen].map((each, index) => {
                                                return <option
                                                    key={each._id}
                                                    value={each._id}
                                                >
                                                    {each.name}
                                                </option>
                                            })
                                            }
                                        </Input>
                                    </Col>

                                    <Col>
                                        <Button
                                            outline
                                            block
                                            color="primary"
                                            onClick={() => this.getLeadsData(true)}>
                                            Get Leads
                                        </Button>
                                    </Col>

                                    {
                                        this.state.selectedPhoneNumber.length > 0 &&
                                        <Button
                                            onClick={() => {

                                                let string = ''
                                                this.state.selectedPhoneNumber.forEach(item => {
                                                    string = `${string}\n${item}`
                                                })

                                                copyToClipboard(string)
                                                toast("IDs Copied", {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true
                                                })
                                                
                                            }}
                                            outline
                                            style={{ alignSelf: 'flex-start', marginLeft: 5 }}
                                        >
                                            <FontAwesomeIcon
                                                className="iconButton"
                                                icon={faCopy}
                                            />
                                        </Button>
                                    }

                                </Col>
                                <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    <Col md="4">
                                        <Button
                                            outline
                                            block
                                            color="primary"
                                            onClick={() => {
                                                const mobile = window.prompt("Enter lead mobile number")
                                                if (mobile) {
                                                    this.getLeadDetails(mobile)
                                                }
                                            }}
                                        >
                                            Single Lead Details
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>

                            {this.state.period === "customTime" && this.state.showCustomTime ?
                                <Row>
                                    <div style={{ display: "flex", marginLeft: 10, alignItems: 'center' }}>
                                        <DatePicker
                                            hintText="From Date"
                                            container="inline"
                                            value={this.state.fromDate}
                                            onChange={(e, date) => this.setState({ fromDate: date })}
                                        />

                                        <DatePicker
                                            hintText="To Date"
                                            container="inline"
                                            value={this.state.toDate}
                                            onChange={(e, date) => this.setState({ toDate: date })}
                                        />

                                        {this.state.fromDate && this.state.toDate ? (
                                            <Button
                                                outline
                                                color="danger"
                                                style={{ margin: 10 }}
                                                disabled={!this.state.fromDate || !this.state.toDate}
                                                onClick={
                                                    () => this.setState({
                                                        fromDate: null,
                                                        toDate: null,
                                                        isLoading: true,
                                                        period: "today",
                                                        periodLabel: "Today",
                                                    })
                                                }
                                            >
                                                CLEAR DATE FILTER
                                            </Button>
                                        ) : null}
                                    </div>
                                </Row>
                                : null
                            }
                        </Col>
                    </Row>

                    <Row>

                        {
                            Array.isArray(this.state.assignedLeads) && this.state.assignedLeads.length > 0 ?
                                <>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: 80 }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <Checkbox
                                                            onCheck={(e, value) => this.checkAllLeads(e, value)}
                                                            checked={this.state.assignedLeads.length === this.state.selectedPhoneNumber.length}
                                                        />
                                                    </div>
                                                </th>
                                                <th>Index</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>Assigned On</th>
                                                <th>Remark</th>
                                                <th>Assigned To</th>
                                                <th>Status</th>
                                                <th>Called Time</th>
                                                <th>Note</th>
                                                <th>Tags</th>
                                                {isAdmin(this.props.roleIds) && <th>Actions</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.assignedLeads.map((lead, index) => {
                                                    return (
                                                        <tr key={lead._id} style={{ padding: 0, margin: 0, backgroundColor: lead.checked && '#e4f2f7' }}>
                                                            <td>
                                                                <Checkbox
                                                                    onCheck={(e, value) => {
                                                                        if (value) {
                                                                            this.setState(oldState => {
                                                                                oldState.selectedPhoneNumber.push(lead.mobile)
                                                                                oldState.assignedLeads[index].checked = true
                                                                                return { selectedPhoneNumber: oldState.selectedPhoneNumber, assignedLeads: oldState.assignedLeads }
                                                                            })
                                                                        } else {
                                                                            this.setState(oldState => {
                                                                                let filterNumber = oldState.selectedPhoneNumber.filter(item => item !== lead.mobile)
                                                                                oldState.assignedLeads[index].checked = false
                                                                                return { selectedPhoneNumber: filterNumber }
                                                                            })
                                                                        }
                                                                    }}
                                                                    checked={lead.checked}
                                                                />
                                                            </td>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <span style={{ marginRight: 10 }}>{lead.name}</span>
                                                                <span>
                                                                    {lead.premiumUser ? <FontAwesomeIcon icon={faChessKing} /> : null}
                                                                </span>
                                                            </td>
                                                            <td>{lead.mobile}</td>
                                                            <td>{moment(lead.date).format("D MMM YYYY")}</td>
                                                            <td>{lead.remark}</td>
                                                            <td>{lead.salesmanName}</td>
                                                            <td>{lead.status}</td>
                                                            <td>
                                                                {
                                                                    (lead.callHistory && Array.isArray(lead.callHistory) && lead.callHistory.length > 0) ?
                                                                        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 200 }}>
                                                                            {
                                                                                lead.callHistory.map(item => {
                                                                                    if (item.dateTime) {
                                                                                        return (
                                                                                            <Badge
                                                                                                pill
                                                                                                key={item._id}
                                                                                                style={{ marginLeft: 3, marginTop: 1, marginBottom: 1 }}
                                                                                            >
                                                                                                {moment(item.dateTime).format("DD-MMM-YYYY HH:mm:ss A")}
                                                                                            </Badge>
                                                                                        )
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        '-'
                                                                }
                                                            </td>
                                                            <td>{lead.note}</td>
                                                            <td>
                                                                {
                                                                    Array.isArray(lead.tags) ?
                                                                        lead.tags.map((each) => <span style={{ fontSize: 14 }}>{each} </span>)
                                                                        : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    style={{ marginRight: 5, marginBottom: 5 }}
                                                                    onClick={() => this.getLeadDetails(lead.mobile)}
                                                                >
                                                                    Lead Details
                                                                </Button>
                                                                {
                                                                    isAdmin(this.props.roleIds) &&
                                                                    <Button
                                                                        outline
                                                                        color="primary"
                                                                        style={{ marginRight: 5, marginBottom: 5 }}
                                                                        onClick={() => this.props.history.push({ pathname: "/bots", state: { mobileNumber: lead.mobile } })}
                                                                    >
                                                                        User Details
                                                                    </Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>

                                    <Row style={{ width: '100%', paddingBottom: 50 }}>
                                        <Col md="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                outline
                                                onClick={() => this.getLeadsData(false)}
                                                style={{ alignSelf: 'flex-end' }}
                                            >
                                                Load More
                                            </Button>
                                        </Col>
                                    </Row>

                                </>
                                :
                                Array.isArray(this.state.assignedLeads) && this.state.assignedLeads.length === 0 ?
                                    <div style={{ width: '100%', marginTop: 100 }}>
                                        <p style={{ fontSize: 22, color: 'grey', textAlign: 'center' }}>No leads assigned in the selected period</p>
                                    </div>
                                    : null
                        }
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        salesmen: state.data.salesmen,
        roleIds: state.auth.roleIds
    }
}

export default connect(mapStateToProps)(AssignedLeads)