import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Button } from "reactstrap"
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply, faTrash } from '@fortawesome/free-solid-svg-icons'

import Loader from '../../Components/Loader'

function BlogList() {

    const [isLoading, setIsLoading] = useState(true)
    const [blogPosts, setBlogPosts] = useState([])

    const getBlogPosts = async () => {
        setIsLoading(true)
        axios.get('/blog/admin/blog-posts')
            .then(res => {
                setIsLoading(false)
                setBlogPosts(res.data)
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })
    }

    const deleteBlogPost = async (blogId, currentActiveFlag) => {

        if (!window.confirm(`Are you sure you want to ${currentActiveFlag ? "delete" : "un-delete"} this blog post?`)) {
            return true
        }

        setIsLoading(true)

        axios.delete('/blog/admin/blog-post', { params: { blogId } })
            .then(() => getBlogPosts())
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })
    }
    
    useEffect(() => {
        getBlogPosts()
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <Container>
                <Row  style={{ marginTop: 20, marginBottom: 20 }}>
                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to={`/blog-editor`}>
                            <Button
                                color="primary"
                            >
                                NEW BLOG POST
                            </Button>
                        </Link>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {
                            Array.isArray(blogPosts) ?
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Title & Subtitle</th>
                                            <th>Thumbnail</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            blogPosts.map((blogPost, index) => {
                                                return (
                                                    <tr 
                                                        key={blogPost._id}
                                                        style={{ backgroundColor: blogPost.activeFlag ? 'transparent' : 'rgba(255,0,0,0.1)' }}
                                                    >
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <h4>{blogPost.title}</h4>
                                                            <p>{blogPost.subtitle}</p>
                                                        </td>
                                                        <td>
                                                            <img src={blogPost.imageUri} alt="blog-thumbnail" />
                                                        </td>
                                                        <td>
                                                            <Link
                                                                style={{ marginRight: 10 }}
                                                                to={`/blog-editor?blogId=${blogPost._id}`}
                                                            >
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    
                                                                >
                                                                    EDIT
                                                                </Button>
                                                            </Link>
                                                                <Button
                                                                    outline
                                                                    color={blogPost.activeFlag ? "danger" : "primary"}
                                                                    onClick={() => deleteBlogPost(blogPost._id, blogPost.activeFlag)}
                                                                    // onClick={() => this.setState({ deleteBotId: bot._id }, () => {
                                                                    // this.setState({ botDeleteDialog: true, showBotDeleteSuccessDialog: false })
                                                                    // })}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="iconButton"
                                                                        style={{ color: blogPost.activeFlag ? '#db1616' : '#3B71CA' }}
                                                                        icon={blogPost.activeFlag ? faTrash : faReply}
                                                                    />
                                                                </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : null
                        }
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default BlogList
