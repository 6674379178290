import React from 'react'
import axios from 'axios'
import {
    Container, Row, Col,
    Table, Button,
    ListGroup, ListGroupItem,
    Nav, NavItem, NavLink,
    UncontrolledButtonDropdown, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu
} from 'reactstrap'
import moment from 'moment'
import queryString from 'query-string';

import Loader from '../Components/Loader'
import Aux from '../Components/Auxiliary'
import NotesFeedBack from './NotesFeedBack'
import { dropDownPeriods } from '../utils';

const reportTypes = [
    { id: "2", label: "Wrong Information" },
    { id: "1", label: "Spelling Mistake" },
    { id: "3", label: "Others" },
    { id: "4", label: "Feedback" }
]

class ReportSlide extends React.Component {

    state = {
        reportedSlideDetails: null,
        currentIndex: null,
        usersDetails: null,
        isLoading: false,

        type: "2",
        totalReportsCount: 0

    }

    componentDidMount() {
        this.fetchReports()
    }

    fetchReports() {

        const { type, page, sortBy, period, periodLabel, userId, moduleId } = queryString.parse(this.props.location.search)

        const setObject = { isLoading: true, type }
        const setQueryItems = []

        const paramsObject = {
            type,
            page,
            sortBy,
            period,
            periodLabel,
            userId,
            moduleId,
        }

        if (isFinite(parseInt(type))) {
            setQueryItems.push({ key: 'type', value: type })
            paramsObject.type = parseInt(type)
            setObject.type = `${paramsObject.type}`
        } else {
            setQueryItems.push({ key: 'type', value: "2" })
            setObject.type = "2"
            paramsObject.type = "2"
        }

        if (isFinite(parseInt(page))) {
            paramsObject.skipCount = parseInt(page) * 50
            setObject.pageNumber = parseInt(page)
            setQueryItems.push({ key: 'page', value: page })
        } else {
            setQueryItems.push({ key: 'page', value: 0 })
            setObject.pageNumber = 0
            paramsObject.skipCount = 0 * 50
        }
        
        if (sortBy === 'date' || sortBy === 'count') {
            paramsObject.sortBy = sortBy
            setObject.sortBy = sortBy
            setQueryItems.push({ key: 'sortBy', value: sortBy })
        } else {
            paramsObject.sortBy = 'count'
            setObject.sortBy = 'count'
            setQueryItems.push({ key: 'sortBy', value: 'count' })
        }

        if (period && periodLabel) {

            paramsObject.period = period
            setObject.period = period
            setObject.periodLabel = periodLabel

            setQueryItems.push(
                { key: 'period', value: period },
                { key: 'periodLabel', value: periodLabel }
            )

        } else {

            paramsObject.period = 'thisMonth'
            setObject.period = 'thisMonth'
            setObject.periodLabel = 'This Month'

            setQueryItems.push(
                { key: 'period', value: 'thisMonth' },
                { key: 'periodLabel', value: 'This Month' }
            )
        }

        this.setQueryParams(setQueryItems)

        this.setState(setObject, () => {

            if (type === "4") {
                axios.get('/learn/slide-notes-feedback', { params: paramsObject })
                    .then(res => {
                        this.setState({
                            learnSlideNotesFeedback: res.data.learnSlideNotesFeedback,
                            totalFeedbackCount: res.data.totalFeedbackCount,
                            isLoading: false
                        })
                    }).catch(err => {
                        console.log(err)
                        alert("Server error. Please check console")
                    })
            } else {
                axios.get('/learn/reported-slides', { params: paramsObject })
                    .then(res => {
                        this.setState({
                            reportedSlideDetails: res.data.module,
                            totalReportsCount: res.data.totalReportsCount,
                            type: this.state.type,
                            isLoading: false
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        alert("Error, check console")
                    })
            }
        })

    }

    getUser(index) {

        let userIds = this.state.reportedSlideDetails[index].userExplanation.map(item => item.userId)

        axios.get(`/get-users-details`, { params: { userIds: userIds, requiredDetails: { name: 1, mobile: 1 } } })
            .then((response) => { this.setState({ usersDetails: response.data.usersDetails }) })
            .catch((error) => {
                console.log(error.response)
                alert("Error. Please check console")
            })
    }

    deleteReportedDetails(reportedDetailsId) {
        axios.delete('learn/reported-slide', { params: { reportedDetailsId } })
            .then(res => {
                this.fetchReports()
            })
            .catch(err => {
                console.log(err)
                alert('Error, check console')
            })
    }

    sendNotificationToUsers(reportedDetailsId) {

        this.setState({ isLoading: true })

        axios.post('learn/reported-slide/send-notification', { reportedDetailsId })
            .then(() => this.fetchReports())
            .catch(err => {
                alert('Error, check console')
                this.setState({ isLoading: true })
                console.log(err)
            })

    }

    setQueryParams = (key, value) => {
        
        const search = this.props.location.search;

        const existingQueryParams = {
            type: new URLSearchParams(search).get('type'),
            page: new URLSearchParams(search).get('page'),
            sortBy: new URLSearchParams(search).get('sortBy'),
            subjectIndex: new URLSearchParams(search).get('subjectIndex'),
            sectionIndex: new URLSearchParams(search).get('sectionIndex'),
            period: new URLSearchParams(search).get('period'),
            periodLabel: new URLSearchParams(search).get('periodLabel')
        }

        if (new URLSearchParams(search).get('userId') !== "null") {
            existingQueryParams["userId"] = new URLSearchParams(search).get('userId')
        }

        if (new URLSearchParams(search).get('moduleId') !== "null") {
            existingQueryParams["moduleId"] = new URLSearchParams(search).get('moduleId')
        }

        if (Array.isArray(key)) {
            Object.keys(key).forEach(eachKey => {
                existingQueryParams[key[eachKey].key] = key[eachKey].value
            })
        } else {
            existingQueryParams[key] = `${value}`
        }

        let searchParams = ""

        Object.keys(existingQueryParams).forEach((eacKey, index) => {
            searchParams += `${eacKey}=${existingQueryParams[eacKey]}&`
        })

        this.props.history.push({
            pathname: "/reported-slides",
            search: searchParams
        })
    }

    setSortedBy = (sortBy) => {
        this.setQueryParams('sortBy', sortBy)
        setTimeout(() => {
            this.fetchReports()
        }, 1)
    }

    renderSkipMenu = () => {

        let returnArray = []
        let itemCount = parseInt(this.state.totalReportsCount) / 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => {
                        this.setQueryParams('page', index)
                        this.setState({ pageNumber: index },
                            () => this.fetchReports())
                    }
                    }>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    render() {
        return (
            <Container fluid style={{ paddingTop: 10 }}>

                {this.state.isLoading && <Loader />}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <div>
                        <UncontrolledDropdown style={{ marginRight: 15 }}>

                            <DropdownToggle block caret color="primary">
                                {this.state.periodLabel && this.state.periodLabel !== null ? this.state.periodLabel : "This Month"}
                            </DropdownToggle>

                            <DropdownMenu>
                                {
                                    dropDownPeriods.slice(0, -1).map(each => (
                                        <DropdownItem
                                            key={each.value}
                                            onClick={() => {

                                                this.setQueryParams([
                                                    { key: 'period', value: each.value },
                                                    { key: 'periodLabel', value: each.label },
                                                ])

                                                this.setState({
                                                    period: each.period,
                                                    periodLabel: each.label
                                                }, () => {
                                                    this.fetchReports()
                                                })

                                            }}
                                        >
                                            {each.label}
                                        </DropdownItem>
                                    ))
                                }
                            </DropdownMenu>

                        </UncontrolledDropdown>
                    </div>

                    <div>
                        <UncontrolledDropdown style={{ marginRight: 15 }}>
                            <DropdownToggle block caret color="primary">Sort by {this.state.sortBy}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => this.setSortedBy('date')} >
                                    Sort by date
                                </DropdownItem>
                                <DropdownItem onClick={() => this.setSortedBy('count')} >
                                    Sort by count
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>

                    <div>
                        <UncontrolledButtonDropdown>

                            <DropdownToggle block caret color="success">
                                Page {this.state.pageNumber + 1}
                            </DropdownToggle>

                            <DropdownMenu>
                                {this.renderSkipMenu()}
                            </DropdownMenu>

                        </UncontrolledButtonDropdown>
                    </div>
                </div>

                <Nav tabs>
                    {reportTypes.map((eachType) => {
                        return (
                            <NavItem key={eachType.id}>
                                <NavLink className={(this.state.type === eachType.id) ? 'active' : ''}
                                    onClick={() => {

                                        this.setQueryParams([
                                            { key: 'page', value: 0 },
                                            { key: 'type', value: eachType.id },
                                        ])

                                        this.setState({ pageNumber: 0, type: eachType.id }, () => {
                                            this.fetchReports()
                                        })

                                    }}>
                                    {eachType.label}
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>

                <Row>

                    {
                            this.state.type === "4" ?
                                <NotesFeedBack
                                    sortBy={this.state.sortBy}
                                    feedbackData={this.state.learnSlideNotesFeedback}
                                    setIsLoading={ (value) => this.setState({ isLoading: value })}
                                    fetchReports={ () => this.fetchReports() }
                                />
                            :
                            !this.state.isLoading && this.state.reportedSlideDetails && this.state.reportedSlideDetails.length > 0 ?
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Slide Title</th>
                                                <th>Slide Index</th>
                                                <th>Explanation</th>
                                                <th>Reported Count</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reportedSlideDetails.map((item, index) => {
                                                return (
                                                    <Aux key={item._id}>
                                                        <tr key={item._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.moduleTitle}</td>
                                                            <td>{item.slideIndex}</td>
                                                            <td style={{ fontSize: 15 }}>
                                                                <Button
                                                                    color="primary"
                                                                    outline
                                                                    disabled={!item.userExplanation}
                                                                    onClick={() => {
                                                                        if (this.state.currentIndex === index) {
                                                                            this.setState({ currentIndex: null, usersDetails: null })
                                                                        } else {
                                                                            this.setState({ currentIndex: index, usersDetails: null })

                                                                        }
                                                                    }}
                                                                    style={{ marginBottom: '1rem' }}
                                                                >
                                                                    {(this.state.currentIndex !== null && this.state.currentIndex === index) ? "Hide Explanation" : "Show Explanation"}
                                                                </Button>
                                                            </td>
                                                            <td>{item.count}</td>
                                                            <td>
                                                                <Button
                                                                    outline
                                                                    style={{ marginRight: 5 }}
                                                                    color='primary'
                                                                    onClick={() => this.props.history.push({ pathname: `/module/${item.moduleId}`, state: { slideIndex: item.slideIndex } })}
                                                                >
                                                                    Edit
                                                                </Button>
                                                                <Button
                                                                    outline
                                                                    color='danger'
                                                                    onClick={() => this.setState({ isLoading: true }, () => {
                                                                        if (window.confirm('Are you sure you want to delete this item')) {
                                                                            this.deleteReportedDetails(item._id)
                                                                        }
                                                                    })
                                                                    }
                                                                >
                                                                    Delete
                                                                </Button>
                                                                <Button
                                                                    color="link"
                                                                    style={{ marginLeft: 5 }}
                                                                    onClick={() => this.sendNotificationToUsers(item._id)}
                                                                >
                                                                    Corrected
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        {
                                                            (this.state.currentIndex === index && Array.isArray(item.userExplanation) && item.userExplanation.length > 0) &&
                                                            <tr>
                                                                <td colSpan={11}>
                                                                    <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: 10 }}>
                                                                        <Button outline color='primary' onClick={() => this.getUser(index)}>Get Users</Button>
                                                                    </div>
                                                                    <ListGroup>
                                                                        {
                                                                            item.userExplanation.map(each => {
                                                                                if (this.state.usersDetails) {
                                                                                    this.state.usersDetails.forEach(item => {
                                                                                        if (item._id === each.userId) {
                                                                                            each.name = item.name
                                                                                            each.mobileNumber = item.mobile
                                                                                        }
                                                                                    })
                                                                                }
                                                                                return (
                                                                                    <ListGroupItem key={each._id}>
                                                                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                                                            <div>{each.explanation}</div>
                                                                                            {each.userReportedDate ? <div>Date: {moment(each.userReportedDate).format("D MMM YYYY, h:mm:ss a")}</div> : null}
                                                                                            {this.state.usersDetails ?
                                                                                                <div style={{ display: 'flex' }}>
                                                                                                    <div>{`Name :`}</div>
                                                                                                    <strong>{each.name},</strong>
                                                                                                    <div style={{ marginLeft: 5 }}>{`Mobile :`}</div>
                                                                                                    <strong>{each.mobileNumber}</strong>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                    </ListGroupItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ListGroup>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </Aux>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                                :
                                !this.state.isLoading && Array.isArray(this.state.reportedSlideDetails) && this.state.reportedSlideDetails.length === 0 ?
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <h4 style={{ textAlign: 'center', marginTop: 50 }}>No Data Found</h4>
                                    </div>
                                    : null
                    }
                </Row>

            </Container>

        )
    }
}

export default ReportSlide
