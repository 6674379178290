import React, { Component } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Container, Col, Row, Button,Input, Form, ListGroup, ListGroupItem } from 'reactstrap'
import { copyToClipboard } from '../utils'
import { toast } from 'react-toastify'


class NewUserQuestions extends Component {
    state = {
        questionIds: null,
        questionIdsArray: [],
        questionsList: null,
    }

    addQuestion() {

        let bodyObject = {
            contentType: 'newUserQuestions',
            value: this.state.questionId,
        }
        
        axios.put('/misc/', bodyObject)
            .then(()=> this.getQuestions())
            .catch(err => {
                console.log(err)
                alert("Error. Check console")
            })
    }

    deleteQuestions(questionId) {
        if (window.confirm('Delete this item?')) {
            axios.delete('/misc/', { params: { contentType: 'newUserQuestions', value: questionId } })
                .then(()=> this.getQuestions())
                .catch(err => {
                    console.log(err)
                    alert("Error. Check console")
                })
        };
    }

    getQuestions() {
        axios.get('/misc/', { params: { contentType: 'newUserQuestions' } })
            .then(res => {
                const questionIdsArray = res.data.requiredContent.map(item => item._id)
                this.setState({ questionsList: res.data.requiredContent, questionIdsArray })
            })
            .catch(err => {
                console.log(err)
                alert("Error. Check console")
            })
    }

    componentDidMount(){
        this.getQuestions()
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    render() {
        return(
            <Container>
                 <Row style={{justifyContent: 'center'}}>
                    <Col style={{display: 'flex', marginBottom: '5rem', marginTop: '2rem', justifyContent: "center"}}>
                        <Form style={{display: 'flex', width: '75%',justifyContent: 'center'}} onSubmit={()=>this.addQuestion()} >
                            <Input 
                                type='text'
                                name='questionId'
                                placeholder='Add new question (Question ID)'
                                value={this.state.questionId}
                                onChange={this.handleInputChange}    
                            />
                            <Button color='primary' style={{ marginLeft: 10 }} type='submit'>Add</Button>
                        </Form>
                    </Col>
                </Row>
                <Row style={{display:'flex', justifyContent: 'center'}}>
                    {this.state.questionsList && this.state.questionsList.length > 0 ?
                     <ListGroup style={{width: '70%'}}>
                       {this.state.questionsList.map(each => {
                           return(
                                <ListGroupItem key={each._id}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <label>{each.question}</label>  
                                        <div>
                                            <FontAwesomeIcon
                                                className="iconButton"
                                                onClick={() => { 
                                                    copyToClipboard(each._id) 
                                                    toast("ID copied", {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true
                                                        })
                                                }}
                                                style={{ color: 'grey', marginLeft:5, marginRight:5 }}
                                                icon={faCopy} />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="iconButton"
                                                style={{ color: '#db1616', marginLeft: 5, marginRight: 5 }}
                                                onClick={() => this.deleteQuestions(each._id)}
                                            />
                                        </div> 
                                    </div>
                                </ListGroupItem> 
                           )
                       })
                        }  
                    </ListGroup>
                    :
                    this.state.questionsList && this.state.questionsList.length === 0 ?
                        <div style={{ marginTop:100 }}>
                            <h4 style={{ textAlign: 'center' }}>No Questions Added</h4>
                        </div>
                        :
                        <div>
                            <h4 style={{ textAlign: 'center' }}>Loading...</h4>
                        </div>
                    }
                </Row>
            </Container>
        )
    }
}

export default NewUserQuestions