import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container, Col, Table, Row, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { DatePicker } from 'material-ui'
import CheckBox from 'material-ui/Checkbox';
import Loader from '../Components/Loader'
import { dropDownPeriods } from '../utils';
import { Link } from 'react-router-dom'


class DataEntry extends Component {

    state = {
        data: [],
        isLoading: true,
        periodLabel: "Today",
        period: "today",
        showDeletedUsers: false
    }

    getStats(showDeletedUsers) {

        this.setState({ isLoading: true }, () => {
            
            const params = {}

            const { fromDate, toDate, period } = this.state

            if (showDeletedUsers) {
                params.showDeletedUsers = true
            } 

            if (fromDate && toDate) {
                params.period = { fromDate, toDate }
            } else {
                params.period = period
            }

            axios.get('/data-entry-reports', { params })
                .then(res => {
                    this.setState({ data: res.data, isLoading: false })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                    alert("Error. Please check console")
                })
        })

    }

    renderBarGraph() {
        return (
            <ResponsiveContainer width={'100%'} height={700}>
              <BarChart
                width={500}
                height={300}
                data={this.state.data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="questionsAddedCount" fill="#356eb8" barGap/>
                <Bar dataKey="modulesAddedCount" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          );
    }

    componentDidMount() {
        this.getStats()
    }

    render() {
        return (
            <>
                { this.state.isLoading && <Loader />}
                <Container fluid>

                    <Row style={{ display: 'flex', flexDirection: 'row', marginTop: '5rem', marginBlock: 10, marginLeft: '5rem' }}>
                        <Col md="1">
                            <Dropdown isOpen={this.state.isOpen} toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                                <DropdownToggle caret>{this.state.periodLabel && this.state.period ? this.state.periodLabel : "Select Date"}</DropdownToggle>
                                <DropdownMenu>
                                    {
                                        dropDownPeriods.map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each.value}
                                                    onClick={() => this.setState({ period: each.value, isOpen: false, periodLabel: each.label, data: null }, () => {
                                                        each.value !== "customTime" ? this.getStats() : this.setState({ showCustomTime: true, isLoading: false })
                                                    })}
                                                >
                                                    {each.label}
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col md="4">
                            <CheckBox
                            label={'Show deleted users'}
                            style={{ marginTop: '10px' }}
                            checked={this.state.showDeletedUsers}
                            onClick={() => this.setState({ showDeletedUsers: !this.state.showDeletedUsers}, () => {
                                this.getStats(this.state.showDeletedUsers)
                                })}
                            />
                        </Col>
                    </Row>

                    {this.state.period === "customTime" && this.state.showCustomTime ?
                        <Row
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: '5rem',
                                marginTop: '5rem',
                                marginBlock: 10,
                            }}
                        >
                            <DatePicker
                                hintText="From Date"
                                container="inline"
                                value={this.state.fromDate}
                                onChange={(e, date) => this.setState({ fromDate: date })}
                            />

                            <DatePicker
                                hintText="To Date"
                                container="inline"
                                value={this.state.toDate}
                                onChange={(e, date) => this.setState({ toDate: date })}
                            />

                            <Button
                                outline
                                color="primary"
                                style={{ margin: 10 }}
                                disabled={!this.state.fromDate || !this.state.toDate}
                                onClick={() =>
                                    this.setState({ isLoading: true }, () => this.getStats())
                                }
                            >
                                GET
                            </Button>

                            {this.state.fromDate && this.state.toDate ? (
                                <Button
                                    outline
                                    color="danger"
                                    style={{ margin: 10 }}
                                    disabled={!this.state.fromDate || !this.state.toDate}
                                    onClick={() => this.setState({ fromDate: null, toDate: null, isLoading: true })}
                                >
                                    CLEAR DATE FILTER
                                </Button>
                            ) : null}
                        </Row>
                        : null
                    }

                     <Row style={{ margin: '2rem' }}>
                        {this.renderBarGraph()}
                    </Row>
                
                    {
                        this.state.data && this.state.data.length > 0 ?
                            <Row >
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Name</th>
                                                { (this.state.period === 'today') || (this.state.period === 'yesterday') ? <th>Login details</th> : null }
                                                <th>Q Added</th>
                                                <th>Q Explanation</th>
                                                <th>Q Edited</th>
                                                <th>Q Reported</th>
                                                <th>Modules Added</th>
                                                <th>Modules Edited</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map((user, index) => {
                                                    return (
                                                        <tr key={user._id} style={{background:!(user.activeFlag) ? "rgba(255,0,0,0.3)" :null }}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div>
                                                                    <h1 style={{fontSize: 17, marginBottom: 1}}>{user.name}</h1> 
                                                                    <p style={{fontSize: 14}}>{user.email}</p>
                                                                </div>
                                                            </td>
                                                            {(this.state.period === 'today') || (this.state.period === 'yesterday') ?
                                                                <td>
                                                                    <div>
                                                                        {
                                                                            user.loginDetails && user.loginDetails[0] ?
                                                                            <>
                                                                                <p style={{ fontSize: 14, marginBottom: 0 }}>Login time : {moment(user.loginDetails[0].firstRequestTime).utcOffset("+05:30").format('hh:mm:ss a')}</p>
                                                                                <p style={{ fontSize: 14, marginBottom: 0 }}>Logout time : {moment(user.loginDetails[0].lastRequestTime).utcOffset("+05:30").format('hh:mm:ss a')}</p>
                                                                                <p style={{ fontSize: 14, marginBottom: 0 }}>Hours worked : {Math.floor(moment(user.loginDetails[0].lastRequestTime).diff(user.loginDetails[0].firstRequestTime, 'minutes') / 60) + 'h ' + moment(user.loginDetails[0].lastRequestTime).diff(user.loginDetails[0].firstRequestTime, 'minutes') % 60}m</p>
                                                                            </> : null
                                                                        }
                                                                    </div>
                                                                </td>
                                                                : null
                                                            }
                                                            <td>
                                                                <Link
                                                                    onClick={()=> {
                                                                        let period = this.state.period

                                                                        if (period === "customTime") {
                                                                            period = { fromDate:this.state.fromDate, toDate: this.state.toDate }
                                                                            this.setState({period})
                                                                        }
                                                                    }}
                                                                    to={`/questions?addedByName=${user.name}&&addedBy=${user._id}&&period=${this.state.period}`}
                                                                    target="_blank"
                                                                >
                                                                    {user.questionsAddedCount}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {user.withExplanation}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    onClick={()=> {
                                                                        let period = this.state.period

                                                                        if (period === "customTime") {
                                                                            period = { fromDate:this.state.fromDate, toDate: this.state.toDate }
                                                                            this.setState({period})
                                                                        }
                                                                    }}
                                                                    to={`/questions?editedByName=${user.name}&&editedBy=${user._id}&&period=${this.state.period}`}
                                                                    target="_blank"
                                                                >
                                                                    {user.questionsEditedCount}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {user.reportedQuestionsCount}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    onClick={() => {
                                                                        let pathData = { userId: user._id }

                                                                        const { fromDate, toDate, period } = this.state

                                                                        if (fromDate && toDate) {
                                                                            pathData.period = { fromDate, toDate }
                                                                        } else {
                                                                            pathData.period = period
                                                                        }
                                                                        this.setState({ pathData: JSON.stringify(pathData) })
                                                                    }}

                                                                    to={`/learn/null/${this.state.pathData}`}
                                                                    target="_blank"
                                                                >
                                                                    {user.modulesAddedCount}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    onClick={() => {
                                                                        let pathData = { editedUserId: user._id }

                                                                        const { fromDate, toDate, period } = this.state

                                                                        if (fromDate && toDate) {
                                                                            pathData.period = { fromDate, toDate }
                                                                        } else {
                                                                            pathData.period = period
                                                                        }
                                                                        this.setState({ pathData: JSON.stringify(pathData) })
                                                                    }}

                                                                    to={`/learn/null/${this.state.pathData}`}
                                                                    target="_blank"

                                                                >
                                                                    {user.modulesEditedCount}
                                                                </Link>
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            :
                            Array.isArray(this.state.data) && this.state.data.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                    <h6>User have not added questions</h6>
                                </div>
                                : null
                    }
                </Container>
            </>
        )
    }
}

export default DataEntry