import React from 'react'
import moment from 'moment'

import {
    Container, Row, Col, Table, Button, Input,Form,Spinner,
    ListGroup, ListGroupItem,Nav, NavItem,NavLink,Label,
    Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane,
    DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown
} from 'reactstrap'
import queryString from 'query-string';
import Axios from 'axios'
import { DatePicker } from 'material-ui'
import { connect } from 'react-redux'
import CheckBox from 'material-ui/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPen, faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons'

import Loader from'../Components/Loader'
import { copyToClipboard } from '../utils';
import { updatePacks } from '../actions'
import { toast } from 'react-toastify'

const examTimeArrays = [
    {
        label: "All Times",
        time: 'all'
    },
    {
        label: "5 AM",
        time: 5
    },
    {
        label: "10 AM",
        time: 10
    },
    {
        label: "6 AM",
        time: 18
    },
]

class TopicExam extends React.Component {

    state = {
        isLoading: true,

        topicExams: null,
        questions: [],
        showNewExamModal: false,
        pageNumber:0,
        showPostDetails: false,
        examPost: null,

        premiumFlag: false,

        examName: '',
        startingDate: new Date(),
        
        newQuestionIdsArr: [],
        selectedPost: "all",

        activeTab: 1,
        selectedSubjectId: "",
        selectedSectionId: "",
        numOfQuestions: null

    }

    componentDidMount() {
        this.getExams()
        const upcomingExams = this.props.posts.filter(each => each.upcoming)
        this.setState({ upcomingExams })
    }

    getExams() {

        const { topicExamId } = queryString.parse(this.props.location.search)

        const { selectedPost, selectedExamTime, pageNumber } = this.state
        
        let paramsObject = {
            skipCount: pageNumber * 25,
            topicExamId
        }

        if (selectedPost && selectedPost !== "all") {
            paramsObject.selectedPost = selectedPost
        }

        if (selectedExamTime && selectedExamTime !== "all") {
            paramsObject.selectedExamTime = selectedExamTime
        }

        Axios.get(`/topic-exam/`, { params: paramsObject })
            .then(res => {
                this.setState({ topicExams: res.data.topicExams, topicExamCount: res.data.examCount , isLoading: false })
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.log(err)
                alert("Error, check console")
            })
    }

    submitNewExam() {

        if (this.state.examName && this.state.examPost && this.state.examPost !== "0") {

            const {
                examName, questions, startingDate, startingTime, 
                examPost, examSection, premiumFlag, examTopics, examSubtitle, examType,
                selectedSubjectId, selectedSectionId, numQuestions
            } = this.state

            if (startingTime === '0') {
                alert("Select exam time")
                return true
            }

            let putObject = {
                description: examName, questionsCount: parseInt(numQuestions),
                questions, startingDate, startingTime,
                premiumFlag, examTopics, examSubtitle, examType, examPost
            }

            if (examSection) { putObject.examSection = examSection }
            if (selectedSubjectId) { putObject.selectedSubjectId = selectedSubjectId }
            if (selectedSectionId) { putObject.selectedSectionId = selectedSectionId }

            Axios.put(`topic-exam/`, putObject)
                .then(() => {
                    this.getExams()
                    this.setState({ showNewExamModal: false, examName: false, isLoading: false })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    if (err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error, check console")
                    }
                })
        } else {
            this.setState({ isLoading: false })
            alert("Enter a vaid exam name and post")
        }
    }

    submitEditExam() {

        if (this.state.examName && this.state.examPost && this.state.examPost !== '0') {
            
            const {
                editingExamId, examName, questions,
                selectedSectionId, selectedSubjectId,
                startingDate, startingTime,
                examSection, premiumFlag, examSubtitle, examTopics, examType, examPost
            } = this.state
            
            Axios.post(`/topic-exam/`, {
                examId: editingExamId,
                description: examName,
                examSection,
                questions,
                startingDate,
                startingTime,
                premiumFlag,
                examSubtitle,
                examTopics,
                examType,
                examPost,
                selectedSectionId,
                selectedSubjectId,
            })
                .then(() => {
                    this.getExams()
                    this.setState({ showNewExamModal: false, examName: false, editingExamId: null, description: null, questions: [], isLoading: false })
                })
                .catch(err => {
                    console.log(err)
                    if (err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error, check console")
                    }
                })
        } else {
            alert("Enter a vaid exam name and exam post")
        }
    }

    addCAQuestions() {

        const { selectedSubjectId, selectedSectionId, numOfQuestions, examSection } = this.state

        if (numOfQuestions) {

            this.setState({ isLoading: true }, () => {
                Axios.post('topic-exam/ca-questions', { 
                    topicExamId: this.state.selectedTopicExamId,
                    subjectId: selectedSubjectId, 
                    sectionId: selectedSectionId,
                    examSection, numOfQuestions,
                })
                    .then(() => {
                        this.getExams()
                        this.updateExamQuestions(this.state.selectedTopicExamId)
                        this.setState({ isLoading: false })
                    })
                    .catch(err => {
                        if (err && err.response && err.response.status === 400) {
                            alert(err.response.data.message)
                        } else {
                            console.log(err)
                            alert('check console')
                        }
                        this.setState({ isLoading: false })
                    })
            })
        } else {
            alert('Please enter all the required fields')
        }
    }

    renderSkipMenu =()=> {
        let returnArray = []
        let itemCount = parseInt(this.state.topicExamCount) / 25
        
        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={
                        ()=> this.setState({ pageNumber: index },
                        ()=>this.getExams())
                }>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    deleteExam(examId, status) {
        Axios.delete(`topic-exam`, { params: { examId, status } })
            .then(res => {
                this.setState({ isLoading: false })
                this.getExams()
            })
            .catch(err => {
                if (err && err.response && err.response.status === 400) {
                    alert(err.response.data.message)
                } else {
                    console.log(err)
                    alert('check console')
                }
                this.setState({ isLoading: false })
            })
    }

    fetchExamQuestions({ _id, description, examSection, examType, selectedSectionId, selectedSubjectId }) {

        Axios.get(`topic-exam/questions`, { params: { examId: _id } })
            .then(res => {

                const examQuestions = res.data.map(each => {
                    each.options.forEach(option => {
                        if (option.id === each.answer) {
                            each.answerText = option.text
                        }
                    })
                    return each
                })

                let sections = []

                if (selectedSubjectId) {
                    const foundSubject = this.props.subjects.find(e => e._id === selectedSubjectId)
                    if (foundSubject) {
                        sections = foundSubject.sections
                    }
                }

                this.setState({
                    examQuestions,
                    examName: description,
                    examType,
                    showExamQuestionsModal: true,
                    isLoading: false,
                    selectedSectionId, 
                    selectedSubjectId,
                    selectedTopicExamId: _id,
                    sections,
                    examSection
                })
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    updateExamQuestions(examId) {

        Axios.get(`topic-exam/questions`, { params: { examId } })
            .then(res => {

                const examQuestions = res.data.map(each => {
                    each.options.forEach(option => {
                        if (option.id === each.answer) {
                            each.answerText = option.text
                        }
                    })
                    return each
                })


                this.setState({ examQuestions, isLoading: false, })
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    handleChangeDate = (event, startingDate) => {
        this.setState({ startingDate });
    };

    selectedSubjectOnChangeHandler(e) {
        const value = e.target.value

        if (value !== "All Subjects") {

            const foundSubject = this.props.subjects.find(e => e._id === value)

            if (foundSubject) {
                this.setState({ selectedSubjectId: value, selectedSectionId: 'allSections', sections: foundSubject.sections })
            } else {
                this.setState({ selectedSubjectId: value, selectedSectionId: 'allSections', sections: [] })
            }
        }
    }

    selectedSectionOnChangeHandler(e) {
        const value = e.target.value
        this.setState({ selectedSectionId: value})
    }

    addQuestionWithId = () => {
        if (this.state.newQuestionIdsArr.length > 0) {
            const questionIdsSplitted = this.state.newQuestionIdsArr.split("\n")
            const allItems = questionIdsSplitted.map(each => each.replace(/\s/g, ''))
            const questionIds = allItems.filter(each => each)

        this.setState({ isLoading: true }, () => {
                Axios.post('/topic-exam/question', {
                    topicExamId: this.state.selectedTopicExamId,
                    questionIds
                })
                    .then(() => {
                        this.setState({ newQuestionIdsArr: [] }, () => {
                            this.updateExamQuestions(this.state.selectedTopicExamId)
                            this.getExams()
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ isLoading: false })
                        alert("Error. Please check console")
                    })
            })

        } else {
            alert("Please enter a valid question ID")
        }
    }

    selectexamTimes(examTime, index) {

        this.setState(currentState => {

            const activePacks = currentState.activePacks

            if (activePacks[index].createTopicExamsAt && activePacks[index].createTopicExamsAt.includes(examTime)) {

                activePacks[index].createTopicExamsAt.splice(activePacks[index].createTopicExamsAt.indexOf(examTime), 1)

            } else if(activePacks[index] && activePacks[index].createTopicExamsAt ){

                activePacks[index].createTopicExamsAt.push(examTime)

            } else {

                activePacks[index].createTopicExamsAt = [examTime]                
            }

            return { activePacks }
        })
    }

    renderQuestionsList = ()  => {
        return (
            <ListGroup style={{ marginTop: 20 }}>
            {
                this.state.examQuestions && this.state.examQuestions.length > 0 ?
                    this.state.examQuestions.map((each, index) => {
                        return (
                            <ListGroupItem key={each._id}>
                                <Row >
                                    <Col md='9'>
                                        <p>{index + 1}. {each.question}</p>
                                        <p style={{ fontSize: 14, marginTop: 1, color: 'grey' }}>{each.answerText}</p>
                                    </Col>
                                    <Col md='1'>
                                        <FontAwesomeIcon
                                            className="iconButton"
                                            onClick={() => { 
                                                copyToClipboard(each._id) 
                                                toast("ID copied", {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true
                                                    })
                                            }}
                                            style={{ color: 'grey', marginLeft: 5, marginRight: 5 }}
                                            icon={faCopy}
                                        />
                                    </Col>
                                    <Col md='2'>
                                        <Button
                                            color='danger'
                                            outline
                                            style={{ marginRight: 5 }}
                                            onClick={() => this.removeQuestionWithId(each._id)}
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>

                            </ListGroupItem>

                        )
                    })
                    :
                    <ListGroupItem>
                        No questions added
                    </ListGroupItem>
            }
        </ListGroup>
        )
    }

    getDisplayExamType = (examType) => {
        if (!examType) {
            return 'path'
        } else if (examType === 'weeklyCa' || examType === 'monthlyCa' || examType === 'yearlyCa') {
            return 'currentAffairs'
        } else {
            return examType
        }
    }

    removeQuestionWithId = (questionId) => {
        this.setState({ isLoading: true }, () => {
            Axios.delete('/topic-exam/question', {
                params: {
                    topicExamId: this.state.selectedTopicExamId,
                    questionId
                }
            })
                .then(() => {
                    this.updateExamQuestions(this.state.selectedTopicExamId)
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                    alert("Error. Please check console")
                })
        })
    }

    enablePost = (index) => {

        this.setState(currentState => {

            const activePacks = currentState.activePacks
            if (activePacks[index] && activePacks[index].enableAutomaticTopicExam) {

                activePacks[index].enableAutomaticTopicExam = false
            } else if (activePacks[index] && !activePacks[index].enableAutomaticTopicExam) {
                activePacks[index].enableAutomaticTopicExam = true

            }

            return { activePacks }
        })
    }

    submitAutomaticTopicExamData = () => {

        this.setState({ isLoading: true })

        for (let i = 0; i < this.state.activePacks.length; i++) {
            if (this.state.activePacks[i].enableAutomaticTopicExam && this.state.activePacks[i].createTopicExamsAt.length < 1) {
                alert(`please select exam time for the ${this.state.activePacks[i].name}`)
                return true
            }
        }

        Axios.post('/topic-exam/set-automatic-topic-exam-data', { activePacks: this.state.activePacks })
            .then(() => {
                this.props.updatePacks(this.state.activePacks)
                this.setState({ showTopicExamManageModal: false, isLoading: false })
            }).catch(err => {
                console.log(err)
                alert('Server Error')
            })

    }

    renderManageTopicExamModal = () => {
        return (

            <Modal
                isOpen={this.state.showTopicExamManageModal}
                size='large'
            >
                <ModalHeader>
                    Select Posts to create Automatic Topic Exam
                </ModalHeader>
                <ModalBody>
                    {
                        Array.isArray(this.state.activePacks) &&
                        <Row>
                            <Col>
                                {
                                    this.state.activePacks.map((each, index) => {

                                        let checked = false
                                        let checkedForFive = false
                                        let checkedForTen = false
                                        let checkedForSix = false

                                        if (this.state.activePacks[index].enableAutomaticTopicExam) {
                                            checked = true
                                        }

                                        if (this.state.activePacks[index].createTopicExamsAt) {

                                            const { createTopicExamsAt } = this.state.activePacks[index]

                                            if (createTopicExamsAt.includes('5')) {
                                                checkedForFive = true
                                            }

                                            if (createTopicExamsAt.includes('10')) {
                                                checkedForTen = true
                                            }
                                            if (createTopicExamsAt.includes('6')) {
                                                checkedForSix = true
                                            }
                                        }

                                        return (
                                            <div key={each._id}>
                                                <CheckBox
                                                    label={each.title}
                                                    checked={checked}
                                                    style={{ fontWeight: "bold" }}
                                                    onClick={() => this.enablePost(index)}
                                                />
                                                <div style={{ marginLeft: 20, display: "flex", marginBottom: 20 }}>
                                                    <CheckBox
                                                        label={"5 AM"}
                                                        checked={checkedForFive}
                                                        size="small"
                                                        disabled={!checked}
                                                        onClick={() => this.selectexamTimes('5', index)}
                                                    />
                                                    <CheckBox
                                                        label={"10 AM"}
                                                        checked={checkedForTen}
                                                        size="small"
                                                        disabled={!checked}
                                                        onClick={() => this.selectexamTimes('10', index)}
                                                    />
                                                    <CheckBox
                                                        label={"6 PM"}
                                                        checked={checkedForSix}
                                                        size="small"
                                                        disabled={!checked}
                                                        onClick={() => this.selectexamTimes('6', index)}
                                                    />
                                                </div>

                                                <hr />
                                            </div>
                                        )
                                    })

                                }
                            </Col>
                        </Row>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='primary'
                        onClick={() => this.submitAutomaticTopicExamData()}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => this.setState({ showTopicExamManageModal: false, activePacks: [] })}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

        )
    }

    render() {
        return (
            <>
           
                {this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}

                <Container fluid style={{ padding: 50 }}>
                    
                    {this.state.showTopicExamManageModal && this.renderManageTopicExamModal()}
                    

                    <Modal isOpen={this.state.showNewExamModal} size="lg">

                        <ModalHeader>
                            Enter Exam Details
                        </ModalHeader>

                        <ModalBody>

                            <Input
                                placeholder="Enter Exam Name"
                                type="text"
                                name="examName"
                                value={this.state.examName || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Input
                                placeholder="Exam Description"
                                type="text"
                                name="examSubtitle"
                                value={this.state.examSubtitle || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Input
                                placeholder="Exam Topics"
                                type="textarea"
                                name="examTopics"
                                value={this.state.examTopics || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Row>
                                <Col md="8">
                                    <Input
                                        placeholder="Topic ID / Crash Course Topic ID / CA ID"
                                        type="text"
                                        name="examSection"
                                        value={this.state.examSection || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </Col>

                                <Col md="4">
                                        <Input
                                            type="select" name="select" id="questionType"
                                            value={this.state.examType}
                                            onChange={(e) => this.setState({ examType: e.target.value })}
                                        >
                                            <option value="topic">Topic Exam</option>
                                            <option value="path">Crash Course Exam</option>
                                            <option value="currentAffairs">CA Exam</option>
                                        </Input>
                                </Col>
                            </Row>

                            {
                                (this.state.examType === "currentAffairs") &&
                                <Row style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col>
                                    <Label for="exampleSelect">Select Subject</Label>
                                    <Input
                                        type="select"
                                        name={"subjectId"}
                                        id={"subjectId"}
                                        value={this.state.selectedSubjectId}
                                        onChange={(e) => { this.selectedSubjectOnChangeHandler(e) }}
                                    >
                                        {
                                            this.props.subjects ?
                                                [
                                                    { _id: "allSubjects", name: "All Subjects" }, ...this.props.subjects].map((subject, index) => (
                                                        <option key={subject._id} value={subject._id}>{subject.name}</option>
                                                    ))
                                                :
                                                null
                                        }
                                    </Input>
                                </Col>
                                <Col>
                                    <Label for="selectedSectionId">Select Section</Label>
                                    <Input
                                        type="select"
                                        name={"selectedSectionId"}
                                        id={"selectedSectionId"}
                                        value={this.state.selectedSectionId}
                                        onChange={(e) => { this.selectedSectionOnChangeHandler(e) }}
                                    >
                                        {
                                            Array.isArray(this.state.sections) && this.state.sections.length > 0 ?
                                                [{ _id: "allSections", name: "All Sections" }, ...this.state.sections].map(section => {
                                                    return (
                                                        <option
                                                            key={section._id}
                                                            value={section._id}
                                                        >
                                                            {section.name}
                                                        </option>
                                                    )
                                                })
                                                :
                                                [{ _id: "allSections", name: "All Sections" }].map(section => {
                                                    return (
                                                        <option key={section._id} value={section._id}>
                                                            {section.name}
                                                        </option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </Col>
                            </Row>
                            }

                            {
                                Array.isArray(this.state.upcomingExams) &&
                                <Row>
                                    <Col>
                                        <Input
                                            type="select"
                                            name="examPost"
                                            id="examPost"
                                            value={this.state.examPost || ''}
                                            onChange={(e) => {
                                                const value = e.target.value
                                                this.setState({ examPost: value })
                                            }}
                                            style={{ marginTop: 10 }}
                                        >
                                            {[
                                                { _id: "0", name: "Select Examination Post" },
                                                ...this.state.upcomingExams].map((each, index) => (
                                                    <option key={each._id} value={each._id}>
                                                        {each.name}
                                                    </option>
                                                ))}
                                        </Input>
                                    </Col>
                                </Row>
                            }

                            <Row style={{ marginTop: '20px' }}>
                                <Col md="6">
                                    <CheckBox
                                        label={'Premium Topic Exam'}
                                        checked={this.state.premiumFlag}
                                        onClick={() => this.setState({ premiumFlag: !this.state.premiumFlag })}
                                    />
                                </Col>

                                {
                                    !this.state.editingExamId &&
                                        <Col md="6">
                                            <Input
                                                placeholder="Number of questions"
                                                type="text"
                                                name="numQuestions"
                                                value={this.state.numQuestions || ''}
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                }

                            </Row>

                            <Row style={{ marginTop: 20 }}>
                                <Col md="6">
                                    <h6>Starting date</h6>
                                    <DatePicker
                                        style={{ paddingLeft: 30, paddingRight: 30 }}
                                        container="inline"
                                        hintText="Exam Starting Date"
                                        value={this.state.startingDate}
                                        onChange={this.handleChangeDate}
                                        defaultDate={this.state.startingDate}
                                    />
                                </Col>
                                <Col md="6">
                                    <label>Starting time</label>
                                    <Col>
                                        <Input
                                            type="select"
                                            name="startingTime"
                                            id="startingTime"
                                            value={this.state.startingTime || ''}
                                            onChange={(e) => {
                                                const value = e.target.value
                                                this.setState({ startingTime: value })
                                            }}
                                            style={{ marginTop: 10 }}
                                        >
                                            {[
                                                { _id: "0", name: "Select Time" },
                                                { _id: "5", name: "5 AM to 9 AM" },
                                                { _id: "10", name: "10 AM to 2 PM" },
                                                { _id: "18", name: "6 PM to 10 PM" },
                                            ].map((each) => (
                                                <option key={each._id} value={each._id}>
                                                    {each.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>

                                </Col>
                            </Row>

                        </ModalBody>

                        <ModalFooter>

                            <Button
                                outline
                                color="primary"
                                onClick={() => {
                                    if (this.state.editingExamId) {
                                        this.submitEditExam()
                                        this.setState({isLoading: true})
                                    } else {
                                        this.submitNewExam()
                                        this.setState({isLoading: true})
                                    }
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                outline
                                onClick={() => this.setState({ showNewExamModal: false, isLoading: false })}
                            >
                                Close
                            </Button>

                        </ModalFooter>

                    </Modal>

                    <Modal isOpen={this.state.showExamQuestionsModal} size="lg">

                        <ModalHeader>
                            Questions in {this.state.examName}
                        </ModalHeader>

                        <ModalBody>

                            <Nav tabs style={{ marginBottom: '1rem' }}>
                                <NavItem>
                                    <NavLink
                                        className={this.state.activeTab === 1 ? 'active' : null}
                                        onClick={() => this.setState({ activeTab: 1 })}
                                    >
                                        Add questions by ID
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={this.state.activeTab === 2 ? 'active' : null}
                                        onClick={() => this.setState({ activeTab: 2 })}
                                    >
                                        Add CA questions
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeTab} style={{ paddingTop: 20 }}>
                                <TabPane tabId={1} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                                    <Input
                                        type="textarea"
                                        rows="10"
                                        name="newQuestionIdsArr"
                                        id="newQuestionIdsArr"
                                        value={this.state.newQuestionIdsArr || ''}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            this.setState({ newQuestionIdsArr: value })
                                        }}
                                        placeholder={"One question ID per line please"}
                                    />

                                    <Button
                                        style={{ marginTop: '1rem' }}
                                        onClick={() => this.addQuestionWithId()}
                                        color="primary"
                                    >
                                        Add Questions
                                    </Button>

                                </TabPane>
                                <TabPane tabId={2} style={{ overflowY: 'scroll', paddingBottom: 50 }}>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Label for="exampleSelect">Select Subject</Label>
                                                <Input
                                                    type="select"
                                                    name={"subjectId"}
                                                    id={"subjectId"}
                                                    value={this.state.selectedSubjectId}
                                                    onChange={(e) => { this.selectedSubjectOnChangeHandler(e) }}
                                                >
                                                    {
                                                        this.props.subjects ?
                                                            [
                                                                { _id: "allSubjects", name: "All Subjects" }, ...this.props.subjects].map((subject, index) => (
                                                                    <option key={subject._id} value={subject._id}>{subject.name}</option>
                                                                ))
                                                            :
                                                            null
                                                    }
                                                </Input>
                                            </Col>
                                            <Col>
                                                <Label for="selectedSectionId">Select Section</Label>
                                                <Input
                                                    type="select"
                                                    name={"selectedSectionId"}
                                                    id={"selectedSectionId"}
                                                    value={this.state.selectedSectionId}
                                                    onChange={(e) => { this.selectedSectionOnChangeHandler(e) }}
                                                >
                                                    {
                                                        Array.isArray(this.state.sections) && this.state.sections.length > 0 ?
                                                            [{ _id: "allSections", name: "All Sections" }, ...this.state.sections].map(section => {
                                                                return (
                                                                    <option
                                                                        key={section._id}
                                                                        value={section._id}
                                                                    >
                                                                        {section.name}
                                                                    </option>
                                                                )
                                                            })
                                                            :
                                                            [{ _id: "allSections", name: "All Sections" }].map(section => {
                                                                return (
                                                                    <option key={section._id} value={section._id}>
                                                                        {section.name}
                                                                    </option>
                                                                )
                                                            })
                                                    }
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: 30 }}>
                                            <Col>
                                                <Label for="questionsCount">Questions</Label>
                                                <Input
                                                    type='number'
                                                    placeholder='Enter number of questions'
                                                    onChange={(e) => this.setState({ numOfQuestions: e.target.value })}
                                                    value={this.state.numOfQuestions || ''}
                                                />
                                            </Col>
                                            <Col>
                                                <Label for="examSection">Current Affairs ID</Label>
                                                <Input
                                                    type='text'
                                                    placeholder='Daily/Monthly/Yealy CA ID'
                                                    onChange={(e) => this.setState({ examSection: e.target.value })}
                                                    value={this.state.examSection || ''}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Button
                                        outline
                                        style={{ marginTop: '1rem' }}
                                        color='primary'
                                        onClick={() => this.addCAQuestions()}
                                    >
                                        Add Questions
                                    </Button>
                                </TabPane>
                            </TabContent>

                            { this.state.isLoading ?  
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                    <Spinner color="info" /> 
                                </div>
                                : 
                                this.renderQuestionsList() 
                            }
                            
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                outline
                                onClick={() => this.setState({ showExamQuestionsModal: false })}
                            >
                                Close
                            </Button>

                        </ModalFooter>

                    </Modal>

                    <Row style={{ marginBottom: 40 }}>
                        
                        <Col md="2">
                            <h2>
                                Topic Exam
                             </h2>
                        </Col>

                        <Col md="2" style={{ flex: 1, justifyContent: 'flex-end' }}>
                            <Button
                                block
                                outline
                                color="primary"
                                onClick={() => this.setState({
                                    editingExamId: null,
                                    examName: null,
                                    examSection: null,
                                    examPost: "0",
                                    startingTime: "0",
                                    questions: [],
                                    examTopics: null,
                                    examSubtitle: null,
                                    examType:"path",
                                    showNewExamModal: true,
                                    premiumFlag: false,
                                    selectedSubjectId: "allSubjects",
                                    selectedSectionId: "allSections",
                                    numQuestions: 0
                                })}
                            >
                                + Create New Topic Exam
                            </Button>
                        </Col>
                        <Col md='2'>
                            <Button
                                outline
                                color='primary'
                                onClick={() => this.setState({ activePacks: this.props.activePacks, showTopicExamManageModal: true })}
                            >
                                Manage Topic Exam
                            </Button>
                        </Col>

                        {
                            Array.isArray(this.state.upcomingExams) &&
                            <Col md="2">
                                <Input
                                    type="select"
                                    name="selectedPost"
                                    id="selectedPost"
                                    value={this.state.selectedPost || ''}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        this.setState({ selectedPost: value }, () => {
                                            this.getExams()
                                        })
                                    }}
                                >
                                    {[
                                        { _id: "all", name: "All Examinations" },
                                        ...this.state.upcomingExams].map((each, index) => (
                                            <option key={each._id} value={each._id}>
                                                {each.name}
                                            </option>
                                        ))}
                                </Input>
                            </Col>
                        }

                        {
                            Array.isArray(this.state.upcomingExams) &&
                            <Col md="2">
                                <Input
                                    type="select"
                                    name="selectExamTime"
                                    id="selectedExamTime"
                                    value={this.state.selectedExamTime || ''}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        this.setState({ selectedExamTime: value }, () => {
                                            this.getExams()
                                        })
                                    }}
                                >
                                    {
                                        examTimeArrays.map((each) => (
                                            <option
                                                key={each.label}
                                                value={each.time}
                                            >
                                                {each.label}
                                            </option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        }

                        <Col md="2">
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="primary" block variant="success" id="dropdown-basic"> Page {this.state.pageNumber + 1} </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {this.renderSkipMenu()}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    </Row>

                    <Row>
                        {
                            this.state.topicExams && this.state.topicExams.length > 0 ?
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Exam Name</th>
                                                <th>Exam Subtitle</th>
                                                <th>Type</th>
                                                <th>Job Post</th>
                                                <th>Attended Users</th>
                                                <th>Starting Time</th>
                                                <th>Questions Count</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.topicExams.map((exam) => {
                                                    return (
                                                        <tr key={exam._id} style={{ background: exam.active ? 'white' : 'rgba(255,0,0,0.1)' }}>
                                                            <td>{exam.description}</td>
                                                            <td>{exam.examSubtitle}</td>
                                                            <td>{
                                                                exam.examType === "path" ? "Crash Path" :
                                                                exam.examType === "topic" ? "Topic" :
                                                                exam.examType === "weeklyCa" ? "Weekly CA" :
                                                                exam.examType === "monthlyCa" ? "Monthly CA" :
                                                                exam.examType === "yearlyCa" ? "Yearly CA" :
                                                                null
                                                            }</td>
                                                            <td>{exam.postDetails ? exam.postDetails.name : null}</td>
                                                            <td>{exam.examResults.length}</td>
                                                            <td>
                                                                {
                                                                    moment(exam.startingTime).format("DD MMMM YYYY") === moment(new Date()).format("DD MMMM YYYY") ?
                                                                        `Today at ${moment(new Date(exam.startingTime)).format("h A")}`
                                                                            :
                                                                                moment(new Date(exam.startingTime)).format("DD MMMM YYYY, h a")
                                                                }
                                                            </td>
                                                            <td>{exam.questions.length}</td>
                                                            <td>
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => this.fetchExamQuestions(exam)}
                                                                >
                                                                    Questions
                                                                </Button>
                                                                
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => {

                                                                        const examHour = new Date(exam.startingTime).getHours()

                                                                        let startingTime = '0'

                                                                        if (examHour < 10) {
                                                                            startingTime = "5"
                                                                        } else if (examHour < 14) {
                                                                            startingTime = "10"
                                                                        } else {
                                                                            startingTime = "18"
                                                                        }

                                                                        let sections = []

                                                                        if (exam.selectedSectionId) {
                                                                            const foundParentSubject = this.props.subjects.find(each => each._id === exam.selectedSubjectId)
                                                                            if (foundParentSubject) {
                                                                                sections = foundParentSubject.sections
                                                                            }
                                                                        }

                                                                        this.setState({
                                                                            editingExamId: exam._id,
                                                                            examName: exam.description,
                                                                            questions: exam.questions,
                                                                            examSection: exam.examSection,
                                                                            examType: this.getDisplayExamType(exam.examType),
                                                                            startingDate: new Date(exam.startingTime),
                                                                            startingTime,
                                                                            showNewExamModal: true,
                                                                            premiumFlag: exam.premiumFlag,
                                                                            examTopics: exam.examTopics,
                                                                            examSubtitle: exam.examSubtitle,
                                                                            examPost: exam.examPost,
                                                                            selectedSubjectId: exam.selectedSubjectId,
                                                                            selectedSectionId: exam.selectedSectionId,

                                                                            sections
                                                                        })

                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </Button>
                                                                <Button outline color={exam.active ? "danger" : "success"} onClick={() => this.deleteExam(exam._id, !exam.active)}
                                                                >
                                                                    {
                                                                        exam.active ?
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                            :
                                                                            <FontAwesomeIcon icon={faTrashRestore} />
                                                                    }
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                :
                                Array.isArray(this.state.topicExams) && this.state.topicExams.length === 0 ?
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <h4 style={{ textAlign: 'center', marginTop: 50 }}>No topic exams found</h4>
                                    </div>
                                : null
                        }

                    </Row>
                </Container>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        posts: state.data.posts,
        activePacks: state.data.packs,
        subjects: state.data.subjects,
        sectionsWithQuestionsCount: state.data.sectionsWithQuestionsCount
    }
}


export default connect(mapStateToProps, { updatePacks })(TopicExam)