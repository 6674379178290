import React, { Component } from 'react'
import axios from 'axios';
import { Container, Col, Table, Row, Button, Modal, ModalBody,
        ModalFooter, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import moment from 'moment'

import Loader from '../Components/Loader'


class FailedPayments extends Component {

    state = { data:[], selectedUsersData:null, isLoading:true, totalCount:0, skipCount:0 }

    componentDidMount() {
        this.fetchFailedPayments()
    }

    fetchFailedPayments() {
        axios.get('/payment/failed-payments', { params: { totalCount:this.state.totalCount, skipCount:this.state.skipCount, limit:100 }})
        .then(res => {
            this.setState({
                data:res.data.failedPayments,
                totalCount:res.data.failedPaymentsCount > 0 ? res.data.failedPaymentsCount : this.state.totalCount,
                isLoading:false
            })
        })
        .catch(err => {
            console.log(err)
            this.setState({ isLoading:false })
            alert("Server error, check console")
        })
    }

    showFailedPaymentsModal(data) {
        this.setState({ selectedUsersData:data })
    }

    renderSkipMenu =()=> {
        let returnArray = []
        let itemCount = parseInt(this.state.totalCount, 10) / 10
        
        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={
                        ()=> this.setState({ skipCount: index, isLoading: true },
                        ()=>this.fetchFailedPayments())
                }>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }


    render() {
        return (
            <>
                { this.state.isLoading && <Loader /> }
                <Container>

                    {
                        this.state.selectedUsersData &&
                            <Modal isOpen={this.state.selectedUsersData !== null} fade={true} size="lg">
                                <ModalBody>
                                    <Row>
                                        <Col xs={12}>
                                            <p>Name: {this.state.selectedUsersData.userData.name}</p>
                                            <p>Mobile: {this.state.selectedUsersData.userData.mobile}</p>
                                        </Col>
                                    </Row>

                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Mode</th>
                                                <th>Time</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                        {
                                                this.state.selectedUsersData.failedPayments.map((data, index) => {
                                                    return (
                                                        <tr key={data._id}>
                                                            <td>{index+1}</td>
                                                            <td>{data.mode}</td>
                                                            <td>{ `${new Date(data.time).toLocaleTimeString()} - ${new Date(data.time).toLocaleDateString()}` }</td>
                                                            <td>
                                                                <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                                                {data.description}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </Table>
                                    
                                </ModalBody>
                                
                                <ModalFooter>
                                    <Button outline color="primary" onClick={ () => this.setState({ selectedUsersData:null }) }>
                                        Close
                                    </Button>
                                </ModalFooter>
                            </Modal>    
                    }

                    <Row style={{ marginTop:50, marginBottom:10, flex:1, justifyContent:'flex-end' }}>
                        <div>
                            <UncontrolledDropdown>
                            <DropdownToggle caret color="success" id="dropdown-basic"> Page {this.state.skipCount + 1} </DropdownToggle>
                            <DropdownMenu className="addScroll">
                                {this.renderSkipMenu()}
                            </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Row>

                    <Row>
                        <Col>
                            {
                                this.state.data && this.state.data.length > 0 ?
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Name</th>
                                                <th>Mobile</th>
                                                <th>District</th>
                                                <th>Joined On</th>
                                                <th>Last Active</th>
                                                <th>Attempts</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                        {
                                                this.state.data.map((data, index) => {
                                                    return (
                                                        <tr key={data._id} style={{ backgroundColor: data.userData.premiumUser ? 'rgba(0,255,0,0.1)' : null }}>
                                                            <td>{index+1}</td>
                                                            <td>{data.userData.name}</td>
                                                            <td>{data.userData.mobile}</td>
                                                            <td>{data.userData.district}</td>
                                                            <td>{moment(data.userData.joiningDate).format("D MMM YYYY, h:mm:ss a")}</td>
                                                            <td>{moment(data.userData.lastActivity).format("D MMM YYYY, h:mm:ss a")}</td>
                                                            <td>{data.failedPayments.length}</td>
                                                            <td>
                                                                <Button
                                                                    outline
                                                                    color="primary" 
                                                                    onClick={ () => this.showFailedPaymentsModal(data) }>
                                                                    Details
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </Table>
                                :
                                null

                            }
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}


export default FailedPayments