import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Button, Table, Row, Modal, ModalHeader, ModalFooter, ModalBody, Label, Input,
    FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu ,DropdownItem
} from 'reactstrap'
import { unstable_batchedUpdates } from 'react-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { cloneDeep } from 'lodash'

import Loader from '../Components/Loader'

const CONTENT_TYPE = "adData"

const AD_ACTIONS = [
    {
        label: "Open URL",
        value: "openUrl",
    },
    {
        label: "Navigate to Route",
        value: "navigateToRoute",
    },
    {
        label: "Open Purchase Modal",
        value: "openPurchaseModal",
    }
]

const ManageVideoImageAds = () => {

    const [adData, setAdData] = useState([])
    const [modalData, setModalData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [adType, setAdType] = useState('')
    const [currentIndex, setCurrentIndex] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [videoId, setVideoId] = useState(null)

    const posts = useSelector(state => state.data.posts, shallowEqual)

    useEffect(() => {
        getAdData()
    }, [])

    const handleInputChange = (e) => {
        setModalData({ ...modalData, [e.target.name]: e.target.value })
    }

    const getAdData = () => {
        axios.get('/misc/', { params: { contentType: CONTENT_TYPE } })
            .then(res => {
                if (res.data.requiredContent && res.data.requiredContent.adData) {
                    unstable_batchedUpdates(() => {
                        setAdData(res.data.requiredContent.adData)
                    })
                }

            }).catch(err => {
                console.log(err)
                alert('server error')
            })

        setIsLoading(false)

    }

    const submitAd = () => {

        const { targetUsers, displayCount, adActions } = modalData

        if (adActions === "navigateToRoute") {
            delete modalData.webViewUrl
        } else if (adActions === "openUrl") {
            delete modalData.routeName
        } else if (adActions === 'openPurchaseModal') {
            delete modalData.routeName
            delete modalData.webViewUrl

        }

        if (!targetUsers || !displayCount) {
            return alert("Please enter all required data")
        }
        
        if (adType === 'videoAd') {
            modalData.videoId = videoId
        } else if ((adType === 'imageAd')) {
            modalData.imageId = imageId
        }

        if (!currentIndex && currentIndex !== 0) {

            axios.put('/misc/', { value: modalData, contentType: CONTENT_TYPE })
                .then(() => {
                    unstable_batchedUpdates(() => {
                        setModalData({})
                        setCurrentIndex(null)
                    })
                    getAdData()
                }).catch(err => {
                    console.log(err)
                    unstable_batchedUpdates(() => {
                        setModalData({})
                        setCurrentIndex(null)
                    })
                    getAdData()
                    alert('server error')
                })
        } else {

            const contentId = adData[currentIndex]._id

            if (adType === 'videoAd') {
                modalData.videoId = videoId
                modalData.imageId = null
            } else if ((adType === 'imageAd')) {
                modalData.imageId = imageId
                modalData.videoId = null
            }

            axios.post('/misc/', { contentType: CONTENT_TYPE, contentId, value: modalData })
                .then(() => {
                    unstable_batchedUpdates(() => {
                        setModalData({})
                        setCurrentIndex(null)
                    })
                    getAdData()
                }).catch(err => {
                    console.log(err)
                    unstable_batchedUpdates(() => {
                        setModalData({})
                    })
                    getAdData()
                    alert('server error')
                })
        }

    }

    const deleteAdData = (itemId) => {
        if (window.confirm("Are you sure to delete this ?")) {

            setIsLoading(true)

            axios.delete('/misc/', { params: { contentType: CONTENT_TYPE, value: itemId } })
                .then(() => {
                    getAdData()
                })
                .catch(err => {
                    console.log(err)
                    alert("Server error. Please check console or contact admin")
                })
        }
    }

    const openAddOrEditModal = () => {
        return (
            <Modal
                isOpen={showModal}
            >
                <ModalHeader>
                    Ad Add or Edit Modal
                </ModalHeader>
                <ModalBody>

                    <Label for="selectAdType">Select Ad Type</Label>
                    <Input
                        type="select"
                        name={"adType"}
                        id={"adType"}
                        value={adType}
                        style={{ marginBottom: 20 }}
                        onChange={(e) => setAdType(e.target.value)}
                    >
                        {
                            [
                                { _id: "selectAdType", name: "Select Ad Type" },
                                { _id: "videoAd", name: "Video Ad" },
                                { _id: "imageAd", name: "Image Ad" }
                            ].map((ad) => (
                                <option
                                    key={ad._id}
                                    value={ad._id}
                                >
                                    {ad.name}
                                </option>
                            ))
                        }
                    </Input>

                    {
                        adType === 'videoAd' ?
                            <>
                                <Label> Video Id </Label>
                                <Input
                                    style={{ marginBottom: 20 }}
                                    value={videoId || ''}
                                    name="videoId"
                                    placeholder={"Enter Video Id"}
                                    onChange={(e) => setVideoId(e.target.value)}
                                />
                            </>
                            :
                            adType === 'imageAd' ?
                                <>
                                    <Label> Image Id </Label>
                                    <Input
                                        style={{ marginBottom: 20 }}
                                        value={imageId || ''}
                                        name="imageId"
                                        placeholder={"Enter Image Id"}
                                        onChange={(e) => setImageId(e.target.value)}
                                    />
                                </>
                                : null

                    }

                    <FormGroup>
                        <UncontrolledDropdown>
                            <Label>Ad Action</Label>
                            <br />
                            <DropdownToggle caret color="primary">
                                {
                                    (modalData && modalData.adActions && AD_ACTIONS.find(each => each.value === modalData.adActions))
                                        ?
                                        AD_ACTIONS.find(each => each.value === modalData.adActions).label
                                        :
                                        "Select Action"
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    AD_ACTIONS.map((each, index) => {
                                        return (
                                            <DropdownItem
                                                key={each.value}
                                                value={each.value}
                                                onClick={() => setModalData({ ...modalData, adActions: each.value })}
                                            >
                                                {each.label}
                                            </DropdownItem>)
                                    })
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </FormGroup>

                    {
                        modalData.adActions === "navigateToRoute" ?
                            <>
                                <Label> Route Name </Label>
                                <Input
                                    style={{ marginBottom: 20 }}
                                    value={modalData.routeName || ''}
                                    name='routeName'
                                    disabled={!adType || adType === 'selectAdType'}
                                    placeholder={"Enter Route Name"}
                                    onChange={handleInputChange}
                                />
                            </>
                                : modalData.adActions === "openUrl" ?
                                    <>
                                        <Label> URL </Label>
                                        <Input
                                            style={{ marginBottom: 20 }}
                                            value={modalData.webViewUrl || ''}
                                            name='webViewUrl'
                                            placeholder={"Enter URL"}
                                            onChange={handleInputChange}
                                        />
                                    </>
                                        : null

                    }

                    <FormGroup>
                        <Label>Target Users</Label>
                        <Input
                            type="select"
                            name="targetUsers"
                            id="targetUsers"
                            value={modalData.targetUsers || ''}
                            onChange={handleInputChange}                       
                        >
                            <option value="">Select target users</option>
                            <option value="allUsers">All Users</option>
                            <option value="freeUsers">Free Users</option>
                            <option value="premiumUsers">Premium Users</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label>User Preparing Post</Label>
                        <Input
                            type="select"
                            name="userPreparingPost"
                            id="userPreparingPost"
                            value={modalData.userPreparingPost || ''}
                            onChange={handleInputChange}
                        >
                            {
                                [
                                    { _id: 'all', name: "All Posts" },
                                    ...posts,
                                ].map(each => (
                                    <option
                                        value={each._id}
                                        key={each._id}
                                    >
                                        {each.name}
                                    </option>
                                ))
                            }
                        </Input>
                    </FormGroup>

                    <Label> Firebase Event Name </Label>
                    <Input
                        style={{ marginBottom: 20 }}
                        value={modalData.eventName || ''}
                        name='eventName'
                        disabled={!adType || adType === 'selectAdType'}
                        placeholder={"Enter Firebase Event Name"}
                        onChange={handleInputChange}
                    />

                    <Label> Button Text </Label>
                    <Input
                        style={{ marginBottom: 20 }}
                        value={modalData.btnText || ''}
                        name='btnText'
                        disabled={!adType || adType === 'selectAdType'}
                        placeholder={"Enter Button Text"}
                        onChange={handleInputChange}
                    />

                    <Label> SubTitle </Label>
                    <Input
                        style={{ marginBottom: 20 }}
                        value={modalData.adSubtitle || ''}
                        name='adSubtitle'
                        disabled={!adType || adType === 'selectAdType'}
                        placeholder={"Enter SubTitle"}
                        onChange={handleInputChange}
                    />

                    <Label>ഒരു ദിവസം ഒരു യൂസർക്ക് എത്ര തവണ ഈ പരസ്യം കാണിക്കണം?</Label>
                    <Input
                        style={{ marginBottom: 20 }}
                        value={modalData.displayCount || ''}
                        name='displayCount'
                        placeholder={"Enter displayCount"}
                        onChange={handleInputChange}
                    />

                </ModalBody>
                <ModalFooter>
                    {
                        (adType !== 'selectAdType') &&

                        <Button
                            color='primary'
                            onClick={() => {
                                unstable_batchedUpdates(() => {
                                    setShowModal(false)
                                    setIsLoading(true)
                                    submitAd()
                                })
                            }}
                        >
                            Submit
                        </Button>
                    }
                    <Button
                        onClick={() => {
                            unstable_batchedUpdates(() => {
                                setShowModal(false)
                                setModalData({})
                                setIsLoading(false)
                                setAdType('')
                                setCurrentIndex(null)
                            })

                            getAdData()
                        }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </Modal>
        )
    }

    return (
        <Container fluid>

            {isLoading && <Loader />}
            {showModal && openAddOrEditModal()}

            <Row style={{ flex: 1, display: "flex", justifyContent: 'space-between' }}>
                <h3 style={{ margin: 20 }}>
                    Manage Ads
                </h3>
                <div style={{ margin: 20 }}>
                    <Button
                        color='primary'
                        outline
                        style={{ marginRight: 20 }}
                        onClick={() => setShowModal(true)}
                    >
                        + Create New Ad
                    </Button>
                </div>
            </Row>
            <Row>
                <Table hover style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 60}}>No</th>
                            <th style={{ width: 100 }}>Type</th>
                            <th>Video/Image Id</th>
                            <th>Action</th>
                            <th>Action Value</th>
                            <th>Firebase Event Name</th>
                            <th>Button Text</th>
                            <th>Subtitle</th>
                            <th>Display Count</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array.isArray(adData) && adData.length > 0 && adData.map((each, index) => {

                                return (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td >{each.imageId ? 'Image Ad' : 'Video Ad'}</td>
                                        <td>{each.imageId ? each.imageId : each.videoId}</td>
                                        <td>{each.adActions}</td>
                                        <td>{each.webViewUrl ? each.webViewUrl : each.routeName ? each.routeName : 'TRUE'}</td>
                                        <td>{each.eventName}</td>
                                        <td style={{ color: each.btnText ? 'black' : 'grey' }}>{each.btnText ? each.btnText : 'nill'}</td>
                                        <td style={{ color: each.adSubtitle ? 'black' : 'grey' }}>{each.adSubtitle ? each.adSubtitle : 'nill'}</td>
                                        <td>{each.displayCount}</td>
                                        <td>
                                            <Button
                                                color='primary'
                                                outline
                                                onClick={() => {

                                                    if (each.videoId) {
                                                        unstable_batchedUpdates(() => {
                                                            setAdType('videoAd')
                                                            setVideoId(each.videoId)
                                                        })
                                                    }
                                                    else {
                                                        unstable_batchedUpdates(() => {
                                                            setAdType('imageAd')
                                                            setImageId(each.imageId)
                                                        })
                                                    }

                                                    unstable_batchedUpdates(() => {
                                                        setShowModal(true)
                                                        setModalData(cloneDeep(each))
                                                        setCurrentIndex(index)
                                                    })
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                outline
                                                color='danger'
                                                style={{marginLeft: 10}}
                                                onClick={() => {
                                                    deleteAdData(each._id)
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Row>

        </Container>
    )
}

export default ManageVideoImageAds;