import React, { useState, useEffect } from 'react'
import {
    Container, Table, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import Axios from 'axios'
import moment from 'moment'

import Loader from '../Components/Loader'
import { commafy, dropDownPeriods } from '../utils'

function LeadsConversionStats() {

    const [isLoading, setIsLoading] = useState(true)
    const [leadsData, setLeadsData] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    const [ periodLabel, setPeriodLabel ] = useState("This Month")
    const [ periodValue, setPeriodValue ] = useState("thisMonth")

    const getLeadConversionStats = (periodValue) => {
        setIsLoading(true)
        Axios.get('/leads-conversion', { params: { period: periodValue } })
        .then(res => {
            setIsLoading(false)
            setLeadsData(res.data)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            alert("Serve error. Check console")
        })
    }

    useEffect(() => {
        getLeadConversionStats(periodValue)
    }, [periodValue])

    return (
        <>

            {isLoading && <Loader />}

            <Container fluid style={{ marginLeft: 10, marginRight: 10 }}>

                <Row>
                    <Dropdown
                        isOpen={isOpen}
                        toggle={() => setIsOpen(!isOpen)}
                        style={{ marginTop: 20, marginBottom: 20 }}
                    >
                        <DropdownToggle caret>{periodLabel}</DropdownToggle>
                        <DropdownMenu>
                            {
                                dropDownPeriods.slice(0,-1).map((each) => {
                                    return (
                                        <DropdownItem
                                            key={each.value}
                                            onClick={() => {
                                                setPeriodLabel(each.label)
                                                setPeriodValue(each.value)
                                            }}
                                        >
                                            {each.label}
                                        </DropdownItem>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </Dropdown>
                </Row>

                <Row>
                    {
                        Array.isArray(leadsData) && leadsData.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Assigning Date</th>
                                        <th>Remark</th>
                                        <th>Games</th>
                                        <th>Marathon</th>
                                        <th>Last Active</th>
                                        <th>Joined After</th>
                                        <th>Quiz</th>
                                        <th>Exams</th>
                                        <th>Daily Exams</th>
                                        <th>Topic Exams</th>
                                        <th>CA Quiz</th>
                                        <th>Batch Modules</th>
                                        <th>Learn Modules</th>

                                        <th>Premium Ending</th>
                                        <th>OS</th>

                                        <th>Leads</th>
                                        <th>Purchases</th>
                                        <th>Revenue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        leadsData.map(each => {
                                            return (
                                                <tr key={each._id}>
                                                    <td>{moment(each.assigningDate).format("D MMM YYYY")}</td>
                                                    <td>{each.remark}</td>
                                                    <td>{each.totalGames ? each.totalGames : "-"} </td>
                                                    <td>{each.marathonsPlayed ? each.marathonsPlayed : "-"} </td>
                                                    <td>{each.lastActiveWithin ? `${each.lastActiveWithin} Days` : "-"} </td>
                                                    <td>{each.joinedAfter ? moment(each.joinedAfter).format("D MMM YYYY")  : "-"} </td>
                                                    <td>{each.quizAttended ? each.quizAttended : "-"} </td>
                                                    <td>{each.totalExamsAttended ? each.totalExamsAttended : "-"} </td>
                                                    <td>{each.dailyExamsAttended ? each.dailyExamsAttended : "-"} </td>
                                                    <td>{each.topicExamsAttended ? each.topicExamsAttended : "-"} </td>
                                                    <td>{each.dailyChallengesSubmitted ? each.dailyChallengesSubmitted : "-"} </td>
                                                    <td>{each.completedBatchModulesCount ? each.completedBatchModulesCount : "-"} </td>
                                                    <td>{each.completedLearnModulesCount ? each.completedLearnModulesCount : "-"} </td>
                                                    <td>
                                                        {`
                                                            ${each.premiumEndingDateFrom ? `From ${moment(each.premiumEndingDateFrom).format('DD MMM YYYY')}` : ""}
                                                            ${each.premiumEndingDateTo ? `To ${moment(each.premiumEndingDateTo).format('DD MMM YYYY')}` : ""}
                                                        `}
                                                    </td>
                                                    <td>{each.selectedOs ? each.selectedOs : "-"} </td>
                                                    <td>{each.totalLeads ? each.totalLeads : "-"} </td>
                                                    <td>{each.conversions} </td>
                                                    <td>₹{commafy(each.totalRevenue)} </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            Array.isArray(leadsData) && leadsData.length === 0 ?
                                <p style={{ textAlign:'center', marginTop: 50 }}>No data found</p>
                                :
                                null
                    }
                </Row>
            </Container>

        </>
    )
}

export default LeadsConversionStats
