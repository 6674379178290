import React, { Component } from 'react'
import { Container, Row, Col, Button, Table, Input, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import Axios from 'axios'
import { connect } from 'react-redux'
import queryString from 'query-string'

import Loader from '../Components/Loader'
class UserLearningPath extends Component {

    state = {
        isLoading: false,
        showNewUnitModal: true,
        userResult: null,
        showResult: true,
        userName: null,
    }

    componentDidMount() {
        const { phoneNumber, postUnitsToFetchId } = queryString.parse(this.props.location.search)
        if (phoneNumber && postUnitsToFetchId) {
            this.submitButton()
        }
    }

    submitButton() {

        const { phoneNumber, postUnitsToFetchId } = queryString.parse(this.props.location.search)

        if (!phoneNumber || phoneNumber.length !== 10) {
            alert("Mobile should be number exactly 10 digits")
        } else if (!postUnitsToFetchId) {
            alert("Please select a valid crash course pack")
        } else {
            this.getResult(postUnitsToFetchId, phoneNumber)
        }

    }

    getResult(postUnitsToFetchId, mobile) {
        this.setState({ isLoading: true }, () => {
            Axios.get("/user-learning-percentage", { params: { prelimsPathId: postUnitsToFetchId, mobile: mobile } })
                .then(res => {
                    if (res.data.topicLearningPercentage) {
                        this.setState({
                            userResult: res.data.topicLearningPercentage,
                            userName: res.data.name,
                            isLoading: false,
                            showResult: true
                        })
                    } else {
                        this.setState({ userResult: res.data.messagge, isLoading: false, showResult: false })
                    }
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    if (err && err.response && err.response.data && err.response.data.message) {
                        alert(err.response.data.message)
                    } else {
                        console.log(err)
                        alert("Error, check console")
                    }
                })
        })
    }

    updateQueryParams = (newQueryStringsObject) => {

        const queryStringObj = queryString.parse(this.props.location.search)
        Object.keys(newQueryStringsObject).forEach(eachKey => queryStringObj[eachKey] = newQueryStringsObject[eachKey])

        let newQueryString = ""

        Object.keys(queryStringObj).forEach(eachKey => {
            newQueryString += `${eachKey}=${queryStringObj[eachKey]}&`
        })

        this.props.history.push({
            pathname: "/user-learning-path",
            search: newQueryString
        })

    }

    render() {

        const { postUnitsToFetchId, postUnitsToFetchName, phoneNumber } = queryString.parse(this.props.location.search)

        return (
            <>
                {this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}

                <Container style={{ paddingTop: 50 }}>

                    <Row>
                        <Col md="3">
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="primary" block>
                                    {(postUnitsToFetchId && postUnitsToFetchName) ? postUnitsToFetchName : "Select Post"}
                                </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {
                                        this.props.packs.map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each._id}
                                                    value={each.title}
                                                    onClick={() => {
                                                        this.updateQueryParams({
                                                            postUnitsToFetchId: each.postId,
                                                            postUnitsToFetchName: each.title
                                                        })
                                                    }}
                                                >
                                                    {each.title}
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col md="6">
                            <Input
                                placeholder="Enter user phone number"
                                type='number'
                                name='phoneNumber'
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.submitButton()
                                    }
                                }}
                                value={phoneNumber}
                                onChange={(e) => this.updateQueryParams({ phoneNumber: e.target.value })}
                            />
                        </Col>
                        <Col md="3">
                            <Button
                                outline
                                color="primary"
                                disabled={!phoneNumber || !postUnitsToFetchId}
                                onClick={() => {
                                    this.submitButton()
                                }}>
                                Submit
                            </Button>
                        </Col>
                    </Row>

                    {
                        Array.isArray(this.state.userResult) && this.state.userResult.length > 0 ?
                            <>
                                <h6 style={{ marginTop: 25, marginBottom: 25 }}>Name: {this.state.userName}</h6>
                                <Row>
                                    {this.state.userResult.map((item, index) => {
                                        return (
                                            <Col md="12" key={index}>
                                                <p key={item._id}>{item.unitName}</p>
                                                <Table >
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: '10%' }}>No</th>
                                                            <th style={{ width: '60%' }}>TopicName</th>
                                                            <th style={{ width: '30%' }}>Questions Completed</th>
                                                            <th style={{ width: '30%' }}>Modules Completed</th>
                                                        </tr>
                                                        {
                                                            item.topicDetails.map((each, position) => {
                                                                return (
                                                                    <tr key={position}>
                                                                        <td>{position + 1}</td>
                                                                        <td>{each.topicName}</td>
                                                                        <td>{each.percentage.toFixed(2)}% ({each.correctCount}/{each.topicQuestionsCount})</td>
                                                                        <td>{each.attendedModulesCount || 0}/{each.totalModulesCount || 0}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        )
                                    })
                                    }
                                </Row>
                            </>
                            :
                            Array.isArray(this.state.userResult) && this.state.userResult.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                    <h6>User have not attened this crash course yet</h6>
                                </div>
                                :
                                null
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        packs: state.data.packs,
    }
}

export default connect(mapStateToProps)(UserLearningPath)
