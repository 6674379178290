import React from 'react'
import { Container, Row, Col, Table, Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import CheckBox from 'material-ui/Checkbox';
import { isFinite, reverse } from 'lodash'
import { connect } from 'react-redux' 
import Loader from '../Components/Loader'
import { isValidObjectId } from '../utils';

const PREMIUM_OFFER = "premiumOffer"

class OfferList extends React.Component {

    state = {
        isLoading: true,
        showEnterAllDataAlert: false,
        showCreateOfferModal: false,
        offerTitle: '',
        offerDescription: '',
        planValidity: null,
        offerDuration: null,
        maximumPrice: null,
        offerPrice: null,
        buttonText: 'Buy Now',
        premiumFlag: false,
        offerList: null,
        courseGroupId: PREMIUM_OFFER
    }

    getAllOfferList = () => {
        Axios.get('/offer')
            .then(res => {
                this.setState({ offerList: reverse(res.data.offers), isLoading: false })
            })
            .catch(err => {
                this.setState({ isLoading: false })
                console.log(err)
                alert("Error, please check console")
            })
    }

    deleteOffer = (offerId, status) => {
        Axios.delete('/offer', { params: { offerId, status } })
            .then(res => {
                this.setState({ isLoading: false })
                this.getAllOfferList()
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
            })
    }

    submitEditOffer = () => {

        const { offerId, offerTitle, offerDescription, planValidity, offerDuration, offerPrice, maximumPrice, buttonText, courseGroupId, premiumFlag } = this.state

        let putObject = {
            offerId,
            offerValidUntilDays: offerDuration,
            planValidity: planValidity,
            offerTitle,
            offerDescription,
            maximumPrice,
            discountedPrice: offerPrice,
            btnText: buttonText,
            isForPremiumUser: premiumFlag
        }

        if (isValidObjectId(courseGroupId)) {
            putObject.courseGroupId = courseGroupId
        } else {
            putObject.courseGroupId = null
        }

        if (offerId && isFinite(parseInt(offerDuration)) && planValidity && offerTitle && offerDescription && maximumPrice && offerPrice) {
            Axios.post('/offer', putObject)
                .then(res => {
                    this.setState({
                        showCreateOfferModal: false,
                        showEnterAllDataAlert: false,
                        isLoading: false })
                    this.getAllOfferList()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                })
        } else {
            this.setState({ showEnterAllDataAlert: true, isLoading: false })
        }
    }

    submitNewOffer = () => {

        const { offerTitle, offerDescription, planValidity, offerDuration, offerPrice, maximumPrice, courseGroupId, buttonText, premiumFlag } = this.state

        let putObject = {
            offerValidUntilDays: offerDuration,
            planValidity: planValidity,
            offerTitle,
            offerDescription,
            maximumPrice,
            discountedPrice: offerPrice,
            btnText: buttonText,
            isForPremiumUser: premiumFlag
        }

        if (isValidObjectId(courseGroupId)) {
            putObject.courseGroupId = courseGroupId
        }
        
        if (isFinite(parseInt(offerDuration)) && planValidity && offerTitle && offerDescription && maximumPrice && offerPrice) {
            Axios.put('/offer', putObject)
                .then(res => {
                    this.setState({ showCreateOfferModal: false, showEnterAllDataAlert: false, isLoading: false })
                    this.getAllOfferList()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                })
        } else { 
            this.setState({ showEnterAllDataAlert: true, isLoading: false })
        }
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    componentDidMount() {
        this.getAllOfferList()
    }

    render() {
        return (
            <>
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}
                <Container fluid style={{ padding: 50 }}>

                    <Modal isOpen={this.state.showCreateOfferModal} size="lg">
                        <ModalHeader>
                            Enter Offer Details
                        </ModalHeader>

                        <ModalBody>

                            <Label>Titile</Label>
                            <Input
                                placeholder="Enter Offer Titile"
                                type="text"
                                name="offerTitle"
                                value={this.state.offerTitle}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Label>Description</Label>
                            <Input
                                placeholder="Enter Offer Description"
                                type="textarea"
                                name="offerDescription"
                                value={this.state.offerDescription}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 30, paddingBottom: 40 }}
                            />

                            <Row>
                                <Col>

                                    <Label>Plan Duration (Years)</Label>
                                    <Input
                                        placeholder="Enter Plan Duration"
                                        type="text"
                                        name="planValidity"
                                        value={this.state.planValidity || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                                <Col>

                                    <Label>Offer Duration (Days)</Label>
                                    <Input
                                        placeholder="Enter Number of days offer to show"
                                        type="number"
                                        name="offerDuration"
                                        value={this.state.offerDuration || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 30 }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Label>Maximum Price</Label>
                                    <Input
                                        placeholder="Enter Plan Maximum Price"
                                        type="number"
                                        name="maximumPrice"
                                        value={this.state.maximumPrice || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>

                                <Col>
                                    <Label>Offer Price</Label>
                                    <Input
                                        placeholder="Enter Offer Price"
                                        type="number"
                                        name="offerPrice"
                                        value={this.state.offerPrice || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 30 }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <SelectField
                                        fullWidth={true}
                                        value={this.state.courseGroupId}
                                        floatingLabelText="Select Course Group (Optional)"
                                        onChange={(event, third, value) => this.setState({ courseGroupId: value })}
                                    >
                                        {
                                            [
                                                { _id: PREMIUM_OFFER, name: "Premium Offer" },
                                                ...this.props.courseGroups
                                            ].map(item => (
                                                <MenuItem
                                                    key={item._id}
                                                    value={item._id}
                                                    primaryText={item.name}
                                                />
                                            ))
                                        }
                                    </SelectField>
                                </Col>

                                <Col>
                                    <Label>Button Text</Label>
                                    <Input
                                        placeholder="Enter Button Text"
                                        type="text"
                                        name="buttonText"
                                        value={this.state.buttonText || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 30 }}
                                    />
                                </Col>
                            </Row>

                            <CheckBox
                                label={'Premium User Offer'}
                                style={{ marginTop: '20px' }}
                                checked={this.state.premiumFlag}
                                onClick={() => this.setState({ premiumFlag: !this.state.premiumFlag })}
                            />

                            <Alert isOpen={this.state.showEnterAllDataAlert} color="danger">Enter all data before submitting</Alert>

                        </ModalBody>

                        <ModalFooter>

                            <Button
                                color="primary"
                                onClick={
                                    () => {
                                        if (this.state.offerId) {
                                            this.submitEditOffer(this.state.offerId)
                                            this.setState({ isLoading: true })
                                        } else {
                                            this.submitNewOffer()
                                            this.setState({ isLoading: true })
                                        }
                                    }}
                            >
                                Submit
                            </Button>

                            <Button
                                outline
                                onClick={() => this.setState({ showCreateOfferModal: false, showEnterAllDataAlert: false, isLoading: false })}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>


                    </Modal>

                    <Row style={{ marginBottom: 40 }}>
                        <Col md='8'>
                            <h2>Offer List</h2>
                        </Col>
                        <Col md="4" style={{ flex: 1, justifyContent: 'flex-end' }}>
                            <Button
                                block
                                outline
                                color='primary'
                                onClick={() => this.setState({
                                    offerId: null,
                                    offerTitle: '',
                                    offerDescription: '',
                                    planValidity: null,
                                    offerDuration: null,
                                    maximumPrice: null,
                                    offerPrice: null,
                                    buttonText: 'Buy Now',
                                    premiumFlag: false,
                                    showCreateOfferModal: true,
                                    courseGroupId: PREMIUM_OFFER,
                                    isLoading: true
                                })}
                            >
                                + Create New offer
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        {this.state.offerList && this.state.offerList.length > 0 ?
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Plan Duration</th>
                                            <th>Offer Valid Until</th>
                                            <th>Max Price</th>
                                            <th>Offer Price</th>
                                            <th>Btn Text</th>
                                            <th>For Premium Users</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.offerList.map((item, index) => {
                                                return (
                                                    <tr key={item._id} style={{ background: item.activeFlag ? 'white' : 'rgba(255,0,0,0.1)' }}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.offerTitle}</td>
                                                        <td>{item.offerDescription}</td>
                                                        <td>{item.planValidity}</td>
                                                        <td>{item.offerValidUntilFormattedDate}</td>
                                                        <td>{item.maximumPrice}</td>
                                                        <td>{item.discountedPrice}</td>
                                                        <td>{item.btnText}</td>
                                                        <td>{`${item.isForPremiumUser}`}</td>
                                                        <td>
                                                            <FontAwesomeIcon
                                                                onClick={() =>
                                                                    this.setState({
                                                                        offerId: item._id,
                                                                        offerTitle: item.offerTitle,
                                                                        offerDescription: item.offerDescription,
                                                                        planValidity: item.planValidity,
                                                                        offerDuration: `${item.offerValidUntilDays}`,
                                                                        maximumPrice: item.maximumPrice,
                                                                        offerPrice: item.discountedPrice,
                                                                        buttonText: item.btnText,
                                                                        premiumFlag: item.isForPremiumUser,
                                                                        courseGroupId: item.courseGroupId,
                                                                        showCreateOfferModal: true,
                                                                    })
                                                                }
                                                                className="iconButton"
                                                                style={{ color: '#44a6c6', marginTop: 10, marginRight: 10 }}
                                                                icon={faPen}
                                                            />

                                                            <FontAwesomeIcon
                                                                onClick={() => {
                                                                    (window.confirm(`${item.activeFlag ? 'Delete' : 'UnDelete'} the Offer?`)) &&
                                                                        this.deleteOffer(item._id, item.activeFlag)
                                                                }}
                                                                className="iconButton"
                                                                style={{ color: '#CA0B00', marginTop: 10, marginLeft: 10 }}
                                                                icon={faTrash}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                            :
                            Array.isArray(this.state.offerList) && this.state.offerList.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <h4 style={{ textAlign: 'center', marginTop: 50 }}>No offers created</h4>
                                </div>
                                :
                            null
                        }
                    </Row>
                </Container>
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        courseGroups: state.data.courseGroups
    }
}

export default connect(mapStateToProps)(OfferList)