import React, { useState } from 'react'
import { cloneDeep } from 'lodash';
import { copyToClipboard } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'


import {
    Col, Row, Container, Table, Button, Input
} from 'reactstrap';

function EditCurrentAffairsNotes({ moduleData, updateModuleData, history, updateModuleDataAndSave }) {

    const [newNoteIds, setNewNoteIds] = useState('');

    return (
        <Container fluid>

            <Row style={{ marginBottom: 20 }}>
                <Col md="6">

                    <Input
                        name="noteIds"
                        id="noteIds"
                        type="textarea"
                        rows="3"
                        value={newNoteIds}
                        placeholder="Enter Note Ids"
                        onChange={(e) => setNewNoteIds(e.target.value)}
                    />

                    <Button
                        color="primary"
                        block
                        style={{ marginTop: 10 }}
                        onClick={() => {

                            if (newNoteIds) {

                                const noteIdsSplitted = newNoteIds.split("\n")
                                const allItems = noteIdsSplitted.map(each => each.replace(/\s/g, ''))
                                const noteIds = allItems.filter(each => each)

                                const editingModuleData = cloneDeep(moduleData)
                                
                                if (Array.isArray(editingModuleData.content.notesId)) {
                                    editingModuleData.content.notesId.push(...noteIds)
                                } else {
                                    editingModuleData.content.notesId = noteIds
                                }

                                updateModuleDataAndSave(editingModuleData)
                                setNewNoteIds('')

                            } else {
                                alert("Enter a valid question Ids")
                            }

                        }}
                    >Add Note</Button>
                </Col>
            </Row>

            {
                moduleData && moduleData.content && Array.isArray(moduleData.content.slides) ?
                    <Table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Note Title</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                moduleData.content.slides.map((each, index) => (
                                    <tr key={each._id}>
                                        <td>{index + 1}</td>
                                        <td>{each.title}</td>
                                        <td>
                                            <Link
                                                to={`/daily-challenges?noteId=${each._id}`}
                                                target="_blank"
                                            >

                                            <Button
                                                outline
                                                color= "primary"
                                            >
                                                Edit
                                            </Button>
                                            </Link>
                                            <Button
                                                onClick={() => {
                                                    copyToClipboard(each._id)
                                                    toast("ID copied", {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true
                                                        })
                                                }}
                                                outline
                                                style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
                                            >
                                                <FontAwesomeIcon
                                                    className="iconButton"
                                                    style={{ color: 'grey' }}
                                                    icon={faCopy}
                                                />
                                            </Button>
                                            <Button
                                                outline
                                                color="danger"
                                                onClick={() => {
                                                    let editingModuleData = cloneDeep(moduleData)
                                                    const notesIdsArray = editingModuleData.content.notesId.filter(e => e !== each._id)
                                                    editingModuleData.content.notesId = notesIdsArray
                                                    // updateModuleData(editingModuleData)
                                                    updateModuleDataAndSave(editingModuleData)

                                                }}
                                            >Delete</Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    : <p>No notes found</p>
            }
        </Container>
    )
}

export default EditCurrentAffairsNotes