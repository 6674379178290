import React, { Component } from 'react'
import axios from 'axios'
import {
  Container, Col, Table, Row, Button,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import { DatePicker } from 'material-ui'
import Loader from '../Components/Loader'
import { commafy, dropDownPeriods } from '../utils'

class SalesmenReport extends Component {

  state = {
    isLoading: true,
    data: [],
    fromDate: null,
    toDate: null,
    totalSalesCount: 0,
    totalSalesIncome: 0,
    periodLabel: "Today",
    period: "today",
    showCustomTime: false
  }

  getStats() {

    let params = {}

    const { fromDate, toDate, period } = this.state

    if (fromDate && toDate) {
      params.period = { fromDate, toDate }
    } else {
      params.period = period
    }

    axios.get('/salesman-reports', { params })
      .then(res => {

        const { salesData } = res.data

        let totalPremiumCount = 0
        let totalPremiumRevenue = 0
        let totalPackCount = 0
        let totalPackRevenue = 0
        let totalGoldCount = 0
        let totalGoldRevenue = 0

        salesData.forEach((each) => {
          totalPremiumCount += (each.premiumCount || 0)
          totalPremiumRevenue += (each.premiumRevenue || 0)
          totalPackCount += (each.packCount || 0)
          totalPackRevenue += (each.packRevenue || 0)
          totalGoldCount += (each.goldCount || 0)
          totalGoldRevenue += (each.goldRevenue || 0)
        })

        this.setState({
          data: salesData,
          totalPremiumCount,
          totalPremiumRevenue,
          totalPackCount,
          totalPackRevenue,
          totalGoldCount,
          totalGoldRevenue,
          isLoading: false
        })

      })
      .catch(err => {
        console.log(err)
        alert("Error. Check console")
      })

  }

  componentDidMount() {
    this.getStats()
  }

  render() {
    return (
      <Container fluid>

        {this.state.isLoading && (
          <Loader customStyle={{ background: "red" }} />
        )}

        <Row style={{ display: 'flex', flexDirection: 'row', margin: '5rem', marginTop: 20, marginBlock: 10, alignItems:'center' }}>
            <Dropdown 
              isOpen={this.state.isOpen}
              style={{ margin: 10, marginLeft: 0 }}
              toggle={() => this.setState({ isOpen: !this.state.isOpen })}
            >
              <DropdownToggle caret>{this.state.periodLabel}</DropdownToggle>
              <DropdownMenu>
                {
                  dropDownPeriods.map((each) => {
                    return (
                      <DropdownItem
                        key={each.value}
                        onClick={() => this.setState({ period: each.value, isOpen: false, periodLabel: each.label, data: null, isLoading: true, fromDate: null, toDate: null },
                          () => {
                            each.value !== "customTime" ?
                              this.getStats()
                              : this.setState({ showCustomTime: true, isLoading: false })
                          }
                        )}
                      >
                        {each.label}
                      </DropdownItem>
                    )
                  })
                }
              </DropdownMenu>
            </Dropdown>
        </Row>

          {this.state.period === "customTime" && this.state.showCustomTime ?
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                marginBlock: 10,
              }}
            >
              <DatePicker
                hintText="From Date"
                container="inline"
                value={this.state.fromDate}
                onChange={(e, date) => this.setState({ fromDate: date })}
              />

              <DatePicker
                hintText="To Date"
                container="inline"
                value={this.state.toDate}
                onChange={(e, date) => this.setState({ toDate: date })}
              />

              <Button
                outline
                color="primary"
                style={{ margin: 10 }}
                disabled={!this.state.fromDate || !this.state.toDate}
                onClick={() =>
                  this.setState({ isLoading: true }, () => this.getStats())
                }
              >
                GET
                  </Button>

              {this.state.fromDate && this.state.toDate ? (
                <Button
                  outline
                  color="danger"
                  style={{ margin: 10 }}
                  disabled={!this.state.fromDate || !this.state.toDate}
                  onClick={
                    () => this.setState({ fromDate: null, toDate: null, isLoading: true },
                      () => this.getStats()
                    )
                  }
                >
                  CLEAR DATE FILTER
                </Button>
              ) : null}
            </Row>
            : null
          }

          <Row>
            <Col>
              {this.state.fromDate && this.state.toDate ? (
                <p>
                  Showing results from{" "}
                  {new Date(this.state.fromDate).toLocaleDateString()} to{" "}
                  {new Date(this.state.toDate).toLocaleDateString()}
                </p>
              ) : null}
              {this.state.data && this.state.data.length > 0 ? (
                <Table hover>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      
                      <th>Premium</th>
                      
                      <th>Silver</th>

                      <th>Gold</th>

                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((each, index) => {
                      return (
                        <tr key={each._id}>
                          <td>{index + 1}</td>
                          <td>
                            {each.salesman.name}
                            <br />
                            <span style={{ fontSize: 12 }}>{each.salesman.email}</span>
                          </td>

                          <td>{`₹${commafy(each.premiumRevenue || 0)} (${each.premiumCount || 0})`}</td>
                          <td>{`₹${commafy(each.packRevenue || 0)} (${each.packCount || 0})`}</td>
                          <td>{`₹${commafy(each.goldRevenue || 0)} (${each.goldCount || 0})`}</td>
                          <td>{`₹${commafy((each.premiumRevenue || 0) + (each.packRevenue || 0) + (each.goldRevenue || 0))} (${(each.premiumCount || 0) + (each.packCount || 0) + (each.goldCount || 0)})`}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <th>Total</th>
                      <th></th>
                      <th>{`₹${commafy(this.state.totalPremiumRevenue || 0)} (${this.state.totalPremiumCount || 0})`}</th>
                      <th>{`₹${commafy(this.state.totalPackRevenue || 0)} (${this.state.totalPackCount || 0})`}</th>
                      <th>{`₹${commafy(this.state.totalGoldRevenue || 0)} (${this.state.totalGoldCount || 0})`}</th>
                      <th>{`₹${commafy(this.state.totalPremiumRevenue + this.state.totalPackRevenue + this.state.totalGoldRevenue)} (${this.state.totalPremiumCount + this.state.totalPackCount + this.state.totalGoldCount})`}</th>
                    </tr>
                  </tbody>
                </Table>
              ) : Array.isArray(this.state.data) &&
                this.state.data.length === 0 ? (
                <Row style={{ marginTop: 50, textAlign: "center" }}>
                  <Col>
                    <h4>No salesmen reports...</h4>
                  </Col>
                </Row>
              )
                : null}
            </Col>
          </Row>
      </Container>
    );
  }
}

export default SalesmenReport
