import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import {
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
    Container, Col, Row, Button, ListGroup, ListGroupItem, Spinner
} from 'reactstrap';
import Loader from '../Components/Loader';

class SearchHistory extends Component {

    state = {
        searchBoxType: '',
        searchHistoryList: null,
        usersDetails: [],
        pageNumber: 0,
        period: "today",
        periodLabel: "Today",

        fromDate: null,
        toDate: null,
        searchHistoryCount: 0,
        skipCount: 0,
        isLoading: true,
        userDetailsIndex: null
    }

    getSearchHistory() {
        const { skipCount, fromDate, toDate, period, searchBoxType } = this.state
        let params = { skip: skipCount }
        if (fromDate && toDate) {
            params.period = { fromDate, toDate }
        } else {
            params.period = period
        }
        if (this.state.searchBoxType) {
            params.searchBoxType = searchBoxType
        }

        axios.get('/search-history', { params })
            .then(res => {
                this.setState({
                    searchHistoryList: res.data.searchHistoryDetails,
                    searchHistoryCount: res.data.searchHistoryCount,
                    isLoading: false
                })
            })
            .catch((err) => {
                console.log(err)
                this.setState({ isLoading: false })
                alert("Error, Check console")
            })
    }

    renderSkipMenu = () => {

        let returnArray = []
        let itemCount = parseInt(this.state.searchHistoryCount, 10) / 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => {
                        this.setState({ pageNumber: index, skipCount: index * 50, isLoading: true },
                            () => this.getSearchHistory(this.state.issueType))
                    }
                    }>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    getUser = (userId, index) => {
        this.setState({ userDetailsIndex: index })
        axios.get(`/get-users-details`, { params: { userIds: [userId], requiredDetails: { name: 1, mobile: 1 } } })
            .then((response) => {
                this.setState({
                    usersDetails: response.data.usersDetails,
                    currentGetUserIndex: index,
                    userDetailsIndex: null
                })
            })
            .catch((error) => {
                console.log(error.response)
                alert("Error. Please check console")
                this.setState({ userDetailsIndex: null })
            })
    }

    componentDidMount() {
        this.getSearchHistory()
    }

    render() {
        const searchBoxArray = [{ name: 'Practice', _id: 1 }, { name: 'Exam', _id: 2 }, { name: 'All', _id: 3 }]
        return (
            <>
                {this.state.isLoading && <Loader />}

                <Container>

                    <Row style={{ display: 'flex', minHeight: '7rem', alignContent: 'center' }}>
                        <Col>
                            <h1>Search History</h1>
                        </Col>
                        <Col style={{ maxWidth: '8rem' }}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="primary" block variant="success"> {this.state.searchBoxType ? this.state.searchBoxType : "All"} </DropdownToggle>

                                <DropdownMenu className="addScroll">
                                    {searchBoxArray.map((each, index) => {
                                        return (
                                            <DropdownItem
                                                key={each._id}
                                                onClick={() => (
                                                    this.setState({ searchBoxType: each.name,isLoading:true}, () => this.getSearchHistory())
                                                )}
                                            >
                                                {each.name}
                                            </DropdownItem>
                                        )
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col style={{ maxWidth: '8rem' }}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="primary" block variant="success" id="dropdown-basic"> Page {this.state.pageNumber + 1} </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {this.renderSkipMenu()}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {this.state.searchHistoryList && this.state.searchHistoryList.length > 0 ?
                                <ListGroup style={{ marginBottom: 60 }}>
                                    {this.state.searchHistoryList.map((each, index) => {
                                        return (
                                            <ListGroupItem key={each._id}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                                        <label>{each.searchText}</label>
                                                        {(this.state.usersDetails.length > 0 && this.state.currentGetUserIndex === index) &&
                                                            <label style={{ fontSize: 14, color: 'grey' }}>
                                                                name: {this.state.usersDetails[0].name}
                                                                <br />
                                                                mobile: {this.state.usersDetails[0].mobile}
                                                            </label>}
                                                    </div>

                                                    <label style={{ flex: 2 }}>{moment(each.date).format('D MMM YYYY')}</label>

                                                    { !(this.state.usersDetails.length > 0 && this.state.currentGetUserIndex === index) &&
                                                    <Button
                                                        style={{ flex: 1 }}
                                                        outline color='primary'
                                                        onClick={() => this.getUser(each.userId, index)}
                                                    >
                                                        {this.state.userDetailsIndex === index ? <Spinner /> : "User Details"}
                                                    </Button> }
                                                </div>
                                            </ListGroupItem>
                                        )
                                    })
                                    }
                                </ListGroup>
                                :
                                this.state.searchHistoryList && this.state.searchHistoryList.length === 0 ?
                                    <div style={{ marginTop: 100 }}>
                                        <h4 style={{ textAlign: 'center' }}>No items</h4>
                                    </div>
                                    :
                                    <div>
                                        <h4 style={{ textAlign: 'center' }}>Loading..</h4>
                                    </div>
                            }
                        </Col>
                    </Row>

                </Container>
            </>
        )
    }
}

export default SearchHistory
