import React, { useEffect, useState } from 'react'
import {
    Container,
    Nav, NavItem, NavLink,
} from 'reactstrap'
import { useSelector } from 'react-redux'

import SalesmenReport from './SalesmenReport'
import PremiumOrCourseSalesReport from './PremiumOrCourseSalesReport'
import { camelCaseToTitleCase } from '../utils'
import { isAdmin } from '../utils/dashboardRoles'

const allTabs = [
    "allSalesReport",
    "salesAgentsReport"
]

function SalesReportPage() {

    const [activeTab, setActiveTab] = useState(null)
    const [tabs, setTabs] = useState([])

    const roleIds = useSelector(state => state.auth.roleIds)

    useEffect(() => {
        if (isAdmin(roleIds)) {
            setTabs(allTabs)
            setActiveTab(allTabs[0])
        } else {
            setTabs([allTabs[1]])
            setActiveTab(allTabs[1])
        }
    }, [roleIds])

    const renderTabContent = () => {
        switch (activeTab) {
            case allTabs[0]:
                return <PremiumOrCourseSalesReport apiEndPoint={'sales-report'} />
            case allTabs[1]:
                return <SalesmenReport />
            default:
                return (
                    <p style={{ textAlign: 'center', marginTop: 100 }}>Please select a tab</p>
                )
        }
    }

    return (
        <Container fluid>
            <Nav tabs style={{ marginTop: 50 }}>
                {
                    tabs.map(tab => (
                        <NavItem key={tab}>
                            <NavLink
                                className={activeTab === tab ? 'active' : null}
                                onClick={() => setActiveTab(tab)}
                            >
                                {camelCaseToTitleCase(tab)}
                            </NavLink>
                        </NavItem>
                    ))
                }
            </Nav>

            { renderTabContent() }

        </Container>
    )
}

export default SalesReportPage
