import React from 'react'
import { Container, Row, Col, Table, Button, Badge, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, CustomInput, ListGroupItem, ListGroup } from 'reactstrap'
import Axios from 'axios'
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { connect } from 'react-redux'
import { reverse, cloneDeep, orderBy } from 'lodash'
import Loader from '../Components/Loader'
import { copyToClipboard, addOrRemoveItem } from '../utils'; 
import { updatePacks, updateCourseGroups } from '../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faCopy, faTrash, faReply, faPen } from '@fortawesome/free-solid-svg-icons'
import { isAdmin, isContentManagerOrHigher, isBatchManagerOrHigher } from '../utils/dashboardRoles';
import { toast } from 'react-toastify'

const SortableItem = SortableElement(({ eachPack, roleIds, history, listIndex, updateAccess, editPack, deletePack, isSortingEnabled }) => {
    return (
        <tr
            key={eachPack._id}
            style={{
                backgroundColor: eachPack.hasExpired ? 'rgba(255,255,0,0.3)' :
                    eachPack.activeFlag ? 'white' :
                        'rgba(255,0,0,0.1)'
            }}
        >
            <td>{listIndex + 1}</td>
            <td>{eachPack.title}</td>
            <td>{eachPack.subtitle}</td>
            <td>{eachPack.purpose}</td>
            <td>{eachPack.crashType}</td>
            <td>
                {!isSortingEnabled ?
                    <>
                        <Button
                            outline
                            color="secondary"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                copyToClipboard(eachPack._id)
                                toast("ID copied", {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true
                                    })
                            }}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>

                        {
                            isAdmin(roleIds) ?
                                <>
                                    <Button
                                        outline
                                        color='primary'
                                        style={{ marginRight: 10 }}
                                        onClick={() => editPack(eachPack)}
                                    >
                                        <FontAwesomeIcon icon={faPen} />
                                    </Button>
                                </>
                                : null
                        }
                        
                        {
                            (isAdmin(roleIds) || isContentManagerOrHigher(roleIds)) &&
                            <Button
                                outline
                                color='primary'
                                style={{ marginRight: 10 }}
                                onClick={() => updateAccess(eachPack)}
                            >
                                Access Control
                            </Button>
                        }

                        {
                            (isContentManagerOrHigher(roleIds) || isBatchManagerOrHigher(roleIds)) &&
                            <Button
                                outline
                                color="primary"
                                style={{ marginRight: 10 }}
                                onClick={() => history.push({
                                    pathname: `/batch-study-groups`,
                                    search: `productId=${eachPack._id}&packName=${eachPack.title}`
                                })}
                            >
                                Study Groups
                            </Button>
                        }

                        <Button
                            outline
                            color='danger'
                            onClick={() => {
                                (window.confirm(`${eachPack.activeFlag ? 'Delete' : 'UnDelete'} the Pack?`)) &&
                                    deletePack(eachPack._id, eachPack.activeFlag)
                            }}
                        >
                            <FontAwesomeIcon icon={eachPack.activeFlag ? faTrash : faReply} />
                        </Button>
                    </>
                    : <FontAwesomeIcon
                        icon={faSort}
                        style={{ marginLeft: 10, color: 'grey' }}
                    />
                }
            </td>       
        </tr>
    )
})

const SortableList = SortableContainer(({ items, history, roleIds, editPack, updateAccess, deletePack, isSortingEnabled }) => {
    return (
        <tbody>
            {
                items.map((item, index) => (
                    <SortableItem
                        roleIds={roleIds}
                        history={history}
                        disabled={!isSortingEnabled}
                        isSortingEnabled={isSortingEnabled}
                        key={item._id}
                        index={index}
                        listIndex={index}
                        eachPack={item}
                        updateAccess={updateAccess}
                        editPack={editPack}
                        deletePack={deletePack}
                    />
                ))
            }
        </tbody>
    )
})
class Packs extends React.Component {

    state = {
        isLoading: true,
        showEnterAllDataAlert: false,
        showCreatePackModal: false,
        isCurrentAffairs: false,
        isGoldCourse: false,
        hasDescriptiveCoaching: false,
        openForPremiumUsers: false,
        hasExpired: false,
        isFoundationCourse: false,
        title: '',
        subtitle: '',
        purpose: '',
        introVideoId: '',
        promoVideoId: '',
        post: null,
        productId: null,
        packsList: null,
        editIndex: null,
        isSortingEnabled: false,
        sortedArray:[],
        tags: [],
        relatedCourseGroups: [],
        goldPlanSellingPrice: '',
        goldPlanMaximumRetailPrice: ''
    }

    submitNewPack() {

        const {
            title,
            subtitle,
            purpose,
            post,
            crashType,
            imageUri,
            slideImageUri,
            promoSlideImageUrl,
            isCurrentAffairs,
            isGoldCourse,
            hasDescriptiveCoaching,
            openForPremiumUsers,
            hasExpired,
            isFoundationCourse,
            introVideoId,
            promoVideoId,
            syllabusLink,
            tags,
            relatedCourseGroups,
            goldPlanSellingPrice,
            goldPlanMaximumRetailPrice
        } = this.state

        if (isGoldCourse && !purpose) {
            alert("Razorpay title is required if gold course is checked")
            return true
        }

        if (title && subtitle && purpose && post) {

            this.setState({ isLoading: true })

            Axios.put('/pack', {
                title,
                subtitle,
                purpose,
                isCurrentAffairs,
                isGoldCourse,
                postId: post,
                crashType,
                introVideoId,
                imageUri,
                slideImageUri,
                promoSlideImageUrl,
                promoVideoId,
                hasDescriptiveCoaching,
                openForPremiumUsers,
                hasExpired,
                isFoundationCourse,
                syllabusLink,
                tags,
                relatedCourseGroups,
                goldPlanSellingPrice,
                goldPlanMaximumRetailPrice,
                order: this.state.packsList.length
            })
                .then(res => {
                    this.setState({ showCreatePackModal: false, showEnterAllDataAlert: false })
                    this.getAllpacks()
                })
                .catch(err => {
                    
                    this.setState({ isLoading: false })

                    if (err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    } else {
                        console.log(err)
                        alert("Error, check console")
                    }

                })
        } else {
            this.setState({ showEnterAllDataAlert: true })
        }
    }

    submitEditPack(productId) {

        const {
            title,
            subtitle,
            purpose,
            post,
            crashType,
            imageUri,
            slideImageUri,
            promoSlideImageUrl,
            isCurrentAffairs,
            isGoldCourse,
            hasDescriptiveCoaching,
            openForPremiumUsers,
            hasExpired,
            isFoundationCourse,
            introVideoId,
            promoVideoId,
            syllabusLink,
            tags,
            relatedCourseGroups,
            goldPlanSellingPrice,
            goldPlanMaximumRetailPrice
        } = this.state

        const postObject = {
            productId: productId,
            title,
            subtitle,
            isCurrentAffairs,
            isGoldCourse,
            purpose,
            postId: post,
            crashType,
            introVideoId,
            imageUri,
            slideImageUri,
            promoSlideImageUrl,
            promoVideoId,
            hasDescriptiveCoaching,
            openForPremiumUsers,
            hasExpired,
            isFoundationCourse,
            syllabusLink,
            tags,
            relatedCourseGroups,
            goldPlanSellingPrice,
            goldPlanMaximumRetailPrice
        }

        if (isGoldCourse && !purpose) {
            alert("Razorpay title is required if gold course is checked")
            return true
        }

        if (title && subtitle && purpose && post) {
            
            this.setState({ isLoading: true })

            Axios.post('/pack', postObject)
                .then(res => {
                    this.setState({
                        showCreatePackModal: false,
                        showEnterAllDataAlert: false
                    })
                    this.getAllpacks()
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                    if (err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error, check console")
                    }
                })
        } else {
            this.setState({ showEnterAllDataAlert: true })
        }
        
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    sortPostsArray() {
        const sortedArray = orderBy(this.props.posts, ['name'], ['asc'])
        this.setState({ sortedArray }, () => {
            this.getAllpacks()
        })
    }

    getAllpacks() {
        Axios.get('/pack')
            .then(res => {
                const { PackDetails, courseGroups } = res.data
                this.setState({ isLoading: false })
                this.props.updatePacks(PackDetails)
                this.props.updateCourseGroups(courseGroups)
                this.setState({ packsList: reverse(PackDetails), isLoading: false })
            })
            .catch(err => {
                this.setState({ isLoading: false })
                console.log(err)
                alert("Error, check console")
            })
    }

    onPacksSortEnd = ({ oldIndex, newIndex }) => {

        this.setState((oldState) => {
            let reorderedList = arrayMove(oldState.packsList, oldIndex, newIndex)
            oldState.packsList = reorderedList
            return oldState
        }, () => {
            let packs = cloneDeep(this.state.packsList)
            let reorderedItem = { documentId: packs[newIndex]._id, newIndex }

            if (newIndex > oldIndex) {

                const data = packs.slice(oldIndex, newIndex).map((each) => each._id)
                const decrementByOneDocuments = data

                Axios.post('/pack/reorder', { decrementByOneDocuments, reorderedItem })
                    .catch(err => {
                        console.log(err)
                        alert("Error, check console")
                    })

            } else if (newIndex < oldIndex) {

                const incrementByOneDocuments = packs.slice(newIndex + 1, oldIndex + 1).map((each) => each._id)

                Axios.post('/pack/reorder', { incrementByOneDocuments, reorderedItem })
                    .catch(err => {
                        console.log(err)
                        alert("Error, check console")
                    })

            } else {
                //No change in array order
                return true
            }

        })

    };

    openAccessEditingModal = (pack) => {

        if (Array.isArray(this.state.contentTeam) && this.state.contentTeam.length > 0) {
            this.setState({ showAccessEditingModal: true, accessEditingPackId: pack._id, allowedMembers: pack.allowedMembers || [] })
        } else {
            Axios.get('/team', { params: { teamGroup: "contentTeam" } })
                .then(res => {
                    this.setState({
                        contentTeam: res.data.team,
                        showAccessEditingModal: true,
                        accessEditingPackId: pack._id,
                        allowedMembers: pack.allowedMembers || []
                    })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Check console")
                })
        }

    }

    handleCheckBoxChange(userId, index) {

        this.setState((oldState) => {

            let foundIndex = oldState.allowedMembers.indexOf(userId)

            if (foundIndex > -1) {
                oldState.allowedMembers.splice(foundIndex, 1);
                return { allowedMembers: oldState.allowedMembers }
            } else {
                oldState.allowedMembers.push(userId)
                return { allowedMembers: oldState.allowedMembers }
            }

        })
    }

    saveAccessList() {
        Axios.post('/pack/update-access', { packId: this.state.accessEditingPackId, allowedUsers: this.state.allowedMembers })
            .then(res => {
                this.setState({ showAccessEditingModal: false, accessEditingPackId: null, allowedMembers: null }, () => this.getAllpacks())
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    componentDidMount() {
        this.sortPostsArray()
    }

    
    onEnterTag(newTag) {

        if (!newTag) {
            return alert("Please enter a tag")
        }

        if (Array.isArray(this.state.tags)) {
            this.setState((oldState) => {

                const foundIndex = oldState.tags.indexOf(newTag)

                if (foundIndex > -1) {
                    alert("This tag already exists")
                } else {
                    oldState.tags.push(newTag)
                    return { tags: oldState.tags, newTagName: null }
                }
            })
        } else {
            this.setState({ tags: [newTag], newTagName: null })
        }

    }

    deleteTag(newTag) {
        this.setState((oldState) => {
            const foundIndex = oldState.tags.indexOf(newTag)
            if (foundIndex > -1) {
                oldState.tags.splice(foundIndex, 1);
                return { tags: oldState.tags }
            }

        })
    }

    render() {
        return (
            <>

                { this.state.isLoading && <Loader /> }

                <Container fluid style={{ padding: 50 }}>

                    {
                        this.state.showAccessEditingModal &&
                        <Modal
                            isOpen={this.state.showAccessEditingModal}
                            size="lg"
                        >
                            <ModalHeader>
                                Select content team allowed to edit this pack
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <div>
                                        {
                                            this.state.contentTeam && this.state.contentTeam.map((each, index) => {
                                                return (
                                                    <CustomInput
                                                        id={each._id}
                                                        key={each._id}
                                                        value={each._id}
                                                        type="checkbox"
                                                        label={each.name}
                                                        onChange={(event) => this.handleCheckBoxChange(each._id)}
                                                        checked={this.state.allowedMembers.includes(each._id)}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </FormGroup>

                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.saveAccessList()}
                                >
                                    Submit
                                </Button>

                                <Button
                                    outline
                                    onClick={() => this.setState({ showAccessEditingModal: false, accessEditingPackId: null, allowedMembers: null })}
                                >
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>
                    }

                    <Modal isOpen={this.state.showCreatePackModal} size="lg">
                        <ModalHeader>
                            Enter Pack Details
                        </ModalHeader>
                        <ModalBody>

                            <Label>Titile</Label>
                            <Input
                                placeholder="Enter Title"
                                type="text"
                                name="title"
                                value={this.state.title}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Label>Subtitle</Label>
                            <Input
                                placeholder="Enter subtitle"
                                type="text"
                                name="subtitle"
                                value={this.state.subtitle || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Label>Razorpay Title</Label>
                            <Input
                                placeholder="Enter Razorpay Title"
                                type="text"
                                name="purpose"
                                value={this.state.purpose || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Row>
                                <Col md="6">
                                    <Label>Intro Video ID</Label>
                                    <Input
                                        placeholder="Intro Video Vimeo ID (Optional)"
                                        type="number"
                                        name="introVideoId"
                                        value={this.state.introVideoId || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                                <Col md="6">
                                    <Label>Promo Video ID</Label>
                                    <Input
                                        placeholder="Promo Video Vimeo ID (Optional)"
                                        type="number"
                                        name="promoVideoId"
                                        value={this.state.promoVideoId || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md="6">
                                    <Label>Enter Course Promo URI (Optional)</Label>
                                    <Input
                                        placeholder="Enter Course Promo URI (Optional)"
                                        type="text"
                                        name="imageUri"
                                        value={this.state.imageUri || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                                <Col md="6">
                                    <Label>Crash Type</Label>
                                    <Input
                                        type="select"
                                        name="crashType"
                                        id="crashType"
                                        value={this.state.crashType || ''}
                                        onChange={(e) => {
                                            const value = e.target.value

                                            if (value !== "selectCrashType") {
                                                this.setState({ crashType: value })
                                            } else {
                                                this.setState({ crashType: null })
                                            }
                                        }}
                                        style={{ marginBottom: 20 }}
                                    >
                                        {[
                                            { _id: "selectCrashType", name: "Select Crash Type" },
                                            { _id: "Crash", name: "Crash" },
                                            { _id: "Practice", name: "Practice" },
                                            { _id: "Batch", name: "Batch" }
                                        ].map((each, index) => {

                                            return <option
                                                key={each._id}
                                                value={each._id}
                                                onClick={(e) => this.setState({ crashType: e.target.value, isLoading: true })}
                                            >
                                                {each.name}
                                            </option>

                                        })
                                        }
                                    </Input>

                                </Col>
                            </Row>

                            <Row>
                                <Col md="6">
                                    <Label>Gold Batch MRP</Label>
                                    <Input
                                        placeholder="Gold Batch MRP"
                                        type="number"
                                        name="goldPlanMaximumRetailPrice"
                                        value={this.state.goldPlanMaximumRetailPrice || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                                <Col md="6">
                                    <Label>Gold Plan Selling Price</Label>
                                    <Input
                                        placeholder="Gold Plan Selling Price"
                                        type="number"
                                        name="goldPlanSellingPrice"
                                        value={this.state.goldPlanSellingPrice || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                            </Row>

                            <Row style={{ marginLeft: 20 }}>
                                <Label>Gold Course Enabled</Label>
                                <Input
                                    type="checkbox"
                                    value={this.state.isGoldCourse || ''}
                                    checked={this.state.isGoldCourse}
                                    onChange={(e) => this.setState({ isGoldCourse: e.target.checked })}
                                />
                            </Row>

                            <Row>
                                <Col md="12">
                                    <Label>Slide Image URI (Required)</Label>
                                    <Input
                                        placeholder="Enter Slide Image URI (Optional)"
                                        type="text"
                                        name="slideImageUri"
                                        value={this.state.slideImageUri || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md="12">
                                    <Label>Promo Slide Image URI (Required)</Label>
                                    <Input
                                        placeholder="Enter Promo Slide Image URI (Optional)"
                                        type="text"
                                        name="promoSlideImageUrl"
                                        value={this.state.promoSlideImageUrl || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 20 }}
                                    />
                                </Col>
                            </Row>

                            <Label>Syllabus Link</Label>
                            <Input
                                placeholder="Syllabus Link (Optional)"
                                type="text"
                                name="syllabusLink"
                                value={this.state.syllabusLink || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Label>Posts</Label>
                            <Input
                                type="select"
                                name="post"
                                id="post"
                                value={this.state.post || 'selectPost'}
                                onChange={(e) => {
                                    const value = e.target.value

                                    if (value !== "selectPost") {
                                        this.setState({ post: value })
                                    } else {
                                        this.setState({ post: null })
                                    }
                                }}
                                style={{ marginBottom: 35 }}
                            >
                                {[{ _id: "selectPost", name: "Select Post" }, ...this.state.sortedArray].map((each, index) => {

                                    return <option
                                        key={each._id}
                                        value={each._id}
                                        onClick={(e) => this.setState({ post: e.target.value, isLoading: true })}
                                    >
                                        {each.name}
                                    </option>

                                })
                                }
                            </Input>

                            <Row style={{ marginLeft: 20 }}>
                                <Label>Show Current Affairs Card</Label>
                                <Input
                                    type="checkbox"
                                    value={this.state.isCurrentAffairs || ''}
                                    checked={this.state.isCurrentAffairs}
                                    onChange={(e) => this.setState({ isCurrentAffairs: e.target.checked })}
                                />
                            </Row>

                            <Row style={{ marginLeft: 20 }}>
                                <Label>Descriptive Coaching Included</Label>
                                <Input
                                    type="checkbox"
                                    value={this.state.hasDescriptiveCoaching || ''}
                                    checked={this.state.hasDescriptiveCoaching}
                                    onChange={(e) => this.setState({ hasDescriptiveCoaching: e.target.checked })}
                                />
                            </Row>


                            <Row style={{ marginLeft:  20 }}>
                                <Label>Text content available for Premium Users</Label>
                                <Input
                                    type="checkbox"
                                    value={this.state.openForPremiumUsers || ''}
                                    checked={this.state.openForPremiumUsers}
                                    onChange={(e) => this.setState({ openForPremiumUsers: e.target.checked })}
                                />
                            </Row>

                            <Row style={{ marginLeft:  20 }}>

                                <Label>Expired</Label>
                                <Input
                                    type="checkbox"
                                    value={this.state.hasExpired || ''}
                                    checked={this.state.hasExpired}
                                    onChange={(e) => this.setState({ hasExpired: e.target.checked })}
                                />
                            </Row>
                            <Row style={{ marginLeft: 20, marginBottom: 20 }}>

                                <Label>Foundation Course</Label>
                                <Input
                                    type="checkbox"
                                    value={this.state.isFoundationCourse || ''}
                                    checked={this.state.isFoundationCourse}
                                    onChange={(e) => this.setState({ isFoundationCourse: e.target.checked })}
                                />
                            </Row>

                            <Alert isOpen={this.state.showEnterAllDataAlert} color="danger">Enter all data before submitting</Alert>

                            <hr />

                            <h6>Select Related Course Groups</h6>

                            <ListGroup>
                                {
                                    this.props.courseGroups.map((each, index) => (
                                        <ListGroupItem key={each._id}>
                                            <CustomInput
                                                id={each._id}
                                                value={each._id}
                                                type="checkbox"
                                                label={`${each.name} (${each.description})`}
                                                onChange={() => {
                                                    this.setState({
                                                        relatedCourseGroups: addOrRemoveItem(this.state.relatedCourseGroups, each._id)
                                                    })
                                                }}
                                                checked={this.state.relatedCourseGroups.includes(each._id)}
                                            />
                                        </ListGroupItem>
                                    ))
                                }
                            </ListGroup>

                            <hr />

                            <Input
                                placeholder="Enter a tag"
                                onChange={this.handleInputChange}
                                value={this.state.newTagName || ''}
                                name="newTagName"
                            />

                            <Button
                                style={{ marginTop: 10 }}
                                variant="outline-primary"
                                block
                                onClick={() => this.onEnterTag(this.state.newTagName)}>Add</Button>
                            
                            <hr />

                            {
                                Array.isArray(this.state.tags) && this.state.tags.length > 0 ?
                                    <>
                                        {
                                            this.state.tags.map(each => (
                                                <Badge
                                                    onClick={() => this.deleteTag(each)}
                                                    style={{ margin: 2, fontSize: 16 }}
                                                    key={each}
                                                >{each}</Badge>
                                            ))
                                        }
                                        <p style={{ marginTop: 10, fontSize: 12 }}>Click a tag to delete it</p>
                                    </>
                                    :
                                    <p>No tags added</p>
                            }

                        </ModalBody>

                        <ModalFooter>

                            <Button
                                color="primary"
                                onClick={ () => {
                                    
                                    if (this.state.productId) {
                                        this.submitEditPack(this.state.productId)
                                    } else {
                                        this.submitNewPack()
                                    }
                                    
                                }}
                            >
                                Submit
                            </Button>

                            <Button
                                outline
                                onClick={() => this.setState({ showCreatePackModal: false, showEnterAllDataAlert: false })}
                            >
                                Cancel
                            </Button>

                        </ModalFooter>
                    </Modal>

                    <Row style={{ marginBottom: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Col>
                            <h2>Packs List</h2>
                        </Col>
                        <Col md='3'>
                            <Button
                                style={{ marginRight: '1rem' }}
                                outline
                                color='primary'
                                onClick={() => this.setState({
                                    title: '',
                                    subtitle: '',
                                    purpose: '',
                                    introVideoId: '',
                                    promoVideoId: '',
                                    post: null,
                                    productId: null,
                                    editIndex: null,
                                    slideImageUri: '',
                                    promoSlideImageUrl: '',
                                    tags: [],
                                    syllabusLink: '',
                                    isCurrentAffairs: false,
                                    isGoldCourse: false,
                                    hasDescriptiveCoaching: false,
                                    openForPremiumUsers: true,
                                    hasExpired: false,
                                    isFoundationCourse: false,
                                    newTagName: '',
                                    imageUri:'',
                                    showCreatePackModal: true, 
                                    relatedCourseGroups: [],
                                    goldPlanSellingPrice: '',
                                    goldPlanMaximumRetailPrice: ''
                                })}
                            >
                                + Create New Pack
                            </Button>

                            <Button
                                outline
                                color='success'
                                onClick={() => this.setState({ isSortingEnabled: !this.state.isSortingEnabled })}
                            >{this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        {this.state.packsList && this.state.packsList.length > 0 ?
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Title</th>
                                            <th>Subtitle</th>
                                            <th>Rzp Title</th>
                                            <th>Crash Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <SortableList
                                        roleIds={this.props.roleIds}
                                        history={this.props.history}
                                        isSortingEnabled={this.state.isSortingEnabled}
                                        items={this.state.packsList}
                                        onSortEnd={this.onPacksSortEnd}
                                        updateAccess={ (pack) => this.openAccessEditingModal(pack) }
                                        editPack={(pack) => {

                                            const relatedCourseGroups = []
                                            
                                            this.props.courseGroups.forEach(eachCourseGroup => {
                                                if (eachCourseGroup.packsList.includes(pack._id)) {
                                                    relatedCourseGroups.push(eachCourseGroup._id)
                                                }
                                            })

                                            this.setState({
                                                title: pack.title,
                                                subtitle: pack.subtitle,
                                                purpose: pack.purpose,
                                                post: pack.postId,
                                                introVideoId: pack.introVideoId,
                                                goldPlanSellingPrice: pack.goldPlanSellingPrice,
                                                goldPlanMaximumRetailPrice: pack.goldPlanMaximumRetailPrice,
                                                promoVideoId: pack.promoVideoId,
                                                crashType: pack.crashType,
                                                imageUri: pack.imageUri,
                                                slideImageUri: pack.slideImageUri,
                                                promoSlideImageUrl: pack.promoSlideImageUrl,
                                                isCurrentAffairs: pack.currentAffairs,
                                                isGoldCourse: pack.isGoldCourse,
                                                hasDescriptiveCoaching: pack.hasDescriptiveCoaching,
                                                openForPremiumUsers: pack.openForPremiumUsers,
                                                hasExpired: pack.hasExpired,
                                                isFoundationCourse: pack.isFoundationCourse,
                                                productId: pack._id,
                                                syllabusLink: pack.syllabusLink,
                                                tags: pack.tags,
                                                editIndex: null,
                                                showCreatePackModal: true,
                                                relatedCourseGroups
                                            })

                                        }}
                                        deletePack={(productId, status) => {
                                            Axios.delete('/pack', { params: { productId, status } })
                                                .then(res => {
                                                    this.getAllpacks()
                                                })
                                                .catch(err => {
                                                    console.log(err)
                                                    alert("Error, check console")
                                                })
                                        }}
                                    />
                                </Table>
                            </Col>
                            :
                            Array.isArray(this.state.packsList) && this.state.packsList.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <h4 style={{ textAlign: 'center', marginTop: 50 }}>No packs created</h4>
                                </div>
                                : null
                        }
                    </Row>
                </Container>
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        posts: state.data.posts,
        courseGroups: state.data.courseGroups,
        roleIds: state.auth.roleIds
    }
}

export default connect(mapStateToProps, { updatePacks, updateCourseGroups })(Packs)