import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import {
    Container, Button, Input,
    Form, FormGroup, Label,
    Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap'

import { camelCaseToTitleCase } from '../utils'
import TinyEditor from '../Components/TinyEditor'
import Loader from '../Components/Loader'
import { cloneDeep, startCase, snakeCase, camelCase } from 'lodash'

const tabs = [ "landingPage", "cutOff", "syllabus", "examDate" ]

const landingPageFields = [ "landingPageTitle", "landingPageDescription" ]
const cutOffFields = [ "cutOffPageTitle", "cutOffPageDescription" ]
const syllabusFields = [ "syllabusPageTitle", "syllabusPageDescription" ]
const examDateFields = [ "examDatePageTitle", "examDatePageDescription" ]

const SingleInputElement = ({ updatePageTitleOrDescription, nestedWebPages, itemKey }) => (
    <FormGroup>
        <Label>{startCase(camelCase(itemKey))}</Label>
        <Input
            placeholder={`${startCase(camelCase(itemKey))}`}
            type="text"
            name={itemKey}
            value={nestedWebPages ? nestedWebPages[itemKey] || '' : ''}
            onChange={(e) => updatePageTitleOrDescription(e.target.value, itemKey)}
            style={{ marginBottom: 20 }}
        />
    </FormGroup>
)

function PostWebsiteEditor({ location }) {

    const [activeTab, setActiveTab] = useState("landingPage")
    const [isLoading, setIsLoading] = useState(true)
    const [websiteEndPoint, setWebsiteEndpoint] = useState(null)
    const [nestedWebPages, setNestedWebpages] = useState(null)

    useEffect(() => {
        const { postId } = queryString.parse(location.search)
        Axios.get('/post/website-content', { params: { postId } })
            .then(res => {
                setIsLoading(false)
                setWebsiteEndpoint(res.data.websiteEndPoint)
                setNestedWebpages(res.data.nestedWebPages)
            })
            .catch(err => {
                console.error(err)
                alert("Server error, check console")
            })
    }, [])

    const saveHtmlChanges = () => {

        setIsLoading(true)

        const { postId } = queryString.parse(location.search)
        
        Axios.post('/post/website-content', { postId, websiteEndPoint, nestedWebPages })
            .then(() => setIsLoading(false))
            .catch(err => {
                console.error(err)
                alert("Server error, check console")
            })

    }

    const updateHtmlContent = (html) => {

        let currentNestedPages = cloneDeep(nestedWebPages)
        
        if (currentNestedPages) {
            currentNestedPages[activeTab] = html
            setNestedWebpages(currentNestedPages)
        } else {
            setNestedWebpages({ [activeTab]: html })
        }

    }

    const updatePageTitleOrDescription = (text, key) => {

        let currentNestedPages = cloneDeep(nestedWebPages)
            
        if (currentNestedPages) {
            currentNestedPages[key] = text
            setNestedWebpages(currentNestedPages)
        } else {
            setNestedWebpages({ [key]: text })
        }

    }

    const openWebsite = () => {
        
        if (websiteEndPoint) {

            let url = `https://keralapsc.app/exams`

            if (activeTab === "landingPage") {
                url += `/${websiteEndPoint}`
            } else {
                url += `/${websiteEndPoint}/${snakeCase(activeTab)}`
            }

            window.open(url, '_blank', 'noreferrer');

        } else {
            alert("Please fill the website endpoint field")
        }
    }

    return (
        <>
            {isLoading && <Loader />}

            <Container>
                
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 25, marginBottom: 25 }}>
                    <h3>Generate Page</h3>
                    <Button color="primary" onClick={saveHtmlChanges}>Save Changes</Button>
                </div>

                <Input
                    placeholder="Enter Website Endpoint (Example: /degree-prelims)"
                    type="text"
                    name="websiteEndPoint"
                    value={websiteEndPoint || ''}
                    onChange={(e) => setWebsiteEndpoint(e.target.value)}
                    style={{ marginBottom: 20 }}
                />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Nav tabs style={{ flex:1 }}>
                        {
                            tabs.map(tab => (
                                <NavItem key={tab}>
                                    <NavLink
                                        className={activeTab === tab ? 'active' : null}
                                        onClick={() => setActiveTab(tab)}
                                    >
                                        {camelCaseToTitleCase(tab)}
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </Nav>

                    <Button
                        outline
                        color="primary"
                        onClick={openWebsite}
                    >Open Website</Button>
                </div>

                <TabContent activeTab={activeTab}>

                    <TabPane tabId={tabs[0]}>

                        <Form style={{ marginTop: 20 }}>
                            {
                                landingPageFields.map(each => (
                                    <SingleInputElement
                                        key={each}
                                        itemKey={each}
                                        nestedWebPages={nestedWebPages}
                                        updatePageTitleOrDescription={(value, key) => updatePageTitleOrDescription(value, key)}
                                    />
                                ))
                            }
                        </Form>
                        <TinyEditor
                            height={'80vh'}
                            content={nestedWebPages ? nestedWebPages[tabs[0]] : ''}
                            onHtmlChange={(newHtml) => updateHtmlContent(newHtml)}
                        />
                    </TabPane>

                    <TabPane tabId={tabs[1]}>

                        <Form style={{ marginTop: 20 }}>
                            {
                                cutOffFields.map(each => (
                                    <SingleInputElement
                                        key={each}
                                        itemKey={each}
                                        nestedWebPages={nestedWebPages}
                                        updatePageTitleOrDescription={(value, key) => updatePageTitleOrDescription(value, key)}
                                    />
                                ))
                            }
                        </Form>
                        <TinyEditor
                            height={'80vh'}
                            content={nestedWebPages ? nestedWebPages[tabs[1]] : ''}
                            onHtmlChange={(newHtml) => updateHtmlContent(newHtml)}
                        />
                    </TabPane>

                    <TabPane tabId={tabs[2]}>
                        <Form style={{ marginTop: 20 }}>
                        {
                            syllabusFields.map(each => (
                                <SingleInputElement
                                    key={each}
                                    itemKey={each}
                                    nestedWebPages={nestedWebPages}
                                    updatePageTitleOrDescription={(value, key) => updatePageTitleOrDescription(value, key)}
                                />
                            ))
                        }
                        </Form>
                        <TinyEditor
                            height={'80vh'}
                            content={nestedWebPages ? nestedWebPages[tabs[2]] : ''}
                            onHtmlChange={(newHtml) => updateHtmlContent(newHtml)}
                        />
                    </TabPane>

                    <TabPane tabId={tabs[3]}>
                        <Form style={{ marginTop: 20 }}>
                        {
                            examDateFields.map(each => (
                                <SingleInputElement
                                    key={each}
                                    itemKey={each}
                                    nestedWebPages={nestedWebPages}
                                    updatePageTitleOrDescription={(value, key) => updatePageTitleOrDescription(value, key)}
                                />
                            ))
                        }
                        </Form>
                        <TinyEditor
                            height={'80vh'}
                            content={nestedWebPages ? nestedWebPages[tabs[3]] : ''}
                            onHtmlChange={(newHtml) => updateHtmlContent(newHtml)}
                        />
                    </TabPane>

                </TabContent>

            </Container>
        </>
    )
}

export default PostWebsiteEditor
