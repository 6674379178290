import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Row, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment'
import queryString from 'query-string';
import { unstable_batchedUpdates } from 'react-dom'
import { connect } from 'react-redux'



function UserNotes(props) {
    const [notes, setNotes] = useState([])
    const [notesCount, setNoteCount] = useState(0)
    const [skipCount, setSkipCount] = useState(0)

    useEffect(() => {
        fetchUserNotes()
    }, [props.location])

    const fetchUserNotes = () => {

        let params = queryString.parse(props.location.search)

        let paramsObject = {
            skipCount: (params && params.index) ? (parseInt(params.index) * 50) : 0,
            limit: 50
        }
        axios.get('/learn/user-notes-collection', { params: paramsObject })
            .then(res => {


                unstable_batchedUpdates(() => {
                    setNotes(res.data.userNotes)
                    setNoteCount(res.data.notesCount)
                })
            })
    }

    const getParamsIndex = () => {
        let params = queryString.parse(props.location.search)
        if (params && params.index) {
            return parseInt(params.index)
        } else {
            return 0
        }
    }

    useEffect(() => {
        setQueryParams(skipCount)
    }, [skipCount])

    const setQueryParams = (index) => {
        props.history.push({
            pathname: "/user-notes",
            search: `index=${index}`
        })
    }

    const renderSkipMenu = () => {
        let returnArray = []
        let itemCount = parseInt(notesCount) / 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => setSkipCount(index)}
                >
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    return (
        <Container fluid>
            <Row style={{ margin: 15, fontFamily: "Arial", justifyContent: 'space-between' }}>
                <h1>
                    User Notes Collections
                </h1>
                <UncontrolledDropdown>
                    <DropdownToggle caret
                        color="success" id="dropdown-basic"
                    >
                        Page {getParamsIndex() + 1}
                    </DropdownToggle>
                    <DropdownMenu className="addScroll">
                        {renderSkipMenu()}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Row>
            <Table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>User Name</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {notes.map((note, index) => {
                        return (
                            <tr key={note.notes._id}>
                                <td>{index + 1}</td>
                                <td>{note.userData.name}</td>
                                <td>
                                    {note.notes.note}
                                    <p>
                                        <span style={{ color: "grey", marginRight: 10 }}>{moment(note.notes.savedDate).format("YYYY MMMM D")}</span>
                                        <span style={{ color: "grey" }}>{note.notes.subjectName} - {note.notes.section} </span>
                                    </p>

                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    )
}

const mapStateToProps = state => {
    return {

        subjects: state.data.subjects
    }
}
export default connect(mapStateToProps)(UserNotes)