import React, { useCallback, useEffect, useState } from 'react'
import Axios from 'axios'
import {
    Container, Row, Table, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, FormGroup,
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap'
import { faPen, faTrash, faSort, faRandom } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { unstable_batchedUpdates } from 'react-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import moment from 'moment'
import { Checkbox } from 'material-ui'
import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import Loader from '../../Components/Loader'
import Aux from '../../Components/Auxiliary'
import { isContentManagerOrHigher } from '../../utils/dashboardRoles';

const dummyModule = {
    contentType: '',
    contentId: '',
    relatedToAboveItem: false
}

const AddOrEditForm = ({ data, isLoading, updateData, submit, close }) => {
    return (
        <Modal isOpen={true} fade={true}>

            { isLoading && <Loader /> }

            <ModalHeader>Enter new module details</ModalHeader>

            <ModalBody>

                <Input
                    type="select"
                    name="crashType"
                    id="crashType"
                    value={data.contentType}
                    onChange={(e) => {
                        const value = e.target.value

                        if (value !== "selectContentType") {
                            updateData({ ...data, contentType: e.target.value })
                        } else {
                            updateData({ ...data, contentType: null })
                        }
                    }}
                    style={{ marginBottom: 20 }}
                >
                    {[
                        { _id: "selectContentType", name: "Select Content Type" },
                        { _id: "video", name: "Video" },
                        { _id: "note", name: "Note" },
                        { _id: "test", name: "Test" },
                        { _id: "mockExam", name: "Mock Exam" },
                        { _id: "revisionExam", name: "Revision Exam" },
                        { _id: "cards", name: "Cards" },
                    ].map((each, index) => {

                        return <option
                            key={each._id}
                            value={each._id}
                            onClick={(e) => this.setState({ crashType: e.target.value, isLoading: true })}
                        >
                            {each.name}
                        </option>

                    })
                    }
                </Input>

                <Input
                    placeholder="Enter ContentID"
                    type="text"
                    name="contentId"
                    value={data.contentId || ''}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => updateData({ ...data, contentId: e.target.value })}
                />

                <FormGroup check>
                    <CustomInput
                        id="relatedToAboveItem"
                        type="checkbox"
                        label="Related to previous module"
                        checked={data.relatedToAboveItem}
                        onChange={e => updateData({ ...data, relatedToAboveItem: e.target.checked })}
                    />
                </FormGroup>

            </ModalBody>

            <ModalFooter>
                <Button onClick={submit} color="primary">Submit</Button>
                <Button onClick={close} outline>Close</Button>
            </ModalFooter>

        </Modal>
    )
}

const SortableItem = SortableElement(({ each, itemIndex, items, match, history, setEditingModuleDetails, removeModule, showMarksList, isSortingEnabled, questionIdsToCopyArr, onCheck }) => {

    let rowBackgroundColor = null

    if (each.moduleDetails && each.moduleDetails.moduleTitle && each.moduleDetails.activeFlag === false) {
        rowBackgroundColor = 'rgba(255,0,0,0.1)'
    }

    const showBorder = each.relatedToAboveItem && items[itemIndex + 1] && !items[itemIndex + 1].relatedToAboveItem

    return (
        <>
            <tr
                key={each._id}
                style={{ background: rowBackgroundColor }}
            >
                <td>
                    <Checkbox
                        checked={questionIdsToCopyArr.includes(each._id)}
                        onCheck={() => onCheck(each._id)}
                    />
                </td>
                <td>{itemIndex + 1}</td>
                <td>
                    {
                        (each.moduleDetails && each.moduleDetails.moduleTitle) ?
                            <>
                                {each.moduleDetails.moduleTitle}
                                {
                                    each.contentType === "test" && each.moduleDetails.deletedQuestions > 0 &&
                                    <>
                                        <br />
                                        <span style={{ color: 'red', fontSize: 12 }}>
                                            {each.moduleDetails.deletedQuestions} deleted questions found
                                        </span>
                                    </>
                                }
                                {each.moduleDetails.activeFlag ? null : <p style={{ fontSize: 14, color: 'red' }}>Deleted module</p>}
                            </> :
                            (each.examDetails && each.examDetails.description) ?
                                <>
                                    {each.examDetails.description}
                                    {
                                        each.examDetails.deletedQuestions > 0 && 
                                        <>
                                            <br/>
                                            <span style={{ color: 'red', fontSize: 12 }}>
                                                {each.examDetails.deletedQuestions} deleted questions found
                                            </span> 
                                        </>
                                    }
                                    
                                </> :
                                ''
                    }
                </td>
                <td>{each.contentType}</td>
                <td>
                    {!isSortingEnabled ?

                        <>
                            <Button
                                style={{ marginRight: 10 }}
                                outline
                                color="primary"
                                onClick={() => {
                                    if (each.contentType === "mockExam" || each.contentType === "revisionExam") {
                                        history.push(`/exams/edit?mockExamId=${each.contentId}`)
                                    } else {
                                        history.push({ pathname: `/module/${each.contentId}` })
                                    }
                                }}
                            >
                                {(each.contentType === "mockExam" || each.contentType === "revisionExam") ? "Show Questions" : "Show Module"}
                            </Button>
                            <Button
                                style={{ marginRight: 10 }} outline color="primary"
                                onClick={() => setEditingModuleDetails(each)}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                            <Button
                                outline
                                color="danger"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    if (window.confirm("Remove this module from this day?")) {
                                        removeModule(each._id, match.params.dayId)
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            {
                                (each.contentType === "mockExam" || each.contentType === "revisionExam") ?
                                    <Button

                                        outline
                                        color="primary"
                                        onClick={() => showMarksList(each._id)}
                                    >
                                        Marks List
                                    </Button>
                                    : null
                            }

                        </>
                        : <FontAwesomeIcon
                            icon={faSort}
                            style={{ marginLeft: 10, color: 'grey' }}
                        />

                    }

                </td>
            </tr>
            {
                showBorder ?
                <tr>
                    <td colSpan={10} style={{ marginBottom: 50 }}>
                        <br/>
                            <div style={{ borderBottom: '0.5px solid #5e5e5e'}} />
                        <br/>
                    </td>
                </tr>
                : null
            }

        </>
    )
})

const SortableModules = SortableContainer(({ items, history, match, setEditingModuleDetails, removeModule, showMarksList, isSortingEnabled, questionIdsToCopyArr, setQuestionIdsToCopyArr, onCheck }) => (
    <tbody>
        {
            items.map((eachItem, index) => (
                <SortableItem
                    onCheck={onCheck}
                    items={items}
                    setQuestionIdsToCopyArr={setQuestionIdsToCopyArr}
                    isSortingEnabled={isSortingEnabled}
                    questionIdsToCopyArr={questionIdsToCopyArr}
                    disabled={!isSortingEnabled}
                    key={eachItem._id}
                    index={index}
                    itemIndex={index}
                    each={eachItem}
                    match={match}
                    history={history}
                    showMarksList={showMarksList}
                    setEditingModuleDetails={setEditingModuleDetails}
                    removeModule={removeModule}
                />
            ))
        }
    </tbody>
))

function BatchDayModules({ history, match }) {

    const [isLoading, setIsLoading] = useState(true)

    const [modules, setModules] = useState(null)
    const [studyGroups, setStudyGroups] = useState(null)
    const [examModuleId, setExamModuleId] = useState(null)
    const [selectedStudyGroup, setSelectedStudyGroup] = useState(null)
    const [examRankList, setExamRankList] = useState(null)

    const [showAddModal, setShowAddModal] = useState(null)
    const [newModuleDetails, setNewModuleDetails] = useState(JSON.parse(JSON.stringify(dummyModule)))

    const [editingModuleDetails, setEditingModuleDetails] = useState(null)
    const [isSortingEnabled, setSorting] = useState(false)
    const [questionIdsToCopyArr, setQuestionIdsToCopyArr] = useState([])
    const [showPasteIdsModal,setShowPasteIdsModal] = useState(false)
    const [moduleIdsSubmitArr, setModuleIdsSubmitArr] = useState([])

    const roleIds = useSelector(state => state.auth.roleIds)

    const getDayModules = useCallback(() => {
        setIsLoading(true)
        Axios.get('/batch/admin/day/modules', { params: { dayId: match.params.dayId } })
            .then(res => {
                unstable_batchedUpdates(() => {
                    setModules(res.data.modules)
                    setStudyGroups(res.data.studyGroups)
                    setIsLoading(false)
                })
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Error. Check console")
            })
    }, [match.params.dayId])

    const addModule = () => {

        setIsLoading(true)

        Axios.put('/batch/admin/day/modules', { ...newModuleDetails, dayId: match.params.dayId })
            .then(() => {
                setShowAddModal(false)
                getDayModules()
            })
            .catch(err => {

                if (err && err.response && err.response.status === 404 && err.response.data && err.response.data.message) {
                    setIsLoading(false)
                    alert(err.response.data.message)
                    console.log(err)
                } else {
                    setIsLoading(false)
                    alert("Error. Please check console")
                    console.log(err)
                }

            })
    }

    const editModule = () => {
        Axios.post('/batch/admin/day/modules', { ...editingModuleDetails, dayId: match.params.dayId })
            .then(() => {
                setEditingModuleDetails(false)
                getDayModules()
            })
            .catch(err => {
                if (err && err.response && err.response.status === 404 && err.response.data && err.response.data.message) {
                    alert(err.response.data.message)
                    console.log(err)
                } else {
                    alert("Error. Please check console")
                    console.log(err)
                }

            })
    }

    const removeModule = (deletingModuleId, dayId) => {
        Axios.delete('/batch/admin/day/modules', { params: { dayId, deletingModuleId } })
            .then(() => {
                getDayModules()
            })
            .catch(err => {
                alert("Error. Please check console")
                console.log(err)
            })
    }

    const onModulesSortEnd = ({ oldIndex, newIndex }) => {

        let reorderedList = arrayMove(modules, oldIndex, newIndex)

        setModules(reorderedList)

        let modulesWithUpdatedIndex = reorderedList.map((each, index) => ({ _id: each._id, index }))

        Axios.post('/batch/admin/day/modules/re-order', {
            dayId: match.params.dayId,
            reorderedItemId: reorderedList[newIndex]._id,
            modulesWithUpdatedIndex
        })
            .then(() => getDayModules())
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })


    };

    useEffect(() => {
        getDayModules()
    }, [getDayModules])

    useEffect(() => {
        if (selectedStudyGroup) {
            setIsLoading(true)
            Axios.get('/batch/admin/rank-list', {
                params: {
                    selectedStudyGroup: selectedStudyGroup._id,
                    dayId: match.params.dayId,
                    examModuleId
                }
            })
                .then(res => {
                    unstable_batchedUpdates(() => {
                        setIsLoading(false)
                        setExamRankList(res.data.ranklist)
                    })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Check console")
                    setIsLoading(false)
                })
        }
    }, [selectedStudyGroup])

    const copyModule = () => {
        const copyIdArr = moduleIdsSubmitArr.split("\n")
        setShowPasteIdsModal(false)
        Axios.post('/batch/admin/day/copy-modules', {
            targetDayId: match.params.dayId,
            copyIdArr
        })
            .then(() => {
                getDayModules()
            })
            .catch(err => {
                alert("Error. Please check console")
                console.log(err)
            })
    }

    const transferModules = (source, destination) => {

        setIsLoading(true)

        Axios.post('/batch/transfer', { sourceDayId: source, destinationDayId: destination })
            .then(() => {
                getDayModules()
            })
            .catch(err => {
                setIsLoading(false)
                alert("Error. Please check console")
                console.log(err)
            })

    }

    const onCheck = (each) => {

        let questionIdsToCopyArrClone= cloneDeep(questionIdsToCopyArr)

            const foundItemIndex = questionIdsToCopyArrClone.indexOf(each)

            if (foundItemIndex >= 0) {
                questionIdsToCopyArrClone.splice(foundItemIndex, 1)
            } else {
                questionIdsToCopyArrClone.push(each)

            }

        setQuestionIdsToCopyArr(questionIdsToCopyArrClone)       
    }

   const onCheckAll = (modules) => {
        let questionIdsToCopyArrClone= cloneDeep(questionIdsToCopyArr)
        if(modules.length!== questionIdsToCopyArrClone.length) {
            modules.map((each) => {
                const foundItemIndex = questionIdsToCopyArrClone.indexOf(each._id)
                if (foundItemIndex < 0) {
                    return(
                        questionIdsToCopyArrClone.push(each._id)
                    )  
                }            
             })
                setQuestionIdsToCopyArr(questionIdsToCopyArrClone)
        } else {
            setQuestionIdsToCopyArr([])
        }
           
    }

    const copyIdsToClipboard =()=> {
        
        let string = ''
        questionIdsToCopyArr.forEach(item => {
            if (string) {
                string = `${string}\n${item}`
            } else {
                string = item
            }
        })

        var textField = document.createElement('textarea')
        textField.innerHTML = string
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        toast("Copied to clipboard", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        })

    } 

    const openPasteIdsModal = () => {
        return (
            <Modal
                isOpen={showPasteIdsModal}
                toggle={() => setShowPasteIdsModal(false)}
            >

                <ModalHeader>Paste Module Ids</ModalHeader>

                <ModalBody>
                    <Input
                    type= "textarea"
                    rows= {5}
                    onChange= {(e) => setModuleIdsSubmitArr(e.target.value)}
                    value= {moduleIdsSubmitArr}
                    />
                </ModalBody>

                <ModalFooter>

                    <Button color= "primary"
                    onClick= {() => copyModule()}
                    >
                        Submit
                    </Button>

                    <Button
                        outline
                        onClick= {() => unstable_batchedUpdates(() => {
                            setModuleIdsSubmitArr([])
                            setShowPasteIdsModal(false)
                        })}
                        color= "secondary">
                        Cancel
                    </Button>

                </ModalFooter>

            </Modal>
        )
    } 

    return (
        <Aux>

            { isLoading && <Loader /> }
            { openPasteIdsModal() }

            <Container style={{ paddingTop: 50 }}>

                {
                    examModuleId ?
                        <Modal
                            isOpen={true}
                            size="xl"
                        // contentClassName="wideModal"
                        >
                            <ModalHeader>
                                Exam Rank List
                            </ModalHeader>

                            <ModalBody>

                                {
                                    (Array.isArray(studyGroups) && studyGroups.length > 0) &&
                                    <Row>
                                        <UncontrolledDropdown style={{ margin: 10 }}>
                                            <DropdownToggle> {(selectedStudyGroup) ? `Batch ${selectedStudyGroup.groupName}` : "Select Batch"}</DropdownToggle>
                                            <DropdownMenu className="addScroll">
                                                {
                                                    studyGroups.map((each) => {
                                                        return (
                                                            <DropdownItem
                                                                key={each._id}
                                                                value={each._id}
                                                                onClick={() => setSelectedStudyGroup({ _id: each._id, groupName: each.groupName })}
                                                            >
                                                                Batch {each.groupName}
                                                            </DropdownItem>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Row>
                                }

                                {
                                    Array.isArray(examRankList) && examRankList.length > 0 ?
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>Name</th>
                                                    <th>Marks %</th>
                                                    <th>Marks</th>
                                                    <th>Correct</th>
                                                    <th>Wrong</th>
                                                    <th>Unattended</th>
                                                    <th>Attended On</th>
                                                    <th>Duration</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    examRankList.map((each, index) => (
                                                        <tr key={each._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{each.name}</td>
                                                            <td>{each.marksPercentage ? `${each.marksPercentage}%` : "-"}</td>
                                                            <td>{each.examResult ? each.examResult.marks : "-"}</td>
                                                            <td>{each.examResult ? each.examResult.correctAnswer : "-"}</td>
                                                            <td>{each.examResult ? each.examResult.wrongAnswer : "-"}</td>
                                                            <td>{each.examResult ? each.examResult.unattended : "-"}</td>
                                                            <td>{each.examResult ? moment(each.examResult.attendedDate).format("hh:MM A DD MMM YYYY") : "-"}</td>
                                                            <td>{each.examResult ? `${(each.examResult.timeTaken / 1000 / 60).toFixed(2)} minutes` : "-"}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        : Array.isArray(examRankList) && examRankList.length === 0 ?
                                            <p>No one has attended this exam so far</p>
                                            : null
                                }


                            </ModalBody>

                            <ModalFooter>
                                <Button onClick={() => {
                                    unstable_batchedUpdates(() => {
                                        setExamModuleId(null)
                                        setExamRankList(null)
                                    })
                                }}>Close</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {showAddModal &&
                    <AddOrEditForm
                        data={newModuleDetails}
                        isLoading={isLoading}
                        updateData={setNewModuleDetails}
                        submit={() => addModule()}
                        close={() => {
                            unstable_batchedUpdates(() => {
                                setNewModuleDetails(JSON.parse(JSON.stringify(dummyModule)))
                                setShowAddModal(false)
                            })
                        }}
                    />
                }

                {(editingModuleDetails) &&
                    <AddOrEditForm
                        isLoading={isLoading}
                        data={editingModuleDetails}
                        updateData={setEditingModuleDetails}
                        submit={() => editModule()}
                        close={() => setEditingModuleDetails(null)}
                    />
                }

                <Row>
                    {questionIdsToCopyArr.length > 0 &&
                        <Button
                            color="primary"
                            style={{ margin: 10 }}
                            onClick={() => copyIdsToClipboard()}
                        >
                            Copy Ids
                        </Button>
                    }

                    <Button
                        outline
                        color="primary"
                        style={{ margin: 10 }}
                        onClick={() => setShowPasteIdsModal(true)}
                    >
                        Add Modules By ID
                    </Button>

                    {
                        isContentManagerOrHigher(roleIds) &&
                        <Button
                            style={{ alignSelf: 'center' }}
                            outline
                            color="primary"
                            onClick={() => {
                                const batchDayId = window.prompt("Enter batch day ID")
                                if (batchDayId) {
                                    transferModules(batchDayId, match.params.dayId)
                                }
                            }}
                        >
                            Transfer By Day ID <FontAwesomeIcon icon={faRandom} />
                        </Button>
                    }

                    <Button
                        color="primary"
                        outline
                        style={{ margin: 10, marginLeft: "auto" }}
                        onClick={() => setShowAddModal(true)}>
                        + ADD MODULE
                    </Button>
                    <Button
                        style={{ margin: 10 }}
                        outline
                        color="success"
                        onClick={() => {
                            setSorting(!isSortingEnabled)
                        }}
                    >
                        {isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                    </Button>                    
                </Row>

                <Row>
                    {
                        (Array.isArray(modules) && modules.length > 0) ?
                            <Table borderless>
                                <thead>
                                    <tr style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                                        <th>
                                            <Checkbox
                                                checked={questionIdsToCopyArr.length === modules.length}
                                                onCheck={() => onCheckAll(modules)}                                                
                                            />
                                        </th>
                                        <th>No</th>
                                        <th>Module Name</th>
                                        <th>Module Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <SortableModules
                                    onCheck={onCheck}
                                    isSortingEnabled={isSortingEnabled}
                                    questionIdsToCopyArr={questionIdsToCopyArr}
                                    setQuestionIdsToCopyArr={setQuestionIdsToCopyArr}
                                    onSortEnd={onModulesSortEnd}
                                    items={modules}
                                    history={history}
                                    match={match}
                                    removeModule={removeModule}
                                    showMarksList={(moduleId) => { setExamModuleId(moduleId) }}
                                    setEditingModuleDetails={setEditingModuleDetails}
                                />

                            </Table>
                            :
                            <h4 style={{ textAlign: 'center', marginTop: 10 }}>No modules added in this day</h4>
                    }
                </Row>
            </Container>

        </Aux>)
}

export default BatchDayModules
