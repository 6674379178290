import React, { useState } from 'react'
import { cloneDeep } from 'lodash';
import { Col, Row, Container, Button, Input } from 'reactstrap';

function EditPdfNotes({ moduleData, updateModuleData, history, updateModuleDataAndSave }) {

    const [pdfNoteLink, setPdfNoteLink] = useState(moduleData.content.noteUri)

    return (
        <Container>
            <Row>
                <Col md="12">
                    <Input
                        name="pdfNoteLink"
                        id="pdfNoteLink"
                        type="text"
                        style={{ marginBottom: 10 }}
                        value={pdfNoteLink}
                        placeholder="Enter PDF Note URI"
                        onChange={(e) => setPdfNoteLink(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Button
                        color="primary"
                        onClick={() => {
                            if (pdfNoteLink) {
                                const editingModuleData = cloneDeep(moduleData)
                                editingModuleData.content.noteUri = pdfNoteLink
                                updateModuleDataAndSave(editingModuleData)
                            } else {
                                alert("Enter a valid question Ids")
                            }
                        }}
                    >Submit</Button>
                </Col>
            </Row>
        </Container>
    )
}

export default EditPdfNotes
