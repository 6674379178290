import React from 'react'
import { Container, Row, Col, Table, Button } from 'reactstrap'
import axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment'
import Loader from '../Components/Loader'

class TrialUsers extends React.Component {
        
    state = {
        isLoading: true,
        usersList: [],
    }
    
    getTrailUsersDetails() {
        axios.get('/trialUsers')
            .then(res => {
                let trailUsersDetails = res.data.map(user => {
                    this.props.salesmen.forEach(salesman => {
                        if(user.recent.salesman === salesman._id) {
                            user.salesman = salesman.name
                        }
                    });
                    return user
                })
                this.setState({ usersList: trailUsersDetails, isLoading:false })
            })
            .catch(err => {
                console.log(err)
            })
    }

    endPremium(userId, mobile, name) {
        if (window.confirm(`Are you sure want to end trail for ${name} with mobile number ${mobile}` )) {
            this.setState({ isLoading: true }, () => {
                axios.post('/payment/endPremium', { userId })
                    .then(res => this.getTrailUsersDetails())
                    .catch(err => {
                        this.setState({ isLoading: false })
                        console.log(err)
                    })
            })
        }
    }

    componentDidMount() {
       this.getTrailUsersDetails()
    }

    render() {
        return(
            <>
                
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }}/>}

                <Container fluid style={{ padding: 40 }}>
                    <Row style={{ marginBottom: 40 }}>
                        <Col md='6'>
                            <h2>Premium Trial Users</h2>
                        </Col>
                    </Row>

                    <Row>
                        {this.state.usersList && this.state.usersList.length > 0 ?
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Phone Number</th>
                                            <th>Enabled Date</th>
                                            <th>Sales Agent</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.usersList.map((item, index) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{moment(item.recent.date).format('DD/MM/YYYY')}</td>
                                                        <td>{item.salesman}</td>
                                                        <td>
                                                            <Button 
                                                                outline 
                                                                onClick={() => this.endPremium(item._id, item.mobile, item.name) }
                                                                color='primary'
                                                            >
                                                                End Premium Trial
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                            :
                            !this.state.isLoading && Array.isArray(this.state.usersList) && this.state.usersList.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <h4 style={{ textAlign: 'center', marginTop: 50 }}>No Data Found</h4>
                                </div>
                            : null
                                
                        }
                    </Row>
                </Container>
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        salesmen: state.data.salesmen
    }
}

export default connect(mapStateToProps)(TrialUsers)