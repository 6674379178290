import React, { useEffect, useState } from 'react'
import { Container, Input, Label, Form, FormGroup, Button } from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loader from '../../Components/Loader'

function PopupManager() {

    const [isLoading, setIsLoading] = useState(true)

    const [imageLink, setImageLink] = useState(null)
    const [imageRoute, setImageRoute] = useState(null)

    useEffect(() => {
        axios.get('/blog/popup')
        .then((res) => {
            const { imageLink, imageRoute } = res.data
            setImageLink(imageLink)
            setImageRoute(imageRoute)
            setIsLoading(false)
        })
        .catch(err => {
            setIsLoading(false)
            console.log(err)
            alert("Server error, check console")
        })
    }, [])

    const saveData = () => {
        if ((imageLink && !imageRoute) || (!imageLink && imageRoute)) {
            alert("Please enter valid data for both or none")
            return true
        }

        setIsLoading(true)

        axios.post('/blog/popup', { imageLink, imageRoute })
        .then(() => {
            setIsLoading(false)
            toast("Popup modal data changed", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            })
        })
        .catch((err) => {
            setIsLoading(false)
            console.log(err)
            alert("Server error, check console")
        })

    }

    return (
        <>
            {isLoading && <Loader />}
            <Container style={{ marginTop: 50 }}>
                <Form>
                    <FormGroup>
                        <Label>Image URL</Label>
                        <Input
                            placeholder={"Image URL"}
                            type="text"
                            name={'imageLink'}
                            value={imageLink}
                            onChange={(e) => setImageLink(e.target.value)}
                            style={{ marginBottom: 20 }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Image Route Link</Label>
                        <Input
                            placeholder={"Image Route Link"}
                            type="text"
                            name={'imageRoute'}
                            value={imageRoute}
                            onChange={(e) => setImageRoute(e.target.value)}
                            style={{ marginBottom: 20 }}
                        />
                    </FormGroup>
                    <Button
                        outline
                        color="primary"
                        onClick={saveData}
                    >Save</Button>
                </Form>
            </Container>
        </>
    )
}

export default PopupManager
