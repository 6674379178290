import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Table, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import Loader from '../../Components/Loader'

function MostStudiedModules() {

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        Axios.get('/most-studied-modules')
            .then(res => {
                setData(res.data)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error, check console")
            })
    }

    return (
        <>
            {isLoading && <Loader />}
            <Container fluid>
                {
                    Array.isArray(data) && data.length > 0 ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Module Name</th>
                                    <th>Studied User Count</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                data.map((e, index) => (
                                    <tr key={e._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {e.moduleName}<br/>
                                            {e.moduleDescription ? <span style={{ fontSize: 14, color: 'grey' }}>{e.moduleDescription}</span> : <span></span>}
                                        </td>
                                        <td>{e.count}</td>
                                        <td>
                                            <Link
                                                to={`/module/${e._id}`}
                                                target="_blank"
                                            >
                                                <Button
                                                    outline
                                                    color="primary"
                                                >
                                                    <FontAwesomeIcon icon={faPen} />
                                                </Button>
                                            </Link>
                                            
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                        : null
                }

            </Container>
        </>
    )

}

export default MostStudiedModules
