import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react'
import {
    Col, Row, Container, Table, Button,
    Modal, ModalBody, ModalFooter,
    Input, ListGroupItem, ListGroup,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import TinyEditor from '../Components/TinyEditor';
import Loader from '../Components/Loader';
import Axios from 'axios';
const DEFAULT_DATA = {
    question: "",
    questionType: "text",
    answer: "",
    answerType: "text"
}

const contentTypes = {
    MATH: 'math',
    HTML: 'html',
    TEXT: 'text'
}

const QuestionModal = ({ isLoading, modalData, setModalData, saveModalData }) => {
    return (
        <Modal
            isOpen={true}
            size="lg"
            style={{ maxWidth: '90%' }}
        >
            {isLoading && <Loader />}

            <ModalBody>

                <ListGroup>
                    <ListGroupItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <h4>Enter Question</h4>
                            <UncontrolledDropdown style={{ margin: 10 }}>
                                <DropdownToggle caret color="primary">
                                    {modalData.questionType}
                                </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {
                                        ["text", "html", "math"].map((each, index) => {
                                            return (
                                                <DropdownItem
                                                    key={each}
                                                    value={each}
                                                    onClick={() => setModalData({ ...modalData, questionType: each })}
                                                >
                                                    {each}
                                                </DropdownItem>)
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>

                        {
                            modalData.questionType === contentTypes.TEXT ?
                                <Input
                                    placeholder="Question"
                                    type="textarea"
                                    rows={5}
                                    name="question"
                                    value={modalData.question || ''}
                                    onChange={(e) => setModalData({ ...modalData, question: e.target.value }) }
                                />
                                :
                                <TinyEditor
                                    height={(window.innerHeight * 70) / 300}
                                    content={modalData.question || ''}
                                    onHtmlChange={(newHtml) => setModalData({ ...modalData, question: newHtml }) }
                                />
                        }

                    </ListGroupItem>
                </ListGroup>

                <ListGroup style={{ marginTop: 20 }}>
                    <ListGroupItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <h4>Enter Answer</h4>
                            <UncontrolledDropdown style={{ margin: 10 }}>
                                <DropdownToggle caret color="primary">
                                    {modalData.answerType}
                                </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {
                                        ["text", "html", "math"].map((each, index) => {
                                            return (
                                                <DropdownItem
                                                    key={each}
                                                    value={each}
                                                    onClick={() => setModalData({ ...modalData,  answerType: each }) }
                                                >
                                                    {each}
                                                </DropdownItem>)
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>

                        {
                            modalData.answerType === contentTypes.TEXT ?
                                <Input
                                    placeholder="Answer"
                                    type="textarea"
                                    rows={15}
                                    name="answer"
                                    value={modalData.answer || ''}
                                    onChange={(e) => setModalData({ ...modalData, answer: e.target.value })}
                                />
                                :
                                <TinyEditor
                                    height={(window.innerHeight * 70) / 150}
                                    content={modalData.answer || ''}
                                    onHtmlChange={(newHtml) => setModalData({ ...modalData, answer: newHtml }) }
                                />
                        }

                    </ListGroupItem>
                </ListGroup>

            </ModalBody>

            <ModalFooter>
                <Col>
                    <Button
                        style={{ marginRight: '1rem' }}
                        color="primary"
                        onClick={() => saveModalData() }
                    >
                        Save
                    </Button>
                </Col>
                <Button
                    onClick={() => setModalData(null) }
                    style={{ marginRight: 10 }}
                    color="secondary"
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

function TextbookQuestions({ match }) {

    const [isLoading, setIsLoading] = useState(true)
    const [modalData, setModalData] = useState(null)
    const [questions, setQuestions] = useState(null)

    useEffect(() => {
        getQuestions()
    }, [])

    const getQuestions = () => {
        Axios.get('/descriptive-questions', { params: { sectionId: match.params.sectionId } })
        .then(res => {
            setIsLoading(false)
            setQuestions(res.data.questions)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            alert("Server error, check console")
        })
    }

    const saveModalData = () => {
        
        setIsLoading(true)

        Axios.post('/descriptive-questions', { modalData: { ...modalData, sectionId: match.params.sectionId } })
        .then(() => {
            getQuestions()
            setModalData(null)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            alert("Server error, check console")
        })
    }

    const deleteQuestion = (questionId) => {
        setIsLoading(true)

        Axios.delete('/descriptive-questions', { data: { questionId } })
        .then(() => {
            getQuestions()
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            alert("Server error, check console")
        })

    }

    return (
        <>

            {isLoading && <Loader />}

            {
                modalData != null ?
                    <QuestionModal
                        isLoading={isLoading}
                        modalData={modalData}
                        setModalData={setModalData}
                        saveModalData={saveModalData}
                    />
                    : null
            }

            <Container>
                <Row>
                    <Button
                        outline
                        color="primary"
                        style={{ marginTop: 50, marginBottom: 20 }}
                        onClick={() => setModalData(cloneDeep(DEFAULT_DATA))}
                    >Add New Question</Button>
                </Row>

                <Row>
                    {
                        Array.isArray(questions) && questions.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Index</th>
                                        <th>Question</th>
                                        <th>Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        questions.map((question, index) => (
                                            <tr
                                                key={question._id}
                                                style={{ backgroundColor: question.activeFlag ? 'white' : 'rgba(255,0,0,0.1)' }}
                                            >
                                                <td>{index + 1}</td>
                                                <td>{question.question}</td>
                                                <td>{question.questionType}</td>
                                                <td>
                                                    <Button
                                                        outline
                                                        color="primary"
                                                        onClick={() => setModalData(question)}
                                                    >
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </Button>
                                                    <Button
                                                        outline
                                                        style={{ marginLeft: "1rem" }}
                                                        color="danger"
                                                        onClick={() => deleteQuestion(question._id)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </Table>
                            :
                            !Array.isArray(questions) ?
                                <h4>No questions added</h4>
                                :
                                null
                    }
                </Row>

            </Container>
        </>
    )

}

export default TextbookQuestions
