import React, { useState, useEffect } from 'react'
import {
    Button, Input, Row, Col,
    Modal, ModalBody, ModalFooter, ModalHeader,
    ListGroup, ListGroupItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep } from 'lodash'
import { Link } from 'react-router-dom'

import { isValidObjectId } from '../utils';
import axios from 'axios';

function RelatedModulesModal({ showRelatedModulesModal, relatedModules: initialRelatedModules, saveRelatedModules, close }) {

    const [newModuleId, setNewModuleId] = useState(null)
    const [relatedModules, setRelatedModules] = useState([])
    const [moduleDetails, setModuleDetails] = useState([])

    useEffect(() => {
        if (Array.isArray(initialRelatedModules)) {
            setRelatedModules(initialRelatedModules)
        } else {
            setRelatedModules([])
        }
    }, [initialRelatedModules])

    const addRelatedModuleId = (moduleId) => {

        let foundIndex = relatedModules.findIndex((each) => {
            return each === moduleId
        })

        if (foundIndex >= 0) {
            alert("Module is already added")
        } else {
            if (isValidObjectId(moduleId)) {
                setRelatedModules([...relatedModules, moduleId])
            } else {
                alert("Please enter a valid module ID")
            }
        }

    }

    const removeRelatedModuleId = (index) => {
        const clonedRelatedModules = cloneDeep(relatedModules)
        clonedRelatedModules.splice(index, 1)
        setRelatedModules(clonedRelatedModules)
    }

    const refreshModuleNames = () => {
        axios.get('/learn/module-names', { params: { moduleIds: relatedModules } })
        .then(res => setModuleDetails(res.data.moduleData))
        .catch(err => {
            console.error(err)
            alert("Server error, check console")
        })
    }

    return (
        <Modal
            isOpen={showRelatedModulesModal}
        >
            
            <ModalHeader>Related Module Ids</ModalHeader>

            <ModalBody>
                <Row>
                    <Col md="10">
                        <Input
                            placeholder="Enter module Id"
                            name="moduleId"
                            type="text"
                            value={newModuleId || ''}
                            onChange={(e) => setNewModuleId(e.target.value)}
                        />
                    </Col>
                    <Col md="2">
                        <Button
                            color="primary"
                            onClick={() => {
                                addRelatedModuleId(newModuleId)
                                setNewModuleId(null)
                            }}
                        >
                            Add
                        </Button>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col md="12">
                    <ListGroup>
                        {
                            Array.isArray(relatedModules) && relatedModules.length > 0 ?
                                relatedModules.map((moduleId, index) => (
                                    <ListGroupItem
                                        key={moduleId}
                                        style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <div>
                                            <Link
                                                to={`/module/${moduleId}`}
                                                target="_blank"
                                            >
                                                {moduleId}
                                            </Link>
                                            {
                                                (moduleDetails && moduleDetails[moduleId]) ?
                                                    <p>{moduleDetails[moduleId]}</p>
                                                    : null
                                            }
                                        </div>

                                        <Button
                                            outline
                                            color="danger"
                                            onClick={() => removeRelatedModuleId(index)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    </ListGroupItem>
                                ))
                                :
                                <ListGroupItem>
                                    No related modules added
                                </ListGroupItem>
                        }

                    </ListGroup>
                    </Col>
                </Row>

                <Row style={{ marginTop: 20 }}>
                    <Col md="12">
                        <Button
                            color="primary"
                            onClick={refreshModuleNames}
                        >Refresh Module Names</Button>
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => saveRelatedModules(relatedModules)}
                >Save</Button>
                <Button
                    outline
                    onClick={close}
                    color="secondary"
                >Close</Button>
            </ModalFooter>
        </Modal>
    )
}

export default RelatedModulesModal
