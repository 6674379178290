import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    Container, Input, Button, Row, Col, Table,
    Modal, ModalBody, ModalHeader, ModalFooter,
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from '../Components/Loader'
import { copyToClipboard } from '../utils'
import { unstable_batchedUpdates } from 'react-dom';

const ROOT_FOLDER = "Root Folder"

function DocumentUpload() {

    const [isLoading, setIsLoading] = useState(true)
    const [prefix, setPrefix] = useState(ROOT_FOLDER)
    const [commonPrefixes, setCommonPrefixes] = useState([])
    const [contents, setContents] = useState([])
    const [showUploadModal, setShowUploadModal] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null)
    const [uploadedLocation, setUploadedLocation] = useState(null)

    useEffect(() => {
        getObjects()
    }, [prefix])

    const getObjects = () => {

        setIsLoading(true)

        let params = {}

        if (prefix !== ROOT_FOLDER) {
            params.prefix = prefix
        }
        
        axios.get('/documents', { params })
            .then(res => {
                setIsLoading(false)

                if (!params.prefix && Array.isArray(res.data.commonPrefixes) && res.data.commonPrefixes.length > 0) {
                    setCommonPrefixes(res.data.commonPrefixes)
                }

                setContents(res.data.contents)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
                alert("Server error. Check console")
            })

    }

    const onFileChange = event => {
        // Update the state
        setSelectedFile(event.target.files[0])
    }

    const onFileUpload = () => {

        setIsLoading(true)
        // Create an object of formData 
        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "myDocument",
            selectedFile,
            selectedFile.name,
        );

        let params = {}

        if (prefix !== ROOT_FOLDER) {
            params.prefix = prefix
        }

        axios.post("/upload-document", formData, { params })
            .then(res => {
                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setUploadedLocation(res.data.uploadLocation)
                    setShowUploadModal(false)
                    setSelectedFile(null)
                })

                getObjects()

            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Error while uploading document.")
            })
    };

    const fileData = () => {
        if (selectedFile) {
            return (
                <div>
                    <h5>File Details:</h5>
                    <p>File Name: {selectedFile.name}</p>
                    <p>File Type: {selectedFile.type}</p>
                    <p>
                        Last Modified:{" "}
                        {selectedFile.lastModifiedDate.toDateString()}
                    </p>
                </div>
            );
        }
    };

    return (
        <>

            { (isLoading && !showUploadModal) && <Loader /> }

            <Modal
                isOpen={showUploadModal}
            >
                
                {isLoading && <Loader />}

                <ModalHeader>
                    Chose file to upload
                </ModalHeader>

                <ModalBody>

                    <p>Uploading to: {prefix}</p>

                    <Input
                        style={{ marginBottom: 30 }}
                        type="file"
                        onChange={onFileChange}
                    />

                    {fileData()}

                </ModalBody>

                <ModalFooter>
                    <Button
                        color={selectedFile ? "primary" : "secondary"}
                        onClick={onFileUpload}
                        disabled={selectedFile ? false : true}
                    >
                        Upload
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={ () => {
                            setShowUploadModal(false)
                            setSelectedFile(null)
                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            <Container style={{ paddingTop: 50 }}>

                {
                    uploadedLocation ?
                        <Row>
                            <Col md="10">
                                <Link
                                    to={{ pathname: uploadedLocation }}
                                    target="_blank"
                                >

                                    <Button
                                        style={{ marginRight: 7, textAlign: 'left' }}
                                        outline
                                        color="link"
                                    >
                                        {uploadedLocation}
                                    </Button>
                                </Link>
                            </Col>
                            <Col md="2">
                                <Button
                                    block
                                    color="primary"
                                    onClick={() => {
                                        copyToClipboard(uploadedLocation)
                                        toast("Link copied", {
                                            position: toast.POSITION.TOP_CENTER,
                                            hideProgressBar: true
                                        })
                                    }}
                                >
                                    Copy Link
                                </Button>
                            </Col>
                            <hr style={{ marginTop: 40, marginBottom: 40 }} />
                        </Row>
                        : null
                }

                <Row>
                    <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                        {
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="primary">{prefix}</DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {
                                        [{ Prefix: ROOT_FOLDER }, ...commonPrefixes].map((each) => (
                                            <DropdownItem
                                                key={each.Prefix}
                                                value={each.Prefix}
                                                onClick={() => {
                                                    setPrefix(each.Prefix)
                                                }}
                                            >
                                                {each.Prefix}
                                            </DropdownItem>)
                                        )
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }

                        <Button
                            outline
                            color="primary"
                            onClick={() => setShowUploadModal(true)}
                        >
                            Upload Files
                        </Button>
                    </Col>

                    <Table>
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Filename</th>
                                <th>Last Modified</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                contents.map((eachItem, index) => (
                                    <tr key={eachItem.ETag}>
                                        <td>{index + 1}</td>
                                        <td>{eachItem.Key.split("/")[eachItem.Key.split("/").length - 1]}</td>
                                        <td>{moment(new Date(eachItem.LastModified)).format("DD MMMM YYYY, h a")}</td>
                                        <td>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => {

                                                    const string = `https://challenger-images.sgp1.cdn.digitaloceanspaces.com/${eachItem.Key}`
                                                    const uri = encodeURI(string)
                                                    copyToClipboard(uri)

                                                    toast("Link copied", {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true
                                                    })
                                                }}
                                            >
                                                Copy Link
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}

export default DocumentUpload
