import React, { Component } from 'react'
import axios from 'axios'
import { Button, Table, Container, Row, Col } from 'reactstrap';
import classes from './NameChange.css'
import Loader from './Loader';
import { toast } from 'react-toastify';

class NameChange extends Component {

    state = {
        nameChangeRequests: null,
        approveDialog: false,
        refuseDialog: false,
        isLoading: true
    }

    fetchChangeRequests() {
        axios.get('/name-change-requests')
            .then(res => {
                this.setState({ nameChangeRequests: res.data.data, isLoading: false })
            })
            .catch((err) => {
                console.error('/name-change-requests')
                console.error(err)
                this.setState({ isLoading: false })
                alert("Server error. Please check console")
            })
    }

    componentDidMount() {
        this.fetchChangeRequests()
    }

    renderRequests() {
        if (this.state.nameChangeRequests && this.state.nameChangeRequests.length > 0) {
            return (
                <Table>

                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Mobile</th>
                            <th>Games</th>
                            <th>Last Active</th>
                            <th>Old Name</th>
                            <th>New Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.nameChangeRequests.map((each, index) => {
                            return (
                                <tr key={each._id}>
                                    <td>{index + 1}</td>
                                    <td>{each.userData.mobile}</td>
                                    <td>{each.userData.scoreData.gamesWon + each.userData.scoreData.gamesLost + each.userData.scoreData.gamesTied}</td>
                                    <td>{new Date(each.userData.lastActivity).toLocaleDateString()}</td>
                                    <td>{each.oldName}</td>
                                    <td>{each.newName}</td>
                                    <td style={{ textAlign: 'right' }}>
                                        <Button outline color="success" style={{ marginRight: 10 }} onClick={() => this.approveNameChange(each, index)}
                                            className={classes.rowBtn} variant="outline-success">Approve</Button>
                                        <Button outline color="danger" onClick={() => this.refuseNameChange(each, index)} className={classes.rowBtn} variant="outline-danger">Reject</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            )
        } else {
            return (
                <div>
                    <h3 style={{ textAlign: 'center', alignSelf: 'center' }}>No Requests Found</h3>
                </div>
            )
        }
    }

    deleteItem = (index)=>{
        let arr = [...this.state.nameChangeRequests]
        arr.splice(index, 1)
        this.setState({nameChangeRequests: arr})
    }

    approveNameChange(each, index) {

        this.deleteItem(index);

        let data = {
            requestId: each._id,
            userId: each.userId,
            newName: each.newName
        }

        axios.post('/approve-name-change', data)
            .then(() => {
                this.setState({ approveDialog: false }, () => {
                    toast(`${each.oldName} changed to ${each.newName}`, {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true
                    })
                })
            })
            .catch(err => {
                alert("Server error approving name change. Please try again");
                console.error('/approve-name-change')
                console.error(err)
            })

    }

    refuseNameChange(each, index) {

        this.deleteItem(index)

        axios.post('/reject-name-change', { requestId: each._id })
            .then(() => {
                this.setState({ refuseDialog: false }, () => {
                    toast(`Refused changing ${each.oldName} to ${each.newName}`, {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true
                    })
                })
            })
            .catch(err => {
                alert("Server error rejecting name change. Please try again");
                console.error('/reject-name-change')
                console.error(err)
            })

    }

    render() {        
        return (
            <>
                {this.state.isLoading && <Loader />}
                <Container fluid>
                    <Row>
                        <Col style={{ paddingTop: 20 }}>{this.renderRequests()}</Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default NameChange