import React, { useState, useEffect } from 'react'
import { Container, Row, Table, Button } from 'reactstrap'
import Axios from 'axios'
import { unstable_batchedUpdates } from 'react-dom'
import FileDownload from 'js-file-download'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'

import Loader from '../../Components/Loader'
import Aux from '../../Components/Auxiliary'
import CustomModal from '../../Components/CustomModal'

function BatchStudents({ match, history }) {

    const [isLoading,  setIsLoading] = useState(true)
    const [students, setStudents] = useState(null)

    const [showAddNewStudentModal, setShowAddNewStudentModal] = useState(false)
    const [newStudentMobile, setNewStudentMobile] = useState('')

    const getStudentsList = () => {
        Axios.get('/batch/admin/students', { params: {
            productId: match.params.productId,
            groupId: match.params.groupId,
        } })
        .then(res => {
            unstable_batchedUpdates(() => {
                setStudents(res.data.students)
                setIsLoading(false)
            })
        })
        .catch(err => {
            console.log(err)
            alert("Error. Check console")
        })
    }

    const exportCompletionStats = () => {
        setIsLoading(true)
        Axios.get('/batch/admin/day-completion-report', { params: { groupId: match.params.groupId } })
            .then(res => {
                setIsLoading(false)
                FileDownload(res.data, 'report.csv')
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Please check console")
            })
    }

    const resetProgress = () => {
        Axios.post('/admin/learning-path/reset-progress', { studyGroupId: match.params.groupId })
        .then(() => getStudentsList())
        .catch(err => {
            console.log(err)
            alert("Server error. Check console")
        })
    }

    useEffect(() => {
        getStudentsList()
    }, [])

    const addStudent = () => {
        if (newStudentMobile && newStudentMobile.length > 7 && newStudentMobile.length <= 10) {
            Axios.post('/batch/admin/student', {
                userMobile: newStudentMobile,
                groupId: match.params.groupId,
            })
            .then(() => {
                getStudentsList()
                setShowAddNewStudentModal(false)
            })
            .catch(err => {
                if (err && err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message)
                } else {
                    alert("Error. Check console")
                }
            })

        } else {
            alert("Please enter a valid mobile number")
        }
    }

    const removeStudent = (userId) => {
        if (window.confirm("Remove this student from the batch?")) {
            Axios.delete('/batch/admin/student', { params: { userId, groupId: match.params.groupId } })
                .then(() => {
                    getStudentsList()
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error. Check console")
                    }
                })
        }
    }

    return (
        <Aux>
            
            { isLoading && <Loader /> }

            {
                showAddNewStudentModal &&
                <CustomModal
                    isOpen={showAddNewStudentModal}
                    fields={
                        [
                            {
                                fieldName: "newSubjectName",
                                value: newStudentMobile,
                                placeholder: "New Student Mobile Number",
                                type: 'number'
                            },
                        ]
                    }
                    onSubmit={() => addStudent()}
                    onCancel={() => {
                        unstable_batchedUpdates(() => {
                            setNewStudentMobile('')
                            setShowAddNewStudentModal(false)
                        })
                    }}
                    showEnterAllDataAlert={false}
                    handleInputChange={(e) => setNewStudentMobile(e.target.value)}
                />
            }

            <Container>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        color="primary"
                        style={{ margin: 15 }}
                        onClick={() => setShowAddNewStudentModal(true)}>
                        + ADD STUDENT
                    </Button>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            color="primary"
                            style={{ margin: 15 }}
                            onClick={() => exportCompletionStats()}>
                            Export Completion Stats
                        </Button>

                        <Button
                            color="danger"
                            style={{ margin: 15 }}
                            onClick={() => {
                                if (window.confirm("Do you want to reset this study group's study progress?")) {
                                    resetProgress()
                                }
                            }}>
                            Reset Progress
                        </Button>
                    </div>

                </div>

                <Row>
                    {
                        (Array.isArray(students) && students.length > 0) ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Percentage</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        students.map((each, index) => (
                                            <tr key={each._id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {each.name}
                                                    {each.premiumUser ? <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCrown} /> : null}
                                                </td>
                                                <td>{each.mobile}</td>
                                                <td>{each.modulesCompletedPercentage ? `${parseInt(each.modulesCompletedPercentage)}%` : '0%'}</td>
                                                <td>
                                                    <Button
                                                        outline
                                                        color="primary"
                                                        style={{ margin: 10 }}
                                                        onClick={() => history.push({ pathname: `/batch-study-progress/${match.params.groupId}/${each._id}` })}
                                                    >
                                                        Progress
                                                    </Button>
                                                    <Button
                                                        outline
                                                        color="danger"
                                                        style={{ margin: 10 }}
                                                        onClick={() => removeStudent(each._id) }
                                                    >
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </Table>
                            :
                            (Array.isArray(students) && students.length === 0) ?
                                <p style={{ marginTop: 100, textAlign: 'center' }}>No students joined</p>
                                : null
                    }
                </Row>
            </Container>
        </Aux>
    )

}

export default BatchStudents