import React from "react";
import CircularProgress from "material-ui/CircularProgress";
import axios from "axios";
import { Container, Col, Table, Row, DropdownToggle,DropdownMenu,
		DropdownItem,UncontrolledDropdown} from "reactstrap";

class ReportedChatDetails extends React.Component {

	state = {
		reportedDetails: null,
		isLoading: true,
		skip: 0,
		reportedDetailsCount: null
	};

	getreportedDetails() {
		axios.get("/report-chat", { params: { skip: this.state.skip * 50 } })
			.then((res) => {
				this.setState({
					reportedDetails: res.data.reportedChatDetails,
					reportedDetailsCount: res.data.totalReportedChatCount,
					isLoading: false
				});
				
			})
			.catch((err) => {
				console.log(err)
				this.setState({ isLoading: false });
			});
	}

	componentDidMount() {
		this.getreportedDetails()
	}

	renderSkipMenu = () => {
		let returnArray = [];
		let itemCount = parseInt(this.state.reportedDetailsCount, 10) / 50;

		for (let index = 0; index < itemCount; index++) {
			returnArray.push(
				<DropdownItem
					key={index}
					value={index}
					onClick={() =>
						this.setState({ skip: index, isLoading: true }, () =>
							this.getreportedDetails()
						)
					}
				>
					{index + 1}
				</ DropdownItem >
			)
		}
		return returnArray;
	};

	render() {
		return (
			<>
				< Container fluid style={{ padding: 40 }}>
					{this.state.isLoading && (
						<div style={{
							backgroundColor: "rgba(0, 0, 0, 0.6)",
							position: "absolute",
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
							paddingTop: "30%",
							paddingLeft: "47%",
							zIndex: 99,
						}}
						>
							<CircularProgress size={80} thickness={5} />
						</div>
					)}

					<Row style={{ marginTop: 50, marginBottom: 10, flex: 1, justifyContent: "flex-end" }}>
						<div>
							<UncontrolledDropdown>
								<DropdownToggle caret color="success" id="dropdown-basic">
									Page {this.state.skip + 1}
								</DropdownToggle>
								<DropdownMenu className="addScroll">
									{this.renderSkipMenu()}
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</Row>

					<Row>
						<Col>
							{this.state.reportedDetails && this.state.reportedDetails.length > 0 ? (
								<Table>
									<thead>
										<tr>
											<th>No.</th>
											<th>Got Reported</th>
											<th>Chat Text</th>
											<th>Reported By</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.reportedDetails.map((data, index) => {
												return (
													<tr key={data._id}>
														<td>{index + 1}</td>
														<td>{`${data.chatUser.name} (${data.chatUser.mobile})`}</td>
														<td>{data.chatText}</td>
														<td>{`${data.reportingUser.name} (${data.reportingUser.mobile})`}</td>
													</tr>
												)
											})
										}
									</tbody>
								</Table>
							)
								:
								null}
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default ReportedChatDetails
