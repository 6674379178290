import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Login from './Pages/Login/Login'
import Header from './Components/Header'
import Main from './Components/Main'
import axios from 'axios'
class App extends Component {

  constructor() {
    super()
    
    const userToken = localStorage.getItem('userToken')
    
    this.state = {
      isLoading: true,
      userToken,
      userData: localStorage.getItem('userData')
    }

    axios.defaults.headers.common['authorization'] = userToken

  }

  render() {
    if (this.state.userToken && this.state.userData) {
      return (
        <>
          <Header />
          <Main />
        </>
      )
    } else {
      return (
        <Route path="/" exact component={Login} />
      )
    }
  }
}

export default App