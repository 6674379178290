import React from "react";
import { useDropzone } from "react-dropzone";

const FileUploader = ({ endpoint, resourceName, resourceId, setImageToState }) => {
    
    const onDrop = React.useCallback(files => {
        let formData = new FormData();
        formData.append("file", files[0]);
        formData.append(`${resourceName}_id`, resourceId);

        //Pass image file to parent
        setImageToState(files[0])
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <div>
                    <p>Drop the files here ...</p>
                </div>
            ) : (
                    <p>Drag 'n' drop some files here, or click to select files</p>
                )}
        </div>
    );
};

export default FileUploader;
