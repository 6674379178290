import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons'
import { unstable_batchedUpdates } from 'react-dom'
import { copyToClipboard } from '../utils';
import {
    Container, Col, Row, Button, Input, Table, Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter,ListGroup,
    ListGroupItem, FormGroup, Label
} from 'reactstrap'
import { toast } from 'react-toastify'


function StatementQuiz({ history }) {

    const [isLoading, setIsLoading] = useState(false)
    const [modalQuizData, setModalQuizData] = useState(null)
    const [quizData, setQuizData] = useState(null)
    const [editQuizId, setEditQuizId] = useState(null)
    const [questionIdsToArr, setQuestionIdsToArr] = useState([])
    const [questionIds, setQuestionIds] = useState([])
    const [sections, setSections] = useState(null)

    const subjects =  useSelector(state => state.data.subjects)

    const fetchStatementQuizData = () => {
        setIsLoading(true)
        axios.get('/statement-quiz')
            .then(res => {
                setQuizData(res.data.quizData)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                alert("Error, Check Console")
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchStatementQuizData()
    }, [])

    const selectedSubjectOnChangeHandler = (e) => {
        const value = e.target.value

        if (value !== "All Subjects") {

            const foundSubject = subjects.find(e => e._id === value)

            if (foundSubject) {
                unstable_batchedUpdates(() => {
                    setModalQuizData({ ...modalQuizData, [e.target.name]: value })
                    setSections(foundSubject.sections)
                })
            } else {
                unstable_batchedUpdates(() => {
                    setModalQuizData({ ...modalQuizData, [e.target.name]: value })
                    setSections([])
                })
            }
        }
    }

    const setAnswerTextToQuestions = (questions) => {
        let updatedQuestions = questions.map(each => {
            each.options.forEach(option => {
                if (option.id === each.answer) {
                    each.answerText = option.text
                }
            })
            return each
        })

        return updatedQuestions
    }

    const navigateToViewQuestions = (quizId) => {
        let paramsObj = {
            statementQuizId: quizId,
        }
        history.push({ pathname: "/questions", state: paramsObj })
    }

    const fetchQuestionsAndSet = (questionIdsToArray) => {
        setIsLoading(true)
        axios.get('/questions', { params: { questionIds: questionIdsToArray }})
            .then(res => {
                let { questions } = res.data
                questions = setAnswerTextToQuestions(questions)
                setQuestionIdsToArr(questions)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                alert("Error, Check Console")
                setIsLoading(false)
            })
    }

    const addQuestionWithId = () => {
        if (questionIds.length > 0) {
            const questionIdsSplitted = questionIds.split("\n")
            const allItems = questionIdsSplitted.map(each => each.replace(/\s/g, ''))
            let questionIdsToArray = allItems.filter(each => each)

            if (questionIdsToArr.length > 0) {
                questionIdsToArray = [...new Set([...questionIdsToArray, ...modalQuizData.questions])]
            }
            
            fetchQuestionsAndSet(questionIdsToArray)
            unstable_batchedUpdates(() => {
                setModalQuizData({ ...modalQuizData, questions: questionIdsToArray })
                setQuestionIds([])
            })
        } else {
            alert("Please enter a valid question ID")
        }
    }

    const onSubmitEditQuiz = () => {
        
        if (!(modalQuizData.name && modalQuizData.name.trim()) || 
        !(modalQuizData.subjectId && modalQuizData.subjectId !== 'allSubjects') || 
        !(modalQuizData.sectionId && modalQuizData.sectionId !== 'allSections')) {
            alert("Please, fill all required field")
            return
        }
        modalQuizData.quizId = editQuizId   

        setIsLoading(true)
        axios.post('/statement-quiz/', modalQuizData)
            .then(() => {
                setModalQuizData(null)
                fetchStatementQuizData()
            })
            .catch(err => {
                console.log(err)
                alert("Error, Check Console")
                setIsLoading(false)
            })
    }

    const onSubmitQuiz = () => {
        if (!(modalQuizData.name && modalQuizData.name.trim()) || 
        !(modalQuizData.subjectId && modalQuizData.subjectId !== 'allSubjects') || 
        !(modalQuizData.sectionId && modalQuizData.sectionId !== 'allSections')) {
            alert("Please, fill all required field")
            return
        }

        setIsLoading(true)
        axios.put('/statement-quiz/', modalQuizData)
            .then(() => {
                setModalQuizData(null)
                fetchStatementQuizData()
            })
            .catch(err => {
                console.log(err)
                alert("Error, Check Console")
                setIsLoading(false)
            })
    }

    const deleteStatementQuiz = (quizId) => {
        axios.delete('/statement-quiz', { params: { quizId }})
            .then(() => {
                fetchStatementQuizData()
            })
            .catch(err => {
                console.log(err)
                alert("Error, Check Console")
            })
    }

    const setEditQuizData = (quizId) => {
        let foundIndex = quizData.findIndex(item => item._id === quizId)
        const { name, description, questions, sectionId, subjectId } = quizData[foundIndex]
        const foundSubject = subjects.find(e => e._id === subjectId)
        let setObj = { name, description, questions, sectionId, subjectId }
        if (questions && questions.length > 0) {
            fetchQuestionsAndSet(questions)
        }

        unstable_batchedUpdates(() => {
            setSections(foundSubject.sections)
            setModalQuizData(setObj)
            setEditQuizId(quizId)
        })

    }

    const removeQuestionWithId = (questionId) => {
        let filteredQuestions = modalQuizData.questions.filter(question => question !== questionId)
        let filteredQuestionIdsToArr = questionIdsToArr.filter(question => question._id !== questionId)

        unstable_batchedUpdates(() => {
            setModalQuizData({ ...modalQuizData, questions: filteredQuestions })
            setQuestionIdsToArr(filteredQuestionIdsToArr)
        })
    }

    const renderQuestionsList = ()  => {
        return (
            <ListGroup style={{ marginTop: 20 }}>
            {
            questionIdsToArr && questionIdsToArr.length > 0 ?
                questionIdsToArr.map((each, index) => {
                        return (
                            <ListGroupItem key={each._id}>
                                <Row >
                                    <Col md='9'>
                                        <p>{index + 1}. {each.question}</p>
                                        <p style={{ fontSize: 14, marginTop: 1, color: 'grey' }}>{each.answerText}</p>
                                    </Col>
                                    <Col md='1'>
                                        <Button
                                            outline
                                            onClick={() => { 
                                                copyToClipboard(each._id) 
                                                toast("ID copied", {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true
                                                    })
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCopy}
                                                className="iconButton"
                                                style={{ color: 'grey', marginLeft: 5, marginRight: 5 }}
                                            />
                                        </Button>
                                    </Col>
                                    <Col md='2'>
                                        <Button
                                            color='danger'
                                            outline
                                            style={{ marginRight: 5 }}
                                            onClick={() => removeQuestionWithId(each._id)}
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>

                            </ListGroupItem>

                        )
                    })
                    :
                    <ListGroupItem>
                        No questions added
                    </ListGroupItem>
            }
        </ListGroup>
        )
    }

    const renderTable = () => {
        if (quizData && quizData.length > 0) {
            return (
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Quiz Name</th>
                                    <th>Description</th>
                                    <th>Question Count</th>
                                    <th>created On</th>
                                    <th>Users Attended</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    quizData.map((item, index) => {
                                        return (
                                            <tr key={item._id} style={{ background: !item.activeFlag ? 'rgba(255,0,0,0.2)' : null }}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>{item.questions && item.questions.length}</td>
                                                {item.createdOn ?
                                                    <td>{moment(item.createdOn).utcOffset("+05:30").format('DD-MM-YYYY')}</td>
                                                    :
                                                    <td></td>
                                                }
                                                <td>{item.usersCount}</td>
                                                <td>
                                                    <Button
                                                        outline
                                                        color="primary"
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => { navigateToViewQuestions(item._id)}}
                                                    >
                                                        View Questions
                                                    </Button>
                                                    <Button
                                                        outline
                                                        color="primary"
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => setEditQuizData(item._id) }
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        outline
                                                        color="danger"
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => deleteStatementQuiz(item._id)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
            )
        } else if (quizData && quizData.length === 0) {
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <h4 style={{ textAlign: 'center', marginTop: 50 }}>No Data Found</h4>
                </div>
            )
        } else {
            return (
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%'}}>
                <Spinner color="primary"/>
             </div>   
            )
        }
    }

    return (
        <>
            {
                modalQuizData ?
                    <Modal
                        isOpen={modalQuizData != null}
                        size="lg"
                    >
                        <ModalHeader>
                            Enter Quiz Details
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="name">Quiz Name</Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            onChange={(e) => {
                                                setModalQuizData({ ...modalQuizData, [e.target.name]: e.target.value })
                                            }}
                                            value={modalQuizData.name || ''}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label for="description">Quiz Description</Label>
                                        <Input
                                            id="description"
                                            name="description"
                                            onChange={(e) => setModalQuizData({ ...modalQuizData, [e.target.name]: e.target.value })}
                                            value={modalQuizData.description || ''}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="subjectId">Select Subject</Label>
                                        <Input
                                            type="select"
                                            name={"subjectId"}
                                            id={"subjectId"}
                                            value={modalQuizData.subjectId || ''}
                                            onChange={(e) => selectedSubjectOnChangeHandler(e)}
                                        >
                                            {
                                                subjects ?
                                                    [
                                                        { _id: "allSubjects", name: "All Subjects" }, ...subjects].map((subject, index) => (
                                                            <option key={subject._id} value={subject._id}>{subject.name}</option>
                                                        ))
                                                    :
                                                    null
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="sectionId">Select Section</Label>
                                        <Input
                                            type="select"
                                            name={"sectionId"}
                                            id={"sectionId"}
                                            value={modalQuizData.sectionId || ''}
                                            onChange={(e) => setModalQuizData({ ...modalQuizData, [e.target.name]: e.target.value })}
                                        >
                                            {
                                                Array.isArray(sections) && sections.length > 0 ?
                                                    [{ _id: "allSections", name: "All Sections" }, ...sections].map(section => {
                                                        return (
                                                            <option
                                                                key={section._id}
                                                                value={section._id}
                                                            >
                                                                {section.name}
                                                            </option>
                                                        )
                                                    })
                                                    :
                                                    [{ _id: "allSections", name: "All Sections" }].map(section => {
                                                        return (
                                                            <option key={section._id} value={section._id}>
                                                                {section.name}
                                                            </option>
                                                        )
                                                    })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Input
                                            type="textarea"
                                            rows="5"
                                            name="newQuestionIdsArr"
                                            id="newQuestionIdsArr"
                                            value={questionIds || ''}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                setQuestionIds(value)
                                            }}
                                            placeholder={"One question ID per line please"}
                                        />

                                        <Button
                                            style={{ marginTop: '1rem' }}
                                            onClick={() => addQuestionWithId()}
                                            color="primary"
                                        >
                                            { isLoading ? <Spinner size="sm"/> : 'Add Questions' }
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            { renderQuestionsList() }
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (!editQuizId) {
                                        onSubmitQuiz()
                                    } else {
                                        onSubmitEditQuiz()
                                    }
                                } }
                            >
                                { isLoading ? <Spinner size="sm"/> : editQuizId ? "Submit Change" : "Submit" }
                            </Button>
                            <Button
                                outline
                                onClick={ () => {
                                    setModalQuizData(null)
                                    setQuestionIdsToArr([])
                                } }
                            >
                                Close
                            </Button>

                        </ModalFooter>

                    </Modal>
                    : null
            }

            <Container style={{ paddingTop: 50, marginLeft: 10, marginRight: 10 }} fluid>
                <Row style={{ marginBottom: 20 }}>
                    <Col md="9">
                        <h4>Statement Question Quizzes</h4>
                    </Col>
                    <Col md="3">
                        <Button color="primary" onClick={() => { 
                            unstable_batchedUpdates(() => {
                                setEditQuizId(null)
                                setModalQuizData({})
                            })
                        }}>
                            + Create New Quiz
                        </Button>
                    </Col>
                </Row>
                <Row>
                    { renderTable() }
                </Row>
            </Container>
        </>
    )
}

export default StatementQuiz
