import React, { useEffect, useState } from 'react'
import {
    Container, Row, Col, Table, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter,
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap'
import Axios from 'axios'
import { faPen, faTrash, faReply, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../Components/Loader'
import Aux from '../../Components/Auxiliary'
import CheckBox from 'material-ui/Checkbox';
import { useSelector, shallowEqual } from 'react-redux'
import { unstable_batchedUpdates } from 'react-dom'
import { connect } from 'react-redux'
import queryString from 'query-string';
import { copyToClipboard } from '../../utils'
import { toast } from 'react-toastify'

const AddOrEditDayModal = ({ dayName, updateDayName, submit, close }) => {
    return (
        <Modal fade={true} isOpen={true}>

            <ModalHeader>Enter day</ModalHeader>

            <ModalBody>
                <Input
                    placeholder="Enter Day"
                    type="number"
                    name="newDayName"
                    value={dayName || ''}
                    style={{ marginBottom: 20 }}
                    onChange={updateDayName}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={submit}
                    color="primary"
                >
                    Submit
                </Button>

                <Button onClick={close} outline>Close</Button>

            </ModalFooter>
        </Modal>
    )
}

const selectedBatchDetailsInterface = {
    daysToFetchBatchName: null,
    daysToFetchBatchId: null
}

function BatchDays({ history, match, roleIds, location}) {

    const [isLoading, setIsLoading] = useState(false)
    const [daysList, setDaysList] = useState(null)
    const [batchesList, setBatchesList] = useState(null)
    const [selectedBatchDetails, setSelectedBatchDetails] = useState(selectedBatchDetailsInterface)

    const [showAddDayModal, setShowAddDayModal] = useState(null)
    const [newDayName, setNewDayName] = useState('')

    const [editingDayDetails, setEditingDayDetails] = useState(null)

    const allPacks = useSelector(state => state.data.packs, shallowEqual)

    const getDaysData = (productId) => {

        setIsLoading(true)

        Axios.get('/batch/admin/days', { params: { productId } })
            .then(res => {
                unstable_batchedUpdates(() => {
                    setDaysList(res.data.batchDays)
                    setIsLoading(false)
                })
            })
            .catch(err => {
                console.log(err)
                alert("Error. Check console")
            })
    }

    const addDay = (productId) => {
        if (newDayName) {
            Axios.put('/batch/admin/day', { dayName: newDayName, productId })
                .then(() => {
                    setShowAddDayModal(false)
                    setNewDayName('')
                    getDaysData(selectedBatchDetails.daysToFetchBatchId)
                })
                .catch(err => {
                    console.log(err)
                    alert("Error, check console")
                })

        } else {
            alert("Please enter a valid day name")
        }
    }

    const editDay = () => {
        if (editingDayDetails && editingDayDetails.editingDayId && editingDayDetails.editingDayName) {
            Axios.post('/batch/admin/day', { dayName: editingDayDetails.editingDayName, editingDayId: editingDayDetails.editingDayId })
                .then(() => {
                    setEditingDayDetails(null)
                    getDaysData(selectedBatchDetails.daysToFetchBatchId)
                })
                .catch(err => {
                    console.log(err)
                    alert("Error, check console")
                })
        } else {
            alert("Please enter a valid day name")
        }
    }

    const deleteDay = (dayId) => {
        Axios.delete('/batch/admin/day', { params: { dayId } })
            .then(() => {
                getDaysData(selectedBatchDetails.daysToFetchBatchId)
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    const togglePremium = (dayId, newFlag) => {
        Axios.post('/batch/admin/day/toggle-premium', { dayId, newFlag })
            .then(res => getDaysData(selectedBatchDetails.daysToFetchBatchId))
            .catch(err => {
                console.log(err)
                alert("Error, please check console")
            })
    }

    useEffect(() => {
        // 1. Set all batches list to state
        const batches = allPacks.filter(each => each.crashType === "Batch")
        setBatchesList(batches)
    }, [allPacks])

    useEffect(() => {

        const { productId } = queryString.parse(location.search)
        const batch = allPacks.find(each => each._id === productId)

        if (selectedBatchDetails && selectedBatchDetails.daysToFetchBatchId) {
            
            getDaysData(selectedBatchDetails.daysToFetchBatchId)
            localStorage.setItem("selectedBatch", JSON.stringify(selectedBatchDetails))

        } else if (productId && batch) {
            
            setSelectedBatchDetails({ daysToFetchBatchId: productId, daysToFetchBatchName: batch.title })
            localStorage.setItem("selectedBatch", JSON.stringify(selectedBatchDetails))

        } else {

            const storedDetails = localStorage.getItem("selectedBatch")

            if (storedDetails) {

                const parsed = JSON.parse(storedDetails)
                setSelectedBatchDetails(parsed)
                getDaysData(parsed.daysToFetchBatchId)
                history.push({ pathname: `/batch-days`, search: `productId=${parsed.daysToFetchBatchId}` })

            } else if (allPacks[0]) {

                const firstPack = allPacks[0]
                setSelectedBatchDetails(firstPack)
                getDaysData(firstPack.daysToFetchBatchId)
                history.push({ pathname: `/batch-days`, search: `productId=${firstPack.productId}` })

            } else {
                setIsLoading(false)
            }

        }

    }, [selectedBatchDetails])

    return (
        <Aux>

            {isLoading && <Loader />}

            <Container style={{ paddingTop: 50 }}>

                {
                    showAddDayModal &&
                    <AddOrEditDayModal
                        dayName={newDayName}
                        updateDayName={(e) => setNewDayName((e.target.value))}
                        submit={() => addDay(selectedBatchDetails.daysToFetchBatchId)}
                        close={() => {
                            setShowAddDayModal(false)
                            setNewDayName('')
                        }}
                    />
                }

                {
                    (editingDayDetails && editingDayDetails.editingDayId && editingDayDetails.editingDayName != null) ?
                        <AddOrEditDayModal
                            dayName={editingDayDetails.editingDayName}
                            updateDayName={(e) => setEditingDayDetails({ ...editingDayDetails, editingDayName: e.target.value })}
                            submit={() => editDay(selectedBatchDetails.daysToFetchBatchId)}
                            close={() => setEditingDayDetails(null)}
                        />
                        : null
                }

                <Row style={{ marginTop: 10, marginBottom: 10, flex: 1, justifyContent: 'space-between' }}>
                    { (selectedBatchDetails && selectedBatchDetails.daysToFetchBatchName && selectedBatchDetails.daysToFetchBatchId) ?(
                        <>
                            <Row>
                                {
                                    (Array.isArray(batchesList) && batchesList.length > 0) &&
                                    <UncontrolledDropdown style={{ margin: 10 }}>
                                        <DropdownToggle caret color="primary"> {(selectedBatchDetails && selectedBatchDetails.daysToFetchBatchName && selectedBatchDetails.daysToFetchBatchId) ? selectedBatchDetails.daysToFetchBatchName : "Select Batch"}</DropdownToggle>
                                        <DropdownMenu className="addScroll">
                                            {
                                                batchesList.map((each) => {
                                                    return (
                                                        <DropdownItem
                                                            key={each._id}
                                                            value={each.title}
                                                            onClick={() => {
                                                                history.push({ pathname: `/batch-days`, search: `productId=${each._id}` })
                                                                setSelectedBatchDetails({ daysToFetchBatchId: each._id, daysToFetchBatchName: each.title })
                                                            }
                                                            }
                                                        >
                                                            {each.title}
                                                        </DropdownItem>)
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                            </Row>

                            <Button
                                color="primary"
                                style={{ margin: 10 }}
                                onClick={() => setShowAddDayModal(true)}>
                                + ADD DAY
                            </Button>
                        </>
                    ) : null}

                </Row>

                {
                    Array.isArray(daysList) && daysList.length > 0 &&
                    <>
                        <hr />
                        <Row>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Day Name</th>
                                            <th>Modules Count</th>
                                            <th>Premium</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            daysList.map(each => {
                                                return (
                                                    <tr
                                                        key={each._id}
                                                        style={{ background: !each.activeFlag ? 'rgba(255,0,0,0.2)' : null }}
                                                    >
                                                        <td>
                                                            {`Day ${each.day}`}
                                                            {
                                                                each.deletedQuestionsCount > 0 &&
                                                                <>
                                                                    <br />
                                                                    <span style={{ color: 'red', fontSize: 12 }}>
                                                                        {each.deletedQuestionsCount} deleted questions found
                                                                    </span>
                                                                </>
                                                            }
                                                        </td>
                                                        <td>{each.modulesCount}</td>
                                                        <td>
                                                            <CheckBox
                                                                style={{ marginTop: '10px' }}
                                                                checked={each.premiumFlag}
                                                                onClick={() => togglePremium(each._id, !each.premiumFlag)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button
                                                                style={{ marginRight: 10 }}
                                                                outline
                                                                color="primary"
                                                                onClick={() => history.push({ pathname: `/batch-day-modules/${each._id}` })}
                                                            >
                                                                MODULES
                                                            </Button>
                                                            <Button
                                                                style={{ marginRight: 10 }} outline color="primary"
                                                                onClick={() => {
                                                                    copyToClipboard(each._id)
                                                                    toast("ID copied", {
                                                                        position: toast.POSITION.TOP_CENTER,
                                                                        hideProgressBar: true
                                                                    })
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faCopy} />
                                                            </Button>

                                                            <Button
                                                                style={{ marginRight: 10 }} outline color="primary"
                                                                onClick={() => setEditingDayDetails({ editingDayId: each._id, editingDayName: each.day })}
                                                            >
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </Button>

                                                            <Button
                                                                outline
                                                                color="danger"
                                                                onClick={() => {
                                                                    if (window.confirm(`${each.activeFlag ? "Delete" : "Undelete"} this day?`)) {
                                                                        deleteDay(each._id)
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={each.activeFlag ? faTrash : faReply} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
            </Container>

        </Aux>
    )
}

const mapStateToProps = state => {
    return {
        roleIds: state.auth.roleIds
    }
}

export default connect(mapStateToProps)(BatchDays)
