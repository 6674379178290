import React from 'react'

import {
    Container, Row, Col, Table, Button, Input,
    ListGroup, ListGroupItem,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'

import { Dropdown } from 'react-bootstrap';
import Axios from 'axios'
import { DatePicker } from 'material-ui'
import CheckBox from 'material-ui/Checkbox';

import { LIVE_EXAM_URL } from '../config/env';
import Loader from '../Components/Loader'

class SocketExam extends React.Component {

    state = {
        isLoading: true,

        liveExams: null,
        questions: [],
        showNewExamModal: false,
        pageNumber:0,

        premiumFlag:false,
        showLeaderboard:true,

        examName: '',
        startingDate: new Date(),
        examHour: '20',
        examMinutes: '30'
        
    }

    componentDidMount() {
        this.getExams()
    }

    getExams() {
        Axios.get(`${LIVE_EXAM_URL}/socketexam/`, { params: { skipCount: this.state.pageNumber * 25 }})
            .then(res => {
                this.setState({ liveExams: res.data.liveExams, liveExamCount: res.data.examCount , isLoading: false })
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.log(err)
                alert("Error, check console")
            })
    }

    submitNewExam() {

        if (this.state.examName) {
            
            const { examName, questions, startingDate, examHour, examMinutes, examSection, premiumFlag, showLeaderboard, examTopics, examSubtitle, examType } = this.state

            let putObject = {
                description: examName,
                questions, startingDate, examHour, examMinutes,
                premiumFlag, showLeaderboard, examTopics, examSubtitle, examType
            }

            if (examSection) { putObject.examSection = examSection }

            Axios.put(`${LIVE_EXAM_URL}/socketexam/`, putObject)
                .then(res => {
                    this.getExams()
                    this.setState({ showNewExamModal: false, examName: false, isLoading: false })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error, check console")
                })
        } else {
            this.setState({isLoading: false})
            alert("Enter a vaid exam name")
        }
    }

    submitEditExam() {
        if (this.state.examName) {
            
            const { editingExamId, examName, questions, startingDate, examHour, examMinutes, examSection, premiumFlag, examSubtitle, examTopics, showLeaderboard, examType } = this.state
            
            Axios.post(`${LIVE_EXAM_URL}/socketexam/`, {
                examId: editingExamId,
                description: examName,
                examSection,
                questions,
                startingDate,
                examHour,
                examMinutes,
                premiumFlag,
                examSubtitle,
                examTopics,
                showLeaderboard,
                examType
            })
                .then(res => {
                    this.getExams()
                    this.setState({ showNewExamModal: false, examName: false, editingExamId: null, description: null, questions: [], isLoading: false })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error, check console")
                })
        } else {
            alert("Enter a vaid exam name")
        }
    }

    renderSkipMenu =()=> {
        let returnArray = []
        let itemCount = parseInt(this.state.liveExamCount) / 25
        
        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <Dropdown.Item
                    key={index}
                    value={index}
                    onClick={
                        ()=> this.setState({ pageNumber: index },
                        ()=>this.getExams())
                }>
                    {index + 1}
                </Dropdown.Item>)
        }

        return returnArray
    }

    deleteExam(examId, status) {
        Axios.delete(`${LIVE_EXAM_URL}/socketexam/`, { params: { examId, status } })
            .then(res => {
                this.setState({ isLoading: false })
                this.getExams()
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    fetchExamQuestions(examId, examName) {
        Axios.get(`${LIVE_EXAM_URL}/socketexam/questions`, { params: { examId } })
            .then(res => {
                this.setState({ examQuestions: res.data, examName, showExamQuestionsModal: true, isLoading: false })
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    handleChangeDate = (event, startingDate) => {
        this.setState({ startingDate });
    };

    render() {
        return (
            <>
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }}/>}
                <Container fluid style={{ padding: 50 }}>

                    <Modal isOpen={this.state.showNewExamModal} size="lg">

                        <ModalHeader>
                            Enter Exam Details
                        </ModalHeader>

                        <ModalBody>

                            <Input
                                placeholder="Enter Exam Name"
                                type="text"
                                name="examName"
                                value={this.state.examName || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Input
                                placeholder="Enter Exam Subtitle"
                                type="text"
                                name="examSubtitle"
                                value={this.state.examSubtitle || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Input
                                placeholder="Exam Topics"
                                type="textarea"
                                name="examTopics"
                                value={this.state.examTopics || ''}
                                onChange={this.handleInputChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Row>
                                <Col md="8">
                                    <Input
                                        placeholder="Enter Exam Section ID (Optional)"
                                        type="text"
                                        name="examSection"
                                        value={this.state.examSection || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                                <Col md="4">
                                        <Input
                                            type="select" name="select" id="questionType"
                                            value={this.state.examType}
                                            onChange={(e) => this.setState({ examType: e.target.value })}
                                        >
                                            <option value="topic">Topic Exam</option>
                                            <option value="path">Crash Course Path</option>
                                        </Input>
                                </Col>
                            </Row>


                            <CheckBox
                                label={'Premium Live Exam'}
                                style={{ marginTop: '20px' }}
                                checked={this.state.premiumFlag}
                                onClick={() => this.setState({ premiumFlag: !this.state.premiumFlag })}
                            />

                            <CheckBox
                                label={'Show Leaderboard After Each Question'}
                                style={{ marginTop: '20px' }}
                                checked={this.state.showLeaderboard}
                                onClick={() => this.setState({ showLeaderboard: !this.state.showLeaderboard })}
                            />

                            <Row style={{ marginTop: 20 }}>

                                <Col sm="12">
                                    <h6>Starting date and time</h6>
                                </Col>

                                <Col md="6">
                                    <DatePicker
                                        style={{ paddingLeft: 30, paddingRight: 30 }}
                                        container="inline"
                                        hintText="Exam Starting Date"
                                        value={this.state.startingDate}
                                        onChange={this.handleChangeDate}
                                        defaultDate={this.state.startingDate}
                                    />
                                </Col>
                                <Col md="3">
                                    <Input
                                        placeholder="Hour (0-23)"
                                        type="number"
                                        max={23}
                                        min={0}
                                        name="examHour"
                                        value={this.state.examHour || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                                <Col md="3">
                                    <Input
                                        placeholder="Minutes (0-59)"
                                        type="number"
                                        max={59}
                                        min={0}
                                        name="examMinutes"
                                        value={this.state.examMinutes || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <h6>Questions</h6>

                            <Row>
                                <Col md="10">

                                    <Input
                                        placeholder="New Question ID"
                                        type="text"
                                        name="newQuestionId"
                                        value={this.state.newQuestionId || ''}
                                        onChange={this.handleInputChange}
                                        style={{ marginBottom: 10 }}
                                    />
                                </Col>
                                <Col md="2">
                                    <Button
                                        block
                                        outline
                                        color="primary"
                                        onClick={() => {
                                            if (this.state.newQuestionId) {
                                                this.setState((oldState) => {
                                                    if (!oldState.questions.includes(this.state.newQuestionId)) {
                                                        oldState.questions.push(this.state.newQuestionId.trim())
                                                    } else {
                                                        alert("Question ID already exists")
                                                    }
                                                    return { questions: oldState.questions, newQuestionId: null }
                                                })
                                            } else {
                                                alert("Please enter a valid quesiton ID")
                                            }
                                        }}
                                    >Add</Button>
                                </Col>
                            </Row>

                            <ListGroup style={{ marginTop: 20 }}>
                                {
                                    this.state.questions.map((each, index) => {
                                        return (
                                            <ListGroupItem key={each}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <p>{index + 1}. {each}</p>
                                                    <Button outline onClick={() => {
                                                        this.setState((oldState) => {
                                                            oldState.questions.splice(index, 1)
                                                            return { questions: oldState.questions }
                                                        })
                                                    }}>Remove</Button>
                                                </div>
                                            </ListGroupItem>
                                        )
                                    })
                                }
                            </ListGroup>
                        </ModalBody>

                        <ModalFooter>

                            <Button
                                outline
                                color="primary"
                                onClick={() => {
                                    if (this.state.editingExamId) {
                                        this.submitEditExam()
                                        this.setState({isLoading: true})
                                    } else {
                                        this.submitNewExam()
                                        this.setState({isLoading: true})
                                    }
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                outline
                                onClick={() => this.setState({ showNewExamModal: false })}
                            >
                                Cancel
                            </Button>

                        </ModalFooter>

                    </Modal>

                    <Modal isOpen={this.state.showExamQuestionsModal} size="lg">

                        <ModalHeader>
                            Questions in {this.state.examName}
                        </ModalHeader>

                        <ModalBody>

                            <ListGroup style={{ marginTop: 20 }}>
                                {
                                    this.state.examQuestions && this.state.examQuestions.length > 0 ?
                                        this.state.examQuestions.map((each, index) => {
                                            return (
                                                <ListGroupItem key={each._id}>
                                                    <p>{index + 1}. {each.question}</p>
                                                </ListGroupItem>
                                            )
                                        })
                                        :
                                        <ListGroupItem>
                                            No questions added
                                        </ListGroupItem>
                                }
                            </ListGroup>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                outline
                                onClick={() => this.setState({ showExamQuestionsModal: false })}
                            >
                                Cancel
                            </Button>

                        </ModalFooter>

                    </Modal>

                    <Row style={{ marginBottom: 40 }}>
                        <Col md="6">
                            <h2>
                                Live Exam
                             </h2>
                        </Col>
                        <Col md="4" style={{ flex: 1, justifyContent: 'flex-end' }}>
                            <Button
                                block
                                outline
                                color="primary"
                                onClick={() => this.setState({
                                    editingExamId: null,
                                    examName: null,
                                    examSection: null,
                                    examHour: null,
                                    examMinutes: null,
                                    questions: [],
                                    examTopics: null,
                                    examSubtitle: null,
                                    examType:"path",
                                    showNewExamModal: true,
                                    showLeaderboard: true,
                                    premiumFlag: false
                                })}
                            >
                                + Create New Live Exam
                            </Button>
                        </Col>
                        <Col md="2">
                            <Dropdown>
                                <Dropdown.Toggle block variant="success" id="dropdown-basic"> Page {this.state.pageNumber + 1} </Dropdown.Toggle>
                                <Dropdown.Menu className="addScroll">
                                    {this.renderSkipMenu()}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <Row>
                        {
                            this.state.liveExams && this.state.liveExams.length > 0 ?
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Exam Description</th>
                                                <th>Questions Count</th>
                                                <th>Type</th>
                                                <th>Attended Users</th>
                                                <th>Created On</th>
                                                <th>Starting Time</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.liveExams.map((exam) => {

                                                    return (
                                                        <tr key={exam._id} style={{ background: exam.active ? 'white' : 'rgba(255,0,0,0.1)' }}>
                                                            <td>{exam.description}</td>
                                                            <td>{exam.questions.length}</td>
                                                            <td>{exam.examType === "path" ? "Crash Path" : "Topic" }</td>
                                                            <td>{exam.examResults.length}</td>
                                                            <td>{new Date(exam.createdOn).toLocaleDateString()}</td>
                                                            <td>{new Date(exam.startingTime).toLocaleString("en-IN")}</td>
                                                            <td>
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => this.fetchExamQuestions(exam._id, exam.description)}
                                                                >View Questions</Button>
                                                                <Button
                                                                    outline
                                                                    color="primary"
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => this.setState({
                                                                        editingExamId: exam._id,
                                                                        examName: exam.description,
                                                                        questions: exam.questions,
                                                                        examSection: exam.examSection,
                                                                        examType: exam.examType || "path",
                                                                        startingDate: new Date(exam.startingTime),
                                                                        examHour: `${new Date(exam.startingTime).getHours()}`,
                                                                        examMinutes: `${new Date(exam.startingTime).getMinutes()}`,
                                                                        showNewExamModal: true,
                                                                        premiumFlag: exam.premiumFlag,
                                                                        showLeaderboard: exam.showLeaderboard,
                                                                        examTopics: exam.examTopics,
                                                                        examSubtitle: exam.examSubtitle,
                                                                    })}
                                                                >Edit</Button>
                                                                <Button outline color="danger" onClick={() => this.deleteExam(exam._id, !exam.active)}>{exam.active ? "Delete" : "Undelete"}</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                :
                                Array.isArray(this.state.liveExams) && this.state.liveExams.length === 0 ?
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <h4 style={{ textAlign: 'center', marginTop: 50 }}>No live exams found</h4>
                                    </div>
                                : null
                        }

                    </Row>
                </Container>
            </>
        )
    }

}

export default SocketExam