import React, { Component } from 'react'
import axios from 'axios'
import { Table, DropdownItem, DropdownToggle, DropdownMenu ,UncontrolledDropdown} from 'reactstrap';

class Analytics extends Component {

    state = { userCount: [], totalItemCount: 0, skipCount: 0 }

    componentDidMount() {
        this.fetchUserCount()
    }

    fetchUserCount() {
        axios.get(`/usersCount`, { params: { skip: this.state.skipCount * 30 } })
            .then(response => {
                let setData = { userCount: response.data.userAnalytics }
                if (response.data.totalItemCount) { setData.totalItemCount = response.data.totalItemCount }
                this.setState(setData)
            })
            .catch(err => console.log(err))
    }

    renderSkipMenu = () => {
        let returnArray = []
        let itemCount = parseInt(this.state.totalItemCount, 10) / 30

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => this.setState({ skipCount: index }, () => this.fetchUserCount())} >
                    {index + 1}
                </DropdownItem>)
        }
        return returnArray
    }

    render() {
        return (
            <div>
                <div style={{ padding: 25 }}>
                    {
                        this.state.userCount && this.state.userCount.length > 0 ?
                            <>

                                <div>
                                <UncontrolledDropdown>
                                        <DropdownToggle caret color="success" id="dropdown-basic"> Page {this.state.skipCount + 1} </DropdownToggle>
                                        <DropdownMenu className="addScroll">

                                            {this.renderSkipMenu()}
                                        </DropdownMenu>
                                        </UncontrolledDropdown>
                                </div>

                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Total Users</th>
                                            <th>Users Joined</th>
                                            {/* <th>Active Today</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.userCount.map((item, index) => {
                                                if (this.state.userCount[index + 1]) {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{new Date(item.date).toDateString()}</td>
                                                            <td>{item.userCount}</td>
                                                            <td>{item.userCount - this.state.userCount[index + 1].userCount}</td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </>
                            :
                            <h3 style={{ textAlign: 'center' }}>Loading...</h3>
                    }
                </div>
            </div>
        )
    }
}

export default Analytics