import React from 'react'
import classes from  '../Pages/ViewQuestions/ViewQuestions.css'
import { Button, UncontrolledTooltip, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faCopy, faImages } from '@fortawesome/free-solid-svg-icons'
import { isContentReviewerOrHigher } from '../utils/dashboardRoles';
import Aux from '../Components/Auxiliary'
import moment from 'moment';
import { Checkbox } from 'material-ui'
import { copyToClipboard } from '../utils';
import { isFinite } from 'lodash';
import MathQuestionViewer from './MathQuestionViewer';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';

const QuestionsTable = (props) => {

    const checkAndReturnParams = (index, questionId) => {
        
        let propsObj = {
            index,
            backRoute: props.backRoute,
            skipCount: parseInt(props.skipCount) * 50,
            addedBy: props.addedBy,
            sortBy: props.sortBy,
            editedBy: props.editedBy,
            topicId: props.topicId,
            period: props.period,
            unitId: props.unitId,
            searchText: props.searchText,
            questionId: questionId,
            dailyChallengeId: props.dailyChallengeId,
            currentAffairsPeriod: props.currentAffairsPeriod,
            issueType: props.issueType,
            subjectId: props.subjectId,
            sectionId: props.sectionId,
            tagId: props.tagId,
            activeFlag: props.activeFlag,
            showInChallenge: props.showInChallenge,
            questionStatus: props.questionStatus,
            questionType: props.questionType,
            languageId: props.languageId
        }

        let params = ``

        Object.keys(propsObj).map(eachKey => {
            if (propsObj[eachKey] || isFinite(propsObj[eachKey])) {
                params = `${params}&${eachKey}=${propsObj[eachKey]}` 
            }
        })
        return params
    }

    return (
        <Table>
            <thead>
                <tr>
                    {
                        Array.isArray(props.questionIdsArr) &&
                        <th>
                            <div style={{ display: 'flex' }}>
                                <Checkbox 
                                    onCheck={(e, value) => props.checkAllQuestions(e, value) } 
                                    checked={props.questionIdsArr.length === props.questionsData.questions.length}    
                                />
                            </div>
                        </th>
                    }
                    <th className={classes.fivePercent}>No.</th>
                    <th className={classes.fortyPercent}>Question</th>
                    <th className={classes.tenPercent}>A</th>
                    <th className={classes.tenPercent}>B</th>
                    <th className={classes.tenPercent}>C</th>
                    <th className={classes.tenPercent}>D</th>
                    {props.additionalFieldTitle !== undefined ? <th>{props.additionalFieldTitle}</th> : null}
                    <th className={classes.tenPercent}>Actions</th>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(props.questionsData.questions) && props.questionsData.questions.map((item, index) => {
                    return (
                        <Aux key={item._id}>

                            <tr style={{ background: !item.activeFlag ? 'rgba(255,0,0,0.2)' : null }} >

                                {
                                    Array.isArray(props.questionIdsArr) &&
                                    <td>
                                        <Checkbox
                                            checked={props.questionIdsArr.includes(item._id)}
                                            onCheck={(e, value) => props.addQuestionIdsToCopyArr(item._id, value)}
                                        />
                                    </td>
                                }

                                <td>
                                    <p style={{ textAlign: 'center'}}>{index + 1}</p>
                                </td>

                                <td
                                    style={{ fontSize: 15 }}
                                    className={classes.fortyPercent}
                                >
                                    {
                                        (item.type === 'html' || item.type === 'statement' || item.type === 'match') ?
                                            <div dangerouslySetInnerHTML={{ __html: item.question.split("rgb(255,255,255)").join("rgb(0,0,0)") }} />
                                            :
                                            item.type === 'math' ?
                                                <MathQuestionViewer
                                                    delimiter={"$"}
                                                    mathText={item.question}
                                                />
                                                : item.question
                                    }

                                    {
                                        item.description ?
                                            <>
                                                <UncontrolledTooltip
                                                    placement="right"
                                                    target={`id_${index}`}
                                                >
                                                    {item.description}
                                                </UncontrolledTooltip>
                                                <span
                                                    href="#"
                                                    id={`id_${index}`}
                                                    style={{textDecoration: "underline", color:"blue"}}
                                                >{` ${"Explanation"}`}</span>
                                            </>
                                            :
                                            null
                                    }
                                    <br />
                                    {
                                        (item.subjectName && item.sectionName) ?
                                        <>
                                                <span style={{ fontSize: 12 }}>{item.subjectName}{item.subtitle ? ` (${item.subtitle})` : null} - {item.sectionName}</span>
                                            <br />
                                        </>
                                        : null
                                    }

                                    {
                                        (props.backRoute !== 'reports' && Array.isArray(item.examNames)) && 
                                        <>
                                            <hr style={{ height: 2, color: 'grey', width: '100%' }} />
                                            {
                                                item.examNames.map(eachItem => {
                                                    return (
                                                        <React.Fragment key={eachItem}>
                                                            <span style={{ fontSize: 10 }}>{eachItem}</span><br />
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </>
                                        
                                    }

                                    

                                    {
                                        (props.backRoute !== 'reports' && Array.isArray(item.pathNames)) && item.pathNames.map((eachItem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <span style={{ fontSize: 10 }}>{eachItem}</span><br />
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </td>

                                {
                                    Array.isArray(item.options) && item.options.map((option) => (
                                        <td
                                            key={option.id}
                                            className={classes.tenPercent}
                                            style={{
                                                color: option.id === item.answer ? 'green' : 'grey',
                                                fontWeight: option.id === item.answer ? 'bold' : null,
                                            }}
                                        >
                                            {option.text}
                                        </td>
                                    ))
                                }

                                {props.additionalFieldProperty ? <td>{item[props.additionalFieldProperty]}</td> : null}

                                <td className={classes.tenPercent}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems: 'center' }}>

                                        <Button
                                            outline
                                            color="secondary"
                                            style={{ marginRight: 10 }}
                                            onClick={() => {
                                                copyToClipboard(item._id)
                                                toast("ID copied", {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true
                                                    })
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </Button>

                                        {props.dailyChallengeId ?
                                            <FontAwesomeIcon
                                                className="iconButton"
                                                onClick={() => props.history.push({
                                                    pathname: "/image",
                                                    state: {
                                                        question: item.question,
                                                        options: item.options,
                                                        answer: item.answer
                                                    }
                                                })
                                                }
                                                style={{ color: 'grey', marginLeft: 5, marginRight: 5 }}
                                                icon={faImages}
                                            />
                                            : null
                                        }

                                        {
                                            props.openEditPageInNewTab ?
                                                <Link
                                                    target="_blank"
                                                    to={{
                                                        pathname: "/questions/edit",
                                                        search: checkAndReturnParams(index, item._id)
                                                    }}
                                                >
                                                    <Button
                                                        outline
                                                        color="primary"
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            icon={faPen}
                                                        />
                                                    </Button>
                                                </Link>
                                                :
                                                <Button
                                                    style={{ marginRight: 10 }} outline color="primary"
                                                    onClick={() => props.history.push({
                                                        pathname: "/questions/edit",
                                                        search: checkAndReturnParams(index, item._id),
                                                    })}
                                                >
                                                    <FontAwesomeIcon
                                                        className="iconButton"
                                                        icon={faPen}
                                                    />
                                                </Button>
                                        }

                                        {
                                            isContentReviewerOrHigher(props.roleIds) ?
                                                props.customActionButton ?
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Button color='link' onClick={() => props.customActionButton(item, 'questionDeleted')}>Deleted</Button>
                                                        <Button color='link' onClick={() => props.customActionButton(item, 'corrected')}>Corrected</Button>
                                                        <Button color='link' onClick={() => props.customActionButton(item, 'alreadyCorrect')}>Already Correct</Button>
                                                    </div>
                                                    :
                                                    <Button
                                                        style={{ marginRight: 10 }}
                                                        outline
                                                        color="danger"
                                                        onClick={() => props.deleteButtonAction(item, index)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                : null
                                        }
                                    </div>

                                </td>


                            </tr>

                            {
                                (props.backRoute === 'reports' && Array.isArray(item.userExplanation) && item.userExplanation.length > 0) &&
                                <tr>
                                    <td colSpan={1}></td>
                                    <td colSpan={9}>
                                        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: 10 }}>
                                            <Button outline color='primary' onClick={() => props.getUser(index)}>Get Users</Button>
                                        </div>

                                        {
                                            item.userExplanation.map(each => {

                                                if (item.usersDetails) {
                                                    item.usersDetails.forEach(item => {
                                                        if (item._id === each.userId) {
                                                            each.name = item.name
                                                            each.mobileNumber = item.mobile
                                                        }
                                                    })
                                                }

                                                return (
                                                    <div key={each._id} style={{ display: "flex", justifyContent: 'space-between' }}>

                                                        <div>{each.explanation}</div>

                                                        <div>
                                                            {each.userReportedDate ? <div>Date: {moment(each.userReportedDate).format("D MMM YYYY, h:mm:ss a")}</div> : null}
                                                            <br />
                                                            {item.usersDetails ?
                                                                <>
                                                                    Name: <strong>{each.name}</strong>
                                                                    <br />
                                                                    Mobile: <strong>{each.mobileNumber}</strong>
                                                                </>
                                                                :
                                                                null}
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </td>
                                </tr>
                            }

                        </Aux>
                    )
                })}
            </tbody>
        </Table>
    )
}

export { QuestionsTable }