import React from 'react'
import { Row, Col, FormGroup, Input, ListGroupItem } from 'reactstrap';
import { cloneDeep } from 'lodash';

import MathQuestionViewer from './MathQuestionViewer';
import TinyEditor from './TinyEditor';

class ExplanationEditor extends React.Component {

    handleChange = (e) => {
        let description = cloneDeep(this.props.description)
        description = e.target.value
        this.props.updateDescriptionState(description)
    }

    render() {

        return (
            <Row>
                <Col md="10">
                    <Row>
                        <Col md="12">
                            {
                                (this.props.explanationType === "text" || !this.props.explanationType) ?
                                    <FormGroup>
                                        <Input
                                            id={"5"}
                                            placeholder="Description (Required)" rows="4" type="textarea"
                                            onChange={(e) => this.props.updateDescriptionState(e.target.value)}
                                            value={this.props.description || ''}
                                            name="description"
                                        />
                                    </FormGroup>
                                    :
                                    <TinyEditor
                                        contentCss={'dark'}
                                        content={this.props.description}
                                        onHtmlChange={(newHtml) => this.props.updateDescriptionState(newHtml)}
                                    />
                            }
                        </Col>
                    </Row>

                    {
                        (this.props.explanationType === "math") ?
                            <Row>
                                <Col md="12">
                                    <ListGroupItem>
                                        <MathQuestionViewer
                                            delimiter={"$"}
                                            mathText={this.props.description || ''}
                                        />
                                    </ListGroupItem>
                                </Col>
                            </Row> : null
                    }
                </Col>

                <Col md="2">
                    <FormGroup tag="fieldset">
                        <Input
                            type="select"
                            name="select"
                            id="explanationTypeSelect"
                            value={this.props.explanationType}
                            onChange={(e) => { this.props.updateExplanationType(e.target.value) }}
                        >
                            <option value="text">Text</option>
                            <option value="html">HTML</option>
                            <option value="math">Math</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

export default ExplanationEditor
