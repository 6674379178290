import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Table, Row, Col, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { SITE_URL } from '../utils/constants'
import Loader from '../Components/Loader'

let alreadyExamCreatedPosts = []

function ExamPostPages() {

    const [isLoading, setIsLoading] = useState(true)
    const [postWebsites, setPostWebsites] = useState([])

    const [showPostPickerModal, setShowPostPickerModal] = useState(false)
    const [selectedPostId, setSelectedPostId] = useState('')

    const posts = useSelector(state => state.data.posts)

    const getPages = () => {
        setIsLoading(true)

        Axios.get('/blog/exam-post-pages')
            .then(res => {
                setIsLoading(false)
                setPostWebsites(res.data.pageCreatedPosts)
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })

    }

    useEffect(() => {
        getPages()
    }, [])

    return (
        <>
            {isLoading && <Loader />}

            <Modal
                isOpen={showPostPickerModal}
            >
                <ModalHeader>
                    Select Post
                </ModalHeader>

                <ModalBody>
                    <Input
                        type="select"
                        name="postSelect"
                        id="postSelect"
                        value={selectedPostId}
                        onChange={(e) => setSelectedPostId(e.target.value) }
                    >
                        {posts.map(post => (
                            <option
                                key={post._id}
                                value={post._id}
                                disabled={alreadyExamCreatedPosts.includes(post._id)}
                            >{post.name}</option>))}
                    </Input>
                </ModalBody>

                <ModalFooter>
                    <Link to={`/post-website-editor?postId=${selectedPostId}`}>
                        <Button color="primary">CREATE</Button>
                    </Link>

                    <Button
                        outline
                        onClick={() => setShowPostPickerModal(false)}
                    >CLOSE</Button>
                </ModalFooter>
            </Modal>

            <Container>

                <Row>
                    <Button
                        outline
                        color="primary"
                        style={{ marginTop: 20, marginBottom: 20 }}
                        onClick={() => {
                            alreadyExamCreatedPosts = postWebsites.map(post => post._id)
                            setShowPostPickerModal(true)
                        }}
                    >
                        Create New Page
                    </Button>
                </Row>

                <Row>
                    <Col md="12">
                        <Table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Post Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    postWebsites.map((post, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{post.name}</td>
                                            <td>
                                                <a
                                                    target='_blank'
                                                    rel="noopener noreferrer"
                                                    href={`${SITE_URL}/exams/${post.websiteEndPoint}`}
                                                >
                                                <Button
                                                    outline
                                                    color="primary"
                                                >
                                                    Open website
                                                </Button>
                                                </a>
                                                <Link to={`/post-website-editor?postId=${post._id}`}>
                                                    <Button
                                                        outline
                                                        color="primary"
                                                        style={{ marginLeft: "1rem" }}
                                                    >
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </Button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ExamPostPages
