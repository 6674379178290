import React from 'react'
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import Axios from 'axios';
import { connect } from 'react-redux'
import { updateTags } from '../actions'
import { Container, Table, Button } from 'reactstrap';
import CustomModal from './CustomModal'

class Tags extends React.Component {

    state = { tagName:'', formErrors: { tagName:'' }, tagNameValid: false,
    editDialog:false, deleteDialog:false, showDialog:false, tagNewName:'', showEnterAllDataAlert:false }

    getTags = () => {
        Axios.get('/tags')
        .then(response => this.props.updateTags(response.data.data))
        .catch(err => console.log(err))
    }

    handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value }, 
            () => { this.validateField(name, value) })
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors
        let tagNameValid = this.state.tagNameValid

        tagNameValid = value !== '' || null
        fieldValidationErrors.tagName = tagNameValid ? '' : 'Enter a tag name'

        this.setState({ formErrors:fieldValidationErrors, tagNameValid })
    }

    addNewTag = () => {
        if(this.state.tagName) {
            Axios.post('/tags/add', { tagName:this.state.tagName })
            .then(res => {
                this.getTags()
                this.setState({ tagName:'', showEnterAllDataAlert:false, showAddTagModal:false })
            })
            .catch(err => console.log(err))
        } else {
            this.setState({ showEnterAllDataAlert:true })
        }
    }

    editTag = () => {
        Axios.post('/tags/edit', { tagName:this.state.tagNewName, tagId:this.state.editItem._id })
        .then(response => this.setState({ editDialog:false, deleteDialog:false, showDialog:false }, this.getTags() ) )
        .catch(err => console.log(err))
    }

    deleteTag = () => {
        Axios.post('/tags/delete', { tagId:this.state.deleteItemId})
        .then(response => this.setState({ editDialog:false, deleteDialog:false, showDialog:false }, this.getTags() ) )
        .catch(err => console.log(err))
    }

    render() {

        const addedActions = [
            <FlatButton
                label="Add Another"
                primary={true}
                onClick={ () => this.setState({  tagName:'', formErrors: { tagName:'' }, tagNameValid: false, showDialog:false })}
            />,
            <FlatButton
                label="Home"
                primary={true}
                onClick={ () => this.props.history.push('/') }
            />,
            <FlatButton
                label="Close"
                primary={true}
                onClick={ () => this.setState({  tagName:'', formErrors: { tagName:'' }, tagNameValid: false, showDialog:false })}
            />
        ];

        const editActions = [
            <FlatButton
                label="Submit"
                primary={true}
                onClick={this.editTag}
                disabled={ this.state.tagNewName === this.state.tagInitialName }
            />,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={ () => this.setState({ tagNewName:'', editDialog:false }) }
            />,
        ];

        const deleteActions = [
            <FlatButton
                label="Confirm"
                primary={true}
                onClick={this.deleteTag}
                disabled={ this.state.tagNewName === this.state.tagInitialName }
            />,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={ () => this.setState({ deleteDialog:false }) }
            />,
        ];

        return (
            <Container fluid>

                <Dialog
                    title="Success"
                    actions={addedActions}
                    modal={true}
                    open={this.state.showDialog}
                >
                    Tag Added Successfully
                </Dialog>

                <Dialog
                    title="Edit Tag"
                    actions={editActions}
                    modal={true}
                    open={this.state.editDialog}
                >
                    <TextField
                        name="tagNewName"
                        type="text"
                        hintText="Tag Name"
                        style={{ display:'block', width:'100%', marginBottom:'15px' }}
                        errorText={this.state.tagNewName ? '' : "Enter a valid name"}
                        onChange={ (e) => this.setState({ tagNewName:e.target.value }) }
                        value={this.state.tagNewName}
                    />
                </Dialog>

                <Dialog
                    title="Delete Tag"
                    actions={deleteActions}
                    modal={true}
                    open={this.state.deleteDialog}
                >
                    Are you sure you want to delete this tag?
                </Dialog>

                <CustomModal
                    isOpen={this.state.showAddTagModal}
                    handleInputChange={this.handleInput}
                    fields={
                        [
                            { fieldName: "tagName", value: this.state.tagName, placeholder: "New Tag Name" },
                        ]
                    }
                    showEnterAllDataAlert={this.state.showEnterAllDataAlert}
                    onSubmit={this.addNewTag}
                    onCancel={() => this.setState({ showAddTagModal: false, showEnterAllDataAlert:false, tagName:'' })}
                />


                <Button
                    outline
                    style={{ margin: 10 }}
                    color="primary"
                    onClick={() => this.setState({ showAddTagModal: true })}>
                    + Add New Tag
                </Button>
                
            {
                    this.props.tags && this.props.tags.length > 0 &&
                    <Table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Tag</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.tags.map((tag, index) => {
                                return <tr key={tag._id} style={{ background: !tag.activeFlag ? 'rgba(255,0,0,0.2)' : null  }}>
                                            <td>{index+1}</td>
                                            <td>{tag.name}</td>
                                            
                                            <td>
                                                <Button
                                                    color="warning" 
                                                    onClick={ () => this.setState({ editItem:tag, tagNewName:tag.name, tagInitialName:tag.name },
                                                                () => { this.setState({ editDialog:true })}) }
                                                >Edit</Button>
                                            </td>

                                            <td>
                                                <Button
                                                    outline
                                                    color="danger" 
                                                    onClick={ () => this.setState({ deleteItemId:tag._id },
                                                              () => this.setState({ deleteDialog:true })) }>
                                                            { tag.activeFlag ? "Delete" : "Undelete" }
                                                </Button>
                                            </td>
                                       </tr>
                                })
                            }
                        </tbody>
                    </Table>
                }

        </Container>
        )
    }
}



const mapStateToProps = state => {
    return {
        auth: state.auth,
        tags:state.data.tags,
    }
}

export default connect(mapStateToProps, { updateTags })(Tags)