import React, { Component } from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row, Button, Modal, ModalBody, ModalHeader, Input, ModalFooter,
    Label, FormText, Form, FormGroup, Alert, Nav, NavLink, NavItem, ListGroupItem,
    UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import CheckBox from 'material-ui/Checkbox';
import Loader from '../Components/Loader'
import striptags from 'striptags'
import queryString from 'query-string';
import { faSpinner, faCheckCircle, faCopy, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'

import { SEARCH_SERVER } from '../config/env'
import { applyColorToHtmlQuestion } from '../utils';
import SimilarQuestions from '../Components/SimilarQuestions'
import { copyToClipboard } from '../utils'
import TinyEditor from '../Components/TinyEditor'
import { isValidObjectId } from '../utils/index'

const languageTree = [
    {
        _id: "5b0326f395409b0f800bd784",
        name: "English"
    },
    {
        _id: "5b0326f395409b0f800bd785",
        name: "Malayalam"
    }
]

let newQuizSchema = {
    name: '',
    addingUserId: "5b03f6df6acf041d64234436",
    createdBy: '',
    questions: [
        {
            type: 'text',
            question: '',
            optionA: '',
            optionB: '',
            optionC: '',
            optionD: '',
            correctOption: '',
            description: ''
        }
    ],
    approved: false,
    premiumQuiz: false
}

const rejectionReasons = [
    { id: 1, text: "Wrong questions or answers" },
    { id: 2, text: "Questions in two languages" },
    { id: 3, text: "Outdated or out of syllabus questions" },
    { id: 4, text: "Already too many questions in this topic" },
    { id: 4, text: "ഇപ്പോൾ ചേർത്ത ചോദ്യങ്ങൾ വ്യത്യസ്‌തമായ വിഷയങ്ങളിൽ നിന്നാണ്, ദയവായി ഒരു വിഷയത്തിലുള്ള ചോദ്യമാക്കിയിട്ട് submit ചെയ്യാമോ ?" },
    { id: 5, text: "Other" },
]

const tabIds = [
    { key: '2', title: "New" },
    { key: '1', title: "Approved" },
    { key: '0', title: "Rejected" }
]

function EditOrCreateQuizModal(props) {

    const quizData = cloneDeep(props.quizData)

    const togglePremium = (value) => {
        const quizData = cloneDeep(props.quizData)
        quizData.premiumQuiz = value
        props.updateQuizData(quizData)
    }

    const handleEditDescriptionChange = (value) => {
        quizData.questions[props.questionIndex].description = value
        props.updateQuizData(quizData)
    }

    const deleteQuestion = () => {

        if (props.quizData.questions.length <= 10) {
            return alert("Unable to delete this question.\nQuiz should have at least 10 question")
        }

        if (window.confirm("Are you sure you want to delete this question?")) {
            let isLastIndex = (props.questionIndex === (quizData.questions.length - 1))
            quizData.questions.splice(props.questionIndex, 1)
            const newQuestionIndex = isLastIndex ? props.questionIndex - 1 : props.questionIndex
            props.updateQuizData(quizData)
            props.updateQuestionIndex(newQuestionIndex)
        }

    }

    return (
        <Modal
            fade={true}
            size="lg"
            style={{ maxWidth: '1600px', width: '80%' }}
            isOpen={props.showQuizForm}
        >
            <ModalBody>
                <Container fluid>
                    <Row>
                        <Col md="4">
                            <Form>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="quizName">Quiz Name</Label>
                                            <Input
                                                disabled={!props.editingMode}
                                                id="quizName"
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    quizData.name = value
                                                    props.updateQuizData(quizData)
                                                }}
                                                value={props.quizData.name}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="quizName">Created By</Label>
                                            <Input
                                                disabled={true}
                                                onChange={(e) => console.log(e)}
                                                value={props.creatorName}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        props.editQuizModal ?
                                            <>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <Label for="quizName">Created On</Label>
                                                        <Input
                                                            disabled={true}
                                                            onChange={(e) => console.log(e)}
                                                            value={new Date(props.quizData.createdOn).toLocaleDateString()}
                                                        />
                                                    </FormGroup>
                                                </Col>


                                                <Col md="12">
                                                    <FormGroup>
                                                        <Label for="quizName">Users Attended</Label>
                                                        <Input
                                                            disabled={true}
                                                            onChange={(e) => console.log(e)}
                                                            value={props.quizData.userAttended}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                            : null
                                    }

                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="description">Quiz Description</Label>
                                            <Input
                                                disabled={!props.editingMode}
                                                id="description"
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    quizData.description = value
                                                    props.updateQuizData(quizData)
                                                }}
                                                value={props.quizData.description || ''}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="12" style={{ marginBottom: 10 }}>
                                        <Row>

                                            <Col md="6">
                                                <ListGroupItem>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                disabled={!props.editingMode}
                                                                checked={props.quizData.premiumQuiz || false}
                                                                onChange={(e) => togglePremium(e.target.checked)}
                                                                type="checkbox" />{' '}
                                                            Premium Quiz
                                                        </Label>
                                                    </FormGroup>
                                                </ListGroupItem>
                                            </Col>

                                            {
                                                !props.editQuizModal ?
                                                    <Col md="6">
                                                        <ListGroupItem>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        checked={props.quizData.approved || false}
                                                                        onChange={(e) => {
                                                                            let value = e.target.checked
                                                                            quizData.approved = value
                                                                            props.updateQuizData(quizData)
                                                                        }}
                                                                        type="checkbox" />{' '}
                                                                    Approved
                                                                </Label>
                                                            </FormGroup>
                                                        </ListGroupItem>
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Col>

                                    
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for="exampleSelect">Select Subject</Label>
                                            <Input
                                                value={props.quizData.subjectId}
                                                type="select"
                                                name={"subjectId"}
                                                id={"subjectId"}
                                                disabled={!props.editingMode}
                                                onChange={(e) => {
                                                    const value = e.target.value

                                                    if (value !== "selectSubject") {
                                                        const foundSubject = props.subjects.find(e => e._id === value)
                                                        if (foundSubject) {
                                                            quizData.subjectId = value
                                                            props.updateQuizData(quizData)
                                                            props.updateSections(foundSubject.sections)
                                                        } else {
                                                            alert("Subject not found")
                                                        }
                                                    }
                                                }}
                                            >
                                                {
                                                    props.subjects ?
                                                        [{ _id: "selectSubject", name: "Select a subject" }, ...props.subjects].map((subject, index) => (
                                                            <option key={subject._id} value={subject._id}>{subject.name}</option>
                                                        ))
                                                        :
                                                        null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                            <Label for="exampleSelect">Select Section</Label>
                                            <Input
                                                disabled={!props.editingMode}
                                                value={props.quizData.sectionId}
                                                type="select"
                                                name={"sectionId"}
                                                id={"sectionId"}
                                                onChange={(e) => {
                                                    const value = e.target.value
                                                    if (value !== "selectSection") {
                                                        quizData.sectionId = value
                                                        props.updateQuizData(quizData)
                                                    }
                                                }}
                                            >
                                                {
                                                    props.sections ?
                                                        [{ _id: "selectSection", name: "Select a topic" }, ...props.sections].map(section => {
                                                            return (
                                                                <option key={section._id} value={section._id}
                                                                    onClick={(e) => props.updateSections(e.target.value)}
                                                                >
                                                                    {section.name}
                                                                </option>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="exampleSelect">Select Quiz Language</Label>
                                            <Input
                                                disabled={!props.editingMode}
                                                value={props.quizData.language}
                                                type="select"
                                                name={"language"}
                                                id={"language"}
                                                onChange={(e) => {
                                                    const value = e.target.value
                                                    if (value !== "selectLanguage") {
                                                        quizData.language = value
                                                        props.updateQuizData(quizData)
                                                    }
                                                }}
                                            >
                                                {
                                                    [{ _id: "selectLanguage", name: "Select Language" }, ...languageTree].map(language => {
                                                        return (
                                                            <option key={language._id} value={language._id}
                                                                onClick={(e) => props.updateLanguageId(e.target.value)}
                                                            >
                                                                {language.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>

                        <Col md="8">
                            <Row style={{ flex: 1, justifyContent: 'space-between', margin: 20 }}>

                                <Button
                                    disabled={props.questionIndex === 0}
                                    onClick={() => {
                                        let newQuestionIndex = cloneDeep(props.questionIndex) - 1
                                        const questionToSearch = props.quizData.questions[newQuestionIndex].question
                                        props.updateQuestionIndexAndFetchQuestions(newQuestionIndex, questionToSearch)
                                    }}
                                >Back</Button>

                                <p>{`Question No: ${props.questionIndex + 1}/${props.quizData.questions.length}`} </p>

                                {
                                    !props.editQuizModal ?
                                        (
                                            (props.questionIndex + 1) === props.quizData.questions.length ?
                                                <>
                                                    <Button
                                                        disabled={props.quizData.questions.length >= 50}
                                                        onClick={() => {
                                                            quizData.questions.push(JSON.parse(JSON.stringify(newQuizSchema.questions[0])))
                                                            const newQuestionIndex = cloneDeep(props.questionIndex) + 1
                                                            props.updatedQuizDataAndIndex(quizData, newQuestionIndex)

                                                        }}
                                                    >
                                                        Add Question
                                                    </Button>

                                                    <Button
                                                        outline
                                                        color={"danger"}
                                                        style={{ marginLeft: 5, marginRight: 5 }}
                                                        onClick={e => {
                                                            if (window.confirm("Are you sure you want to delete this question?")) {
                                                                if (quizData.questions.length === 1) {
                                                                    alert("Unable to deleted question. Quiz should have at least one question.")
                                                                } else {
                                                                    let newIndex = props.questionIndex
                                                                    quizData.questions.splice(props.questionIndex, 1)
                                                                    if (props.questionIndex > 0) {
                                                                        newIndex = newIndex - 1
                                                                    }
                                                                    props.updateQuizData(quizData)
                                                                    props.updateQuestionIndex(newIndex)
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            icon={faTimes}
                                                        />
                                                    </Button>
                                                </>

                                                :
                                                <Button
                                                    disabled={(props.questionIndex + 1) === props.quizData.questions.length}
                                                    onClick={() => {
                                                        let newQuestionIndex = cloneDeep(props.questionIndex) + 1
                                                        const questionToSearch = props.quizData.questions[newQuestionIndex].question
                                                        props.updateQuestionIndexAndFetchQuestions(newQuestionIndex, questionToSearch)
                                                    }}
                                                >Next</Button>
                                        )
                                        :
                                        <Button
                                            disabled={(props.questionIndex + 1) === props.quizData.questions.length}
                                            onClick={() => {
                                                let newQuestionIndex = cloneDeep(props.questionIndex) + 1
                                                const questionToSearch = props.quizData.questions[newQuestionIndex].question
                                                props.updateQuestionIndexAndFetchQuestions(newQuestionIndex, questionToSearch)
                                            }}
                                        >Next</Button>
                                }

                            </Row>
                            <Form>
                                {
                                    props.editQuizModal ?
                                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {
                                                    (props.quizData.questions[props.questionIndex].mergedQuestionId && isValidObjectId(props.quizData.questions[props.questionIndex].mergedQuestionId)) &&
                                                    
                                                        <Button
                                                            color="primary"
                                                            style={{marginRight: 5}}
                                                            disabled={!props.quizData.questions[props.questionIndex].mergedQuestionId}
                                                        >
                                                            <Link
                                                                to={`/questions/edit?questionId=${props.quizData.questions[props.questionIndex].mergedQuestionId}`}
                                                                target="_blank"
                                                                style={{textDecoration: "none", color: 'white'}}
                                                            >                                                            
                                                                    Go To Question
                                                            </Link>
                                                        </Button>
                                                }
                                                <Button
                                                    color="primary"
                                                    disabled={
                                                        isValidObjectId(props.quizData.questions[props.questionIndex].mergedQuestionId)
                                                        || props.quizData.questions[props.questionIndex].mergedQuestionId === true
                                                    }
                                                    onClick={() => props.addQuestionToQuestionsCollection(props.quizData.questions[props.questionIndex], props.questionIndex)}
                                                >
                                                    Add to Main Questions List
                                                </Button>
                                            </Col>
                                        </Row>
                                        : null
                                }
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="questionField">Question</Label>
                                            {
                                                (props.quizData.questions[props.questionIndex].type === "html" ||
                                                    props.quizData.questions[props.questionIndex].type === "math") ?
                                                    <ListGroupItem style={{ marginBottom: 10, backgroundColor: !props.editingMode && 'rgba(220,220,220)' }}>
                                                        <TinyEditor
                                                            contentCss={'dark'}
                                                            content={props.quizData.questions[props.questionIndex].question}
                                                            onHtmlChange={(newHtml) => {
                                                                quizData.questions[props.questionIndex].question = newHtml
                                                                props.updateQuizData(quizData)
                                                            }}
                                                        />
                                                    </ListGroupItem>
                                                    :
                                                    <FormGroup>
                                                        <Input
                                                            placeholder="Question"
                                                            type="textarea"
                                                            name="question"
                                                            disabled={!props.editingMode}
                                                            value={props.quizData.questions[props.questionIndex].question || ''}
                                                            onChange={(e) => {
                                                                let value = e.target.value
                                                                let updatedQuizData = cloneDeep(quizData)
                                                                updatedQuizData.questions[props.questionIndex].question = value
                                                                props.updateQuizData(updatedQuizData)
                                                            }}
                                                        />
                                                    </FormGroup>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Col md="3">
                                        <FormGroup tag="fieldset">
                                            <Input
                                                type="select" name="select" id="questionType"
                                                disabled={!props.editingMode}
                                                value={props.quizData.questions[props.questionIndex].type}
                                                onChange={(e) => {
                                                    const type = e.target.value
                                                    quizData.questions[props.questionIndex].type = type
                                                    props.updateQuizData(quizData)
                                                }}
                                            >
                                                <option value="text">Text</option>
                                                <option value="html">HTML</option>
                                                <option value="math">Math</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col md="6">
                                        <Row>
                                            <Col md="9">
                                                <Label for="optionA">Option A</Label>
                                                <Input
                                                    disabled={!props.editingMode}
                                                    id="optionA"
                                                    onChange={(e) => props.handleOptionChange(e.target.value, 0)}
                                                    value={props.optionA}
                                                />
                                            </Col>
                                            <Col md="3" style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckBox
                                                    label={'Math'}
                                                    style={{ marginTop: '10px' }}
                                                    checked={props.optionAType === 'math'}
                                                    disabled={!props.editingMode}
                                                    onCheck={(e, value) => props.toggleMathOption(value, 0)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md="6">
                                        <Row>
                                            <Col md="9">
                                                <Label for="optionB">Option B</Label>
                                                <Input
                                                    disabled={!props.editingMode}
                                                    id="optionB"
                                                    onChange={(e) => props.handleOptionChange(e.target.value, 1)}
                                                    value={props.optionB} />
                                            </Col>
                                            <Col md="3" style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckBox
                                                    label={'Math'}
                                                    style={{ marginTop: '10px' }}
                                                    checked={props.optionBType === 'math'}
                                                    disabled={!props.editingMode}
                                                    onCheck={(e, value) => props.toggleMathOption(value, 1)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Col md="6">
                                        <Row>
                                            <Col md="9">
                                                <Label for="optionC">Option C</Label>
                                                <Input
                                                    disabled={!props.editingMode}
                                                    id="optionC"
                                                    onChange={(e) => props.handleOptionChange(e.target.value, 2)}
                                                    value={props.optionC} />
                                            </Col>
                                            <Col md="3" style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckBox
                                                    label={'Math'}
                                                    style={{ marginTop: '10px' }}
                                                    checked={props.optionCType === 'math'}
                                                    disabled={!props.editingMode}
                                                    onCheck={(e, value) => props.toggleMathOption(value, 2)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md="6">
                                        <Row>
                                            <Col md="9">
                                                <Label for="optionD">Option D</Label>
                                                <Input
                                                    disabled={!props.editingMode}

                                                    id="optionD"
                                                    onChange={(e) => props.handleOptionChange(e.target.value, 3)}
                                                    value={props.optionD} />
                                            </Col>
                                            <Col md="3" style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckBox
                                                    label={'Math'}
                                                    style={{ marginTop: '10px' }}
                                                    checked={props.optionDType === 'math'}
                                                    disabled={!props.editingMode}
                                                    onCheck={(e, value) => props.toggleMathOption(value, 3)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="9">
                                        <Label for="explanation">Explanation</Label>
                                        <Input
                                            type="textarea"
                                            id="explanation"
                                            disabled={!props.editingMode}
                                            onChange={(e) => handleEditDescriptionChange(e.target.value)}
                                            value={props.quizData.questions[props.questionIndex].description || ''}
                                        />
                                    </Col>

                                    <Col md="3">
                                        <Label for="correctAnswerSelect">Correct Answer</Label>
                                        <Input
                                            disabled={!props.editingMode}
                                            type="select" name="select" id="correctAnswerSelect"
                                            onChange={(e) => props.handleAnswerChange(e.target.value)}
                                            value={props.correctAnswer}
                                        >
                                            <option>Correct Answer</option>
                                            <option>A</option>
                                            <option>B</option>
                                            <option>C</option>
                                            <option>D</option>
                                        </Input>
                                    </Col>
                                </Row>
                                {
                                    props.editQuizModal ?
                                        <Row style={{ justifyContent: 'flex-end', marginTop: '1rem', marginRight: '1rem' }}>
                                            <Button
                                                outline
                                                color="danger"
                                                onClick={() => deleteQuestion()}
                                                disabled={!props.editingMode}
                                            >
                                                Delete Question
                                            </Button>
                                        </Row>
                                        : null
                                }
                            </Form>

                            <Row>
                                <Col>
                                    <SimilarQuestions similarQuestions={props.similarQuestions} />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </ModalBody>

            {!props.addCSVByteQuizSuccess &&
                <ModalFooter style={{ display: "flex", justifyContent: 'space-between' }}>

                    <div>
                        {props.showEditSuccessAlert && <Alert color="success">Edited successfully</Alert>}
                    </div>

                    <div>
                        {
                            props.editQuizModal ?

                                <Button
                                    outline
                                    color="primary"
                                    onClick={() => props.toggleEditingMode()}
                                >
                                    {props.editingMode ? "Disable" : "Enable"} Editing Mode
                                </Button>
                                : null
                        }

                        <Button
                            outline
                            color="primary"
                            style={{ marginRight: 10, marginLeft: 10 }}
                            disabled={!props.editingMode}
                            onClick={() => props.submitQuiz() }
                        >
                            {props.editQuizModal ? "Submit Edit" : "submit New Quiz"}
                        </Button>

                        <Button
                            outline
                            color="primary"
                            onClick={() => { props.closeQuestionModal() }}

                        >
                            Close
                        </Button>
                    </div>

                </ModalFooter>
            }

        </Modal>)
}
class ByteQuiz extends Component {

    state = {
        quizzes: [],
        isLoading: true,
        questionIndex: null,
        showQuestionsModal: false,
        editingMode: false,
        showCreateQuizForm: false,
        newQuizData: JSON.parse(JSON.stringify(newQuizSchema)),
        csvByteQuizzesIndex: 0,
        showCSVUploadForm: false,
        fileUploading: false,
        addCSVByteQuizSuccess: false,
        sections: null,
        skipCount: 0,
        totalCount: 0,
        questionSearchString: "",
        showEditSuccessAlert: false,

        fetchNotificationsEnabled: false,
        fetchStatementQuizOnly: false,
        activeTab: "2",

        rejectQuizData: null,
        rejectionReason: null,
        rejectionNote: null,
        resubmittable: true
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state) {
            const { quizId, questionId } = this.props.location.state
            this.fetchRecentQuizzes(true, quizId, questionId)
        } else {
            this.fetchRecentQuizzes(true)
        }
    }

    fetchRecentQuizzes(getCount, quizId, questionId, searchString) {

        const { skipCount, fetchNotificationsEnabled, fetchStatementQuizOnly, activeTab } = this.state

        const paramsObject = {
            skipCount,
            notificationEnabled: fetchNotificationsEnabled,
            fetchStatementQuizOnly,
            approvalStatus: activeTab
        }

        if (getCount) { paramsObject.getTotalCount = true }
        if (quizId) { paramsObject.quizId = quizId }
        if (questionId) { paramsObject.questionId = questionId }
        if (searchString) { paramsObject.searchString = searchString }

        if (this.props.location && this.props.location.search) {
            const { userId } = queryString.parse(this.props.location.search)
            if (userId) paramsObject.userId = userId
        }

        axios.get('/bytequiz/all-quizzes', { params: paramsObject })
            .then(res => {

                let setData = { quizzes: res.data.Quizzes, isLoading: false, editingMode: false, newQuizData: JSON.parse(JSON.stringify(newQuizSchema)) }

                if (getCount) { setData.totalCount = res.data.QuizzesCount }
                this.setState(setData, () => {
                    if (quizId && questionId) {
                        if (res.data.Quizzes && res.data.Quizzes.length > 0) {
                            if (res.data.Quizzes[0]._id === quizId) {
                                let questionIndex = res.data.Quizzes[0].questions.findIndex((eachQuestion) => eachQuestion._id === questionId)
                                if (questionIndex >= 0) {
                                    this.viewQuestions(res.data.Quizzes[0], questionIndex)
                                }
                            }
                        }
                    }
                })
            })
            .catch(err => {
                this.setState({ isLoading: false })
                console.log(err)
                alert("Error. Please check console")
            })
    }

    viewQuestions(editingQuiz, questionIndex) {

        let quiz = cloneDeep(editingQuiz)

        quiz.questions = cloneDeep(quiz.questions)

        let sections = []

        const subjectIndex = this.props.subjects.findIndex(subject => subject._id === quiz.subjectId)

        if (this.props.subjects[subjectIndex] && this.props.subjects[subjectIndex].sections) { sections = this.props.subjects[subjectIndex].sections }

        this.setState({
            showQuestionsModal: true,
            editingQuiz: quiz,
            sections,
            questionIndex: questionIndex != null ? questionIndex : 0,
            editingMode: false
        }, () => {
            this.fetchSimilarQuestions(this.state.editingQuiz.questions[this.state.questionIndex].question)
        })

    }

    approveQuiz(quiz) {
        this.setState({ isLoading: true }, () => {
            axios.post('/bytequiz/approve', { quizId: quiz._id })
                .then(res => this.fetchRecentQuizzes())
                .catch(err => {
                    this.setState({ isLoading: false })
                    alert("Error, check console")
                    console.log(err)
                })
        })
    }

    handleEditOptionChange(value, index) {
        this.setState((oldState) => {
            oldState.editingQuiz.questions[this.state.questionIndex].options[index].text = value
            return { editingQuiz: oldState.editingQuiz }
        })
    }


    handleEditDescriptionChange(value) {
        this.setState((oldState) => {
            oldState.editingQuiz.questions[this.state.questionIndex].description = value
            return { editingQuiz: oldState.editingQuiz }
        })
    }
    updatedQuizDataAndIndex(data, index) {
        this.setState({ newQuizData: data }, () => this.setState({ questionIndex: index }))
    }

    handleNewQuizOptionChange(value, index) {
        let optionName
        if (index === 0) {
            optionName = "optionA"
        } else if (index === 1) {
            optionName = "optionB"
        } else if (index === 2) {
            optionName = "optionC"
        } else if (index === 3) {
            optionName = "optionD"
        }
        this.setState((oldState) => {
            oldState.newQuizData.questions[this.state.questionIndex][optionName] = value
            return { newQuizData: oldState.newQuizData }
        })
    }

    handleNewAnswerChange(value) {
        this.setState((oldState) => {
            oldState.newQuizData.questions[this.state.questionIndex].correctOption = value
            return { newQuizData: oldState.newQuizData }
        })
    }


    toggleNewPremium(value) {
        this.setState((oldState) => {
            oldState.newQuizData.premiumQuiz = value
            return { newQuizData: oldState.newQuizData }
        })
    }

    submitNewQuiz() {

        const {
            addingUserId,
            questions,
            name,
            premiumQuiz,
            description,
            approved,
            subjectId,
            sectionId
        } = this.state.newQuizData

        if (!name) {
            return alert("Enter a valid quiz name")
        } else if (!subjectId || !sectionId) {
            return alert("Please select subject and section of quiz")
        }

        let invalidQuestionIndex = -1
        
        questions.forEach((question, index) => {
            if (
                !question.question ||
                !question.optionA ||
                !question.optionB ||
                !question.optionC ||
                !question.optionD ||
                !question.correctOption
            ) {
                invalidQuestionIndex = index
            } else {
                if (question.type === "html" || question.type === "math") {
                    question.question = applyColorToHtmlQuestion(question.question)
                }
            }

        })

        if (invalidQuestionIndex >= 0) {
            return alert(`Fill all fiends in question ${invalidQuestionIndex + 1}`)
        }

        axios.post('/bytequiz/admin/create', {
            userId: addingUserId,
            quizName: name,
            questions,
            premiumQuiz,
            description,
            approved,
            subjectId,
            sectionId
        })
            .then(() => {
                this.setState({ showCreateQuizForm: false }, () => {
                    this.fetchRecentQuizzes()
                })
            })
            .catch(err => {
                alert("Error, please check console")
                console.log(err)
            })
    }

    submitEditedQuiz() {

        let { editingQuiz } = this.state

        editingQuiz.questions.forEach(question => {
            if (question.type === "html" || question.type === "math") {
                question.question = applyColorToHtmlQuestion(question.question)
            }
        })

        this.setState({ showQuestionsModal: false, isLoading: true }, () => {
            axios.post('/bytequiz/admin/edit', { quiz: editingQuiz })
                .then(res => {
                    this.fetchRecentQuizzes()
                    this.setState({ showEditSuccessAlert: true }, () => {
                        setTimeout(() => {
                            this.setState({ showEditSuccessAlert: false })
                        }, 2000);
                    })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error, check console")
                })
        })

    }

    toggleEditPremium(value) {
        this.setState((oldState) => {
            oldState.editingQuiz.premiumQuiz = value
            return { editingQuiz: oldState.editingQuiz }
        })
    }

    onFormSubmit = (e) => {
        this.setState({ fileUploading: true })
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        e.preventDefault();
        const formData = new FormData();
        formData.append('myImage', this.state.file);
        axios.post('/bytequiz/csv-create', formData, config)
            .then(res => {
                this.setState({ fileUploading: false, showCSVUploadForm: false, csvByteQuizzes: res.data })
                this.viewQuestions(res.data[this.state.csvByteQuizzesIndex])
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    onChange = (e) => {
        this.setState({ file: e.target.files[0] });
    }

    showSuccessView = () => {
        return (<Container>
            <Row style={{ padding: "0px 30px", marginTop: 50, justifyContent: 'center' }}>
                <FontAwesomeIcon style={{ color: "#29b529" }} className="iconButton fa-5x" icon={faCheckCircle} />
            </Row>
            <Row style={{ justifyContent: 'center', marginTop: 30 }}>
                <p style={{ fontWeight: 'bold' }}>Byte Quiz : {this.state.csvByteQuizzesIndex} / {this.state.csvByteQuizzes.length}</p>
            </Row>

            <Row style={{ justifyContent: 'center' }}>
                {this.state.csvByteQuizzesIndex !== this.state.csvByteQuizzes.length ? <Button
                    disabled={this.state.csvByteQuizzesIndex === this.state.csvByteQuizzes.length}
                    outline onClick={() => {
                        this.setState({ addCSVByteQuizSuccess: false })
                        this.viewQuestions(this.state.csvByteQuizzes[this.state.csvByteQuizzesIndex])
                    }}> Add next byte Quiz</Button> : <Button onClick={() => this.setState({ addCSVByteQuizSuccess: false, showQuestionsModal: false })}>Close</Button>
                }
            </Row>
        </Container>
        )
    }

    renderCSVUploadForm = () => {
        return (<Modal
            fade={true}
            size="lg"
            isOpen={this.state.showCSVUploadForm}>

            <ModalBody>
                <Container>
                    <Row style={{ padding: "0px 30px" }}>
                        <Form>
                            <FormGroup>
                                <p style={{ color: 'grey', fontSize: 13, marginTop: 50 }}>Upload your Spreadsheet file</p>
                                <Input type="file" name="file" id="exampleFile" onChange={this.onChange} />
                                <FormText color="muted"> Max 1 file. </FormText>
                            </FormGroup>
                            <Button
                                disabled={!this.state.file}
                                style={{ paddingLeft: 70, paddingRight: 70 }} type="submit" onClick={this.onFormSubmit} outline color="primary"> + Upload CSV File</Button>
                        </Form>
                    </Row>
                    <div style={{ float: 'right' }}>
                        <Button onClick={() => this.setState({ showCSVUploadForm: false })} outline>Close</Button>
                    </div>

                    {this.state.fileUploading && <p>
                        Uploading... <FontAwesomeIcon
                            icon={faSpinner}
                            className="iconButton fa-spin"
                            style={{ color: 'blue' }}
                        />
                    </p>}

                </Container>
            </ModalBody>
        </Modal>)
    }

    toggleNewQuizOptionMath(value, option) {
        
        const optionsArray = [ 'A', 'B', 'C', 'D']
        const optionKey = `option${optionsArray[option]}Type`

        this.setState((oldState) => {
            if (oldState.newQuizData.questions[this.state.questionIndex][optionKey] === "math") {
                oldState.newQuizData.questions[this.state.questionIndex][optionKey] = "text"
            } else {
                oldState.newQuizData.questions[this.state.questionIndex][optionKey] = "math"
            }
            return oldState
        })
    }

    toggleEditingMathOption(value, option) {
        this.setState((oldState) => {
            if (oldState.editingQuiz.questions[this.state.questionIndex].options[option].type === "math") {
                oldState.editingQuiz.questions[this.state.questionIndex].options[option].type = "text"
            } else {
                oldState.editingQuiz.questions[this.state.questionIndex].options[option].type = "math"
            }
            return oldState
        })
    }

    renderSkipMenu = () => {

        let returnArray = []

        let itemCount = parseInt(this.state.totalCount, 10) / 25

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => this.setState({ skipCount: index * 25, isLoading: true }, () => this.fetchRecentQuizzes())} >
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray

    }

    fetchSimilarQuestions(questionToSearch) {

        if (questionToSearch) {

            axios.get(`${SEARCH_SERVER}/search/similar-questions`, { params: { question: striptags(questionToSearch) } })
                .then(res => {
                    this.setState({ similarQuestions: res.data })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    addQuestionToQuestionsCollection(questionData, questionIndex) {

        const { _id, subjectId, sectionId, language } = this.state.editingQuiz

        const { question, options, answer, type, description } = questionData

        if (!subjectId || subjectId === "selectSubject") {
            alert("Please select quiz subject")
        } else if (!sectionId || sectionId === "selectSection") {
            alert("Please select quiz section")
        } else if (!language || language === "selectLanguage") {
            alert("Please select quiz language")
        } else {

            let questionText = question

            if (type === "html" || type === "math") {
                questionText = applyColorToHtmlQuestion(question)
            }

            let data = {
                question: questionText,
                type: type,
                options: [
                    {
                        id: options[0].id,
                        text: options[0].text,
                        type: options[0].type,
                    },
                    {
                        id: options[1].id,
                        text: options[1].text,
                        type: options[1].type,
                    },
                    {
                        id: options[2].id,
                        text: options[2].text,
                        type: options[2].type,
                    },
                    {
                        id: options[3].id,
                        text: options[3].text,
                        type: options[3].type,
                    },
                ],
                answer: answer,
                subjectId: subjectId,
                sectionId: sectionId,
                languageId: language,
                dailyChallengeQuestion: false,
                showInChallenge: false,
                premiumFlag: true,
                exams: [],
                description: description,
                tags: [],
                explanationType: type,
                byteQuizId: _id,
                byteQuizQuestionId: questionData._id
            }

            this.setState({ isLoading: true }, () => {
                axios.post(`/add-question`, data)
                    .then((response) => {
                        this.setState((oldState) => {
                            oldState.editingQuiz.questions[questionIndex].mergedQuestionId = response.data.data._id
                            return { editingQuiz: oldState.editingQuiz, isLoading: false }
                        })
                        alert("Question added successfully")
                    })
                    .catch((error) => {
                        console.log("Error Adding Question : ", error)
                        this.setState({ isLoading: false })
                    })
            })

        }
    }

    deleteQuestion() {
        if (this.state.editingQuiz.questions.length <= 10) {
            return alert("Unable to delete this question.\nQuiz should have at least 10 question")
        }

        if (window.confirm("Are you sure you want to delete this question?")) {
            this.setState(oldState => {
                let isLastIndex = (oldState.questionIndex === (oldState.editingQuiz.questions.length - 1))
                oldState.editingQuiz.questions.splice(oldState.questionIndex, 1)
                return {
                    editingQuiz: oldState.editingQuiz,
                    questionIndex: isLastIndex ? oldState.questionIndex - 1 : oldState.questionIndex
                }
            })
        }
    }

    closeQuestionModal() {
        this.setState({ similarQuestions: [], showQuestionsModal: false, showCreateQuizForm: false })
    }
    setNotificationStatus(quizId, status) {
        this.setState({ isLoading: true }, () => {
            axios.post('/bytequiz/update-notification-status', { quizId, status })
                .then(res => {
                    this.fetchRecentQuizzes()
                })
                .catch(err => {
                    console.log(err)
                    alert("Error, please check console")
                })
        })
    }

    rejectQuiz = () => {
        this.setState({ isLoading: true }, () => {

            const { rejectQuizData, rejectionNote, rejectionReason, resubmittable } = this.state


            if (rejectQuizData._id && rejectionReason) {

                axios.post('/bytequiz/reject', { quizId: rejectQuizData._id, rejectionReason, rejectionNote, resubmit: resubmittable })
                    .then(() => {
                        this.setState({ rejectQuizData: null, rejectionReason: null, rejectionNote: null, resubmittable: true })
                        this.fetchRecentQuizzes(true)
                    })
                    .catch(err => {
                        this.setState({ isLoading: false })
                        console.log(err)
                        alert("Server error while rejecting qiuz")
                    })
            } else {
                alert("Please enter all required fields")
                this.setState({ isLoading: false })
            }
        })
    }

    onRadioChange = (e) => {
        this.setState({ [e.target.name]: e.currentTarget.value })
    }

    onValueChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    renderRejectQuizModal() {
        return (
            <Modal
                size="lg"
                isOpen={this.state.rejectQuizData != null}
            >
                <ModalHeader>
                    Confirm rejection
                </ModalHeader>

                <ModalBody>
                    <Form>

                        <FormGroup tag="fieldset" row>
                            <legend className="col-form-label col-sm-2">Reason *</legend>
                            <Col sm="12">
                                {
                                    rejectionReasons.map(each => (
                                        <FormGroup check key={`${each.id}`}>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="rejectionReason"
                                                    value={each.id}
                                                    onChange={this.onRadioChange}
                                                />{' '}
                                                {each.text}
                                            </Label>
                                        </FormGroup>
                                    ))
                                }
                            </Col>
                        </FormGroup>

                        <FormGroup check style={{ marginBottom: 10 }}>
                            <Label check>
                                <Input
                                    checked={this.state.resubmittable}
                                    onChange={(e) => this.setState({ resubmittable: e.target.checked })}
                                    type="checkbox" />{' '}
                                Resubmittable
                            </Label>
                        </FormGroup>

                        <FormGroup>
                            <Label for="rejectionNote">{this.state.rejectionReason === "5" ? "Explanation" : "Explanation (Optional)"}</Label>
                            <Input
                                id="rejectionNote"
                                name="rejectionNote"
                                value={this.state.rejectionNote || ''}
                                onChange={this.onValueChange}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color={"danger"}
                        disabled={!this.state.rejectionReason || (this.state.rejectionReason === "5" && !this.state.rejectionNote)}
                        onClick={this.rejectQuiz}
                    >
                        REJECT
                    </Button>

                    <Button
                        outline
                        color={"secondary"}
                        onClick={e => this.setState({ rejectQuizData: null, rejectionReason: null, rejectionNote: null, resubmittable: true })}
                    >
                        CLOSE
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    updateQuizData(QuizData, updateState) {
        this.setState({ [`${updateState}`]: QuizData })
    }

    updateQuizDataAndQuestionIndex(data, index) {
        this.state((oldState => {
            oldState.newQuizData = data
            return { newQuizData: oldState.newQuizData }
        }, () => this.setState({ questionIndex: index })))
    }

    updateQuestionIndexAndFetchQuestions(questionIndex, questionToSearch) {
        if (questionIndex >= 0) {
            this.setState({ questionIndex, similarQuestions: [] }, () => this.fetchSimilarQuestions(questionToSearch))
        } else {
            this.fetchSimilarQuestions(questionToSearch)
        }
    }

    handleEditCheckOptionChange(value, index) {
        if (value) {
            this.setState((oldState) => {
                oldState.editingQuiz.questions[this.state.questionIndex].options[index].type = "math"
                return { editingQuiz: oldState.editingQuiz }
            })
        } else {
            this.setState((oldState) => {
                oldState.editingQuiz.questions[this.state.questionIndex].options[index].type = "text"
                return { editingQuiz: oldState.editingQuiz }
            })
        }
    }

    handleNewCheckOptionChange(value, index) {

        let option

        if (index === 0) {
            option = "optionAType"
        } else if (index === 1) {
            option = "optionBType"
        } else if (index === 2) {
            option = "optionCType"
        } else if (index === 3) {
            option = "optionDType"
        }

        this.setState((oldState) => {
            if (oldState.newQuizData.questions[this.state.questionIndex][option] === "math") {
                oldState.newQuizData.questions[this.state.questionIndex][option] = "text"
            } else {
                oldState.newQuizData.questions[this.state.questionIndex][option] = "math"
            }
            return oldState
        })

    }

    handleEditAnswerChange(value) {
        this.setState((oldState) => {
            oldState.editingQuiz.questions[this.state.questionIndex].answer = value
            return { editingQuiz: oldState.editingQuiz }
        })

    }


    render() {
        return (
            <>

                {this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}

                {this.state.rejectQuizData && this.renderRejectQuizModal()}

                <Container fluid>

                    {this.state.showCSVUploadForm && this.renderCSVUploadForm()}

                    {
                        this.state.showCreateQuizForm &&
                        <EditOrCreateQuizModal
                            quizData={this.state.newQuizData}
                            correctAnswer={this.state.newQuizData.questions[this.state.questionIndex].correctOption}
                            handleAnswerChange={(value) => this.handleNewAnswerChange(value)}
                            editingMode={true}
                            showQuizForm={this.state.showCreateQuizForm}
                            questionIndex={this.state.questionIndex}
                            creatorName={this.state.newQuizData.addingUserId}
                            subjects={this.props.subjects}
                            sections={this.state.sections}
                            updateSections={(sections) => this.setState({ sections })}
                            optionA={this.state.newQuizData.questions[this.state.questionIndex].optionA || ''}
                            optionB={this.state.newQuizData.questions[this.state.questionIndex].optionB || ''}
                            optionC={this.state.newQuizData.questions[this.state.questionIndex].optionC || ''}
                            optionD={this.state.newQuizData.questions[this.state.questionIndex].optionD || ''}
                            optionAType={this.state.newQuizData.questions[this.state.questionIndex].optionAType || ''}
                            optionBType={this.state.newQuizData.questions[this.state.questionIndex].optionBType || ''}
                            optionCType={this.state.newQuizData.questions[this.state.questionIndex].optionCType || ''}
                            optionDType={this.state.newQuizData.questions[this.state.questionIndex].optionDType || ''}
                            toggleMathOption={ (value, option) => this.toggleNewQuizOptionMath(value, option)}
                            handleOptionChange={(value, index) => this.handleNewQuizOptionChange(value, index)}
                            handleCheckOptionChange={(value, index) => this.handleNewCheckOptionChange(value, index)}
                            closeQuestionModal={() => this.closeQuestionModal()}
                            updatedQuizDataAndIndex={(quizData, index) => this.updatedQuizDataAndIndex(quizData, index)}
                            updateQuestionIndexAndFetchQuestions={(questionIndex, questionToSearch) => this.updateQuestionIndexAndFetchQuestions(questionIndex, questionToSearch)}
                            updateQuestionIndex={(index) => this.setState({ questionIndex: index })}
                            updateQuizData={(e) => this.updateQuizData(e, "newQuizData")}
                            submitQuiz={() => this.submitNewQuiz()}
                        />
                    }

                    {
                        this.state.showQuestionsModal &&
                        !this.state.showCreateQuizForm &&
                        this.state.editingQuiz &&
                        this.state.editingQuiz.questions.length > 0 &&
                        <EditOrCreateQuizModal
                            quizData={this.state.editingQuiz}
                            editQuizModal={true}
                            correctAnswer={this.state.editingQuiz.questions[this.state.questionIndex].answer}
                            handleAnswerChange={(value) => this.handleEditAnswerChange(value)}
                            creatorName={this.state.editingQuiz.creator.name}
                            showQuizForm={this.state.showQuestionsModal}
                            optionA={this.state.editingQuiz.questions[this.state.questionIndex].options[0].text}
                            optionB={this.state.editingQuiz.questions[this.state.questionIndex].options[1].text}
                            optionC={this.state.editingQuiz.questions[this.state.questionIndex].options[2].text}
                            optionD={this.state.editingQuiz.questions[this.state.questionIndex].options[3].text}
                            optionAType={this.state.editingQuiz.questions[this.state.questionIndex].options[0].type}
                            optionBType={this.state.editingQuiz.questions[this.state.questionIndex].options[1].type}
                            optionCType={this.state.editingQuiz.questions[this.state.questionIndex].options[2].type}
                            optionDType={this.state.editingQuiz.questions[this.state.questionIndex].options[3].type}
                            handleOptionChange={(value, index) => this.handleEditOptionChange(value, index)}
                            toggleMathOption={ (value, option) => this.toggleEditingMathOption(value, option)}
                            toggleEditingMode={() => this.setState({ editingMode: !this.state.editingMode })}
                            deleteQuestion={this.deleteQuestion}
                            subjects={this.props.subjects}
                            sections={this.state.sections}
                            updateQuestionIndex={(questionIndex) => this.setState({ questionIndex })}
                            updateQuestionIndexAndFetchQuestions={(questionIndex, questionToSearch) => this.updateQuestionIndexAndFetchQuestions(questionIndex, questionToSearch)}
                            updateSections={(sections) => this.setState({ sections })}
                            questionIndex={this.state.questionIndex}
                            editingMode={this.state.editingMode}
                            updateQuizData={(e) => this.updateQuizData(e, "editingQuiz")}
                            similarQuestions={this.state.similarQuestions}
                            addCSVByteQuizSuccess={this.state.addCSVByteQuizSuccess}
                            showEditSuccessAlert={this.state.showEditSuccessAlert}
                            addQuestionToQuestionsCollection={(data, questionIndex) => this.addQuestionToQuestionsCollection(data, questionIndex)}
                            updateQuizDataAndQuestionIndex={(data, questionIndex) => this.updateQuizDataAndQuestionIndex(data, questionIndex)}
                            submitQuiz={() => this.submitEditedQuiz()}
                            closeQuestionModal={() => this.closeQuestionModal()}
                        />
                    }

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 20 }}>

                        <div>

                            <Button
                                outline
                                style={{ marginRight: 20 }}
                                color="primary"
                                onClick={() => this.setState({ showCreateQuizForm: true, questionIndex: 0, newQuizData: JSON.parse(JSON.stringify(newQuizSchema)) })}>
                                + Create New Quiz
                            </Button>

                            <Button
                                style={{ marginRight: 20 }}
                                outline
                                onClick={() => this.setState({ showCSVUploadForm: true })}
                                color="primary">
                                + Upload CSV
                            </Button>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <Input
                                    type="checkbox"
                                    checked={this.state.fetchNotificationsEnabled}
                                    onChange={(e) => this.setState({
                                        fetchNotificationsEnabled: !this.state.fetchNotificationsEnabled,
                                        isLoading: true
                                    }, () => { this.fetchRecentQuizzes(true) })}
                                />{' '}Notifications Enabled

                                <br />

                                <Input
                                    type="checkbox"
                                    checked={this.state.fetchStatementQuizOnly}
                                    onChange={(e) => this.setState({
                                        fetchStatementQuizOnly: !this.state.fetchStatementQuizOnly,
                                        isLoading: true
                                    }, () => { this.fetchRecentQuizzes(true) })}
                                />{' '}Statement Quiz Only
                            </div>

                            <UncontrolledDropdown style={{ marginLeft: 10 }}>
                                <DropdownToggle caret color="success" id="dropdown-basic"> Page  {((this.state.skipCount / 25) + 1).toFixed(0)} </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {this.renderSkipMenu()}
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </div>

                    </div>
                    {
                        this.state.leaderboard && this.state.leaderboard.length > 0 ? 
                            this.renderLeaderBoard()
                                :
                                    <>                        
                                        <Nav tabs>
                                            {tabIds.map((each) => {
                                                return (
                                                    <NavItem key={each.key}>
                                                        <NavLink className={this.state.activeTab === each.key ? 'active' : ''}
                                                            onClick={() => {
                                                                this.setState({ skipCount: 0, activeTab: each.key, isLoading: true }, () => {
                                                                    this.fetchRecentQuizzes(true)
                                                                })
                                                            }}>
                                                            {each.title}
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            })}
                                        </Nav>

                                        <Row>
                                            <Col>
                                                {
                                                    this.state.quizzes && this.state.quizzes.length > 0 ?
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>No.</th>
                                                                    <th>Quiz Name</th>
                                                                    <th>Date</th>
                                                                    <th>Created By</th>
                                                                    <th>Questions</th>
                                                                    <th>Attended</th>
                                                                    <th>Actions</th>
                                                                    <th>Edit</th>
                                                                    {this.state.activeTab === "1" && <th>Notification</th>}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.quizzes.map((dc, index) => {
                                                                        return (
                                                                            <tr key={dc._id} style={{
                                                                                backgroundColor:
                                                                                    dc.approvalStatus === 1 ? 'rgba(0,255,0,0.1)' : dc.approvalStatus === 0 ? 'rgba(255,0,0,0.1)' : "rgba(224, 218, 16, 0.2)"
                                                                            }}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{dc.name}</td>
                                                                                <td>{new Date(dc.createdOn).toLocaleDateString()}</td>
                                                                                <td>{dc.creator.name}</td>
                                                                                <td>{dc.questions.length}</td>
                                                                                <td>{dc.userAttended}</td>

                                                                                <td>
                                                                                    <Button
                                                                                        onClick={() => { 
                                                                                            copyToClipboard(dc._id)
                                                                                            toast("ID copied", {
                                                                                                position: toast.POSITION.TOP_CENTER,
                                                                                                hideProgressBar: true
                                                                                                }) 
                                                                                        }}
                                                                                        outline
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            className="iconButton"
                                                                                            icon={faCopy}
                                                                                        />
                                                                                    </Button>

                                                                                    <Button
                                                                                        outline
                                                                                        color={"success"}
                                                                                        disabled={dc.approvalStatus === 1}
                                                                                        style={{ marginLeft: 5, marginRight: 5 }}
                                                                                        onClick={e =>
                                                                                            window.confirm("Approve this byte quiz?") &&
                                                                                            this.approveQuiz(dc)
                                                                                        }
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            className="iconButton"
                                                                                            icon={faCheck}
                                                                                        />
                                                                                    </Button>

                                                                                    <Button
                                                                                        outline
                                                                                        color={"danger"}
                                                                                        disabled={dc.approvalStatus === 0}
                                                                                        onClick={e => this.setState({ rejectQuizData: dc })}
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            className="iconButton"
                                                                                            icon={faTimes}
                                                                                        />
                                                                                    </Button>
                                                                                </td>

                                                                                <td>
                                                                                    <Button
                                                                                        outline
                                                                                        block
                                                                                        color="primary"
                                                                                        onClick={() => this.viewQuestions(dc)}
                                                                                    >
                                                                                        Edit
                                                                                    </Button>
                                                                                </td>

                                                                                {
                                                                                    this.state.activeTab === "1" &&
                                                                                    <td>
                                                                                        <Button
                                                                                            outline
                                                                                            block
                                                                                            color={
                                                                                                dc.notificationStatus === 2 ? "secondary" :
                                                                                                    dc.notificationStatus === 1 ? "primary" :
                                                                                                        "danger"
                                                                                            }
                                                                                            disabled={dc.notificationStatus === 2}
                                                                                            onClick={e => {
                                                                                                if (dc.notificationStatus === 1) {
                                                                                                    window.confirm("Disable notification?") && this.setNotificationStatus(dc._id, 0)
                                                                                                } else {
                                                                                                    if (dc.approved) {
                                                                                                        window.confirm("Enable notification?") && this.setNotificationStatus(dc._id, 1)
                                                                                                    } else {
                                                                                                        alert("Sorry. Cannot enable notification for non approved quizzes")
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                dc.notificationStatus === 1 ? "Disable Notification" :
                                                                                                    dc.notificationStatus === 2 ? "Notification" :
                                                                                                        "Enable Notification"
                                                                                            }
                                                                                        </Button>
                                                                                    </td>
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                        :
                                                        (Array.isArray(this.state.quizzes) && this.state.quizzes.length === 0) ?
                                                            <h5 style={{ marginTop: 50, textAlign: 'center' }}>No quiz found</h5>
                                                            : null
                                                }
                                            </Col>
                                        </Row>
                                    </>
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        subjects: state.data.subjects,
    }
}

export default connect(mapStateToProps)(ByteQuiz)
