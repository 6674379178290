import React, { Component } from 'react'
import axios from 'axios'
import Aux from './Auxiliary'
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { connect } from 'react-redux'
import { updateQuestions } from '../actions'
import { QuestionsTable } from './QuestionsTable'
import { isFinite } from 'lodash'
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledButtonDropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    Button
} from 'reactstrap'
import Loader from '../Components/Loader'
import queryString from 'query-string';
import { dropDownPeriods } from '../utils';
import ExplanationFeedBack from '../Pages/ExplanationFeedBack';
import { toast } from 'react-toastify';

const reportTypes = [
    {
        id:"2",
        label:"Wrong Answer",
        message:"Showing: Wrong answers"
    },
    {
        id:"1",
        label:"Typo",
        message:"Showing: Typo in question or answer"
    },
    {
        id:"3",
        label:"Outdated Question",
        message:"Showing: Outdated question"
    },
    {
        id:"4",
        label:"Others",
        message:"Showing: Other reports"
    },
    {
        id:"5",
        label:"Explanation Required",
        message:"Showing: Explanation Required"
    },
    {
        id:"6",
        label:"Explanation Feedback",
        message:"Showing: Explanation Feedback"
    }
]

class ReportedQuestions extends Component {

    state = {
        activeTab:'2',
        deleteDialog: false,
        usersDetails: null,
        questionIdToSearch: ''
    }

    componentDidMount() {
        this.props.updateQuestions({ questions: [], count: 0 })
        this.fetchReports()
    }
    
    fetchReports() {

        this.setState({ isLoading: true }, () => {

            const {
                type, page, sortBy, period, periodLabel,
                subjectIndex, sectionIndex, userId, questionId,
                questionType, questionTypeLabel
            } = queryString.parse(this.props.location.search)
            
            let paramsObject = {}

            let setObject = { isLoading: false }

            let setQueryItems = []

            if (isFinite(parseInt(type))) {
                setQueryItems.push({ key: 'type', value: type })
                paramsObject.type = parseInt(type)
                setObject.type = paramsObject.type
            } else {
                setQueryItems.push({ key: 'type', value: 2 })
                setObject.type = 2
                paramsObject.type = 2
            }

            if (isFinite(parseInt(page))) {
                paramsObject.skipCount = parseInt(page) * 50
                setObject.pageNumber = parseInt(page)
                setQueryItems.push({ key: 'page', value: page })
            } else {
                setQueryItems.push({ key: 'page', value: 0 })
                setObject.pageNumber = 0
                paramsObject.skipCount = 0 * 50
            }

            if (sortBy === 'date' || sortBy === 'count') {
                paramsObject.sortBy = sortBy
                setObject.sortBy = sortBy
                setQueryItems.push({ key: 'sortBy', value: sortBy })
            } else {
                paramsObject.sortBy = 'count'
                setObject.sortBy = 'count'
                setQueryItems.push({ key: 'sortBy', value: 'count' })
            }

            if (period && periodLabel) {

                paramsObject.period = period
                setObject.period = period
                setObject.periodLabel = periodLabel

                setQueryItems.push(
                    { key: 'period', value: period },
                    { key: 'periodLabel', value: periodLabel }
                )

            } else {
             
                paramsObject.period = 'thisMonth'
                setObject.period = 'thisMonth'
                setObject.periodLabel = 'This Month'

                setQueryItems.push(
                    { key: 'period', value: 'thisMonth' },
                    { key: 'periodLabel', value: 'This Month' }
                )
            }

            if (questionType && questionTypeLabel) {

                paramsObject.questionType = questionType
                setObject.questionType = questionType
                setObject.questionTypeLabel = questionTypeLabel

                setQueryItems.push(
                    { key: 'questionType', value: questionType },
                    { key: 'questionTypeLabel', value: questionTypeLabel }
                )

            } else {

                paramsObject.questionType = 'all'
                setObject.questionType = 'all'
                setObject.questionTypeLabel = 'All Types'

                setQueryItems.push(
                    { key: 'questionType', value: 'all' },
                    { key: 'questionTypeLabel', value: 'All Types' }
                )
            }

            if (userId) {
                paramsObject.userId = userId
                setObject.userId = userId
                setQueryItems.push({ key: 'userId', value: userId })
            }

            if (questionId) {
                paramsObject.questionId = questionId
                setObject.questionId= questionId
                setQueryItems.push({ key: 'questionId', value: questionId })
            } 

            if (this.state.questionIdToSearch) {
                paramsObject.questionId = this.state.questionIdToSearch
                setObject.questionId= this.state.questionIdToSearch
                setQueryItems.push({ key: 'questionId', value: this.state.questionIdToSearch })
            }

            this.setQueryParams(setQueryItems)

            const subjectIndexInt = parseInt(subjectIndex)
            const sectionIndexInt = parseInt(sectionIndex)

            if (isFinite(subjectIndexInt) && this.props.subjects[subjectIndexInt]) {
                paramsObject.subjectId = this.props.subjects[subjectIndexInt]._id
                setObject.subjectIndex = subjectIndexInt

                if (isFinite(sectionIndexInt) && this.props.subjects[subjectIndexInt].sections[sectionIndexInt]) {
                    paramsObject.sectionId = this.props.subjects[subjectIndexInt].sections[sectionIndexInt]._id
                    setObject.sectionIndex = sectionIndexInt
                }
            }

            if (this.state.activeTab === "6") {
                axios.get('/question-explanation-feedback', { params: paramsObject })
                    .then(res => {
                        this.setState(setObject, () => {
                            this.props.updateQuestions({
                                questions: res.data.explanationFeedback,
                                count: res.data.totalCount
                            })
                        })
                    }).catch(err => {
                        console.log(err)
                        alert("Server error. Please check console")
                    })
            } else {
                axios.get(`/fetchReports`, { params: paramsObject })
                    .then(response => {
                        this.setState(setObject, () => {
                            this.props.updateQuestions({
                                questions: response.data.reports,
                                count: response.data.totalReportsCount
                            })
                        })
                    })
                    .catch(err => {
                        console.error('get/fetchReports')
                        console.error(err)
                        this.setState({ isLoading: false })
                        alert("Error. Please check console")
                    })
            }

        })
    }

    deleteReport(reportActionStatus) { 
        axios.delete(`/report`, { params: { reportId: this.state.deleteItemId, reportActionStatus } })
            .then((response) => {
                this.setState({ isLoading: false, deleteDialog: false, deleteItemId: '' }, () => {
                    
                    this.fetchReports(this.state.issueType)

                    if (response.data.data) {
                        toast(`Notification Sent: ${response.data.data}`, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                        })
                    }
                    
                })
            })
            .catch((error) => {
                console.log(error)
                alert("Server error while deleting report. Please check console")
            })
    }

    getUser = (index) => {

        let userIds = this.props.questionData.questions[index].userExplanation.map(item => item.userId)

        axios.get(`/get-users-details`, { params: { userIds: userIds, requiredDetails: { name: 1, mobile: 1 } } })
            .then((response) => {
                const { questions, count } = this.props.questionData
                questions[index].usersDetails = response.data.usersDetails
                this.props.updateQuestions({ questions, count })
            })
            .catch((error) => {
                console.log(error)
                alert("Error. Please check console")
            })
            
    }

    setQueryParams = (key, value) => {

        const search = this.props.location.search;

        const existingQueryParams = {
            type: new URLSearchParams(search).get('type'),
            page: new URLSearchParams(search).get('page'),
            sortBy: new URLSearchParams(search).get('sortBy'),
            subjectIndex: new URLSearchParams(search).get('subjectIndex'),
            sectionIndex: new URLSearchParams(search).get('sectionIndex'),
            period: new URLSearchParams(search).get('period'),
            periodLabel: new URLSearchParams(search).get('periodLabel'),
            questionType: new URLSearchParams(search).get('questionType'),
            questionTypeLabel: new URLSearchParams(search).get('questionTypeLabel'),
        }
        if (new URLSearchParams(search).get('userId')!=="null") {
            existingQueryParams["userId"]= new URLSearchParams(search).get('userId')
        }
        if (new URLSearchParams(search).get('questionId')!=="null") {
            existingQueryParams["questionId"]= new URLSearchParams(search).get('questionId')
        }

        if (Array.isArray(key)) {
            Object.keys(key).forEach(eachKey => {
                existingQueryParams[key[eachKey].key] = key[eachKey].value
            })
        } else {
            existingQueryParams[key] = `${value}`
        }

        let searchParams = ""

        Object.keys(existingQueryParams).forEach((eachKey, index) => {
            searchParams += `${eachKey}=${existingQueryParams[eachKey]}&`
        })

        this.props.history.push({
            pathname: "/reported-questions",
            search: searchParams
        })
    }

    showDialogMessage () {
        if (this.state.reportActionStatus === 'questionDeleted') {
            return 'Please confirm you have deleted this question from reported questions, Reported users will be notified'
        } else if (this.state.reportActionStatus === 'corrected') {
            return 'Please confirm you have corrected this question, Reported users will be notified'
        } else {
            return 'Please confirm this questions is already correct, Reported users will be notified'
        }
    }

    renderSkipMenu =()=> {
        
        let returnArray = []
        let itemCount = parseInt(this.props.questionData.count, 10) / 10
        
        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={ () => {
                        this.setQueryParams('page', index)
                        this.setState({ pageNumber: index },
                        () => this.fetchReports())
                    }
                }>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    setSortedBy = (sortBy) => {
        this.setState({ sortBy }, () => {
            this.setQueryParams('sortBy', sortBy)
            this.fetchReports()
        })
    }

    sendNotificationToUser = (questionId) => {
        this.setState({ isLoading: true }, () => {
            axios.post('/send-explanation-feedback-notification-to-users', { questionId })
                .then(() => {
                    this.fetchReports()
                })
                .catch((error) => {
                    this.setState({ isLoading : false })
                    console.log("Error while sending  notification to user")
                    console.log(error)
                })
        })
    }

    deleteFeedback = (questionId) => {
        if (window.confirm("Are you sure you want to delete?")) {
            this.setState({ isLoading: true }, () => {
                axios.delete('/delete-explanation-feedback', { params: { questionId } })
                    .then(() => {
                        this.fetchReports()
                    })
                    .catch((error) => {
                        this.setState({ isLoading : false })
                        console.log("Error while sending  notification to user")
                        console.log(error)
                    })
            })
        }
    }

    render() {

        const deleteActions = [
            <FlatButton
                label="Yes"
                primary={true}
                onClick={ () => this.deleteReport(this.state.reportActionStatus) }
            />,
            <FlatButton
                label="No"
                secondary={true}
                onClick={ () => this.setState({ deleteDialog:false }) }
            />,
        ];

        return(
            <Aux>
                { this.state.isLoading && <Loader /> }
                <div>
                    <Dialog
                        title="Confirm Delete"
                        actions={deleteActions}
                        modal={true}
                        open={this.state.deleteDialog}
                    >
                        {this.showDialogMessage()}
                    </Dialog>
                </div>

                <div style={{ padding:0 }}>

                    <Row style={{ marginTop: 10, marginBottom: 10, flex: 1, justifyContent: 'flex-end' }}>
                        
                        <Col md="2" style={{ display: "flex" }}>
                            <Input
                                type="text"
                                id="questionIdToSearch"
                                placeholder={"search by question Id"}
                                onChange={(e) => this.setState({ questionIdToSearch: e.target.value })}
                                value={this.state.questionIdToSearch}
                            />
                            <Button
                                style={{ marginLeft: "4px" }}
                                color="primary"
                                onClick={()=> this.fetchReports()}
                            >
                                Search
                            </Button>
                        </Col>
                        
                        <Col md="1">
                            <UncontrolledDropdown style={{ marginRight: 15 }}>

                                <DropdownToggle block caret color="primary">
                                    {this.state.questionTypeLabel && this.state.questionTypeLabel !== null ? this.state.questionTypeLabel : "Question Type"}
                                </DropdownToggle>

                                <DropdownMenu>
                                    {
                                        [
                                            { value: "all", label: "All Types" },
                                            { value: "text", label: "Text" },
                                            { value: "html", label: "HTML" },
                                            { value: "math", label: "Math" }
                                        ].map(each => (
                                            <DropdownItem
                                                key={each.value}
                                                onClick={() => {

                                                    this.setQueryParams([
                                                        { key: 'questionType', value: each.value },
                                                        { key: 'questionTypeLabel', value: each.label },
                                                    ])

                                                    this.setState({
                                                        questionType: each.value,
                                                        questionTypeLabel: each.label
                                                    }, () => {
                                                        this.fetchReports()
                                                    })

                                                }}
                                            >
                                                {each.label}
                                            </DropdownItem>
                                        ))
                                    }
                                </DropdownMenu>


                            </UncontrolledDropdown>
                        </Col>

                        <Col md="2">
                            <UncontrolledDropdown style={{ marginRight: 15 }}>

                                <DropdownToggle block caret color="primary">
                                    {this.state.periodLabel && this.state.periodLabel !== null ? this.state.periodLabel : "Select Period"}
                                </DropdownToggle>

                                <DropdownMenu>
                                    {
                                        dropDownPeriods.slice(0, -1).map(each => (
                                            <DropdownItem
                                                key={each.value}
                                                onClick={() => {

                                                    this.setQueryParams([
                                                        { key: 'period', value: each.value },
                                                        { key: 'periodLabel', value: each.label },
                                                    ])

                                                    this.setState({
                                                        period: each.period,
                                                        periodLabel: each.label
                                                    }, () => {
                                                        this.fetchReports()
                                                    })

                                                }}
                                            >
                                                {each.label}
                                            </DropdownItem>
                                        ))
                                    }
                                </DropdownMenu>

                            </UncontrolledDropdown>
                        </Col>

                        <Col md="2">
                            <UncontrolledDropdown style={{ marginRight: 15 }}>
                                <DropdownToggle block caret color="primary">
                                    {(isFinite(this.state.subjectIndex) && this.props.subjects[this.state.subjectIndex]) ? this.props.subjects[this.state.subjectIndex].name : "All Subjects"}
                                </DropdownToggle>

                                <DropdownMenu>
                                    {
                                        this.props.subjects.map((each, index) => {
                                            return (
                                                <DropdownItem
                                                    key={each._id}
                                                    onClick={() => {

                                                        this.setQueryParams([
                                                            { key: 'subjectIndex', value: index },
                                                            { key: 'sectionIndex', value: null }
                                                        ])

                                                        this.setState({ subjectIndex: index, sectionIndex: null }, () => {
                                                            this.fetchReports()
                                                        })
                                                    }}
                                                >
                                                    {each.name}
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>

                        <Col md="2">
                            <UncontrolledDropdown style={{ marginRight: 15 }}>
                                <DropdownToggle
                                    caret
                                    block
                                    color="primary"
                                    disabled={(isFinite(this.state.subjectIndex) && this.props.subjects[this.state.subjectIndex]) ? false : true}
                                >
                                    {
                                        isFinite(this.state.subjectIndex) &&
                                            isFinite(this.state.sectionIndex) &&
                                            this.props.subjects[this.state.subjectIndex] &&
                                            this.props.subjects[this.state.subjectIndex].sections[this.state.sectionIndex]
                                            ? this.props.subjects[this.state.subjectIndex].sections[this.state.sectionIndex].name : "All Sections"}
                                </DropdownToggle>

                                {
                                    isFinite(this.state.subjectIndex) && this.props.subjects[this.state.subjectIndex] ?
                                        <DropdownMenu>
                                            {
                                                this.props.subjects[this.state.subjectIndex].sections.map((each, index) => (
                                                    <DropdownItem
                                                        key={each._id}
                                                        onClick={() => {
                                                            this.setQueryParams('sectionIndex', index)
                                                            this.setState({ sectionIndex: index }, () => {
                                                                this.fetchReports()
                                                            })
                                                        }}
                                                    >
                                                        {each.name}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </DropdownMenu>
                                        : null
                                }

                            </UncontrolledDropdown>
                        </Col>

                        <Col md="2">
                            <UncontrolledDropdown style={{ marginRight: 15 }}>
                                <DropdownToggle block caret color="primary">Sort by {this.state.sortBy}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => this.setSortedBy('date')} >
                                        Sort by date
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.setSortedBy('count')} >
                                        Sort by count
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>

                        <Col md="1">
                            <UncontrolledButtonDropdown>
                                
                                <DropdownToggle block caret color="success">
                                    Page {this.state.pageNumber + 1}
                                </DropdownToggle>

                                <DropdownMenu>
                                    {this.renderSkipMenu()}
                                </DropdownMenu>

                            </UncontrolledButtonDropdown>
                        </Col>

                    </Row>

                    <div>
                        {
                            (this.props.questionData && isFinite(this.props.questionData.count)) ?
                                <p style={{ textAlign: 'right', marginRight: 20 }}>
                                    Reports Count: {this.props.questionData.count}
                                </p>
                                :
                                null
                        }
                    </div>



                    <Nav tabs>
                        {reportTypes.map((eachType) => {
                            return (
                                <NavItem key={eachType.id}>
                                    <NavLink className={(this.state.activeTab === eachType.id) ? 'active' : ''}
                                        onClick={() => {

                                            this.setQueryParams([
                                                { key:'page', value: 0 },
                                                { key:'type', value: eachType.id },
                                            ])

                                            this.setState({ pageNumber: 0, activeTab: eachType.id }, () => {
                                                this.fetchReports()
                                            })

                                        }}>
                                        {eachType.label}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        {
                            reportTypes.map(eachType => {
                                if (eachType.id === "6") {
                                    return (
                                        <TabPane
                                            key={eachType.id}
                                            tabId={eachType.id}
                                            style={{ overflowY: 'scroll', paddingBottom: 50 }}
                                        >
                                            <ExplanationFeedBack
                                                sendNotificationToUser={this.sendNotificationToUser}
                                                deleteFeedback={this.deleteFeedback}
                                                explanationData={this.props.questionData.questions}
                                            />
                                        </TabPane>
                                    )
                                } else {
                                    return (
                                        <TabPane
                                            key={eachType.id}
                                            tabId={eachType.id}
                                            style={{ overflowY: 'scroll', paddingBottom: 50 }}
                                        >
                                            {
                                                this.props.questionData &&
                                                    this.props.questionData.questions &&
                                                    this.props.questionData.questions.length > 0 ?
                                                    <QuestionsTable
                                                        openEditPageInNewTab={true}
                                                        questionsData={this.props.questionData}
                                                        history={this.props.history}
                                                        skipCount={this.state.pageNumber * 50}
                                                        backRoute={'reports'}
                                                        issueType={this.state.issueType}
                                                        sortBy={this.state.sortBy}
                                                        roleIds={this.props.auth.roleIds}
                                                        customActionButton={(question, type) => this.setState({ deleteDialog: true, deleteItemId: question._id, reportActionStatus: type })}
                                                        getUser={(index) => this.getUser(index)}
                                                        additionalFieldTitle={"Count"}
                                                        additionalFieldProperty={"count"}
                                                    />
                                                    :
                                                    <h4 style={{ alignSelf: 'center', paddingTop: 100, textAlign: 'center' }}>No Reports Fetched</h4>
                                            }
                                        </TabPane>
                                    )
                                }
                            })
                        }
                    </TabContent>

                </div>
            </Aux>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth:state.auth,
        subjects: state.data.subjects,
        questionData: state.data.questions,
    }
}

export default connect(mapStateToProps, { updateQuestions })(ReportedQuestions)