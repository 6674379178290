import React, { useState, useEffect } from 'react'
import { Button, Container, Row, Table } from 'reactstrap'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { unstable_batchedUpdates } from 'react-dom'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons'

import { copyToClipboard } from '../utils'

function NotesFeedBack({ sortBy, setIsLoading, feedbackData, fetchReports }) {

    const [showusers, setShowusers] = useState(false)
    const [currentIndex, setCurrentIndex] = useState('')

    const sendNotificationTouser = (moduleId) => {
        setIsLoading(true)
        axios.post('/learn/send-notes-feedback-notification-to-users', { moduleId })
            .then(() => {
                fetchReports()
            })
            .catch((error) => {
                setIsLoading(false)
                console.log("Error while sending  notification to user")
                console.log(error)

            })
    }

    const deleteFeedback = (moduleId) => {

        if (window.confirm("Are you sure you want to delete?")) {

            setIsLoading(true)

            axios.delete('/learn/notes-feedback', { params: { moduleId } })
                .then(() => {
                    fetchReports()
                })
                .catch((error) => {
                    setIsLoading(false)
                    console.log("Error while sending  notification to user")
                    console.log(error)
                })
        }
    }

    return (
        <Container fluid>
        {
            Array.isArray(feedbackData) && feedbackData[0] ?
                <>

                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>modules</th>
                                    <th>Likes</th>
                                    <th>Dislike</th>
                                    <th>Feedback</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {
                                        feedbackData.map((each, index) => {
                                            return (
                                                <tr key={each._id}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <p>{each.moduleTitle}</p>
                                                    </td>
                                                    <td style={{ color: "green" }}>{each.learnSlideNote.likes ? each.learnSlideNote.likes : 0}</td>
                                                    <td style={{ color: "red" }}>{each.learnSlideNote.dislikes ? each.learnSlideNote.dislikes : 0}</td>
                                                    <td>
                                                        {
                                                            each.learnSlideNote.feedback ? each.learnSlideNote.feedback.map((item, itemIndex) => {
                                                                return (
                                                                    <React.Fragment key={item._id}>
                                                                        <p style={{ marginBottom: 10 }}>

                                                                            <span key={itemIndex}> {item ? item.feedbackText : null}</span>
                                                                            <br></br>
                                                                            {
                                                                                showusers && (currentIndex === index) ?
                                                                                    <span style={{ marginBottom: 40, color: '#625F63' }}>{item ? item.userId : "nill"}</span>
                                                                                    : null
                                                                            }
                                                                        </p>
                                                                    </React.Fragment>
                                                                )

                                                            }) : null
                                                        }
                                                    </td>
                                                    <td>

                                                        <Button
                                                            onClick={() => {
                                                                copyToClipboard(each._id)
                                                                toast("Module Id copied", {
                                                                    position: toast.POSITION.TOP_CENTER,
                                                                    hideProgressBar: true
                                                                })
                                                            }}
                                                            outline
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                style={{ color: 'grey' }}
                                                                icon={faCopy}
                                                            />
                                                        </Button>

                                                        <Button
                                                            outline
                                                            color='primary'
                                                            style={{ margin: 5 }}
                                                            onClick={() => {
                                                                unstable_batchedUpdates(() => {
                                                                    setShowusers(!showusers)
                                                                    setCurrentIndex(index)
                                                                })
                                                            }}
                                                        >
                                                            {showusers && (currentIndex === index) ? "Hide Users" : "Show Users"}
                                                        </Button>

                                                        <Link
                                                            to={`/module/${each._id}`}
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                style={{ margin: 5 }}
                                                                outline
                                                                color='primary'
                                                            >
                                                                Edit Notes
                                                            </Button>

                                                        </Link>
                                                        <Button
                                                            style={{ margin: 5 }}
                                                            outline
                                                            color='success'
                                                            onClick={() => sendNotificationTouser(each._id)}
                                                        >
                                                            Corrected
                                                        </Button>
                                                        <Button
                                                            style={{ margin: 5 }}
                                                            outline
                                                            color='danger'
                                                            onClick={() => deleteFeedback(each._id)}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                style={{ color: 'red' }}
                                                                icon={faTrash} 
                                                            />
                                                        </Button>
 
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                            </tbody>

                        </Table>
                    </Row>
                </>
                    :
                    <>
                        <Row style={{ justifyContent: 'center', paddingTop: 100 }}>
                            <h1>No Feedback Fetched</h1>
                        </Row>
                    </>
            }           
        </Container>
    )
}

export default NotesFeedBack