import React, { Component } from 'react'
import axios from 'axios'
import {
    Table, Button, Input, Label, Form, FormGroup, Container,
    ListGroup, ListGroupItem,
    Row, Col,
    Card, CardBody, CardFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import { connect } from 'react-redux'
import { DatePicker } from 'material-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'

import Loader from '../Components/Loader'
import { camelCaseToTitleCase } from '../utils';
import { isAdmin, isSalesPerson } from '../utils/dashboardRoles'

const stateItems = [
    "games",
    "marathon",
    "lastActiveWithin",
    "joiningDate",
    "totalGames",
    "marathonsPlayed",
    "joiningWithin",
    "examAttended",
    "dailyExamsAttended",
    "topicExamAttended",
    "currentAffairsQuiz",
    "practiceModulesCompleted",
    "byteQuiz",
    "batchModulesCompleted",
    "battleAttended",
]

const EachItem = ({ title, type, name, id, value, placeholder, onChange, disabled }) => {
    return (
        <FormGroup row>
            <Label for={id} sm={4} style={{ color: disabled ? 'grey' : 'black' }}>{title}:</Label>
            <Col sm={8}>
                <Input
                    type={type}
                    name={name}
                    id={id}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            </Col>
        </FormGroup>
    )
}
class Leads extends Component {

    state = {
        skip: 0,

        leadsData: null,
        file: null,

        totalRegistrations: 0,
        filterMatchedFreeUserCount: 0,
        filterMatchedPremiumUserCount: 0,
        unassignedUsers: 0,
        assignedUsers: 0,

        games: '',
        marathon: '',
        lastActiveWithin: '',
        joiningDate: new Date(),
        totalGames: 0,
        marathonsPlayed: 0,
        joiningWithin: 0,
        examAttended: 0,
        dailyExamsAttended: 0,
        topicExamAttended: 0,
        currentAffairsQuiz: 0,
        practiceModulesCompleted: 0,
        byteQuiz: 0,
        batchModulesCompleted: 0,
        battleAttended: 0,

        showLeadsDialog: false,

        unassignLeadsList: '',

        activeTab: 1,
        splittedNumbers: [],
        salesmen: [],

        selectedOs: 'all',
        premiumEndingDateTo: null,
        premiumEndingDateFrom: null,
        lastActiveIn: null,

        leadDataNumber: '',
        assigningDate: new Date()

    }

    getLeadsFiltersFromLocalStorage() {
        
        let setData = {}

        stateItems.forEach(eachItem => {
            const data = localStorage.getItem(eachItem)
            if (data) { setData[eachItem] = data }
        })

        const joiningDate = localStorage.getItem("joiningDate")
        if (joiningDate && joiningDate !== 'null') {
            setData.joiningDate = new Date(joiningDate)
        }

        this.setState(setData)

    }

    componentDidMount() {

        this.getLeadsFiltersFromLocalStorage()

        let setData = {}

        const salesTeamMembers = this.props.salesmen.filter((each) => {
            return Array.isArray(each.roleIds) && isSalesPerson(each.roleIds) && each.activeFlag === true
        })

        // const leadsPerSalesman = JSON.parse(localStorage.getItem('leadsPerSalesman'))

        // if (leadsPerSalesman) {
        //     const salesmenWithLeadsCount = salesTeamMembers.map((each, index) => {
        //         const foundData = leadsPerSalesman.find(e => e.salespersonId === each._id)
        //         if (foundData && foundData.leadsCount) {
        //             return { ...each, leadsCount: foundData.leadsCount }
        //         } else {
        //             return { ...each }
        //         }
        //     })
        //     setData.salesmenWithLeadsCount = salesmenWithLeadsCount
        // } else {
            setData.salesmenWithLeadsCount = cloneDeep(salesTeamMembers)
        // }

        setData.salesmen = salesTeamMembers
        this.setState(setData)

    }

    storeSearchInLocalStorage() {
        stateItems.forEach(eachItem => {
            localStorage.setItem(eachItem, this.state[eachItem])
        })
    }

    // generateCSV = () => {

    //     this.storeSearchInLocalStorage()

    //     let data = {
    //         limit: 100000,
    //         skip: this.state.skip,
    //         games: this.state.games,
    //         lastActiveWithin: this.state.lastActiveWithin,
    //         joiningDate: this.state.joiningDate,
    //         csv: true,
    //         marathon: this.state.marathon,

    //         examAttended: this.state.examAttended,
    //         dailyExamsAttended: this.state.dailyExamsAttended,
    //         topicExamAttended: this.state.topicExamAttended,
    //         currentAffairsQuiz: this.state.currentAffairsQuiz,
    //         practiceModulesCompleted: this.state.practiceModulesCompleted,
    //         byteQuiz: this.state.byteQuiz,
    //         batchModulesCompleted: this.state.batchModulesCompleted,
    //         battleAttended: this.state.battleAttended,
    //     }

    //     this.setState({ isLoading: true }, () => {
    //         axios.get('/leads', { params: data })
    //             .then((res) => {
    //                 const url = window.URL.createObjectURL(new Blob([res.data]));
    //                 const link = document.createElement('a');
    //                 link.href = url;
    //                 const fileName = `salesLeads.csv`
    //                 link.setAttribute('download', fileName);
    //                 document.body.appendChild(link);
    //                 link.click();
    //                 link.remove();
    //                 this.setState({ isLoading: false })
    //             })
    //             .catch((err) => {
    //                 this.setState({ isLoading: false })
    //                 console.log('error', err)
    //                 alert("Error, check console")
    //             })
    //     })

    // }

    fetchData = () => {

        this.storeSearchInLocalStorage()

        let data = {
            limit: 1000,
            skip: this.state.skip,
            games: this.state.games,
            lastActiveWithin: this.state.lastActiveWithin,
            joiningDate: this.state.joiningDate,
            csv: false,
            marathon: this.state.marathon,
            examAttended: this.state.examAttended,
            dailyExamsAttended: this.state.dailyExamsAttended,
            topicExamAttended: this.state.topicExamAttended,
            currentAffairsQuiz: this.state.currentAffairsQuiz,
            practiceModulesCompleted: this.state.practiceModulesCompleted,
            byteQuiz: this.state.byteQuiz,
            batchModulesCompleted: this.state.batchModulesCompleted,
            battleAttended: this.state.battleAttended,
            unassignedLeadsOnly: this.state.unassignedLeadsOnly,
            premiumUsersOnly: this.state.premiumUsersOnly,
            premiumEndingDateFrom: this.state.premiumEndingDateFrom,
            premiumEndingDateTo: this.state.premiumEndingDateTo,
            selectedOs: this.state.selectedOs
        }

        this.setState({ leadsData: null, isLoading: true }, () => {

            axios.get('/leads', { params: data })
                .then((res) => {
                    this.setState({
                        totalRegistrations: res.data.totalRegistrations,
                        filterMatchedFreeUserCount: res.data.filterMatchedFreeUserCount,
                        filterMatchedPremiumUserCount: res.data.filterMatchedPremiumUserCount,
                        unassignedUsers: res.data.unassignedUsers,
                        assignedUsers: res.data.assignedUsers,
                        isLoading: false
                    })
                })
                .catch((err) => {
                    console.log('error', err)
                    alert("Error, check console")
                    this.setState({ isLoading: false })
                })
        })

    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    getLeads() {

        const {
            salesmenWithLeadsCount,
            activeTab,
            assignByNumbersList,
            games,
            marathon,
            premiumEndingDateFrom,
            premiumEndingDateTo,
            lastActiveWithin,
            joiningDate,
            assigningDate,
            selectedOs
        } = this.state

        let leadsData = []
        let localStorageSetItem = []
        let requiredLeadsCount = 0

        salesmenWithLeadsCount.forEach(each => {
            if (each.leadsCount && parseInt(each.leadsCount) > 0) {
                requiredLeadsCount += parseInt(each.leadsCount)
                leadsData.push({ salespersonId: each._id, salespersonName: each.name, leadsCount: parseInt(each.leadsCount) })
                localStorageSetItem.push({ salespersonId: each._id, leadsCount: parseInt(each.leadsCount) })
            }
        });

        if (leadsData.length < 1) {
            return alert("Total leads should be greater than 0")
        }

        // localStorage.setItem('leadsPerSalesman', JSON.stringify(localStorageSetItem))

        this.storeSearchInLocalStorage()

        let postData = {
            assigningDate,
            leadsByPerson: leadsData,
        }

        if (activeTab === 1) {

            postData.totalGames = games
            postData.marathonsPlayed = marathon
            postData.lastActiveWithin = lastActiveWithin
            postData.joiningWithin = joiningDate
            postData.examAttended = this.state.examAttended
            postData.dailyExamsAttended = this.state.dailyExamsAttended
            postData.topicExamAttended = this.state.topicExamAttended
            postData.currentAffairsQuiz = this.state.currentAffairsQuiz
            postData.practiceModulesCompleted = this.state.practiceModulesCompleted
            postData.byteQuiz = this.state.byteQuiz
            postData.batchModulesCompleted = this.state.batchModulesCompleted
            postData.battleAttended = this.state.battleAttended
            postData.unassignedLeadsOnly = this.state.unassignedLeadsOnly
            postData.premiumUsersOnly = this.state.premiumUsersOnly
            postData.premiumEndingDateFrom = premiumEndingDateFrom
            postData.premiumEndingDateTo = premiumEndingDateTo
            postData.selectedOs = selectedOs

        } else if (activeTab === 2) {

            let text = assignByNumbersList

            if (text && text.length > 0) {

                let splittedNumbers = text.split("\n")
                const mobileNumbers = splittedNumbers.map(each => each.replace(/\s/g, ''))

                if (mobileNumbers.length !== requiredLeadsCount) {
                    return alert(`Total numbers typed: ${mobileNumbers.length} \nTotal leads per person selected: ${requiredLeadsCount} `)
                } else {
                    postData.mobileNumbers = mobileNumbers
                }
            } else {
                return alert("Enter mobile numbers")
            }

        }

        if (this.state.leadRemark) {
            postData.remark = this.state.leadRemark
        }

        this.setState({ isLoading: true })

        axios.post('/generate-leads', postData)
            .then(() => {
                this.setState({ showSuccessAlert: true, isLoading: false }, () => {
                    toast("Successfully assigned daily leads to salespersons", {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true
                    })
                })
            })
            .catch(err => {
                this.setState({ isLoading: false })
                if (err && err.response && err.response.status === 400) {
                    alert(err.response.data.message)
                } else {
                    console.log(err)
                    alert("Error, check console")
                }
            })

    }

    sendBroadcastMessage() {

        const {
            games,
            marathon,
            premiumEndingDateFrom,
            premiumEndingDateTo,
            lastActiveWithin,
            joiningDate,
            selectedOs,
            examAttended,
            dailyExamsAttended,
            topicExamAttended,
            currentAffairsQuiz,
            practiceModulesCompleted,
            byteQuiz,
            batchModulesCompleted,
            battleAttended,
            unassignedLeadsOnly,
            premiumUsersOnly,
            numberOfUsersToSendBroadcastMessage,
            broadcastMessage
        } = this.state
        


        if (!numberOfUsersToSendBroadcastMessage) {
            alert("Please enter a valid user count")
            return true
        } else if (!broadcastMessage) {
            alert("Please enter a broadcast message")
            return true
        }

        const postData = {
            userCount: numberOfUsersToSendBroadcastMessage,
            broadcastMessage,
            totalGames: games,
            marathonsPlayed: marathon,
            lastActiveWithin: lastActiveWithin,
            joiningWithin: joiningDate,
            examAttended,
            dailyExamsAttended,
            topicExamAttended,
            currentAffairsQuiz,
            practiceModulesCompleted,
            byteQuiz,
            batchModulesCompleted,
            battleAttended,
            unassignedLeadsOnly,
            premiumUsersOnly,
            premiumEndingDateFrom: premiumEndingDateFrom,
            premiumEndingDateTo: premiumEndingDateTo,
            selectedOs: selectedOs
        }

        this.setState({ isLoading: true })

        axios.post('/whatsapp/broadcast', postData)
            .then(() => {

                this.setState({ isLoading: false })

                toast("Successfully send whatsapp broadcast message", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
                alert("Server error. Check console")
            })

    }

    unassignLeads() {

        let text = this.state.unassignLeadsList

        if (!text) {
            return alert("Please enter at least one number")
        }

        let splittedNumbers = text.split("\n")
        const numbersIncludingEmpty = splittedNumbers.map(each => each.replace(/\s/g, ''))
        const numbers = numbersIncludingEmpty.filter(each => each)

        if (numbers.length <= 0) {
            return alert("Please enter atleast one mobile number")
        }

        this.setState({ isLoading: true }, () => {
            axios.post('/unassign', numbers)
                .then(() => {
                    this.setState({ unassignLeadsList: null, isLoading: false }, () => {
                        toast("Successfully unassigned leads", {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                        })
                    })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error, check console")
                })
        })

    }

    renderSalesMenList() {
        if (this.state.salesmenWithLeadsCount && this.state.salesmenWithLeadsCount.length > 0) {
            return (
                <ListGroup>
                    {
                        this.state.salesmenWithLeadsCount.map((salesman, index) => {
                            return (
                                <ListGroupItem key={salesman._id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div style={{ flex: 2 }}>
                                        <p>{salesman.name}</p>
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <Input
                                            type="number"
                                            name={`${salesman.name}leadsCount`}
                                            id={`${salesman.name}leadsCount`}
                                            value={this.state.salesmenWithLeadsCount[index].leadsCount || ''}
                                            onChange={(e) => {
                                                const value = e.target.value
                                                this.setState((oldState) => {
                                                    oldState.salesmenWithLeadsCount[index].leadsCount = value
                                                    return { salesmenWithLeadsCount: oldState.salesmenWithLeadsCount }
                                                })
                                            }}
                                            placeholder={'Leads Count'}
                                        />
                                    </div>
                                </ListGroupItem>
                            )
                        })
                    }
                </ListGroup>
            )
        } else {
            return (
                <ListGroupItem>
                    <ListGroup>
                        <h5>No salesmen found</h5>
                    </ListGroup>
                </ListGroupItem>
            )
        }
    }

    renderAssignLeadsBtn() {
        return (
            <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button
                    color="primary"
                    outline
                    onClick={() => this.getLeads()}
                >
                    Assign Leads
                </Button>
            </ListGroupItem>
        )
    }

    renderRightSideBar() {
        return (
            <>
                <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ flex: 3 }}>
                        <p>Assigning for Date</p>
                    </div>

                    <div style={{ flex: 1 }}>
                        <DatePicker
                            container="inline"
                            hintText="Assigning for Date"
                            defaultDate={this.state.assigningDate}
                            style={{ paddingLeft: 30, paddingRight: 30 }}
                            onChange={(event, assigningDate) => this.setState({ assigningDate })}
                        />
                    </div>
                </ListGroupItem>

                <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ flex: 2 }}>
                        <p>{"Lead Remarks (Optional)"}</p>
                    </div>

                    <div style={{ flex: 1 }}>
                        <Input
                            type="textarea"
                            name={`leadRemark`}
                            id={`leadRemark`}
                            value={this.state.leadRemark || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                this.setState({ leadRemark: value })
                            }}
                            placeholder={'Leads Remark'}
                            rows={3}
                        />
                    </div>
                </ListGroupItem>

                <br />

                {this.renderSalesMenList()}
                
            </>
        )
    }

    renderFilterInputs() {
        return (
            <Card>
                <Form>

                    <CardBody>

                        <EachItem
                            title={"Total Games"}
                            type="number"
                            name="games"
                            id="gamesModal"
                            value={this.state.games}
                            onChange={this.handleInput}
                            placeholder={this.state.games}
                        />

                        <EachItem
                            title={"Marathon"}
                            type="number"
                            name="marathon"
                            id="marathon"
                            value={this.state.marathon}
                            onChange={this.handleInput}
                            placeholder={this.state.marathon}
                        />

                        <FormGroup row>
                            <Label for="joiningDate" sm={4}>Joined After</Label>
                            <Col sm={8}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <DatePicker
                                        style={{ paddingRight: 30 }}
                                        container="inline"
                                        hintText="Joined After"
                                        value={this.state.joiningDate}
                                        defaultDate={this.state.joiningDate}
                                        onChange={(event, joiningDate) => this.setState({ joiningDate })}
                                    />

                                    {this.state.joiningDate &&
                                        <Button
                                            variant="outline-danger"
                                            style={{ height: 40, width: 40, display: 'inline' }}
                                            onClick={() => this.setState({ joiningDate: null })}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    }
                                </div>
                            </Col>

                        </FormGroup>

                        <FormGroup row>
                            <Label for="lastActiveWithin" sm={4}>Last Active:</Label>
                            <Col sm={8}>
                                <Input
                                    type="number" name="lastActiveWithin" id="lastActiveWithin"
                                    value={this.state.lastActiveWithin}
                                    onChange={this.handleInput}
                                    placeholder={this.state.lastActiveWithin} />
                            </Col>
                        </FormGroup>

                        <EachItem
                            title={"PYQ & mock exams attended"}
                            type="number"
                            name="examAttended"
                            id="examAttended"
                            value={this.state.examAttended}
                            onChange={this.handleInput}
                            placeholder={this.state.examAttended}
                        />

                        <EachItem
                            title={"Daily exams attended"}
                            type="number"
                            name="dailyExamsAttended"
                            id="dailyExamsAttended"
                            value={this.state.dailyExamsAttended}
                            onChange={this.handleInput}
                            placeholder={this.state.dailyExamsAttended}
                        />

                        <EachItem
                            title={"Topic exams attended"}
                            type="number"
                            name="topicExamAttended"
                            id="topicExamAttended"
                            value={this.state.topicExamAttended}
                            onChange={this.handleInput}
                            placeholder={this.state.topicExamAttended}
                        />

                        <EachItem
                            title={"Daily current affairs attended"}
                            type="number"
                            name="currentAffairsQuiz"
                            id="currentAffairsQuiz"
                            value={this.state.currentAffairsQuiz}
                            onChange={this.handleInput}
                            placeholder={this.state.currentAffairsQuiz}
                        />

                        <EachItem
                            title={"Byte Quiz Attended"}
                            type="number"
                            name="byteQuiz"
                            id="byteQuiz"
                            value={this.state.byteQuiz}
                            onChange={this.handleInput}
                            placeholder={this.state.byteQuiz}
                        />

                        <EachItem
                            title={"Practice modules completed"}
                            type="number"
                            name="practiceModulesCompleted"
                            id="practiceModulesCompleted"
                            value={this.state.practiceModulesCompleted}
                            onChange={this.handleInput}
                            placeholder={this.state.practiceModulesCompleted}
                        />

                        <EachItem
                            title={"Batch modules completed"}
                            type="number"
                            name="batchModulesCompleted"
                            id="batchModulesCompleted"
                            value={this.state.batchModulesCompleted}
                            onChange={this.handleInput}
                            placeholder={this.state.batchModulesCompleted}
                        />

                        <EachItem
                            title={"Premium Users Only"}
                            type="checkbox"
                            name="premiumUsersOnly"
                            id="premiumUsersOnly"
                            disabled={false}
                            onChange={(e) => this.setState({ premiumUsersOnly: e.target.checked })}
                            placeholder={this.state.premiumUsersOnly}
                        />

                        {
                            this.state.premiumUsersOnly ?
                                <>
                                    <FormGroup>
                                        <Col style={{ display: 'flex', marginBottom: '1rem' }}>
                                            <Label sm={4}>Premium Ending Date From</Label>
                                            <DatePicker
                                                container="inline"
                                                hintText="Premium Ending Date From: "
                                                value={this.state.premiumEndingDateFrom}
                                                style={{ paddingLeft: 30, paddingRight: 30, marginLeft: '1rem' }}
                                                onChange={(event, premiumEndingDateFrom) => this.setState({ premiumEndingDateFrom })}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup>
                                        <Col style={{ display: 'flex', marginBottom: '1rem' }}>
                                            <Label sm={4}>Premium Ending Date To</Label>
                                            <DatePicker
                                                container="inline"
                                                hintText="Premium Ending Date To: "
                                                value={this.state.premiumEndingDateTo}
                                                style={{ paddingLeft: 30, paddingRight: 30, marginLeft: '1rem' }}
                                                onChange={(event, premiumEndingDateTo) => this.setState({ premiumEndingDateTo })}
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                                : null
                        }

                        <EachItem
                            title={"Select Previous Unassigned Leads Only"}
                            type="checkbox"
                            name="unassignedLeadsOnly"
                            id="unassignedLeadsOnly"
                            disabled={false}
                            onChange={(e) => this.setState({ unassignedLeadsOnly: e.target.checked })}
                            placeholder={this.state.unassignedLeadsOnly}
                        />

                        <FormGroup row>
                            <Label sm={4} style={{ color: 'black' }}>User Device: </Label>
                            <Col sm={8}>
                                <UncontrolledDropdown style={{ margin: 10 }}>
                                    <DropdownToggle caret>
                                        {camelCaseToTitleCase(this.state.selectedOs)}
                                    </DropdownToggle>
                                    <DropdownMenu className="addScroll">
                                        {
                                            [
                                                { label: 'All', value: 'all' },
                                                { label: 'iOS', value: 'ios' },
                                                { label: 'Android', value: 'android' }
                                            ].map((each) => {
                                                return (
                                                    <DropdownItem
                                                        key={each.value}
                                                        value={each.value}
                                                        onClick={() => this.setState({ selectedOs: each.value })}
                                                    >
                                                        {each.label}
                                                    </DropdownItem>)
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </FormGroup>

                    </CardBody>

                    <CardFooter>
                        <Button color="success"
                            onClick={() => this.fetchData()}
                            style={{ paddingLeft: 50, paddingRight: 50, marginLeft: 20 }}>Submit </Button>
                        {/* {
                                                    isAdmin(this.props.roleIds) &&
                                                    <Button
                                                        onClick={() => this.generateCSV()}
                                                        outline color="primary" style={{ marginLeft: 20 }}>Generate CSV </Button>
                                                } */}
                    </CardFooter>
                </Form>
            </Card>
        )
    }

    renderFilterOut() {
        return (
            <Card>
            <CardBody>
                <h4>Query Results</h4>
                <br />

                <p>Total Registrations (No filters): <strong>{this.state.totalRegistrations}</strong></p>
                <br />

                <p><b>With Filters:</b></p>
                <p>All Users: <strong>{this.state.filterMatchedFreeUserCount + this.state.filterMatchedPremiumUserCount}</strong></p>
                <p>Free Users: <strong>{this.state.filterMatchedFreeUserCount}</strong></p>
                <p>Premium Users: <strong>{this.state.filterMatchedPremiumUserCount}</strong></p>
                <br />

                <p>Assigned Leads: <strong>{this.state.assignedUsers}</strong></p>
                <p>Unassigned Leads: <strong>{this.state.unassignedUsers}</strong></p>

            </CardBody>
        </Card>
        )
    }

    render() {
        return (

            <>
                {this.state.isLoading && <Loader />}

                <Container fluid style={{ marginTop: 50, marginBottom: 50 }}>

                    <Nav tabs>

                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 1 ? 'active' : null}
                                onClick={() => this.setState({ activeTab: 1 })}
                            >
                                Assign by Filters
                            </NavLink>
                        </NavItem>

                        {
                            isAdmin(this.props.roleIds) &&
                            <NavItem>
                                <NavLink
                                    className={this.state.activeTab === 3 ? 'active' : null}
                                    onClick={() => this.setState({ activeTab: 3 })}
                                >
                                    WhatsApp Broadcast
                                </NavLink>
                            </NavItem>
                        }

                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 2 ? 'active' : null}
                                onClick={() => this.setState({ activeTab: 2 })}
                            >
                                Assign Numbers
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 4 ? 'active' : null}
                                onClick={() => this.setState({ activeTab: 4 })}
                            >
                                Unassign Leads
                            </NavLink>
                        </NavItem>

                    </Nav>

                    <TabContent activeTab={this.state.activeTab} style={{ paddingTop: 20 }}>

                        <TabPane tabId={1} style={{ overflowY: 'scroll', paddingBottom: 50  }}>

                            { this.renderAssignLeadsBtn() }

                            <Row>
                                <Col md="6">
                                    { this.renderFilterInputs () }
                                </Col>

                                <Col md="6">
                                    <ListGroup>
                                        { this.renderFilterOut () }
                                        { this.renderRightSideBar() }
                                    </ListGroup>

                                </Col>
                            </Row>

                        </TabPane>

                        <TabPane tabId={2} style={{ overflowY: 'scroll', paddingBottom: 50  }}>

                            { this.renderAssignLeadsBtn() }

                            <Row>
                                <Col md="6">
                                    <Input
                                        type="textarea"
                                        rows="10"
                                        name="assignByNumbersList"
                                        id="assignByNumbersList"
                                        value={this.state.assignByNumbersList || ''}
                                        onChange={this.handleInput}
                                        placeholder={"One mobile number per line please"}
                                    />
                                </Col>
                                <Col md="6">
                                    { this.renderRightSideBar() }
                                </Col>
                            </Row>

                        </TabPane>

                        <TabPane tabId={3} style={{ overflowY: 'scroll', paddingBottom: 50 }}>

                            <Row>
                                <Col md="6">
                                    {this.renderFilterInputs()}
                                </Col>
                                <Col md="6">
                                    { this.renderFilterOut() }
                                    
                                    <ListGroup>

                                        <ListGroupItem>
                                            <EachItem
                                                title={"Number of users"}
                                                type="number"
                                                name="numberOfUsersToSendBroadcastMessage"
                                                id="numberOfUsersToSendBroadcastMessage"
                                                value={this.state.numberOfUsersToSendBroadcastMessage}
                                                onChange={this.handleInput}
                                                placeholder={"Number of users"}
                                            />
                                        </ListGroupItem>
                                        
                                        <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <Label sm={4}>Broadcast Message</Label>

                                            <Col sm={8}>
                                                <Input
                                                    type="textarea"
                                                    name={`broadcastMessage`}
                                                    id={`broadcastMessage`}
                                                    value={this.state.broadcastMessage || ''}
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        this.setState({ broadcastMessage: value })
                                                    }}
                                                    placeholder={'Broadcast Message'}
                                                    rows={10}
                                                />
                                            </Col>
                                            
                                        </ListGroupItem>
                                        <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => this.sendBroadcastMessage()}
                                            >
                                                Send Broadcast Message
                                            </Button>
                                        </ListGroupItem>

                                    </ListGroup>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tabId={4} style={{ overflowY: 'scroll', paddingBottom: 50  }}>

                            <Row>
                                <Col sm="12">
                                    <Input
                                        type="textarea"
                                        rows="10"
                                        name="unassignLeadsList"
                                        id="unassignLeadsList"
                                        value={this.state.unassignLeadsList || ''}
                                        onChange={this.handleInput}
                                        placeholder={"One mobile number per line please"}
                                    />
                                </Col>
                                <Col sm="12">
                                    <ListGroup>
                                        <ListGroupItem>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => this.unassignLeads()}
                                            >
                                                Unassign Leads
                                            </Button>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                            </Row>

                        </TabPane>

                    </TabContent>

                    {this.state.leadsData !== null &&
                        <Table>
                            <thead>
                                <tr>
                                    <th> # </th>
                                    <th> Name </th>
                                    <th> Mobile </th>
                                    <th> Games </th>
                                    <th> Marathon </th>
                                    <th> Joining Date </th>
                                    <th> Last Activity </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.leadsData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{index + 1}</p></td>
                                            <td><p>{item.name}</p></td>
                                            <td><p>{item.mobile}</p></td>
                                            <td><p>{item.totalGames}</p></td>
                                            <td><p>{item.analytics.marathonsPlayed || 0}</p></td>
                                            <td><p>{moment(item.joiningDate).format("YYYY MMMM D, h:mm:ss a")} </p></td>
                                            <td><p>{moment(item.lastActivity).format("YYYY MMM D, h:mm:ss a")} </p></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleIds: state.auth.roleIds,
        salesmen: state.data.salesmen
    }
}

export default connect(mapStateToProps)(Leads)
