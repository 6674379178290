import React, { Component } from 'react'
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import CheckBox from 'material-ui/Checkbox';
import FlatButton from 'material-ui/FlatButton';
import axios from 'axios'
import Dialog from 'material-ui/Dialog';
import {
    Container, Row, Col, CustomInput,
    Form, Button, FormGroup,
    Modal, ModalBody, ModalFooter, ModalHeader,
    Badge, Input, Table, 
    ListGroupItem, Collapse, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown
} from 'reactstrap';
import { connect } from 'react-redux'
import '../App.css'

import CircularProgress from 'material-ui/CircularProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import striptags from 'striptags'
import { toast } from 'react-toastify'

import StatementType, { generateStatementHtmlQuestion } from '../Pages/QuestionGenerator/StatementType';
import MatchType, { generateMatchHtmlQuestion } from '../Pages/QuestionGenerator/MatchType';

import { isContentManagerOrHigher, isContentTeam, isCurrentAffairsManagerOrHigher } from '../utils/dashboardRoles';
import { applyColorToHtmlQuestion, copyToClipboard, fixSpacesBetweenStrings } from '../utils';
import EditorOptionModal from './EditorOptionModal';
import SimilarQuestions from '../Components/SimilarQuestions'
import ExplanationEditor from './ExplanationEditor';
import { QUESTION_LANGUAGE_REGEX, REMOVE_CHARS_REGEX, SEARCH_SERVER } from '../config/env'
import MathQuestionViewer from './MathQuestionViewer';
import TinyEditor from './TinyEditor';
import { cloneDeep, sortBy } from 'lodash';
import RelatedModulesModal from './RelatedModulesModal';

export const questionTypes = {
    TEXT: 'text',
    HTML: 'html',
    MATH: 'math',
    STATEMENT: 'statement',
    MATCH: 'match'
}

export const getHtmlQuestion = (type, baseQuestion, statements) => {
    if (type === questionTypes.STATEMENT) {
        return generateStatementHtmlQuestion(baseQuestion, statements)
    } else if (type === questionTypes.MATCH) {
        return generateMatchHtmlQuestion(
            baseQuestion,
            statements[0].statementLeftSide,
            statements[1].statementLeftSide,
            statements[2].statementLeftSide,
            statements[3].statementLeftSide,
            [
                statements[0].shuffledOption,
                statements[1].shuffledOption,
                statements[2].shuffledOption,
                statements[3].shuffledOption,
            ]
        )
    }
}

class QuestionForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            openAddSuccess: false,
            showAllSubject: false,
            islanguagePrediction: true,
            isExamListOpen: false,
            subject: '',
            exams: [],
            question: '', 
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            answer: '',
            description: '',
            relatedModules: [],
            // approved:true,
            dailyChallengeQuestion: false,
            showInChallenge: false,
            premiumFlag: true,
            splittedWords: [],
            selectedWords: [],
            subjectId: '',
            sectionId: '',
            languageId: "",
            type:"text",
            explanationType:"text",
            similarQuestions: [], 
            
            availablePacks: null,
            pinnedPathUnits: {},
            selectedPathIndex: 0,
            selectedPathUnits: [],

        }
    }


    clickedWord(eachItem) {

        let foundItem = this.state.selectedWords.find(e => e === eachItem)

        let question = ''

        if (this.state.type === questionTypes.MATCH || this.state.type === questionTypes.STATEMENT) {
            question = this.state.baseQuestion
        } else {
            question = this.state.question
        }

        if (foundItem) {
            let afterRemoving = this.state.selectedWords.filter(e => e !== eachItem)
            let wordsFromThisQuestion = afterRemoving.filter(e => question.split(" ").includes(e))
            this.setState({ selectedWords: wordsFromThisQuestion })
        } else {
            let afterAdding = this.state.selectedWords.concat([eachItem])
            let wordsFromThisQuestion = afterAdding.filter(e => question.split(" ").includes(e))
            this.setState({ selectedWords: wordsFromThisQuestion })
        }
    }

    predictData() {

        const { type, selectedWords, question, baseQuestion } = this.state

        let text = ""
        let unselected = []

        if (type === questionTypes.STATEMENT || type === questionTypes.MATCH) {
            text = baseQuestion
            unselected = baseQuestion.split(" ").filter(e => !(selectedWords.includes(e)))
        } else if (type === questionTypes.TEXT) {
            text = question
            unselected = question.split(" ").filter(e => !(selectedWords.includes(e)))
        }

        axios.post('/predict', { selected: selectedWords, unselected, text })
            .then(res => {

                const { sectionData, descriptions } = res.data

                if (descriptions && descriptions.length > 0) { this.setState({ descriptions }) }


                let subject = this.props.subjects.find(item => {
                    let found = item.sections.find(section => section._id === sectionData[0].sectionId)
                    return found
                })

                const clonedSections = cloneDeep(subject.sections)
                const sortedSections = sortBy(clonedSections, ['name'])

                this.setState({ subjectId: subject._id, sections: sortedSections }, () => {
                    this.setState({ sectionId: sectionData[0].sectionId })
                })
                
            }).catch(err => {
                console.error('/predict')
                console.error(err)
                alert("Unable to predict subject and section due to server error.\nPlease check console")
            })
    }

    fetchSimilarQuestions(question) {
        if (question) {
            axios.get(`${SEARCH_SERVER}/search/similar-questions`, { params: { question: striptags(question) } })
                .then(res => this.setState({ similarQuestions: res.data }))
                .catch(err => {
                    console.error('search_server/search/similar-questions')
                    console.error(err)
                })
        }
    }

    componentDidUpdate(prevProps, oldState) {
        if (this.state.question && oldState.question !== this.state.question && this.state.type === 'text') {
            this.setState({ splittedWords: this.state.question.split(" ") })
        } else if (this.state.baseQuestion && oldState.baseQuestion !== this.state.baseQuestion) {
            this.setState({ splittedWords: this.state.baseQuestion.split(" ") })
        }
    }

    handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        const spacesFixedOption = fixSpacesBetweenStrings(value)
        this.setState({ [name]: spacesFixedOption })
    }

    handleMenuChange = (name, value) => {
        this.setState({ [name]: value })
    }


    formIsValid() {

        const { question, baseQuestion, type, answer, option1, option2, option3, option4, subjectId, sectionId, languageId, description } = this.state

        if (
            (question || (baseQuestion && (type === questionTypes.MATCH || type === questionTypes.STATEMENT))) && 
            answer && option1 && option2 && option3 && option4 && subjectId && sectionId && languageId && (description && description.trim())
        ) {
            return true
        } else {
            return false
        }

    }

    componentDidMount() {
       
        const availablePacks = this.props.pathUnits.filter(each => Array.isArray(each.allowedMembers) &&  each.allowedMembers.includes(this.props.auth._id))

        const data = availablePacks.map(eachPack => {
            eachPack.units = eachPack.units.filter(eachUnit => {
                if (eachUnit.activeFlag) {
                    eachUnit.topics = eachUnit.topics.filter(eachTopic => eachTopic.activeFlag)
                    return true
                } else {
                    return false
                }
            })
            return eachPack
        })

        this.setState({ availablePacks: data })

        if (this.props && this.props.question) {
            const { _id, question, options, answer, subjectId } = this.props.question.question;
            this.setState({
                _id: _id,
                question: question,
                option1: options[0].text,
                option2: options[1].text,
                option3: options[2].text,
                option4: options[3].text,
                answer: answer,
                subjectId: subjectId
            })
        }

    }



    submitQuestion() {

        this.setState({ isLoading: true })

        const { question, type, description, explanationType } = this.state
        
        let questionText = question

        if (type === "html" || type === "math") {
            questionText = applyColorToHtmlQuestion(questionText)
        }

        let descriptionText = description

        if (explanationType === 'math' || explanationType === 'html') {
            descriptionText = applyColorToHtmlQuestion(descriptionText)
        }

        let data = {
            question: questionText,
            type: this.state.type,
            options: [
                {
                    id: 'A',
                    text: this.state.option1,
                    type: this.state.option1Type,
                },
                {
                    id: 'B',
                    text: this.state.option2,
                    type: this.state.option2Type,
                },
                {
                    id: 'C',
                    text: this.state.option3,
                    type: this.state.option3Type,
                },
                {
                    id: 'D',
                    text: this.state.option4,
                    type: this.state.option4Type,
                },
            ],
            answer: this.state.answer,
            // approved: this.state.approved,
            subjectId: this.state.subjectId,
            sectionId: this.state.sectionId,
            languageId: this.state.languageId,
            dailyChallengeQuestion: this.state.dailyChallengeQuestion,
            showInChallenge: this.state.showInChallenge,
            premiumFlag: this.state.premiumFlag,
            exams: this.state.exams,
            description: descriptionText,
            explanationType,
            pathUnits: this.state.selectedPathUnits,
            paragraphId: this.state.paragraphId,
            relatedModules: this.state.relatedModules
        }

        if (this.state.type === questionTypes.STATEMENT || this.state.type === questionTypes.MATCH) {
            data.baseQuestion = this.state.baseQuestion
            data.statements = this.state.statements
            data.question = getHtmlQuestion(this.state.type, this.state.baseQuestion, this.state.statements)
        }

        axios.post(`/add-question`, data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    openAddSuccess: true,
                    addedQuestionId: response.data.data._id
                })
            })
            .catch((error) => {
                alert("Error adding question")
                console.log("Error Adding Question : ", error)
                this.setState({ isLoading: false })
            })
    }

    addOrRemoveExam(examId) {
        let foundIndex = this.state.exams.findIndex((each) => {
            return each === examId
        })

        if (foundIndex > -1) {
            this.setState((oldState) => {
                return { exams: oldState.exams.slice(0, foundIndex) }
            })
        } else {
            this.setState((oldState) => {
                oldState.exams.push(examId)
                return { exams: oldState.exams }
            })
        }
    }

    clearData = () => {
        this.setState({
            question: null,
            type: "text",
            explanationType:"text",
            option1: null,
            inputText: '',
            option1Type: "text",
            option2: null,
            option2Type: "text",
            option3: null,
            option3Type: "text",
            option4: null,
            option4Type: "text",
            answer: null,
            // approved:true,
            description: null,
            descriptions: null,
            similarQuestions: [],
            selectedWords: [],
            splittedWords: [],
            openAddSuccess: false,
            showRawHtmlExplanation: false,
            relatedModules: []
        })
    }

    onFormSubmit = (e) => {
        this.setState({  fileUploading: true })
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        e.preventDefault();
        const formData = new FormData();
        formData.append('csvFile', this.state.file);
        axios.post('/addQuestionFromCSV', formData, config)
            .then(res => {
                this.setState({ fileUploading: false})
            })
            .catch(err => {
                this.setState({ fileUploading: false})
                console.error('onFormSubmit')
                console.error(err)
                alert("Server error. Please check console")
            })
    }

    addOrRemoveFromPathUnitTopic(unitId) {

        let foundIndex = this.state.selectedPathUnits.findIndex((each) => each === unitId)

        if (foundIndex > -1) {
            this.setState((oldState) => {
                oldState.selectedPathUnits.splice(foundIndex, 1)
                return { selectedPathUnits: oldState.selectedPathUnits }
            })
        } else {
            this.setState((oldState) => {
                oldState.selectedPathUnits.push(unitId)
                return { selectedPathUnits: oldState.selectedPathUnits }
            })
        }

    }
    
    addOrRemoveUnitToPinnedModules( unitIndex, topicLength){
        let arrayOfTopicIndexes = []
        for (let i = 0; i < topicLength; i++) { arrayOfTopicIndexes.push(i) }
        
        this.setState(oldState => {
            const pinnedUnits = oldState.pinnedPathUnits
            if (Object.keys(pinnedUnits).includes(`${unitIndex}`)) {
                delete pinnedUnits[`${unitIndex}`]
            } else {
                pinnedUnits[unitIndex] = arrayOfTopicIndexes
            }
            return { pinnedPathUnits: pinnedUnits }
            })
    } 

    renderPinnedUnits(units) {
        return units.map((eachUnit, unitIndex) => {
            if (Object.keys(this.state.pinnedPathUnits).includes(`${unitIndex}`)) {
                return (
                    <div key={eachUnit._id} style={{ border: '1px solid #000' }}>
                        <div style={{display:"flex", justifyContent:'space-between'}}>
                            <h6 style={{ marginLeft: 10 }}>{eachUnit.unitName}</h6>
                            <FontAwesomeIcon
                                icon={faArrowCircleDown}
                                onClick={ () => this.addOrRemoveUnitToPinnedModules(unitIndex, eachUnit.topics.length) } 
                            />
                        </div>
                        {
                            eachUnit.topics && eachUnit.topics.length > 0 && eachUnit.topics.map((eachTopic, topicIndex) => {
                                
                                if (this.state.pinnedPathUnits[unitIndex].includes(topicIndex)) {
                                    
                                    const checked = this.state.selectedPathUnits.includes(eachTopic._id)

                                    return (
                                        <div key={eachTopic._id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <CustomInput
                                                onChange={(event) => { this.addOrRemoveFromPathUnitTopic(event.target.value, this.state.selectedPathIndex, unitIndex, topicIndex, checked) }}
                                                id={eachTopic._id}
                                                key={eachTopic._id}
                                                value={eachTopic._id}
                                                type="checkbox"
                                                label={eachTopic.name}
                                                checked={checked}
                                            />
                                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <FontAwesomeIcon
                                                    icon={faArrowCircleDown}
                                                    style={{height: 10}}
                                                    onClick={ () => this.addOrRemoveToPinnedModules(unitIndex, topicIndex) } 
                                                />
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                )
            }
        })
    }

    renderEditorModal() {
        return (
            <Modal
                isOpen={this.state.showEditorModal}
                toggle={() => this.setState({ showEditorModal: false })}
            >

                <ModalHeader>
                    Editor Options
                </ModalHeader>

                <ModalBody>
                    <CheckBox
                        label={'Show All Subjects'}
                        style={{ marginTop: '10px' }}
                        checked={this.state.isShowAllSubjectChecked}
                        onClick={() => { this.setState({ isShowAllSubjectChecked: !this.state.isShowAllSubjectChecked })}}
                    />
                    <CheckBox
                        label={'Language Prediction'}
                        style={{ marginTop: '10px' }}
                        checked={this.state.islanguagePredictionChecked}
                        onClick={() => this.setState({ islanguagePredictionChecked: !this.state.islanguagePredictionChecked })}
                    />
                </ModalBody>

                <ModalFooter>

                    <Button color="primary"
                        onClick={() => this.setState({ showEditorModal: false, showAllSubject: this.state.isShowAllSubjectChecked, islanguagePrediction: this.state.islanguagePredictionChecked})}
                    >
                        Submit
                    </Button>

                    <Button
                        outline
                        onClick={() => this.setState({ showEditorModal: false })}
                        color="secondary">
                        Cancel
                    </Button>

                </ModalFooter>

            </Modal>
        )
    }

    renderPathModules(units, pinnedModules) {
        return units.map((eachUnit, unitIndex) => {
            return (
                <div key={eachUnit._id} style={{ border: '1px solid #000' }}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <h6 style={{ marginLeft: 10 }}>{eachUnit.unitName}</h6>
                        <FontAwesomeIcon
                            icon={faArrowCircleUp}
                            onClick={ () => this.addOrRemoveUnitToPinnedModules(unitIndex, eachUnit.topics.length) } 
                        />
                    </div>
                    {
                        eachUnit.topics && eachUnit.topics.length > 0 && eachUnit.topics.map((eachTopic, topicIndex) => {
                            // const checked = eachTopic.questions.includes(this.state._id)
                            const checked = this.state.selectedPathUnits.includes(eachTopic._id)
                            return (
                                <div key={eachTopic._id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CustomInput
                                        onChange={(event) => { this.addOrRemoveFromPathUnitTopic(event.target.value, this.state.selectedPathIndex, unitIndex, topicIndex, checked) }}
                                        id={eachTopic._id}
                                        key={eachTopic._id}
                                        value={eachTopic._id}
                                        type="checkbox"
                                        label={eachTopic.name}
                                        checked={checked}
                                    />

                                    {
                                        !pinnedModules &&
                                        <div
                                            
                                            style={{ paddingLeft: 10, paddingRight: 10 }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowCircleUp}
                                                style={{height: 12}}
                                                onClick={ () => this.addOrRemoveToPinnedModules(unitIndex, topicIndex) } 
                                            />
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            )
        })
    }

    addOrRemoveToPinnedModules(unitIndex, topicIndex) {

        this.setState(oldState => {

            const pinnedUnits = oldState.pinnedPathUnits

            if (Object.keys(pinnedUnits).includes(`${unitIndex}`)) {
                if (pinnedUnits[unitIndex].includes(topicIndex)) {
                    pinnedUnits[unitIndex].splice(topicIndex, 1)
                    let test = pinnedUnits[unitIndex].filter(item => item !== topicIndex)
                    pinnedUnits[unitIndex] = test
                } else {
                    pinnedUnits[unitIndex].push(topicIndex)
                }
            } else {
                pinnedUnits[unitIndex] = [topicIndex]
            }
            return { pinnedPathUnits: pinnedUnits }

        })
    }

    splitAndDistributeToOptions(e, onPasteIndex) {
        
        let splitStrArr = e.target.value.trim().split('\n')

        if (splitStrArr.length > 1) {
            splitStrArr.forEach((item, index) => {
                if (index < 4) {
                    this.setState((oldState) => {
                        const trimmedItem = item.trim()
                        const spacesFixedOption = fixSpacesBetweenStrings(trimmedItem)
                        oldState[`option${index + onPasteIndex}`] = spacesFixedOption
                        return oldState
                    })
                }
            })
        } else {
            this.handleInput(e)
        }

    }

    updateOptions = (baseQuestion, statements, fourOptions) => {

        const setObject = { }

        if (Array.isArray(fourOptions) && fourOptions.length === 4) {

            let optionsArray = ["A", "B", "C", "D"]
            
            optionsArray.forEach((eachOption, index) => {

                if (fourOptions[index].isCorrect) {
                    setObject.answer = eachOption
                }

                setObject[`option${index + 1}`] = fourOptions[index].text
                setObject[`option${index + 1}Type`] = "text"

            })
            
            if (!setObject.answer) {
                return alert("Correct answer not found")
            }

        } else {
            alert("Need exactly four options")
        }

        if (this.state.type === questionTypes.STATEMENT && (!Array.isArray(statements) || statements.length <= 0)) {
            return ("Should have at least two statements")
        } else {
            setObject.statements = statements
        }


        if (this.state.type === questionTypes.MATCH && (!Array.isArray(statements) || statements.length !== 4)) {
            return ("Should have exactly four statements")
        } else {
            setObject.statements = statements
        }

        if (baseQuestion) {
            setObject.baseQuestion = baseQuestion
        } else {
            alert("Unable to find base question. Check console")
        }

        setObject.question = getHtmlQuestion(this.state.type, baseQuestion, statements)

        this.setState(setObject)

    }

    onBlurQuestion = (baseQuestion) => {

        // 1
        this.fetchSimilarQuestions(baseQuestion)

        // 2. Set question language automatically
        if (baseQuestion && this.state.islanguagePrediction) {
            if (RegExp(QUESTION_LANGUAGE_REGEX).test(baseQuestion.replace(REMOVE_CHARS_REGEX, ""))) {
                this.setState({ languageId: this.props.languages[1].id })
            } else {
                this.setState({ languageId: this.props.languages[0].id })
            }
        }

    }

    renderQuestionField = () => {

        const { type } = this.state

        if (type === "text") {
            return (
                <Input
                    id={"8"}
                    placeholder="Question"
                    name="question"
                    type="textarea"
                    rows={3}
                    value={this.state.question || ''}
                    onChange={this.handleInput}
                    style={{ backgroundColor: 'rgba(0, 34, 255,0.1)', borderRadius: 5, padding: 10 }}
                    onBlur={() => {
                        this.onBlurQuestion(this.state.question)
                        const spacesRemovedQuestion = fixSpacesBetweenStrings(this.state.question)
                        this.setState({ question: spacesRemovedQuestion })
                    }}
                />
            )
        } else if (this.state.showRawHtml) {
            return (
                <Col md="12">
                    <Input
                        type="textarea"
                        id="questionHtml"
                        rows={10}
                        onChange={(e) => this.setState({ question: e.target.value })}
                        value={this.state.question}
                    />
                </Col>
            )
        } else if (type === "html" || type === "math") {
            return (
                <TinyEditor
                    content={this.state.question}
                    contentCss={'dark'}
                    onHtmlChange={ (newHtml) => this.setState({ question: newHtml })}
                />
            )
        } else if (type === "statement") {
            return (
                <StatementType
                    question={this.state.question}
                    onBlurQuestion={(baseQuestion) => this.onBlurQuestion(baseQuestion)}
                    questionLanguageId={this.state.languageId}
                    updateOptions={this.updateOptions}
                />
            )
        } else if (type === "match") {
            return (
                <MatchType
                    question={this.state.question}
                    onBlurQuestion={(baseQuestion) => this.onBlurQuestion(baseQuestion)}
                    questionLanguageId={this.state.languageId}
                    updateOptions={this.updateOptions}
                />
            )
        } else {
            return (
                <h5>Please select a valid question type</h5>
            )
        }

    }

    disableOptionsIfStatementOrMatchType = () => {
        return this.state.type === questionTypes.STATEMENT || this.state.type === questionTypes.MATCH
    }

    render() {

        const addActions = [
            <FlatButton
                label="Copy Added Question ID"
                primary={true}
                onClick={() => {
                    copyToClipboard(this.state.addedQuestionId)
                    toast("ID copied", {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true
                    })
                } }
            />,
            <FlatButton
                label="Add More"
                primary={true}
                onClick={this.clearData}
            />,
            <FlatButton
                label="Go Home"
                primary={true}
                onClick={() => this.props.history.push('/')}
            />,
        ];

        return (
            <Container fluid style={{ paddingTop: 30 }}>

                <RelatedModulesModal
                    showRelatedModulesModal={this.state.showRelatedModulesModal}
                    relatedModules={this.state.relatedModules}
                    close={() => this.setState({ showRelatedModulesModal: false })}
                    saveRelatedModules={(relatedModules) => this.setState({ relatedModules, showRelatedModulesModal: false })}
                />

                {
                    this.state.isLoading &&
                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        paddingTop: '30%',
                        paddingLeft: '47%',
                        zIndex: 999
                    }}>
                        <CircularProgress size={80} thickness={5} />
                    </div>
                }

                { this.state.showEditorModal && this.renderEditorModal() }

                <Row>

                    <Col sm={(Array.isArray(this.state.availablePacks) && this.state.availablePacks.length > 0) ? "8" : "10"}>

                        <Dialog
                            title="Adding Success"
                            actions={addActions}
                            modal={true}
                            open={this.state.openAddSuccess}
                        >
                            Question added successfully.
                        </Dialog>

                        <Row>
                            <Col md="3">
                                {
                                    !this.state.showRawHtml &&
                                    <FormGroup tag="fieldset">
                                        <Input
                                            type="select" name="select" id="questionType"
                                            value={this.state.type}
                                            onChange={(e) => this.setState({ type: e.target.value })}
                                        >
                                            <option value="text">Text</option>
                                            <option value="html">HTML</option>
                                            <option value="math">Math</option>
                                            <option value="statement">Statement</option>
                                            <option value="match">Match Pairs</option>
                                        </Input>
                                    </FormGroup>
                                }
                            </Col>

                            <Col>
                                <Button
                                    block
                                    color="success"
                                    style={{ color: 'white' }}
                                    disabled={!(this.formIsValid())}
                                    onClick={() => this.submitQuestion()}
                                >Submit</Button>
                            </Col>

                        </Row>

                        <Row>
                            <Col style={{ marginBottom: 10 }}>
                                { this.renderQuestionField() }
                            </Col>
                        </Row>

                        {
                            (this.state.type === "math") ?
                                <ListGroupItem>
                                    <MathQuestionViewer
                                        delimiter={"$"}
                                        mathText={this.state.question}
                                    />
                                </ListGroupItem> : null
                        }
                              
                        <SimilarQuestions similarQuestions={this.state.similarQuestions} />

                        {
                            isContentTeam(this.props.auth.roleIds) && this.state.splittedWords.map((eachItem, index) => (
                                <Badge
                                    onClick={() => this.clickedWord(eachItem)}
                                    style={{ margin: 2, fontSize: 16 }}
                                    color={this.state.selectedWords.includes(eachItem) ? "primary" : "secondary"}
                                    key={index}
                                >
                                    {eachItem}
                                </Badge>
                            ))
                        }

                        <Row>
                            <Col md="10">
                                <TextField
                                    id={"1"}
                                    value={this.state.option1 || ''}
                                    hintText="Option 1"
                                    multiLine
                                    name="option1"
                                    fullWidth={true}
                                    onChange={(e) => this.splitAndDistributeToOptions(e, 1)}
                                    disabled={this.disableOptionsIfStatementOrMatchType()}
                                />
                                {
                                    this.state.option1Type === "math" &&
                                    <ListGroupItem>
                                        <MathQuestionViewer delimiter="$$" mathText={this.state.option1} />
                                    </ListGroupItem>
                                }
                            </Col>
                            
                            <Col md="2">

                            <CheckBox
                                    label={'Math'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.option1Type === "math"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (this.state.option1Type === "math") {
                                                oldState.option1Type = "text"
                                            } else {
                                                oldState.option1Type = "math"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>

                        </Row>

                        <Row>
                            <Col md="10">

                                <TextField
                                    id={"2"}
                                    value={this.state.option2 || ''}
                                    hintText="Option 2"
                                    multiLine
                                    name="option2"
                                    fullWidth={true}
                                    onChange={(e) => {this.splitAndDistributeToOptions(e,2)}}
                                    disabled={this.disableOptionsIfStatementOrMatchType()}
                                />
                                {
                                    this.state.option2Type === "math" &&
                                    <ListGroupItem>
                                        <MathQuestionViewer delimiter="$$" mathText={this.state.option2} />
                                    </ListGroupItem>
                                }
                            </Col>
                            <Col md="2">
                            <CheckBox
                                    label={'Math'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.option2Type === "math"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (this.state.option2Type === "math") {
                                                oldState.option2Type = "text"
                                            } else {
                                                oldState.option2Type = "math"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>


                        </Row>

                        <Row>
                            <Col md="10">

                                <TextField
                                    id={"3"}
                                    value={this.state.option3 || ''}
                                    hintText="Option 3"
                                    name="option3"
                                    multiLine
                                    fullWidth={true}
                                    onChange={(e) => {this.splitAndDistributeToOptions(e,3)}}
                                    disabled={this.disableOptionsIfStatementOrMatchType()}
                                />
                                {
                                    this.state.option3Type === "math" &&
                                    <ListGroupItem>
                                        <MathQuestionViewer delimiter="$$" mathText={this.state.option3} />
                                    </ListGroupItem>
                                }
                            </Col>


                            <Col md="2">
                                <CheckBox
                                    label={'Math'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.option3Type === "math"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (this.state.option3Type === "math") {
                                                oldState.option3Type = "text"
                                            } else {
                                                oldState.option3Type = "math"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md="10">

                                <TextField
                                    id={"4"}
                                    value={this.state.option4 || ''}
                                    hintText="Option 4"
                                    name="option4"
                                    fullWidth={true}
                                    onChange={this.handleInput}
                                    disabled={this.disableOptionsIfStatementOrMatchType()}
                                />
                                {
                                    this.state.option4Type === "math" &&
                                    <ListGroupItem>
                                        <MathQuestionViewer delimiter="$$" mathText={this.state.option4} />
                                    </ListGroupItem>
                                }
                            </Col>

                            <Col md="2">
                                <CheckBox
                                    label={'Math'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.option4Type === "math"}
                                    onClick={() => {
                                        this.setState((oldState) => {

                                            if (this.state.option4Type === "math") {
                                                oldState.option4Type = "text"
                                            } else {
                                                oldState.option4Type = "math"
                                            }

                                            return oldState
                                        })
                                    }}
                                />
                            </Col>


                        </Row>

                        <Row>
                            <Col md="6">
                                <SelectField
                                    value={this.state.answer}
                                    floatingLabelText="Correct Answer"
                                    onChange={(event, third, value) => this.handleMenuChange("answer", value)}
                                    style={{ marginRight: 20 }}
                                >
                                    <MenuItem value={"A"} primaryText="Option 1" />
                                    <MenuItem value={"B"} primaryText="Option 2" />
                                    <MenuItem value={"C"} primaryText="Option 3" />
                                    <MenuItem value={"D"} primaryText="Option 4" />
                                </SelectField>
                            </Col>
                            {/* <Col md="6">
                                <SelectField
                                    floatingLabelText="Approved"
                                    value={this.state.approved}
                                    onChange={(event, index, value) => this.setState({ approved: value })}
                                >
                                    <MenuItem value={true} primaryText="Approved" />
                                    <MenuItem value={false} primaryText="Not Aprroved" />
                                </SelectField>
                            </Col> */}
                        </Row>


                        <div style={{ flex: 1, marginTop: 10 }}>
                            <Form>

                                <Row>
                                    <Col md="4">
                                        <TextField
                                            style={{ marginTop: 10, marginBottom: 20 }}
                                            id={"paragraphId"}
                                            value={this.state.paragraphId || ''}
                                            hintText="Paragraph ID (Optional)"
                                            name="paragraphId"
                                            fullWidth={true}
                                            onChange={this.handleInput}
                                        />
                                    </Col>
                                </Row>

                                <ExplanationEditor
                                    explanationType={this.state.explanationType}
                                    description={this.state.description}
                                    updateDescriptionState={(description) => this.setState({ description })}
                                    updateDescriptionAndShowRawHtml={(description) => this.setState({ description })}
                                    updateExplanationType={(explanationType) => this.setState({ explanationType })}
                                />
                                
                            </Form>
                        </div>

                        <Row>
                            <Col>
                                <CheckBox
                                    label={'Add to Current Affairs'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.dailyChallengeQuestion}
                                    disabled={!isCurrentAffairsManagerOrHigher(this.props.auth.roleIds)}
                                    onClick={() => {
                                        this.setState({ dailyChallengeQuestion: !this.state.dailyChallengeQuestion })
                                    }}
                                />
                            </Col>
                            <Col>
                                <CheckBox
                                    label={'Show in Challenge'}
                                    style={{ marginTop: '10px' }}
                                    disabled={!isContentManagerOrHigher(this.props.auth.roleIds)}
                                    checked={this.state.showInChallenge}
                                    onClick={() => {
                                        this.setState({ showInChallenge: !this.state.showInChallenge })
                                    }}
                                />
                            </Col>
                            <Col>
                                <CheckBox
                                    label={'Premium Question'}
                                    style={{ marginTop: '10px' }}
                                    checked={this.state.premiumFlag}
                                    disabled={!isContentManagerOrHigher(this.props.auth.roleIds)}
                                    onClick={() => {
                                        this.setState({ premiumFlag: !this.state.premiumFlag })
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col sm="2">

                        <EditorOptionModal
                            onSubmit={(isShowAllSubjectChecked, islanguagePredictionChecked) => this.setState({ showAllSubject: isShowAllSubjectChecked, islanguagePrediction: islanguagePredictionChecked})}
                            showAllSubject={this.state.showAllSubject}
                            islanguagePrediction={this.state.islanguagePrediction}
                        />

                        <Button
                            block={true}
                            color="link"
                            onClick={() => this.predictData()}
                            disabled={!this.state.question}
                            style={{ marginTop: 20, marginBottom: -10 }}
                        >Predict Subject and Section</Button>

                        <SelectField
                            fullWidth={true}
                            floatingLabelText="Subject"
                            value={this.state.subjectId}
                            onChange={(event, third, value) => this.handleMenuChange("subjectId", value)}
                        >
                            {
                                this.props.subjects ?
                                    this.props.subjects.map(subject => {
                                        return <MenuItem
                                            key={subject._id}
                                            value={subject._id}
                                            primaryText={subject.name}
                                            disabled={!subject.active && !this.state.showAllSubject}
                                            onClick={() => {
                                                const clonedSections = cloneDeep(subject.sections)
                                                const sortedSections = sortBy(clonedSections, ['name'])
                                                this.setState({ sections: sortedSections, chapter: '' })
                                            }}
                                        />
                                    })
                                    :
                                    null
                            }
                        </SelectField>

                        <SelectField
                            fullWidth={true}
                            disabled={!this.state.subjectId}
                            floatingLabelText={this.state.subjectId ? "Section" : "Section (Select Subject First)"}
                            value={this.state.sectionId}
                            onChange={(event, third, value) => this.handleMenuChange("sectionId", value)}
                        >
                            {
                                this.state.sections ?
                                    this.state.sections.map(section => {
                                        return <MenuItem
                                            key={section._id}
                                            value={section._id}
                                            primaryText={section.name}
                                            disabled={!section.activeFlag && !this.state.showAllSubject}
                                            onClick={() => this.setState({ chapter: section.children })}
                                        />
                                    })
                                    :
                                    null
                            }
                        </SelectField>



                        <SelectField
                            fullWidth={true}
                            floatingLabelText="Language"
                            value={this.state.languageId}
                            onChange={(event, third, value) => this.handleMenuChange("languageId", value)}
                        >
                            {
                                this.props.languages ?
                                    this.props.languages.map(languages => {
                                        return <MenuItem
                                            key={languages.id}
                                            value={languages.id}
                                            primaryText={languages.name}
                                        />
                                    })
                                    :
                                    null
                            }
                        </SelectField>

                        <Row style={{ marginTop: 20, marginBottom: 20 }}>
                            <Col md="12" >
                                <div style={{display:"flex", justifyContent:'space-between'}}>
                                    Related Modules: {Array.isArray(this.state.relatedModules) ? this.state.relatedModules.length : "0"}
                                    <Button
                                        color="primary"
                                        onClick={() => this.setState({ showRelatedModulesModal: true })}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <hr />

                        <div style={{ marginTop: 8 }}>
                            
                            <Button
                                color="primary"
                                block
                                onClick={() => this.setState({ isExamListOpen: !this.state.isExamListOpen })}
                                style={{ marginBottom: '1rem', }}>
                                Show Exam List
                            </Button>
                            
                            <Collapse isOpen={this.state.isExamListOpen}>
                                <span><strong>Exam</strong></span>
                                <div style={{ overflowY: 'scroll', height: 400 }}>
                                    <FormGroup>
                                        <div>
                                            {
                                                this.props.posts && this.props.posts.map(post => {

                                                    if (post.exams.length > 0) {

                                                        return <div key={post._id} style={{ border: '1px solid rgb(161, 161, 161)', padding: 4 }}>
                                                            <p>{post.name}</p>

                                                            {
                                                                post.exams[0].questionCount > 0 ? post.exams.map(exam => {
                                                                    const checked = Array.isArray(this.state.exams) && this.state.exams.includes(exam._id)
                                                                    const label = `${exam.description} - ${exam.code ? exam.code : ''}`
                                                                    return (
                                                                        <CustomInput
                                                                            onChange={(event) => this.addOrRemoveExam(event.target.value)}
                                                                            id={exam._id}
                                                                            key={exam._id}
                                                                            value={exam._id}
                                                                            type="checkbox"
                                                                            label={label}
                                                                            checked={checked}
                                                                        />
                                                                    )
                                                                })
                                                                    :
                                                                    <p>No exam added</p>
                                                            }
                                                        </div>
                                                    } else {
                                                        return null
                                                    }
                                                })
                                            }
                                        </div>
                                </FormGroup>
                            </div>
                            </Collapse>
                        </div>
                    </Col>

                    <Col sm="2">
                        {
                            (Array.isArray(this.state.availablePacks) && this.state.availablePacks.length > 0) &&
                            <UncontrolledDropdown style={{ margin: 10 }}>
                                <DropdownToggle caret>
                                        {
                                            this.state.availablePacks[this.state.selectedPathIndex] ?
                                                this.state.availablePacks[this.state.selectedPathIndex].title :
                                                "Select a course"
                                        }
                                </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {
                                        this.state.availablePacks.map((each, index) => {
                                            return (
                                                <DropdownItem
                                                    key={each._id}
                                                    value={each._id}
                                                    onClick={() => this.setState({ selectedPathIndex: index })}
                                                >
                                                    {each.title}
                                                </DropdownItem>)
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }

                        {
                            this.state.availablePacks && this.state.availablePacks[this.state.selectedPathIndex] &&

                            <div style={{ border: '1px solid #000' }}>

                                <h5 style={{ margin: 5 }}>{this.state.availablePacks[this.state.selectedPathIndex].title}</h5>

                                <div>
                                    Pinned Modules
                                    {this.renderPinnedUnits(this.state.availablePacks[this.state.selectedPathIndex].units)}
                                </div>

                                <hr />

                                {this.renderPathModules(this.state.availablePacks[this.state.selectedPathIndex].units)}

                            </div>
                        }

                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <div>
                            {
                                this.state.descriptions && this.state.descriptions.length > 0 ?
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Explanation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.descriptions.map((each, index) => {
                                                    return (
                                                        <tr key={index} onClick={() => this.setState({ description: each })} >
                                                            <td>{index + 1}</td>
                                                            <td>{each}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <p style={{ padding: 10, fontSize: 18, marginTop: 20, color: 'red', textAlign: 'center' }}>No descriptions fetched</p>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        subjects: state.data.subjects,
        posts: state.data.posts,
        languages: state.data.languages,
        pathUnits: state.data.pathUnits,
    }
}

export default connect(mapStateToProps)(QuestionForm)