import React, { useState, useEffect } from 'react'
import Loader from '../Components/Loader'
import {
    Container, Row, Col, Button, Table, Modal, FormGroup, Label, Input,
    ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import axios from 'axios'
import { unstable_batchedUpdates } from 'react-dom'

const EditHomeSlider = () => {

    const [homeSlideData, setHomeSlideData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedSlideModel, setSelectedSlideModel] = useState(null)
    const [slideTypeValue, setSlideTypeValue] = useState(null)
    const [slideImageUrl, setSlideImageUrl] = useState('')
    const [currentIndex, setCurrentIndex] = useState(null)
    const [editMode, setEditMode] = useState(false)

    const slideTypes = [
        {
            key: "routeName",
            name: "Route Name"
        },
        {
            key: "showPremiumModal",
            name: "Show Premium Modal"
        },
        {
            key: "urlToOpen",
            name: "Url To Open"
        }
    ]

    useEffect(() => {
        getHomeSliderData()
    }, [])

    const getHomeSliderData = () => {

        axios.get('/misc/', { params: { contentType: "homeSlider" } })
            .then(res => {
                if (res.data && res.data.requiredContent) {
                    unstable_batchedUpdates(() => {
                        setHomeSlideData(res.data.requiredContent.homeSlider)
                        setIsLoading(false)
                    })
                } else {
                    setIsLoading(false)
                }
            }).catch((err) => {
                setIsLoading(false)
                console.log(err)
                alert("Error, check console")
            })

    }

    const cancelAddOrEdit = () => {

        unstable_batchedUpdates(() => {
            setIsLoading(false)
            setShowAddModal(false)
            setSlideImageUrl('')
            setSlideTypeValue('')
            setCurrentIndex(null)
            setEditMode(false)
        })

    }

    const createNewHomeSlideDate = () => {

        let bodyObject = {
            contentType: "homeSlider",
            value: {
                slideImageUri: slideImageUrl
            }
        }

        const { value } = bodyObject

        if (selectedSlideModel && selectedSlideModel.key) {
            if (selectedSlideModel.key === "routeName") {
                value.routeName = slideTypeValue
            } else if (selectedSlideModel.key === "showPremiumModal") {
                if (!slideTypeValue) {
                    value.showPremiumModal = false
                } else {
                    value.showPremiumModal = slideTypeValue
                }
            } else if (selectedSlideModel.key === "urlToOpen") {
                value.urlToOpen = slideTypeValue

            }
        }

        axios.put('/misc/', bodyObject)
            .then(() => {
                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setShowAddModal(false)
                    setSlideImageUrl('')
                    setSlideTypeValue('')
                    getHomeSliderData()
                })
            }).catch(err => {
                console.log(err)
                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setShowAddModal(false)
                })
            })
    }

    const editSlideContent = (currentIndex) => {

        let SlideTypeValueSet
        let selectedSlideType

        if (homeSlideData[currentIndex].routeName) {
            SlideTypeValueSet = homeSlideData[currentIndex].routeName
            selectedSlideType = slideTypes[0]

        } else if (homeSlideData[currentIndex].showPremiumModal) {
            SlideTypeValueSet = homeSlideData[currentIndex].urlToOpen
            selectedSlideType = slideTypes[1]
        } else if (homeSlideData[currentIndex].urlToOpen) {
            SlideTypeValueSet = homeSlideData[currentIndex].urlToOpen
            selectedSlideType = slideTypes[2]
        }

        unstable_batchedUpdates(() => {
            setSlideImageUrl(homeSlideData[currentIndex].slideImageUri)
            setSlideTypeValue(SlideTypeValueSet)
            setSelectedSlideModel(selectedSlideType)
        })

    }

    const submitEdit = () => {

        let value = { slideImageUri: slideImageUrl }

        if (selectedSlideModel && selectedSlideModel.key === "routeName") {
            value.routeName = slideTypeValue
        } else if (selectedSlideModel && selectedSlideModel.key === "showPremiumModal") {
            value.showPremiumModal = slideTypeValue
        } else if (selectedSlideModel && selectedSlideModel.key === "urlToOpen") {
            value.urlToOpen = slideTypeValue
        }

        const contentId = homeSlideData[currentIndex]._id

        axios.post('/misc/', { contentType: "homeSlider", contentId, value })
            .then(res => {
                unstable_batchedUpdates(() => {
                    setSlideImageUrl('')
                    setSlideTypeValue('')
                    setSelectedSlideModel('')
                    setCurrentIndex(null)
                    setEditMode(false)
                    setShowAddModal(false)
                    getHomeSliderData()
                })
            }).catch(err => {
                unstable_batchedUpdates(() => {
                    setSlideImageUrl('')
                    setSlideTypeValue('')
                    setSelectedSlideModel('')
                    setCurrentIndex(null)
                    setEditMode(false)
                    setShowAddModal(false)
                })
                alert("Server error. Please check console or contact admin")
                console.log(err)
            })
    }

    const deleteSlideContent = (itemId) => {

        if (window.confirm("Are you sure to delete this ?")) {

            setIsLoading(true)

            axios.delete('/misc/', { params: { contentType: "homeSlider", value: itemId } })
                .then(() => {
                    getHomeSliderData()
                    setIsLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    alert("Server error. Please check console or contact admin")
                })
        }

    }

    return (
        <>
            {isLoading && <Loader />}

            <Container
                fluid
                style={{ marginTop: 20, marginLeft: 10, width: "100%" }}
            >
                <Modal
                    isOpen={showAddModal}
                >
                    <ModalHeader>
                        Home Slider
                    </ModalHeader>
                    <ModalBody>

                        <FormGroup>
                            <Label>Slide Image URL</Label>
                            <Input placeholder='Enter SlideImageUrl' type="text" onChange={(e) => setSlideImageUrl(e.target.value)} value={slideImageUrl || ''} />
                        </FormGroup>

                        <hr />

                        <UncontrolledDropdown style={{ marginBottom: 20 }}>
                            <Label>Slide onPress Action</Label><br />
                            <DropdownToggle caret color="primary">
                                {selectedSlideModel ? selectedSlideModel.name : "Select Slide Type"}
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    slideTypes.map((each, index) => {
                                        return (
                                            <DropdownItem
                                                key={each.key}
                                                value={each.name}
                                                onClick={() => {
                                                    if (each.key === 'showPremiumModal') {
                                                        unstable_batchedUpdates(() => {
                                                            setSelectedSlideModel(each)
                                                            setSlideTypeValue(true)
                                                        })
                                                    } else {
                                                        setSelectedSlideModel(each)
                                                    }
                                                }}
                                            >
                                                {each.name}
                                            </DropdownItem>

                                        )

                                    })
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        {
                            (selectedSlideModel && selectedSlideModel.key && selectedSlideModel.key !== "showPremiumModal") &&
                            <FormGroup>
                                <Label>{`Enter ${selectedSlideModel.name}`}</Label>
                                <Input placeholder={`Enter ${selectedSlideModel.name}`} type="text" onChange={(e) => setSlideTypeValue(e.target.value)} value={slideTypeValue || ''} />
                            </FormGroup>
                        }
                        
                    </ModalBody>
                    <ModalFooter>
                        {
                            editMode ?
                                <Button
                                    color="primary"
                                    onClick={() => submitEdit()}
                                >
                                    Submit Edit
                                </Button>
                                :
                                <Button
                                    color='primary'
                                    onClick={() => createNewHomeSlideDate()}
                                >
                                    Create Slide
                                </Button>
                        }
                        <Button
                            outline
                            onClick={() => cancelAddOrEdit()}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>

                </Modal>
                <Row style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Col>
                        <h3>Edit Home Slider </h3>
                    </Col>

                    <Button
                        color='primary'
                        style={{ marginRight: 30, marginBottom: 10 }}
                        onClick={() => setShowAddModal(true)}
                    >
                        + Add Home Slide
                    </Button>
                </Row>
                <Row>

                    <Table style={{ tableLayout: 'fixed', wordWrap: 'break-word', margin: 50 }}>
                        <thead>
                            <tr>
                                <th style={{ width: 50 }}>No</th>
                                <th>Slide Image Url</th>
                                <th>Type</th>
                                <th>Value</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                homeSlideData && homeSlideData[0] && homeSlideData.map((each, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{each.slideImageUri ? each.slideImageUri : "nil"}</td>
                                            <td>
                                                {
                                                    each.routeName ? "Route Name" :
                                                        each.showPremiumModal ? "Show Premium Modal" :
                                                            each.urlToOpen ? "URL To Open" : "nil"
                                                }
                                            </td>
                                            <td>
                                                {
                                                    each.routeName ? each.routeName :
                                                        each.urlToOpen ? each.urlToOpen :
                                                            each.showPremiumModal ? each.showPremiumModal.toString()
                                                                : "false"
                                                }
                                            </td>
                                            <td>
                                                <Button
                                                    outline
                                                    color='primary'
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {

                                                        editSlideContent(index)

                                                        unstable_batchedUpdates(() => {
                                                            setCurrentIndex(index)
                                                            setShowAddModal(true)
                                                            setEditMode(true)
                                                        })
                                                    }}                                                    
                                                >Edit
                                                </Button>

                                                <Button
                                                    outline
                                                    color='danger'
                                                    onClick={() => deleteSlideContent(each._id)}
                                                >Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>

                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )

}
export default EditHomeSlider