import React, { useState, useEffect } from 'react'
import {
    Container, Row, Col, Table, Button, Input, Label,
    Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem
} from 'reactstrap'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import { unstable_batchedUpdates } from 'react-dom';
import { isFinite } from 'lodash'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../Components/Loader'
import { copyToClipboard } from '../utils'
import { updateCourseGroups } from '../actions'

const AddOrEditCourseModal = ({ editingGroupId, isOpen, handleInputChange, handleCheckboxChange, data, submit, close }) => {
    return (
        <Modal isOpen={isOpen} size="lg">

            <ModalHeader>
                {editingGroupId ? 'Edit' : 'Add'} Course Group
            </ModalHeader>

            <ModalBody>

                <Label>Group Name</Label>
                <Input
                    placeholder="Enter Group Name"
                    type="text"
                    name="name"
                    value={data.name || ''}
                    onChange={handleInputChange}
                    style={{ marginBottom: 20 }}
                />
                <Label>Group Description</Label>
                <Input
                    placeholder="Enter Group Description"
                    type="text"
                    name="description"
                    value={data.description || ''}
                    onChange={handleInputChange}
                    style={{ marginBottom: 20 }}
                />

                <Row>
                    <Col>
                        <Label>MRP</Label>
                        <Input
                            placeholder="Enter MRP"
                            type="text"
                            name="maximumRetailPrice"
                            value={data.maximumRetailPrice || ''}
                            onChange={handleInputChange}
                            style={{ marginBottom: 20 }}
                        />
                    </Col>

                    <Col>
                        <Label>Selling Price</Label>
                        <Input
                            placeholder="Enter Selling Price"
                            type="text"
                            name="discoutedPrice"
                            value={data.discoutedPrice || ''}
                            onChange={handleInputChange}
                            style={{ marginBottom: 20 }}
                        />
                    </Col>

                    <Col>

                        <Label>Premium User Price</Label>
                        <Input
                            placeholder="Enter Price for Premium User"
                            type="text"
                            name="premiumUserPrice"
                            value={data.premiumUserPrice || ''}
                            onChange={handleInputChange}
                            style={{ marginBottom: 20 }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <Label>{"Pack & Premium Validity (Months)"}</Label>
                        <Input
                            placeholder="Premium Months included with this plan"
                            type="text"
                            name="packAndPremiumValidity"
                            value={data.packAndPremiumValidity || ''}
                            onChange={handleInputChange}
                            style={{ marginBottom: 20 }}
                        />
                    </Col>
                    <Col md="6">
                        <Label>{"Promo Video ID"}</Label>
                        <Input
                            placeholder="Promo Video ID for Purchase Modal"
                            type="text"
                            name="promoVideoId"
                            value={data.promoVideoId || ''}
                            onChange={handleInputChange}
                            style={{ marginBottom: 20 }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Label>Free User Promo Image</Label>
                        <Input
                            placeholder="Enter Promo Image for Free User"
                            type="text"
                            name="freeUserPromoImage"
                            value={data.freeUserPromoImage || ''}
                            onChange={handleInputChange}
                            style={{ marginBottom: 20 }}
                        />
                    </Col>

                    <Col>
                        <Label>Premium User Promo Image</Label>
                        <Input
                            placeholder="Enter Promo Image for Premium User"
                            type="text"
                            name="premiumUserPromoImage"
                            value={data.premiumUserPromoImage || ''}
                            onChange={handleInputChange}
                            style={{ marginBottom: 20 }}
                        />
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button
                    color="primary"
                    onClick={submit}
                >
                    Submit
                </Button>

                <Button
                    outline
                    color="secondary"
                    onClick={close}
                >
                    Close
                </Button>

            </ModalFooter>

        </Modal>
    )
}

function CourseGroups() {

    const [isLoading, setIsLoading] = useState(true)
    const [showFeaturesModal, setShowFeaturesModal] = useState(false)
    const [groupDetails, setGroupDetails] = useState(null)
    const [editIndex, setEditIndex] = useState(null)
    const [showAddCourseModal, setShowAddCourseModal] = useState(false)
    const [featureData, setFeatureData] = useState({})

    const courseGroups = useSelector(state => state.data.courseGroups)
    
    const dispatch = useDispatch()

    const handleInputChange = (e) => {
        const currentGroupDetails = { ...groupDetails }
        currentGroupDetails[e.target.name] = e.target.value
        setGroupDetails(currentGroupDetails)
    }

    const handleFeatureOnChange = (e) => {
        const currentFeatureDetails = { ...featureData }
        currentFeatureDetails[e.target.name] = e.target.value
        setFeatureData(currentFeatureDetails)
    }

    const getAllCoursePacks = () => {
        setIsLoading(true)
        axios.get('/course-group/admin')
            .then(res => {
                dispatch(updateCourseGroups(res.data.courseGroups))
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error while getting course packs")
            })
    }

    useEffect(() => {
        getAllCoursePacks()
    }, [])

    const addFeature = () => {
        
        if (!featureData.featureTitle || featureData.featureTitle.trim().length === 0 || 
            !featureData.featureDescription || featureData.featureDescription.trim().length === 0) {
            alert("Please fill feature title and description to add")
            return true
        }

        const currentGroupDetails = { ...groupDetails }

        if (!isFinite(editIndex) && !currentGroupDetails.features) {
            currentGroupDetails.features = [featureData]
        } else if (!isFinite(editIndex))  {
            currentGroupDetails.features.push(featureData)
        } else {
            currentGroupDetails.features[editIndex] = featureData
        }

        const currentFeatureData = { }
        currentFeatureData.featureTitle = ''
        currentFeatureData.featureDescription = ''
        currentFeatureData.featureIcon = ''
        
        unstable_batchedUpdates(() => {
            setGroupDetails(currentGroupDetails)
            setFeatureData(currentFeatureData)
        })

    }
    
    const createNewGroup = () => {
        setIsLoading(true)
        axios.put('/course-group/admin', groupDetails)
            .then(() => {
                getAllCoursePacks()
                unstable_batchedUpdates(() => {
                    setShowAddCourseModal(false)
                    setIsLoading(false)
                    setGroupDetails(null)
                })
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error while creating new course group")
            })
    }
    
    const removeFeature = (index) => {
        const currentGroupDetails = { ...groupDetails }
        currentGroupDetails.features.splice(index, 1)
        setGroupDetails(currentGroupDetails)
    }

    const editingCourseGroup = () => {
        
        setIsLoading(true)

        axios.post('/course-group/admin', { ...groupDetails, courseGroupId: groupDetails._id })
            .then(() => {
                getAllCoursePacks()
                unstable_batchedUpdates(() => {
                    setGroupDetails(null)
                    setShowAddCourseModal(false)
                    setShowFeaturesModal(false)
                })
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error while editing course group")
            })
    }

    const toggleActiveFlag = (courseGroupId) => {
        setIsLoading(true)
        axios.delete('/course-group/admin', { params: { courseGroupId } })
            .then(() => {
                getAllCoursePacks()
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error while delete course group")
            })
    }

    const renderFeatureModal = () => {
        return (
            <Modal isOpen={showFeaturesModal} size="lg">
            <ModalHeader>
                Enter feature details
            </ModalHeader>
            
            <ModalBody>
            <Label style={{ fontWeight: '600', fontSize: 17 }}>Add Features</Label>
                <Row style={{ alignItems: 'center' }}>
                    <Col>
                        <Input
                            placeholder="Enter Feature Title"
                            type="text"
                            name="featureTitle"
                            value={featureData.featureTitle || ''}
                            onChange={handleFeatureOnChange}
                        />
                        <Input
                            placeholder="Enter Feature Description"
                            type="text"
                            name="featureDescription"
                            value={featureData.featureDescription || ''}
                            onChange={handleFeatureOnChange}
                        />
                        <Input
                            placeholder="Enter Feature Icon URI (Optional)"
                            type="text"
                            name="featureIcon"
                            value={featureData.featureIcon || ''}
                            onChange={handleFeatureOnChange}
                            style={{ marginBottom: 10 }}
                        />
                        
                        <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 20 }}>
                            <Button 
                                outline 
                                style={{ width: '25%' }} 
                                color='primary'
                                onClick={() => addFeature() }
                            >
                                {isFinite(editIndex) ? 'Edit' : 'Add'}
                            </Button>
                        </div>
                    </Col>
                </Row>
                
                    <ListGroup>
                        {groupDetails && groupDetails.features && groupDetails.features.length > 0 && <Label>Features List</Label>}
                        {groupDetails && groupDetails.features && groupDetails.features.map((item, index) => {
                            return (
                                <ListGroupItem key={item.key}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                                        <div style={{ maxWidth: '75%' }}>
                                            <p>Title: {item.featureTitle}</p>
                                            <p>Description: {item.featureDescription}</p>
                                            <p style={{ fontSize: 14 }}>Icon: {item.featureIcon}</p>
                                        </div>
                                        <div>
                                            <Button outline style={{ marginRight: 5 }} onClick={() => {
                                                const currentFeatureData = { ...featureData }
                                                currentFeatureData.featureTitle = item.featureTitle
                                                currentFeatureData.featureDescription = item.featureDescription
                                                currentFeatureData.featureIcon = item.featureIcon
                                                setFeatureData(currentFeatureData)
                                                setEditIndex(index)
                                            }}>
                                                Edit
                                            </Button>
                                            <Button outline color='danger' onClick={() => {
                                                (window.confirm(`Are you sure you want remove this feature?`)) &&
                                                    removeFeature(index)
                                            }}>
                                                Remove
                                            </Button>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            )
                        })
                        }
                    </ListGroup>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => {
                        if (Array.isArray(groupDetails.features)) {
                            editingCourseGroup() 
                        } else {
                            alert("Please add at least one feature")
                        }
                    }}
                >
                    Submit
                </Button>
                <Button
                    outline 
                    onClick={() => {
                        setShowFeaturesModal(false)
                        const currentFeatureData = { }
                        currentFeatureData.featureTitle = ''
                        currentFeatureData.featureDescription = ''
                        currentFeatureData.featureIcon = ''
                        setFeatureData(currentFeatureData)
                    }}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
        )
    }

    return (
        <>
            {isLoading && <Loader />}

            <Container
                fluid
                style={{ paddingTop: 20 }}
            >

                { showFeaturesModal && renderFeatureModal() }

                {
                    groupDetails && groupDetails._id ?
                        <AddOrEditCourseModal
                            data={groupDetails}
                            isOpen={showAddCourseModal}
                            handleInputChange={handleInputChange}
                            submit={editingCourseGroup}
                            close={() => {
                                unstable_batchedUpdates(() => {
                                    setGroupDetails(null)
                                    setShowAddCourseModal(false)
                                })
                            }}
                        /> :
                        groupDetails ?
                            <AddOrEditCourseModal
                                data={groupDetails}
                                isOpen={showAddCourseModal}
                                handleInputChange={handleInputChange}
                                submit={createNewGroup}
                                close={() => {
                                    unstable_batchedUpdates(() => {
                                        setGroupDetails(null)
                                        setShowAddCourseModal(false)
                                    })
                                }}
                            />
                            : null
                }

                <Row>
                    <Col>
                        <Button
                            color="primary"
                            style={{ marginBottom: 20 }}
                            onClick={() => {
                                unstable_batchedUpdates(() => {
                                    setGroupDetails({ name: '' })
                                    setShowAddCourseModal(true)
                                })
                            }}
                        >
                            + Create New Group
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {
                        Array.isArray(courseGroups) && courseGroups.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Course Name</th>
                                        <th>Course Description</th>
                                        <th>MRP</th>
                                        <th>Selling Price</th>
                                        <th>Premium User Price</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        courseGroups.map((each, index) => {
                                            return (
                                                <tr
                                                    key={each._id}
                                                    style={{ background: each.activeFlag === false ? 'rgba(255,0,0,0.1)' : 'transparent' }}
                                                >
                                                    <td>{index + 1}</td>
                                                    <td>{each.name}</td>
                                                    <td>{each.description}</td>
                                                    <td>{each.maximumRetailPrice}</td>
                                                    <td>{each.discoutedPrice}</td>
                                                    <td>{each.premiumUserPrice}</td>
                                                    <td>

                                                        <Button
                                                            style={{ marginRight: 10 }}
                                                            outline
                                                            color="primary"
                                                            onClick={() => {
                                                                copyToClipboard(each._id)
                                                                toast("ID copied", {
                                                                    position: toast.POSITION.TOP_CENTER,
                                                                    hideProgressBar: true
                                                                    }) 
                                                            } }
                                                        >
                                                            <FontAwesomeIcon icon={faCopy} />
                                                        </Button>


                                                        <Button
                                                            style={{ marginRight: 10 }}
                                                            outline
                                                            color="primary"
                                                            onClick={() => {
                                                                unstable_batchedUpdates(() => {
                                                                    setGroupDetails(each)
                                                                    setShowFeaturesModal(true)
                                                                })
                                                            }}
                                                        >
                                                            Features
                                                        </Button>
                                                        <Button
                                                            style={{ marginRight: 10 }}
                                                            outline
                                                            color="primary"
                                                            onClick={() => {
                                                                unstable_batchedUpdates(() => {
                                                                    setGroupDetails(each)
                                                                    setShowAddCourseModal(true)
                                                                })
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </Button>
                                                        
                                                        <Button
                                                            style={{ marginRight: 10 }}
                                                            outline
                                                            color="danger"
                                                            onClick={() => toggleActiveFlag(each._id) }
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            Array.isArray(courseGroups) && courseGroups.length === 0 ?
                                <h5 style={{ marginTop: 50 }}>No course groups added yet.</h5>
                                :
                                null
                    }
                </Row>
            </Container>
        </>
    )
}

export default CourseGroups
