import React, { Component } from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row, Button,
    UncontrolledDropdown, Dropdown, DropdownMenu, DropdownToggle, DropdownItem
} from 'reactstrap'
import { DatePicker } from 'material-ui'

import Loader from '../../Components/Loader'
import { dropDownPeriods } from '../../utils'

const groupByOptions = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "adgroup_name",
    "campaign_name",
    "campaign_group_name",
    "publisher_platform"
]

class Referral extends Component {

    state = {
        data: null,
        joiningDate: null,

        groupBy: 'utm_source',

        period: 'thisMonth',
        periodLabel: 'This Month',

        fromDate: new Date(2018, 4),
        toDate: new Date(),

        isLoading: false
    }

    componentDidMount() {
        this.getList()
    }

    getList = () => {
        this.setState({ isLoading: true }, () => {
            axios.get('/referralList', {
                params: {
                    period: this.state.period,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                    groupBy: this.state.groupBy
                }, timeout: 120000
            })
                .then(res => {
                    this.setState({ data: res.data, isLoading: false })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Server error. Please check console")
                })
        })
    }

    render() {
        return (
            <>
                {this.state.isLoading && <Loader />}

                <Container fluid style={{ marginTop: '5%' }}>

                    <Row>
                        <Col md="2">
                            <UncontrolledDropdown style={{ marginRight: 20 }}>

                                <DropdownToggle caret color="primary">Group by: {this.state.groupBy}</DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {
                                        groupByOptions.map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each}
                                                    value={each}
                                                    onClick={() => this.setState({ groupBy: each }, () => this.getList())}
                                                >
                                                    {each}
                                                </DropdownItem>)
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>

                        <Col md="9">

                            <Dropdown
                                isOpen={this.state.isOpen}
                                toggle={() => this.setState({ isOpen: !this.state.isOpen })}
                            >
                                <DropdownToggle caret>{this.state.periodLabel}</DropdownToggle>
                                <DropdownMenu>
                                    {
                                        dropDownPeriods.map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each.value}
                                                    onClick={() => this.setState({
                                                        isOpen: false,
                                                        period: each.value,
                                                        periodLabel: each.label,
                                                    },
                                                        () => {
                                                            if (each.value !== "customTime") {
                                                                this.getList()
                                                            } else {
                                                                this.setState({ showCustomTime: true, isLoading: false })
                                                            }
                                                        }
                                                    )}
                                                >
                                                    {each.label}
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>

                            {
                                this.state.period === 'customTime' &&
                                <>
                                    <DatePicker
                                        hintText="From Date"
                                        style={{ marginRight: 10 }}
                                        onChange={(e, date) => {
                                            this.setState({ fromDate: date })
                                        }}
                                        defaultDate={this.state.fromDate}
                                    />
                                    <DatePicker
                                        hintText="To Date"
                                        style={{ marginRight: 10 }}
                                        onChange={(e, date) => {
                                            this.setState({ toDate: date })
                                        }}
                                        defaultDate={this.state.toDate}
                                    />
                                    <Button
                                        outline
                                        color="primary"
                                        onClick={() => this.getList()}
                                    >
                                        Submit
                                    </Button>
                                </>
                            }

                        </Col>

                    </Row>

                    {this.state.loading ? <p>Loading....</p> : null}

                    {
                        this.state.data !== null && <Row>
                            <Col md="12" style={{ marginTop: 20 }}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Channel</th>
                                            <th>Downloads</th>
                                            <th>Premium Payments</th>
                                            <th>Premium Revenue</th>
                                            <th>Pack Payments</th>
                                            <th>Pack Revenue</th>
                                            <th>Total Revenue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item._id}</td>
                                                        <td>{item.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td>{item.premiumPayments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td>{item.premiumRevenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td>{item.packPayments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td>{item.packRevenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        <td>{(item.premiumRevenue + item.packRevenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    }
                </Container>
            </>
        )
    }
}

export default Referral
