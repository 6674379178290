import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage';

import rootReducer from './reducers'

const persistConfig = {
    key:'root',
    storage: localforage,
    blacklist:['home']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)  