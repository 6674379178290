import React, { Component } from 'react'
import axios from 'axios'
import { List, Dialog, FlatButton, Checkbox } from 'material-ui';
import {
    Button, Table, Container, Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Input, Badge, FormGroup
} from 'reactstrap';
import { connect } from 'react-redux'
import { updateSubjects, updateLanguages, updateSectionQuestionsCount} from '../actions'
import CustomModal from './CustomModal'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faCopy, faSort } from '@fortawesome/free-solid-svg-icons'

import { isContentManagerOrHigher } from '../utils/dashboardRoles';
import { copyToClipboard } from '../utils';
import { cloneDeep, isFinite } from 'lodash';
import { toast } from 'react-toastify'

const SortableListSubjects = SortableContainer(({ subjects, onClickSection, onEditButton, toggleCheckBox, isSortingEnabled }) => {
    return (
        <tbody>
            {
                subjects.map((subject, index) => (
                    <SortableItemSubject
                        toggleCheckBox={toggleCheckBox}
                        disabled={!isSortingEnabled}
                        isSortingEnabled={isSortingEnabled}
                        subject={subject}
                        key={subject._id}
                        onClickSection={onClickSection}
                        onEditButton={onEditButton}
                        listIndex={index}
                        index={index}
                    />
                ))
            }
        </tbody>
    )
})

const SortableItemSubject = SortableElement(({ subject, onClickSection, listIndex, onEditButton, toggleCheckBox, isSortingEnabled }) => {
    return (
        <tr key={subject._id}>
            <td>
                {subject.order}
            </td>
            <td>
                {
                    isSortingEnabled ?
                        subject.name
                        :
                        <Checkbox
                            label={subject.name}
                            checked={subject.active}
                            disabled={isSortingEnabled}
                            onCheck={(e, value) => toggleCheckBox(listIndex, subject)}
                        />
                }
                { subject.subtitle ? <p style={{ fontSize: 14, marginBottom: 0 }}><br />{subject.subtitle}</p> : null }
            </td>
           { !isSortingEnabled ? 
            <>
                <td>
                    <Button
                        color="primary"
                        style={{ marginTop: 5, marginRight: 10 }}
                        onClick={() => onClickSection(listIndex)}>
                        Sections
                    </Button>
                </td>
                <td>
                    <FontAwesomeIcon
                        onClick={() => onEditButton(subject)}
                        className="iconButton"
                        style={{ color: '#0fffc', marginTop:10 }}
                        icon={faPen}
                    />
                </td>
            </>
            :
            <td>
                <FontAwesomeIcon
                    icon={faSort}
                    style={{ margin: 11, color: 'grey' }}
                />
            </td>
            }
        </tr>
    )
})

const SortableItemSection = SortableElement(({
    section,
    getQuestionsCount,
    getHtmlQuestionsCount,
    listIndex,
    navigateToViewQuestion, 
    onClickEdit,
    onClickDelete,
    navigateToLearn,
    roleIds,
    isSortingEnabled
}) => {

    return (
        <tr
            key={section._id}
            style={{
                background: section.activeFlag ? 'white' : 'rgba(255,0,0,0.2)',
                borderBottomColor: 'red', borderBlockWidth: 1
            }}
        >
            <td>{isFinite(section.order) ? section.order : ''}</td>
            <td>
                <p style={{ display: 'block' }}>{`${section.emoji ? section.emoji : ''} ${section.name} `}</p>
                <br></br>
                <p>{section.description}</p>
            </td>

            {(isContentManagerOrHigher(roleIds)) ?
                <>
                    <td><p>{getQuestionsCount(section._id)}</p> </td>
                    <td>{getHtmlQuestionsCount(section._id)}</td>
                </>
                : null
            }
            {!isSortingEnabled ?
                <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button outline style={{ marginRight: 10 }}
                        onClick={() => {
                            copyToClipboard(section._id)
                            toast("ID copied", {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true
                                })
                        }}>
                        <FontAwesomeIcon
                            className="iconButton"

                            style={{ color: 'grey' }}
                            icon={faCopy} />
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => navigateToViewQuestion(section._id)}
                    >
                        Questions
                    </Button>
                    <Button
                        color="warning"
                        onClick={() => onClickEdit(section, listIndex)}
                    >
                        <FontAwesomeIcon
                            className="iconButton"
                            style={{ color: 'grey' }}
                            icon={faPen} />
                    </Button>
                    <Button
                        color="info"
                        onClick={() => navigateToLearn(section._id)}
                    >
                        Learn
                    </Button>
                </td>
                :
                <td>
                    <FontAwesomeIcon
                        icon={faSort}
                        style={{ margin: 10, marginBottom: 25, marginTop: 25, color: 'grey' }}
                    />
                </td>
            }
        </tr>
    )
})

const SortableListSections = SortableContainer(({ sections, isSortingEnabled, getHtmlQuestionsCount, getQuestionsCount, navigateToViewQuestion, onClickEdit, onClickDelete, navigateToLearn, roleIds }) => {
    return (
        <tbody>
            {
                sections.map((section, index) => (
                    <SortableItemSection
                        disabled={!isSortingEnabled}
                        getHtmlQuestionsCount={getHtmlQuestionsCount}
                        isSortingEnabled={isSortingEnabled}
                        roleIds={roleIds}
                        section={section}
                        key={section._id}
                        getQuestionsCount={getQuestionsCount}
                        navigateToViewQuestion={navigateToViewQuestion}
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                        navigateToLearn={navigateToLearn}
                        listIndex={index}
                        index={index}
                    />
                ))
            }
        </tbody>
    )
})
class Subjects extends Component {

    state = { 
        editDialog: false,
        deleteDialog: false,
        editingSectionId: null,
        editingSectionSubjectId: null,
        editSectionDialog: false,
        deleteSectionDialog: false,
        addNewSubject: false,
        subjectIndex: null,
        addNewSection: false,
        subjects: [],
        isSubjectsSortingEnabled: false,
        isSectionsSortingEnabled: false,
        newSectionEmoji: '',
        newSectionDescription:"",
        showEnterAllDataAlert:false,
        editingSubject:null,
        deleteSectionCurrentStatus:null
    }

    handleInput = (e) => { this.setState({ [e.target.name]: e.target.value }) }

    updateTableData = (newData) => {

        newData.sections.sort((a, b) => {
            if (a.order < b.order) {
                return -1
            } else {
                return 1
            }
        })

        let foundItem = false

        const reduxSubjectsUpdate = this.props.subjects.map(subject => {
            if (newData._id === subject._id) {
                subject = newData
                foundItem = true
            }
            return subject
        })

        if (!foundItem) {
            reduxSubjectsUpdate.push(newData)
        }
        
        this.props.updateSubjects(reduxSubjectsUpdate)
        this.setState({ subjects: reduxSubjectsUpdate })

    }

    addSubject = ()  => {

        
        const { newSubjectName, newSubjectSubtitle, groupName, shadowColor, backgroundColor, image } = this.state

        if(newSubjectName && groupName && shadowColor && backgroundColor && image) {
            axios.post(`/subjects/add`, {
                name: newSubjectName,
                subtitle: newSubjectSubtitle,
                groupName, shadowColor, backgroundColor, image,
            })
            .then(res => {
                this.setState({ addNewSubject:false, newSubjectName:'', newSubjectSubtitle:'', showEnterAllDataAlert:false })
                this.updateTableData(res.data.newSubject)
            })
            .catch(err => {
                console.error('post/subjects/add')
                console.error(err)
            })
        } else { this.setState({ showEnterAllDataAlert:true }) }
    }

    editSubject() {
        const { _id, name, image, backgroundColor, shadowColor, groupName, subtitle } = this.state.editingSubject

        if (_id && name && image && backgroundColor && shadowColor && groupName) {
            axios.post(`/subjects/edit`, {
                subjectId: _id,
                name,
                image,
                backgroundColor,
                shadowColor,
                groupName,
                subtitle,
                tags: this.state.editingSubjectTags
            })
                .then(response => {
                    this.setState({ editingSubject:null })
                    this.updateTableData(response.data.newSubject)
                }).catch(err => console.log(err))
        } else {
            alert("Enter All Details")
        }
    }

    deleteSubject = () => {
        axios.post(`/subjects/delete`, { subjectId: this.state.deletingSubject._id })
            .then(response => {
                this.setState({ deleteDialog:false })
                let subjectsAfterDeletion = this.props.subjects.filter(each => each._id !== this.state.deletingSubject._id)
                this.props.updateSubjects(subjectsAfterDeletion)
            }).catch(err => console.log(err))
    }

    addSection = () => {
        const { newSectionName, newSectionDescription, newSectionEmoji } = this.state
        
        if (newSectionName) {
            const subjects = cloneDeep(this.props.subjects)
            axios.post(`/section/add`, {
                subjectId: subjects[this.state.subjectIndex]._id,
                newSectionName, newSectionDescription, newSectionEmoji
            })
                .then(response => {
                    this.setState({ newSectionName: '', newSectionDescription: '', newSectionEmoji: '', addNewSection: false, showEnterAllDataAlert: false })
                    this.updateTableData(response.data.updatedSubject)
                })
                .catch(err => console.log(err))
        } else {
            this.setState({ showEnterAllDataAlert: true })
        }
        
    }

    editSection = () => {

        const { editingSectionId, newSectionName, newSectionDescription, newSectionEmoji, changedActiveFlagValue,
                activeFlagValue, premiumFlagValue, marathonEnabled, spacedRepetitionEnabled, changedPremiumFlagValue,
                subjectIndex, subjects, changedMarathon, changedSpacedRepetition } = this.state

        const editingSectionSubjectId = subjects[subjectIndex]._id

        this.updateTags()

        if ( editingSectionId && editingSectionSubjectId && (activeFlagValue || premiumFlagValue || marathonEnabled ||  spacedRepetitionEnabled)) {

            if ( activeFlagValue !== undefined && changedActiveFlagValue) {

                axios.post('/section/toggleActiveFlag', { subjectId:editingSectionSubjectId,  sectionId: editingSectionId, flagValue: activeFlagValue })
                    .then(res => {
                        this.updateTableData(res.data.data)
                        this.setState({changedActiveFlagValue: false})
                    })
                    .catch(err => console.log(err))
            } 

            if (premiumFlagValue !== undefined && changedPremiumFlagValue) {

                axios.post('/section/togglePremiumFlag', { subjectId: editingSectionSubjectId, sectionId: editingSectionId, flagValue: premiumFlagValue })
                .then(res => {
                    this.setState({changedPremiumFlagValue: false})
                    this.updateTableData(res.data.data)
                })
                .catch(err => console.log(err))
            }

            if (marathonEnabled !== undefined && changedMarathon) {
                axios.post('/section/toggleMarathonFlag', { subjectId: editingSectionSubjectId, sectionId: editingSectionId, flagValue: marathonEnabled})
                .then(res => {
                    this.setState({changedMarathon: false})
                    this.updateTableData(res.data.data)
                })
                .catch(err => console.log(err))
            }

            if (spacedRepetitionEnabled !== undefined && changedSpacedRepetition) {
                axios.post('/section/toggle-spaced-repetition', { subjectId: editingSectionSubjectId, sectionId: editingSectionId, flagValue: spacedRepetitionEnabled })
                .then(res => {
                    this.setState({changedSpacedRepetition: false})
                    this.updateTableData(res.data.data)
                })
                .catch(err => console.log(err))
            } 
        } else {
            console.log("subjectId, sectionId, flagValue is required")
        }


        if (newSectionName) {
            axios.post('/section/edit', { subjectId: editingSectionSubjectId, sectionId: editingSectionId, newSectionName, newSectionDescription, newSectionEmoji })
            .then(response => {
                this.setState({ editDialog: false,  editSectionDialog: false, showEnterAllDataAlert:false })
                this.updateTableData(response.data.data)
            }).catch(err => console.log(err))
        } else {
            this.setState({ showEnterAllDataAlert: true })
        }

    }

    deleteSection =()=> {
        const { editingSectionId } = this.state
        let deletingSectionSubjectId = this.state.subjects[this.state.subjectIndex]._id 
        axios.post('/section/delete', { sectionId: editingSectionId, subjectId: deletingSectionSubjectId })
            .then(response => {
                this.setState({ deleteDialog: false, deleteSectionDialog: false })
                this.updateTableData(response.data.data)
            })
            .catch(err => console.log(err))
    }

    toggleSubjectsActiveFlag(subjectId, flagValue) {
        if (subjectId && flagValue !== undefined) {
            axios.post('/subject/toggleActiveFlag', { subjectId, flagValue })
                .then(res => {
                    this.updateTableData(res.data.data)
                })
                .catch(err => console.log(err))
        } else {
            console.log("subjectId, sectionId, flagValue is required")
        }
    }


    onSubjectSortEnd = ({ oldIndex, newIndex }) => {
        let reorderedList = arrayMove(this.state.subjects, oldIndex, newIndex)
        this.setState({subjects: reorderedList})
        let subjectsWithUpdatedIndex = reorderedList.map((each, index) => ({ _id: each._id, index }))

       let  subjectsClone= cloneDeep(this.props.subjects)
        
       subjectsClone.forEach(subject =>{
        subjectsWithUpdatedIndex.forEach(each=> {
            if(each._id === subject._id ) {
                subject.order = each.index
            }
        })
       })

       let subjectsSort = subjectsClone.sort((a,b) => {
            
        if(a.order < b.order) {
            return -1
        } else {
            return 1
        }
    })

    this.props.updateSubjects(subjectsSort)
    this.setState({subject: subjectsSort})


        axios.post('/subject/re-order', {
            reorderedItemId: reorderedList[newIndex]._id,
            subjectsWithUpdatedIndex
        })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
            
    }

    onSectionSortEnd = ({ oldIndex, newIndex }) => {

        let subjects = cloneDeep(this.state.subjects)
        let sections = subjects[this.state.subjectIndex].sections
        let reorderedList = arrayMove(sections, oldIndex, newIndex)
        let sectionsWithUpdatedIndex = reorderedList.map((each, index) => ({ _id: each._id, index }))

        
        reorderedList.forEach(section => {
            sectionsWithUpdatedIndex.forEach(each => {
                if(section._id === each._id) {
                    section.order = each.index
                }
            })
        })

        subjects[this.state.subjectIndex].sections = reorderedList
        
        this.setState({ subjects }, () => { this.sectionsSortFun() })

        let  subjectsClone= cloneDeep(this.props.subjects)

       subjectsClone.forEach(subject => {
           if (subjects[this.state.subjectIndex]._id === subject._id) {
                subject.sections.forEach(section => {
                    reorderedList.forEach(each =>{
                        if (section._id === each._id) {
                            section.order = each.order
                        }
                    })
                })
            }
        })

        this.props.updateSubjects(subjectsClone)

        axios.post('/section/re-order', {
            subjectId: subjects[this.state.subjectIndex]._id,
            sectionsWithUpdatedIndex
        })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    renderLevelOne() {
        return (
            <Container style={{ flex: 1, margin: 20 }}>

                <Row style={{ marginBottom: 10 }}>
                    <h5>Subjects</h5>

                    <Button
                        outline
                        style={{ marginRight: 10, marginLeft: 10 }}
                        color="primary"
                        onClick={() => {
                            this.setState({
                                addNewSubject: true,
                                newSubjectName: '',
                                newSectionEmoji: '',
                                showEnterAllDataAlert: false,
                                alertMessage: false
                            })
                        }}
                    >+ Add New Subject</Button>

                    <Button
                        outline
                        color='success'
                        onClick={() => this.setState({ isSubjectsSortingEnabled: !this.state.isSubjectsSortingEnabled, subjectIndex: null })}
                    >{this.state.isSubjectsSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                    </Button>
                </Row>

                {
                    this.state.subjects && this.state.subjects.length > 0 &&
                    <Row>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Active</th>
                                    <th>Subject Name</th>
                                    <th>Actions</th>
                                    {!this.state.isSubjectsSortingEnabled && <th>Edit</th>}
                                </tr>
                            </thead>
                            <SortableListSubjects
                                subjects={this.state.subjects}
                                isSortingEnabled={this.state.isSubjectsSortingEnabled}
                                toggleCheckBox={(index, subject) => {
                                    this.setState({ subjectIndex: index }, () => {
                                        this.toggleSubjectsActiveFlag(subject._id, !subject.active, index)
                                    })
                                }}
                                onClickSection={(index) => {
                                    this.setState({ subjectIndex: index }, () => {
                                        window.scrollTo(0, 0)
                                    })
                                    this.setQueryParams(index)
                                }}
                                onEditButton={(subject) => this.setState({
                                    editingSubject: subject,
                                    editingSubjectTags: subject.tags
                                })}
                                onSortEnd={this.onSubjectSortEnd}
                            />
                        </Table>
                    </Row>
                }

            </Container>
        )
    }
    
    setQueryParams = (index) => {
        this.props.history.push({
            pathname: "/subjects",
            search: `index=${index}`
        })
    }

    getQuestionsCount(sectionId) {
        const foundSection = this.props.sectionsWithQuestionsCount.find((item) => item._id === sectionId)
        if(foundSection) {
            return foundSection.numOfQuestions
        } else {
            return 0
        }
    }

    getHtmlQuestionsCount(sectionId) {
        const foundSection = this.props.sectionsWithQuestionsCount.find((item) => item._id === sectionId)
        if(foundSection) {
            return foundSection.numOfHtmlQuestions
        } else {
            return 0
        }
    }

    renderLevelTwo() {
        
        let { subjectIndex } = this.state;
        let { subjects } = this.state;

        return (
            <Container style={{ flex: 1, margin: 20 }} >
                {
                    (Array.isArray(subjects) && subjects.length > 0) ?
                        <List>
                            <div style={{ display: 'flex', flexDirection: 'space-between' }}>
                                <div style={{ flex: 1, textAlign: 'center', display: 'flex', flexDirection: 'column', alignContent:'center' }}> 
                                    <h6>Selected Subject: {subjects[subjectIndex].name} </h6> 
                                    <p style={{ fontSize: 13, color: 'grey' }}>{subjects[subjectIndex].subtitle}</p>
                                </div>
                                <div style={{ padding: 10 }}>
                                    <button style={{ marginLeft: 10 }}
                                        disabled={subjectIndex === null}
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={() => {
                                            this.setState({ addNewSection: true, newSectionName:'' })
                                        }}> + Add New Section </button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10, marginBottom: 5, padding: 10, maxHeight: 60 }}>
                                    <Button
                                        outline
                                        color='success'
                                        onClick={() => this.setState({ isSectionsSortingEnabled: !this.state.isSectionsSortingEnabled })}
                                    >{this.state.isSectionsSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                                    </Button>
                                </div>
                            </div>

                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Section Name</th>
                                        {
                                            (isContentManagerOrHigher(this.props.auth.roleIds)) &&
                                            <>
                                                <th>Question Count</th>
                                                <th>HTML Count</th>
                                            </>
                                        }
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <SortableListSections
                                    sections={subjects[subjectIndex].sections}
                                    onSortEnd={this.onSectionSortEnd} 
                                    getHtmlQuestionsCount={(sectionId) => this.getHtmlQuestionsCount(sectionId)}
                                    isSortingEnabled={this.state.isSectionsSortingEnabled}
                                    roleIds={this.props.auth.roleIds}
                                    getQuestionsCount={(sectionId) => this.getQuestionsCount(sectionId)}
                                    navigateToViewQuestion={(sectionId) => this.props.history.push({ pathname: "/questions", state: { subjectIndex: parseInt(subjectIndex), sectionId: sectionId }})}
                                    onClickEdit={(section, index) => this.setState({
                                        editingSectionId: section._id,
                                        newSectionName: section.name,
                                        newSectionEmoji: section.emoji ? section.emoji : '',
                                        showEnterAllDataAlert: false,
                                        alertMessage: false,
                                        newSectionDescription: section.description,
                                        editingTagSubjectIndex: subjectIndex,
                                        editingTagSectionIndex: index,
                                        editingTagSectionId: section._id,
                                        editingSectionTags: section.tags,
                                        editingTagsSectionName: section.name,
                                        activeFlagValue: subjects[subjectIndex].sections[index].activeFlag,
                                        premiumFlagValue: subjects[subjectIndex].sections[index].premiumFlagValue,
                                        marathonEnabled: subjects[subjectIndex].sections[index].marathonEnabled,
                                        spacedRepetitionEnabled: subjects[subjectIndex].sections[index].spacedRepetitionEnabled
                                    }, () => {
                                        this.setState({ editSectionDialog: true })
                                    })}
                                    onClickDelete={(section) => {
                                        this.setState({ deletingSectionId: section._id,deleteSectionCurrentStatus:section.activeFlag}, () => {
                                            this.setState({ deleteSectionDialog: true })
                                        })
                                    }}
                                    navigateToLearn={(sectionId) => this.props.history.push({ pathname: `/learn/${sectionId}/null` })}
                                />
                                {/* <tbody> 
                                    {
                                        subjects ?
                                            subjects[subjectIndex].sections.map((section, index) => {
                                                return (
                                                    <React.Fragment key={section._id}>
                                                        <tr
                                                            key={section._id}
                                                            style={{
                                                                background: section.activeFlag ? 'white' : 'rgba(255,0,0,0.2)',
                                                                borderBottomColor: 'red', borderBlockWidth: 1
                                                            }}
                                                        >

                                                            <td style={{ display: 'flex', flexDirection: 'row', alignItems:'center' }}>
                                                                <Button outline style={{ marginRight: 10 }}>
                                                                    <FontAwesomeIcon
                                                                        className="iconButton"
                                                                        onClick={() => copyToClipboard(section._id)}
                                                                        style={{ color: 'grey' }}
                                                                        icon={faCopy} />
                                                                </Button>
                                                                <p style={{ marginBottom: 0 }}>{`${section.emoji ? section.emoji : ''} ${section.name} `}</p>
                                                            </td>

                                                            {(isContentManagerOrHigher(this.props.auth.roleIds)) ?
                                                                <>
                                                                    <td><p>{this.getQuestionsCount(section._id)}</p> </td>
                                                                    <td>{this.getHtmlQuestionsCount(section._id)}</td>
                                                                    <td>
                                                                        <Checkbox
                                                                            label="Active"
                                                                            checked={section.activeFlag}
                                                                            onCheck={(e, value) => {
                                                                                this.toggleActiveFlag(subjects[subjectIndex]._id, section._id, value)
                                                                            }}
                                                                        />
                                                                        <Checkbox
                                                                            label="Premium"
                                                                            checked={section.premiumFlag}
                                                                            onCheck={(e, value) => this.togglePremiumFlag(subjects[subjectIndex]._id, section._id, value)}
                                                                        />
                                                                        <Checkbox
                                                                            label="Marathon"
                                                                            checked={section.marathonEnabled}
                                                                            onCheck={(e, value) => this.toggleMarathonFlag(subjects[subjectIndex]._id, section._id, value)}
                                                                        />
                                                                    </td>

                                                                </>
                                                                : null
                                                            }

                                                            <td style={{ display:'flex', justifyContent:'space-between'}}>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => this.props.history.push({ pathname: "/questions", state: { subjectIndex: parseInt(subjectIndex), sectionId: section._id } })}
                                                                >
                                                                    Questions
                                                                </Button>


                                                                <Button
                                                                    color="warning"
                                                                    onClick={() => this.setState({
                                                                        editingSectionId: section._id,
                                                                        newSectionName: section.name,
                                                                        newSectionEmoji: section.emoji ? section.emoji : '',
                                                                        showEnterAllDataAlert: false,
                                                                        alertMessage: false,
                                                                        newSectionDescription: section.description,
                                                                    }, () => {
                                                                        this.setState({ editSectionDialog: true })
                                                                    })}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="iconButton"
                                                                        style={{ color: 'grey' }}
                                                                        icon={faPen} />
                                                                </Button>

                                                                {
                                                                    isContentManagerOrHigher(this.props.auth.roleIds) &&

                                                                    <>
                                                                        
                                                                            <Button
                                                                                color="success"
                                                                                onClick={() => this.setState({
                                                                                    editingTagSubjectIndex: subjectIndex,
                                                                                    editingTagSectionIndex: index,
                                                                                    editingTagSectionId: section._id,
                                                                                    editingSectionTags: section.tags,
                                                                                    editingTagsSectionName: section.name,
                                                                                    showEditTagModal: true
                                                                                })}
                                                                            >
                                                                                Tags
                                                                            </Button>
                                                                        
                                                                            <Button
                                                                                color={"outline-danger"}
                                                                                onClick={() => this.setState({ deletingSectionId: section._id, deleteSectionCurrentStatus: section.activeFlag }, () => {
                                                                                    this.setState({ deleteSectionDialog: true })
                                                                                })}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    className="iconButton"
                                                                                    style={{ color: 'red' }}
                                                                                    icon={faTrash} />
                                                                            </Button>
                                                                    </>
                                                                }

                                                                <Button
                                                                    color="info"
                                                                    onClick={() => this.props.history.push({ pathname: `/learn/${section._id}/null` })}
                                                                >
                                                                    Learn
                                                                </Button>

                                                            </td>
                                                        </tr>

                                                        <tr style={{ background: section.activeFlag ? 'white' : 'rgba(255,0,0,0.2)', }}>
                                                            <td colSpan="15"
                                                                style={{ padding: 0, paddingLeft: 20 }}>
                                                                {section.description}
                                                                <hr />
                                                            </td>
                                                        </tr>
                                                        
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <p>No sections under selected subject</p>
                                    }
                                </tbody> */}
                            </Table>
                        </List>
                        :
                        <h5>No Subjects Fetched</h5>
                }
            </Container>
        )
    }

    onEnterTag(newTagKey, tagKey) {

        if (!this.state[newTagKey]) {
            return alert("Please enter a tag")
        }

        if (Array.isArray(this.state[tagKey])) {
            this.setState((oldState) => {

                const foundIndex = oldState[tagKey].indexOf(this.state[newTagKey])

                if (foundIndex > -1) {
                    alert("This tag already exists")
                } else {
                    oldState[tagKey].push(this.state[newTagKey])
                    oldState[newTagKey] = null
                    return oldState
                }
            })
        } else {
            this.setState((oldState) => {
                oldState[tagKey] = [ this.state[newTagKey] ];
                oldState[newTagKey] = null
                return oldState
            })
        }

    }

    deleteTag(newTagKey, tagKey) {
        this.setState((oldState) => {
            const foundIndex = oldState[tagKey].indexOf(newTagKey)
            if (foundIndex > -1) {
                oldState[tagKey].splice(foundIndex, 1);
                return oldState
            }
        })
    }

    updateTags() {

        const { editingTagSectionId, editingSectionTags } = this.state

        if (editingTagSectionId) {
            axios.post('/subjects/tags', { sectionId: editingTagSectionId, tags: editingSectionTags })
            .then(res => {
                this.updateTableData(res.data)
                this.setState({ showEditTagModal: false, editingTagSectionIndex: null, editingTagSubjectIndex: null, editingTagSectionId: null, editingSectionTags: null, editingTagsSectionName: null })
            })
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
        }

    }

    subjectsSortFun(){

        let subjectsSort = this.state.subjects.sort((a,b) => {
            
            if(a.order < b.order) {
                return -1
            } else {
                return 1
            }
        })

        this.setState({ subjects: subjectsSort }, () => {
            this.sectionsSortFun()
        })

        return
    }

    sectionsSortFun() {
        
        let subjects = this.state.subjects.map((each, index) => {

            const sortedSections = each.sections.sort((a, b) => {
                if (a.order < b.order) {
                    return -1
                } else {
                    return 1
                }
            })

            each.sections = sortedSections
            return each

        })

        this.setState({ subjects })

    }

    openDeleteSectionDialog () {
        const { editingSectionId, subjects, editingTagSectionIndex, subjectIndex } = this.state

        let activeFlag = subjects[subjectIndex].sections[editingTagSectionIndex].activeFlag

        this.setState({ deletingSectionId: editingSectionId,deleteSectionCurrentStatus:activeFlag}, () => {
            this.setState({ deleteSectionDialog: true })
        })
    }

    componentDidMount() {

        const search = this.props.location.search;
        const subjectIndex = new URLSearchParams(search).get('index'); 
        if (subjectIndex) { this.setState({ subjectIndex}) }

        this.setState({ subjects: cloneDeep(this.props.subjects) }, () => {
            this.subjectsSortFun()
        })
    }

    render() {  

        const deleteSectionActions = [
            <FlatButton label={this.state.deleteSectionCurrentStatus ? "delete" : "undelete"} primary={true} onClick={this.deleteSection.bind(this)} />,
            <FlatButton label="Cancel" primary={true} onClick={() => this.setState({ deleteSectionDialog: false, deleteSectionCurrentStatus: !this.state.deleteSectionCurrentStatus })} />
        ]

        return (
            <div>

                {
                    this.state.showEditTagModal && 
                    <Modal isOpen={this.state.showEditTagModal}>
                        <ModalHeader>Edit Subject Tags</ModalHeader>
                        <ModalBody>
                            
                            <h6>Selected Subject: {this.state.editingTagsSectionName}</h6>

                            <Input
                                placeholder="Enter a tag"
                                onChange={this.handleInput}
                                value={this.state.newTagName || ''}
                                name="newTagName"
                            />
                            <Button
                                style={{ marginTop: 10 }}
                                variant="outline-primary"
                                block
                                onClick={() => this.onEnterTag('newTagName', 'editingSectionTags')}>Add</Button>
                            <hr />

                            <p>Existing tags</p>

                            {
                                Array.isArray(this.state.editingSectionTags) && this.state.editingSectionTags.length > 0 ?
                                <>
                                    {
                                        this.state.editingSectionTags.map(each => (
                                            <Badge
                                                onClick={() => this.deleteTag(each, 'editingSectionTags')}
                                                style={{ margin: 2, fontSize: 16 }}
                                                key={each}
                                            >{each}</Badge>
                                        ))
                                    }
                                    <p style={{ marginTop: 10, fontSize: 12 }}>Click a tag to delete it</p>
                                </>
                                :
                                <p>No tags added</p>
                            }


                        </ModalBody>

                        <ModalFooter>
                            <Button variant="outline-primary" onClick={() => this.updateTags()}>Submit</Button>
                            <Button
                                variant="outline-secondary"
                                onClick={() => this.setState({ newTagName: null, showEditTagModal: false, editingTagSectionId: null, editingSectionTags: null, editingTagsSectionName: null })}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                }


                <CustomModal
                    isOpen={this.state.addNewSubject}
                    handleInputChange={this.handleInput}
                    fields={
                        [
                            { fieldName: "newSubjectName", value: this.state.newSubjectName, placeholder: "Subject Name" },
                            { fieldName: "newSubjectSubtitle", value: this.state.newSubjectSubtitle, placeholder: "Subject Subtitle" },
                            { fieldName: "image", value: this.state.image, placeholder: "Subject Image URI" },
                            { fieldName: "backgroundColor", value: this.state.backgroundColor, placeholder: "Background Color" },
                            { fieldName: "shadowColor", value: this.state.shadowColor, placeholder: "Shadow Color" },
                            { fieldName: "groupName", value: this.state.groupName, placeholder: "Group Name" },
                        ]
                    }
                    showEnterAllDataAlert={this.state.showEnterAllDataAlert}
                    onSubmit={() => this.addSubject()}
                    onCancel={() => this.setState({ addNewSubject: false, showEnterAllDataAlert: false })}
                />

                {
                    this.state.editingSubject &&
                    <CustomModal
                        isOpen={this.state.editingSubject !== null}
                        handleInputChange={ (e) => {
                            let oldEditingState = JSON.parse(JSON.stringify(this.state.editingSubject))
                            oldEditingState[e.target.name] = e.target.value
                            this.setState({ editingSubject:oldEditingState })
                        }}
                        fields={
                            [
                                { fieldName: "name", value: this.state.editingSubject.name, placeholder: "Subject Name" },
                                { fieldName: "subtitle", value: this.state.editingSubject.subtitle, placeholder: "Subject Sub Title" },
                                { fieldName: "image", value: this.state.editingSubject.image, placeholder: "Subject Image URI" },
                                { fieldName: "backgroundColor", value: this.state.editingSubject.backgroundColor, placeholder: "Background Color" },
                                { fieldName: "shadowColor", value: this.state.editingSubject.shadowColor, placeholder: "Shadow Color" },
                                { fieldName: "groupName", value: this.state.editingSubject.groupName, placeholder: "Group Name" },
                            ]
                        }
                        extraComponent={(
                            <Row>
                                <hr />
                                <Col>
                                    <p>Tags</p>
                                    <Input
                                        placeholder="Enter a tag"
                                        onChange={this.handleInput}
                                        value={this.state.newSubjectTagName || ''}
                                        name="newSubjectTagName"
                                    />
                                    <Button
                                        style={{ marginTop: 10 }}
                                        variant="outline-primary"
                                        block
                                        onClick={() => this.onEnterTag('newSubjectTagName', 'editingSubjectTags')}>Add</Button>
                                    <hr />
                                    <p>Existing tags</p>
                                    {Array.isArray(this.state.editingSubjectTags) && this.state.editingSubjectTags.length > 0 ?
                                        <>
                                            {
                                                this.state.editingSubjectTags.map(each => (
                                                    <Badge
                                                        onClick={() => this.deleteTag(each, 'editingSubjectTags')}
                                                        style={{ margin: 2, fontSize: 16 }}
                                                        key={each}
                                                    >{each}</Badge>
                                                ))
                                            }
                                            <p style={{ marginTop: 10, fontSize: 12 }}>Click a tag to delete it</p>
                                        </>
                                        :
                                        <p>No tags added</p>
                                    }
                                </Col>
                            </Row>
                        )}
                        showEnterAllDataAlert={this.state.showEnterAllDataAlert}
                        onSubmit={() => this.editSubject()}
                        onCancel={() => this.setState({ editingSubject:null, showEnterAllDataAlert: false })}
                    />
                }

                <CustomModal
                    isOpen={this.state.addNewSection}
                    handleInputChange={this.handleInput}
                    fields={
                        [
                            { fieldName: "newSectionName", value: this.state.newSectionName, placeholder: "Section Name" },
                            { fieldName: "newSectionEmoji", value: this.state.newSectionEmoji, placeholder: "Section Emoji" },
                            { fieldName: "newSectionDescription", value: this.state.newSectionDescription, placeholder: "Section Description" },
                        ]
                    }
                    showEnterAllDataAlert={this.state.showEnterAllDataAlert}
                    onSubmit={() => this.addSection()}
                    onCancel={() => this.setState({ addNewSection: false, showEnterAllDataAlert: false })}
                />


                        <CustomModal
                            isOpen={this.state.editSectionDialog}
                            handleInputChange={this.handleInput}
                            fields={
                                [
                                    { fieldName: "newSectionName", value: this.state.newSectionName, placeholder: "Section Name" },
                                    { fieldName: "newSectionEmoji", value: this.state.newSectionEmoji, placeholder: "Section Emoji" },
                                    { fieldName: "newSectionDescription", value: this.state.newSectionDescription, placeholder: "Section Description" },
                                ]
                            }
                            showEnterAllDataAlert={this.state.showEnterAllDataAlert}
                            onSubmit={() => this.editSection()}
                            onDelete={()=> this.openDeleteSectionDialog()}
                            onCancel={() => this.setState({ editSectionDialog: false, showEnterAllDataAlert: false })}
                            showDeleteButton={true}
                            isDelete={this.state.deleteSectionCurrentStatus}
                            extraComponent={(
                                
                                    <>
                                        {
                                            isContentManagerOrHigher(this.props.auth.roleIds) &&
                                                <>
                                        
                                                    <FormGroup>
                                                    
                                                        <Checkbox
                                                            label="Active"
                                                            checked={this.state.activeFlagValue}            
                                                            onCheck={() => this.setState((currentState => {
                                                                let newFlagValue = !currentState.activeFlagValue
                                                                return {activeFlagValue: newFlagValue, changedActiveFlagValue: true}
                                                            }))}
                                                            
                                                        />
                                                        <Checkbox
                                                            label="Premium"
                                                            checked={this.state.premiumFlag}            
                                                            onCheck={() => this.setState((currentState => {
                                                                let newFlagValue = !currentState.premiumFlag
                                                                return {premiumFlag: newFlagValue, changedPremiumFlagValue: true}
                                                            }))}
                                                        />
                                                        <Checkbox
                                                            label="Marathon"
                                                            checked={this.state.marathonEnabled}            
                                                            onCheck={() => this.setState((currentState => {
                                                                let newFlagValue = !currentState.marathonEnabled
                                                                return {marathonEnabled: newFlagValue, changedMarathon: true}
                                                            }))}
                                                        />
                                                        <Checkbox
                                                            label="Spaced Repetition"                            
                                                            checked={this.state.spacedRepetitionEnabled}            
                                                            onCheck={() => this.setState((currentState => {
                                                                let newFlagValue = !currentState.spacedRepetitionEnabled
                                                                return {spacedRepetitionEnabled: newFlagValue, changedSpacedRepetition: true}
                                                            }))}
                                                            
                                                        />

                                                    </FormGroup>

                                                    <Row>
                                                        <hr/>
                                                        <Col>
                                                            <p>Tags</p>
                                                            <Input
                                                                placeholder="Enter a tag"
                                                                onChange={this.handleInput}
                                                                value={this.state.newTagName || ''}
                                                                name="newTagName"
                                                            />
                                                            <Button
                                                                style={{ marginTop: 10 }}
                                                                variant="outline-primary"
                                                                block
                                                                onClick={() => this.onEnterTag('newTagName', 'editingSectionTags')}>Add</Button>
                                                            <hr />

                                                            <p>Existing tags</p>

                                                            {
                                                                Array.isArray(this.state.editingSectionTags) && this.state.editingSectionTags.length > 0 ?
                                                                <>
                                                                    {
                                                                        this.state.editingSectionTags.map(each => (
                                                                            <Badge
                                                                                onClick={() => this.deleteTag(each, 'editingSectionTags')}
                                                                                style={{ margin: 2, fontSize: 16 }}
                                                                                key={each}
                                                                            >{each}</Badge>
                                                                        ))
                                                                    }
                                                                    <p style={{ marginTop: 10, fontSize: 12 }}>Click a tag to delete it</p>
                                                                </>
                                                                :
                                                                <p>No tags added</p>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                        }
                                    </>
                            )}
                        />

                { this.state.deleteSectionDialog &&
                        <Dialog
                            modal={true}
                            title={this.state.deleteSectionCurrentStatus ? "Delete section?" : "Undelete section?" } 
                            actions={deleteSectionActions}
                            open={this.state.deleteSectionDialog}
                        >
                            <p>Are you sure you want to {this.state.deleteSectionCurrentStatus?"delete" : "undelete" } this section?</p>
                        </Dialog>
                }

                <Container fluid={true}>
                    <Row>
                        <Col xl={4} md={4} sm={12}>{this.renderLevelOne()}</Col>
                        <Col xl={8} md={8} sm={12}>{this.state.subjects && this.state.subjectIndex !== null && this.renderLevelTwo()}</Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        subjects:state.data.subjects,
        languages:state.data.languages,
        sectionsWithQuestionsCount:state.data.sectionsWithQuestionsCount
    }
}


export default connect(mapStateToProps, { updateLanguages, updateSectionQuestionsCount, updateSubjects })(Subjects)