import React, { Component } from 'react'
import axios from 'axios'
import { Container, Col, Row, Button,Input, Form, ListGroup, ListGroupItem } from 'reactstrap'

const CONTENT_TYPE = "recommendedBytequizTopics"

class ByteQuizPreferredTopics extends Component {
    
    state = {
        topicToAdd : '', 
        preferredTopicsList : null,
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    addPreferredTopic() {

        let bodyObject = {
            contentType: CONTENT_TYPE,
            value: this.state.topicToAdd
        }

        axios.put('/misc/', bodyObject)
            .then(() => {
                this.getPreferredTopic()
                this.setState({ topicToAdd: '' })
            })
            .catch(err => {
                console.log(err)
                alert("Error. Check console")
            })

    }

    getPreferredTopic() {
        axios.get('/misc/', { params: { contentType: CONTENT_TYPE } })
            .then(res => {
                if (res.data.topics.length > 0) {
                    this.setState({ preferredTopicsList: res.data.topics })
                } else {
                    this.setState({ preferredTopicsList: [] })
                }
            })
            .catch(err => {
                console.log(err)
                alert("Error. Check console")
            })
    }

    deletePreferredTopic(content) {
        if (window.confirm('Delete this item?')) {
            axios.delete('/misc/', { params: { contentType: CONTENT_TYPE, value: content } })
                .then(() => this.getPreferredTopic())
                .catch(err => {
                    this.getPreferredTopic()
                    console.log(err)
                    alert("Error. Check console")
                })
        };
    }

    componentDidMount() {
        this.getPreferredTopic()
    }

    render() {
        return(
            <Container fluid>
                <Row style={{justifyContent: 'center'}}>
                    <Col style={{display: 'flex', marginBottom: '5rem', marginTop: '2rem', justifyContent: "center"}}>
                        <Form style={{display: 'flex', width: '75%',justifyContent: 'center'}} onSubmit={()=>this.addPreferredTopic()} >
                            <Input 
                                type='text'
                                name='topicToAdd'
                                placeholder='Add new topic'
                                value={this.state.topicToAdd}
                                onChange={this.handleInputChange}    
                            />
                            <Button color='primary' style={{ marginLeft: 10 }} type='submit'>Add</Button>
                        </Form>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    { this.state.preferredTopicsList && this.state.preferredTopicsList.length > 0 ?
                        <ListGroup style={{width: '70%'}}>
                           {this.state.preferredTopicsList.map(each => {
                               return(
                                    <ListGroupItem key={each}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <label>{each}</label>   
                                            <Button color='danger' outline onClick={()=>this.deletePreferredTopic(each)}>Delete</Button>   
                                        </div>
                                    </ListGroupItem>
                               )
                           })
                            }  
                        </ListGroup>
                        :
                        this.state.preferredTopicsList && this.state.preferredTopicsList.length === 0 ? 
                            <div style={{ marginTop:100 }}>
                                <h4 style={{ textAlign: 'center' }}>No Preferred Topics Added</h4>
                            </div>
                            :
                            <div>
                                <h4 style={{ textAlign: 'center' }}>Loading..</h4>
                            </div>
                    }
                </Row>
            </Container>
        )
    }
}

export default ByteQuizPreferredTopics