export const SITE_URL = 'https://keralapsc.app'

export const leadsStatus = [
    '-',
    'Contacted',
    'Contacted, talk later',
    'Unreachable',
    'Invalid Number',
    'To Be Contacted',
    'Contacted via WhatsApp',
    'All'
]

export const leadsResult = [
    '-',
    "Don't need premium",
    'App bugs/uninstalled',
    'Just fot GK',
    'Money Problem',
    'Maybe in future',
    'Could not contact',
    'Purchased'
]

export const availableTags = [
    "Psc beginner",
    "PSC Coaching",
    "GK",
    "House wife",
    "Job",
    "Full time psc student",
    "Youtube",
    "Other",
    "Hot",
    "Cold",
    "Warm",
]

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const explanationTypes = {
    MATH: 'math',
    HTML: 'html',
    TEXT: 'text'
}

export const slideModuleContentTypes = {
    MATH: 'math',
    HTML: 'html',
    TEXT: 'text'
}
