import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios'
import Container from 'reactstrap/lib/Container'
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Button,
        Modal,ModalBody,ModalFooter,ModalHeader } from 'reactstrap'
import moment from 'moment'
import { unstable_batchedUpdates } from 'react-dom'
import { copyToClipboard } from '../utils';

import Loader from '../Components/Loader'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy, faLock, faTimes, faUnlock } from '@fortawesome/free-solid-svg-icons'


function BattleHistory({ history }){

    const fetchData = useRef(false)
    
    const [battleData, setBattleData] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [pageNumber, setPageNumber] = useState('1')
    const [battleDataCount,setBattleDataCount] = useState('')
    const [skipCount, setSkipCount] = useState('0')
    const [isLoading, setIsLoading] = useState(true)
    const [battleChatData, setBattleChatData] = useState([])
    const [isBattleChatModalOpen,setBattleChatModalOpen] = useState(false)


    const renderSkipMenu = () => {

        let returnArray = []
        let itemCount = battleDataCount/ 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => {
                        unstable_batchedUpdates(() => {
                            setPageNumber(index+1)
                            setSkipCount(index*50)
                        })
                    }}>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    useEffect(()=> {
        fetchBattleHistoryData()
    }, [])

    useEffect(()=> {
        if (fetchData.current){
            setIsLoading(true)
            fetchBattleHistoryData()
        }
    }, [skipCount])

    const fetchBattleHistoryData =()=> {
        const paramsObject = {
            skipCount
        }

        axios.get('/battle-history', {params: paramsObject})
            .then((res)=>{
                unstable_batchedUpdates (()=>{
                    setIsLoading(false)
                    setBattleData(res.data.battleHistoryData)
                    setBattleDataCount(res.data.battleDataCount)
                })
                fetchData.current = true
            }).catch((error)=>{
                setIsLoading(false)
                console.log('error',error)
                alert('Error while fetching Battle History')
            })

    }

    const fetchChatHistory = (chatId) => {
        setIsLoading(true)
        axios.get('/battle-history/chats', {params: {chatId}})
            .then((res)=> {
                unstable_batchedUpdates(()=> {
                    setIsLoading(false)
                    setBattleChatData(res.data)
                    setBattleChatModalOpen(true)
                })
            }).catch((error)=>{
                setIsLoading(false)
                console.log(error)
                alert('Error while fetching Battle Chat History')
            })
    }

    return (
        <>
        
            { isLoading && <Loader /> }

            <Container fluid>
            
                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', marginTop: 10, marginBottom: 10 }}>
                    
                    <h3>Battle History</h3>

                    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                        <DropdownToggle caret color='primary'>Page {pageNumber}</DropdownToggle>
                        <DropdownMenu>
                            {renderSkipMenu()}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <Table>
                    <thead>
                        <tr>
                            <th>Index</th>
                            <th>Subject-Section</th>
                            <th>Created By</th>
                            <th>Starting Time</th>
                            <th>Question Count</th>
                            <th>Premium Battle</th>
                            <th>Locked Battle</th>
                            <th>Users Count</th>
                            <th>Chats Count</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {battleData.map((each,index) => {
                            return(
                                <tr key={each._id}>
                                <td>{index+1}</td>
                                <td>{each.subject}-{each.sectionName}</td>
                                <td>{each.createdBy}</td>
                                <td>{each.createdAt ? moment(each.createdAt).format('DD/MM/YYYY - hh:mm:ss a') : "Date Not Set"}</td>
                                <td>{each.questionsCount}</td>
                                <td>
                                    {
                                        each.premiumBattle ?  
                                                <FontAwesomeIcon
                                                    style={{ color: 'green' }}
                                                    icon={faCheck}
                                                /> 
                                                : <FontAwesomeIcon
                                                    style={{ color: 'red' }}
                                                    icon={faTimes}
                                                /> 
                                    }
                                </td>
                                <td>
                                    {
                                        each.lockedBattle ? 
                                            <FontAwesomeIcon
                                            style={{ color: 'black' }}
                                            icon={faLock}
                                            /> 
                                            : <FontAwesomeIcon
                                            style={{ color: 'grey' }}
                                            icon={faUnlock}
                                            /> 
                                    }
                                </td>
                                <td>{each.userCount}</td>
                                <td>{each.chatCount}</td>
                                <td>
                                    <Button outline style={{ marginRight: 10 }}>
                                        <FontAwesomeIcon
                                            className="iconButton"
                                            onClick={() => {
                                                copyToClipboard(each._id)
                                                toast("ID copied", {
                                                    position: toast.POSITION.TOP_CENTER,
                                                    hideProgressBar: true
                                                    })
                                            }}
                                            style={{ color: 'grey' }}
                                            icon={faCopy}
                                        />
                                    </Button>
                                    <Button
                                        color= {!each.chatCount ? 'secondary' : 'success'}
                                        outline
                                        disabled={!each.chatCount}
                                        onClick={()=> fetchChatHistory(each._id)}
                                    >
                                        CHATS
                                    </Button>
                                </td>
                            </tr>
                            )
                            })}
                            
                    </tbody>

                </Table>

                <Modal
                    isOpen={isBattleChatModalOpen}
                    toggle={() => setBattleChatModalOpen(false) }
                >
                    <ModalHeader>
                        Battle Chat History
                    </ModalHeader>

                    <ModalBody
                        style={{ overflowY: 'scroll', height: 400 }}
                    >
                        <Table hover>
                            <tbody>
                                {
                                    battleChatData.map(each => {
                                        return (
                                            <tr
                                                key={each.time}
                                                style={{ borderBottom: '.1px solid #000' }}
                                            >
                                                <td style={{ fontWeight: 'bold', marginBottom: 0 }}>{each.name}</td>
                                                <td>{each.message}</td>
                                                <td>{each.time ? moment(each.time).format('DD/MM/YYYY - hh:mm:ss a') : "Date Not Set"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            onClick={() => setBattleChatModalOpen(false)}
                            color="secondary">
                            close
                        </Button>

                    </ModalFooter>
                </Modal>
                
            </Container>
        </>
    )

}
export default BattleHistory