import React,{ Component } from 'react'
import { Container, Row, Col, Button, Table,
        DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown } from 'reactstrap'
import Axios from 'axios'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Loader from '../Components/Loader'

class Comments extends Component {

    state = {
        commentDetails: null,
        recentCommentsCount: null,
        message: null,
        skip: 0,
        isLoading: true
    }

    getAllCommentDetails = () => {
        this.setState({ isLoading: true })
        Axios.get('/admin-comments', { params: { skipCount: this.state.skip * 25 } })
            .then(res => {
                if (res.data.recentComments && res.data.recentCommentsCount) {
                    this.setState({
                        commentDetails: res.data.recentComments,
                        recentCommentsCount: res.data.recentCommentsCount,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        message: res.data.message,
                        isLoading: false
                    })
                }
            }).catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    deleteButton = (moduleId, commentId) => {
        Axios.delete('/comment', { params: { moduleId, commentId } })
            .then(res => {
                this.getAllCommentDetails()
                alert(res.data.message)
                this.setState({ isLoading: true })
            }).catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    skipMenu = () => {

        let returnArray = []
        let itemCount = parseInt(this.state.recentCommentsCount, 10) / 25

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={
                        () => this.setState({ skip: index },
                            () => this.getAllCommentDetails())
                    }>
                    {index + 1}
                </DropdownItem>
            )
        }
        return returnArray
    }

    componentDidMount() {
        this.getAllCommentDetails();
    }

    render() {
        return (
            <>
                {this.state.isLoading && <Loader />}
                <Container fluid>

                    <Row style={{ marginTop: 50, marginBottom: 10, marginRight: 10, flex: 1, justifyContent: 'flex-end' }}>
                        <UncontrolledDropdown>
                            <DropdownToggle caret color="primary" id="dropdown-basic">
                                Page {this.state.skip + 1}
                            </DropdownToggle>
                            <DropdownMenu className="addScroll">
                                {this.skipMenu()}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Row>

                    <Row>
                        <Col>{this.state.commentDetails && this.state.commentDetails.length > 0 ?
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>No</th>
                                        <th>Comment</th>
                                        <th>Date</th>
                                        <th>Module</th>
                                        <th>User</th>
                                        <th>Mobile</th>
                                        <th>Open</th>
                                        <th>Remove</th>
                                    </tr>
                                    {
                                        this.state.commentDetails.map((each, index) => {
                                            console.log(each)
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{each.text}</td>
                                                    <td>{each.dateAndTime ? moment(each.dateAndTime).format('d MMM YYYY') : "Date Not Set"}</td>
                                                    <td>
                                                        {each.moduleTitle}<br />
                                                        <span style={{ fontSize: 12 }}>{each.moduleDescription}</span>
                                                    </td>
                                                    <td>{each.userDetails.name}</td>
                                                    <td>{each.userDetails.mobile}</td>
                                                    <td>
                                                        <Link
                                                            to={`/module/${each.moduleId}`}
                                                            target="_blank"
                                                            style={{ margin: 0, fontSize: 20 }}
                                                        >
                                                            <Button
                                                                outline
                                                                color="primary"
                                                            >
                                                                Module
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                    <td>

                                                        <Button outline color="danger" onClick={() => {
                                                            if (window.confirm("Delete Comments?")) {
                                                                this.deleteButton(each.moduleId, each.commentId)
                                                            }
                                                        }}>
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                style={{ color: '#db1616' }}
                                                                icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            : !this.state.isLoading && !Array.isArray(this.state.userResult) ?
                                <p style={{ textAlign: 'center' }}>Comments Not Found </p>
                            : <p style={{ textAlign: 'center' }}>Loading... </p>
                        }
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
export default Comments