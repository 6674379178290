import React, { Component } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCopy, faCaretDown,faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Button, Container, Row, Col, ListGroup, ListGroupItem, Input,
        Modal, ModalBody, ModalHeader, ModalFooter, Collapse, Table, Badge,
        DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown,
        UncontrolledTooltip } from 'reactstrap';
import { Checkbox } from 'material-ui'
import { connect } from 'react-redux'
import { groupBy, isFinite, cloneDeep, intersection }from 'lodash'
import queryString from 'query-string';
import arrayMove from 'array-move';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'
import ScrollIntoView from 'scroll-into-view-if-needed'

import { updateQuestions } from '../actions'
import './Pages.css'
import { addExtraDetailsWithQuestions, copyToClipboard } from '../utils';
import Loader from '../Components/Loader';

import { updateSalesmen } from '../actions'
import MathQuestionViewer from '../Components/MathQuestionViewer';
import moment from 'moment';

const subjectsWithOutGk = [
    "5c15e4282382552bef36d777", // science
    "5deb1eb6342a6e576bdf71ab", // computer
    "5e68b3703a990a3faef7e8f1", // first aid
    "5c36781b2546324e8101bba2", // English
    "5edcdc6a08d8684a2e492eb2", // Maths
    "5c97425fbf6a70489a388a3e"  // malayalam
]
class EditMockExam extends Component {

    state = {
        mockExamName: '',
        newQuestionIdsArray:null,
        showAddNewQuestionForm:false,
        openIndex:null,
        totalCaQuestions: null,
        isLoading: true,
        questionIds: [],
        replacedQuestionIds: [],
        contentTeamArray:[],
        selectedUserIndex:'',
        questionIdsToCopyArr: [],
        approvedQuestionsCount: 0
    }

    componentDidMount() {

        this.getQuestions()

        const contentTeam = this.props.salesmen.filter(item => {
            if (intersection(item.roleIds, [12, 13, 15, 16, 17, 18, 19]).length > 0) {
                return true
            }
        })

        this.setState({ contentTeamArray: contentTeam })
        
    }

    replaceQuestion(question, examId, isPathExam, type) {

        axios.patch('/exam/replace-question', {
            questionId: question._id,
            packId: question.packId,
            unitId: question.unitsId,
            topicId: question.topicId,
            examId,
            isPathExam,
            type
        })
            .then((res) => {
                
                this.getQuestions(res.data.addedQuestionId, question._id)

                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })

            })
            .catch(err => {
                
                this.setState({ isLoading: false })
                console.log(err)

                if (err.response) {
                    alert(err.response.data.message)
                } else {
                    alert("Error. Check console")
                }
                
            })

    }

    addCaDetailsInSubjectWiseCount(subjectWiseCount)  {

        let totalCaQuestions = 0

        this.props.location.state.subjectWiseCount.map(each => {

            let checked = false

            totalCaQuestions += each.subjectWiseCaCount
            each.totalCaQuestions = totalCaQuestions

            subjectWiseCount.forEach(item => {
                if (each._id === item._id) {
                    item.subjectWiseCaCount = each.subjectWiseCaCount
                    let checkSection = false
                    each.sectionWiseStats.forEach(eachSection => {
                        item.sectionWiseStats.forEach(section => {
                            if (eachSection._id === section._id) {
                                section.name = eachSection.sectionName
                                section.caQuestionsCount = eachSection.caQuestionsCount
                                checkSection = true
                            }
                        })

                        if (!checkSection) {
                            eachSection.name = eachSection.sectionName
                            eachSection.typeCount = 0
                            item.sectionWiseStats.push(eachSection)
                        }
                    })
                    checked = true
                }
            })  

            if (!checked) {

                each.htmlCount = 0
                each.questionCount = 0
                each.totalHtmlCount = subjectWiseCount[subjectWiseCount.length - 1].totalHtmlCount
                each.totalQuestionCount = subjectWiseCount[subjectWiseCount.length - 1].totalQuestionCount
                each.sectionWiseStats.forEach(section => {
                    section.name = section.sectionName
                    section.typeCount = 0
                })

                subjectWiseCount.push(each)

            }

            return each
        })

        this.setState({ totalCaQuestions })
        return subjectWiseCount
    }  

    filterQuestionsByGeneralAndOthers(questions) {

        const generalKnowledge = []
        const otherQuestions = []

        questions.forEach(question => {
            if (subjectsWithOutGk.includes(question.subjectId)) {
                otherQuestions.push(question)
            } else {
                generalKnowledge.push(question)
            }
        })

        return { generalKnowledge, otherQuestions }

    }

    getSubjectWiseCount(questions) {

        
        let textTotalCount = 0
        let htmlTotalCount = 0
        let mathTotalCount = 0
        let statementTotalCount = 0
        let matchTotalCount = 0
        
        let totalQuestionCount = 0
        let groupedBySubjects = groupBy(questions, (question) => question.subjectId)

        let subjectWiseCount = Object.keys(groupedBySubjects).map((each, index) => {

            let subjectIndex = this.props.subjects.findIndex(subject => subject._id === each)


            if (subjectIndex >= 0) {

                let sectionWiseStats = []
                let textCount = 0
                let htmlCount = 0
                let mathCount = 0
                let statementCount = 0
                let matchCount = 0

                groupedBySubjects[each].forEach(question => {
                    
                    let section = this.props.subjects[subjectIndex].sections.find(e => e._id === question.sectionId)

                    if (section) {
                        
                        let checkIndex = null

                        sectionWiseStats.forEach((item, index) => {
                            if (item && item.name === section.name) {
                                checkIndex = index
                            }
                        })

                        if (sectionWiseStats.length === 0 || !isFinite(checkIndex)) {
                            
                            let obj = {
                                _id: section._id,
                                questionCount: 1,
                                name: section.name,
                                textCount: 0,
                                htmlCount: 0,
                                mathCount: 0,
                                statementCount: 0,
                                matchCount: 0,
                                caQuestionsCount: 0
                            }
                            
                            if (question.type === 'text') {
                                obj.textCount = 1
                            } else if (question.type === 'html') {
                                obj.htmlCount = 1
                            } else if (question.type === 'math') {
                                obj.mathCount = 1
                            } else if (question.type === 'statement') {
                                obj.statementCount = 1
                            } else if (question.type === 'match') {
                                obj.matchCount = 1
                            }

                            sectionWiseStats.push(obj)

                        } else {
                            
                            sectionWiseStats[checkIndex].questionCount = sectionWiseStats[checkIndex].questionCount + 1
                            sectionWiseStats[checkIndex].caQuestionsCount = 0
                            
                            if (question.type === 'text') {
                                sectionWiseStats[checkIndex].textCount = sectionWiseStats[checkIndex].textCount + 1
                            } else if (question.type === 'html') {
                                sectionWiseStats[checkIndex].htmlCount = sectionWiseStats[checkIndex].htmlCount + 1
                            } else if (question.type === 'math') {
                                sectionWiseStats[checkIndex].mathCount = sectionWiseStats[checkIndex].mathCount + 1
                            } else if (question.type === 'statement') {
                                sectionWiseStats[checkIndex].statementCount = sectionWiseStats[checkIndex].statementCount + 1
                            } else if (question.type === 'match') {
                                sectionWiseStats[checkIndex].matchCount = sectionWiseStats[checkIndex].matchCount + 1
                            }
                            
                        }
                    }

                    if (question.type === 'text') {
                        textCount = textCount + 1
                    } else if (question.type === 'html') {
                        htmlCount = htmlCount + 1
                    } else if (question.type === 'math') {
                        mathCount = mathCount + 1
                    } else if (question.type === 'statement') {
                        statementCount = statementCount + 1
                    } else if (question.type === 'match') {
                        matchCount = matchCount + 1
                    }

                }); 

                textTotalCount += textCount
                htmlTotalCount += htmlCount
                mathTotalCount += mathCount
                statementTotalCount += statementCount
                matchTotalCount += matchCount
                
                totalQuestionCount += groupedBySubjects[each].length

                let returnObj = {
                    _id:this.props.subjects[subjectIndex]._id,
                    subjectName: this.props.subjects[subjectIndex].name,
                    subtitle:this.props.subjects[subjectIndex].subtitle,
                    questionCount: groupedBySubjects[each].length,
                    sectionWiseStats,
                    
                    textCount, htmlCount, mathCount, statementCount, matchCount,

                    totalQuestionCount
                }

                if (this.props.location && this.props.location.state && this.props.location.state.subjectWiseCount) { 
                    returnObj.subjectWiseCaCount = 0
                }

                return returnObj

            } else {
                return {
                    _id:index,
                    subjectName: "Not found",
                    subtitle: "Not found",
                    questionCount: 0,
                    sectionWiseStats: {}
                }
            }

        })

        this.setState({
            textTotalCount,
            htmlTotalCount,
            mathTotalCount,
            statementTotalCount,
            matchTotalCount,
        })

        return subjectWiseCount
    }

    getQuestions(questionId, replacedQuestionId) {
        
        const { sectionId, mockExamId } = queryString.parse(this.props.location.search)

        let requestData = {
            count: 999,
            subjectId: 1,
            activeFlag: null,
            examId: mockExamId
        }

        if (questionId) {
            requestData.questionId = questionId
        }

        axios.get(`/get-questions`, { params: requestData })
            .then(res => {
                const { questions, examName, isPathExam, examType } = res.data.data
                this.setQuestionsToState({ questions, examName, isPathExam, examType, sectionId, questionId, replacedQuestionId })
            })
            .catch(err => {
                alert('Error. Please check console')
                console.log(err)
            })
    }
    
    setQuestionsToState = ({ questions, examName, isPathExam, examType, sectionId, questionId, replacedQuestionId }) => {

        try {

            let setObject = {}
            if (examName) { setObject.examName = examName }
            if (isPathExam) { setObject.isPathExam = isPathExam }
            if (examType) { setObject.examType = examType }

            let allNewQuestions = []

            if (questionId && questions.length === 1) {
                const replacedQuestionIndex = this.state.allQuestions.findIndex(each => each._id === replacedQuestionId)
                const allOtherQuestions = this.state.allQuestions.filter(each => each._id !== replacedQuestionId)
                allOtherQuestions.splice(replacedQuestionIndex, 0, questions[0]);
                allNewQuestions = allOtherQuestions
            } else {
                allNewQuestions = questions
            }

            let allQuestions = []

            const { generalKnowledge, otherQuestions } = this.filterQuestionsByGeneralAndOthers(allNewQuestions)

            allQuestions.push(...generalKnowledge)
            allQuestions.push(...otherQuestions)

            let subjectWiseCount = this.getSubjectWiseCount(allNewQuestions)
            const approvedQuestionsCount = allNewQuestions.filter(each => each.activeFlag).length

            if (this.props.location && this.props.location.state && this.props.location.state.subjectWiseCount) {
                subjectWiseCount = this.addCaDetailsInSubjectWiseCount(subjectWiseCount)
            }

            let questionIds = allQuestions.map(item => item._id)
            let replacedQuestionIds = cloneDeep(this.state.replacedQuestionIds)

            if (this.state.questionIds.length > 0) {

                const newQuestionsIndex = questionIds.findIndex(item => !this.state.questionIds.includes(item))

                if (newQuestionsIndex >= 0) {
                    replacedQuestionIds.push(questionIds[newQuestionsIndex])
                    const deletedQuestionIndex = this.state.questionIds.findIndex(eachId => !questionIds.includes(eachId))
                    allQuestions = arrayMove(allQuestions, newQuestionsIndex, deletedQuestionIndex)
                    questionIds = allQuestions.map(item => item._id)
                }

            }

            //function to add section details, exam name, path name along with questions
            allQuestions = addExtraDetailsWithQuestions(allQuestions)

            this.setState({
                questionIds,
                replacedQuestionIds,
                subjectWiseCount,
                approvedQuestionsCount,
                allQuestions,
                isLoading: false,
                ...setObject
            })

            this.props.updateQuestions({
                questions: allQuestions,
                count: allQuestions.count
            })

            if (sectionId) {
                this.filterQuestionStatus(sectionId)
            } else {

                const { sectionId, subjectId } = queryString.parse(this.props.location.search)

                if (sectionId) {
                    this.filterQuestionStatus(sectionId)
                } else if (subjectId) {
                    this.filterQuestionBySubject(subjectId)
                }

            }

        } catch (err) {
            console.log(err)
            alert("Error while setting questions to state")
        }

    }

    addQuestions = () => {
        if (this.state.newQuestionIdsArray) {

            const questionIdsSplitted = this.state.newQuestionIdsArray.split("\n")
            const allItems = questionIdsSplitted.map(each => each.replace(/\s/g, ''))
            const questionIds = allItems.filter(each => each)

            if (questionIds.length > 0) {

                axios.post('/exam/add-question', {
                    questionIds,
                    examId: queryString.parse(this.props.location.search).mockExamId
                })
                    .then(() => {

                        this.setState((currentState) => {

                            if (currentState.replacedQuestionIds) {
                                currentState.replacedQuestionIds.push(...questionIds)
                            } else {
                                currentState.replacedQuestionIds = questionIds
                            }

                            return {
                                newQuestionIdsArray: null,
                                showAddNewQuestionForm: false,
                                replacedQuestionIds: currentState.replacedQuestionIds
                            }
                        }, () => this.getQuestions())

                    })
                    .catch(err => {
                        alert("Error. Check console")
                        console.log(err)
                    })
            } else {
                alert("Enter valid question ID")
            }

        } else {
            alert("Enter valid question ID")
        }

    }

    deleteQuestion(questionId, index) {
        
        this.setState({ isLoading: true })

        axios.patch('/exam', { questionId, examId: queryString.parse(this.props.location.search).mockExamId })
        .then(() => {
            const remainingQuestions = this.props.questionsData.questions.filter(each => each._id !== questionId)
            this.setQuestionsToState({ questions: remainingQuestions })
        })
        .catch(err => {
            this.setState({ isLoading: true })
            console.log(err)
            alert("Error. Check console")
        })

    }

    setQueryParams(keyName, value) {

        const existingQueryParams = queryString.parse(this.props.location.search)
        existingQueryParams[keyName] = value

        if (keyName === 'subjectId') {
            delete existingQueryParams.sectionId
        } else if (keyName === 'sectionId') {
            delete existingQueryParams.subjectId
        }

        let searchParams = ""
        Object.keys(existingQueryParams).forEach((eachKey, index) => {
            searchParams += `${eachKey}=${existingQueryParams[eachKey]}&`
        })


        this.props.history.push({
            pathname: `/exams/edit`,
            search: searchParams
        })

    }

    filterQuestionStatus(sectionId, autoScrollToTop) {
        let filteredData = this.state.allQuestions.filter(item => item.sectionId === sectionId)
        this.props.updateQuestions({ questions: filteredData, count: filteredData.length })
        this.setState({ filteredSectionDetail: { sectionName: filteredData[0].sectionName, subjectName: filteredData[0].subjectName } }, () => {
            if (autoScrollToTop) {
                const node = document.getElementById('questionsTable')
                ScrollIntoView(node)
            }
        })
        this.setQueryParams('sectionId', sectionId)
    }

    filterQuestionBySubject(subjectId, autoScrollToTop) {
        
        let filteredData = this.state.allQuestions.filter(item => item.subjectId === subjectId)
        this.props.updateQuestions({ questions: filteredData, count: filteredData.length })
        this.setState({ 
            filteredSectionDetail: { subjectName: filteredData[0].subjectName },
            filteredQuestionType: null
        }, () => {
            if (autoScrollToTop) {
                const node = document.getElementById('questionsTable')
                ScrollIntoView(node)
            }
        })
        this.setQueryParams('subjectId', subjectId)
    }

    filterQuestionByType(questionType) {
        
        let filteredData = this.state.allQuestions.filter(item => item.type === questionType)

        this.props.updateQuestions({ questions: filteredData, count: filteredData.length,  })

        this.setState({
            filteredQuestionType: questionType,
            filteredSectionDetail: null,
        }, () => {
            if (filteredData.length) {
                const node = document.getElementById('questionsTable')
                ScrollIntoView(node)
            }
        })

        this.setQueryParams('questionType', questionType)

    }

    renderQuestionStats() {

        let { subjectWiseCount, openIndex, totalCaQuestions } = this.state

        if (subjectWiseCount && subjectWiseCount.length > 0) {
            return (
                <ListGroup>
                    
                    <ListGroupItem>
                        <Table borderless>
                            <thead>
                                <tr>
                                    <th style={{ width: '30%' }}>Subject</th>
                                    <th style={{ width: '10%' }}>
                                        <Button onClick={ () => this.filterQuestionByType("text") } color="link">
                                            Text
                                        </Button>
                                    </th>
                                    <th style={{ width: '10%' }}>
                                        <Button onClick={ () => this.filterQuestionByType("html") } color="link">
                                            HTML
                                        </Button>
                                    </th>
                                    <th style={{ width: '10%' }}>
                                        <Button onClick={ () => this.filterQuestionByType("math") } color="link">
                                            Math
                                        </Button>
                                    </th>
                                    <th style={{ width: '10%' }}>
                                        <Button onClick={ () => this.filterQuestionByType("statement") } color="link">
                                            Statement
                                        </Button>
                                    </th>
                                    <th style={{ width: '10%' }}>
                                        <Button onClick={ () => this.filterQuestionByType("match") } color="link">
                                            Match
                                        </Button>
                                    </th>
                                    <th style={{ width: '10%' }}>Total</th>
                                    <th style={{ width: '10%' }}>Actions</th>
                                </tr>
                            </thead>
                        </Table>
                    </ListGroupItem>

                    {
                        subjectWiseCount.map((each, index) => {
                            const isOpen = openIndex === index
                            return (
                                <ListGroupItem key={each._id} >
                                    <Table borderless style={{ marginBottom: 0 }}>
                                        <tbody>
                                            
                                            <tr style={{ display: 'flex', justifyContent: 'space-between', alignContent: "center", height: isOpen ? "50px" : "20px" }}>
                                                <td style={{ width: '30%' }}>
                                                    <div style={{ margin: 0, display: "flex", alignItems: "center" }}>
                                                        <p style={{ margin: 0 }}>
                                                            <Button
                                                                color="link"
                                                                onClick={() => this.filterQuestionBySubject(each._id, true)}
                                                            >
                                                                {`${each.subjectName}`}
                                                            </Button>
                                                        </p>
                                                        <p style={{ margin: 0, marginLeft: "10px", fontSize: "13px" }}>{each.subtitle ? <>{each.subtitle}</> : null}</p>
                                                    </div>
                                                </td>
                                                
                                                {/* <td style={{ flex: 1 }}>{isOpen ? null : each.questionCount}</td> */}
                                                <td style={{ width: '10%' }}>{isOpen ? null : each.textCount} </td>
                                                <td style={{ width: '10%' }}>{isOpen ? null : each.htmlCount} </td>
                                                <td style={{ width: '10%' }}>{isOpen ? null : each.mathCount} </td>
                                                <td style={{ width: '10%' }}>{isOpen ? null : each.statementCount} </td>
                                                <td style={{ width: '10%' }}>{isOpen ? null : each.matchCount} </td>
                                                <td style={{ width: '10%' }}>{isOpen ? null : `${each.textCount + each.htmlCount + each.mathCount + each.statementCount + each.matchCount}`} </td>

                                                <td style={{ width: '10%' }}>
                                                    <Button
                                                        color={isOpen ? "primary" : "secondary"}
                                                        size="sm"
                                                        onClick={() => this.setState({ openIndex: isOpen ? null : index })}>
                                                        <FontAwesomeIcon
                                                            icon={isOpen ? faCaretUp : faCaretDown}
                                                        >
                                                            {isOpen ? "CLOSE" : "OPEN"}
                                                        </FontAwesomeIcon>
                                                    </Button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Collapse isOpen={isOpen}>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <td style={{ width: '30%' }}>Section Name</td>
                                                                    <td style={{ width: '10%' }}>Text</td>
                                                                    <td style={{ width: '10%' }}>HTML</td>
                                                                    <td style={{ width: '10%' }}>Math</td>
                                                                    <td style={{ width: '10%' }}>Statement</td>
                                                                    <td style={{ width: '10%' }}>Match</td>
                                                                    <td style={{ width: '10%' }}>Total</td>
                                                                    <td  style={{ width: '10%' }}>
                                                                        {isFinite(each.subjectWiseCaCount) ?  "CA removed questions" : '' }
                                                                    </td>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    each.sectionWiseStats.map(eachSection => {
                                                                        return (
                                                                            <tr key={eachSection._id}>
                                                                                
                                                                                <td
                                                                                    style={{ margin: 5 }}
                                                                                    onClick={() => this.filterQuestionStatus(eachSection._id, true)}
                                                                                >
                                                                                    <Button color="link" style={{ textAlign: 'left' }}>
                                                                                        {`${eachSection.name}`}
                                                                                    </Button>
                                                                                </td>

                                                                                <td style={{ width: '10%' }}>{eachSection.textCount}</td>
                                                                                <td style={{ width: '10%' }}>{eachSection.htmlCount}</td>
                                                                                <td style={{ width: '10%' }}>{eachSection.mathCount}</td>
                                                                                <td style={{ width: '10%' }}>{eachSection.statementCount}</td>
                                                                                <td style={{ width: '10%' }}>{eachSection.matchCount}</td>
                                                                                <td style={{ width: '10%' }}>{eachSection.textCount + eachSection.htmlCount + eachSection.mathCount + eachSection.statementCount + eachSection.matchCount}</td>

                                                                                {isFinite(each.subjectWiseCaCount) && <td style={{ margin: 5, color: 'red' }}> {`${eachSection.caQuestionsCount}`}</td>}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                {/* <tr>
                                                                    <td></td>
                                                                    <td>{isOpen ? each.textCount : null}</td>
                                                                    <td>{isOpen ? each.htmlCount : null}</td>
                                                                    <td>{isOpen ? each.mathCount : null}</td>
                                                                    <td>{isOpen ? each.statementCount : null}</td>
                                                                    <td>{isOpen ? each.matchCount : null}</td>
                                                                    <td>{isOpen && isFinite(each.subjectWiseCaCount) ? <p style={{ color: 'red' }}>{each.subjectWiseCaCount}</p> : null}</td>
                                                                </tr> */}
                                                            </tbody>
                                                        </Table>
                                                    </Collapse>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </ListGroupItem>
                            )
                        })
                    }

                    <ListGroupItem>
                        <Table  borderless>
                            <tbody style={{ fontWeight: "bold" }}>
                                <tr style={{ display: "flex" }}>
                                    <th style={{ width: '30%' }}>Total</th>
                                    <th style={{ width: '10%' }}>{this.state.textTotalCount}</th>
                                    <th style={{ width: '10%' }}>{this.state.htmlTotalCount}</th>
                                    <th style={{ width: '10%' }}>{this.state.mathTotalCount}</th>
                                    <th style={{ width: '10%' }}>{this.state.statementTotalCount}</th>
                                    <th style={{ width: '10%' }}>{this.state.matchTotalCount}</th>
                                    <th style={{ width: '10%' }}>{this.state.textTotalCount + this.state.htmlTotalCount + this.state.mathTotalCount + this.state.statementTotalCount + this.state.matchTotalCount}</th>
                                    <th style={{ width: '10%' }}></th>
                                </tr>
                            </tbody>
                        </Table>
                    </ListGroupItem>
                    
                </ListGroup>
            )
        }
    }

    filterQuestions(userId){
        let filteredData = this.state.allQuestions.filter(item => item.addedBy._id === userId)
        this.props.updateQuestions({ questions: filteredData, count:  filteredData.length })
    }

    checkAllQuestions=(e,value)=>{
        if (value){
                let questions = cloneDeep(this.props.questionsData.questions)
                questions.forEach(each => {
                    if (!this.state.questionIdsToCopyArr.includes(each._id)) {
                        this.setState(oldState =>{
                            oldState.questionIdsToCopyArr.push(each._id)
                            return { questionIdsToCopyArr: oldState.questionIdsToCopyArr }
                        })
                    }
                })
        } else {
            this.setState({ questionIdsToCopyArr: [] })
        }
    }

    addQuestionIdsToCopyArr(questionId, checkedValue) {
        if (checkedValue) {
            this.setState(oldState => {
                oldState.questionIdsToCopyArr.push(questionId)
                return { questionIdsToCopyArr: oldState.questionIdsToCopyArr }
            })        
        } else {
            this.setState(oldState => {
                let filterIds = oldState.questionIdsToCopyArr.filter(item => item !== questionId)
                return { questionIdsToCopyArr: filterIds }
            }) 
        }
    }

    copyIdsToClipboard() {
        
        let string = ''

        this.state.questionIdsToCopyArr.forEach(item => {
            if (string) {
                string = `${string}\n${item}`
            } else {
                string = item
            }
        })

        var textField = document.createElement('textarea')
        textField.innerHTML = string
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        toast("Copied Ids", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        })

    }

    render() {
        if (this.props.questionsData.questions) {
            return (
                <>
                    {this.state.isLoading && <Loader />}
                    <Container fluid style={{ marginTop: 50, marginBottom: 50 }}>



                        <Modal isOpen={this.state.showAddNewQuestionForm}>

                            <ModalHeader>
                                Enter New Question ID
                            </ModalHeader>

                            <ModalBody>
                                <Input
                                    type="textarea"
                                    rows="10"
                                    name="newQuestionIdsArray"
                                    id="newQuestionIdsArray"
                                    value={this.state.newQuestionIdsArray || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ newQuestionIdsArray: value })
                                    }}
                                    placeholder={"One question ID per line please"}
                                />
                                {/* 
                                <Input
                                    value={this.state.newQuestionId || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ newQuestionId: value })
                                    }}
                                />
                            */}
                            </ModalBody>

                            <ModalFooter>
                                <Button outline color="primary" onClick={this.addQuestions}>Submit</Button>
                                <Button outline onClick={() => this.setState({ newQuestionIdsArray: null, showAddNewQuestionForm: false })}>Cancel</Button>
                            </ModalFooter>

                        </Modal>

                        <Row style={{ marginBottom: 10 }}>
                            <Col md="4">
                                <ListGroup>
                                    <ListGroupItem>
                                        <h5>Exam Name: {this.state.examName}</h5>
                                        <h6 style={{ marginTop: 10, marginBottom: 20 }}>Total Questions: {this.state.approvedQuestionsCount}/{this.props.questionsData.questions.length}</h6>
                                        <div style={{display:'flex'}}>
                                            <Button
                                                  color="primary"
                                                  outline 
                                                  style={ {marginRight:'1rem'} }
                                                  onClick={() => this.setState({ showAddNewQuestionForm: true })}
                                                  >
                                                    + Add Question
                                            </Button>
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret>
                                                    { this.state.selectedUserIndex ? this.state.contentTeamArray[this.state.selectedUserIndex].name : 'Select All' }
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.state.contentTeamArray.map((user,index)=> {
                                                        return(
                                                            <DropdownItem
                                                                key= {user._id}
                                                                value= {user._id}
                                                                onClick= { ()=>this.setState({ selectedUserIndex: index },() => this.filterQuestions(user._id)) }
                                                            >
                                                                {user.name}
                                                            </DropdownItem>
                                                        )
                                                     })
                                                    }

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>

                            <Col md="8">
                                <ListGroup>
                                    {this.renderQuestionStats()}
                                </ListGroup>
                            </Col>


                        </Row>
                        {
                            this.state.filteredSectionDetail &&
                            <Row>
                                <Col md='4'>
                                    <Button
                                        color="secondary"
                                        outline
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                            const mockExamId = queryString.parse(this.props.location.search).mockExamId;
                                            this.props.history.push({
                                                pathname: `/exams/edit`,
                                                search: `mockExamId=${mockExamId}&`
                                            })
                                            this.setState({ filteredSectionDetail: null })
                                            this.props.updateQuestions({ questions: this.state.allQuestions, count: this.state.allQuestions.length })
                                        }}
                                    >
                                        X
                                    </Button>
                                    <Badge style={{ borderRadius: 10, paddingTop: 7, paddingBottom: 7 }}>
                                        {`${this.state.filteredSectionDetail.subjectName}${this.state.filteredSectionDetail.sectionName ? `- ${this.state.filteredSectionDetail.sectionName}` : ''}`}
                                    </Badge>
                                </Col>
                            </Row>
                        }
                        {
                            this.state.filteredQuestionType &&
                            <Row>
                                <Col md='4'>
                                    <Button
                                        color="secondary"
                                        outline
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                            const mockExamId = queryString.parse(this.props.location.search).mockExamId;
                                            this.props.history.push({
                                                pathname: `/exams/edit`,
                                                search: `mockExamId=${mockExamId}&`
                                            })
                                            this.setState({ filteredQuestionType: null })
                                            this.props.updateQuestions({ questions: this.state.allQuestions, count: this.state.allQuestions.length })
                                        }}
                                    >
                                        X
                                    </Button>
                                    <Badge style={{ borderRadius: 10, paddingTop: 7, paddingBottom: 7 }}>
                                        {this.state.filteredQuestionType}
                                    </Badge>
                                </Col>
                            </Row>
                        }
                        {
                            this.props.questionsData.questions.length > 0 ?
                                <>
                                    <hr style={{ marginTop: 20, marginBottom: 20 }} />
                                    <h4 id="questionsTable">Questions</h4>
                                    <Row>
                                        <Col>
                                            <div>

                                                <Row style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems:'center',
                                                    marginBottom: 10,
                                                    marginLeft: 20
                                                }}>
                                                    <Checkbox
                                                        style={{ display: "inline", width: 50 }}
                                                        onCheck={(e, value) => this.checkAllQuestions(e, value)}
                                                        checked={this.state.questionIdsToCopyArr.length === this.props.questionsData.questions.length}
                                                    />
                                                    <Button
                                                        sttyle={{}}
                                                        color='primary'
                                                        display="inline"
                                                        disabled={this.state.questionIdsToCopyArr.length === 0}
                                                        onClick={() => this.copyIdsToClipboard()}
                                                    >
                                                        Copy Ids
                                                    </Button>
                                                    <Col>{this.state.questionIdsToCopyArr.length > 0 ? `${this.state.questionIdsToCopyArr.length} Questions Selected `: null}</Col>
                                                </Row>

                                                <ListGroup>
                                                    {
                                                        this.props.questionsData.questions.map((question, index) => {
                                                            
                                                            let isNewQuestion = this.state.replacedQuestionIds.includes(question._id)

                                                            return (
                                                                <ListGroupItem
                                                                    action
                                                                    key={question._id}
                                                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                                                    className={(!question.activeFlag) ? 'deletedQuestion' : isNewQuestion && 'fading'}
                                                                >
                                                                    <div style={{ flex: 5 }}>

                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div >
                                                                                <Checkbox 
                                                                                    onCheck={(e, value) => this.addQuestionIdsToCopyArr(question._id, value) } 
                                                                                    checked={this.state.questionIdsToCopyArr.includes(question._id)}
                                                                                />
                                                                            </div>

                                                                            <div>
                                                                                <strong style={{ display: 'flex'}}>
                                                                                    {index + 1}.
                                                                                    {
                                                                                        question.type === 'html' || question.type === 'statement' || question.type === 'match' ?
                                                                                        <div
                                                                                            style={{ marginLeft: 1 }}
                                                                                            dangerouslySetInnerHTML={{ __html: question.question.split("rgb(255,255,255)").join("rgb(0,0,0)") }}
                                                                                        /> :
                                                                                        question.type === 'math' ?
                                                                                        <MathQuestionViewer
                                                                                            delimiter={"$"}
                                                                                            mathText={question.question}
                                                                                        />
                                                                                        :
                                                                                        question.question
                                                                                    }
                                                                                </strong>
                                                                                
                                                                                <p>
                                                                                    Answer: <strong style={{ color: 'green' }}>{ question.options.find(each => each.id === question.answer).text  }</strong>
                                                                                </p>
                                                                                <br/>

                                                                                {
                                                                                    question.description ?
                                                                                        <>
                                                                                            <UncontrolledTooltip
                                                                                                placement="right"
                                                                                                target={`id_${index}`}
                                                                                            >
                                                                                                {question.description}
                                                                                            </UncontrolledTooltip>
                                                                                            <span
                                                                                                href="#"
                                                                                                id={`id_${index}`}
                                                                                                style={{textDecoration: "underline", color:"blue"}}
                                                                                            >{` ${"Explanation"}`}</span>
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                
                                                                                {(!question.activeFlag) &&
                                                                                    <div style={{ color: 'red', fontSize: 13 }}>
                                                                                        {!question.activeFlag ? 'Deleted Question' : null}
                                                                                    </div>
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    (question.subjectName && question.sectionName) ?
                                                                                        <>
                                                                                            <span style={{ fontSize: 12, opacity: 0.7 }}>{question.subjectName} - {question.sectionName}</span>
                                                                                            <br />
                                                                                        </>
                                                                                        : null
                                                                                }

                                                                                {
                                                                                    (question.lastEditedOn) ?
                                                                                        <>
                                                                                            <span style={{ fontSize: 12, opacity: 0.7 }}>Last Edited On: {moment(question.lastEditedOn).format("DD MMM YYYY")}</span>
                                                                                            <br />
                                                                                        </>
                                                                                        :
                                                                                        question.addedOn ?
                                                                                        <>
                                                                                            <span style={{ fontSize: 12, opacity: 0.7 }}>Added On: {moment(question.addedOn).format("DD MMM YYYY")}</span>
                                                                                            <br />
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </div>

                                                                           
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: "flex" }}>
                                                                        <div style={{ marginRight: 20 }}>
                                                                            {
                                                                                Array.isArray(question.examNames) && question.examNames.map(eachItem => {
                                                                                    return (
                                                                                        <div key={eachItem}>
                                                                                            <p style={{ fontSize: 12, marginBottom: -10, textAlign: 'right', opacity: 0.7 }}>{eachItem}</p><br />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div style={{ height: 0.5, backgroundColor: 'lightgrey', marginBottom: 5 }} />
                                                                            {
                                                                                Array.isArray(question.pathNames) && question.pathNames.map(eachItem => {
                                                                                    return (
                                                                                        <div key={eachItem}>
                                                                                            <p style={{ fontSize: 12, marginBottom: -10, textAlign: 'right', opacity: 0.7 }}>{eachItem}</p><br />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <div>

                                                                            <Button
                                                                                style={{ marginRight: 10 }}
                                                                                onClick={() => { 
                                                                                    copyToClipboard(question._id) 
                                                                                    toast("ID copied", {
                                                                                        position: toast.POSITION.TOP_CENTER,
                                                                                        hideProgressBar: true
                                                                                    })
                                                                                }}
                                                                                outline
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    className="iconButton"
                                                                                    icon={faCopy}
                                                                                />
                                                                            </Button>

                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/questions/edit",
                                                                                    search: `&questionId=${question._id}`
                                                                                }}
                                                                                target="_blank"
                                                                            >
                                                                                <Button
                                                                                    color="primary"
                                                                                    outline
                                                                                    style={{ marginRight: 10 }}
                                                                                >Edit</Button>
                                                                            </Link>

                                                                            {
                                                                                this.state.examType === "mock" &&
                                                                                <Button color="primary" outline style={{ marginRight: 10 }}
                                                                                    onClick={() => {
                                                                                        if (window.confirm("Replace this question with a new question from the same topic?")) {
                                                                                            this.setState({ isLoading: true })
                                                                                            this.replaceQuestion(question, queryString.parse(this.props.location.search).mockExamId, this.state.isPathExam)
                                                                                        }
                                                                                    }}
                                                                                >Replace</Button>
                                                                            }
                                                                            {
                                                                                this.state.examType === "mock" &&
                                                                                <Button color="primary" outline style={{ marginRight: 10 }}
                                                                                    onClick={() => {
                                                                                        if (window.confirm("Replace this question with another HTML question from the same topic?")) {
                                                                                            this.setState({ isLoading: true })
                                                                                            this.replaceQuestion(question, queryString.parse(this.props.location.search).mockExamId, this.state.isPathExam, "html")
                                                                                        }
                                                                                    }}
                                                                                >Replace with HTML</Button>
                                                                            }
                                                                            <Button color="danger" outline onClick={() => { if (window.confirm('Delete question from exam? This action is IRREVERSIBLE')) { this.deleteQuestion(question._id, index) }; }} >Delete</Button>
                                                                        </div>

                                                                    </div>
                                                                </ListGroupItem>
                                                            )
                                                        })
                                                    }
                                                </ListGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <h4 style={{ marginTop: 50 }}>No questions added</h4>
                        }

                    </Container>
                </>
            )
        } else {
            return <Loader />
        }
    }
}

const mapStateToProps = state => {
    return {
        questionsData: state.data.questions,
        subjects:state.data.subjects,
        salesmen: state.data.salesmen,
    }
}

export default connect(mapStateToProps, { updateSalesmen, updateQuestions })(EditMockExam) 