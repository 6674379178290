import React, { Component } from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row, Button, Dropdown, DropdownToggle, Input,
    DropdownMenu, DropdownItem,Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'

import { DatePicker } from 'material-ui'
import { connect } from 'react-redux'
import Loader from '../Components/Loader'
import { cloneDeep, intersection } from 'lodash'
import { commafy, dropDownPeriods } from '../utils'

const getTotals = (each) => {
    let eachTotalCount = (each.organicPremiumCount || 0) + (each.salesPremiumCount || 0) + (each.organicSilverCount || 0) + (each.salesSilverCount || 0) + (each.goldCount || 0)
    let eachTotalRevenue = (each.organicPremiumRevenue || 0) + (each.salesPremiumRevenue || 0) + (each.organicSilverRevenue || 0) + (each.salesSilverRevenue || 0) + (each.goldRevenue || 0)
    return { eachTotalRevenue, eachTotalCount }
}
class SalesReport extends Component {

    state = {
        isLoading: false,
        isOpen: false,
        data: null,
        fromDate: null,
        toDate: null,
        periodLabel: "Today",
        showCustomTime: false,
        period: "today",
        salesDetails: null,

        salesmen: this.filterAndSortSalesmen(),
        courseDetails: this.props.packs,
        crashTypeSelectorIsOpen: false,
        selectedCrashCourseId: null,
        selectedCrashCourseTitle: "All",
    }

    filterAndSortSalesmen() {

        let salesmen = cloneDeep(this.props.salesmen)

        salesmen = salesmen.filter(salesman => salesman.activeFlag && intersection(salesman.roleIds, [7,8,9]).length)

        salesmen.sort(function SortArray(x, y) {
            if (x.name.toLowerCase() < y.name.toLowerCase()) { return -1 }
            if (x.name.toLowerCase() > y.name.toLowerCase()) { return 1 }
            return 0
        })

        return salesmen
    }

    getAllCoursePacks() {
        this.setState({ isLoading:  true }, () => {
            axios.get('/course-group/admin', { params: { getNameOnly: true }})
                .then(res => {
                    this.setState({ courseDetails: res.data.courseGroups })
                    this.setState({ isLoading:  false })
                })
                .catch(err => {
                    this.setState({ isLoading:  false })
                    console.log(err)
                    alert("Server error while getting course packs")
                })
        })
    }

    componentDidMount() {
        this.getAllCoursePacks()
    }

    getStats() {

        const { period, fromDate, toDate, salesmanId } =  this.state
        let params = { selectedCrashCourseId: this.state.selectedCrashCourseId }
        
        if (salesmanId) {
            params.salesmanId = salesmanId
        }

        if (fromDate && toDate) {
            params.fromDate = fromDate
            params.toDate = toDate
        } else {
            params.period = period
        }

        this.setState({ isLoading: true }, () => {
            axios.get(`/${this.props.apiEndPoint}`, { params })
                .then(res => {
                    this.setState({ data: res.data, isLoading: false })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Server error. Please check console")
                })
        })

    }

    fetchSalesDetails(date) {
        this.setState({ isLoading: true }, () => {
            axios.get('/sales-details', { params: { date, period: this.state.period } })
                .then(res => {
                    this.setState({ salesDetails: res.data.salesDetails, isLoading: false })
                })
                .catch(err => {
                    this.setState({ isLoading:false })
                    console.log(err)
                    alert("Server error. Please check console")
                })
        })
    }

    render() {

        let totalOrganicPremium = 0
        let totalOrganicPremiumCount = 0
        let totalSalesPremium = 0
        let totalSalesPremiumCount = 0
        let totalOrganicSilver = 0
        let totalOrganicSilverCount = 0
        let totalSalesSilver = 0
        let totalSalesSilverCount = 0
        let totalGold = 0
        let totalGoldCount = 0
        let totalRevenue = 0
        let totalCount = 0


        return (
            <>
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}

                {
                    this.state.salesDetails ?
                        <Modal
                            size="lg"
                            isOpen={Array.isArray(this.state.salesDetails)}
                        >
                            <ModalHeader>
                                Single day sales distribution
                            </ModalHeader>

                            <ModalBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <td>Index</td>
                                            <td>Type</td>
                                            <td>Count</td>
                                            <td>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.salesDetails.map((each, index) => {
                                                return (
                                                    <tr key={each._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{each._id}</td>
                                                        <td>{each.totalCount}</td>
                                                        <td>{each.totalAmount}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    outline
                                    onClick={() => this.setState({ salesDetails: false })}
                                    color="primary"
                                >
                                    Close
                            </Button>
                            </ModalFooter>

                        </Modal> : null
                }

                <Container fluid>
                    <Row style={{ display: 'flex', flexDirection: 'row', margin: '5rem', marginTop: 20, marginBlock: 10, alignItems:'center' }}>
                        
                        <Dropdown isOpen={this.state.isOpen} toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                            <DropdownToggle caret>{this.state.periodLabel}</DropdownToggle>
                            <DropdownMenu>
                                {
                                    dropDownPeriods.map((each) => {
                                        return (
                                            <DropdownItem
                                                key={each.value}
                                                onClick={() => this.setState({
                                                    period: each.value,
                                                    isOpen: false,
                                                    periodLabel: each.label,
                                                    showCustomTime: true
                                                })}
                                            >
                                                {each.label}
                                            </DropdownItem>
                                        )
                                    })
                                }
                            </DropdownMenu>
                        </Dropdown>

                        {this.state.period === "customTime" && this.state.showCustomTime ?
                            <div style={{ display: "flex", marginLeft: 10, alignItems: 'center' }}>
                                <DatePicker
                                    hintText="From Date"
                                    container="inline"
                                    value={this.state.fromDate}
                                    onChange={(e, date) => this.setState({ fromDate: date })}
                                />

                                <DatePicker
                                    hintText="To Date"
                                    container="inline"
                                    value={this.state.toDate}
                                    onChange={(e, date) => this.setState({ toDate: date })}
                                />


                                {this.state.fromDate && this.state.toDate ? (
                                    <Button
                                        outline
                                        color="danger"
                                        style={{ margin: 10 }}
                                        disabled={!this.state.fromDate || !this.state.toDate}
                                        onClick={ () => this.setState({ fromDate: null, toDate: null, isLoading: true }) }
                                    >
                                        CLEAR DATE FILTER
                                    </Button>
                                ) : null}
                            </div>
                            : null
                        }

                        {
                            Array.isArray(this.props.packs) && this.props.packs.length > 0 &&
                            <Dropdown
                                isOpen={this.state.crashTypeSelectorIsOpen}
                                style={{ marginLeft: 10 }}
                                toggle={() => this.setState({ crashTypeSelectorIsOpen: !this.state.crashTypeSelectorIsOpen })}
                            >
                                <DropdownToggle caret>{this.state.selectedCrashCourseTitle}</DropdownToggle>
                                <DropdownMenu>
                                    {
                                            [{ _id: null, name: "All" }, ...this.state.courseDetails].map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each._id}
                                                    onClick={() => this.setState({
                                                        crashTypeSelectorIsOpen: false,
                                                        selectedCrashCourseId: each._id,
                                                        selectedCrashCourseTitle: each.name,
                                                    })}
                                                >
                                                    {each.name}
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        }
                            <Col md="4">
                            <Input
                                style={{ marginRight: 10 }}
                                type="select"
                                name="post"
                                id="post"
                                value={this.state.salesmanId || ''}
                                onChange={(e) => {
                                    const value = e.target.value
                                    if (value !== "allSalesmen") {
                                        this.setState({ salesmanId: value })
                                    } else {
                                        this.setState({ salesmanId: null })
                                    }
                                }}
                            >
                                {[{ _id: "allSalesmen", name: "All Sales Agents" }, ...this.state.salesmen].map((each, index) => {
                                    return <option
                                        key={each._id}
                                        value={each._id}
                                    >
                                        {each.name}
                                    </option>
                                })
                                }
                            </Input>
                            </Col>
                            
                        <Button
                            outline
                            color="primary"
                            style={{ margin: 10 }}
                            onClick={() => this.getStats() }
                        >
                            GET
                        </Button>

                    </Row>

                    <Row style={{ marginTop: 20 }} >
                        <Col>
                            {
                                this.state.fromDate && this.state.toDate ?
                                    <p>Showing results from {new Date(this.state.fromDate).toLocaleDateString()} to {new Date(this.state.toDate).toLocaleDateString()}</p>
                                    : null
                            }
                            {
                                this.state.data && this.state.data.length > 0 ?
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Organic Premium</th>
                                                <th>Sales Premium</th>
                                                <th>Organic Silver</th>
                                                <th>Sales Silver</th>
                                                <th>Sales Gold</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map((each) => {
                                                    
                                                    totalOrganicPremium += (each.organicPremiumRevenue || 0)
                                                    totalOrganicPremiumCount += (each.organicPremiumCount || 0)

                                                    totalSalesPremium += (each.salesPremiumRevenue || 0)
                                                    totalSalesPremiumCount += (each.salesPremiumCount || 0)
                                                    totalOrganicSilver += (each.organicSilverRevenue || 0)
                                                    totalOrganicSilverCount += (each.organicSilverCount || 0)
                                                    totalSalesSilver += (each.salesSilverRevenue || 0)
                                                    totalSalesSilverCount += (each.salesSilverCount || 0)
                                                    totalGold += (each.goldRevenue || 0)
                                                    totalGoldCount += (each.goldCount || 0)

                                                    const { eachTotalRevenue, eachTotalCount } = getTotals(each)
                                                    totalRevenue += eachTotalRevenue
                                                    totalCount += eachTotalCount

                                                    return (
                                                        <tr key={each._id}>
                                                            <td>{each._id}</td>
                                                            <td>{`₹${commafy(each.organicPremiumRevenue || 0)} (${each.organicPremiumCount || 0})`}</td>
                                                            <td>{`₹${commafy(each.salesPremiumRevenue || 0)} (${each.salesPremiumCount || 0})`}</td>
                                                            <td>{`₹${commafy(each.organicSilverRevenue || 0)} (${each.organicSilverCount || 0})`}</td>
                                                            <td>{`₹${commafy(each.salesSilverRevenue || 0)} (${each.salesSilverCount || 0})`}</td>
                                                            <td>{`₹${commafy(each.goldRevenue || 0)} (${each.goldCount || 0})`}</td>
                                                            <td>{`₹${commafy(eachTotalRevenue)} (${eachTotalCount})`}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <th>Total</th>
                                                <th>{`₹${commafy(totalOrganicPremium)}`} ({totalOrganicPremiumCount})</th>
                                                <th>{`₹${commafy(totalSalesPremium)}`} ({totalSalesPremiumCount})</th>
                                                <th>{`₹${commafy(totalOrganicSilver)}`} ({totalOrganicSilverCount})</th>
                                                <th>{`₹${commafy(totalSalesSilver)}`} ({totalSalesSilverCount})</th>
                                                <th>{`₹${commafy(totalGold)}`} ({totalGoldCount})</th>
                                                <th>{`₹${commafy(totalRevenue)}`} ({totalCount})</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    :
                                    this.state.data && this.state.data.length === 0 ?
                                        <p style={{ textAlign: 'center' }}>No Data Available</p>
                                        :
                                        this.state.isLoading &&
                                        <p style={{ textAlign: 'center' }}>Loading income reports...</p>
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleIds: state.auth.roleIds,
        packs: state.data.packs,
        salesmen: state.data.salesmen
    }
}

export default connect(mapStateToProps)(SalesReport)