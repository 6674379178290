import React from 'react'
import TinyEditor from '../Components/TinyEditor'
import { Container, Button } from 'reactstrap'
import { copyToClipboard } from '../utils'
import { toast } from 'react-toastify'

class HtmlEditor extends React.Component {

    state = {
        html: ''
    }

    render() {
        return (
            <Container fluid>
                <TinyEditor
                    height={720}
                    onHtmlChange={(newHtml) => this.setState({ html: newHtml })}
                />

                <Button
                    color="primary"
                    style={{ marginTop: 15 }}
                    onClick={() => {
                        copyToClipboard(this.state.html)
                        toast("ID copied", {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                            })
                    }}
                >Copy HTML</Button>
            </Container>
        )
    }
}

export default HtmlEditor