import React from 'react'
import {
    Col, Row, Container, Table, Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, CustomInput
} from 'reactstrap';

import axios from 'axios'
import { toast } from 'react-toastify';
import { connect } from 'react-redux'
import { Checkbox } from 'material-ui'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { cloneDeep } from 'lodash';
import Axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faCopy, faSort } from '@fortawesome/free-solid-svg-icons'

import { isContentManagerOrHigher } from '../../utils/dashboardRoles';
import { copyIdsToClipboard, copyToClipboard } from '../../utils';
import Loader from '../../Components/Loader';

export const IsJsonString = (str) => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

const SortableModuleItem = SortableElement(({ roleIds, selectModule, eachModule, selectedModulesArray, editModuleContent, itemIndex, onPremiumToggle, onShowInGeneralToggle, editModule, onDelete, isSortingEnabled }) => {
    return (
        <tr
            key={eachModule._id}
            style={{ cursor: 'pointer', background: eachModule.activeFlag === false ? 'rgba(255,0,0,0.2)' : null }}
        >
            <td>{itemIndex + 1}</td>
            <td>
                <div style={{ display: 'flex' }}>
                    <Checkbox
                        onCheck={(e, value) => selectModule(eachModule._id, value)}
                        checked={selectedModulesArray.includes(eachModule._id)}
                    />
                </div>
            </td>

            <td width="60%">

                {eachModule.moduleTitle}<br />

                {eachModule.moduleDescription ? <span style={{ fontSize: 14 }}>{eachModule.moduleDescription}</span> : null}
                {eachModule.subjectName ? <span style={{ fontSize: 12 }}>{`${eachModule.subjectName} - ${eachModule.sectionName}`}</span> : null}
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    {eachModule.addedOn ?
                        <span style={{ fontSize: 12 }}>Added on {moment(eachModule.addedOn).format("D MMM YYYY, h:mm:ss a")}
                            {eachModule.addedBy ? ` by ${eachModule.addedBy} ` : null}
                        </span> : null}

                    {eachModule.lastEditedOn ?
                        <span style={{ fontSize: 12 }}>Edited on {moment(eachModule.lastEditedOn).format("D MMM YYYY, h:mm:ss a")}
                            {eachModule.lastEditedBy ? ` by ${eachModule.lastEditedBy} ` : null}
                        </span> : null}

                </div>

            </td>

            <td>{eachModule.content.moduleType}</td>

            {!isSortingEnabled ?
                <>
                    <td>
                        <Checkbox
                            style={{ marginTop: '10px' }}
                            checked={eachModule.showInGeneralTopic || (Array.isArray(eachModule.postsList) && eachModule.postsList.length === 0)}
                            onClick={() => onShowInGeneralToggle(eachModule._id, !eachModule.showInGeneralTopic)}
                        />
                    </td>

                    <td>
                        <Checkbox
                            style={{ marginTop: '10px' }}
                            checked={eachModule.premiumFlag}
                            onClick={() => onPremiumToggle(eachModule._id, !eachModule.premiumFlag)}
                        />
                    </td>

                    <td>
                        <Button
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                copyToClipboard(eachModule._id)
                                toast("ID copied", {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true
                                    })
                            }}
                            outline
                        >
                            <FontAwesomeIcon
                                // outline
                                className="iconButton"
                                style={{ color: 'grey' }}
                                icon={faCopy}
                            />
                        </Button>

                        <Button
                            color="primary"
                            outline
                            onClick={() => editModuleContent(eachModule._id)}
                        >Edit</Button>
                        
                        <Button style={{ marginLeft: 10, marginRight: 10 }} onClick={() => {
                            if (Array.isArray(eachModule.postsList)) {
                                editModule(eachModule, itemIndex)
                            } else {
                                editModule({ ...eachModule, postsList: [] }, itemIndex)
                            }
                        }} >
                            <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <Button outline color="danger" onClick={() => onDelete(eachModule._id)} >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </td>
                </>
                :
                <>
                    <td></td>
                    <td></td>
                    <td>
                        <FontAwesomeIcon
                            icon={faSort}
                            style={{ marginLeft: 10, color: 'grey' }}
                        />
                    </td>
                </>
            }
        </tr>
    )
})

const SortableModules = SortableContainer(({ roleIds, items, selectModule, selectedModulesArray, editModuleContent, onPremiumToggle, onShowInGeneralToggle, editModule, onDelete, disabled, isSortingEnabled }) => {
    return (
        <tbody>
            {items.map((module, index) => (
                <SortableModuleItem
                    roleIds={roleIds}
                    selectModule={selectModule}
                    allModules={items}
                    selectedModulesArray={selectedModulesArray}
                    disabled={!isSortingEnabled}
                    isSortingEnabled={isSortingEnabled}
                    key={module._id}
                    index={index}
                    itemIndex={index}
                    eachModule={module}
                    editModuleContent={editModuleContent}
                    onPremiumToggle={onPremiumToggle}
                    onShowInGeneralToggle={onShowInGeneralToggle}
                    editModule={editModule}
                    onDelete={onDelete}
                />
            ))
            }
        </tbody>
    )
})

class Learn extends React.Component {

    state = {
        isLoading: true,
        modules: [],
        selectedModuleIndex: 0,
        selectedSlideIndex: null,
        showAddNewModuleModal: false,
        showAddQuestionForm: false,
        newModuleTitle: "",
        newModuleType: 'slide',
        newModulePosts: [],
        sectionIndex: null,
        subjectIndex: null,
        relatedPosts: [],
        post: "selectPost",
        isSortingEnabled: false,
        selectedModulesArray: []
    }

    componentDidMount() {

        const { sectionId, userId } = this.props.match.params
        const search = this.props.location.search;
        const postId = new URLSearchParams(search).get('postId');

        if (sectionId || userId) {

            const setObject = { sectionIndex: -1, subjectIndex: -1 }

            this.props.subjects.find((subject, subjectIdx) => {
                const foundIndex = subject.sections.findIndex(section => section._id === sectionId)
                if (foundIndex >= 0) {
                    setObject.subjectIndex = subjectIdx
                    setObject.subjectId = subject._id
                    setObject.sectionIndex = foundIndex;
                    return true
                } else {
                    return false
                }
            })

            setObject.relatedPosts = this.props.posts.filter(each => {
                if (
                    isContentManagerOrHigher(this.props.roleIds) ||
                    (
                        Array.isArray(each.sections) &&
                        each.sections.includes(sectionId) &&
                        (Array.isArray(each.allowedMembers) && each.allowedMembers.includes(this.props.userId))
                    )
                ) {
                    return true
                } else {
                    return false
                }
            })

            if (postId) {
                setObject.post = postId
            } else if (!isContentManagerOrHigher(this.props.roleIds) && Array.isArray(setObject.relatedPosts) && setObject.relatedPosts.length > 0) {
                setObject.post = setObject.relatedPosts[0]._id
            }

            this.setState(setObject, () => this.getModules(setObject.post))

        } else {
            alert("Section ID missing in nav link")
        }

    }

    toggleModulePremium(moduleId, newFlag) {
        Axios.post('/learn/module/toggle-premium', { moduleId, newFlag })
            .then(res => this.getModules(this.state.post))
            .catch(err => {
                console.log(err)
                alert("Error, please check console")
            })
    }

    toggleShowInGeneral(moduleId, newFlag) {
        Axios.post('/learn/module/toggle-show-in-general', { moduleId, newFlag })
            .then(() => this.getModules(this.state.post))
            .catch(err => {
                console.log(err)
                alert("Error, please check console")
            })
    }

    deleteModule(moduleId) {
        axios.delete('/learn/module', { params: { moduleId } })
            .then(res => this.getModules(this.state.post))
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    onModulesSortEnd = ({ oldIndex, newIndex }) => {

        this.setState((oldState) => {
            let reorderedList = arrayMove(oldState.modules, oldIndex, newIndex)
            oldState.modules = reorderedList
            return oldState
        }, () => {

            let modules = cloneDeep(this.state.modules)
            let reorderedItem = { documentId: modules[newIndex]._id, newIndex }

            if (newIndex > oldIndex) {

                const data = modules.slice(oldIndex, newIndex).map((each) => each._id)
                const decrementByOneDocuments = data

                Axios.post('/learn/modules/reorder', { decrementByOneDocuments, reorderedItem })
                    .catch(err => {
                        console.log(err)
                        alert("Error, check console")
                    })

            } else if (newIndex < oldIndex) {

                const incrementByOneDocuments = modules.slice(newIndex + 1, oldIndex + 1).map((each) => each._id)

                Axios.post('/learn/modules/reorder', { incrementByOneDocuments, reorderedItem })
                    .catch(err => {
                        console.log(err)
                        alert("Error, check console")
                    })

            } else {
                //No change in array order
                return true
            }

        })

    };

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    getModules(selectedPostId) {

        this.setState({ isLoading: true })

        const { sectionId, userIdAndPeriod } = this.props.match.params

        let params = { selectedPostId }
        let mergeSubjectsAndSections = false

        if (sectionId !== 'null') {
            params.sectionId = sectionId
        } else {
            const { userId, period, editedUserId } = JSON.parse(userIdAndPeriod)
            params.userId = userId
            params.editedUserId = editedUserId
            params.period = period
            mergeSubjectsAndSections = true
        }

        axios.get('/learn/modules', { params })
            .then(res => {
                if (mergeSubjectsAndSections) {
                    const modules = res.data.modules.map(eachModule => {

                        let subjectIndex = -1
                        let sectionIndex = - 1

                        this.props.subjects.find((subject, subjectIdx) => {

                            const foundIndex = subject.sections.findIndex(section => section._id === eachModule.sectionId)

                            if (foundIndex >= 0) {
                                sectionIndex = foundIndex;
                                subjectIndex = subjectIdx
                                return true
                            } else {
                                return false
                            }

                        })

                        if (subjectIndex >= 0 && sectionIndex >= 0) {
                            const subjectName = this.props.subjects[subjectIndex].name
                            const sectionName = this.props.subjects[subjectIndex].sections[sectionIndex].name
                            return { ...eachModule, subjectName, sectionName }
                        } else {
                            return { ...eachModule }
                        }
                    })

                    this.setState({ modules, editingModuleData: false, showAddNewModuleModal: false, isLoading: false })

                } else {
                    this.setState({
                        modules: res.data.modules,
                        editingModuleData: false,
                        showAddNewModuleModal: false,
                        isLoading: false
                    })
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
                alert("Error, check console")
            })
    }

    addNewModule() {
        if (this.state.newModuleTitle && this.state.newModuleType.length > 0) {

            const { sectionId } = this.props.match.params

            const bodyObject = {
                sectionId,
                moduleTitle: this.state.newModuleTitle,
                moduleDescription: this.state.newModuleDescription,
                moduleType: this.state.newModuleType,
                postsList: this.state.newModulePosts,
                order: this.state.modules.length
            }

            axios.put('/learn/module', bodyObject)
                .then(() => {
                    this.getModules(this.state.post)
                    this.setState({
                        newModuleType: null,
                        newModuleTitle: null,
                        newModuleDescription: null,
                        newModulePosts: [],
                        showAddNewModuleModal: false
                    })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Check console")
                })

        } else {
            alert("Enter module details")
        }
    }

    updateModuleDetails() {

        const { _id, moduleTitle, moduleDescription, postsList } = this.state.editingModuleData

        axios.post('/learn/module', { moduleId: _id, moduleTitle, moduleDescription, postsList })
            .then(() => this.getModules(this.state.post))
            .catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    setQueryParams = (post) => {
        const { sectionId } = this.props.match.params

        this.props.history.push({
            pathname: `/learn/${sectionId}/null`,
            search: `postId=${post}`
        })
    }

    handleCheckBoxChange(postId) {

        this.setState((oldState) => {

            let foundIndex = oldState.editingModuleData.postsList.indexOf(postId)

            if (foundIndex > -1) {
                oldState.editingModuleData.postsList.splice(foundIndex, 1);
                return { editingModuleData: oldState.editingModuleData }
            } else {
                oldState.editingModuleData.postsList.push(postId)
                return { editingModuleData: oldState.editingModuleData }
            }

        })
    }

    selectModule = (moduleId, checkedValue) => {
        if (checkedValue) {
            this.setState(oldState => {
                const selectedModulesArray = [ ...oldState.selectedModulesArray, moduleId ]
                return { selectedModulesArray }
            })
        } else {
            this.setState(oldState => {
                const selectedModulesArray = oldState.selectedModulesArray.filter(each => each !== moduleId)
                return { selectedModulesArray }
            })
        }
    }

    checkAllModules = (e, checked) => {
        if (checked) {
            const allModuleIds = this.state.modules.map(each => each._id)
            this.setState({ selectedModulesArray: allModuleIds })
        } else {
            this.setState({ selectedModulesArray: [] })
        }
    }

    moveModules = () => {
        
        if (!window.confirm("Are you sure you want to move these modules?")) {
            return true
        }

        this.setState({ isLoading: true }, () => {
            axios.post('/learn/modules/move', {
                targetSectionId: this.state.targetSectionId,
                moduleIds: this.state.selectedModulesArray
            })
            .then(() => {
                this.setState({ showModuleMoveModal: false, targetSubjectId: null, targetSectionId: null })
                this.getModules(this.state.post)
                toast("Modules moved successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })
            })
            .catch(err => {
                console.log(err)
                alert("Server error. Check console")
            })
        })

    }

    renderModuleMoveModal() {
        return (
            <Modal
                fade={true}
                isOpen={this.state.showModuleMoveModal}
            >
                <ModalHeader>
                    Select Target Subject and Section
                </ModalHeader>

                <ModalBody>

                    <Row>

                        <Col md="12"  style={{ marginBottom: 20 }}>
                            <Label for="subjectId">Select Subject</Label>
                            <Input
                                type="select"
                                name={"subjectId"}
                                id={"subjectId"}
                                value={this.state.targetSubjectId}
                                onChange={(e) => {
                                    const value = e.target.value
                                    const foundSubject = this.props.subjects.find(e => e._id === value)
                                    if (foundSubject) {
                                        this.setState({ targetSubjectId: value, targetSectionId: 'none', sections: foundSubject.sections })
                                    } else {
                                        this.setState({ targetSubjectId: value, targetSectionId: 'none', sections: [] })
                                    }
                                }}
                            >
                                {
                                    this.props.subjects ?
                                        [
                                            { _id: "allSubjects", name: "All Subjects" }, ...this.props.subjects].map((subject, index) => (
                                                <option key={subject._id} value={subject._id}>{subject.name}</option>
                                            ))
                                        :
                                        null
                                }
                            </Input>
                        </Col>

                        <Col md="12">
                            <Label for="targetSectionId">Select Section</Label>
                            <Input
                                type="select"
                                name={"targetSectionId"}
                                id={"targetSectionId"}
                                value={this.state.targetSectionId}
                                onChange={(e) => {
                                    const value = e.target.value
                                    this.setState({ targetSectionId: value })
                                }}
                            >
                                {
                                    Array.isArray(this.state.sections) && this.state.sections.length > 0 ?
                                        [{ _id: "none", name: "Select Section" }, ...this.state.sections].map(section => {
                                            return (
                                                <option
                                                    key={section._id}
                                                    value={section._id}
                                                    disabled={this.props.match.params.sectionId === section._id}
                                                >
                                                    {section.name}
                                                </option>
                                            )
                                        })
                                        :
                                        [{ _id: "none", name: "Select Section" }].map(section => {
                                            return (
                                                <option key={section._id} value={section._id}>
                                                    {section.name}
                                                </option>
                                            )
                                        })
                                }
                            </Input>
                        </Col>

                    </Row>

                </ModalBody>

                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={this.moveModules}
                    >Move</Button>
                    <Button onClick={() => this.setState({ showModuleMoveModal: false })} outline>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderModuleEditingModal() {
        return (
            <Modal isOpen={this.state.editingModuleData != null} fade={true}>

                <ModalHeader>Edit Module Details</ModalHeader>

                <ModalBody>

                    <Form>
                        <FormGroup>
                            <Input
                                type="text"
                                name="moduleTitle"
                                placeholder="Enter Module Name"
                                value={this.state.editingModuleData.moduleTitle}
                                onChange={(event) => {
                                    let value = event.target.value
                                    this.setState((oldState) => {
                                        oldState.editingModuleData.moduleTitle = value
                                        return { editingModuleData: oldState.editingModuleData }
                                    })
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Input
                                type="text"
                                name="moduleDescription"
                                placeholder="Enter Module Description (Optional)"
                                value={this.state.editingModuleData.moduleDescription}
                                onChange={(event) => {
                                    let value = event.target.value
                                    this.setState((oldState) => {
                                        oldState.editingModuleData.moduleDescription = value
                                        return { editingModuleData: oldState.editingModuleData }
                                    })
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <div>
                                {
                                    Array.isArray(this.state.relatedPosts) && this.state.relatedPosts.map((each, index) => {
                                        return (
                                            <CustomInput
                                                id={each._id}
                                                key={each._id}
                                                value={each._id}
                                                type="checkbox"
                                                label={each.name}
                                                onChange={(event) => this.handleCheckBoxChange(each._id)}
                                                checked={this.state.editingModuleData.postsList.includes(each._id)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </FormGroup>

                    </Form>

                </ModalBody>

                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => this.updateModuleDetails()}
                    >Save</Button>
                    <Button onClick={() => this.setState({ editingModuleData: false })} outline>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        return (
            <>
                {this.state.isLoading && <Loader />}
                <Container fluid style={{ paddingTop: 50 }}>

                    {this.state.editingModuleData ? this.renderModuleEditingModal() : null}
                    {this.state.showModuleMoveModal ? this.renderModuleMoveModal() : null}

                    <Modal isOpen={this.state.showAddNewModuleModal}>

                        <ModalHeader>Add New Module</ModalHeader>

                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Input
                                        name="newModuleTitle"
                                        placeholder="Enter Module Title"
                                        value={this.state.newModuleTitle || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        name="newModuleDescription"
                                        placeholder="Enter Module Subtitle (Optional)"
                                        value={this.state.newModuleDescription || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="exampleSelect">Select Module Type</Label>
                                    <Input
                                        value={this.state.newModuleType || ''}
                                        name="newModuleType"
                                        onChange={this.handleInputChange}
                                        type="select"
                                        id="exampleSelect"
                                    >
                                        <option value={null}></option>
                                        <option value="slide">Slide</option>
                                        <option value="quiz">Quiz</option>
                                        <option value="video">Video</option>
                                        <option value="cards">Cards</option>
                                        <option value="caNotes">CA Notes</option>
                                        <option value="pdfNote">PDF Note</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <div>
                                        {
                                            Array.isArray(this.state.relatedPosts) && this.state.relatedPosts.map((each, index) => {
                                                return (
                                                    <CustomInput
                                                        id={each._id}
                                                        key={each._id}
                                                        value={each._id}
                                                        type="checkbox"
                                                        label={each.name}
                                                        onChange={(event) => {
                                                            this.setState((oldState) => {

                                                                let foundIndex = oldState.newModulePosts.indexOf(each._id)

                                                                if (foundIndex > -1) {
                                                                    oldState.newModulePosts.splice(foundIndex, 1);
                                                                    return { newModulePosts: oldState.newModulePosts }
                                                                } else {
                                                                    oldState.newModulePosts.push(each._id)
                                                                    return { newModulePosts: oldState.newModulePosts }
                                                                }

                                                            })

                                                        }}
                                                        checked={this.state.newModulePosts.includes(each._id)}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </FormGroup>

                                <Button color="primary" block onClick={() => this.addNewModule()}>Add New Module</Button>

                                <Button block onClick={() => this.setState({
                                    newModuleType: null,
                                    newModuleTitle: null,
                                    newModuleDescription: null,
                                    newModulePosts: [],
                                    showAddNewModuleModal: false
                                })}>
                                    Cancel
                                </Button>

                            </Form>
                        </ModalBody>

                    </Modal>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div style={{ display: "flex", flexDirection: 'column' }}>
                            {
                                (this.props.subjects && this.props.subjects[this.state.subjectIndex] && this.props.subjects[this.state.subjectIndex].sections[this.state.sectionIndex]) ?
                                    <>
                                        <h2>{this.props.subjects[this.state.subjectIndex].name}</h2>
                                        <br />
                                        <h4>{this.props.subjects[this.state.subjectIndex].sections[this.state.sectionIndex].name}</h4>
                                    </> : null
                            }

                            <Button
                                outline
                                color="primary"
                                style={{ alignSelf: 'flex-end' }}
                                onClick={ () => this.props.history.push({ pathname: `/textbook-questions/${this.props.match.params.sectionId}` }) }
                            >
                                Textbook Questions
                            </Button>

                        </div>

                        <div style={{ display: "flex", flexDirection: 'column' }}>
                            {
                                this.props.match.params.sectionId !== 'null' ?
                                    <Input
                                        type="select"
                                        name="post"
                                        id="post"
                                        value={this.state.post || ''}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            this.setState({ post: value }, () => {
                                                this.getModules(this.state.post)
                                                this.setQueryParams(value)
                                            })
                                        }}
                                        style={{ margin: 10 }}
                                    >
                                        {[
                                            { _id: "allModules", name: "All Modules" },
                                            { _id: "selectPost", name: "Practice Modules" },
                                            ...this.state.relatedPosts].map((each, index) => {
                                                return <option
                                                    key={each._id}
                                                    value={each._id}
                                                >
                                                    {each.name}
                                                </option>
                                            })
                                        }
                                    </Input>
                                    : null
                            }
                        </div>

                    </div>

                    <hr style={{ marginTop: 50, marginBottom: 50 }} />

                    {
                        this.state.modules && this.state.modules.length > 0 ?
                            <Row>
                                <Col md="12">
                                    <div style={{ display: "flex" }}>

                                        {
                                            this.props.match.params.sectionId !== 'null' ?
                                                <>
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({
                                                                showAddNewModuleModal: true,
                                                                newModulePosts: (this.state.post && this.state.post !== "allModules" && this.state.post !== "selectPost") ? [this.state.post] : []
                                                            })
                                                        }}
                                                        style={{ width: '20%', margin: 5 }}
                                                        color="primary"
                                                    >
                                                        ADD NEW MODULE
                                                    </Button>
                                
                                                    {
                                                        this.state.selectedModulesArray.length > 0 ?
                                                            <>
                                                                <Button
                                                                    color="primary"
                                                                    outline style={{ margin: 5 }}
                                                                    onClick={() => {
                                                                        copyIdsToClipboard(this.state.selectedModulesArray)
                                                                        toast("Module Ids copied", {
                                                                            position: toast.POSITION.TOP_CENTER,
                                                                            hideProgressBar: true
                                                                        })
                                                                    }}
                                                                >
                                                                    {`Copy ${this.state.selectedModulesArray.length} IDs`}
                                                                </Button>

                                                                <Button
                                                                    color="primary"
                                                                    outline style={{ margin: 5 }}
                                                                    onClick={() => this.setState({ showModuleMoveModal: true })}
                                                                >
                                                                    {`Move ${this.state.selectedModulesArray.length} Modules`}
                                                                </Button>
                                                            </>
                                                            : null
                                                    }

                                                    {
                                                        this.state.post === "selectPost" ?
                                                            <Button
                                                                style={{ margin: 5, marginLeft: "auto" }}
                                                                outline
                                                                color="success"
                                                                onClick={() => { this.setState({ isSortingEnabled: !this.state.isSortingEnabled }) }}
                                                            >
                                                                {this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                                                            </Button>
                                                            : null
                                                    }

                                                    
                                                </>
                                                : null
                                        }
                                    </div>

                                    <Table hover striped>

                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>
                                                    <Checkbox
                                                        onCheck={(e, value) => this.checkAllModules(e, value)}
                                                        checked={this.state.modules.length === this.state.selectedModulesArray.length}
                                                    />
                                                </th>
                                                <th>Modules</th>
                                                <th>Type</th>
                                                <th>Show in General</th>
                                                <th>Premium</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <SortableModules
                                            roleIds={this.props.roleIds}
                                            selectedModulesArray={this.state.selectedModulesArray}
                                            isSortingEnabled={this.state.isSortingEnabled}
                                            items={this.state.modules}
                                            onSortEnd={this.onModulesSortEnd}
                                            editModule={(data) => this.setState({ editingModuleData: cloneDeep(data) })}
                                            editModuleContent={(moduleId) => this.props.history.push({ pathname: `/module/${moduleId}` })}
                                            onPremiumToggle={(moduleId, newFlag) => this.toggleModulePremium(moduleId, newFlag)}
                                            onShowInGeneralToggle={(moduleId, newFlag) => this.toggleShowInGeneral(moduleId, newFlag)}
                                            onDelete={(moduleId) => window.confirm("Delete module?") && this.deleteModule(moduleId)}
                                            selectModule={ (moduleId, checkedValue) => this.selectModule(moduleId, checkedValue) }
                                        />

                                    </Table>

                                </Col>

                            </Row>
                            :
                            <Row>
                                <Col>
                                    <h3>No Module Added Yet</h3>
                                    {
                                        this.props.match.params.sectionId !== 'null' ?
                                            <Button
                                                onClick={() => this.setState({
                                                    showAddNewModuleModal: true,
                                                    newModulePosts: (this.state.post && this.state.post !== "allModules" && this.state.post !== "selectPost") ? [this.state.post] : []
                                                })}
                                                color="primary"
                                            >
                                                Add First Module
                                            </Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                    }
                </Container>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        roleIds: state.auth.roleIds,
        userId: state.auth._id,
        posts: state.data.posts,
        subjects: state.data.subjects,
        languages: state.data.languages,
    }
}

export default connect(mapStateToProps)(Learn)
