import React, { useState } from 'react'
import { Button, Container, Row, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import { unstable_batchedUpdates } from 'react-dom'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons'
import { copyToClipboard } from '../utils'

function ExplanationFeedBack({ explanationData, sendNotificationToUser, deleteFeedback }) {

    const [showUsers, setShowUsers] = useState(false)
    const [currentIndex, setCurrentIndex] = useState('')

    return (
        <Container fluid>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Question</th>
                            <th>Likes</th>
                            <th>Dislike</th>
                            <th>Feedback</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                            {
                                Array.isArray(explanationData) && explanationData.map((each, index) => {
                                    if (each.explanationRating) {
                                        return (
                                            <tr key={each._id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <p>{each.question}</p>
                                                    <p style={{color: 'grey'}}>{each.description}</p>
                                                </td>
                                                <td style={{ color: "green" }}>{each.explanationRating.likes ? each.explanationRating.likes : 0}</td>
                                                <td style={{ color: "red" }}>{each.explanationRating.dislikes ? each.explanationRating.dislikes : 0}</td>
                                                <td>
                                                    {
                                                        each.explanationRating.feedback ? each.explanationRating.feedback.map((item, itemIndex) => {
                                                            return (
                                                                <>
                                                                    <p style={{ marginBottom: 10 }}>
    
                                                                        <span key={itemIndex}> {item ? item.feedbackText : null}</span>
                                                                        <br></br>
                                                                        {
                                                                            showUsers && (currentIndex === index) ?
                                                                                <span style={{ marginBottom: 40, color: '#625F63' }}>{item ? item.userId : "nil"}</span>
                                                                                : null
                                                                        }
                                                                    </p>
                                                                </>
    
                                                            )
    
                                                        }) : null
                                                    }
                                                </td>
                                                <td>
    
                                                    <Button
                                                        onClick={() => {
                                                            copyToClipboard(each._id)
                                                            toast("Question ID copied", {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                hideProgressBar: true
                                                            })
                                                        }}
                                                        outline
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            style={{ color: 'grey' }}
                                                            icon={faCopy}
                                                        />
                                                    </Button>
    
                                                    <Button
                                                        outline
                                                        color='primary'
                                                        style={{ margin: 5 }}
                                                        onClick={() => {
                                                            unstable_batchedUpdates(() => {
                                                                setShowUsers(!showUsers)
                                                                setCurrentIndex(index)
                                                            })
                                                        }}
                                                    >
                                                        {showUsers && (currentIndex === index) ? "Hide Users" : "Show Users"}
                                                    </Button>
    
                                                    <Link
                                                        to={`/questions/edit?questionId=${each._id}`}
                                                        target="_blank"
                                                    >
                                                        <Button
                                                            style={{ margin: 5 }}
                                                            outline
                                                            color='primary'
                                                        >
                                                            Edit Explanation
                                                        </Button>
    
                                                    </Link>
                                                    <Button
                                                        style={{ margin: 5 }}
                                                        outline
                                                        color='success'
                                                        onClick={() => sendNotificationToUser(each._id)}
                                                    >
                                                        Corrected
                                                    </Button>
    
                                                    <Button
                                                        style={{ margin: 5 }}
                                                        outline
                                                        color='danger'
                                                        onClick={() => deleteFeedback(each._id)}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            style={{ color: 'red' }}
                                                            icon={faTrash} 
                                                        />
                                                    </Button>
                                                    
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                    </tbody>

                </Table>
            </Row>
        </Container>
    )
}

export default ExplanationFeedBack
