import { LOGIN, LOGOUT } from '../config/types'

export const login = (userData) => {
    return {
        type:LOGIN,
        payload:userData
    }
}

export const logout = () => {
    localStorage.removeItem('userData')
    return {
        type:LOGOUT
    }
}