// Auth Reducers
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const UPDATE_AUTH_DATA = 'update_auth_data'

//Question Data Reducer
export const UPDATE_SUBJECTS = 'update_subjects'
export const UPDATE_TAGS = 'update_tags'
export const UPDATE_POSTS = 'update_posts'
export const UPDATE_ADDED_QUESTIONS = 'update_added_questions'
export const UPDATE_LANGUAGES = 'update_languages'
export const UPDATE_PACKS = 'update_packs'
export const UPDATE_COURSE_GROUPS = 'update_course_groups'
export const UPDATE_SECTIONS_QUESTIONS_COUNT = 'updateSectionsQuestionsCount'
export const UPDATE_QUESTIONS = 'update_questions'
export const UPDATE_PATH_UNITS = 'update_path_units'

export const UPDATE_SALESMEN = 'update_salesmen'
export const UPDATE_TEACHERS_LIST = 'update_teachers_list'