import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import imageCompression from 'browser-image-compression';
import axios from 'axios'

function TinyEditor({ content, onHtmlChange, height, contentCss }) {

    const editorRef = useRef(null);

    function imageUploadHandler(blobInfo, success, failure, progress) {

        const reader = new window.FileReader();

        reader.onloadend = async () => {

            const formData = new FormData();

            const imageFile = blobInfo.blob()

            const options = {
                maxSizeMB: 0.25,
                maxWidthOrHeight: 1080,
                useWebWorker: true
            }

            const compressedFile = await imageCompression(imageFile, options);

            const compressedImageSizeInKB = compressedFile.size / 1024

            if (compressedImageSizeInKB > 250) {
                failure("Too large image. Please compress the image to less than 250 KB and upload again")
                return true
            }

            formData.append('myImage', compressedFile);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            axios.post("/upload-image", formData, config)
                .then(res => {
                    success(res.data.uploadLocation)
                })
                .catch(err => {
                    alert("Error while upload images, please check console")
                    console.log(err)
                    failure(err)
                })

        };
        reader.readAsDataURL(blobInfo.blob());

    };

    return (
        <Editor
            apiKey="3afxdmivvsr8v22gpval9ez10lvaexnn7wmw1x2urfxmt3t9"
            init={{
                height: height || 300,
                menubar: true,
                plugins: [
                    "advlist autolink lists link image imagetools charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste image code help wordcount",
                ],
                toolbar:
                    "undo redo | formatselect | " +
                    "bold italic underline forecolor backcolor | bullist numlist outdent indent | " +
                    "alignleft aligncenter alignright alignjustify | " +
                    "removeformat | help | code"
                ,
                content_css: contentCss,
                images_upload_handler: imageUploadHandler,
                imagetools_cors_hosts: ['challenger-images.sgp1.digitaloceanspaces.com'],
            }}
            value={content}
            onInit={(evt, editor) => {
                editorRef.current = editor

                editor.addShortcut('ctrl+shift+b', 'UnorderedList', function () {
                    editor.execCommand('InsertUnorderedList');
                });

                editor.addShortcut('ctrl+shift+i', 'Open image upload window', function () {
                    editor.execCommand('mceImage')

                    setTimeout(() => {
                        let element = document.querySelectorAll('.tox-dialog__body-nav-item')[1];
                        if (element) { element.click() }
                    }, 0)
                });

            }}
            onEditorChange={ (newHtml) => onHtmlChange(newHtml) }
        />
    );
}

export default TinyEditor