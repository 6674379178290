import React from 'react'
import { Container, Row ,Input, Col, Button, Table, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import axios from 'axios'
import Loader from '../Components/Loader'

const CONTENT_TYPE = "monthlyGoal"

const months = Array.from({length: 12}, (e, i) => {
    return new Date(null, i + 1, null).toLocaleDateString("en", { month: "long"});
})

const now = new Date().getUTCFullYear();    
const years = Array(now - (now - 20)).fill('').map((v, idx) => now + idx); 

class MonthlyGoal extends React.Component {
    
    state = {
        selectedMonth: 1,
        selectedYear: years[0],
        amount: 0,
        currentIndex: null,
        monthlyGoalDetails: [],
        showEditModal: false,
        isLoading: false
    }
    
    componentDidMount() {
        this.getMonthlyGoalDetails()
    }

    editMonthlyGoal(contentId) {
        axios.post('/misc/', {  contentType: CONTENT_TYPE, contentId, value: this.state.amount })
            .then(res => {
                this.getMonthlyGoalDetails()
                this.setState({ showEditModal: false })
            })
            .catch(err => {
                console.log(err)
                alert("Error. Please check console")
                this.setState({ showEditModal: false })
            })
    }

    getMonthlyGoalDetails () {
        this.setState({ isLoading: true }, () => {
            axios.get('/misc/', { params: { contentType: CONTENT_TYPE } })
                .then(res => {
                    this.setState({ monthlyGoalDetails: res.data.requiredContent.monthlyGoal, isLoading: false })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Please check console")
                    this.setState({ isLoading: false })
                })
        })
    }

    setMontlyGoal() {
        
        let month = ''
        if (this.state.selectedMonth < 10 ) {
            month = `0${this.state.selectedMonth}-${this.state.selectedYear}`
        } else {
            month = `${this.state.selectedMonth}-${this.state.selectedYear}`
        }
        
        let value = {
            month,
            amount: this.state.amount 
        }

        axios.put('/misc/', { value, contentType: CONTENT_TYPE })
            .then(res => {
                this.getMonthlyGoalDetails()
            })
            .catch(err => {
                console.log(err)
                if (err && err.response && err.response.status === 400) {
                    alert(err.response.data.message)
                }
            })
    }

    renderEditModal() {
        const { monthlyGoalDetails, currentIndex } = this.state
        return(
            <Modal
                fade={true}
                isOpen={this.state.showEditModal}
            >

            <ModalHeader>Edit Montly Goal</ModalHeader>

            <ModalBody>
            <Row style={{ marginTop: '1rem', alingItem: 'center' }}>
                    <Col>
                        <label><strong>Selected Month: </strong></label><br />
                        <label>{monthlyGoalDetails[currentIndex].month}</label>
                    </Col>
                    <Col>
                        <label><strong>Set Amount</strong></label>
                        <Input
                            type="number" name="amount" id="amount"
                            value={this.state.amount}
                            onChange={(e) => this.setState({amount: e.target.value})}
                        />
                    </Col>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button 
                    onClick={() => this.editMonthlyGoal(monthlyGoalDetails[currentIndex]._id)}
                    color="primary" 
                    outline
                >
                    Set Goal
                </Button>
                <Button onClick={() => this.setState({ showEditModal: false })} outline>Close</Button>
            </ModalFooter>

            </Modal>
        )
    }

    render() {

        return(
            <Container>
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }}/>}
                { this.state.showEditModal && this.renderEditModal() }
                <Row style={{ marginTop: '2rem', alingItem: 'center' }}>
                    <Col>
                        <label><strong>Select Month</strong></label>
                        <Input
                            type="select" name="selectedMonth" id="selectedMonth"
                            value={this.state.selectedMonth}
                            onChange={(e) => this.setState({selectedMonth: e.target.value})}
                        >
                            { months.map((item,index) => {
                                return (
                                    <option key={index} value={index + 1}>{item}</option>
                                )
                            })
                            }
                        </Input>
                    </Col>
                    <Col>
                        <label><strong>Select Year</strong></label>
                        <Input
                            type="select" name="selectedYear" id="selectedYear"
                            value={this.state.selectedYear}
                            onChange={(e) => this.setState({selectedYear: e.target.value})}
                        >
                            {years.map(item => {
                                return(
                                    <option key={item} value={item}>{item}</option>
                                )
                            })
                            }
                        </Input>
                    </Col>
                    <Col>
                        <label><strong>Set Amount</strong></label>
                        <Input
                            type="number" name="amount" id="amount"
                            value={this.state.amount}
                            onChange={(e) => this.setState({amount: e.target.value})}
                        />
                    </Col>
                    <Col style={{ marginTop: '2rem' }}>
                        <Button 
                            onClick={()=>this.setMontlyGoal()}
                            color="primary" 
                            outline
                        >
                            Set Goal
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    { this.state.monthlyGoalDetails && this.state.monthlyGoalDetails.length > 0 ?
                    <Table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.monthlyGoalDetails.map((item, index) => {
                                    return (
                                        <tr key={item._id}>
                                            <td>{index + 1}</td>
                                            <td>{item.month}</td>
                                            <td>{item.amount}</td>
                                            <td>
                                                <Button onClick={() => {
                                                    this.setState({
                                                        amount: item.amount,
                                                        currentIndex: index,
                                                        showEditModal: true
                                                    })
                                                }}>
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    :
                    Array.isArray(this.state.monthlyGoalDetails) && this.state.monthlyGoalDetails.length === 0 ?
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <h4 style={{ textAlign: 'center', marginTop: 50 }}>No monthly goal details added</h4>
                    </div>
                    : null
                    }
                </Row>
            </Container>
        )
    }
}

export default MonthlyGoal 