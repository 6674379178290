import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button, Input, FormGroup, Form, Label, Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap'
import { unstable_batchedUpdates } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckBox from 'material-ui/Checkbox';
import { faPen, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Spinner from 'reactstrap/lib/Spinner';
import { isEmpty } from 'lodash'

import Loader from '../Components/Loader'
import { copyToClipboard } from '../utils';
import { toast } from 'react-toastify'

const CONTENT_TYPE = "premiumValues"

function PremiumPricing() {
    const scrollIntoView = useRef(null);
    const [isLoading, setIsLoading] = useState(true)
    const [enableEditMode, setEnableEditMode] = useState(false)
    const [premiumValuesData, setPremiumValuesData] = useState({})
    const [featureData, setFeatureData] = useState({})
    const [multipleValidityPlan, setMultipleValidityPlan] = useState({})
    const [editIndex, setEditIndex] = useState(-1)

    useEffect(() => {
        fetchPremiumValueData()
    }, [])

    const fetchPremiumValueData = () => {
        axios.get('/misc/', { params: { contentType: CONTENT_TYPE } })
            .then(res => {
                unstable_batchedUpdates(() => {
                    if (res.data.requiredContent) {
                        setPremiumValuesData(res.data.requiredContent)
                    }
                    setIsLoading(false)
                })
            })
            .catch(err => {
                console.log(err)
                alert("Error, Check console")
                setIsLoading(false)
            })
    }

    const validateRequiredFields = (value) => {
        
        let validate = true

        if (!premiumValuesData) {
            return false
        }

        const emptyFields = []
        Object.keys(value).forEach(each => {
            if (!value[`${each}`] || (value[`${each}`] && `${value[`${each}`]}`.trim().length === 0)) {
                emptyFields.push(each)
                validate = false
            }
        })

        return validate
    }

    function validateValidityPlansData(validityPlans) {
        let foundIndex = validityPlans.findIndex(e => e.primary)
        if (foundIndex > -1) {
            return true
        }
    }

    const submitData = () => {

        let value = {
            purchaseModalTitle: premiumValuesData.purchaseModalTitle,
            purchaseModalSubtitle: premiumValuesData.purchaseModalSubtitle,
            minGamesToShowIM: premiumValuesData.minGamesToShowIM,
            iapButtonText: premiumValuesData.iapButtonText,
            actualPrice: premiumValuesData.actualPrice,
            discountPrice: premiumValuesData.discountPrice,
            razorpayAmount: premiumValuesData.discountPrice * 100,
            coins: premiumValuesData.coins,
            validity: premiumValuesData.validity,
            extenstionAmount: premiumValuesData.extenstionAmount,
            extenstionRzpAmount: premiumValuesData.extenstionAmount * 100,
        }

        if (!validateRequiredFields(value)) {
            alert("Please fill all the fields")
            return
        }


        // Find invalid items
        const foundInvalidItem = premiumValuesData.validityPlans.filter(each => {
            if (
                !each.validity ||
                !each.planNumOfDays ||
                !each.actualPrice ||
                !each.discountPrice ||
                !each.razorpayAmount ||
                !each.storeSku
            ) {
                return true
            } else {
                return false
            }
        })

        if (foundInvalidItem.length) {
            alert("Invalid multiple pricing item found")
            return true
        }

        if (!validateValidityPlansData(premiumValuesData.validityPlans)) {
            alert("Please select one primary plan")
            return
        }

        value.offerText = premiumValuesData.offerText
        value.features = premiumValuesData.features
        value.validityPlans = premiumValuesData.validityPlans

        setIsLoading(true)

        axios.put('/misc/', { value, contentType: CONTENT_TYPE })
            .then(res => {
                fetchPremiumValueData()
                setIsLoading(false)
                scrollIntoView.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
            })
            .catch(err => {
                console.log(err)
                alert("Error, Check console")
                if (err && err.response && err.response.status === 400) {
                    alert(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    const setData = (name, value) => {
        setPremiumValuesData({ ...premiumValuesData, [name]: value })
    }

    function validateMultipleValidityPlan(multipleValidityPlanKeys) {
        let foundIndex = multipleValidityPlanKeys.findIndex(each => !`${multipleValidityPlan[each]}`.trim())
        if (foundIndex > -1 || multipleValidityPlanKeys.length < 6) {
            return true
        }
    }

    const addMultipleValidityPlans = () => {

        if (!multipleValidityPlan.primary) {
            multipleValidityPlan.primary = false
        }

        if (!multipleValidityPlan.razorpayAmount) {
            multipleValidityPlan.razorpayAmount = multipleValidityPlan.discountPrice * 100
        }

        let isPlanDataValid = validateMultipleValidityPlan(Object.keys(multipleValidityPlan))

        if (isPlanDataValid) {
            alert("Please, Add all multiple plan data")
            return true
        }

        let validityPlans = []
        if (Array.isArray(premiumValuesData.validityPlans)) {
            validityPlans = premiumValuesData.validityPlans
        }

        if (editIndex > -1) {
            validityPlans[editIndex] = multipleValidityPlan
        } else {
            validityPlans.push(multipleValidityPlan)
        }

        unstable_batchedUpdates(() => {
            setPremiumValuesData({ ...premiumValuesData, validityPlans: validityPlans })
            setMultipleValidityPlan({})
            setEditIndex(-1)
        })

    }

    const addFeature = () => {

        if (isEmpty(featureData)) {
            alert("Please, Features title and description")
            return
        }

        let features = []
        if (Array.isArray(premiumValuesData.features)) {
            features = premiumValuesData.features
        }
        if (editIndex > -1) {
            features[editIndex] = featureData
        } else {
            features.push(featureData)
        }

        let featuresWithKey = features.map((item, index) => {
            item.key = `${index + 1}`
            return item
        })

        unstable_batchedUpdates(() => {
            setPremiumValuesData({ ...premiumValuesData, features: featuresWithKey })
            setFeatureData({})
            setEditIndex(-1)
        })
    }

    const removeFeature = (index, state) => {
        const currentPremiumValuesData = { ...premiumValuesData }
        currentPremiumValuesData[`${state}`].splice(index, 1)
        setPremiumValuesData(currentPremiumValuesData)
    }

    return (
        <>

            {isLoading && <Loader />}

            <Container style={{ paddingTop: 30 }} >

                <div style={{ display: "flex", justifyContent: "center" }} ref={scrollIntoView}>
                    <h1>Premium Values</h1>
                </div>

                <Row style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
                    <Card>
                        <CardBody>
                            <Form style={{ width: '100%' }}>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label id="purchaseModalTitle">Purchase Modal Title</Label>
                                            <Input
                                                type='text'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"purchase modal title"}
                                                value={premiumValuesData.purchaseModalTitle || ''}
                                                name={"purchaseModalTitle"}
                                                id={"purchaseModalTitle"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label id="purchaseModalSubTitle">Purchase Modal SubTitle</Label>
                                            <Input
                                                type='text'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"purchase modal subtitle"}
                                                value={premiumValuesData.purchaseModalSubtitle || ''}
                                                name={"purchaseModalSubtitle"}
                                                id={"purchaseModalSubtitle"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label id="minGamesToShowIM">Minimum Games To Show IM</Label>
                                            <Input
                                                type='number'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"minGamesToShowIM"}
                                                value={premiumValuesData.minGamesToShowIM || ''}
                                                name={"minGamesToShowIM"}
                                                id={"minGamesToShowIM"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label id="iapButtonText">IAP Buttton Text</Label>
                                            <Input
                                                type='text'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Button Text"}
                                                value={premiumValuesData.iapButtonText || ''}
                                                name={"iapButtonText"}
                                                id={"iapButtonText"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label id="offerText">Offer Text</Label>
                                            <Input
                                                type='text'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Offer Text"}
                                                value={premiumValuesData.offerText || ''}
                                                name={"offerText"}
                                                id={"offerText"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label id="coins">Coins</Label>
                                            <Input
                                                type='number'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Coins"}
                                                value={premiumValuesData.coins || ''}
                                                name={"coins"}
                                                id={"coins"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label id="actualPricing">Actual Price(MRP)</Label>
                                            <Input
                                                type='number'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Enter MRP price"}
                                                value={premiumValuesData.actualPrice || ''}
                                                name={"actualPrice"}
                                                id={"actualPrice"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label id="discountPricing">Discount price</Label>
                                            <Input
                                                type='number'
                                                disabled={!enableEditMode}
                                                onBlur={(e) => setData("razorpayAmount", e.target.value * 100)}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Enter discount price"}
                                                value={premiumValuesData.discountPrice || ''}
                                                name={"discountPrice"}
                                                id={"discountPrice"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label id="discountPricing">Razorpay Amount</Label>
                                            <Input
                                                type='number'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"RazorPay Amount"}
                                                value={premiumValuesData.razorpayAmount || ''}
                                                name={"razorpayAmount"}
                                                id={"razorpayAmount"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label id="validity">Validity</Label>
                                            <Input
                                                type='text'
                                                disabled={!enableEditMode}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Validity"}
                                                value={premiumValuesData.validity || ''}
                                                name={"validity"}
                                                id={"validity"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label id="extenstionAmount">Extension Amount</Label>
                                            <Input
                                                type='number'
                                                disabled={!enableEditMode}
                                                onBlur={(e) => setData("extenstionRzpAmount", e.target.value * 100)}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Extention Amount"}
                                                value={premiumValuesData.extenstionAmount || ''}
                                                name={"extenstionAmount"}
                                                id={"extenstionAmount"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label id="extenstionRzpAmount">Extension Razorpay Amount</Label>
                                            <Input
                                                type='number'
                                                disabled={true}
                                                onChange={(e) => setData(e.target.name, e.target.value)}
                                                placeholder={"Extention RazorPay Amount"}
                                                value={premiumValuesData.extenstionRzpAmount || ''}
                                                name={"extenstionRzpAmount"}
                                                id={"extenstionRzpAmount"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <div style={{ backgroundColor: '#F8F8F8', padding: 10, borderRadius: 5 }}>

                                    <Label>Mutliple Price</Label>

                                    <Row>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label id="primary">Primary</Label>
                                                <CheckBox
                                                    style={{ marginTop: '5px' }}
                                                    disabled={!enableEditMode}
                                                    checked={multipleValidityPlan.primary || false}
                                                    onClick={() => {
                                                        if (premiumValuesData.validityPlans) {
                                                            premiumValuesData.validityPlans.forEach(each => {
                                                                if (each.primary) { each.primary = !each.primary }
                                                            })
                                                        }
                                                        setMultipleValidityPlan({ ...multipleValidityPlan, primary: !multipleValidityPlan.primary })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label id="validity">Validity</Label>
                                                <Input
                                                    type='text'
                                                    disabled={!enableEditMode}
                                                    onChange={(e) => { setMultipleValidityPlan({ ...multipleValidityPlan, validity: e.target.value }) }}
                                                    placeholder={"Validity"}
                                                    value={multipleValidityPlan.validity || ''}
                                                    name={"validity"}
                                                    id={"validity"}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label id="planNumOfDays">Days</Label>
                                                <Input
                                                    type='number'
                                                    disabled={!enableEditMode}
                                                    onChange={(e) => { setMultipleValidityPlan({ ...multipleValidityPlan, planNumOfDays: e.target.value }) }}
                                                    placeholder={"days"}
                                                    value={multipleValidityPlan.planNumOfDays || ''}
                                                    name={"planNumOfDays"}
                                                    id={"planNumOfDays"}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label id="actualPricing">Actual Price(MRP)</Label>
                                                <Input
                                                    type='number'
                                                    disabled={!enableEditMode}
                                                    onChange={(e) => { setMultipleValidityPlan({ ...multipleValidityPlan, actualPrice: e.target.value }) }}
                                                    placeholder={"Enter MRP price"}
                                                    value={multipleValidityPlan.actualPrice || ''}
                                                    name={"actualPrice"}
                                                    id={"actualPrice"}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label id="discountPricing">Discount price</Label>
                                                <Input
                                                    type='number'
                                                    disabled={!enableEditMode}
                                                    onBlur={(e) => setMultipleValidityPlan({ ...multipleValidityPlan, razorpayAmount: e.target.value * 100 })}
                                                    onChange={(e) => { setMultipleValidityPlan({ ...multipleValidityPlan, discountPrice: e.target.value }) }}
                                                    placeholder={"Enter discount price"}
                                                    value={multipleValidityPlan.discountPrice || ''}
                                                    name={"discountPrice"}
                                                    id={"discountPrice"}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label id="storeSku">Store SKU</Label>
                                                <Input
                                                    type='text'
                                                    disabled={!enableEditMode}
                                                    onChange={(e) => { setMultipleValidityPlan({ ...multipleValidityPlan, storeSku: e.target.value }) }}
                                                    placeholder={"Enter Store SKU"}
                                                    value={multipleValidityPlan.storeSku || ''}
                                                    name={"storeSku"}
                                                    id={"storeSku"}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='1'>
                                            <FormGroup>
                                                <Button
                                                    disabled={!enableEditMode}
                                                    style={{ marginTop: 30 }}
                                                    color="success"
                                                    onClick={() => addMultipleValidityPlans()}
                                                >
                                                    +
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {
                                        premiumValuesData && Array.isArray(premiumValuesData.validityPlans) && premiumValuesData.validityPlans.length > 0 &&
                                        premiumValuesData.validityPlans.map((plan, index) => {
                                            return (
                                                <div
                                                    key={plan._id}
                                                    style={{ display: 'flex', borderRadius: 10, backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', padding: 7, marginTop: 5 }}
                                                >
                                                    <CheckBox
                                                        style={{ flex: '1' }}
                                                        disabled={true}
                                                        checked={plan.primary}
                                                    />
                                                    <h6 style={{ fontSize: 17, flex: '2', marginLeft: 5 }}>{plan.validity}</h6>
                                                    <h6 style={{ fontSize: 17, flex: '2', marginLeft: 5 }}>{plan.planNumOfDays}</h6>
                                                    <h6 style={{ fontSize: 17, flex: '1' }}>{plan.actualPrice}</h6>
                                                    <h6 style={{ fontSize: 17, flex: '1' }}>{plan.discountPrice}</h6>
                                                    <h6 style={{ fontSize: 17, flex: '1', marginRight: 10 }}>{plan.storeSku}</h6>
                                                    <>
                                                        <Button
                                                            outline
                                                            color="secondary"
                                                            style={{ marginRight: 7 }}
                                                            onClick={() => {
                                                                copyToClipboard(plan._id)
                                                                toast("ID copied", {
                                                                    position: toast.POSITION.TOP_CENTER,
                                                                    hideProgressBar: true
                                                                    })
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faCopy} />
                                                        </Button>
                                                        <Button
                                                            style={{ marginRight: 10 }}
                                                            outline
                                                            disabled={!enableEditMode}
                                                            color="primary"
                                                            onClick={() => {
                                                                const currentValidityPlan = {}
                                                                currentValidityPlan.validity = plan.validity
                                                                currentValidityPlan.primary = plan.primary
                                                                currentValidityPlan.planNumOfDays = plan.planNumOfDays
                                                                currentValidityPlan.actualPrice = plan.actualPrice
                                                                currentValidityPlan.discountPrice = plan.discountPrice
                                                                currentValidityPlan.storeSku = plan.storeSku
                                                                setMultipleValidityPlan(currentValidityPlan)
                                                                setEditIndex(index)
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                icon={faPen}
                                                            />
                                                        </Button>
                                                        <Button
                                                            style={{ marginRight: 10 }}
                                                            outline
                                                            color="danger"
                                                            disabled={!enableEditMode}
                                                            onClick={() => {
                                                                (window.confirm(`Are you sure you want remove this plan?`)) &&
                                                                    removeFeature(index, 'validityPlans')
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                icon={faTrash}
                                                            />
                                                        </Button>
                                                    </>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                <Label style={{ fontSize: 17, marginTop: 30 }}>Add Features</Label>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col>
                                        <Input
                                            placeholder="Enter Feature Title"
                                            disabled={!enableEditMode}
                                            type="text"
                                            name="title"
                                            value={featureData.title || ''}
                                            onChange={(e) => { setFeatureData({ ...featureData, title: e.target.value }) }}
                                        />
                                        <Input
                                            placeholder="Enter Feature Description"
                                            disabled={!enableEditMode}
                                            type="text"
                                            name="description"
                                            value={featureData.description || ''}
                                            onChange={(e) => { setFeatureData({ ...featureData, description: e.target.value }) }}
                                        />

                                        <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 20, marginTop: 5 }}>
                                            <Button
                                                style={{ width: '5%' }}
                                                color='success'
                                                disabled={!enableEditMode}
                                                onClick={() => addFeature()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                <ListGroup>
                                    {premiumValuesData && premiumValuesData.features && premiumValuesData.features.length > 0 && <Label>Features List</Label>}
                                    {premiumValuesData && premiumValuesData.features && premiumValuesData.features.map((item, index) => {
                                        return (
                                            <ListGroupItem key={item.key}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                                                    <div style={{ maxWidth: '75%' }}>
                                                        <p>Title: {item.title}</p>
                                                        <p>Description: {item.description}</p>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ marginRight: 10 }} outline
                                                            color="primary"
                                                            disabled={!enableEditMode}
                                                            onClick={() => {
                                                                const currentFeatureData = { ...featureData }
                                                                currentFeatureData.title = item.title
                                                                currentFeatureData.description = item.description
                                                                setFeatureData(currentFeatureData)
                                                                setEditIndex(index)
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                icon={faPen}
                                                            />
                                                        </Button>
                                                        <Button
                                                            style={{ marginRight: 10 }}
                                                            outline
                                                            disabled={!enableEditMode}
                                                            color="danger"
                                                            onClick={() => {
                                                                (window.confirm(`Are you sure you want remove this feature?`)) &&
                                                                    removeFeature(index, 'features')
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                icon={faTrash}
                                                            />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        )
                                    })
                                    }
                                </ListGroup>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <Button
                                        color="success"
                                        outline
                                        onClick={() => setEnableEditMode(!enableEditMode)}
                                        style={{ width: '20%', marginTop: 10, marginRight: 10 }}
                                    >
                                        {enableEditMode ? 'Disable Edit Mode' : 'Enable Edit Mode'}
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                        disabled={!enableEditMode}
                                        onClick={submitData}
                                        style={{ width: '30%', marginTop: 10 }}
                                    >
                                        {isLoading ? <Spinner size="sm" /> : 'Submit'}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Row>

            </Container>
        </>
    )
}

export default PremiumPricing