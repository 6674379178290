import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import queryString from 'query-string';

import { DatePicker, SelectField, MenuItem, TextField, RaisedButton, Dialog, FlatButton, CircularProgress, Checkbox } from 'material-ui';
import { updateQuestions } from '../../actions'
import {  Button, Container, Row, Col, Form, Badge } from 'reactstrap'
import { QuestionsTable } from '../../Components/QuestionsTable'
import { addExtraDetailsWithQuestions, dropDownPeriods } from '../../utils';
import { cloneDeep, sortBy } from 'lodash';
import { questionTypes } from '../../Components/AddForm'
import { toast } from 'react-toastify';


const languageTree = [
    {
        _id: "1",
        name: "All"
    },
    {
        _id: "5b0326f395409b0f800bd784",
        name: "English"
    },
    {
        _id: "5b0326f395409b0f800bd785",
        name: "Malayalam"
    }
]

class ViewQuestions extends Component {

    constructor() {
        super()
        this.getQuestions = this.getQuestions.bind(this)
    }

    state = { 
        isLoading:false,
        deleteDialog:false,
        questionId:null,
        // questionStatus:"all",
        questionType: "all",
        showInChallenge:"all",
        period:"allTime",
        skipCount:0,
        subjectId:"1",
        deletedStatus:'undeleted',
        premiumQuestion:'all',
        explanationFilter:'all',
        addedBy:null,
        languageId: '1',
        questionIdsToCopyArr: []
    }

    
    handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value })
    }

    fetchNonExamQuestions = (e, value) => {
        this.setState({ [e.target.name]: value })
    }

    setStatesAndGetQuestions(params) {

        const {
            subjectIndex,
            sectionId,
            addedBy,
            dailyChallengeId,
            statementQuizId,
            skipCount,
            searchText,
            currentAffairsPeriod,
            examId,
            editedBy,
            topicId,
            unitId,
            period,
            quizModuleId,
            type
        } = params
    
        let setObject = {}
        
        if (type) {
            setObject.subjectId = "5c13eff60c59ab1db896ff9a"
            setObject.sectionId = "5c13f0130c59ab1db896ff9d"
        }
        if (subjectIndex) {
            setObject.subjectId = this.props.subjects[subjectIndex+1]._id
            setObject.sections = this.props.subjects[subjectIndex+1].sections
        }

        if (sectionId) { setObject.sectionId = sectionId }
        if (addedBy) { setObject.addedBy = addedBy }
        if (skipCount) { setObject.skipCount = parseInt(skipCount) / 50 }
        if (searchText) { setObject.searchText = searchText }
        if (editedBy) { setObject.editedBy = editedBy }
        if (dailyChallengeId) { 
            setObject.dailyChallengeId = dailyChallengeId 
            setObject.currentAffairsPeriod = currentAffairsPeriod 
        }
        if (statementQuizId) {
            setObject.statementQuizId = statementQuizId 
        }
        if (examId) { setObject.examId = examId }
        if(period) { setObject.period = period }

        if (unitId && topicId) {
            setObject.unitId = unitId
            setObject.topicId = topicId
        }

        if (quizModuleId) {
            setObject.quizModuleId = quizModuleId
        }

        this.setState(setObject, this.getQuestions)
    }

    async componentDidMount() {
        if (this.props.location && this.props.location.state ) {
            this.setStatesAndGetQuestions(this.props.location.state)
        } else if (this.props.location && this.props.location.search) {
            this.setStatesAndGetQuestions(queryString.parse(this.props.location.search))
        } else if (this.props.questionsData && this.props.questionsData.length > 0) {
            let questions = addExtraDetailsWithQuestions(this.props.questionsData.questions)
            this.props.updateQuestions({ questions, count: this.props.questionsData.questions.length });
        }
    }

    getQuestions(event) {

        if (event) {
            event.preventDefault()
        }

        if(this.state.subjectId) {
            
            this.setState({ isLoading:true })

            const { subjectId, quizModuleId, questionType, statementQuizId, deletedStatus, sectionId, skipCount, searchText, tagId, premiumQuestion, addedBy, dailyChallengeId, currentAffairsPeriod, examId, showInChallenge, editedBy, unitId, topicId, questionId, languageId, fetchNonExamQuestions, explanationFilter } = this.state
            let { period } = this.state

            let requestData = {
                count:50,
                subjectId:subjectId,
                // questionStatus:questionStatus,
                activeFlag:deletedStatus,
                showInChallenge,
                languageId
            }

            if(explanationFilter) { requestData.explanationFilter = explanationFilter }
            if(sectionId) { requestData.sectionId = sectionId }
            if(skipCount) { requestData.skipCount = parseInt(skipCount, 10)*50 }
            if(searchText) { requestData.searchText = searchText }
            if(tagId) { requestData.tagId = tagId }
            if(premiumQuestion !== 'all') { requestData.premiumQuestion = premiumQuestion }
            if(questionType !== 'all') { requestData.questionType = questionType }
            if(addedBy) { requestData.addedBy = addedBy }
            if(editedBy) { requestData.lastEditedBy = editedBy }
            if(dailyChallengeId) { 
                requestData.dailyChallengeId = dailyChallengeId 
                requestData.currentAffairsPeriod = currentAffairsPeriod 
            }
            if (statementQuizId) { requestData.statementQuizId = statementQuizId }
            if(examId) {
                requestData.examId = examId; requestData.count = 150
                requestData.activeFlag = null
            }
            if (unitId && topicId) {
                requestData.unitId = unitId
                requestData.topicId = topicId
            }
            if (questionId && questionId.trim().length > 0) { requestData.questionId = questionId.trim() }
            
            if (period === 'customTime') {
                
                if (!this.state.fromDate) {
                    this.setState({ isLoading: false })
                    return alert("Please select from date")
                } else if (!this.state.toDate) {
                    this.setState({ isLoading: false })
                    return alert("Please select to date")
                }

                requestData.period = {
                    fromDate: this.state.fromDate, 
                    toDate: this.state.toDate
                }
            } else if (period) {
                requestData.period = period
            }

            if(quizModuleId) { requestData.quizModuleId = quizModuleId }
            if(fetchNonExamQuestions) {requestData.fetchNonExamQuestions = true}
            
            axios.get(`/get-questions`, { params: requestData })
            .then((response) => {
                let questions = response.data.data.questions
                // function to add seciton details, exam name, path name along with questions
                questions = addExtraDetailsWithQuestions(questions)
                this.props.updateQuestions({ questions, count:response.data.data.count });
                this.setState({ isLoading:false, questionIdsToCopyArr: [] })
            })
            .catch((error) => { 
                if (error.response) {
                    alert(error.response.data.message)
                    this.setState({ isLoading: false })
                } else {
                    alert("Error while processing data")
                    console.log(error)
                }
            })
        } else {
            this.setState({ error:true, errorMessage:"Select a Subject" })
        }
    }

    renderPagination() {
        let returnArray = []
        let pagesCount = parseInt(this.props.questionsData.count, 10)/50
        for (let index = 0; index < pagesCount; index++) {
            returnArray.push(<MenuItem key={index} value={index} primaryText={index+1} />)
        }
        return returnArray
    }

    addQuestionIdsToCopyArr(questionId, checkedValue) {
        if (checkedValue) {
            this.setState(oldState => {
                oldState.questionIdsToCopyArr.push(questionId)
                return { questionIdsToCopyArr: oldState.questionIdsToCopyArr }
            })        
        } else {
            this.setState(oldState => {
                let filterIds = oldState.questionIdsToCopyArr.filter(item => item !== questionId)
                return { questionIdsToCopyArr: filterIds }
            }) 
        }
    }

    copyIdsToClipboard() {
        
        let string = ''

        this.state.questionIdsToCopyArr.forEach(item => {
            if (string) {
                string = `${string}\n${item}`
            } else {
                string = item
            }
        })

        var textField = document.createElement('textarea')
        textField.innerHTML = string
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        toast("Module Ids copied", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        })

    }

    deleteQuestionApi() {
        axios.post(`/delete-question`, { _id:this.state.deleteItemId })
        .then((response) => {
            this.setState({ isLoading:false, deleteDialog:false, deleteItemId:'' })
            const questions = ([]).concat(this.props.questionsData.questions)
            questions[this.state.deleteIndex].activeFlag = false
            this.props.updateQuestions({ questions })
        })
        .catch((error) => { console.log(error) })
    }
    
    checkAllQuestions=(e,value)=>{
        if (value){
                let questions = cloneDeep(this.props.questionsData.questions)
                questions.forEach(each => {
                    if (!this.state.questionIdsToCopyArr.includes(each._id)) {
                        this.setState(oldState =>{
                            oldState.questionIdsToCopyArr.push(each._id)
                            return { questionIdsToCopyArr: oldState.questionIdsToCopyArr }
                        })
                    }
                })
        } else {
            this.setState({ questionIdsToCopyArr: [] })
        }
    }

    render() {

        const deleteActions = [
            <FlatButton
                label="Yes"
                primary={true}
                onClick={this.deleteQuestionApi.bind(this)}
            />,
            <FlatButton
                label="No"
                secondary={true}
                onClick={ () => this.setState({ deleteDialog:false }) }
            />,
        ];

        return (
            <Container fluid style={{padding:0}}>

                <Dialog
                    title="Confirm Delete"
                    actions={deleteActions}
                    modal={true}
                    open={this.state.deleteDialog}
                >
                    Are you sure you want to delete this question?
            </Dialog>

                <Row style={{ marginTop: 50 }}>
                    <Container>
                        <Form onSubmit={this.getQuestions}>

                            <Col sm={12}>
                                <TextField
                                    type="text"
                                    name="searchText"
                                    value={this.state.searchText || ''}
                                    hintText="Enter Search Text (Optional)"
                                    onChange={this.handleInput}
                                    fullWidth={true}
                                />
                            </Col>
                            <Col>
                                <TextField
                                    type="text"
                                    name="questionId"
                                    value={this.state.questionId || ''}
                                    hintText="Enter Question Id (Optional)"
                                    onChange={this.handleInput}
                                />
                            </Col>

                            <Col>
                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.subjectId}
                                    floatingLabelText="Subject"
                                    onChange={(event, index, subjectId) => this.setState({ subjectId, sectionId: null, error: false })}
                                >
                                    {
                                        this.props.subjects ?
                                            this.props.subjects.map(subject => {
                                                return <MenuItem
                                                    key={subject._id}
                                                    value={subject._id}
                                                    primaryText={subject.subtitle ? `${subject.name + " - " + (subject.subtitle)}` : subject.name}
                                                    onClick={() => {
                                                        const clonedSections = cloneDeep(subject.sections)
                                                        const sortedSections = sortBy(clonedSections, ['name'])
                                                        this.setState({ sections: sortedSections })
                                                    }}
                                                />
                                            })
                                            :
                                            null
                                    }
                                </SelectField>

                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.sectionId}
                                    floatingLabelText="Section (Optional)"
                                    onChange={(event, index, sectionId) => this.setState({ sectionId, error: false })}
                                    disabled={this.state.subjectId === "1"}
                                >
                                    {
                                        this.state.sections ?
                                            this.state.sections.map(section => {
                                                return <MenuItem
                                                    key={section._id}
                                                    value={section._id}
                                                    primaryText={section.name}
                                                />
                                            })
                                            :
                                            null
                                    }
                                </SelectField>
{/* 
                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.questionStatus}
                                    floatingLabelText="Approval Status (Optional)"
                                    onChange={(event, index, questionStatus) => this.setState({ questionStatus, error: false })}
                                >
                                    <MenuItem value={"all"} primaryText="All" />
                                    <MenuItem value={"approved"} primaryText="Approved" />
                                    <MenuItem value={"nonapproved"} primaryText="Non Aprroved" />
                                </SelectField> */}

                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.showInChallenge}
                                    floatingLabelText="Show in Challenge (Optional)"
                                    onChange={(event, index, showInChallenge) => this.setState({ showInChallenge, error: false })}
                                >
                                    <MenuItem value={"all"} primaryText="All" />
                                    <MenuItem value={"true"} primaryText="True" />
                                    <MenuItem value={"false"} primaryText="False" />
                                </SelectField>

                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.deletedStatus}
                                    floatingLabelText="Active Status (Optional)"
                                    onChange={(event, index, deletedStatus) => this.setState({ deletedStatus, error: false })}
                                >
                                    <MenuItem value={"all"} primaryText="All" />
                                    <MenuItem value={"undeleted"} primaryText="Undeleted" />
                                    <MenuItem value={"deleted"} primaryText="Deleted" />
                                </SelectField>

                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.questionType}
                                    floatingLabelText="Question Type (Optional)"
                                    onChange={(event, index, questionType) => this.setState({ questionType, error: false })}
                                >
                                    <MenuItem value={"all"} primaryText="All" />
                                    {
                                        Object.keys(questionTypes).map(eachKey => (
                                            <MenuItem
                                                key={eachKey}
                                                value={questionTypes[eachKey]}
                                                primaryText={questionTypes[eachKey]}
                                            />
                                        ))
                                    }
                                </SelectField>

                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.premiumQuestion}
                                    floatingLabelText="Premium Question (Optional)"
                                    onChange={(event, index, premiumQuestion) => this.setState({ premiumQuestion, error: false })}
                                >
                                    <MenuItem value={"all"} primaryText="All" />
                                    <MenuItem value={"premium"} primaryText="Premium" />
                                    <MenuItem value={"nonpremium"} primaryText="Non Premium" />
                                </SelectField>

                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.languageId}
                                    floatingLabelText="Language (optional)"
                                    onChange={(event, index, languageId) => this.setState({ languageId, error: false })}
                                >
                                    {
                                        languageTree ?
                                            languageTree.map(each => {
                                                return <MenuItem
                                                    key={each._id}
                                                    value={each._id}
                                                    primaryText={each.name}
                                                />
                                            })
                                            :
                                            null
                                    }
                                </SelectField>

                                <SelectField
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                    value={this.state.explanationFilter}
                                    floatingLabelText={"Explanation"}
                                    onChange={(event, index, skipCount) => this.setState({ explanationFilter: skipCount })}
                                >
                                    <MenuItem value={"all"} primaryText="All" />
                                    <MenuItem value={"hasExplanation"} primaryText="Has Explanation" />
                                    <MenuItem value={"hasNoExplanation"} primaryText="No Explanation" />
                                </SelectField>

                                {
                                    this.props.questionsData.count ?
                                        <SelectField
                                            style={{ marginLeft: 10, marginRight: 10 }}
                                            value={this.state.skipCount}
                                            floatingLabelText={"Page Number"}
                                            onChange={(event, index, skipCount) => this.setState({ skipCount: skipCount }, () => this.getQuestions())}
                                        >
                                            {this.renderPagination()}
                                        </SelectField>
                                        :
                                        null
                                }
                            </Col>

                            <Row>

                                <Col md="3">
                                    <SelectField
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                        value={this.state.period}
                                        floatingLabelText={"Added On"}
                                        onChange={(event, index, period) => {
                                            this.setState({ period })
                                        }}
                                    >
                                        {
                                            dropDownPeriods.map(each => (
                                                <MenuItem
                                                    key={each.value}
                                                    value={each.value}
                                                    primaryText={each.label}
                                                />
                                            ))
                                        }
                                    </SelectField>
                                </Col>

                                {
                                    this.state.period === "customTime" &&
                                    <>
                                        <Col md="3">
                                            <DatePicker
                                                style={{ marginTop: 20 }}
                                                hintText="From Date"
                                                container="inline"
                                                value={this.state.fromDate}
                                                onChange={(e, date) => this.setState({ fromDate: date })}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <DatePicker
                                                style={{ marginTop: 20 }}
                                                hintText="To Date"
                                                container="inline"
                                                value={this.state.toDate}
                                                onChange={(e, date) => this.setState({ toDate: date })}
                                            />
                                        </Col>
                                    </>
                                }
                            </Row>

                            <Row style={{ alignItems: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }}>
                                <Col sm='0'>
                                    <Checkbox
                                        id={"1"}
                                        name="fetchNonExamQuestions"
                                        type="checkbox"
                                        checked={this.state.fetchNonExamQuestions === true}
                                        onCheck={(e, value) => this.fetchNonExamQuestions(e, value)}
                                    />
                                </Col>
                                <span>Fetch questions not in any exams</span>
                            </Row>

                            <Row>
                                <RaisedButton
                                    disabled={this.state.subjectId === null || this.state.subjectId.length === 0}
                                    style={{ margin: 10 }}
                                    fullWidth={false}
                                    type="submit"
                                    label="Get Questions"
                                />
                            </Row>
                        </Form>
                    </Container>
                </Row>

                { this.state.error ? <h4>{this.state.errorMessage}</h4> : null }

                {
                    this.state.isLoading ?
                        <div style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            paddingTop: '20%',
                            paddingLeft: '47%'
                        }}>
                            <CircularProgress size={80} thickness={5} />
                        </div>
                        :
                        null
                }

                { this.props.location.search && queryString.parse(this.props.location.search).addedByName ? <span style={{ textAlign:'right' }} >Added By : {queryString.parse(this.props.location.search).addedByName} <Badge pill onClick={ () => this.setState({ addedBy:null })} style={{ color:'white' }}>X</Badge> | </span> : null }
                { this.props.location.search && queryString.parse(this.props.location.search).editedByName ? <span style={{ textAlign:'right' }} >Edited By : {queryString.parse(this.props.location.search).editedByName} <Badge pill onClick={ () => this.setState({ editedBy:null })} style={{ color:'white' }}>X</Badge> | </span> : null }

                <Row style={{display: 'flex', justifyContent: 'space-between', marginLeft: '1rem', marginRight: '1rem'}}>
                    <div>
                        <Button
                            color='primary'
                            disabled={this.state.questionIdsToCopyArr.length === 0}
                            style={{marginBottom: 10}}
                            onClick={() => this.copyIdsToClipboard()}
                            >
                            Copy IDs
                        </Button>
                        {
                            Array.isArray(this.state.questionIdsToCopyArr) && this.state.questionIdsToCopyArr.length > 0 &&
                            <span style={{ marginLeft: 10 }}>{this.state.questionIdsToCopyArr.length} Questions Selected</span>
                        }
                    </div>
                    <span style={{ textAlign:'right' }}>Total : {this.props.questionsData.count}</span>
                </Row>

                {
                    this.props.questionsData.questions && this.props.questionsData.questions.length > 0 ?
                        <QuestionsTable
                            questionsData={this.props.questionsData}
                            history={this.props.history}
                            skipCount={this.state.skipCount}
                            backRoute={(this.props.location.state && this.props.location.state.backRoute) ? this.props.location.state.backRoute : 'searchQuestions'}
                            addedBy={this.state.addedBy}
                            editedBy={this.state.editedBy}
                            topicId={this.props.location.state && this.props.location.state.topicId}
                            dailyChallengeId={this.state.dailyChallengeId}
                            currentAffairsPeriod={this.state.currentAffairsPeriod}
                            period={this.props.location.state && this.props.location.state.period}
                            unitId={this.props.location.state && this.props.location.state.unitId}
                            subjectId={this.state.subjectId}
                            addQuestionIdsToCopyArr={(questionId, checkedValue) => this.addQuestionIdsToCopyArr(questionId, checkedValue)}
                            questionIdsArr={this.state.questionIdsToCopyArr}
                            checkAllQuestions={(e,value)=>this.checkAllQuestions(e,value)}
                            sectionId={this.state.sectionId}
                            languageId={this.state.languageId}
                            tagId={this.state.tagId}
                            activeFlag={this.state.deletedStatus}
                            searchText={this.state.searchText}
                            questionId={this.state.questionId && this.state.questionId.trim()}
                            showInChallenge={this.state.showInChallenge}
                            // questionStatus={this.state.questionStatus}
                            questionType={this.state.questionType}
                            roleIds={this.props.auth.roleIds}
                            deleteButtonAction={ (item, index) => this.setState({ deleteDialog: true, deleteItemId: item._id, deleteIndex:index }) }
                        />
                        :
                        this.props.questionsData.count > 0 ?
                        <div style={{ marginTop:100 }}>
                            <h4 style={{ textAlign: 'center' }}>{this.props.questionsData.count} Questions Found. Check available pages</h4>
                        </div>
                        :
                        <div style={{ marginTop:100 }}>
                            <h4 style={{ textAlign: 'center' }}>No Questions Found</h4>
                        </div>
                }
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        questionsData: state.data.questions,
        subjects:[{ name: 'All', _id: "1" }].concat(state.data.subjects),
    }
}

export default connect(mapStateToProps, { updateQuestions })(ViewQuestions)
