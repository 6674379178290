import React from 'react'
import { Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Container, Row, Col, Alert, Button, CustomInput } from 'reactstrap'

const CustomModal = (props) => {
    return (
        <Modal style={{ top: 60 }} isOpen={props.isOpen}>
            <ModalBody>
                <Form>
                    <Row>
                        {
                            props.fields.map(each => {
                                return (
                                    <Col md={ props.fields.length % 2 === 0 ? "6" : "12"} key={each.fieldName}>
                                        <FormGroup key={each.fieldName}>
                                            <Label for={each.fieldName}>Enter {each.placeholder}</Label>
                                            <Input type={each.type || "text"} name={each.fieldName} onChange={props.handleInputChange} value={each.value || ''} />
                                        </FormGroup>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {
                        props.selectFields && props.selectFields.map(eachSelect => {
                            return (
                                <FormGroup key={eachSelect.fieldName}>
                                    <Label for={eachSelect.fieldName}>Select</Label>
                                    <Input value={eachSelect.value} type="select" name={eachSelect.fieldName} id={eachSelect.fieldName} onChange={props.handleInputChange}>
                                        {
                                            eachSelect.data.map((each) => {
                                                return (<option key={each._id} value={each._id}>{each.name}</option>)
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            )
                        })
                    }
                    {
                        props.checkBoxFields && props.checkBoxFields.map(eachSelection => {
                            return (
                                <div
                                    style={{ overflowY: 'scroll' }}
                                    key={eachSelection.fieldName}
                                >
                                    <FormGroup>
                                        <Label for="tags">Posts</Label>
                                        <div>
                                            {
                                                eachSelection.data && eachSelection.data.map(post => {
                                                    return (
                                                        <CustomInput
                                                            onChange={(event) => props.handleCheckBoxChange(post._id)}
                                                            id={post._id}
                                                            key={post._id}
                                                            value={post._id}
                                                            type="checkbox"
                                                            label={post.name}
                                                            checked={eachSelection.value && eachSelection.value.includes(post._id)}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </FormGroup>
                                </div>
                            )
                        })
                    }
                    { props.extraComponent }
                </Form>
            </ModalBody>
            <ModalFooter>
                <Container>
                    <Row>
                        <Col>
                            <Alert isOpen={props.showEnterAllDataAlert} color="danger">{ props.alertMessage ? props.alertMessage : "Enter all data before submitting" }</Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="primary" block onClick={props.onSubmit}>Submit</Button>
                            {props.showDeleteButton && <Button color="danger" block onClick={props.onDelete}>{props.isDelete ? 'Undelete' : 'Delete' }</Button>}
                            <Button color="secondary" block onClick={props.onCancel}>Cancel</Button>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </Modal>
    )
}

export default CustomModal