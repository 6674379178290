import React, { useState } from 'react'
import axios from 'axios'
import { useSelector, shallowEqual } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { unstable_batchedUpdates } from 'react-dom'
import { copyToClipboard } from '../utils';
import { Container, Col, Row, Button,Input, Table, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'


export default function SearchModule(props) {

    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const subjects = useSelector(state => state.data.subjects, shallowEqual)
    const [serachResult, setSerachResult] = useState(null)
    
    const fetchModules = () => {
        setIsLoading(true)
        axios.get('/learn/search-modules', { params: { searchText }})
            .then(res => {
                const  { moduleDetails } = res.data
                
                moduleDetails.forEach(item => {
                    subjects.forEach(subject => {
                        subject.sections.forEach(section => {
                            if (section._id === item.sectionId) {
                                item.sectionName = section.name 
                                item.subjectName = subject.name 
                            }
                        })
                    })
                })

                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setSerachResult(res.data.moduleDetails)
                })

            })
            .catch(err => {
                alert("Error, Check console")
                console.log(err)
            })
    }

    return (
        <Container fluid>

            <Row style={{ margin: '3rem' }}>
                <Col md='5'>
                    <h1>search modules</h1>
                </Col>
                <Col md="5">
                    <Input
                        type="text"
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Col>
                <Col md='2'>
                    <Button 
                        color='primary'
                        onClick={() => { fetchModules() }}
                    >
                        Search
                    </Button>
                </Col>
            </Row>

            { isLoading && <Spinner color="info" style={{ position: 'absolute', top: '50%', right: '50%'}}/> }

            { Array.isArray(serachResult) && serachResult.length > 0 ?
                <Row style={{ margin: '2rem'}}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Title</th>
                                <th>Sub Title</th>
                                <th>Type</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { serachResult.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{index}</td>
                                        <td>
                                            <div style={{ fontSize: 18 }}>{item.moduleTitle}</div>
                                            <p style={{ fontSize: 13 }}>{item.subjectName}-{item.sectionName}</p>
                                        </td>
                                        <td>{item.moduleDescription && item.moduleDescription}</td>
                                        <td>{item.content.moduleType}</td>
                                        <td>
                                            <Button
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    copyToClipboard(item._id)
                                                    toast("ID copied", {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true
                                                        })
                                                }}
                                                outline
                                            >
                                                <FontAwesomeIcon
                                                    // outline
                                                    className="iconButton"
                                                    style={{ color: 'grey' }}
                                                    icon={faCopy}
                                                />
                                            </Button>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => props.history.push({ pathname: `/module/${item._id}` })}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })

                            }
                        </tbody>
                    </Table>
                </Row> 
                :
                Array.isArray(serachResult) && serachResult.length === 0 ?
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
                        <h3>No data found</h3>
                    </Row>
                    :
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
                        <h3>search module title..</h3>
                    </Row>
            }

        </Container>
    )
}
