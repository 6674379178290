import _ from 'lodash'

export const superAdmin = 'superAdmin'
export const admin = 'admin'

export const accountant = 'accountant'
export const hr = 'hr'

// = = = Operations Team = = =
// Customer Support
export const supportManager = 'supportManager'
export const supportExecutive = 'supportExecutive'

// Sales
export const salesManager = 'salesManager'
export const salesLead = 'salesLead'
export const salesTeam = 'salesTeam'

// Digital Marketing
export const marketingManager = 'marketingManager'
export const marketingExecutive = 'marketingExecutive'

// Content
export const contentManager = 'contentManager'
export const dataEntry = 'dataEntry'
export const contentReviewer = 'contentReviewer'

export const examsManager = 'examsManager'
export const currentAffairsManager = 'currentAffairsManager'
export const batchManager = 'batchManager'
export const crashCourseManager = 'crashCourseManager'

export const rolesNameList = [
    {
        id: 0,
        name: 'Super Admin',
    },
    {
        id: 1,
        name: 'Admin',
    },
    {
        id: 3,
        name: 'Accountant',
    },
    {
        id: 4,
        name: 'Hr',
    },
    {
        id: 5,
        name: 'Support Manager',
    },
    {
        id: 6,
        name: 'Support Executive',
    },
    {
        id: 7,
        name: 'Sales Manager',
    },
    {
        id: 8,
        name: 'Sales Lead',
    },
    {
        id: 9,
        name: 'Sales Team',
    },
    {
        id: 10,
        name: 'Marketing Manager',
    },
    {
        id: 11,
        name: 'Marketing Executive',
    },
    {
        id: 12,
        name: 'Content Manager',
    },
    {
        id: 13,
        name: 'Data Entry',
    },
    {
        id: 15,
        name: 'Content Reviewer',
    },
    {
        id: 16,
        name: 'Exams Manager',
    },
    {
        id: 17,
        name: 'Current Affairs Manager',
    },
    {
        id: 18,
        name: 'Batch Manager',
    },
    {
        id: 19,
        name: 'Crash Course Manager',
    },
]

export const roles = {
    0: superAdmin,
    1: admin,
    3: accountant,
    4: hr,

    5: supportManager,
    6: supportExecutive,

    7: salesManager,
    8: salesLead,
    9: salesTeam,

    10: marketingManager,
    11: marketingExecutive,

    12: contentManager,
    13: dataEntry,
    15: contentReviewer,
    16: examsManager,
    17: currentAffairsManager,
    18: batchManager,
    19: crashCourseManager,
}

export const isDataEntryUser = (roleIds) => {
    if (roleIds.includes(13) && roleIds.length === 1) {
        return true
    } else {
        return false
    }
}

export const isContentManagerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12]).length) {
        return true
    } else {
        return false
    }
}

export const isAdmin = (roleIds) => {
    if (roleIds.includes(1)) {
        return true
    } else {
        return false
    }
}

export const isSalesManagerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,7]).length) {
        return true
    } else {
        return false
    }
}

export const isSalesPerson = (roleIds) => {
    if (_.intersection(roleIds, [7, 8, 9]).length) {
        return true
    } else {
        return false
    }
}


export const isMarketingManagerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,10]).length) {
        return true
    } else {
        return false
    }
}

export const isContentReviewerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12,15]).length) {
        return true
    } else {
        return false
    }
}

export const isExamManagerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12,16]).length) {
        return true
    } else {
        return false
    }
}

export const isCrashCourseManagerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12,19]).length) {
        return true
    } else {
        return false
    }
}

export const isBatchManagerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12,18]).length) {
        return true
    } else {
        return false
    }
}

export const isCurrentAffairsManagerOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12,17]).length) {
        return true
    } else {
        return false
    }
}

export const isDataEntryUserOrHigher = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12,13]).length) {
        return true
    } else {
        return false
    }
}

export const isContentTeam = (roleIds) => {
    if (_.intersection(roleIds, [0,1,12,13,15,16,17,18,19]).length) {
        return true
    } else {
        return false
    }
}