import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Container, Table, Row,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'

import Loader from '../../Components/Loader'

function MostStudiedCourseTopics() {

    const [isLoading, setIsLoading] = useState(false)
    const [upcomingPosts, setUpcomingPosts] = useState()
    const [selectedPost, setSelectedPost] = useState(null)
    const [data, setData] = useState(null)

    const posts = useSelector((state) => state.data.posts)

    useEffect(() => {
        const upcomingPosts = posts.filter(each => each.upcoming)
        setUpcomingPosts(upcomingPosts)
    }, [])

    useEffect(() => {
        if (selectedPost) {
            setIsLoading(true)
            getData(selectedPost._id)
        }
    }, [selectedPost])

    const getData = (postId) => {
        Axios.get('/most-studied-course-topics', { params: { postId }})
            .then(res => {
                setData(res.data)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error, check console")
            })
    }

    return (
        <>
            {isLoading && <Loader />}
            <Container fluid>
                
                <Row>
                    <UncontrolledDropdown style={{ margin: 10 }}>
                        <DropdownToggle caret color="primary">{selectedPost ? selectedPost.name : "Select Post"}</DropdownToggle>
                        <DropdownMenu className="addScroll">
                            {
                                Array.isArray(upcomingPosts) && upcomingPosts.map((each) => {
                                    return (
                                        <DropdownItem
                                            key={each._id}
                                            value={each.title}
                                            onClick={() => setSelectedPost(each)}
                                        >
                                            {each.name}
                                        </DropdownItem>)
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Row>

                {
                    Array.isArray(data) && data.length > 0 ?
                        data.map((each, index) => (
                            <React.Fragment key={each._id}>
                                <h5 style={{ marginTop: 20 }}>{index + 1}. {each.unitName}</h5>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Module Name</th>
                                            <th>Studied User Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            each.modules.map((module, index) => (
                                                <tr key={module._id}>
                                                    <td style={{ width: '10%' }}>{index + 1}</td>
                                                    <td style={{ width: '60%' }}>{module.moduleTitle}</td>
                                                    <td style={{ width: '30%' }}>{module.count}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        ))
                        : null
                }

            </Container>
        </>
    )

}

export default MostStudiedCourseTopics
