/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import axios from 'axios'
import {
    Container, Row, Col, Table, Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Label, Form, FormGroup, Input, Alert,
    TabContent, TabPane, Nav, NavItem, NavLink,
    DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown
} from 'reactstrap';
import { cloneDeep, isPlainObject } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux'
import { copyToClipboard } from '../utils';
import { toast } from 'react-toastify'

const languageTree = [
    {
        _id: "5b0326f395409b0f800bd784",
        name: "English"
    },
    {
       _id: "5b0326f395409b0f800bd785",
        name: "Malayalam"
    }
]

const tabIds = [
    { key: '1', title: "New" },
    { key: '2', title: "Deleted" },
    { key: '3', title: "Approved" }
]

class UserQuestions extends React.Component {

    state = {
        skip: 0,
        questions: null,
        editingQuestion: null,
        newQuestionId: null,
        showSubmitAlert: false,
        showDeleteAlert: false,
        phoneNumber: '',
        pageNumber: 0,
        questionCount: 50,
        activeTab: '1',

        subjectId:"allSubjects",
        sectionId:"allSections",
        sections:[]
    }

    componentDidMount() {
        this.getQuestions()
    }

    getQuestions(tabNumber) {

        const { subjectId, sectionId, phoneNumber, pageNumber } = this.state
        const filterObject = { skipCount: pageNumber*50 }

        if (tabNumber === '2') { filterObject.activeFlag = false }
        if (tabNumber === '3') { filterObject.approved = true }
        if (phoneNumber) { filterObject.phoneNumber = phoneNumber }
        if (subjectId && subjectId !== "allSubjects") { filterObject.subjectId = subjectId }
        if (sectionId && sectionId !== "allSections") { filterObject.sectionId = sectionId }

        axios.get('/user-question/admin', { params: filterObject })
            .then(res => {
                this.setState({ questions: res.data.questions, skip: res.data.questions.length, questionCount: res.data.questionsCount ,editingQuestion: null })
            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message)
                } else {
                    alert("Error. Please check console")
                }
                
            })
    }

    mergeQuestion(questionData) {

        const { subjectId, sectionId, languageId, activeFlag } = questionData

        if (!activeFlag) {
            alert("Cannot merge deleted question")
        } else if (!subjectId || !sectionId || !languageId) {
            alert("Please select Subject, Section and Language before merging")
        } else {
            axios.post('/user-question/admin/merge', questionData)
                .then(res => {
                    this.setState({ newQuestionId: res.data.newQuestionId }, () => {
                        setTimeout(() => {
                            this.setState({ newQuestionId: '' })
                        }, 5000);
                    })
                    
                    this.getQuestions(this.state.activeTab)

                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Please check console")
                })
        }
    }

    submitQuestionEdit() {

        const { question, options, answer } = this.state.editingQuestion

        if (question && answer) {

            const noTextOptions = options.filter(each => !each.text)
            if (noTextOptions.length > 0) { return alert("Please enter all options") }

            axios.post('/user-question/admin', this.state.editingQuestion)
                .then(() => {
                    this.getQuestions(this.state.activeTab)
                    this.setState({ showSubmitAlert: true }, () => {
                        setTimeout(() => {
                            this.setState({ showSubmitAlert: false })
                        }, 5000);
                    })
                }).catch(err => {
                    console.log(err)
                    alert("Error. Please check console")
                })

        } else {
            alert("Please enter all data")
        }
        
    }

    showAlter(condition, message) {
        if (condition) {
            return (
                <Alert color="success" style={{ position: 'absolute', right: 20, top: 50 }}>
                    {message}
                </Alert>
            )
        }
    }

    deleteQuestion(questionId, activeFlag) {
        if (window.confirm(`Are you sure want to ${activeFlag ? "delete" : "restore"} this question?`)) {
            axios.delete('/user-question/admin', { params: { questionId } })
                .then(() => {
                    this.getQuestions(this.state.activeTab)
                    this.setState({ showDeleteAlert: true }, () => {
                        setTimeout(() => {
                            this.setState({ showDeleteAlert: false })
                        }, 5000);
                    })
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Please check console")
                })
        }
    }

    renderFilter() {
        return (
            <>
                {
                    this.state.newQuestionId ?
                        <Alert color="success" style={{ position: 'absolute', right: 20, top: 50 }}>
                            Merged succesfully. <a onClick={() => {
                                copyToClipboard(this.state.newQuestionId)
                                toast("ID copied", {
                                    position: toast.POSITION.TOP_CENTER,
                                    hideProgressBar: true
                                    })
                                this.setState({ newQuestionId: null })
                            }} className="alert-link changePointer"><u>Click here to copy added question ID</u></a>
                        </Alert>
                        : null
                }

                { this.showAlter(this.state.showSubmitAlert, 'Edited succesfully') }
                { this.showAlter(this.state.showDeleteAlert, 'Deleted succesfully') }
                
                <Row style={{ paddingTop: 30 }}>
                    <Col md="3">
                        <FormGroup>
                            <Label for="subjectId">Select Subject</Label>
                            <Input
                                value={this.state.subjectId}
                                type="select"
                                name={"subjectId"}
                                id={"subjectId"}
                                onChange={(e) => {
                                    const value = e.target.value
                                    if (value === "allSubjects") {
                                        this.setState({ subjectId: value, sectionId: "allSections", sections: [] })
                                    } else {
                                        const foundSubject = this.props.subjects.find(e => e._id === value)
                                        if (foundSubject) {
                                            this.setState({ subjectId: value, sectionId: "allSections", sections: foundSubject.sections })
                                        } else {
                                            alert("Subject not found")
                                        }
                                    }
                                }}
                            >
                                {
                                    this.props.subjects ?
                                        [
                                            { _id: "allSubjects", name: "All Subjects" }, ...this.props.subjects].map((subject, index) => (
                                                <option key={subject._id} value={subject._id}>{subject.name}</option>
                                            ))
                                        :
                                        null
                                }
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col md="3">
                        <FormGroup>
                            <Label for="sectionId">Select Section</Label>
                            <Input
                                value={this.state.sectionId}
                                type="select"
                                name={"sectionId"}
                                id={"sectionId"}
                                onChange={(e) => {
                                    const value = e.target.value
                                    this.setState({ sectionId: value })
                                }}
                            >
                                {
                                    Array.isArray(this.state.sections) && this.state.sections.length > 0 ?
                                        [{ _id: "allSections", name: "All Sections" }, ...this.state.sections].map(section => {
                                            return (
                                                <option
                                                    key={section._id}
                                                    value={section._id}
                                                    onClick={(e) => this.setState({ sectionId: e.target.value })}
                                                >
                                                    {section.name}
                                                </option>
                                            )
                                        })
                                        :
                                        [{ _id: "allSections", name: "All Sections" }].map(section => {
                                            return (
                                                <option key={section._id} value={section._id}
                                                    onClick={(e) => this.setState({ sectionId: e.target.value })}
                                                >
                                                    {section.name}
                                                </option>
                                            )
                                        })
                                }
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col md="3">
                        <FormGroup>
                            <Label for="sectionId">Enter Mobile Number (Optional)</Label>
                            <Input
                                value={this.state.phoneNumber}
                                name={"phoneNumber"}
                                id={"phoneNumber"}
                                onChange={(e) => {
                                    const value = e.target.value
                                    this.setState({ phoneNumber: value })
                                }}
                                placeholder={'Search by phone Number'}
                                type="text"

                            />
                        </FormGroup>
                    </Col>

                    <Col md="3" style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <Button
                            outline
                            color='primary'
                            onClick={() => this.getQuestions()}
                        >
                            Search
                        </Button>
                    </Col>

                </Row>
            </>
        )
    }

    renderSkipMenu =()=> {
        
        let returnArray = []
        let itemCount = this.state.questionCount / 50
        
        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={
                        ()=>this.setState({ pageNumber:index },
                        ()=>this.getQuestions(this.state.activeTab))
                }>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    onClickTab(number) {
        this.getQuestions(number)
        this.setState({ activeTab: number })
    }

    renderTable(tabNumber) {
        const { questions } = this.state

        if ( questions && questions.length > 0  ){

            return(
                
                <Row style={{ paddingTop: 10 }}>
                    <Row style={{ marginBottom:10, flex:1, justifyContent:'flex-end' }}>
                        <div style={{paddingRight: 25}}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="success" id="dropdown-basic"> Page {this.state.pageNumber + 1} </DropdownToggle>
                                <DropdownMenu className="addScroll">
                                    {this.renderSkipMenu()}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Created By</th>
                                <th>Question</th>
                                <th>Option A</th>
                                <th>Option B</th>
                                <th>Option C</th>
                                <th>Option D</th>
                                <th>Correct Answer</th>
                                <th>Added On</th>
                                {tabNumber !== '3' && <th>Actions</th> }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                questions.map((question, index) => {
                                    return (
                                        <tr key={question._id} style={{ background: question.activeFlag === false ? 'rgba(255,0,0,0.2)' : null }}>
                                            <td>{index + 1}</td>
                                            <td>{question.username}</td>
                                            <td>{question.question}</td>
                                            <td>{question.options[0].text}</td>
                                            <td>{question.options[1].text}</td>
                                            <td>{question.options[2].text}</td>
                                            <td>{question.options[3].text}</td>
                                            <td>{question.options.find(e => e.id === question.answer).text}</td>
                                            <td>{moment(question.addedOn).format('D MMM YYYY')}</td>
                                            { tabNumber !== '3' && 
                                                <td>
                                                    <Button
                                                        outline
                                                        style={{ margin: 2 }}
                                                        color="primary"
                                                        disabled={!question.sectionId || !question.subjectId || !question.languageId}
                                                        onClick={() => this.mergeQuestion(question)}>
                                                        Merge
                                                    </Button>
                                                    <Button
                                                        outline
                                                        style={{ margin: 2 }}
                                                        color="primary"
                                                        onClick={() => {
                                                            if (question.sectionId) {
                                                                const foundSubject = this.props.subjects.find(each => each._id === question.subjectId)
                                                                if (foundSubject) {
                                                                    this.setState({
                                                                        sections: foundSubject.sections,
                                                                        editingQuestion: { ...cloneDeep(question), index }
                                                                    })
                                                                } else {
                                                                    this.setState({ editingQuestion: { ...cloneDeep(question), index } })
                                                                }
                                                            } else {
                                                                this.setState({ editingQuestion: { ...cloneDeep(question), index } })
                                                            }
                                                        }}>
                                                        Edit
                                                    </Button>

                                                    <Button
                                                        outline
                                                        style={{ margin: 2 }}
                                                        color="danger"
                                                        onClick={() => this.deleteQuestion(question._id, question.activeFlag)}>
                                                        {question.activeFlag ? "Delete" : "Restore"}
                                                    </Button>
                                                </td>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Row>
            )
        } else if (questions && questions.length === 0) {
            return(
                <Row>
                    <Col>
                        <h4 style={{ marginTop: 100, textAlign: 'center' }}>No questions available</h4>
                    </Col>
                </Row>
            )
        } else {

        }
    }

    render() {

        const {  editingQuestion } = this.state
            return (
                <Container fluid>

                    {
                        isPlainObject(editingQuestion) &&
                        <Modal
                            fade={true}
                            size="lg"
                            isOpen={true}
                        >
                            <ModalHeader>
                                Edit a user added question
                            </ModalHeader>

                            <ModalBody>
                                <Form>

                                    <FormGroup>
                                        <Label>Question</Label>
                                        <Input
                                            placeholder="Enter Question"
                                            type="textarea"
                                            name="question"
                                            value={editingQuestion.question}
                                            onChange={(e) => {
                                                const newText = e.target.value
                                                this.setState(oldState => {
                                                    const question = editingQuestion
                                                    question.question = newText
                                                    return { editingQuestion: question }
                                                })
                                            }}
                                            style={{ marginBottom: 30, paddingBottom: 40 }}
                                        />
                                    </FormGroup>

                                    <Row>
                                        {
                                            ["A", "B", "C", "D"].map((each, index) => {
                                                return (
                                                    <Col md="6" key={each}>
                                                        <Label>Option {each}</Label>
                                                        <Input
                                                            placeholder={`Option ${each}`}
                                                            type="text"
                                                            name={`option${each}`}
                                                            value={editingQuestion.options[index].text}
                                                            onChange={(e) => {
                                                                const newAnswer = e.target.value
                                                                this.setState(oldState => {
                                                                    const question = editingQuestion
                                                                    question.options[index].text = newAnswer
                                                                    return { editingQuestion: question }
                                                                })
                                                            }}
                                                            style={{ marginBottom: 20 }}
                                                        />
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>

                                    <Row>
                                        <Col md="9">
                                            <Label for="explanation">Explanation</Label>
                                            <Input
                                                type="textarea"
                                                id="explanation"
                                                value={editingQuestion.description}
                                                onChange={(e) => {
                                                    let updatedExplanation = e.target.value
                                                    this.setState(oldState => {
                                                        const question = editingQuestion
                                                        question.description = updatedExplanation
                                                        return { editingQuestion: question }
                                                    })
                                                }}
                                            />
                                        </Col>
                                        <Col md="3">
                                            <Label for="correctAnswerSelect">Correct Answer</Label>
                                            <Input
                                                type="select"
                                                name="select"
                                                id="correctAnswerSelect"
                                                value={editingQuestion.answer}
                                                onChange={(e) => {
                                                    let newAnswerOption = e.target.value
                                                    this.setState(oldState => {
                                                        const question = editingQuestion
                                                        question.answer = newAnswerOption
                                                        return { editingQuestion: question }
                                                    })
                                                }}
                                            >
                                                <option>A</option>
                                                <option>B</option>
                                                <option>C</option>
                                                <option>D</option>
                                            </Input>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop:20 }}>

                                        <Col md="4">
                                            <FormGroup>
                                                <Label for="exampleSelect">Select Subject</Label>
                                                <Input
                                                    value={editingQuestion.subjectId}
                                                    type="select"
                                                    name={"subjectId"}
                                                    id={"subjectId"}
                                                    onChange={(e) => {

                                                        const value = e.target.value

                                                        if (value !== "selectSubject") {

                                                            const foundSubject = this.props.subjects.find(e => e._id === value)

                                                            if (foundSubject) {
                                                                this.setState((oldState) => {
                                                                    const editingQuestion = oldState.editingQuestion

                                                                    editingQuestion.subjectId = value
                                                                    return { question: editingQuestion, sections: foundSubject.sections }
                                                                })
                                                            } else {
                                                                alert("Subject not found")
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {
                                                        this.props.subjects ?
                                                            [
                                                                { _id: "selectSubject", name: "Select a subject" }, ...this.props.subjects].map((subject, index) => (
                                                                    <option key={subject._id} value={subject._id}>{subject.name}</option>
                                                                ))
                                                            :
                                                            null
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup>
                                                <Label for="exampleSelect">Select Section</Label>
                                                <Input
                                                    value={editingQuestion.sectionId}
                                                    type="select"
                                                    name={"sectionId"}
                                                    id={"sectionId"}
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        if (value !== "selectSection") {
                                                            this.setState((oldState) => {
                                                                const editingQuestion = oldState.editingQuestion
                                                                editingQuestion.sectionId = value
                                                                return { question: editingQuestion }
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {
                                                        this.state.sections ?
                                                            [{ _id: "selectSection", name: "Select a topic" }, ...this.state.sections].map(section => {
                                                                return (
                                                                    <option key={section._id} value={section._id}
                                                                        onClick={(e) => this.setState({ sectionId: e.target.value })}
                                                                    >
                                                                        {section.name}
                                                                    </option>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup>
                                                <Label for="exampleSelect">Select Quiz Language</Label>
                                                <Input
                                                    type="select"
                                                    name={"languageId"}
                                                    value={editingQuestion.languageId}
                                                    id={"languageId"}
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        if (value !== "selectLanguage") {
                                                            this.setState((oldState) => {
                                                                const editingQuestion = oldState.editingQuestion
                                                                editingQuestion.languageId = value
                                                                return { editingQuestion }
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {
                                                        [
                                                            { _id: "selectLanguage", name: "Select Language" }, ...languageTree].map(language => {
                                                            return (
                                                                <option key={language._id} value={language._id}
                                                                    onClick={(e) => this.setState({ languageId: e.target.value })}
                                                                >
                                                                    {language.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                </Form>


                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    outline
                                    color="primary"
                                    onClick={() => this.submitQuestionEdit()}>
                                    Submit
                                </Button>
                                <Button
                                    outline
                                    onClick={() => this.setState({ editingQuestion: false })}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Modal>
                    }

                    { this.renderFilter() }
                    
                    <Nav tabs>
                        { tabIds.map((each) => {
                            return(
                                <NavItem key={each.key}>
                                    <NavLink className={this.state.activeTab === each.key ? 'active' : ''} 
                                        onClick={ () => {
                                            this.setState({ pageNumber: 0 }, () => {
                                                this.onClickTab(each.key)
                                            })
                                        }}>
                                      {each.title}
                                    </NavLink>
                                </NavItem>
                            )
                        }) }
                   </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                    { tabIds.map((each) => {
                        return(
                            <TabPane tabId={each.key} key={each.key}>
                                <div style={{marginLeft : 20, marginRight : 20}}>
                                    { this.renderTable(each.key) }
                                </div>
                             </TabPane>
                        )
                    }) }
                  </TabContent>
                    
                </Container>
            )
    }
}

const mapStateToProps = state => {
    return {
        subjects: state.data.subjects,
        languages: state.data.languages
    }
}

export default connect(mapStateToProps)(UserQuestions)
