import {
    UPDATE_SUBJECTS,
    UPDATE_TAGS,
    UPDATE_POSTS,
    UPDATE_PACKS,
    UPDATE_LANGUAGES,
    UPDATE_SECTIONS_QUESTIONS_COUNT,
    UPDATE_QUESTIONS,
    UPDATE_PATH_UNITS,
    UPDATE_SALESMEN,
    UPDATE_ADDED_QUESTIONS,
    UPDATE_COURSE_GROUPS,
    UPDATE_TEACHERS_LIST
} from '../config/types'

export const updateSubjects = (subjects) => {
    return {
        type: UPDATE_SUBJECTS,
        payload: subjects
    }
}

export const updateTags = (tags) => {
    return {
        type: UPDATE_TAGS,
        payload: tags
    }
}

export const updatePosts = (posts) => {
    return {
        type: UPDATE_POSTS,
        payload: posts
    }
}

export const updatePacks = (posts) => {
    return {
        type: UPDATE_PACKS,
        payload: posts
    }
}

export const updateCourseGroups = (courseGroups) => {
    return {
        type: UPDATE_COURSE_GROUPS,
        payload: courseGroups
    }
}

export const updateAddedQuestionsCountDetails = (data) => {
    return {
        type: UPDATE_ADDED_QUESTIONS,
        payload: data
    }
}

export const updateLanguages = (languages) => {
    return {
        type: UPDATE_LANGUAGES,
        payload: languages
    }
}

export const updateSectionQuestionsCount = (data) => {
    return {
        type: UPDATE_SECTIONS_QUESTIONS_COUNT,
        payload: data
    }
}

export const updateQuestions = (questions) => {
    return {
        type: UPDATE_QUESTIONS,
        payload: questions
    }
}

export const updatePathUnits = (data) => {
    return {
        type: UPDATE_PATH_UNITS,
        payload: data
    }
}

export const updateSalesmen = (salesmen) => {
    return {
        type: UPDATE_SALESMEN,
        payload: salesmen
    }
}

export const updateTeachersList = (teachers) => {
    return {
        type: UPDATE_TEACHERS_LIST,
        payload: teachers
    }
}
