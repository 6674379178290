import React, { Component } from 'react'
import Aux from '../../Components/Auxiliary'
import FlatButton from 'material-ui/FlatButton'
import Dialog from 'material-ui/Dialog'
import { connect } from 'react-redux'
import { login } from '../../actions'
import axios from 'axios'
import { Form, FormGroup, Input, Button, Row, Col, Container, Spinner } from 'reactstrap'
class Login extends Component {

    state = {
        email:'', password:'', isLoading: false,
        formErrors: { email:'', password:'' },
        emailValid:false, passwordValid:false,
        formValid: false, showDialog: false
    }

    handleInput = (e) => this.setState({ [e.target.name]: e.target.value })

    validateForm() {
        const { email, password } = this.state
        return !(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && password.length > 6)
    }

    checkLogin() {
        if (this.state.email && this.state.password) {
            this.setState({ isLoading: true }, () => {
                axios.post('/adminlogin', {
                    email: this.state.email,
                    password: this.state.password
                })
                .then(res => {
                    this.props.login(res.data.data);
                    localStorage.setItem('userToken',res.data.token);
                    localStorage.setItem('userData',JSON.stringify(res.data.data));
                    window.location.reload()
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message) {
                        alert(err.response.data.message)
                    } else {
                        console.log(err)
                        alert("Error. Please check console")
                    }
                    this.setState({ isLoading: false })
                })
            })
        } else {
            this.setState({ showDialog:true })
        }
    }

    render() {

        const errorActions = [
            <FlatButton
                label="Okay"
                primary={true}
                onClick={() => this.setState({ showDialog:false })}
            />
        ];

        return (
            <Aux history={this.props.history}>

            <Dialog
                title="Invalid Credentials"
                actions={errorActions}
                modal={true}
                open={this.state.showDialog}
            >
                Invalid login details. Please try again.
            </Dialog>

                <div style={{ width:'100%' }}>
                    <div style={{ width:'80%', margin:'auto', textAlign:'center' }}>

                        <h2 style={{ marginTop:'25vh', marginBottom:50 }}>Welcome to <br/>Challenger Dashboard</h2>

                        <Container fluid>
                            <Form>
                                <Row>
                                    <Col sm={{ size: 6, offset:3 }} >

                                        <FormGroup>
                                            <Input
                                                id="email"
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                onChange={this.handleInput}
                                                value={this.state.email}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Input
                                                id="password"
                                                type="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                onKeyPress= {(event) => {
                                                    if(event.key === "Enter") {
                                                        this.checkLogin()
                                                    }
                                                }}
                                                onChange={this.handleInput}
                                                value={this.state.password}
                                            />
                                        </FormGroup>

                                        <Button
                                            block
                                            disabled={this.validateForm()}
                                            outline color="primary" 
                                            style={{ paddingLeft: 15, paddingRight: 15 }}
                                            onClick={this.checkLogin.bind(this)}>
                                            { this.state.isLoading ? <Spinner color="light" /> : "Login" }
                                        </Button>

                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </div>
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth:state.auth
    }
}

export default connect(mapStateToProps, { login })(Login)