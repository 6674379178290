import React from 'react'
import {Container, Row, Col, Table, Button, Input} from 'reactstrap'
import Axios from 'axios'
import moment from 'moment'
import Loader from '../Components/Loader'

class RecentOtp extends React.Component {
        
    state = {
        isLoading: true,
        phoneNumber : null,
        skip: 0,
        limit: 25,
        otpDetails: [],
        showRecentContacts: false
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value, showMinDigitAlert: false })

    getRecentOtpDetails(phoneNumber) {

        let paramsObject = {}
        
        if (!phoneNumber) {
            paramsObject = { skip: this.state.skip, limit: this.state.limit }
        } else {
            paramsObject = { skip: 0, limit: this.state.limit, mobileNumber: phoneNumber}
            this.setState({ showRecentContacts: true })
        }

        this.setState({ isLoading : true },() => {
            Axios.get("/recent-otps",{ params: paramsObject })
                .then(res => { 
                        if (!phoneNumber && res.data.recentOtps && res.data.recentOtps.length === 0) {
                            this.setState({ disableSeeMoreButton : true, isLoading: false })
                        } else {
                            this.setState( oldState => {
                                return {
                                    otpDetails: !phoneNumber ? [...oldState.otpDetails, ...res.data.recentOtps] : [ ...res.data.recentOtps],
                                    isLoading: false
                                }
                            })
                        }
                    })
                .catch(err => {
                    console.log(err)
                    alert("Error, check console")
                })
        })
    }

    componentDidMount() {
       this.getRecentOtpDetails()
    }

    render() {
        return(
            <>
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }}/>}
                <Container fluid style={{ padding: 40 }}>
                    <Row style={{ marginBottom: 40 }}>

                        <Col md='6'>
                            <h2>Recent OTPs</h2>
                        </Col>

                        <Col md='4' style={{ alignItems: 'center' }}>
                            <Row>
                                <Input
                                    placeholder="Search by phone number"
                                    type='number'
                                    name='phoneNumber'
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            if (this.state.phoneNumber) {
                                                this.getRecentOtpDetails(this.state.phoneNumber)
                                            }
                                        }
                                    }}
                                    value={this.state.phoneNumber || ''}
                                    onChange={this.handleInputChange}
                                />
                            </Row>
                        </Col>

                        <Col md='2'>
                            <Button
                                outline
                                color='primary'
                                onClick={() => {
                                    if (this.state.phoneNumber) {
                                        this.getRecentOtpDetails(this.state.phoneNumber)
                                    }
                                }}
                            >
                                Search
                        </Button>
                        </Col>

                    </Row>

                    <Row>
                        {this.state.otpDetails && this.state.otpDetails.length > 0 ?
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Phone Number</th>
                                            <th>Date And Time</th>
                                            <th>OTP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.otpDetails.map((item, index) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.mobile}</td>
                                                        {item.date ?
                                                            <td>{moment(item.date).utcOffset("+05:30").format('DD/MM/YYYY - hh:mm:ss a')}</td>
                                                            :
                                                            <td></td>
                                                        }
                                                        <td>{item.otp}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                            :
                            !this.state.isLoading && Array.isArray(this.state.otpDetails) && this.state.otpDetails.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <h4 style={{ textAlign: 'center', marginTop: 50 }}>No Data Found</h4>
                                </div>
                            : null
                                
                        }
                    </Row>
                    

                    <Row style={{ justifyContent: 'center' }}>
                        {!this.state.disableSeeMoreButton &&
                            <Button
                                outline
                                onClick={() => {
                                    this.setState((oldState) => {
                                        if (oldState.showRecentContacts) {
                                            return { showRecentContacts: false, otpDetails: [] }
                                        } else {
                                            return { skip: oldState.skip + oldState.limit }
                                        }
                                    }, () => this.getRecentOtpDetails())
                                }}
                            >
                                {this.state.showRecentContacts ? `Load Recent OTPs` : `Load More`}
                            </Button>
                        }
                    </Row>

                </Container>
            </>

        )
    }
}

export default RecentOtp