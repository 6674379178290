import React from 'react'
import {
    Form, FormGroup, Label,
    Container, Row, Col, Table, Button, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem,
} from 'reactstrap'
import moment from 'moment'
import { DatePicker } from 'material-ui'
import { connect } from 'react-redux'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faReply } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import { updatePosts } from '../actions'
import Loader from '../Components/Loader'
import { cloneDeep } from 'lodash'

class DailyExams extends React.Component {

    state = {
        isLoading: true,
        newExamId: null,
        newExamDate: null,
        newExamPremiumFlag: false,
        showNewExamModal: false,
        dailyExams: null,
        skip: 0,
        showAttendedCount: false,
        selectedPost: "all",

        upcomingExams: [],
        showAutomationModal: false,
        automationEnabledPosts: []
    }

    componentDidMount() {
        this.getDailyExams()
        this.updateUpcomingPostsAndAutomationEnabledPosts()
    }

    updateUpcomingPostsAndAutomationEnabledPosts = () => {
        const upcomingExams = this.props.posts.filter(each => each.upcoming)
        this.setState({ upcomingExams: cloneDeep(upcomingExams) })
    }

    getDailyExams(isSkipInc) {

        const { selectedPost, showAttendedCount } = this.state
        const paramsObject = { showAttendedCount }

        if (isSkipInc) {
            paramsObject.skip = this.state.skip
        } else {
            paramsObject.skip = 0
        }
        
        if (selectedPost && selectedPost !== "all") {
            paramsObject.selectedPost = selectedPost
        }

        this.setState({ isLoading: true }, () => {
            Axios.get('/daily-exam', { params: paramsObject })
                .then(res => {
                    if (!(res.data.length > 0)) {
                        alert("No more data to fetch")
                    } else if (Array.isArray(this.state.dailyExams) && paramsObject.skipCount) {
                        this.setState({ isLoading: false, dailyExams: this.state.dailyExams.concat(res.data), skip: this.state.skip + res.data.length })
                    } else {
                        this.setState({ isLoading: false, skip: this.state.skip + res.data.length, dailyExams: res.data })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                    alert("Error. Please check console")
                })
        })
    }

    addDailyExam() {

        const { newExamId, newExamDate, newExamPremiumFlag, newExamPost } = this.state

        if (!newExamId) {
            alert("Please enter a valid exam ID")
            return true
        }

        if (!newExamPost) {
            alert("Please select an examination post")
            return true
        }

        if (!newExamDate) {
            alert("Please chose an exam date")
            return true
        }

        Axios.put('/daily-exam', {
            date: newExamDate,
            examId: newExamId,
            premiumExam: newExamPremiumFlag,
            examPost: newExamPost
        })
            .then(() => {
                this.setState({
                    isLoading: true,
                    newExamId: null,
                    newExamDate: null,
                    newExamPremiumFlag: false,
                    showNewExamModal: false
                })
                this.getDailyExams()
            })
            .catch(err => {
                this.setState({ isLoading: false })
                if (err && err.response && err.response.status === 400) {
                    alert(err.response.data.message)
                } else {
                    console.log(err)
                    alert("Error, check console")
                }
            })

    }

    editDailyExam() {
        
        const { editingDailyExamId, editingExamId, editingDailyExamDate, editingExamPremiumFlag, editingExamPost } = this.state

        if (editingExamPost) {
            this.setState({ isLoading: true, showEditModal: false, editingDailyExamId: null, editingExamId: null, editingExamPremiumFlag: false, editingDailyExamDate: false }, () => {
    
                Axios.post('/daily-exam', {
                    dailyExamId: editingDailyExamId,
                    date: editingDailyExamDate,
                    examId: editingExamId,
                    premiumExam: editingExamPremiumFlag,
                    examPost: editingExamPost
                })
                    .then(() => {
                        this.getDailyExams()
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ isLoading: false })
                        if(err && err.response && err.response.status === 400) {
                            alert(err.response.data.message)
                        } else {
                            console.log(err)
                            alert("Error, check console")
                        }
                    })
            })

        } else {
            alert("Please select examination job post")
        }

    }

    submitAutomatedExamCourses() {
        this.setState({ isLoading: true }, () => {
            
            const automationEnabledPosts = this.state.upcomingExams.filter(each => (each.automaticDailyExamData && each.automaticDailyExamData.enabled))
            
            Axios.post('/daily-exam/set-automatic-exam-data', { automationEnabledPosts })
            .then(() => {
                
                this.setState({ isLoading: false, showAutomationModal: false })

                const updatedPosts = this.props.posts.map(eachPropsPost => {
                    
                    const foundPost = this.state.upcomingExams.find(eachStatePost => eachStatePost._id === eachPropsPost._id)
                    
                    if (foundPost) {
                        return foundPost
                    } else {
                        return eachPropsPost
                    }

                })

                this.props.updatePosts(updatedPosts)
                
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false })
                alert("Server error. Check console")
            })
        })   
    }

    deleteExam(examId, examPost, date) {
        this.setState({ isLoading: true }, () => {
            Axios.delete('/daily-exam', { params: { dailyExamId: examId, date: date, examPost: examPost } })
                .then(() => this.getDailyExams())
                .catch(err => {
                    console.log(err)
                    this.setState({ isLoading: false })
                    
                    if(err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error, check console")
                    }
                })
        })
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })
    handleChangeDate = (event, startingDate) => { this.setState({ newExamDate: startingDate }); };

    renderNewExamModal() {
        return (
            <Modal
                isOpen={this.state.showNewExamModal}
            >

                {this.state.isLoading && <Loader />}

                <ModalHeader>
                    Enter new exam details
                </ModalHeader>

                <ModalBody>
                    <Input
                        placeholder="Enter Exam ID"
                        type="text"
                        name="newExamId"
                        value={this.state.newExamId || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                    <div>
                        {
                            Array.isArray(this.state.upcomingExams) &&
                            <Row>
                                <Col>
                                    <Input
                                        type="select"
                                        name="newExamPost"
                                        id="newExamPost"
                                        value={this.state.newExamPost || ''}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            this.setState({ newExamPost: value })
                                        }}
                                        style={{ marginTop: 10 }}
                                    >
                                        {[
                                            { _id: "0", name: "Select Examination Post" },
                                            ...this.state.upcomingExams].map((each, index) => (
                                                <option key={each._id} value={each._id}>
                                                    {each.name}
                                                </option>
                                            ))}
                                    </Input>
                                </Col>
                            </Row>
                        }
                    </div>

                    <div style={{ flex: 1, flexDirection: 'row' }}>
                        <span style={{ marginLeft: 10 }}>Select Exam Date: </span>
                        <DatePicker
                            style={{ marginLeft: 20, display: 'inline' }}
                            container="inline"
                            hintText="Exam Date"
                            onChange={this.handleChangeDate}
                            value={this.state.newExamDate}
                            defaultDate={this.state.newExamDate}
                        />
                    </div>

                    <div style={{ flex: 1, marginLeft: 25, flexDirection: 'row' }}>
                        <Input
                            checked={this.state.newExamPremiumFlag}
                            onChange={(e) => this.setState({ newExamPremiumFlag:!this.state.newExamPremiumFlag }) }
                            type="checkbox" />{' '}

                        <span style={{ marginLeft: 10 }}>Premium Exam </span>
                    </div>

                </ModalBody>

                <ModalFooter>
                    <Button
                        outline
                        color="success"
                        onClick={() => this.addDailyExam()}
                        style={{ marginBottom: '1rem', marginRight: 10 }}>
                        Add Exam
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={() => this.setState({ newExamId: null, newExamDate: null, showNewExamModal: false, newExamPremiumFlag: false })}
                        style={{ marginBottom: '1rem', marginRight: 10 }}>
                        Close
                    </Button>

                </ModalFooter>
            </Modal>
        )
    }

    renderExamEditingModal() {

        return (
            <Modal
                isOpen={this.state.showEditModal}
            >
                <ModalHeader>
                    Edit Daily Exam
                </ModalHeader>

                <ModalBody>
                    <Input
                        placeholder="Exam ID"
                        type="text"
                        name="editingExamId"
                        value={this.state.editingExamId || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                    <div>
                        {
                            Array.isArray(this.state.upcomingExams) &&
                            <Row>
                                <Col>
                                    <Input
                                        type="select"
                                        name="editingExamPost"
                                        id="editingExamPost"
                                        value={this.state.editingExamPost || ''}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            this.setState({ editingExamPost: value })
                                        }}
                                        style={{ marginTop: 10, marginBottom: 20 }}
                                    >
                                        {[
                                            { _id: "0", name: "Select Examination Post" },
                                            ...this.state.upcomingExams].map((each, index) => (
                                                <option key={each._id} value={each._id}>
                                                    {each.name}
                                                </option>
                                            ))}
                                    </Input>
                                </Col>
                            </Row>
                        }
                    </div>

                    <div style={{ flex: 1, flexDirection: 'row', marginBottom: 10 }}>
                        <span style={{ marginLeft: 10 }}>Select Exam Date: </span>
                        <DatePicker
                            style={{ marginLeft: 20, display: 'inline' }}
                            container="inline"
                            hintText="Exam Date"
                            value={this.state.editingDailyExamDate}
                            defaultDate={ this.state.editingDailyExamDate}
                            onChange={ (event, newDate) => this.setState({ editingDailyExamDate: newDate })}
                        />
                    </div>

                    <div style={{ flex: 1, marginLeft: 25, flexDirection: 'row' }}>
                        <Input
                            checked={this.state.editingExamPremiumFlag}
                            onChange={(e) => this.setState({ editingExamPremiumFlag:!this.state.editingExamPremiumFlag }) }
                            type="checkbox" />{' '}

                        <span style={{ marginLeft: 10 }}>Premium Exam </span>
                    </div>

                </ModalBody>

                <ModalFooter>
                    <Button
                        outline
                        color="success"
                        onClick={() => this.editDailyExam()}
                        style={{ marginBottom: '1rem', marginRight: 10 }}
                    >
                        Edit Exam
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        onClick={() => this.setState({ showEditModal:false, editingDailyExamDate: null, editingDailyExamId: null, editingExamId: null }) }
                        style={{ marginBottom: '1rem', marginRight: 10 }}
                    >
                        Close
                    </Button>

                </ModalFooter>
            </Modal>
        )
    }

    renderPosts(posts) {
        const foundPosts = this.props.posts.filter(e => posts.includes(e._id))
        return foundPosts.map((each) => <p key={each._id}>{each.name}</p>)
    }

    toggleAutoDailyExamEnabled(index) {
        this.setState((oldState) => {
            if (oldState.upcomingExams[index] && oldState.upcomingExams[index].automaticDailyExamData) {
                if (oldState.upcomingExams[index].automaticDailyExamData.enabled) {
                    oldState.upcomingExams[index].automaticDailyExamData.enabled = false
                } else {
                    oldState.upcomingExams[index].automaticDailyExamData.enabled = true
                }
            } else {
                oldState.upcomingExams[index].automaticDailyExamData = {
                    enabled: true
                }
            }
            return { upcomingExams: oldState.upcomingExams }
        })
    }

    updateAutoExamQuestionsCount(index, dataType, count) {
        this.setState((oldState) => {
            if (oldState.upcomingExams[index] && oldState.upcomingExams[index].automaticDailyExamData) {
                oldState.upcomingExams[index].automaticDailyExamData[dataType] = parseInt(count)
            } else {
                oldState.upcomingExams[index].automaticDailyExamData = {
                    [dataType]: parseInt(count)
                }
            }
            return { upcomingExams: oldState.upcomingExams }
        })
    }

    renderAutomationModal() {
        return (
            <Modal
                isOpen={this.state.showAutomationModal}
            >
                <ModalHeader>
                    Select posts to auto create daily exams
                </ModalHeader>

                <ModalBody>
                    {
                                <ListGroup>
                                    <ListGroupItem>
                                        <Form>
                                        <Row>
                                            <Col md={6}>
                                                Posts
                                            </Col>
                                            <Col md={3}>
                                                HTML
                                            </Col>
                                            <Col md={3}>
                                                CA
                                            </Col>
                                        </Row>
                                        </Form>
                                    </ListGroupItem>
                                    {
                                        this.state.upcomingExams.map((each, index) => {
                                            return (
                                                <ListGroupItem key={each._id}>
                                                    <Form>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        style={{ height: 20, width: 20 }}
                                                                        checked={(each.automaticDailyExamData && each.automaticDailyExamData.enabled) ? each.automaticDailyExamData.enabled : false}
                                                                        onChange={() => this.toggleAutoDailyExamEnabled(index)}
                                                                    />
                                                                    <Label for="exampleCheck" check style={{ marginLeft: 10 }}>{each.name}</Label>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={3}>
                                                                <FormGroup>
                                                                    <Input
                                                                        placeholder={"HTML"}
                                                                        type="number"
                                                                        value={(each.automaticDailyExamData && each.automaticDailyExamData.htmlQuestions) ? each.automaticDailyExamData.htmlQuestions : ""}
                                                                        onChange={(e) => this.updateAutoExamQuestionsCount(index, "htmlQuestions", e.target.value)}
                                                                        disabled={each.automaticDailyExamData ? !each.automaticDailyExamData.enabled : true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={3}>
                                                                <FormGroup>
                                                                    <Input
                                                                        placeholder={"CA"}
                                                                        type="number"
                                                                        value={(each.automaticDailyExamData && each.automaticDailyExamData.caQuestions) ? each.automaticDailyExamData.caQuestions : ""}
                                                                        onChange={(e) => this.updateAutoExamQuestionsCount(index, "caQuestions", e.target.value)}
                                                                        disabled={each.automaticDailyExamData ? !each.automaticDailyExamData.enabled : true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                    </Form>
                                                    
                                                </ListGroupItem>
                                            )
                                        })
                                    }
                                </ListGroup>
                    }
                </ModalBody>
                
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={ () => this.submitAutomatedExamCourses() }
                    >
                        Submit
                    </Button>
                    <Button
                        outline
                        onClick={ () => {
                            this.setState({ showAutomationModal: false })
                            this.updateUpcomingPostsAndAutomationEnabledPosts()
                        }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        return (
            <>

                {this.state.isLoading && <Loader />}
                {this.state.showAutomationModal && this.renderAutomationModal() }

                <Container fluid style={{ marginTop: 10 }}>

                    {this.state.showNewExamModal && this.renderNewExamModal()}
                    { this.state.showEditModal && this.renderExamEditingModal() }

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <Col md="4">
                            <Button
                                outline
                                color="success"
                                onClick={() => this.setState({ newExamId: null, newExamDate: null, newExamPremiumFlag: false, showNewExamModal: true })}
                                style={{ marginBottom: '1rem', marginRight: 10 }}>
                                + Add New Exam
                            </Button>
                        </Col>

                        <Col md="2">
                            <Button
                                outline
                                color="primary"
                                onClick={ () => this.setState({ showAutomationModal: true }) }
                            >
                                Manage Automatic Daily Exam
                            </Button>
                        </Col>

                        <Col md="2">
                            <Button
                                outline
                                color="primary"
                                onClick={ () => {
                                    if (window.confirm("This action will create daily exam for selected posts for the next 7 days. Are you sure you want to continue?")) {
                                        Axios.post('/daily-exam/auto-create-exam')
                                        .then(() => {
                                            toast("Exam creation started, result/error will be notified via Telegram channel", {
                                                position: toast.POSITION.TOP_CENTER,
                                                hideProgressBar: true
                                            })
                                        })
                                        .catch(err => {
                                            console.log(err)
                                            alert(err)
                                        })
                                    }
                                }}
                            >
                                Auto Create Exam Now
                            </Button>
                        </Col>

                        {
                            Array.isArray(this.state.upcomingExams) &&
                            <Col md="2">
                                <Input
                                    type="select"
                                    name="selectedPost"
                                    id="selectedPost"
                                    value={this.state.selectedPost || ''}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        this.setState({ selectedPost: value }, () => {
                                            this.getDailyExams()
                                        })
                                    }}
                                >
                                    {[
                                        { _id: "all", name: "All Examinations" },
                                        ...this.state.upcomingExams].map((each, index) => (
                                            <option key={each._id} value={each._id}>
                                                {each.name}
                                            </option>
                                        ))}
                                </Input>
                            </Col>
                        }

                        <Col md="2">
                            <Input
                                checked={this.state.showAttendedCount}
                                onChange={(e) => this.setState({ showAttendedCount: !this.state.showAttendedCount }, () => { this.getDailyExams() })}
                                type="checkbox" />{' '}
                            Show Attended Count
                        </Col>
                    </div>

                    {
                        Array.isArray(this.state.dailyExams) && this.state.dailyExams.length > 0 ?
                            <>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Exam Name</th>
                                            <th>Exam Post</th>
                                            <th>Active/Total Questions</th>
                                            <th>Date</th>
                                            <th>Premium/Free</th>
                                            <th>Posts</th>
                                            {this.state.showAttendedCount ? <th>Users Attended</th> : null}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            this.state.dailyExams.map((each, index) => {
                                                return (
                                                    <tr key={each._id} style={{ background: each.activeFlag === false ? 'rgba(255,0,0,0.2)' : null }}>
                                                        <td>{index + 1}</td>
                                                        <td>{each.description}</td>
                                                        <td>{each.postDetails ? each.postDetails.name : ''}</td>
                                                        <td>{each.activeQuestionsCount}/{each.questionsCount}</td>
                                                        <td>{moment(each.date).format('D MMM YYYY')}</td>
                                                        <td>{each.premiumExam ? "Premium" : "Free"}</td>
                                                        <td>{each.posts ? this.renderPosts(each.posts) : "------"}</td>

                                                        {
                                                            this.state.showAttendedCount ?
                                                                <td>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <span style={{ fontSize: 14 }}>Premium :{each.premiumUsersCount}</span>
                                                                        <span style={{ fontSize: 14 }}>Free :{each.nonPremiumUsersCount}</span>
                                                                        <span style={{ fontSize: 14 }}>Total : {each.nonPremiumUsersCount + each.premiumUsersCount}</span>
                                                                    </div>
                                                                </td>
                                                                : null
                                                        }

                                                        <td>

                                                            <Button
                                                                outline
                                                                color="primary"
                                                                style={{ marginRight: 10 }}
                                                                onClick={() => this.props.history.push(`/exams/edit?mockExamId=${each.examId}`)}
                                                            >
                                                                Go To Exam
                                                            </Button>

                                                            <Button
                                                                outline
                                                                color="primary"
                                                                onClick={() => this.setState({
                                                                    showEditModal: true,
                                                                    editingDailyExamDate: new Date(each.date),
                                                                    editingDailyExamId: each._id,
                                                                    editingExamId: each.examId,
                                                                    editingExamPremiumFlag: each.premiumExam,
                                                                    editingExamPost: each.examPost
                                                                })}
                                                                style={{ marginRight: 10 }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="iconButton"
                                                                    style={{ color: 'blue', }}
                                                                    icon={faPen}
                                                                />
                                                            </Button>
                                                            <Button
                                                                outline
                                                                onClick={() => this.deleteExam(each._id, each.examPost, each.date)}
                                                                color="danger"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="iconButton"
                                                                    style={{ color: 'red' }}
                                                                    icon={each.activeFlag ? faTrash : faReply}
                                                                />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>

                                </Table>

                                <Row style={{ width: '100%', marginTop: 50, paddingBottom: 50, justifyContent: 'center' }}>
                                    <Col md="12" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            outline
                                            onClick={() => this.getDailyExams(true)}
                                            style={{ alignSelf: 'flex-end', paddingHorizontal: 50 }}
                                        >
                                            Load More
                                        </Button>
                                    </Col>
                                </Row>
                            </>

                            :
                            Array.isArray(this.state.dailyExams) && this.state.dailyExams.length === 0 ?
                                <p>No exams found</p>
                                :
                                null
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        posts: state.data.posts
    }
}

export default connect(mapStateToProps, { updatePosts })(DailyExams)
