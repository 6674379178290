import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { login, logout } from '../actions'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import {
    isContentManagerOrHigher,
    isSalesManagerOrHigher,
    isMarketingManagerOrHigher,
    isContentTeam,
    isContentReviewerOrHigher,
    isExamManagerOrHigher,
    isBatchManagerOrHigher,
    isCrashCourseManagerOrHigher,
    isCurrentAffairsManagerOrHigher,
    isAdmin
} from '../utils/dashboardRoles';

class Header extends Component {

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.state = { questionsDropDownOpen: false }
    }

    toggle() { this.setState({ isOpen: !this.state.isOpen }) }

    render() {

        const admin = isAdmin(this.props.roleIds || [])
        const salesManagerOrHigher = isSalesManagerOrHigher(this.props.roleIds || [])
        const contentManagerOrHigher = isContentManagerOrHigher(this.props.roleIds || [])
        const batchManagerOrHigher = isBatchManagerOrHigher(this.props.roleIds || [])
        const crashCourseManagerOrHigher = isCrashCourseManagerOrHigher(this.props.roleIds || [])
        const currentAffairsManagerOrHigher = isCurrentAffairsManagerOrHigher(this.props.roleIds || [])
        const marketingManager = isMarketingManagerOrHigher(this.props.roleIds || [])
        const contentReviewer = isContentReviewerOrHigher(this.props.roleIds || [])
        const contentTeam = isContentTeam(this.props.roleIds || [])
        const examManagerOrHigher = isExamManagerOrHigher(this.props.roleIds || [])

        return (
            <div>
                <Navbar color="light" light expand="md">
                    <NavbarBrand to="/" tag={Link}>Challenger</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>

                            {contentTeam && <NavItem><NavLink to="/subjects/" tag={Link}>Subjects</NavLink></NavItem>}

                            {currentAffairsManagerOrHigher && <NavItem><NavLink to="/daily-challenges/" tag={Link}>Current Affairs</NavLink></NavItem>}

                            {
                                salesManagerOrHigher &&
                                <UncontrolledDropdown nav inNavbar>

                                    <DropdownToggle nav caret>Sales</DropdownToggle>

                                    <DropdownMenu right>
                                        <DropdownItem header>Leads</DropdownItem>


                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/assign-leads/" tag={Link}>Assign Leads</NavLink>
                                            </NavItem>
                                        </DropdownItem>

                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/assigned-leads/" tag={Link}>Assigned Leads</NavLink>
                                            </NavItem>
                                        </DropdownItem>

                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/lead-conversions/" tag={Link}>Leads Conversions</NavLink>
                                            </NavItem>
                                        </DropdownItem>

                                        {/* <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/trial-users/" tag={Link}>Trial Users</NavLink>
                                            </NavItem>
                                        </DropdownItem> */}

                                        <DropdownItem divider />

                                        <DropdownItem header>Reports</DropdownItem>

                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/sales-manager-home/" tag={Link}>Sales Stats</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/sales-report-page/" tag={Link}>Sales Report</NavLink>
                                            </NavItem>
                                        </DropdownItem>

                                        <DropdownItem divider />
                                        <DropdownItem header>Others</DropdownItem>

                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/failed-payments/" tag={Link}>
                                                    Failed Payments
                                                </NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            }

                            {(crashCourseManagerOrHigher) && <NavItem><NavLink to="/learning-path/" tag={Link}>Path</NavLink></NavItem>}

                            {(batchManagerOrHigher) && <NavItem><NavLink to="/batch-days/" tag={Link}>Batches</NavLink></NavItem>}

                            {
                                (admin || marketingManager) &&
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>Users</DropdownToggle>
                                    <DropdownMenu right>
                                        {
                                            admin &&
                                            <>
                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/bots/" tag={Link}>Search</NavLink>
                                                    </NavItem>
                                                </DropdownItem>

                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/name-change/" tag={Link}>Name Change</NavLink>
                                                    </NavItem>
                                                </DropdownItem>

                                            </>
                                        }

                                        {
                                            marketingManager &&
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink to="/users/referral/" tag={Link}>Referral Downloads</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                        }

                                        {
                                            admin &&
                                            <>
                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/battle-history" tag={Link}>Battle History</NavLink>
                                                    </NavItem>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/user-notes" tag={Link}>User Notes</NavLink>
                                                    </NavItem>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/doubts" tag={Link}>Doubts</NavLink>
                                                    </NavItem>
                                                </DropdownItem>
                                            </>
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }

                            {
                                contentTeam &&
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>Question</DropdownToggle>
                                    <DropdownMenu right>
                                        {
                                            contentTeam &&
                                            <>
                                                <DropdownItem header>Questions</DropdownItem>

                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/questions/add/" tag={Link}>
                                                            Add
                                                        </NavLink>
                                                    </NavItem>
                                                </DropdownItem>

                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/questions/" tag={Link}>Search</NavLink>
                                                    </NavItem>
                                                </DropdownItem>

                                                    {
                                                        (contentTeam) &&
                                                        <>
                                                            <DropdownItem>
                                                                <NavItem>
                                                                    <NavLink to="/reported-questions/" tag={Link}>Reported Questions</NavLink>
                                                                </NavItem>
                                                            </DropdownItem>

                                                            <DropdownItem>
                                                                <NavItem>
                                                                    <NavLink to="/reported-slides/" tag={Link}>
                                                                        Reported Notes
                                                                    </NavLink>
                                                                </NavItem>
                                                            </DropdownItem>
                                                        </>
                                                    }

                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/image-upload/" tag={Link}>Image Upload</NavLink>
                                                    </NavItem>
                                                </DropdownItem>

                                                {
                                                    contentTeam &&
                                                    <>
                                                        <DropdownItem>
                                                            <NavItem>
                                                                <NavLink to="/data-entry-reports/" tag={Link}>Content Team Report</NavLink>
                                                            </NavItem>
                                                        </DropdownItem>
                                                    </>
                                                }

                                            </>
                                        }

                                        {
                                            contentReviewer &&
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItem header>Byte Quiz</DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/bytequiz/" tag={Link}>Search</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/bytequiz-reported/" tag={Link}>Reports</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/byte-quiz-leaderboard/" tag={Link}>Leaderboard</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/bytequiz-preferred-topics/" tag={Link}>Preferred Topics</NavLink></NavItem></DropdownItem>
                                            </>
                                        }

                                        <DropdownItem divider />
                                        <DropdownItem header>Others</DropdownItem>
                                        <DropdownItem><NavItem><NavLink to="/paragraphs/" tag={Link}>Paragraphs</NavLink></NavItem></DropdownItem>
                                        
                                            {
                                                admin &&
                                                <DropdownItem>
                                                    <NavItem>
                                                        <NavLink to="/new-user-questions/" tag={Link}>New User Questions</NavLink>
                                                    </NavItem>
                                                </DropdownItem>
                                            }

                                        <DropdownItem><NavItem><NavLink to="/math-editor/" tag={Link}>Math Editor</NavLink></NavItem></DropdownItem>

                                        {
                                            contentManagerOrHigher &&
                                            <>
                                                <DropdownItem><NavItem><NavLink to="/user-questions/" tag={Link}>User Submitted Questions</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/duplicate-questions/1" tag={Link}>Duplicate Questions</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/search-modules/" tag={Link}>Search Modules</NavLink></NavItem></DropdownItem>
                                            </>
                                        }

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }

                            {
                                examManagerOrHigher &&
                                <UncontrolledDropdown nav inNavbar>

                                    <DropdownToggle nav caret>Exams</DropdownToggle>

                                    <DropdownMenu right>

                                        {
                                            contentManagerOrHigher &&
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink to="/posts/" tag={Link}>Job Posts</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                        }

                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/exams/" tag={Link}>Exams</NavLink>
                                            </NavItem>
                                        </DropdownItem>

                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/daily-exams/" tag={Link}>Daily Exam</NavLink>
                                            </NavItem>
                                        </DropdownItem>

                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/topic-exam/" tag={Link}>Topic Exam</NavLink>
                                            </NavItem>
                                        </DropdownItem>

                                        {/* <DropdownItem>
                                            <NavItem>
                                                <NavLink to="/live-exam/" tag={Link}>Live Exam</NavLink>
                                            </NavItem>
                                        </DropdownItem> */}

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }

                            {
                                admin &&
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>Website</DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem><NavItem><NavLink to="/site-notes" tag={Link}>Site Notes</NavLink></NavItem></DropdownItem>
                                        <DropdownItem><NavItem><NavLink to="/popup-manager/" tag={Link}>Popup Manager</NavLink></NavItem></DropdownItem>
                                        <DropdownItem><NavItem><NavLink to="/blog-posts/" tag={Link}>Blog Posts</NavLink></NavItem></DropdownItem>
                                        <DropdownItem><NavItem><NavLink to="/exam-post-pages/" tag={Link}>Exam Post Pages</NavLink></NavItem></DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }

                            {
                                admin &&
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>Analytics</DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem><NavItem><NavLink to="/analytics/" tag={Link}>Analytics</NavLink></NavItem></DropdownItem>
                                        <DropdownItem><NavItem><NavLink to="/most-studied-modules/" tag={Link}>Most Studied Modules</NavLink></NavItem></DropdownItem>
                                        <DropdownItem><NavItem><NavLink to="/most-studied-course-topics/" tag={Link}>Most Studied Course Topics</NavLink></NavItem></DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }

                            
{
                                (admin || contentManagerOrHigher || batchManagerOrHigher) && <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>Others</DropdownToggle>
                                    <DropdownMenu right>

                                        {
                                            admin &&
                                            <>
                                                <DropdownItem header>Admin</DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/awards/" tag={Link}>Awards</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/user-activity/" tag={Link}>Employee Activity</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/edit-home-slider/" tag={Link}>Home Slider</NavLink></NavItem></DropdownItem>
                                            </>
                                        }
                                        
                                        {
                                            (contentManagerOrHigher || batchManagerOrHigher) &&
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItem header>Course</DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/packs-list/" tag={Link}>Packs List</NavLink></NavItem></DropdownItem>
                                                {
                                                    admin &&
                                                        <DropdownItem><NavItem><NavLink to="/course-groups/" tag={Link}>Course Groups</NavLink></NavItem></DropdownItem>
                                                }
                                            </>
                                        }

                                        {
                                            admin &&
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItem header>Pricing</DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/offer-list/" tag={Link}>Offers</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/premium-pricing/" tag={Link}>Premium Values</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/in-app-messages/" tag={Link}>InApp Messages</NavLink></NavItem></DropdownItem>
                                            </>
                                        }

                                        {
                                            contentManagerOrHigher &&
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItem header>Reports</DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/search-history/" tag={Link}>Search History</NavLink></NavItem></DropdownItem>
                                                {
                                                    admin &&
                                                    <>
                                                        <DropdownItem><NavItem><NavLink to="/video-comments/" tag={Link}>Video Comments</NavLink></NavItem></DropdownItem>
                                                        <DropdownItem><NavItem><NavLink to="/reported-chat/" tag={Link}>Reported Chat</NavLink></NavItem></DropdownItem>
                                                        <DropdownItem><NavItem><NavLink to="/recent-otp/" tag={Link}>Recent OTP</NavLink></NavItem></DropdownItem>
                                                    </>
                                                }
                                            </>
                                        }

                                        <DropdownItem header>Others</DropdownItem>

                                        {
                                            contentManagerOrHigher &&
                                            <>
                                                <DropdownItem><NavItem><NavLink to="/html-editor/" tag={Link}>Html editor</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/teachers/" tag={Link}>Teachers List</NavLink></NavItem></DropdownItem>
                                                <DropdownItem><NavItem><NavLink to="/document-upload/" tag={Link}>Upload PDF Document</NavLink></NavItem></DropdownItem>
                                                {
                                                    admin &&
                                                    <>
                                                        <DropdownItem><NavItem><NavLink to="/redis-keys/" tag={Link}>Redis Keys</NavLink></NavItem></DropdownItem>
                                                        <DropdownItem><NavItem><NavLink to="/image/" tag={Link}>Image Editor</NavLink></NavItem></DropdownItem>
                                                        
                                                        <DropdownItem><NavItem><NavLink to="/features-to-index/" tag={Link}>Features To Index</NavLink></NavItem></DropdownItem>
                                                        <DropdownItem><NavItem><NavLink to="/manage-video-image-ads/" tag={Link}>Manage Ads</NavLink></NavItem></DropdownItem>

                                                    </>
                                                }
                                                
                                                <DropdownItem><NavItem><NavLink to="/wrong-type-questions" tag={Link}>
                                                    Wrong Type Question
                                                </NavLink></NavItem></DropdownItem>

                                            </>
                                        }

                                        {
                                            contentManagerOrHigher &&
                                                <DropdownItem><NavItem><NavLink to="/roles-manager/" tag={Link}>Roles Manager</NavLink></NavItem></DropdownItem>
                                        }

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }

                            <NavItem>
                                <NavLink to="/" tag={Link} onClick={() => { this.props.logout(); window.location.href = "/" }}  >Logout</NavLink>
                            </NavItem>

                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleIds: state.auth.roleIds
    }
}

export default connect(mapStateToProps, { login, logout })(Header)