import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = ({ customStyle }) => {
    return (
        <div style={{
            position: 'fixed', 
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            background: 'rgba(0,0,0,0.5)',
            zIndex: 10
        }}>
            <Spinner animation="border" role="status">
                Loading...
            </Spinner>
        </div>
    )
}

export default Loader