import React, { Component } from 'react'
import axios from 'axios'
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import CustomModal from './CustomModal'
import { Button, Table, Input, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, ListGroupItem, Collapse, Spinner, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown, Row } from 'reactstrap';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Checkbox } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faReply, faCopy } from '@fortawesome/free-solid-svg-icons'
import { groupBy } from 'lodash';
import Container from 'reactstrap/lib/Container';
import queryString from 'query-string';
import { toast } from 'react-toastify'

import { updatePosts } from '../actions'
import { isAdmin, isContentManagerOrHigher } from '../utils/dashboardRoles';
import { copyToClipboard } from '../utils';
import Loader from '../Components/Loader'

const initialState = {
    examDate: null,
    examDescription: null,
    newExamPosts: [],
    examCode: null,
    alphaCode: null,
    showEnterAllDataAlert: false,
    showAddExamModal: false,
    showEditExamModal: false,

}

const examTypeList = [
    { _id: 'mock', name: "MOCK" },
    { _id: 'pyq', name: "PYQ" },
    { _id: 'all', name: "All" }
]

class Exams extends Component {
    isDataFetched = false

    state = {
        parentExamId: '', description: '', day: '', month: '', year: '',
        formErrors: { year: '' }, yearValid: false,
        showDialog: false, isError: false, formValid: false,
        editDialog: false, deleteDialog: false, exams: null,
        examCategoryArray: [],
        post: null,
        showDeletedExams: false,
        showEnterAllDataAlert: false,
        newExamPosts: [],
        countDetails: null,
        duplicateExamName: '',
        openIndex: null,
        showExamDuplicateModal: false,
        showExamCreatedModal: false,
        examType: 'mock'
    }

    handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value },
            () => { this.validateField(name, value) })
    }

    handleMenuChange = (name, value) => {
        this.setState({ [name]: value }, () => { this.validateField(name, value) })
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors

        let yearValid = this.state.yearValid

        switch (fieldName) {
            case "year":
                yearValid = value.length > 0
                fieldValidationErrors.year = yearValid && value > 2000 && value < 2020 ? '' : "Please enter valid year"
                break
            default:
                break
        }

        this.setState({ formErrors: fieldValidationErrors, yearValid }, this.validateForm)
    }

    validateForm() {
        this.setState({
            formValid:
                this.state.yearValid
        })
    }

    getPostsWithExam() {
        axios.get(`/posts/exams`)
            .then((response) => this.props.updatePosts(response.data.posts))
            .catch((err) => {
                console.error('/posts/exams')
                console.error(err)
                alert("Error, check console")
            })
    }

    getSubjectWiseQuestionCount(groupedBySubjects) {
        const subjectWiseCount = Object.keys(groupedBySubjects).map((each) => {
            let subjectIndex = this.props.subjects.findIndex(subject => subject._id === each)
            let totalQuestionCount = 0
            let sectionWiseStats = []
            let subjectWiseCaCount = 0
            this.props.subjects[subjectIndex].sections.forEach(section => {
                groupedBySubjects[each].forEach(item => {
                    if (item.sectionId === section._id) {
                        let questionCount = item.questionCount

                        let pushObj = {
                            _id: section._id,
                            sectionName: section.name,
                            questionCount: questionCount,
                            caQuestionsCount: Array.isArray(item.caQuestions) ?  item.caQuestions.length : 0
                        }

                        totalQuestionCount += questionCount
                        subjectWiseCaCount += pushObj.caQuestionsCount
                        sectionWiseStats.push(pushObj)
                    }
                })
            })
            
            return {
                _id: this.props.subjects[subjectIndex]._id,
                subjectName: this.props.subjects[subjectIndex].name,
                subtitle: this.props.subjects[subjectIndex].subtitle,
                sectionWiseStats,
                subjectWiseCaCount,
                totalQuestionCount
            }
        })
        
        return subjectWiseCount
    }

    addSectionWiseCaQuestionCount(questions, dailyChallengeQuestions) {
        let sectionIdWithQuestionCount = questions.map(item => {

            let returnObj = {
                sectionId: item.sectionId,
                type: item.type,
                questionCount: item.questionCount,
                subjectId: item.subjectId,
            }
            
            if (dailyChallengeQuestions.length > 0) {
                dailyChallengeQuestions[0].questions.forEach(each => {
                    if (item._id.sectionId === each.sectionId) {
                        returnObj.questionCount = returnObj.questionCount - 1
                        if (!returnObj.caQuestions) {
                            returnObj.caQuestions = [each._id]
                        } else {
                            returnObj.caQuestions.push(each._id)
                        }
                    }

                })
            }
            
            return returnObj
        })

        return sectionIdWithQuestionCount
    }

    getExamDetailsAndShowExamDuplicateModal(examId) {
        this.setState({ isLoading: true }, () => {
            axios.get('/exam/details', { params: { examId } })
                .then(res => {

                    let sectionIdWithQuestionCount = this.addSectionWiseCaQuestionCount(res.data.questions, res.data.dailyChallengeQuestions)
                    let groupedBySubjects = groupBy(sectionIdWithQuestionCount, (question) => question.subjectId)
                    let subjectWiseCount = this.getSubjectWiseQuestionCount(groupedBySubjects)

                    this.setState({ 
                        sectionIdWithQuestionCount, 
                        subjectWiseCount,
                        duplicateExamPosts: res.data.examData.posts,
                        duplicateExamIds: res.data.examData.examIdsArray,
                        isLoading: false,
                        showExamDuplicateModal: true,
                        duplicateExamName: ''
                    })
                    
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.error('/exam/details')
                    console.error(err)
                    alert("Error, please check console")
                })   
        })
    }

    showQuestionMissingAlert(questionNeeded, questionsFound, sectionId) {

        let subjectName = ''
        let sectionName = ''

        // eslint-disable-next-line array-callback-return
        this.props.subjects.every((subject) => {
            const foundSection = subject.sections.find(section => section._id === sectionId)
            if (foundSection) {
                subjectName = subject.name
                sectionName = foundSection.name
            } else {
                return true
            }
        })

        alert(`No enough questions in ${subjectName} > ${sectionName}\nQuestions Needed: ${questionNeeded}\nQuestions Found: ${questionsFound}`)

    }

    duplicateExam() {
        const { duplicateExamName, duplicateExamPosts, sectionIdWithQuestionCount, duplicateExamIds } = this.state
        
        if (!(duplicateExamName.trim().length > 0)) {
            alert("Please enter exam name")
            return
        }

        let sectionIdWithQuestionCountFiltered = sectionIdWithQuestionCount.filter(item => item.questionCount > 0)

        let paramsObj = {
            name: duplicateExamName,
            posts: duplicateExamPosts,
            sectionIdWithQuestionCount: sectionIdWithQuestionCountFiltered,
            examIds: duplicateExamIds
        }

        this.setState({ isLoading: true }, () => {
            axios.put('/exam/mock', paramsObj)
                .then(res => {
                    const { countDetails, examId } = res.data
                    countDetails.forEach((item,index) => {
                        this.props.subjects.forEach(subject => {
                            const foundSection = subject.sections.find(section => section._id === item.sectionId)
                            if (foundSection) {
                                countDetails[index].subjectName = subject.name
                                countDetails[index].sectionName = foundSection.name
                            } 
                        })
                    })
                    this.setState({ showExamDuplicateModal: false ,showExamCreatedModal: true, countDetails, createdExamId :examId, isLoading: false, duplicateExamName: '' })
                })
                .catch(err => {
                    this.setState({ isLoading: false })

                    if (err && err.response && err.response.data) {
                        const { questionNeeded, questionsFound, sectionId } = err.response.data
                        this.showQuestionMissingAlert(questionNeeded, questionsFound, sectionId)
                    } else {
                        console.error('/exam/mock')
                        console.error(err)
                        alert("Error, check console")
                    }
                })
        })
    }

    getExams() {

        let params = queryString.parse(this.props.location.search)

        let paramsObject = {
            skipCount: (params && params.index )? parseInt(params.index) : 0,
            limit: 50,
            showDeletedExams: this.state.showDeletedExams,
             postId: this.state.post,
              examType: this.state.examType
        }
        
        this.setState({ isLoading: true, isError: false })
        axios.get(`/exams`, { params: paramsObject})
            .then((response) => {
                this.setState({ isLoading: false, exams: response.data.examData , examsCount: response.data.examDataCount})
                this.isDataFetched = true
            })
            .catch((err) => {
                console.error('/exams')
                console.error(err)
                alert("Error, check console")
                this.setState({ isLoading: false })
            })
    }

    deleteExam() {
        axios.delete(`/exam`, { params: { examId: this.state.deleteItemId } })
            .then((response) => {
                this.setState({ examId: '', deleteDialog: false },
                    () => {
                        this.getExams()
                        this.getPostsWithExam()
                    })
            })
            .catch((err) => {
                alert("Error, check console")
                console.error('/exams')
                console.error(err)
            })
    }

    resetForm() {
        this.setState({
            description: '',
            day: '',
            month: '',
            year: '',
            formErrors: { year: '' },
            yearValid: false,
            showDialog: false
        })
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    handleCheckBoxChange(postId) {
        this.setState((oldState) => {
            let foundIndex = oldState.newExamPosts.indexOf(postId)

            if (foundIndex > -1) {
                oldState.newExamPosts.splice(foundIndex, 1);
                return { newExamPosts: oldState.newExamPosts }
            } else {
                oldState.newExamPosts.push(postId)
                return { newExamPosts: oldState.newExamPosts }
            }
        })
    }

    addExam() {
        const { examCode, alphaCode, examDescription, examDay, examMonth, examYear, newExamPosts } = this.state
        if ( examDescription && newExamPosts && examDay && examMonth && examYear && newExamPosts.length > 0 && examCode && alphaCode)
            
           {
            axios.put('/exam', {
                description: examDescription,
                examDate: {
                    day: examDay,
                    month: examMonth,
                    year: examYear
                },
                posts: newExamPosts,
                code: examCode,
                alphaCode: alphaCode
            })
                .then(res => {
                    this.setState({ ...initialState })
                    this.getExams()
                    this.getPostsWithExam()
                })
                .catch(err => {
                    alert("Server error adding exam")
                    console.error('/exam')
                    console.error(err)                
                })
        } else {
            this.setState({ showEnterAllDataAlert: true })
        }
    }

    editExam() {
        const { editExamId, examDay, examMonth, examYear, examDescription, newExamPosts, examCode, alphaCode, editExamApproved } = this.state

        if (
            (editExamId && examDay && examMonth && examYear && examDescription && newExamPosts && newExamPosts.length > 0) &&
            (this.state.examType === 'mock' || (examCode && alphaCode))
        )
         {
            axios.post(`/exam`, {
                examId: editExamId,
                examDate: {
                    day: examDay,
                    month: examMonth,
                    year: examYear
                },
                description: examDescription,
                posts: newExamPosts,
                code: examCode,
                alphaCode: alphaCode,
                approved: editExamApproved === 'approved' ? true : false
            })
                .then((response) => {
                    this.setState({ ...initialState, showEditExamModal: false },
                        () => {
                            this.getExams()
                            this.getPostsWithExam()
                        })
                })
                .catch((err) => {
                    alert("Error, check console")
                    console.error('/exam')
                    console.error(err)                
                })
        } else {
            this.setState({ showEnterAllDataAlert: true })
        }
    }

    fetchExamQuestions(examId) {
        this.props.history.push({ pathname: "/questions", state: { examId } })
    }


    // getDate(date) {
    //     let year = new Date(date).getFullYear()
    //     let month = new Date(date).getMonth() + 1
    //     let day = new Date(date).getDate()
    //     if (month < 10) { month = `0${month}` }
    //     if (day < 10) { day = `0${day}` }
    //     return `${year}-${month}-${day}`
    // }

    toggleApproved(examId, value) {
        axios.post('/exam/toggleApproved', { examId, approved: value })
            .then(res => {
                this.getExams()
                this.getPostsWithExam()
            })
            .catch(err => {
                alert("Error, check console")
                console.error('/exam/toggleApproved')
                console.error(err)
            })
    }
    
    toggleShowInWebsite(examId, value) {
        axios.post('/exam/toggle-show-in-website', { examId, showInWebsite: value })
            .then(res => {
                this.getExams()
                this.getPostsWithExam()
            })
            .catch(err => {
                alert("Error, check console")
                console.error('/exam/toggleApproved')
                console.error(err)
            })
    }

    togglePremium(examId, value) {
        axios.post('/exam/togglePremium', { examId, premium: value })
            .then(res => {
                this.getExams()
                this.getPostsWithExam()
            })
            .catch(err => {
                alert("Error, check console")
                console.error('/exam/togglePremium')
                console.error(err)
            })
    }

    renderPosts(posts) {
        const foundPosts = this.props.posts.filter(e => posts.includes(e._id))
        return foundPosts.map((each) => <p key={each._id}>{each.name}</p>)
    }

    addExamName(examId) {
        this.setState({ isLoading: true }, () => {
            axios.post('/setExamName', { examId })
                .then(() => {
                    this.setState({ isLoading: false })
                })
                .catch(err => {
                    console.error('/setExamName')
                    console.error(err)
                    this.setState({ isLoading: false }, () => {
                        alert("Error setting exam name. Please check console logs")
                    })
                })
        })
    }

    renderExamCreatedModal() {
        let totalNonRepeatCount = 0
        let totalRepeatedCount = 0
        return (
            <Modal isOpen={this.state.showExamCreatedModal} size="lg">
                <ModalHeader>Successfully created exam</ModalHeader>
                <ModalBody>
                    <Form>
                        <div style={{ overflowY: 'scroll', height: 600 }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Subject</th>
                                        <th>Section</th>
                                        <th>New Questions</th>
                                        <th>Repeated Questions</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.countDetails.map((item, index) => {
                                            totalNonRepeatCount +=  item.nonRepeatCount
                                            totalRepeatedCount += item.repeatedCount
                                            return (
                                                <tr key={item.sectionId}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.subjectName}</td>
                                                    <td>{item.sectionName}</td>
                                                    <td>{item.nonRepeatCount}</td>
                                                    <td>{item.repeatedCount}</td>
                                                    <td>{item.nonRepeatCount + item.repeatedCount}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <th>Total</th>
                                        <th></th>
                                        <th></th>
                                        <th>{totalNonRepeatCount}</th>
                                        <th>{totalRepeatedCount}</th>
                                        <th>{totalNonRepeatCount + totalRepeatedCount}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>

                    <Link to={`/exams/edit?mockExamId=${this.state.createdExamId}`}>
                        <Button color="primary">Go to exam</Button>
                    </Link>

                    <Button 
                        onClick={() => { this.setState({ showExamCreatedModal: false }, () => {
                            this.getExams()
                        })}}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderExamDuplicateModal() {

        let totalQuestions = 0
        let totalRemovedCaQuestions = 0

        return (
            <Modal isOpen={this.state.showExamDuplicateModal}>
                <ModalHeader>Duplicate Exam</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Exam Name</Label>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ duplicateExamName: e.target.value })}
                                placeholder={"Enter Exam Name"}
                                value={this.state.duplicateExamName}
                                name={"duplicateExamName"}
                                id={"duplicateExamName"}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Exam Schema</Label>
                            {
                                this.state.subjectWiseCount.map((each, index) => {
                                    
                                    const isOpen = this.state.openIndex === index
                                    totalQuestions += each.totalQuestionCount
                                    totalRemovedCaQuestions += each.subjectWiseCaCount
                                    
                                    return (
                                        <ListGroupItem key={each._id}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                
                                                <p style={{ flex: 2 }}>
                                                    {each.subjectName} { each.subtitle ? <><br /> {each.subtitle}</>: null}
                                                </p>
                                                
                                                <p style={{ flex: 2 }}>{each.totalQuestionCount}</p>
                                                <p style={{ flex: 1, color: 'red' }}>{each.subjectWiseCaCount}</p>
                                                <Button
                                                    size="sm"
                                                    style={{ flex: 1 }}
                                                    color={isOpen ? "primary" : "secondary"}
                                                    onClick={() => this.setState({ openIndex: isOpen ? null : index })}
                                                >
                                                    {isOpen ? "CLOSE" : "OPEN"}
                                                </Button>
                                            </div>
                    
                                            <Collapse isOpen={isOpen}>
                                                {
                                                    each.sectionWiseStats.map(eachSection => {
                                                        return (
                                                            <div key={eachSection._id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <p style={{ margin: 5 }}>{`${eachSection.sectionName}:${eachSection.questionCount}`}</p>
                                                                <p style={{ margin: 5, marginLeft: 2, color: 'red' }}>{`(${eachSection.caQuestionsCount})`}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Collapse>
                    
                                        </ListGroupItem>
                                    )
                                })
                            }

                            <ListGroupItem>
                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                    <p style={{ flex: 2 }}></p>
                                    <p style={{ flex: 2 }}><strong>{totalQuestions}</strong></p>
                                    <p style={{ flex: 1, color: 'red' }}><strong>{totalRemovedCaQuestions}</strong></p>
                                    <p style={{ flex: 1 }}></p>
                                </div>
                            </ListGroupItem>
                        </FormGroup>

                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => this.setState({ showExamDuplicateModal: false })}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.duplicateExam()}
                        color="primary"
                    >
                        { this.state.isLoading ? <Spinner color="light" size='sm' /> : "Create Exam" }
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderLoader() {
        return (
            <div style={{
                position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center',
                height: '100%', width: '100%', background: 'rgba(0,0,0,0.5)', zIndex: 10
            }}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        )
    }

    componentDidMount() {
        this.getExams()
    }

    componentDidUpdate(prevProps) {

        let prevParams = queryString.parse(prevProps.location.search)
        let newParams = queryString.parse(this.props.location.search)

        if (prevParams.index !== newParams.index) {
            this.getExams()
        }
    }

    getParamsIndex = () => {
        let params = queryString.parse(this.props.location.search)
        if (params && params.index) {
            return parseInt(params.index)
        } else {
            return 0
        }
    }

    renderSkipMenu = () => {
        let returnArray = []
        let itemCount = parseInt(this.state.examsCount) / 50

        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={() => this.setState({ skipCount: index }, () => {
                        this.setQueryParams(index)
                    })}
                >
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    setQueryParams = (index) => {
        this.props.history.push({
            pathname: "/exams",
            search: `index=${index}`
        })
        this.getExams()
    }
    render() {

        const addedActions = [
            <FlatButton
                label="Add Another"
                primary={true}
                onClick={() => this.resetForm()}
            />,
            <FlatButton
                label="Home"
                primary={true}
                onClick={() => this.props.history.push('/')}
            />,
            <FlatButton
                label="Close"
                primary={true}
                onClick={() => this.resetForm()}
            />
        ];

        const editActions = [
            <FlatButton
                label="Submit"
                primary={true}
                onClick={this.editExam.bind(this)}
            />,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={() => this.setState({ description: '', editDialog: false })}
            />,
        ];

        const deleteActions = [
            <FlatButton
                label="Confirm"
                primary={true}
                onClick={this.deleteExam.bind(this)}
            />,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={() => this.setState({ deleteDialog: false })}
            />,
        ];



        return (
            <>

                {this.state.isLoading && <Loader />}
                { this.state.showExamDuplicateModal && this.renderExamDuplicateModal() }
                { this.state.showExamCreatedModal && this.renderExamCreatedModal() }

                <Container fluid>

                    <CustomModal
                        isOpen={this.state.showAddExamModal}
                        handleInputChange={this.handleInputChange}
                        fields={
                            [
                                { fieldName: "examDescription", value: this.state.examDescription, placeholder: "Exam Description" },
                                { fieldName: "examDay", value: this.state.examDay, placeholder: "Exam Day", type: "number" },
                                { fieldName: "examMonth", value: this.state.examMonth, placeholder: "Exam Month", type: "number" },
                                { fieldName: "examYear", value: this.state.examYear, placeholder: "Exam Year", type: "number" },
                                { fieldName: "examCode", value: this.state.examCode, placeholder: "Exam Code", type: "text" },
                                { fieldName: "alphaCode", value: this.state.alphaCode, placeholder: "Alpha Code", type: "text" },
                            ]
                        }
                        handleCheckBoxChange={(postId) => this.handleCheckBoxChange(postId)}
                        checkBoxFields={
                            [
                                { fieldName: "posts", value: this.state.newExamPosts, placeholder: "Posts", data: this.props.posts }
                            ]
                        }
                        showEnterAllDataAlert={this.state.showEnterAllDataAlert}
                        onSubmit={() => this.addExam()}
                        onCancel={() => this.setState({ showAddExamModal: false })}
                    />

                    <CustomModal
                        isOpen={this.state.showEditExamModal}
                        handleInputChange={this.handleInputChange}
                        fields={

                            [
                                { fieldName: "examDescription", value: this.state.examDescription, placeholder: "Exam Description" },
                                { fieldName: "examDay", value: this.state.examDay, placeholder: "Exam Day", type: "number" },
                                { fieldName: "examMonth", value: this.state.examMonth, placeholder: "Exam Month", type: "number" },
                                { fieldName: "examYear", value: this.state.examYear, placeholder: "Exam Year", type: "number" },

                                ...(this.state.examType === 'mock') ?
                                    [] 
                                    :
                                    [{ fieldName: "examCode", value: this.state.examCode, placeholder: "Exam Code", type: "text" },
                                     { fieldName: "alphaCode", value: this.state.alphaCode, placeholder: "Alpha Code", type: "text" }]

                            ]
                                
                            
                        }
                        selectFields={[
                            {
                                fieldName: "editExamApproved",
                                value: this.state.editExamApproved,
                                data: [
                                    { _id: "approved", name: "Approved" },
                                    { _id: "nonapproved", name: "Not Approved" }
                                ]
                            }
                        ]}
                        handleCheckBoxChange={(postId) => this.handleCheckBoxChange(postId)}
                        checkBoxFields={
                            [
                                { fieldName: "posts", value: this.state.newExamPosts, placeholder: "Posts", data: this.props.posts }
                            ]
                        }
                        showEnterAllDataAlert={this.state.showEnterAllDataAlert}
                        onSubmit={() => this.editExam()}
                        onCancel={() => this.setState({ showEditExamModal: false })}
                    />

                    <Dialog
                        title="Success"
                        actions={addedActions}
                        modal={true}
                        open={this.state.showDialog}
                    >
                        Exam Added Successfully
                    </Dialog>

                    <Dialog
                        title="Rename Exam"
                        actions={editActions}
                        modal={true}
                        open={this.state.editDialog}
                    >
                        <TextField
                            name="description"
                            type="text"
                            hintText="Description"
                            style={{ margin: '15px' }}
                            errorText={this.state.formErrors.description}
                            onChange={this.handleInput}
                            value={this.state.description}
                        />

                        <TextField
                            name="day"
                            type="number"
                            hintText="Day"
                            style={{ margin: '15px' }}
                            errorText={this.state.formErrors.day}
                            onChange={this.handleInput}
                            value={this.state.day}
                        />

                        <TextField
                            name="month"
                            type="number"
                            hintText="Month"
                            style={{ margin: '15px' }}
                            errorText={this.state.formErrors.month}
                            onChange={this.handleInput}
                            value={this.state.month}
                        />
                        <TextField
                            name="year"
                            type="number"
                            hintText="Year"
                            style={{ margin: '15px' }}
                            errorText={this.state.formErrors.year}
                            onChange={this.handleInput}
                            value={this.state.year}
                        />
                    </Dialog>

                    <Dialog
                        title="Delete Exam"
                        actions={deleteActions}
                        modal={true}
                        open={this.state.deleteDialog}
                    >
                        Are you sure you want to delete or undelete this exam?
                    </Dialog>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: "flex" }}>
                            <Button
                                outline
                                style={{ margin: 10 }}
                                color="primary"
                                onClick={() => this.setState({ showAddExamModal: true })}>
                                + Add New PYQ
                            </Button>
                            <Button
                                outline
                                style={{ margin: 10 }}
                                color="primary"
                                onClick={() => this.props.history.push({ pathname: "/newmockexam", })}>
                                + Add New Mock Exam
                            </Button>
                            <Button
                                outline
                                style={{ margin: 10 }}
                                color="primary"
                                onClick={() => this.props.history.push({ pathname: "/new-path-exam", })}>
                                + Add Path Exam
                            </Button>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Input
                                type="select"
                                name="examType"
                                id="examType"
                                value={this.state.examType}
                                onChange={(e) => {
                                    this.setState({ examType: e.target.value }, () => {
                                        this.getExams()
                                    })
                                }}
                                style={{ margin: 10 }}
                            >
                                {
                                    examTypeList.map((item) => {
                                        return (
                                            <option key={item._id} value={item._id}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            <Input
                                type="select"
                                name="post"
                                id="post"
                                value={this.state.post || ''}
                                onChange={(e) => {
                                    const value = e.target.value
                                    if (value !== "selectPost") {
                                        this.setState({ post: value }, () => this.getExams())
                                    } else {
                                        this.setState({ post: null }, () => this.getExams())
                                    }
                                }}
                                style={{ margin: 10 }}
                            >
                                {[{ _id: "selectPost", name: "All Posts" }, ...this.props.posts].map((each, index) => {
                                    return <option
                                        key={each._id}
                                        value={each._id}
                                    >
                                        {each.name}
                                    </option>
                                })
                                }
                            </Input>
                        </div>
                    </div>

                    <Row style={{ justifyContent:'space-between'}}>

                    <div style={{ display: 'flex', margin: 10, marginLeft: 30 }}>
                        <Input
                            type="checkbox"
                            value={this.state.showDeletedExams}
                            checked={this.state.showDeletedExams}
                            onChange={(e) => {
                                this.setState({ showDeletedExams: e.target.checked }, () => this.getExams())
                            }}
                        />
                        <label>Show Deleted Exams</label>
                    </div>
                    <div style={{marginRight: 25}}>

                    <UncontrolledDropdown>
                            <DropdownToggle caret
                                color="success" id="dropdown-basic"    
                            > 
                                Page {this.getParamsIndex() + 1}
                            </DropdownToggle>
                            <DropdownMenu className="addScroll">
                                {this.renderSkipMenu()}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    </Row>

                    {
                        this.state.exams && this.state.exams.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No:</th>
                                        <th>Description</th>
                                        {this.state.examType === "all" && <th>Type</th>}
                                        <th>Date</th>
                                        <th>Posts</th>
                                        {
                                            (this.state.examType === "pyq" || this.state.examType === "all") &&
                                            <>
                                                <th>Exam Code</th>
                                                <th>Alpha Code</th>
                                            </>
                                        }
                                        {isAdmin(this.props.auth.roleIds) && <th>Show in Website</th>}
                                        {isContentManagerOrHigher(this.props.auth.roleIds) && <th>Show in App</th>}
                                        {isContentManagerOrHigher(this.props.auth.roleIds) && <th>Premium</th>}
                                        <th>Questions</th>
                                        <th>Attended Users</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.exams.map((exam, index) => {
                                            return (
                                                <tr key={index} style={{ background: !(exam.active) ? 'rgba(255,0,0,0.2)' : null }}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {exam.description}<br/>
                                                        {exam.createdBy ? <span style={{ fontSize: 14, color: '#525252' }}>Created By: {exam.createdBy}</span> : ""}
                                                    </td>
                                                    {this.state.examType === "all" && <td> {exam.examType ? exam.examType.toUpperCase() : null} </td>}
                                                    <td> {new Date(exam.date).toLocaleDateString()}</td>
                                                    <td> {exam.posts ? this.renderPosts(exam.posts) : "------"}</td>

                                                    {
                                                        (this.state.examType === "pyq" || this.state.examType === "all") &&
                                                        <>
                                                            <td> {exam.code}</td>
                                                            <td> {exam.alphaCode}</td>
                                                        </>
                                                    }

                                                    {isAdmin(this.props.auth.roleIds) && <td>
                                                        <Checkbox
                                                            label="Show"
                                                            checked={exam.showInWebsite}
                                                            onCheck={(e, value) => this.toggleShowInWebsite(exam._id, value)}
                                                        />
                                                    </td>}

                                                    {isContentManagerOrHigher(this.props.auth.roleIds) && <td>
                                                        <Checkbox
                                                            label="Show"
                                                            checked={exam.approved}
                                                            onCheck={(e, value) => this.toggleApproved(exam._id, value)}
                                                        />
                                                    </td>}

                                                    {isContentManagerOrHigher(this.props.auth.roleIds) && <td>
                                                        <Checkbox
                                                            label="Premium"
                                                            checked={exam.premium}
                                                            onCheck={(e, value) => this.togglePremium(exam._id, value)}
                                                        />
                                                    </td>}

                                                    <td>{exam.questions}</td>
                                                    <td>{exam.attendedUsers}</td>

                                                    <td>

                                                        <Button
                                                            outline
                                                            color="primary"
                                                            style={{ marginRight: 5 }}
                                                            onClick={() => this.props.history.push(`/exams/edit/?mockExamId=${exam._id}`)}>
                                                            Questions
                                                        </Button>

                                                        <Button
                                                            outline
                                                            color="primary"
                                                            style={{ marginRight: 5 }}
                                                            onClick={() => this.getExamDetailsAndShowExamDuplicateModal(exam._id) }
                                                        >
                                                            Duplicate
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                copyToClipboard(exam._id)
                                                                toast("ID copied", {
                                                                    position: toast.POSITION.TOP_CENTER,
                                                                    hideProgressBar: true
                                                                })
                                                            }}
                                                            outline
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                style={{ color: 'grey' }}
                                                                icon={faCopy}
                                                            />
                                                        </Button>

                                                        <Button
                                                            style={{ marginRight: 5, marginLeft: 5 }} outline color="primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editExamId: exam._id,
                                                                    examDescription: exam.description,
                                                                    examDay: new Date(exam.date).getDate(),
                                                                    examMonth: new Date(exam.date).getMonth() + 1,
                                                                    examYear: new Date(exam.date).getFullYear(),
                                                                    newExamPosts: exam.posts ? exam.posts : [],
                                                                    examCode: exam.code,
                                                                    alphaCode: exam.alphaCode,
                                                                    editExamApproved: exam.approved ? "approved" : "nonapproved"
                                                                },
                                                                    () => { this.setState({ showEditExamModal: true }) })
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </Button>

                                                        <Button outline color="danger" onClick={() => this.setState({ deleteItemId: exam._id }, () => this.setState({ deleteDialog: true }))} >
                                                            <FontAwesomeIcon icon={exam.active ? faTrash : faReply} />
                                                        </Button>

                                                    </td>

                                                </tr>)
                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            <p style={{ textAlign: 'center', marginTop: 100 }}>{this.isDataFetched ? 'No Exams Found' : 'Please choose exam type and post to fetch exam data'}</p>
                    }
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        posts: state.data.posts,
        subjects:state.data.subjects,
    }
}

export default connect(mapStateToProps, { updatePosts })(Exams)