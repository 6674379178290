import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './store'

import axios from 'axios'
import { URL } from './config/env'
axios.defaults.baseURL = URL

axios.interceptors.response.use((response) => {
    return response
  }, function (error) {

    if (error.response && error.response.status === 423) {
        alert("You do not have permission for this action\nPlease contact admin if you need this permission")
    }
  
    return Promise.reject(error);
  });

const app = (
        <MuiThemeProvider>
            <HashRouter>
                <PersistGate persistor={persistor} loading={null}>
                    <Provider store={store}>
                        <ToastContainer />
                        <App />
                    </Provider>
                </PersistGate>
            </HashRouter>
        </MuiThemeProvider>
)

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
