import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { faTrash, faReply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { toast } from 'react-toastify'

import Loader from '../Components/Loader'

function Doubts() {

    const [isLoading, setIsLoading] = useState(true)
    const [doubts, setDoubts] = useState([])
    const [replies, setReplies] = useState(null)
    const [replyViewingDoubtId, setReplyViewingDoubtId] = useState(null)

    useEffect(() => {
        getDoubts(true)
    }, [])

    useEffect(() => {
        if (replyViewingDoubtId) {
            fetchReplies(replyViewingDoubtId)
        }
    }, [replyViewingDoubtId])

    const getDoubts = (reset) => {
        setIsLoading(true)

        Axios.get('/doubt/admin/all', { params: { skip: reset ? 0 : doubts.length } })
            .then(res => {
                if (reset) {
                    setDoubts(res.data.doubts)
                } else {
                    setDoubts([...doubts, ...res.data.doubts])
                }
                setIsLoading(false)
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })
    }

    const deleteDoubt = (doubtId) => {
        setIsLoading(true)

        Axios.delete('/doubt/admin/delete', { params: { doubtId } })
            .then(() => {
                getDoubts(true)
                toast("Doubt deleted", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })

    }
    
    const fetchReplies = (doubtId) => {

        setIsLoading(true)

        Axios.get('/doubt/admin/replies', { params: { doubtId } })
            .then(res => {
                setReplies(res.data.replies)
                setIsLoading(false)
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })

    }

    const deleteReply = (replyId) => {
        setIsLoading(true)

        Axios.delete('/doubt/admin/delete-reply', { params: { replyId } })
            .then(() => {
                fetchReplies(replyViewingDoubtId)
                toast("Reply deleted", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Server error. Check console")
            })

    }

    return (
        <>
            {isLoading && <Loader />}

            {
                Array.isArray(replies) ?
                    <Modal
                        size="lg"
                        isOpen={true}
                    >

                        {isLoading && <Loader />}

                        <ModalHeader>
                            Replies
                        </ModalHeader>

                        <ModalBody>
                            <Table>
                                <tbody>
                                    {
                                        replies.map(reply => {
                                            return (
                                                <React.Fragment key={reply._id}>
                                                    <tr
                                                        style={{ background: reply.activeFlag ? 'transparent' : 'rgba(255,0,0,0.1)' }}
                                                    >
                                                        <td colSpan={3}>{reply.text}</td>
                                                        <td colSpan={2}>{moment(reply.dateTime).format('DD MMM YYYY hh:mm A')}</td>
                                                        <td colSpan={2}>{reply.userDetails.name}</td>
                                                        <td>
                                                            <Button
                                                                outline
                                                                color="danger"
                                                                onClick={() => deleteReply(reply._id)}
                                                            >
                                                                <FontAwesomeIcon icon={reply.activeFlag ? faTrash : faReply} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    {
                                                        Array.isArray(reply.replies) && reply.replies.map(nestedReply => {
                                                            return (
                                                                <tr
                                                                    key={nestedReply._id}
                                                                    style={{ background: nestedReply.activeFlag ? 'transparent' : 'rgba(255,0,0,0.1)' }}
                                                                >
                                                                    <td colSpan={1}></td>
                                                                    <td colSpan={2}>{nestedReply.text}</td>
                                                                    <td colSpan={2}>{moment(nestedReply.dateTime).format('DD MMM YYYY hh:mm A')}</td>
                                                                    <td colSpan={2}>{nestedReply.userDetails.name}</td>
                                                                    <td>
                                                                        <Button
                                                                            outline
                                                                            color="danger"
                                                                            onClick={() => deleteReply(nestedReply._id)}
                                                                        >
                                                                            <FontAwesomeIcon icon={nestedReply.activeFlag ? faTrash : faReply} />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={() => {
                                setReplies(null)
                                setReplyViewingDoubtId(null)
                            }}>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
            }

            <Container style={{ marginTop: 20 }}>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Post</th>
                                <th>User Name</th>
                                <th>Date</th>
                                <th>Likes</th>
                                <th>Replies</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                doubts.map((doubt, index) => {
                                    return (
                                        <tr
                                            key={doubt._id}
                                            style={{ background: doubt.activeFlag ? 'transparent' : 'rgba(255,0,0,0.1)' }}
                                        >
                                            <td>{index + 1}</td>
                                            <td>{doubt.text}</td>
                                            <td>{moment(doubt.dateTime).format('DD MMM YYYY hh:mm A')}</td>
                                            <td>{doubt.userDetails.name}</td>
                                            <td>{doubt.likesCount}</td>
                                            <td>{doubt.repliesCount}</td>
                                            <td>
                                                <Button
                                                    outline
                                                    color="primary"
                                                    style={{ marginRight: '5px' }}
                                                    onClick={() => setReplyViewingDoubtId(doubt._id)}
                                                >
                                                    Replies
                                                </Button>
                                                <Button
                                                    outline
                                                    color="danger"
                                                    onClick={() => deleteDoubt(doubt._id)}
                                                >
                                                    <FontAwesomeIcon icon={doubt.activeFlag ? faTrash : faReply} />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}

export default Doubts
