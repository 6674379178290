import { LOGIN, LOGOUT } from '../config/types'

const initialState = { _id:'', email:'', name:'' }

export default (state = initialState, action) => {
    switch(action.type) {
        case LOGIN:
            return {
                ...state,
                _id: action.payload._id,
                email: action.payload.email,
                name: action.payload.name,
                roleIds: action.payload.roleIds
            }
        case LOGOUT:
            return { ...initialState }
        default:
            return state
    }
}