import React, { Component } from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row, Input, Button, ListGroup, ListGroupItem,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import RaisedButton from 'material-ui/RaisedButton'
import { connect } from 'react-redux'
import Aux from '../Components/Auxiliary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { isContentReviewerOrHigher } from '../utils/dashboardRoles'
import moment from 'moment';
import Loader from '../Components/Loader'

const reportTypes = [
    {
        id:"1",
        label:"Typo",
        message:"Showing: Typo in question or answer"
    },
    {
        id:"2",
        label:"Wrong Answer",
        message:"Showing: Wrong answers"
    },
    {
        id:"3",
        label:"Outdated Question",
        message:"Showing: Outdated question"
    },
    {
        id:"4",
        label:"Others",
        message:"Showing: Other reports"
    }
]
class ByteQuizReported extends Component {

    state = { 
        data: [], 
        page: 0, 
        skipCount:0,
        isLoading: false,
        issueType: 2, 
        usersDetails: null,
        currentIndex: null,
        message: "Showing: Wrong answers",
        sortByDate: true,
        totalQuestionsCount: 50,
     }

    getUser =(index)=> {
        
        let userIds = this.state.reportedQuestions[index].userExplanation.map(item => item.userId)

        axios.get(`/get-users-details`, { params: { userIds: userIds, requiredDetails: { name: 1, mobile: 1 } } })
            .then((response) => { this.setState({ usersDetails: response.data.usersDetails }) })
            .catch((error) => { 
                console.log(error.response) 
                alert("Error. Please check console")    
            })
    }

    fetchReports(issueType) {
        this.setState({ isLoading: true,issueType }, () => {

            axios.get(`/bytequiz/reported-questions`, { params: { type: issueType, sortByDate: this.state.sortByDate,skipCount: this.state.page*50 } })
                .then(response => {
                    this.setState({ 
                        reportedQuestions: response.data.reportedQuestions, 
                        totalQuestionsCount: response.data.totalQuestionCount,
                        isLoading: false
                    })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                })

        })
    }

    renderSkipMenu =()=> {
        
        let returnArray = []
        let itemCount =  this.state.totalQuestionsCount / 50
     
        for (let index = 0; index < itemCount; index++) {
            returnArray.push(
                <DropdownItem
                    key={index}
                    value={index}
                    onClick={
                        ()=> this.setState({ page: index },
                        ()=>this.fetchReports(this.state.issueType))
                }>
                    {index + 1}
                </DropdownItem>)
        }

        return returnArray
    }

    deleteReport(deleteItemId) {
        this.setState({ isLoading:true }, () => {
            axios.post(`/bytequiz/deleteReport`, { reportId: deleteItemId })
            .then((response) => {
                this.setState({ isLoading: false, deleteDialog: false, deleteItemId: '' })
                this.fetchReports(this.state.issueType)
            })
            .catch((error) => {
                this.setState({ isLoading:false })
                console.log(error.response)
            })
        })
    }

    componentDidMount() {
        this.fetchReports(this.state.issueType)
    }
    
    renderSelections() {
        return (
            <Row style={{ marginTop: 50, marginBottom: 10, flex: 1, justifyContent: 'flex-end' }}>
                <Col md="9">
                    {reportTypes.map(eachType => (
                        <RaisedButton
                            key={eachType.id}
                            label={eachType.label}
                            onClick={() => {
                                this.setState({ message: eachType.message, pageNumber: 0 }, () => {
                                    this.fetchReports(parseInt(eachType.id))
                                })
                            }}
                        />
                    ))}
                    <p style={{ marginTop:10 }}>{this.state.message}</p>
                </Col>
                <Col md="1">
                    <Input
                        checked={this.state.sortByDate}
                        onChange={(e) => this.setState({ sortByDate:!this.state.sortByDate }, () => {
                            this.fetchReports(parseInt(this.state.issueType))
                        })}
                        type="checkbox" />{' '}
                            Sort by date
                </Col>
                <Col md="2">
                    <UncontrolledDropdown>
                        <DropdownToggle caret block color="success" id="dropdown-basic"> Page {this.state.page + 1} </DropdownToggle>
                        <DropdownMenu className="addScroll">
                            {this.renderSkipMenu()}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
            </Row>
        )
    }

    render() {

        if (this.state.reportedQuestions && this.state.reportedQuestions.length <= 0) {
            return (
                <Container fluid>

                    {this.renderSelections()}

                    <Row style={{ justifyContent: 'center', paddingTop: 100 }}>
                        <h1>No questions</h1>
                    </Row>
                </Container>
            )
        }
        return (
            <Aux>

                { this.state.isLoading && <Loader />}

                <Container fluid>

                    {this.renderSelections()}

                    <Row style={{ marginTop: 50 }}>
                        <Col>
                            {
                                this.state.reportedQuestions && this.state.reportedQuestions.length > 0 &&
                                < Table >
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Question</th>
                                            <th>User Explanation</th>
                                            <th>Option A</th>
                                            <th>Option B</th>
                                            <th>Option C</th>
                                            <th>Option D</th>
                                            <th>Correct Option</th>
                                            <th>Count</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.reportedQuestions.map((each, index) => {
                                                return (
                                                    <Aux key={each._id}>
                                                        <tr key={each._id}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {each.question.question}<br />
                                                            Quiz Name: {each.quizName}
                                                            </td>
                                                            <td style={{ fontSize: 15 }}>
                                                                <Button
                                                                    color="primary"
                                                                    outline
                                                                    disabled={!each.userExplanation}
                                                                    onClick={() => {
                                                                        if (this.state.currentIndex === index) {
                                                                            this.setState({ currentIndex: null, usersDetails: null })
                                                                        } else {
                                                                            this.setState({ currentIndex: index, usersDetails: null })
                                                                        }
                                                                    }}
                                                                    style={{ marginBottom: '1rem' }}
                                                                >
                                                                    {(this.state.currentIndex !== null && this.state.currentIndex === index) ? "Hide Explanation" : "Show Explanation"}
                                                                </Button>
                                                            </td>
                                                            <td>{each.question.options[0].text}</td>
                                                            <td>{each.question.options[1].text}</td>
                                                            <td>{each.question.options[2].text}</td>
                                                            <td>{each.question.options[3].text}</td>
                                                            <td>{each.question.answer}</td>
                                                            <td>{each.count}</td>
                                                            <td>
                                                                {isContentReviewerOrHigher(this.props.auth.roleIds) &&
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        className="iconButton"
                                                                        onClick={() => {

                                                                            this.props.history.push({
                                                                                pathname: "/bytequiz", state: {
                                                                                    quizId: each.quizId,
                                                                                    questionId: each.question._id
                                                                                }
                                                                            })
                                                                        }}
                                                                        style={{ color: '#546beb' }}
                                                                    />
                                                                }
                                                            </td>
                                                            <td>
                                                                {isContentReviewerOrHigher(this.props.auth.roleIds) &&
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="iconButton"
                                                                        onClick={() => {
                                                                            if (window.confirm('Delete the report?')) {
                                                                                this.deleteReport(each._id)
                                                                            }
                                                                        }}
                                                                        style={{ color: '#db1616' }}
                                                                    />
                                                                }
                                                            </td>
                                                        </tr>

                                                        {
                                                            (this.state.currentIndex === index && Array.isArray(each.userExplanation) && each.userExplanation.length > 0) &&
                                                            <tr>
                                                                <td colSpan={11}>
                                                                <div style={{display:"flex", justifyContent: 'flex-end', marginBottom: 10}}>
                                                                    <Button outline color='primary' onClick={()=>this.getUser(index)}>Get Users</Button>
                                                                </div>
                                                                <ListGroup>
                                                                   {
                                                                       each.userExplanation.map(item => {
                                                                          if (this.state.usersDetails) {
                                                                              this.state.usersDetails.forEach(each => {
                                                                                  if (each._id === item.userId) {
                                                                                      item.name = each.name
                                                                                      item.mobileNumber = each.mobile
                                                                                  }
                                                                              })
                                                                          }
                                                                      
                                                                           return(
                                                                               <ListGroupItem key={item._id}>
                                                                                    <div style={{display: "flex", justifyContent: 'space-between'}}>
                                                                                        <div>{item.explanation}</div>
                                                                                        {item.userReportedDate ? <div>Date: {moment(item.userReportedDate).format("D MMM YYYY, h:mm:ss a")}</div> : null}
                                                                                        {this.state.usersDetails ? 
                                                                                            <div style={{display: 'flex'}}>
                                                                                                <div>{`Name :`}</div>
                                                                                                <strong>{item.name},</strong>
                                                                                                <div style={{marginLeft: 5 }}>{`Mobile :`}</div>
                                                                                                <strong>{item.mobileNumber}</strong>
                                                                                            </div> 
                                                                                            : 
                                                                                            null
                                                                                        }
                                                                                    </div>
                                                                               </ListGroupItem>
                                                                           )
                                                                       })
                                                                   }
                                                               </ListGroup>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </Aux>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            }
                        </Col>
                    </Row>
                </Container>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth:state.auth,
    }
}

export default connect(mapStateToProps)(ByteQuizReported)
