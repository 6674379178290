import React, { Component } from 'react'
import axios from 'axios'
import {
    Button, Card, Container, Row, Col, Table,
    Badge, ListGroup, ListGroupItem, Collapse, CardBody, Input,
    FormGroup, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter,
    DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown
} from 'reactstrap';
import { connect } from 'react-redux'

import Loader from '../Components/Loader'
import Aux from '../Components/Auxiliary'
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';

class NewPathExam extends Component {

    state = {
        pathData: null,
        showDialog: false,
        postUnitsToFetchId: null,
        postUnitsToFetchName: null,
        showPostsModals: false,
        selectedPosts: [],
        countDetails: [],
        updatedPostsArray: [],
        isLoading: false
    }

    handleCheckBoxChange(postId, index) {

        this.setState((oldState) => {

            let foundIndex = oldState.updatedPostsArray.indexOf(postId)

            if (foundIndex > -1) {
                oldState.updatedPostsArray.splice(foundIndex, 1);
                return { updatedPostsArray: oldState.updatedPostsArray }
            } else {
                oldState.updatedPostsArray.push(postId)
                return { updatedPostsArray: oldState.updatedPostsArray }
            }

        })
    }

    submitButton(postUnitsToFetchId) {

        let selectedPosts = []
        let postObject = this.state.posts.find(post=> post._id === postUnitsToFetchId)
        if (postObject){
            let postId= postObject._id
            selectedPosts=[postId]
        }

        this.setState({ isLoading: true }, () => {
            axios.get("/admin/learning-path/all", { params: { postId: postUnitsToFetchId } })
                .then(res => {
                    this.setState({ pathData: res.data.learningPaths, isLoading: false, selectedPosts })
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error, check console")
                })
        })
    }

    componentDidMount() {

        const activeposts = this.props.posts.filter(post => (post.active === true && post.approved === true))
        this.setState({ posts: activeposts })

    }

    showQuestionMissingAlert(questionNeeded, questionsFound, topicId) {

        let moduleName = ''
        let topicName = ''

        // eslint-disable-next-line array-callback-return
        this.state.pathData.every((eachModule) => {
            const foundSection = eachModule.topics.find(section => section._id === topicId)
            if (foundSection) {
                moduleName = eachModule.unitName
                topicName = foundSection.name
            } else {
                return true
            }
        })

        alert(`No enough questions in ${moduleName} > ${topicName}\nQuestions Needed: ${questionNeeded}\nQuestions Found: ${questionsFound}`)

    }

    submitMockexam = () => {

        let examIdsArray = []

        if (this.state.examIds) {
            let text = this.state.examIds
            let splittedExamIds = text.split("\n")
            examIdsArray = splittedExamIds.map(each => each.replace(/\s/g, ''))
        }

        let topicIdWithQuestionCount = []

        this.state.pathData.forEach((eachSubject, index) => {
            eachSubject.topics.forEach((eachTopic, index) => {
                if (eachTopic.numOfQuestions) {
                    let pushObject = {
                        topicId: eachTopic._id,
                        questionCount: eachTopic.numOfQuestions
                    }
                    if (eachSubject.languageId) {
                        pushObject.languageId = eachSubject.languageId
                    }
                    topicIdWithQuestionCount.push(pushObject)
                }
            })
        })

        if (!this.state.mockExamName) {
            alert(`Please enter a mock exam name.`)
        } else if (this.state.selectedPosts.length < 1) {
            alert(`Please select at least one examination post.`)
        } else {
            this.setState({ isLoading: true })
            
            axios.put('/exam/path-mock-test', {
                name: this.state.mockExamName,
                posts: this.state.selectedPosts,
                topicIdWithQuestionCount,
                postId: this.state.postUnitsToFetchId,
                examIds: examIdsArray
            })
                .then(res => {
                    const { countDetails, examId } = res.data
                    countDetails.forEach((item, index) => {
                        this.state.pathData.forEach(subject => {
                            const foundSection = subject.topics.find(section => section._id === item.topicId)
                            if (foundSection) {
                                countDetails[index].topicName = foundSection.name
                            }
                        })
                    })
                    this.setState({ showDialog: true, countDetails, examId, isLoading: false })
                })
                .catch(err => {

                    if (err && err.response && err.response.data) {
                        this.showQuestionMissingAlert(
                            err.response.data.questionNeeded,
                            err.response.data.questionsFound,
                            err.response.data.topicId
                        )
                    } else {
                        console.log(err)
                        alert('Error. Please check console')
                    }
                    this.setState({ isLoading: false })

                })
        }

    }

    render() {        

        let totalSelectedQuestions = 0
        let totalNonRepeated = 0
        let totalRepeated = 0

        return (
            <Aux>

                {this.state.isLoading && <Loader />}

                <Modal
                    isOpen={this.state.showDialog}
                    size="lg"
                >
                    <ModalBody style={{ overflowY: 'scroll', height: 600 }}>
                        <h2>Successfuly created path mock exam</h2>
                        <Table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Topic Name</th>
                                    <th>New Questions</th>
                                    <th>Repeated Questions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.countDetails.map((item, index) => {
                                        totalNonRepeated += item.nonRepeatCount
                                        totalRepeated += item.repeatedCount
                                        return (
                                            <tr key={item.topicName}>
                                                <td>{index + 1}</td>
                                                <td>{item.topicName}</td>
                                                <td>{item.nonRepeatCount}</td>
                                                <td>{item.repeatedCount}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <th></th>
                                    <th>Total</th>
                                    <th>{totalNonRepeated}</th>
                                    <th>{totalRepeated}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        
                        <Link
                            to={`/exams/edit/?mockExamId=${this.state.examId}`}
                            target="_blank"
                        >
                            <Button color="primary">Go to Exam</Button>
                        </Link>

                        <Link to="/exams/">Close</Link>

                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showPostsModals}>
                    <ModalHeader>
                        Select Examination Posts
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <div>
                                {
                                    this.state.posts && this.state.posts.map((post, index) => {
                                        return (
                                            <CustomInput
                                                id={post._id}
                                                key={post._id}
                                                value={post._id}
                                                type="checkbox"
                                                label={post.name}
                                                onChange={(event) => this.handleCheckBoxChange(post._id)}
                                                checked={this.state.updatedPostsArray.includes(post._id)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            color="primary"
                            onClick={() => this.setState({ selectedPosts: this.state.updatedPostsArray, updatedPostsArray: [], showPostsModals: false })}>
                            SUBMIT
                        </Button>
                        <Button
                            outline
                            color="secondary"
                            onClick={() => this.setState({ updatedPostsArray: [], showPostsModals: false })}>
                            CANCEL
                        </Button>
                    </ModalFooter>
                </Modal>


                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <Col md="3">
                            <UncontrolledDropdown>

                                <DropdownToggle caret color="primary" block>
                                    {(this.state.postUnitsToFetchId && this.state.postUnitsToFetchName) ? this.state.postUnitsToFetchName : "Select Post"}
                                </DropdownToggle>

                                <DropdownMenu className="addScroll">
                                    {
                                        this.props.packs.map((each) => {
                                            return (
                                                <DropdownItem
                                                    key={each._id}
                                                    value={each.title}
                                                    onClick={() => this.setState({
                                                        postUnitsToFetchId: each.postId,
                                                        postUnitsToFetchName: each.title
                                                    }, () => {
                                                        this.submitButton(this.state.postUnitsToFetchId)
                                                    })}
                                                >
                                                    {each.title}
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>

                            </UncontrolledDropdown>

                        </Col>
                    </Row>

                    {
                        Array.isArray(this.state.pathData) && this.state.pathData.length > 0 ?
                            <Row style={{ marginTop: 50 }}>
                                <Col md="8">
                                    <ListGroup>
                                        {
                                            this.state.pathData.map((each, pathIndex) => {

                                                const isOpen = this.state.openIndex === pathIndex

                                                let totalQuestions = 0

                                                each.topics.forEach(eachTopic => {
                                                    if (eachTopic.numOfQuestions) {
                                                        totalQuestions += eachTopic.numOfQuestions
                                                        totalSelectedQuestions += eachTopic.numOfQuestions
                                                    }
                                                });

                                                return (
                                                    <ListGroupItem action key={each._id} >

                                                        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                            <p style={{ flex: 2 }}>
                                                                {each.unitName}
                                                            </p>
                                                            <p style={{ flex: 1 }}>{totalQuestions} Questions</p>
                                                            <Button
                                                                size="sm"
                                                                style={{ flex: 1 }}
                                                                color={isOpen ? "primary" : "secondary"}
                                                                onClick={() => this.setState({ openIndex: isOpen ? null : pathIndex })}
                                                            >
                                                                {isOpen ? "CLOSE" : "OPEN"}
                                                            </Button>
                                                        </div>

                                                        {
                                                            isOpen &&
                                                            <Collapse isOpen={isOpen} style={{ marginTop: 10 }}>
                                                                {
                                                                    each.topics.map((each, moduleIndex) => {
                                                                        return (
                                                                            <Card key={each._id} >
                                                                                <CardBody>
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', }}>

                                                                                        <div style={{ flex: 8 }}>
                                                                                            {each.name} <Badge pill>{each.questions.length}</Badge>
                                                                                            <p></p>
                                                                                        </div>

                                                                                        <Input
                                                                                            style={{ flex: 2 }}
                                                                                            type="number"
                                                                                            placeholder="No of questions"
                                                                                            value={each.numOfQuestions || ''}
                                                                                            onChange={(e) => {
                                                                                                e.stopPropagation()
                                                                                                const value = e.target.value
                                                                                                if (value >= 0) {
                                                                                                    this.setState((oldState) => {
                                                                                                        if (value > each.questions.length) {
                                                                                                            alert(`Only ${each.questions.length} questions available`)
                                                                                                        } else {
                                                                                                            oldState.pathData[pathIndex].topics[moduleIndex].numOfQuestions = parseInt(value)
                                                                                                            return oldState
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        )
                                                                    })
                                                                }
                                                            </Collapse>
                                                        }

                                                    </ListGroupItem>
                                                )
                                            })
                                        }
                                    </ListGroup>
                                </Col>
                                <Col md="4">
                                    <ListGroup>

                                        <ListGroupItem>
                                            <h5>Mock Test Name</h5>
                                            <Input
                                                value={this.state.mockExamName || ''}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({ mockExamName: value })
                                                }}
                                            />
                                        </ListGroupItem>

                                        <ListGroupItem>
                                            <h5>Examination Posts</h5>
                                            <p>{this.state.selectedPosts.length} posts selected</p>
                                            <Button 
                                                outline 
                                                color="secondary" 
                                                block
                                                onClick={() => this.setState({
                                                    updatedPostsArray: cloneDeep(this.state.selectedPosts),
                                                    showPostsModals: true
                                                })}
                                            > Edit Posts
                                            </Button>

                                        </ListGroupItem>

                                        <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <h5>Total Questions</h5>
                                            <h4>{totalSelectedQuestions}</h4>
                                        </ListGroupItem>

                                        <ListGroupItem>
                                            <h5>Exam IDs</h5>
                                            <p>Only fetch questions from the below give examID exams</p>
                                            <Input
                                                type="textarea"
                                                rows="6"
                                                name="examIds"
                                                id="examIds"
                                                value={this.state.examIds || ''}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({ examIds: value })
                                                }}
                                                placeholder={"One exam ID per line please"}
                                            />
                                        </ListGroupItem>


                                    </ListGroup>
                                    <Button block color="primary" outline style={{ marginTop: 20 }} onClick={this.submitMockexam} >SUBMIT</Button>
                                </Col>
                            </Row>
                            :
                            (this.state.postUnitsToFetchId && this.state.postUnitsToFetchName) ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                    No modules added in this path
                                </div>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                                    Please select a crash course from above dropdown
                                </div>
                    }

                </Container>
            </Aux>
        )
    }
}



const mapStateToProps = state => {
    return {
        posts: state.data.posts,
        packs: state.data.packs,
    }
}


export default connect(mapStateToProps)(NewPathExam)
