import React from 'react'
import { Container, Row, Col, Label, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { InlineMath } from 'react-katex';

class MathEditor extends React.Component {

    state = {
        inputText: ''
    }

    renderMath() {

        if (this.state.inputText) {
            function nthIndex(str, pat, n) {
                var L = str.length, i = -1;
                while (n-- && i++ < L) {
                    i = str.indexOf(pat, i);
                    if (i < 0) break;
                }
                return i;
            }

            let indexArray = []

            for (let index = 0; index < this.state.inputText.length; index++) {
                let found = nthIndex(this.state.inputText, '$', index)
                if (found >= 0) { indexArray.push(found) }
            }

            let twoByTwoArray = []

            indexArray.forEach((element, index) => {
                if (index % 2 === 0) {
                    twoByTwoArray.push([indexArray[index], indexArray[index + 1]])
                }
            });

            return (
                <ListGroup>
                    {
                        twoByTwoArray.map((each, index) => (
                            <ListGroupItem key={each[0]}>
                                <InlineMath>{this.state.inputText.substring(each[0] + 1, each[1])}</InlineMath>
                            </ListGroupItem>
                        ))
                    }
                </ListGroup>
            )
        }

    }

    handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value })
    }

    render() {
        return (
            <Container style={{ marginTop:100 }}>
                <Row>
                    <Col md="6" style={{ borderWidth: 2, borderColor: 'red' }}>
                        <FormGroup>
                            <Label for="exampleText">Input Math Text</Label>
                            <Input
                                onChange={this.handleInput}
                                type="textarea"
                                name="inputText"
                                id="exampleText"
                                rows={10}
                                value={this.state.inputText}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" style={{ borderWidth: 2, borderColor: 'red' }}>
                        <FormGroup>
                            <Label for="exampleText">Will Render As</Label>
                            { this.renderMath() }
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default MathEditor