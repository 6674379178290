import React, { useState, useEffect } from 'react'
import { Container, Row, Input, Col, Button, Table, Modal, ModalHeader, ModalFooter, ModalBody, Badge } from 'reactstrap'
import axios from 'axios'
import { unstable_batchedUpdates } from 'react-dom'
import { cloneDeep } from 'lodash';
import './FeatureToIndex.css'
import Loader from '../Components/Loader'

const CONTENT_TYPE = "featuresToIndex"

function FeatureToIndex() {
    const [name, setName] = useState('')
    const [emoji, setEmoji] = useState('')
    const [routeName, setRouteName] = useState('')
    const [tags, setTags] = useState(null)
    const [newTagName, setNewTagName] = useState('')
    const [featuresToIndexData, setFeaturesToIndexData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenAddOrEditModal, setIsOpenAddOrEditModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        getFeaturesToIndexDetails()
    }, [])

    const getFeaturesToIndexDetails = () => {
        axios.get('/misc/', { params: { contentType: CONTENT_TYPE } })
            .then(res => {
                if (res.data.requiredContent && res.data.requiredContent.featuresToIndex) {
                    setFeaturesToIndexData(res.data.requiredContent.featuresToIndex)
                }
            })
            .catch(err => {
                console.log(err)
                alert("Error. Please check console")
            })
        setIsLoading(false)
    }

    const setFeaturesToIndex = () => {

        let value = {
            name: name,
            emoji: emoji,
            routeName: routeName,
            tags: tags
        }

        if (!currentIndex && currentIndex !== 0) {

            axios.put('/misc/', { value, contentType: CONTENT_TYPE })
                .then(res => {

                    unstable_batchedUpdates(() => {
                        setIsOpenAddOrEditModal(false)
                        setIsLoading(true)
                        setRouteName('')
                        setName('')
                        setEmoji('')
                        setTags()

                    })

                    getFeaturesToIndexDetails()


                })
                .catch(err => {
                    console.log(err)
                    if (err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    }

                    unstable_batchedUpdates(() => {
                        setIsOpenAddOrEditModal(false)
                        setIsLoading(false)
                        setRouteName('')
                        setName('')
                        setEmoji('')
                        setTags()

                    })
                })
        } else {

            const contentId = featuresToIndexData[currentIndex]._id

            axios.post('/misc/', { contentType: CONTENT_TYPE, contentId, value })
                .then(res => {

                    unstable_batchedUpdates(() => {
                        setIsOpenAddOrEditModal(false)
                        setCurrentIndex(null)
                        setIsLoading(true)
                        setRouteName('')
                        setName('')
                        setEmoji('')
                        setTags()

                    })

                    getFeaturesToIndexDetails()
                })
                .catch(err => {
                    console.log(err)
                    if (err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    }

                    unstable_batchedUpdates(() => {
                        setIsOpenAddOrEditModal(false)
                        setIsLoading(false)
                        setRouteName('')
                        setName('')
                        setEmoji('')
                        setTags()

                    })
                })
        }

    }

    const onEnterTag = (newTag) => {

        if (!newTag) {
            return alert("Please enter a tag")
        }

        if (Array.isArray(tags)) {
            const foundIndex = tags.indexOf(newTag)

            if (foundIndex > -1) {
                alert("This tag already exists")
            } else {
                let tagsCopy = cloneDeep(tags)
                tagsCopy.push(newTag)

                unstable_batchedUpdates(() => {
                    setTags(tagsCopy)
                    setNewTagName('')
                })
            }
        } else {
            unstable_batchedUpdates(() => {
                setTags([newTag])
                setNewTagName('')
            })

        }

    }

    const deleteTag = (newTag) => {

        const foundIndex = tags.indexOf(newTag)

        if (foundIndex > -1) {
            let tagsCopy = cloneDeep(tags)
            tagsCopy.splice(foundIndex, 1)
            setTags(tagsCopy)
        }
    }
    const closeModal = () => {

        unstable_batchedUpdates(() => {
            setIsOpenAddOrEditModal(false)
            setRouteName('')
            setName('')
            setEmoji('')
            setTags()

        })
    }

    const deleteFeaturesToIndexData = (itemId) => {
        if (window.confirm("Are you sure to delete this ?")) {

            setIsLoading(true)
            axios.delete('/misc/', { params: { contentType: CONTENT_TYPE, value: itemId } })
                .then(() => {
                    getFeaturesToIndexDetails()
                })
                .catch(err => {
                    console.log(err)
                    alert("Server error. Please check console or contact admin")
                })
        }

    }

    return (
        <Container >
            {isLoading && <Loader />}

            <Modal
                fade={true}
                isOpen={isOpenAddOrEditModal}
            >
                <ModalHeader> Add Features To IndexData</ModalHeader>
                <ModalBody>
                    <Col>
                        <Row style={{ marginBottom: 25 }}>

                            <label><strong>Set Name</strong></label>
                            <Input
                                type="text" name="name" id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginBottom: 25 }}>

                            <label><strong>Set Emoji</strong></label>
                            <Input
                                type="text" name="emoji" id="emoji"
                                value={emoji}
                                onChange={(e) => setEmoji(e.target.value)}
                            />
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ marginBottom: 25 }}>

                            <label><strong>Route Name</strong></label>
                            <Input
                                type="text" name="routeName" id="routeName"
                                value={routeName}
                                onChange={(e) => setRouteName(e.target.value)}
                            />
                        </Row>
                    </Col>
                    <Col>
                        <Row>

                            <label style={{ width: '100%' }}><strong>Tags</strong></label>
                            <div style={{ display: 'flex', width: '100%' }}>

                                <Input
                                    style={{ marginRight: 15 }}
                                    type="text" name="tags" id="tags"
                                    value={newTagName || ''}
                                    onChange={(e) => setNewTagName(e.target.value)}
                                />
                                <Button
                                    outline
                                    onClick={() => onEnterTag(newTagName)}
                                >
                                    Add
                                </Button>
                            </div>


                            {
                                Array.isArray(tags) && tags.length > 0 ?
                                    <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', marginTop: 10 }}>
                                        {
                                            tags.map(each => (
                                                <Badge
                                                    onClick={() => deleteTag(each)}
                                                    style={{ margin: 2, fontSize: 16, display: 'flex', alignItems: 'center', marginRight: 5 }}
                                                    key={each}
                                                >{each}</Badge>
                                            ))
                                        }
                                        <p style={{ marginTop: 10, fontSize: 12 }}>Click a tag to delete it</p>
                                    </div>
                                    :
                                    <p style={{ width: '100%', marginTop: 5 }}>No tags added</p>
                            }

                        </Row>
                    </Col>

                </ModalBody>
                <ModalFooter >
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{ marginRight: 10 }}
                            onClick={() => setFeaturesToIndex()}
                            color="primary"
                            outline
                        >
                            Set Features To Index
                        </Button>
                        <Button
                            outline
                            color='primary'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>
                    </div>

                </ModalFooter>
            </Modal>
            <Row style={{ justifyContent: 'space-between', marginLeft: 10, marginTop: 10 }}>
                <h2 style={{ color: '#4B4645' }}>Features To IndexData</h2>
                <Button
                    style={{ marginRight: 20 }}
                    outline
                    color='primary'
                    onClick={() => setIsOpenAddOrEditModal(true)}
                >
                    + Add Features To Index
                </Button>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
                {
                    Array.isArray(featuresToIndexData) && featuresToIndexData.length > 0 ?
                        <Table className='customiseTableTd'>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Name</th>
                                    <th>Emoji</th>
                                    <th>Route Name</th>
                                    <th>Tags</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    featuresToIndexData.map((item, index) => {
                                        return (
                                            <tr key={item._id}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.emoji}</td>
                                                <td>{item.routeName}</td>
                                                <td>
                                                    {
                                                        Array.isArray(item.tags) && item.tags.length > 0 ?
                                                            item.tags.map((each, index) => {
                                                                return (
                                                                    <ul key={index}>
                                                                        <li>

                                                                            {each}
                                                                        </li>
                                                                    </ul>
                                                                )
                                                            })
                                                            :
                                                            null

                                                    }
                                                </td>
                                                <td>
                                                    <Button
                                                        outline
                                                        color='primary'
                                                        style={{marginRight: 5}}
                                                        onClick={() => {
                                                            unstable_batchedUpdates(() => {
                                                                setIsOpenAddOrEditModal(true)
                                                                setRouteName(item.routeName)
                                                                setName(item.name)
                                                                setEmoji(item.emoji)
                                                                setTags(item.tags)
                                                                setCurrentIndex(index)

                                                            })
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        outline
                                                        color='danger'
                                                        onClick={() => {
                                                            deleteFeaturesToIndexData(item._id)
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        :
                        Array.isArray(featuresToIndexData) && featuresToIndexData.length === 0 ?
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <h4 style={{ textAlign: 'center', marginTop: 50 }}>No Features To Index Data added</h4>
                            </div>
                            : null
                }
            </Row>

        </Container>
    )
}
export default FeatureToIndex