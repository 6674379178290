import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Input, Spinner } from 'reactstrap'
import { DatePicker } from 'material-ui'
import moment from 'moment'
import { useSelector, shallowEqual } from 'react-redux'
import Axios from 'axios'
import { isAdmin, isContentManagerOrHigher, isSalesManagerOrHigher } from '../utils/dashboardRoles';

export default function UserActivity() {

    const rolesIds = useSelector(state => state.auth.roleIds, shallowEqual)

    const superAdmin = isAdmin(rolesIds)
    const contentManager = isContentManagerOrHigher(rolesIds)
    const salesManager = isSalesManagerOrHigher(rolesIds)

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isLoading, setIsLoading] = useState(true)
    const [selectedTeam, setSelectedTeam] = useState(checkUserRoleandGetTeamName())
    const [userActivity, setUserActivity] = useState(null)

    function fetchUserActivity () {
        setIsLoading(true)
        Axios.get('/user-activity', { params: { selectedDate, selectedTeam }})
        .then(res => {
            setUserActivity(res.data.userActivityDetails)
            setIsLoading(false)
        })  
        .catch(err => {
            alert("Error, please check console")
            setIsLoading(false)
            console.log(err)
        })      
    }

    function checkUserRoleandGetTeamName() {
        if (superAdmin) {
            return 'all'
        } else if (contentManager) {
            return 'contentTeam'
        } else if (salesManager) {
            return 'salesTeam'
        }
    }

    useEffect(() => {
        fetchUserActivity()   
    }, [selectedDate, selectedTeam])

    if (isLoading) {
        return (
            <Container style={{display: 'flex', justifyContent: 'center', marginTop: '5rem'}}>
                <Spinner animation="border" role="status"></Spinner>
            </Container>
        )
    } else {
        return (
            <Container fluid>
                
                <Row style={{ margin: '1rem', justifyContent: 'center'}}>
                    <h1>Employee Activity</h1>
                </Row>       

                <Row style={{ justifyContent: superAdmin ? 'space-around' : 'flex-start', margin: '1rem' }}>
                    <Col md='8' style={{ flexDirection: 'row' }}>
                        <DatePicker
                            container="inline"
                            hintText="Joining Date"
                            onChange={(e,date) => {
                                setSelectedDate(date)
                            }}
                            defaultDate={selectedDate}
                        />
                    </Col>
                    <Col md='3' style={{display: 'flex', alignItems: 'center'}}>
                        <Input
                            type="select" name="select"
                            value={selectedTeam}
                            disabled={(superAdmin) ? false : true}
                            onChange={(e) => setSelectedTeam(e.target.value)}
                        >
                            <option value="all">All</option>
                            <option value="contentTeam">Content Team</option>
                            <option value="salesTeam">Sales Team</option>
                        </Input>
                    </Col>
                    
                </Row>

                <Row>
                    { userActivity && userActivity.length > 0 ?
                        <Col>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Login Time</th>
                                        <th>Logout Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userActivity.map((item, index) => {
                                            return (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{(item.loginDetails && item.loginDetails[0]) ? moment(item.loginDetails[0].firstRequestTime).utcOffset("+05:30").format('hh:mm:ss a') : '-' }</td>
                                                    <td>{(item.loginDetails && item.loginDetails[0]) ? moment(item.loginDetails[0].lastRequestTime).utcOffset("+05:30").format('hh:mm:ss a') : '-' }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        :
                        !isLoading && Array.isArray(userActivity) && userActivity.length === 0 ?
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <h4 style={{ textAlign: 'center', marginTop: 50 }}>No Data Found</h4>
                            </div>
                        : null                                 
                        }
                    </Row>
            </Container>        
        )
    }

}
