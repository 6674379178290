import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Table, Button } from 'reactstrap'
import { Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'

function UserPackProgress({ userId, mobile, courseProgress, updateCourseProgress }) {

    const [isLoading, setIsLoading] = useState(true)

    const fetchCourseProgress = () => {
        Axios.get('/batch/admin/course-progress', { params: { userId } })
            .then(res => {
                updateCourseProgress(res.data.courseProgress)
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
                alert("Server error. Check console")
            })
    }

    useEffect(() => {
        if (!courseProgress) {
            fetchCourseProgress()
        } else {
            setIsLoading(false)
        }
    }, [])

    if (isLoading) {
        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Spinner animation="border" role="status">
                    Loading...
                </Spinner>
            </div>
        )
    } else {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Course Name</th>
                        <th>Questions Attended</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        courseProgress.map(each => {
                            return (
                                <tr key={each._id}>
                                    <td>{each.postName}</td>
                                    <td>{each.correctCount}</td>
                                    <td>
                                        <Link
                                            target="_blank"
                                            to={{
                                                pathname: "/user-learning-path",
                                                search: `postUnitsToFetchId=${each._id}&postUnitsToFetchName=${each.postName}&phoneNumber=${mobile}`
                                            }}
                                        >
                                            <Button
                                                color="link"
                                                target="_blank"
                                            >
                                                See Detailed Stats
                                            </Button>
                                        </Link>

                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }
}

export default UserPackProgress