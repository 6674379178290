import React, { Component } from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row, Button, Label, FormGroup,
    Modal, ModalHeader, ModalBody, Input, ModalFooter
} from 'reactstrap'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faCopy, faTrash, faReply, faPlus, faRandom, faSort } from '@fortawesome/free-solid-svg-icons'
import Loader from '../Components/Loader'
import { copyToClipboard } from '../utils'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { cloneDeep } from 'lodash'
import { Checkbox } from 'material-ui';
import { toast } from 'react-toastify'

import { isContentManagerOrHigher } from '../utils/dashboardRoles';

const SortableItem = SortableElement(({
    topicId, activeFlag, name, emoji, description, availableQuestions, totalQuestions, spacedRepetitionEnabled,
    modules, topicIndex, roleIds, editTopic, addQuestionById, openSectionIdModal, transferQuestionIds, fetchQuestions, fetchModules, deleteTopic, isSortingEnabled
}) => {
    return(
        <>
            <tr style={{ background: activeFlag ? 'white' : '#ffcccb' }}>
                <td>{topicIndex + 1}</td>
                <th>{emoji}</th>
                <td>{name}</td>
                <td width={"30%"}>{description}</td>
                <td>{availableQuestions}</td>
                <td>{totalQuestions}</td>
                <td>{modules.length}</td>
            </tr>
            <tr
                style={{
                    marginBottom: 10,
                    borderBottom: '1px solid #bababa',
                    background: activeFlag ? 'white' : '#ffcccb'
                }}
            >
                {!isSortingEnabled ?
                    <td colSpan={9} style={{ paddingBottom: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <Button
                                style={{ marginRight: 5 }}
                                outline
                                color="primary"
                                onClick={() => addQuestionById(topicId)}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {" Paste Question Ids"}
                            </Button>
                            <Button
                                style={{ marginRight: 5 }}
                                outline
                                color="primary"
                                onClick={() => openSectionIdModal(topicId)}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {" Add Questions from Section ID"}
                            </Button>
                            {
                                isContentManagerOrHigher(roleIds) &&
                                <Button
                                    style={{ marginRight: 5 }}
                                    outline
                                    color="primary"
                                    onClick={() => transferQuestionIds(topicId, name)}
                                >
                                    <FontAwesomeIcon icon={faRandom} />
                                </Button>
                            }

                            <Button
                                outline
                                color="primary"
                                style={{ marginRight: 5 }}
                                onClick={() => fetchQuestions(topicId)}
                            >
                                Questions
                            </Button>
                            <Button
                                outline
                                color="primary"
                                style={{ marginRight: 5 }}
                                onClick={() => fetchModules(topicId)}
                            >
                                Modules
                            </Button>
                            <Button
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    copyToClipboard(topicId)
                                    toast("ID copied", {
                                        position: toast.POSITION.TOP_CENTER,
                                        hideProgressBar: true
                                    })
                                }}
                                outline
                            >
                                <FontAwesomeIcon
                                    className="iconButton"
                                    style={{ color: 'grey' }}
                                    icon={faCopy}
                                />
                            </Button>
                            <Button
                                style={{ marginRight: 5 }}
                                outline color="primary"
                                onClick={() => editTopic(topicId, name, description, emoji, spacedRepetitionEnabled)}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                            <Button outline color="danger" onClick={() => deleteTopic(topicId)} >
                                <FontAwesomeIcon icon={activeFlag ? faTrash : faReply} />
                            </Button>
                        </div>
                    </td>
                    :
                    <td colSpan={9}>
                        <FontAwesomeIcon
                            icon={faSort}
                            style={{ marginLeft: 10, color: 'grey' }}
                        />
                    </td>
                }
            </tr>
        </>
    )
})

const SortableList = SortableContainer(({isSortingEnabled,topics, roleIds, editTopic, openSectionIdModal, addQuestionById, transferQuestionIds, fetchQuestions, fetchModules, deleteTopic }) => {
    return(
        <tbody>
            {
                topics.map((topic,index) => (
                    <SortableItem
                        key={topic._id}
                        index={index}
                        topicIndex={index}
                        deleteTopic={deleteTopic}
                        fetchModules={fetchModules}
                        fetchQuestions={fetchQuestions}
                        transferQuestionIds={transferQuestionIds}
                        editTopic={editTopic}
                        disabled={!isSortingEnabled}
                        isSortingEnabled={isSortingEnabled}
                        topicId={topic._id}
                        activeFlag={topic.activeFlag}
                        emoji={topic.emoji}
                        name={topic.name}
                        description={topic.description}
                        availableQuestions={topic.availableQuestions}
                        totalQuestions={topic.totalQuestions}
                        modules={topic.modules}
                        roleIds={roleIds}
                        addQuestionById={addQuestionById}
                        openSectionIdModal={openSectionIdModal}
                        spacedRepetitionEnabled={topic.spacedRepetitionEnabled}
                    />
                ))

            }
        </tbody>
    )
})

class EditPath extends Component {

    state = {
        newTopicName: "",
        newTopicDescription:"",
        newTopicEmoji:"",
        unitData: null,
        isLoading:true,
        showNewQuestionModal:false,
        newQuestionId:'',
        isSortingEnabled: false
    }

    componentDidMount() {
        this.fetchUnitData()
    }

    fetchUnitData() {
        const { unitId } = this.props.match.params
        axios.get('/admin/learning-path/unit', { params: { unitId } })
            .then(res => {
                this.setState({
                    unitData: res.data.unitData,
                    isLoading:false,

                    addingTopicId: null,
                    showSectionIdModal: false,
                    sectionId: null,
                    showNewQuestionModal:false,
                    newQuestionId:''
                })
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.log(err)
                alert("Error, check console")
                this.setState({ isLoading:false })
            })
    }

    createNewTopic() {
        if (this.state.newTopicName && this.state.newTopicName.length > 0) {

            if (this.state.editingTopicId) {
                const { unitId } = this.props.match.params
                axios.post('/admin/learning-path/topic', {
                    unitId,
                    topicId:this.state.editingTopicId,
                    topicName: this.state.newTopicName,
                    topicDescription: this.state.newTopicDescription,
                    topicEmoji: this.state.newTopicEmoji,
                    spacedRepetitionEnabled: this.state.newTopicSpacedRepetitionEnabled,
                })
                    .then(res => {
                        this.setState({
                            showNewTopicModal: false,
                            newTopicName: null,
                            newTopicDescription:null,
                            newTopicEmoji:null,
                            editingTopicId:null,
                            newTopicSpacedRepetitionEnabled: true,
                            isLoading: false
                        })
                        this.fetchUnitData()
                    })
                    .catch(err => {
                        this.setState({isLoading: false})
                        console.log(err)
                        alert("Error, check console")
                    })
            } else {
                const { unitId } = this.props.match.params
                axios.put('/admin/learning-path/topic', {
                    unitId,
                    topicName: this.state.newTopicName,
                    topicDescription: this.state.newTopicDescription,
                    topicEmoji:this.state.newTopicEmoji,
                    newTopicSpacedRepetitionEnabled: this.state.newTopicSpacedRepetitionEnabled
                })
                    .then(res => {
                        this.setState({
                            showNewTopicModal: false,
                            newTopicName: null,
                            newTopicDescription:null,
                            newTopicEmoji:null,
                            isLoading: false
                        })
                        this.fetchUnitData()
                    })
                    .catch(err => {
                        this.setState({isLoading: false})
                        console.log(err)
                        alert("Error, check console")
                    })
            }


        } else {
            this.setState({ isLoading: false })
            alert("Enter a valid topic name")
        }
    }

    fetchQuestions(topicId) {
        const { unitId } = this.props.match.params
        this.props.history.push({ pathname: "/questions", state: { topicId, unitId } })
    }

    fetchModules(topicId) {
        const { unitId } = this.props.match.params
        this.props.history.push({ pathname: `/edit-unit/${unitId}/${topicId}` })
    }

    renderNewTopicModal() {
        return (
            <Modal
                fade={true}
                isOpen={this.state.showNewTopicModal}
            >
                <ModalHeader>Select Post for New Path</ModalHeader>

                <ModalBody>

                    <Input
                        placeholder="Enter Topic Name"
                        type="text"
                        name="newTopicName"
                        value={this.state.newTopicName || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                    <Input
                        placeholder="Enter Emoji (Optional)"
                        type="text"
                        name="newTopicEmoji"
                        value={this.state.newTopicEmoji || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                    <Input
                        placeholder="Enter Topic Description (Optional)"
                        type="text"
                        name="newTopicDescription"
                        value={this.state.newTopicDescription || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                    <Checkbox
                        label="Spaced Repetition"
                        checked={this.state.newTopicSpacedRepetitionEnabled || this.state.newTopicSpacedRepetitionEnabled == null}
                        onCheck={(e, value) => this.setState({ newTopicSpacedRepetitionEnabled: value })}
                    />

                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => this.createNewTopic()} color="primary">{this.state.editingTopicId ? "Submit Edit" : "Create Topic"}</Button>
                    <Button onClick={() => this.setState({ 
                        showNewTopicModal: false,
                        newTopicName: null,
                        newTopicDescription:null,
                        newTopicSpacedRepetitionEnabled: true, 
                        editingTopicId:null,
                        newTopicEmoji:null,
                        isLoader: true
                    })} outline>Close</Button>
                </ModalFooter>

            </Modal>

        )
    }

    editTopic(topicId, editingTopicName, editingTopicDescription, editingTopicEmoji, editingTopicSpacedRepetitionEnabled) {
        this.setState({
            editingTopicId: topicId,
            showNewTopicModal: true,
            newTopicName: editingTopicName,
            newTopicDescription: editingTopicDescription,
            newTopicEmoji: editingTopicEmoji,
            newTopicSpacedRepetitionEnabled: editingTopicSpacedRepetitionEnabled
        })
    }

    deleteTopic(topicId) {
        const { unitId } = this.props.match.params
        if (window.confirm("Are you sure you want to delete this unit?")) {
            axios.delete('/admin/learning-path/topic', { params: { unitId, topicId } })
            .then(res => {
                this.fetchUnitData()
            }).catch(err => {
                console.log(err)
                alert("Error, please check console")
            })
        }
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })
    
    addQuestionWithId() {

        if (this.state.newQuestionId) {

            const questionIdsSplitted = this.state.newQuestionId.split("\n")
            const allItems = questionIdsSplitted.map(each => each.replace(/\s/g, ''))
            const questionIds = allItems.filter(each => each)

            if (questionIds.length > 0) {
                this.setState({ isLoading:true }, () => {
                    axios.post('/admin/learning-path/question', { topicId: this.state.addingTopicId, questionIdArray:questionIds })
                    .then(res => {
                        toast(`Added ${res.data.questionCount} questions`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
                        this.fetchUnitData()
                    })
                    .catch(err => {
                        if (err && err.response && err.response.data && err.response.data.message) {
                            alert(err.response.data.message)
                        } else {
                            alert("Error. Please check console")
                            console.log(err)
                        }
                        this.setState({ isLoading: false })
                    })
                })
            }
        } else {
            alert('Please Enter Question Ids')
        }
        
    }

    renderNewQuestionModal() {
        return (
            <Modal
                fade={true}
                isOpen={this.state.showNewQuestionModal}
            >
                
                <ModalHeader>Enter Question Ids to add</ModalHeader>

                <ModalBody>
                    <Input
                        placeholder="One question ID per line please"
                        type="textarea"
                        rows='10'
                        name="newQuestionId"
                        value={this.state.newQuestionId || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => this.addQuestionWithId()}
                        color="primary">
                            Add Questions
                        </Button>
                    <Button
                        outline
                        onClick={() => this.setState({ showNewQuestionModal: false, newQuestionId: '' })}
                    >Close</Button>

                </ModalFooter>
            </Modal>
        )
    }

    addQuestionWithSectionId = () => {

        const { sectionId, addingTopicId } = this.state

        if (sectionId && addingTopicId) {

            this.setState({ isLoading: true }, () => {
                axios.post('/admin/learning-path/question', { topicId: addingTopicId, sectionId })
                    .then(res => {
                        toast(
                            `Added ${res.data.questionCount} questions`,
                            { position: toast.POSITION.TOP_CENTER, hideProgressBar: true }
                        )
                        this.fetchUnitData()
                    })
                    .catch(err => {
                        if (err && err.response && err.response.data && err.response.data.message) {
                            alert(err.response.data.message)
                        } else {
                            alert("Error. Please check console")
                            console.log(err)
                        }
                        this.setState({ isLoading: false })
                    })
            })

        } else {
            alert('Enter Section ID')
        }

    }

    renderSectionIdModal() {
        return (
            <Modal
                fade={true}
                isOpen={this.state.showSectionIdModal}
            >
                <ModalHeader>
                    Enter Section ID to add questions from
                </ModalHeader>
                <ModalBody>
                    <Input
                        placeholder="Enter Section ID to Get Questions From"
                        type="text"
                        name="sectionId"
                        value={this.state.sectionId || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={this.addQuestionWithSectionId}
                        color="primary">
                            Add Questions
                        </Button>
                    <Button
                        outline
                        onClick={() => this.setState({
                            sectionId: '',
                            showSectionIdModal: false,
                            addingTopicId: null
                        })}
                    >Close</Button>

                </ModalFooter>
            </Modal>
        )
    }

    transferContent() {
        
        if (!this.state.transferContentSource) {
            return alert("Enter Source Topic ID")
        }

        if (!(this.state.transferQuestions || this.state.transferModules)) {
            return alert("Please select what you want to transfer")
        }

        this.setState({ isLoading: true }, () => {
            axios.post('/admin/learning-path/transfer', {
                destinationTopicId: this.state.destinationTopicId,
                transferContentSource: this.state.transferContentSource,
                transferQuestions: this.state.transferQuestions,
                transferModules: this.state.transferModules
            })
            .then(res => {
                this.setState({
                    transferContentSource: null,
                    transferQuestions: false,
                    transferModules: false,
                    showTransferModal: false,
                    destinationTopicId: null,

                }, () => {
                    this.fetchUnitData()
                })
                alert(res.data.message)
            }).catch(err => {
                if (err && err.response && err.response.data && err.response.data.message) {
                    alert(err.response.data.message)
                } else {
                    alert("Server error. Check console")
                    console.log(err)
                }
            }) 
        })


    }
    renderTransferModal() {
        return (
            <Modal
                fade={true}
                isOpen={this.state.showTransferModal}
            >

                <ModalHeader>
                    Enter Details
                </ModalHeader>

                <ModalBody>
                    <p>Transferring data to <span style={{ fontWeight: 'bold', fontSize: 18 }}>{this.state.destinationTopicName}</span></p>

                    <Input
                        placeholder="Enter Source Topic ID"
                        type="text"
                        name="transferContentSource"
                        value={this.state.transferContentSource || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                    <FormGroup>
                        <Label>
                            Transfer Questions
                        </Label>
                        <Input
                            type="checkbox"
                            name="transferQuestions"
                            id="transferQuestions"
                            value={this.state.transferQuestions}
                            onChange={(e) => {
                                this.setState({ transferQuestions: e.target.checked })
                            }}
                            style={{ margin: 10 }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>
                            Transfer Modules
                        </Label>
                        <Input
                            type="checkbox"
                            name="transferModules"
                            id="transferModules"
                            value={this.state.transferModules}
                            onChange={(e) => {
                                this.setState({ transferModules: e.target.checked })
                            }}
                            style={{ margin: 10 }}
                        />
                    </FormGroup>

                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={() => this.transferContent()}
                        color="primary">Transfer
                    </Button>

                    <Button
                        onClick={() => this.setState({ showTransferModal: false, transferContentSource: '' })}
                        outline
                    >Close</Button>

                </ModalFooter>

            </Modal>
        )
    } 
    
    onModulesSortEnd = ({oldIndex, newIndex}) => {
        let reorderedList = []
        this.setState(oldState => {
            reorderedList = arrayMove(oldState.unitData.topics, oldIndex, newIndex)
            oldState.unitData.topics = reorderedList
            return oldState
        })
        let topicsWithUpdatedIndex = reorderedList.map((each, index) => ({ _id: each._id, index: index }))
        let topicsClone = cloneDeep(this.state.unitData.topics)
        topicsClone.forEach(topic =>{
            topicsWithUpdatedIndex.forEach(each=> {
                if(each._id === topic._id ) {
                    topic.order = each.index
                }
            })
           })

        this.setState(oldState => {
            oldState.unitData.topics = topicsClone
            return oldState
        })
        
        axios.post('/admin/learning-path/module/topics/reorder', {
            unitDataId: this.state.unitData._id,
            topicsWithUpdatedIndex
        }).catch(err => {
            console.log(err)
            alert("Error, check console")
        })
            
    }

    render() {
        if (this.state.unitData) {
            return (
                <>
                    { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}
                    <Container fluid>

                        {/* {
                            this.state.isLoading &&
                            <div style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                paddingTop: '30%',
                                paddingLeft: '47%',
                                zIndex: 99
                            }}>
                                <CircularProgress size={80} thickness={5} />
                            </div>
                        } */}

                        {this.state.showNewTopicModal && this.renderNewTopicModal()}
                        {this.state.showNewQuestionModal && this.renderNewQuestionModal()}
                        {this.state.showSectionIdModal && this.renderSectionIdModal()}
                        {this.state.showTransferModal && this.renderTransferModal()}

                        <Row style={{ paddingTop: 20 , justifyContent:"space-between"}}>
                            <Button
                                color="primary"
                                style={{ margin: 10 }}
                                onClick={() => this.setState({ showNewTopicModal: true })}>
                                + ADD NEW TOPIC
                            </Button>
                            <Button
                                color= "success"
                                outline
                                style={{ margin: 10 }}
                                onClick={()=>this.setState({isSortingEnabled: !this.state.isSortingEnabled})}
                            >
                                { this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting" }
                            </Button>
                        </Row>

                        <Row>
                            <Col>
                                {
                                    this.state.unitData.topics.length > 0 ?
                                        <Table borderless>
                                            <thead style={{ borderBottom: '1px solid #bababa', borderTop: '1px solid #bababa' }}>
                                                <tr>
                                                    <th>Index</th>
                                                    <th>Icon</th>
                                                    <th>Topic Name</th>
                                                    <th>Description</th>
                                                    <th>Available Questions</th>
                                                    <th>Total Questions</th>
                                                    <th>Modules Count</th>
                                                </tr>
                                            </thead>
                                            <SortableList
                                                newQuestionId={this.state.newQuestionId}
                                                showNewQuestionModal={this.state.showNewQuestionModal}
                                                addingTopicId={this.state.addingTopicId}
                                                isSortingEnabled={this.state.isSortingEnabled}
                                                topics={this.state.unitData.topics}
                                                roleIds={this.props.roleIds}
                                                editTopic={(topicId, name, description, emoji, spacedRepetitionEnabled)=>this.editTopic(topicId, name, description, emoji, spacedRepetitionEnabled)}
                                                addQuestionById={(topicId)=> {
                                                    this.setState({
                                                        newQuestionId: '',
                                                        showNewQuestionModal: true,
                                                        addingTopicId: topicId
                                                    })
                                                }}
                                                openSectionIdModal={(topicId)=> {
                                                    this.setState({
                                                        sectionId: '',
                                                        showSectionIdModal: true,
                                                        addingTopicId: topicId
                                                    })
                                                }}
                                                transferQuestionIds = {(topicId, name)=>{
                                                    this.setState({
                                                        showTransferModal: true,
                                                        destinationTopicName: name,
                                                        destinationTopicId: topicId
                                                    })
                                                }}
                                                fetchQuestions={(topicId)=>this.fetchQuestions(topicId)}
                                                fetchModules={(topicId)=> this.fetchModules(topicId)}
                                                deleteTopic={(topicId)=> this.deleteTopic(topicId)}
                                                onSortEnd={this.onModulesSortEnd}
                                            />

                                        </Table>
                                        :
                                        <p>No Topics Added</p>
                                }
                            </Col>
                        </Row>
                    </Container>
                </>
            )
        } else {
            return (
                <>
                    { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}
                </>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        roleIds: state.auth.roleIds,
    }
}


export default connect(mapStateToProps)(EditPath)
