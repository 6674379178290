import React, { Component } from 'react'
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import axios from 'axios'
import Dialog from 'material-ui/Dialog';
import moment from 'moment'
import CheckBox from 'material-ui/Checkbox';
import {
    Container, Row, Col, CustomInput, Button, FormGroup, Label, Badge,Input, Table, Form,
    Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import CircularProgress from 'material-ui/CircularProgress'
import { connect } from 'react-redux'

import { cloneDeep, isString, sortBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import queryString from 'query-string';
import striptags from 'striptags'

import { updateQuestions, updatePosts, updatePathUnits } from '../actions'
import { QUESTION_LANGUAGE_REGEX, REMOVE_CHARS_REGEX, SEARCH_SERVER } from '../config/env'
import SimilarQuestions from './SimilarQuestions';
import ExplanationEditor from './ExplanationEditor';
import StatementType from '../Pages/QuestionGenerator/StatementType';
import MatchType from '../Pages/QuestionGenerator/MatchType';
import EditorOptionModal from './EditorOptionModal';
import { isAdmin, isContentManagerOrHigher, isContentReviewerOrHigher } from '../utils/dashboardRoles';
import { addExtraDetailsWithQuestions, applyColorToHtmlQuestion, fixSpacesBetweenStrings, isValidObjectId } from '../utils';
import { questionTypes, getHtmlQuestion } from './AddForm'
import '../App.css'
import MathQuestionViewer from './MathQuestionViewer';
import TinyEditor from './TinyEditor';
import RelatedModulesModal from './RelatedModulesModal';

class QuestionForm extends Component {

    state = {

        searchParams: queryString.parse(this.props.location.search),
        gotQuestion: true,
        isLoading: true,

        showAllSubject: false,
        islanguagePrediction: true,
        
        subject: '', 

        question: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        answer: '',
        description: '',
        deleteDialog: false,
        premiumFlag: true,
        exams: [],

        pinnedPathUnits:{},

        splittedWords: [],

        selectedWords: [],
        currentSkipCount: 0,

        selectedPathIndex: 0,
        pathUnitTemp: cloneDeep(this.props.pathUnits),
        unitCheckListArr: [],

        openEndDialog: false,
        showDeleteSuccess: false,
        isExamListOpen: false,

        examsIncludingThisQuestion: []
    }

    handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value })
    }

    handleMenuChange = (name, value) => {
        if(name === "subjectId") {
            this.setState({ [name]: value, sectionId:null })
        } else {
            this.setState({ [name]: value })
        }
    }

    clickedWord(eachItem) {
        
        const foundItem = this.state.selectedWords.find(e => e === eachItem)

        let question = ''

        if (this.state.type === questionTypes.MATCH || this.state.type === questionTypes.STATEMENT) {
            question = this.state.baseQuestion
        } else {
            question = this.state.question
        } 

        if(foundItem) {
            let afterRemoving = this.state.selectedWords.filter(e => e !== eachItem)
            let wordsFromThisQuestion = afterRemoving.filter(e => question.split(" ").includes(e)) 
            this.setState({ selectedWords:wordsFromThisQuestion })
        } else {
            let afterAdding = this.state.selectedWords.concat([eachItem])
            let wordsFromThisQuestion = afterAdding.filter(e => question.split(" ").includes(e))
            this.setState({ selectedWords:wordsFromThisQuestion })
        }
    }

    predictData() {

        let text = ''

        const { type } = this.state

        if (type === questionTypes.MATCH || type === questionTypes.STATEMENT) {
            text = this.state.baseQuestion
        } else {
            text = this.state.question
        }

        let selected = this.state.selectedWords
        let unselected = text.split(" ").filter(e => !(this.state.selectedWords.includes(e)))

        axios.post('/predict', { selected, unselected, text })
        .then(res => {

            const { sectionData, descriptions } = res.data

            if(descriptions && descriptions.length > 0) { this.setState({ descriptions }) }


            let subject = this.props.subjects.find(item => {
                let found = item.sections.find(section => section._id === sectionData[0].sectionId)
                return found
            })

            const clonedSections = cloneDeep(subject.sections)
            const sortedSections = sortBy(clonedSections, ['name'])

            this.setState({
                subjectId: subject._id,
                sections: sortedSections,
                sectionId: sectionData[0].sectionId
            })

        }).catch(err => {
            console.error('/predict')
            console.error(err)
            alert("Server error while predicting. Check console")
        })
    }

    updatePills() {
        if (this.state.question) {
            if (this.state.type === "text") {
                this.setState({ splittedWords: this.state.question.split(" ") })
            } else {
                this.setState({ splittedWords: [] })
            }
        }
    }
    
    updateSelectFields(explanationType, description) {

        let subject = this.props.subjects
        //Populating Sections
        let afterData = subject.filter(each => each._id === this.state.subjectId)

        if (Array.isArray(afterData) && afterData.length > 0) {
            const clonedSections = cloneDeep(afterData[0].sections)
            const sortedSections = sortBy(clonedSections, ['name'])
            this.setState({ sections: sortedSections })
        }

    }

    fetchQuestionWithId() {
        axios.get(`/get-questions`, { params: { subjectId: 1, count: 1, questionId: this.state.searchParams.questionId } })
                .then(response => {
                        this.setState({
                            questions: response.data.data.questions,
                            isLoading: false,
                        },()=>{
                            this.setQuestionToState(response.data.data.questions[0])
                        })
                })
                .catch(err => {
                    console.error('/get-questions')
                    console.error(err)
                    alert("Error, check console")
                })
    }

    fetchReportedQuestions() {
        const { searchParams } = this.state
        searchParams.index = parseInt(searchParams.index)
        searchParams.count = searchParams.count ? parseInt(searchParams.count) : 50
        searchParams.skipCount = searchParams.skipCount ? parseInt(searchParams.skipCount) : 0

        axios.get('/fetchReports', { params: { skipCount: searchParams.skipCount, type: searchParams.issueType, sortByDate: searchParams.sortByDate, sortByCount: searchParams.sortByCount }})
            .then(response => {
                let questions = response.data.reports
                questions = addExtraDetailsWithQuestions(questions)
                this.props.updateQuestions({ questions, count: response.data.totalReportsCount });

                this.setState({ isLoading: false, dailyChallengeId: null, examId: null, questionIdsToCopyArr: [] })
                
                const { subjectId } = this.props.questionData.questions[searchParams.index]
                let afterData = this.props.subjects.filter(each => each._id === subjectId)
                
                const clonedSections = cloneDeep(afterData[0].sections)
                const sortedSections = sortBy(clonedSections, ['name'])

                this.setState({ sections: sortedSections, initialSkipCount: parseInt(searchParams.skipCount), currentSkipCount: parseInt(searchParams.skipCount), index: parseInt(searchParams.index), questions: this.props.questionData.questions, isLoading: false }, () => {
                    this.setQuestionToState(this.props.questionData.questions[searchParams.index])
                })
            })
            .catch( err => {
                console.error('/fetchReports')
                console.error(err)
                alert('Error, check console')
            })
    }

    fetchLearnModuleQuizQuestions() {
        const { searchParams } = this.state

        axios.get('/learn/module', { params: { moduleId: searchParams.moduleId } })
            .then(res => {
                let questions = res.data.quizQuestions
                questions = addExtraDetailsWithQuestions(questions)
                this.props.updateQuestions({ questions, count: res.data.quizQuestions.length });
                this.setState({ isLoading: false, dailyChallengeId: null, examId: null, questionIdsToCopyArr: [] })
                
                const { subjectId } = this.props.questionData.questions[searchParams.index]
                let afterData = this.props.subjects.filter(each => each._id === subjectId)
                
                this.setState({ sections: afterData[0].sections, initialSkipCount: parseInt(searchParams.skipCount), currentSkipCount: parseInt(searchParams.skipCount), index: parseInt(searchParams.index), questions: this.props.questionData.questions, isLoading: false }, () => {
                    this.setQuestionToState(this.props.questionData.questions[searchParams.index])
                })
            })
            .catch(err => {
                console.error('/learn/module')
                console.error(err)
                alert("Error. Check console")
            })
    }

    checkAndReturnParams = () => {

        const { searchParams } = this.state

        let propsObj = {
            index: parseInt(searchParams.index),
            backRoute: searchParams.backRoute,
            skipCount: parseInt(searchParams.skipCount),
            addedBy: searchParams.addedBy,
            sortByDate: searchParams.sortByDate,
            sortByCount: searchParams.sortByCount,
            editedBy: searchParams.editedBy,
            topicId: searchParams.topicId,
            moduleId: searchParams.moduleId,
            period: searchParams.period,
            unitId: searchParams.unitId,
            searchText: searchParams.searchText,
            questionId: searchParams.questionId,
            dailyChallengeId: searchParams.dailyChallengeId,
            currentAffairsPeriod: searchParams.currentAffairsPeriod,
            issueType: searchParams.issueType,
            subjectId: searchParams.subjectId,
            sectionId: searchParams.sectionId,
            tagId: searchParams.tagId,
            activeFlag: searchParams.activeFlag,
            showInChallenge: searchParams.showInChallenge,
            questionStatus: searchParams.questionStatus,
            languageId: searchParams.languageId 
        }

        let params = ``

        Object.keys(propsObj).forEach(eachKey => {
            if (propsObj[eachKey] || isFinite(propsObj[eachKey])) {
                params = `${params}&${eachKey}=${propsObj[eachKey]}` 
            }
        })

        return params
    }

    disableOptionsIfStatementOrMatchType = () => {
        return this.state.type === questionTypes.STATEMENT || this.state.type === questionTypes.MATCH
    }

    fetchQuestionsParams() {
        
        const { searchParams } = this.state

        searchParams.index = parseInt(searchParams.index)
        searchParams.questionId = null
        searchParams.count = searchParams.count ? parseInt(searchParams.count) : 50
        searchParams.skipCount = searchParams.skipCount ? parseInt(searchParams.skipCount) * 50 : 0
        
        axios.get(`/get-questions`, { params: searchParams })
            .then((response) => {
                let questions = response.data.data.questions
                questions = addExtraDetailsWithQuestions(questions)
                this.props.updateQuestions({ questions, count: response.data.data.count });
                
                this.setState({ isLoading: false, dailyChallengeId: null, examId: null, questionIdsToCopyArr: [] })
                
                const { subjectId } = this.props.questionData.questions[searchParams.index]
                let afterData = this.props.subjects.filter(each => each._id === subjectId)

                const clonedSections = cloneDeep(afterData[0].sections)
                const sortedSections = sortBy(clonedSections, ['name'])
                
                this.setState({ 
                    sections: sortedSections, 
                    initialSkipCount: parseInt(searchParams.skipCount), 
                    currentSkipCount: parseInt(searchParams.skipCount), 
                    index: parseInt(searchParams.index), 
                    questions: this.props.questionData.questions, 
                    isLoading: false 
                }, () => {
                    this.setQuestionToState(this.props.questionData.questions[searchParams.index])
                })
            })
            .catch((error) => { 
                if (error.response) {
                    alert(error.response.data.message) 
                    this.setState({ isLoading: false })
                }
                console.error('/get-questions')
                console.error(error)
            })

    }

    componentDidMount() {

        const { searchParams } = this.state

        if (searchParams && searchParams.questionId && searchParams.index) {
            if (this.props.questionData.questions && this.props.questionData.questions[searchParams.index] && (searchParams.questionId === this.props.questionData.questions[searchParams.index]._id)) {
                const { subjectId } = this.props.questionData.questions[searchParams.index]
                
                let afterData = this.props.subjects.filter(each => each._id === subjectId)
                
                const clonedSections = cloneDeep(afterData[0].sections)
                const sortedSections = sortBy(clonedSections, ['name'])

                this.setState({ sections: sortedSections, initialSkipCount: parseInt(searchParams.skipCount), currentSkipCount: parseInt(searchParams.skipCount), index: parseInt(searchParams.index), questions: this.props.questionData.questions, isLoading: false }, () => {
                    this.setQuestionToState(this.props.questionData.questions[searchParams.index])
                })

            } else {
                if (((searchParams.backRoute === 'searchQuestions') || (searchParams.backRoute === 'exams'))) {
                    this.fetchQuestionsParams()
                } else if (searchParams.backRoute === 'reports') {
                    this.fetchReportedQuestions()
                } else if (searchParams.backRoute === 'learnModules') {
                    this.fetchLearnModuleQuizQuestions()
                } else if (this.props.questionData) {
                    this.setQuestionToState(this.props.questionData.questions[searchParams.index])
                } else {
                    this.setState({ gotQuestion: false })
                }
            }
        } else if (searchParams && searchParams.questionId && !searchParams.index) {
            this.fetchQuestionWithId()   
        }

    }

    submitQuestion() {

        if (this.state.paragraphId) {
            if (!isValidObjectId(this.state.paragraphId)) {
                alert("Please enter a valid Paragraph ID or remove invalid Paragraph ID")
                return true
            }
        }
        
        const { question, type, description, explanationType } = this.state
        
        let questionText = question

        if (type === "html" || type === "math") {
            questionText = applyColorToHtmlQuestion(questionText)
        }

        let descriptionText = description

        if (explanationType === 'math' || explanationType === 'html') {
            descriptionText = applyColorToHtmlQuestion(descriptionText)
        }

        let postObject = {
            _id: this.state._id,
            question: questionText,
            type: this.state.type,
            options: [
                {
                    id: 'A',
                    text: this.state.option1,
                    type: this.state.option1Type,
                },
                {
                    id: 'B',
                    text: this.state.option2,
                    type: this.state.option2Type,
                },
                {
                    id: 'C',
                    text: this.state.option3,
                    type: this.state.option3Type,
                },
                {
                    id: 'D',
                    text: this.state.option4,
                    type: this.state.option4Type,
                },
            ],
            answer: this.state.answer,
            subjectId: this.state.subjectId,
            sectionId: this.state.sectionId,
            languageId: this.state.languageId,
            tags: this.state.tags || [],
            difficulty: this.state.wrongAttempt / this.state.totalAttempt,
            wrongAttempt: this.state.wrongAttempt,
            totalAttempt: this.state.totalAttempt,
            addedBy: this.state.addedBy,
            addedOn: this.state.addedOn,
            // approved: this.state.approved,
            showInChallenge: this.state.showInChallenge,
            description: descriptionText,
            explanationType: this.state.explanationType,
            premiumFlag: this.state.premiumFlag,
            exams: this.state.exams,
            pathUnits: this.state.unitCheckListArr,
            activeFlag: this.state.activeFlag,
            paragraphId: this.state.paragraphId,
            baseQuestion: this.state.baseQuestion,
            statements: this.state.statements,
            relatedModules: this.state.relatedModules
        }
        
        axios.post(`/edit-question`, postObject)
            .then((response) => {
                let foundIndex = this.props.questionData.questions.findIndex(item => item._id === this.state._id)
                this.props.updatePathUnits(this.state.pathUnitTemp)
                let question = this.addExtraDetailsWithQuestion(postObject)
                if (foundIndex > -1) { this.props.questionData.questions[foundIndex] = question }
                this.props.updateQuestions(this.props.questionData)
                this.getNewQuestion("next")
            })
            .catch((error) => {
                console.log("Error Editing Question : ", error)
                alert("Server error while editing question. Check console")
                this.setState({ isLoading: false })
            })
    }

    deleteQuestionApi() {

        const paramsObj = {
            _id: this.state._id
        }

        if (this.state.deleteReason) {
            paramsObj.deleteReason = this.state.deleteReason
        }

        axios.post(`/delete-question`, paramsObj)
            .then((response) => {
                let questions = ([]).concat(this.props.questionData.questions)
                let index =  questions.findIndex(e => e._id === this.state._id)
                if (index > -1) {
                    questions[index].activeFlag = !this.state.activeFlag
                }
                this.props.updateQuestions({ questions })

                this.setState({ isLoading: false, deleteDialog: false, showDeleteSuccess: true, activeFlag:!this.state.activeFlag, deleteReasonInput: false, deleteReason: null })
            })
            .catch((error) => {
                console.error('/delete-question') 
                 console.error(error) 
                })
    }

    setQuestionToState(questionData, direction) {

        let { _id, question, options, answer, subjectId, sectionId, languageId, wrongAttempt, totalAttempt, tags, showInChallenge,
            description, premiumFlag, activeFlag, addedBy, addedOn, exams, type, explanationType, paragraphId, statements, baseQuestion, relatedModules } = questionData;

        const examsIncludingThisQuestion = []

        if (Array.isArray(exams) && Array.isArray(this.props.posts) && exams.length > 0) {
            this.props.posts.forEach((post) => {
                if (Array.isArray(post.exams)) {
                    post.exams.forEach(exam => {
                        const checked = exams.includes(exam._id) && exam.active
                        if (checked) {
                            const label = `${exam.description} - ${exam.code ? exam.code : ''}`
                            examsIncludingThisQuestion.push(label)
                        }
                    })
                }
            })
        }

        if (!type) {
            type = "text"
        }

        this.setState({
            index: direction ? direction === 'next' ? this.state.index + 1: this.state.index - 1 : this.state.index,
            _id: _id,
            question,
            option1:options[0].text,
            option1Type:options[0].type,
            option2:options[1].text,
            option2Type:options[1].type,
            option3:options[2].text,
            option3Type:options[2].type,
            option4:options[3].text,
            option4Type:options[3].type,
            answer,
            subjectId,
            sectionId,
            languageId,
            wrongAttempt,
            totalAttempt,
            tags,
            activeFlag,
            // approved,
            isLoading: false,
            showDeleteSuccess: false,
            showInChallenge,
            description,
            explanationType,
            premiumFlag,
            exams,
            gotQuestion:true,
            addedBy:addedBy,
            addedOn:addedOn,
            type,
            examsIncludingThisQuestion,
            paragraphId,
            pathUnitTemp: cloneDeep(this.state.pathUnitTemp),
            statements,
            baseQuestion,
            unitCheckListArr: [],
            relatedModules
        }, () => {
            this.updateSelectFields(explanationType, description)
            this.fetchSimilarQuestions(_id, question)
        })

    }

    addExtraDetailsWithQuestion(question) {

        let returnData = question

        this.state.pathUnitTemp.forEach(eachPath => {
            eachPath.units.forEach(eachUnit => {
                eachUnit.topics.forEach(eachTopic => {
                    this.props.packs.forEach(eachPack => {
                        if (Array.isArray(eachTopic.questions) && eachTopic.questions.includes(returnData._id) && (eachPack.postId === eachUnit.postId) ) {
                            const pushStr = `${eachPack.title} - ${eachUnit.unitName} - ${eachTopic.name}`
                            if (Array.isArray(returnData.pathNames)) {
                                returnData.pathNames.push(pushStr)
                            } else {
                                returnData.pathNames = [pushStr]
                            }

                        }
                    })
                })
            })
        })

        this.props.posts.forEach(eachPost => {
            eachPost.exams.forEach(eachExam => {
                if (eachExam.examType === "mock" && Array.isArray(eachExam.questions) && eachExam.questions.includes(returnData._id)) {
                    const pushStr = `${eachPost.name} - ${eachExam.description}`
                    if (Array.isArray(returnData.examNames && !returnData.examNames.includes(pushStr))) {
                        returnData.examNames.push(pushStr)
                    } else {
                        returnData.examNames = [pushStr]
                    }
                }
            })
        })

        returnData.subjectName = "Not found"
        returnData.sectionName = "Not found"

        const foundSubjectIndex = this.props.subjects.findIndex(eachSubject => eachSubject._id === returnData.subjectId)
        if (foundSubjectIndex >= 0) {
            returnData.subjectName = this.props.subjects[foundSubjectIndex].name
            const foundSectionIndex = this.props.subjects[foundSubjectIndex].sections.findIndex(eachSection => eachSection._id === returnData.sectionId)
            if (foundSectionIndex >= 0) {
                returnData.sectionName = this.props.subjects[foundSubjectIndex].sections[foundSectionIndex].name
            }
        }

        return returnData
    }

    fetchSimilarQuestions(questionId, question) {
        axios.get(`${SEARCH_SERVER}/search/similar-questions`, { params: { questionId, question: striptags(question) } })
            .then(res => {
                this.setState({ similarQuestions: res.data })
            })
            .catch(err => {
                console.error('search_server/search/similar-questions')
                console.error(err)
            })
    }

    applyQuestion(direction) {

        let newData;

        if (direction === 'next') {
            newData = this.state.questions[this.state.index + 1]
        } else {
            newData = this.state.questions[this.state.index - 1];
        }

        if (newData) {
            this.setQuestionToState(newData, direction)
        } else {
            this.setState({ isLoading:false })
            alert("Reached end of questions")
        }

        this.setState(oldState => {
            oldState.searchParams.index = direction === 'next' ? parseInt(oldState.searchParams.index) + 1 : parseInt(oldState.searchParams.index) - 1
            return { searchParams: oldState.searchParams }
        }, () => {
            this.props.history.push({
                pathname: "/questions/edit",
                search: this.checkAndReturnParams(),
            })
        })
    }

    fetchQuestion = (direction, skipCount) => {

        if (this.state.searchParams.backRoute === 'reports') {
            
            let paramsObj = {
                skipCount, 
                type: this.state.searchParams.issueType,
                sortByDate: this.state.searchParams.sortByDate,
                sortByCount: this.state.searchParams.sortByCount 
            }

            axios.get('/fetchReports', { params: paramsObj })
                .then(response => {
                    if (direction === 'next') {
                        this.setState({questions: [...this.state.questions,...response.data.reports], isLoading: false, currentSkipCount: skipCount},() => {
                            this.setState({index: this.state.index + 1})
                            this.applyQuestion(direction)
                        })
                    } else {
                        this.setState({questions: [...response.data.reports, ...this.state.questions], isLoading: false, currentSkipCount: skipCount},() => {
                            this.setState({index: 50},()=>{
                                this.applyQuestion(direction)
                            })
                        })
                    }
                })
                .catch( err => {
                    console.error('/fetchReports')
                    console.error(err)
                    alert('Error, check console')
                })
        } else {

            this.setState({ isLoading: true })
            
            let data = {
                skipCount: skipCount,
                questionStatus: "all",
                tagId: "1",
                count: 50
            }

            if (this.state.searchParams.addedBy) { 
                data.addedBy = this.state.searchParams.addedBy
            } else if (this.state.searchParams.editedBy) {
                data.lastEditedBy = this.state.searchParams.editedBy
            }

            if (this.state.searchParams.searchText) { data.searchText = this.state.searchParams.searchText }
            if (this.state.searchParams.activeFlag) { data.activeFlag = this.state.searchParams.activeFlag }
            if (this.state.searchParams.subjectId) { data.subjectId = this.state.searchParams.subjectId }
            if (this.state.searchParams.questionType) { data.questionType = this.state.searchParams.questionType }
            if (this.state.searchParams.languageId) { data.languageId = this.state.searchParams.languageId }
            if (this.state.searchParams.topicId) { data.topicId = this.state.searchParams.topicId }
            if (this.state.searchParams.unitId) { data.unitId = this.state.searchParams.unitId }
            if (this.state.searchParams.sectionId) { data.sectionId = this.state.searchParams.sectionId }
            if (this.state.searchParams.period) { data.period = this.state.searchParams.period }
            if (this.state.searchParams.dailyChallengeId) { data.dailyChallengeId = this.state.searchParams.dailyChallengeId }
            if (this.state.searchParams.showInChallenge) { data.showInChallenge = this.state.searchParams.showInChallenge }
            if (this.state.searchParams.currentAffairsPeriod) { data.currentAffairsPeriod = this.state.searchParams.currentAffairsPeriod }

            axios.get(`/get-questions`, { params: data })
                .then(response => {
                    if (direction === 'next') {
                        this.setState({questions: [...this.state.questions,...response.data.data.questions], isLoading: false, currentSkipCount: skipCount},()=>{
                            this.setState({index: this.state.index + 1})
                            this.applyQuestion(direction)
                        })
                    } else {
                        this.setState({questions: [...response.data.data.questions, ...this.state.questions], isLoading: false, currentSkipCount: skipCount},()=>{
                            this.setState({index: 50},()=>{
                                this.applyQuestion(direction)
                            })
                        })
                    }
                })
                .catch(err => {
                    console.error('/get-questions')
                    console.error(err)
                    alert("Error, check console")
                })
        }

    }

    getNewQuestion(direction) {
        const { count } = this.props.questionData;
        this.setState({ selectedWords:[] })
        
        this.props.history.push({
            pathname: "/questions/edit",
            search: this.checkAndReturnParams(direction),
        })

        let { currentSkipCount, index, questions, initialSkipCount } = this.state;

        if (direction === 'next') {
            if ((index + 1) % 50 === 0 && initialSkipCount !== (count - questions.length)) {
                this.fetchQuestion(direction, currentSkipCount + 50)
            } else if(index + 1 === questions.length && initialSkipCount === (count - questions.length)) {
                this.setState({openEndDialog: true})
            } else{
                this.applyQuestion(direction)
            }
        } else {
            if (index <= 0 && currentSkipCount !== 0) {
                this.fetchQuestion(direction, currentSkipCount - 50)
            } else if(index <=0 && currentSkipCount === 0){
                this.setState({openEndDialog: true})
            } else {
                this.applyQuestion(direction)
            }
        }
    }

    addOrRemoveTag(tagId) {
        this.setState((oldState) => {
            let foundIndex = oldState.tags.indexOf(tagId)
            if (foundIndex > -1) {
                oldState.tags.splice(foundIndex, 1);
                return { tags: oldState.tags }
            } else {
                oldState.tags.push(tagId)
                return { tags: oldState.tags }
            }
        })
    }

    addOrRemoveExam(examId) {
        this.setState((oldState) => {
            let foundIndex = oldState.exams.indexOf(examId)
            if (foundIndex > -1) {
                oldState.exams.splice(foundIndex, 1);
                return { exams: oldState.exams }
            } else {
                oldState.exams.push(examId)
                return { exams: oldState.exams }
            }
        })
    }

    addOrRemoveFromPathUnitTopic(topicId, pathIndex, unitIndex, topicIndex, checked) {
        
        if (!this.state.unitCheckListArr.includes(topicId)) {
            this.setState(oldState => {
                oldState.unitCheckListArr.push(topicId)
                return { unitCheckListArr: oldState.unitCheckListArr }
            })
        }

        let foundIndex = this.state.pathUnitTemp[this.state.selectedPathIndex].units[unitIndex].topics[topicIndex].questions.findIndex((each) => each === this.state._id)

        if (!(foundIndex > -1)) {
            this.setState(oldState => {
                oldState.pathUnitTemp[this.state.selectedPathIndex].units[unitIndex].topics[topicIndex].questions.push(this.state._id)
                return { pathUnitTemp: oldState.pathUnitTemp}
            })
        } else {
            this.setState(oldState => {
                let questionIndex = oldState.pathUnitTemp[this.state.selectedPathIndex].units[unitIndex].topics[topicIndex].questions.indexOf(this.state._id)
                oldState.pathUnitTemp[this.state.selectedPathIndex].units[unitIndex].topics[topicIndex].questions.splice(questionIndex, 1);
                return { pathUnitTemp: oldState.pathUnitTemp }
            })
        }

    }

    formIsValid() {
        const { question, answer, option1, option2, option3, option4, subjectId, sectionId } = this.state
        return (question && answer && option1 && option2 && option3 && option4 && subjectId && sectionId)
    } 

    addOrRemoveUnitToPinnedModules( unitIndex, topicLength){
        let arrayOfTopicIndexes = []
        for (let i = 0; i < topicLength; i++) { arrayOfTopicIndexes.push(i) }
        
        this.setState(oldState => {
            const pinnedUnits = oldState.pinnedPathUnits
            if (Object.keys(pinnedUnits).includes(`${unitIndex}`)) {
                delete pinnedUnits[`${unitIndex}`]
            } else {
                pinnedUnits[unitIndex] = arrayOfTopicIndexes
            }
            return { pinnedPathUnits: pinnedUnits }
            })
    }

    addOrRemoveToPinnedModules(unitIndex, topicIndex) {

        this.setState(oldState => {

            const pinnedUnits = oldState.pinnedPathUnits

            if (Object.keys(pinnedUnits).includes(`${unitIndex}`)) {
                if (pinnedUnits[unitIndex].includes(topicIndex)) {
                    pinnedUnits[unitIndex].splice(topicIndex, 1)
                    let test = pinnedUnits[unitIndex].filter(item => item !== topicIndex)
                    pinnedUnits[unitIndex] = test
                } else {
                    pinnedUnits[unitIndex].push(topicIndex)
                }
            } else {
                pinnedUnits[unitIndex] = [topicIndex]
            }
            return { pinnedPathUnits: pinnedUnits }

        })
    }

    renderPinnedUnits(units) {
        return units.map((eachUnit, unitIndex) => {
            if (Object.keys(this.state.pinnedPathUnits).includes(`${unitIndex}`)) {
                return (
                    <div key={eachUnit._id} style={{ border: '1px solid #000' }}>
                        <div style={{
                            display: "flex",
                            justifyContent: 'space-between',
                            background: !eachUnit.activeFlag ? 'rgba(255, 0, 0, 0.1)' : 'none'
                        }}>
                            <h6 style={{ marginLeft: 10 }}>{eachUnit.unitName}</h6>
                            <FontAwesomeIcon
                                icon={faArrowCircleDown}
                                onClick={() => this.addOrRemoveUnitToPinnedModules(unitIndex, eachUnit.topics.length)}
                            />
                        </div>
                        {
                            (eachUnit.topics && eachUnit.topics.length > 0) &&
                            eachUnit.topics.map((eachTopic, topicIndex) => {

                                const checked = eachTopic.questions.includes(this.state._id)
                                const deletedButShow = ((!eachTopic.activeFlag && checked) || eachUnit.activeFlag === false)

                                if (
                                    (eachTopic.activeFlag || checked) &&
                                    (eachUnit.activeFlag || checked) &&
                                    this.state.pinnedPathUnits[unitIndex].includes(topicIndex)
                                ) {
                                    return (
                                        <div
                                            key={eachTopic._id}
                                            style={{ display: 'flex', justifyContent: 'space-between', background: deletedButShow ? 'rgba(255, 0, 0, 0.1)' : 'none' }}
                                        >
                                            <CustomInput
                                                onChange={(event) => { this.addOrRemoveFromPathUnitTopic(event.target.value, this.state.selectedPathIndex, unitIndex, topicIndex, checked) }}
                                                id={eachTopic._id}
                                                key={eachTopic._id}
                                                value={eachTopic._id}
                                                type="checkbox"
                                                label={eachTopic.name}
                                                checked={checked}
                                            />
                                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <FontAwesomeIcon
                                                    icon={faArrowCircleDown}
                                                    style={{ height: 10 }}
                                                    onClick={() => this.addOrRemoveToPinnedModules(unitIndex, topicIndex)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }


                            })
                        }
                    </div>
                )
            }
        })
    }

    renderPathModules(units, pinnedModules) {
        return units.map((eachUnit, unitIndex) => {
            return (
                <div
                    key={eachUnit._id}
                    style={{ border: '1px solid #000' }}
                >
                    
                    <div style={{
                        display:"flex",
                        justifyContent:"space-between",
                        background: !eachUnit.activeFlag ? 'rgba(255, 0, 0, 0.1)' : 'none'
                    }}>
                        <h6 style={{ marginLeft: 10 }}>{eachUnit.unitName}</h6>
                        <FontAwesomeIcon
                            icon={faArrowCircleUp}
                            onClick={ () => this.addOrRemoveUnitToPinnedModules(unitIndex, eachUnit.topics.length) } 
                        />
                    </div>

                    {
                        (eachUnit.topics && eachUnit.topics.length > 0) &&
                        eachUnit.topics.map((eachTopic, topicIndex) => {
                            const checked = eachTopic.questions.includes(this.state._id)

                            const deletedButShow = ((!eachTopic.activeFlag && checked) || eachUnit.activeFlag === false)

                            if ((eachTopic.activeFlag || checked) && (eachUnit.activeFlag || checked)) {
                                return (
                                    <div
                                        key={eachTopic._id}
                                        style={{ display: 'flex', justifyContent: 'space-between', background: deletedButShow ? 'rgba(255, 0, 0, 0.1)' : 'none' }}
                                    >
    
                                        <CustomInput
                                            onChange={(event) => { this.addOrRemoveFromPathUnitTopic(event.target.value, eachTopic, unitIndex, topicIndex, checked) }}
                                            id={eachTopic._id}
                                            key={eachTopic._id}
                                            value={eachTopic._id}
                                            type="checkbox"
                                            label={eachTopic.name}
                                            checked={checked}
                                        />
    
                                        {
                                            !pinnedModules &&
                                            <div style={{ paddingLeft: 10, paddingRight: 10 }} >
                                                <FontAwesomeIcon
                                                    icon={faArrowCircleUp}
                                                    style={{ height: 12 }}
                                                    onClick={() => this.addOrRemoveToPinnedModules(unitIndex, topicIndex)}
                                                />
                                            </div>
                                        }
    
                                    </div>
                                )
                            }

                        })
                    }
                </div>
            )
        })
    }

    updateOptions = (baseQuestion, statements, fourOptions) => {

        const setObject = { }

        if (Array.isArray(fourOptions) && fourOptions.length === 4) {

            let optionsArray = ["A", "B", "C", "D"]
            
            optionsArray.forEach((eachOption, index) => {

                if (fourOptions[index].isCorrect) {
                    setObject.answer = eachOption
                }

                setObject[`option${index + 1}`] = fourOptions[index].text
                setObject[`option${index + 1}Type`] = "text"

            })
            
            if (!setObject.answer) {
                return alert("Correct answer not found")
            }

        } else {
            alert("Need exactly four options")
        }

        if (this.state.type === "statement" && (!Array.isArray(statements) || statements.length <= 0)) {
            return ("Should have at least two statements")
        } else {
            setObject.statements = statements
        }

        if (this.state.type === "match" && (!Array.isArray(statements) || statements.length !== 4)) {
            return ("Should have exactly four statements")
        } else {
            setObject.statements = statements
        }

        if (baseQuestion) {
            setObject.baseQuestion = baseQuestion
        } else {
            alert("Unable to find base question. Check console")
        }

        setObject.question = getHtmlQuestion(this.state.type, baseQuestion, statements)

        this.setState(setObject)

    }

    renderQuestionField = () => {

        const { type } = this.state

        if (type === questionTypes.TEXT) {
            return (
                <Input
                    type="textarea"
                    style={{ marginBottom: 10 }}
                    placeholder="Question"
                    rows={3}
                    id={"4"}
                    name="question"
                    value={this.state.question || ''}
                    onChange={this.handleInput}
                    onBlur={() => {

                        // 1. update pills for related subjects matching
                        this.updatePills()

                        // 2. Set question language
                        if (this.state.question && this.state.islanguagePrediction) {
                            if (RegExp(QUESTION_LANGUAGE_REGEX).test(this.state.question.replace(REMOVE_CHARS_REGEX, ""))) {
                                this.setState({ languageId: this.props.languages[1].id })
                            } else {
                                this.setState({ languageId: this.props.languages[0].id })
                            }
                        }

                        // 3. Remove extra empty spaces
                        const extraSpacesRemoved = fixSpacesBetweenStrings(this.state.question)
                        this.setState({ question: extraSpacesRemoved })

                    }}
                />
            )
        } else if (type === questionTypes.MATH || type === questionTypes.HTML) {
            return (
                <TinyEditor
                    contentCss={'dark'}
                    content={this.state.question}
                    onHtmlChange={ (newHtml) => {
                        this.updatePills(newHtml)
                        this.setState({ question: newHtml })
                    }}
                />
            )
        } else if (type === questionTypes.STATEMENT) {
            return (
                <StatementType
                    questionId={this.state._id}
                    question={this.state.question}
                    currentStatements={this.state.statements}
                    currentBaseQuestion={this.state.baseQuestion}
                    onChangeQuestion={this.handleInput}
                    onBlurQuestion={() => {
                        this.fetchSimilarQuestions(this.state.question)
                        if (isString(this.state.question) && this.state.islanguagePrediction) {
                            RegExp(QUESTION_LANGUAGE_REGEX).test(this.state.question.replace(REMOVE_CHARS_REGEX, "")) ? this.setState({ languageId: this.props.languages[1].id }) : this.setState({ languageId: this.props.languages[0].id })
                        }
                    }}
                    questionLanguageId={this.state.languageId}
                    updateOptions={this.updateOptions}
                />
            )
        } else if (type === questionTypes.MATCH) {
            return (
                <MatchType
                    questionId={this.state._id}
                    currentStatements={this.state.statements}
                    currentBaseQuestion={this.state.baseQuestion}
                    onChangeQuestion={this.handleInput}
                    onBlurQuestion={() => {
                        this.fetchSimilarQuestions(this.state.question)
                        if (this.state.question && this.state.islanguagePrediction) {
                            RegExp(QUESTION_LANGUAGE_REGEX).test(this.state.question.replace(REMOVE_CHARS_REGEX, "")) ? this.setState({ languageId: this.props.languages[1].id }) : this.setState({ languageId: this.props.languages[0].id })
                        }
                    }}
                    questionLanguageId={this.state.languageId}
                    updateOptions={this.updateOptions}
                />
            )
        } else {
            return (
                <h5>Please select a valid question type</h5>
            )
        }
    }

    render() {
        const endActions = [
            <FlatButton
                label="Okay"
                primary={true}
                onClick={() => this.setState({ openEndDialog: false, isLoading: false })}
            />
        ];

        const editActions = [
            <FlatButton
                label="Previous Question"
                primary={true}
                onClick={() => this.getNewQuestion("previous")}
            />,
            <FlatButton
                label="Home"
                primary={true}
                onClick={() => this.props.history ? this.props.history.push('/') : "Cannot Go Home, No Nav in Props"}
            />,
            <FlatButton
                label="Next Question"
                primary={true}
                onClick={() => this.getNewQuestion("next")}
            />
        ];

        const deleteActions = [
            <FlatButton
                label="Yes"
                primary={true}
                onClick={() => this.deleteQuestionApi()}
            />,
            <FlatButton
                label="No"
                secondary={true}
                onClick={() => this.setState({ deleteDialog: false })}
            />,
        ];

        if (this.state.gotQuestion) {
            return (
                <Container fluid style={{ flex: 1, flexDirection: 'row', padding: 25 }}>

                    {
                        this.state.showRelatedModulesModal ?
                            <RelatedModulesModal
                                showRelatedModulesModal={this.state.showRelatedModulesModal}
                                relatedModules={this.state.relatedModules}
                                close={() => this.setState({ showRelatedModulesModal: false })}
                                saveRelatedModules={(relatedModules) => this.setState({ relatedModules, showRelatedModulesModal: false })}
                            />
                            : null
                    }

                    {
                        this.state.isLoading &&
                            <div style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                paddingTop: '30%',
                                paddingLeft: '47%',
                                zIndex:99
                            }}>
                                <CircularProgress size={80} thickness={5} />
                            </div>
                    }

                    <Row>

                        <Col md="6" sm="12" style={{ display: 'flex'}}>
                            <Button
                                color="primary"
                                disabled={this.state.index === 0}
                                outline
                                onClick={() => this.getNewQuestion("previous")}
                            >Previous</Button>
                            <FormGroup tag="fieldset" style={{ marginBottom: 0, marginLeft: '20px' }}>
                                <Input
                                    type="select" name="select" id="questionType"
                                    value={this.state.type}
                                    onChange={(e) => this.setState({ type: e.target.value })}
                                >
                                    {
                                        Object.keys(questionTypes).map(each => (
                                            <option key={questionTypes[each]} value={questionTypes[each]}>
                                                {questionTypes[each]}
                                            </option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col md="4" sm="12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            
                            <Button
                                color="success"
                                block
                                style={{ color: 'white', height:'100%' }}
                                disabled={!(this.formIsValid())}
                                onClick={() => this.submitQuestion()}
                            >Submit</Button>

                            {
                                (
                                    isContentReviewerOrHigher(this.props.auth.roleIds) ||
                                    (this.state.addedBy && this.props.auth._id === this.state.addedBy._id)
                                ) &&
                                    <Button
                                        outline={this.state.activeFlag ? false : true}
                                        color="danger"
                                        style={{ marginTop:0, marginLeft:10, paddingLeft: 20, paddingRight: 20 }}
                                        onClick={() => this.state.activeFlag ? this.setState({ showDeleteReasonModal: true }) : this.setState({ deleteDialog: true })}
                                    >{this.state.activeFlag ? "Delete" : "Undelete"}</Button>
                            }
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <EditorOptionModal
                                onSubmit={(isShowAllSubjectChecked, islanguagePredictionChecked) => this.setState({ showAllSubject: isShowAllSubjectChecked, islanguagePrediction: islanguagePredictionChecked })}
                                showAllSubject={this.state.showAllSubject}
                                islanguagePrediction={this.state.islanguagePrediction}
                            />
                            <Button
                                color="primary"
                                outline
                                onClick={() => this.getNewQuestion("next")}
                            >Next</Button>
                        </Col>

                    </Row>

                    <Row>

                        <Col sm={(Array.isArray(this.state.pathUnitTemp) && this.state.pathUnitTemp.length > 0) ? "8" : "10"}>

                            <div>
                                <Dialog
                                    title="Confirm Delete"
                                    actions={deleteActions}
                                    modal={true}
                                    open={this.state.deleteDialog}
                                >
                                    Are you sure you want to delete this question?
                               </Dialog>
                            </div>

                            <div>
                                <Modal
                                    isOpen={this.state.showDeleteReasonModal}
                                >
                                    <ModalHeader>
                                        Why do you want to delete this question?
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            
                                            <Form style={{ marginLeft: 20, disPlay: "flex", justifyContent: "space-between" }}>

                                                <Row style={{ marginLeft: 20 }}>

                                                    <Col>
                                                        <FormGroup>

                                                            <CustomInput
                                                                type="radio"
                                                                id="exampleCustomRadio"
                                                                name="customRadio"
                                                                label="Question is wrong"
                                                                value='Question is wrong'
                                                                onChange={(e) => this.setState({ deleteReason: e.target.value, deleteReasonInput: false })}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>

                                                            <CustomInput
                                                                type="radio"
                                                                id="exampleCustomRadio2"
                                                                name="customRadio"
                                                                label="Duplicate"
                                                                value='Duplicate'
                                                                onChange={(e) => this.setState({ deleteReason: e.target.value, deleteReasonInput: false})}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <Row style={{ marginLeft: 20 }}>

                                                    <Col>
                                                        <FormGroup>

                                                            <CustomInput
                                                                type="radio"
                                                                id="exampleCustomRadio3"
                                                                name="customRadio"
                                                                label="Couldn't find answer"
                                                                value="Couldn't find answer"
                                                                onChange={(e) => this.setState({ deleteReason: e.target.value, deleteReasonInput: false })}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>

                                                            <CustomInput
                                                                type="radio"
                                                                id="exampleCustomRadio4"
                                                                name="customRadio"
                                                                label="Other"
                                                                onClick={() => this.setState({ deleteReason: "", deleteReasonInput: true })}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                
                                            </Form>

                                        </Row>

                                        {
                                            this.state.deleteReasonInput &&

                                            <Row style={{ marginLeft: 20 }}>
                                                <Label>Enter the Reason</Label>
                                                <Input
                                                    style={{ marginRight: 30 }}
                                                    value={this.state.deleteReason || ''}
                                                    onChange={(e) => this.setState({ deleteReason: e.target.value })}
                                                >
                                                </Input>
                                            </Row>
                                        }

                                    </ModalBody>
                                    <ModalFooter>
                                        <Row>
                                            <Button
                                                color='primary'
                                                onClick={() => {
                                                    if (!this.state.deleteReason) {
                                                        alert("Please choose one")
                                                    } else {
                                                        this.setState({ showDeleteReasonModal: false, deleteReasonInput: false }, () => {
                                                            this.deleteQuestionApi()
                                                        })
                                                    }
                                                }}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                style={{ marginLeft: 10,marginRight:10 }}
                                                onClick={() => this.setState({ showDeleteReasonModal: false, deleteReasonInput: false, deleteReason: null })}
                                            >
                                                Cancel
                                            </Button>

                                        </Row>
                                    </ModalFooter>
                                </Modal>
                            </div>

                            <div>
                                <Dialog
                                    title="Delete Success"
                                    actions={editActions}
                                    modal={true}
                                    open={this.state.showDeleteSuccess}
                                > Question deleted successfully.
                        </Dialog>
                            </div>

                            <div>
                                <Dialog
                                    title="Don't Wander"
                                    actions={endActions}
                                    modal={true}
                                    open={this.state.openEndDialog}
                                >  Reached End of Questions  </Dialog>
                            </div>

                            <br />


                            <Row>
                                <Col md="12">
                                    {this.renderQuestionField()}
                                </Col>
                            </Row>

                            {
                                (this.state.type === "math") &&
                                <ListGroupItem>
                                    <MathQuestionViewer
                                        delimiter={"$"}
                                        mathText={this.state.question}
                                    />
                                </ListGroupItem>
                            }

                            {
                                this.state.splittedWords.map((eachItem, index) => {
                                    let color = "secondary"
                                    if(this.state.selectedWords.includes(eachItem)) { color = "primary" }
                                    return <Badge onClick={ () => this.clickedWord(eachItem) } style={{ margin:2, fontSize:16 }} color={color} key={index}>{eachItem}</Badge>
                                })
                            }

                            <Row style={{ marginTop: 20 }}>
                                <Col md="12">
                                    <SimilarQuestions similarQuestions={this.state.similarQuestions} />
                                </Col>
                            </Row>

                            <div style={{ flex: 1, marginTop: 25 }}>
                                <Row>
                                    <Col md="10">
                                        <TextField
                                            id={"1"}
                                            value={this.state.option1 || ''}
                                            hintText="Option 1"
                                            name="option1"
                                            fullWidth={true}
                                            onChange={this.handleInput}
                                            disabled={this.disableOptionsIfStatementOrMatchType()}
                                        />
                                        {
                                            this.state.option1Type === "math" &&
                                            <ListGroupItem>
                                                <MathQuestionViewer delimiter="$$" mathText={this.state.option1} />
                                            </ListGroupItem>
                                        }
                                    </Col>
                                    <Col md="2">
                                        <CheckBox
                                            label={'Math'}
                                            style={{ marginTop: '10px' }}
                                            checked={this.state.option1Type === "math"}
                                            onClick={() => {
                                                this.setState((oldState) => {

                                                    if (this.state.option1Type === "math") {
                                                        oldState.option1Type = "text"
                                                    } else {
                                                        oldState.option1Type = "math"
                                                    }

                                                    return oldState
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md="10">
                                        <TextField
                                            id={"2"}
                                            value={this.state.option2 || ''}
                                            hintText="Option 2"
                                            name="option2"
                                            fullWidth={true}
                                            onChange={this.handleInput}
                                            disabled={this.disableOptionsIfStatementOrMatchType()}
                                        />
                                        {
                                            this.state.option2Type === "math" &&
                                            <ListGroupItem>
                                                <MathQuestionViewer delimiter="$$" mathText={this.state.option2} />
                                            </ListGroupItem>
                                        }
                                    </Col>
                                    <Col md="2">
                                        <CheckBox
                                            label={'Math'}
                                            style={{ marginTop: '10px' }}
                                            checked={this.state.option2Type === "math"}
                                            onClick={() => {
                                                this.setState((oldState) => {

                                                    if (this.state.option2Type === "math") {
                                                        oldState.option2Type = "text"
                                                    } else {
                                                        oldState.option2Type = "math"
                                                    }

                                                    return oldState
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md="10">
                                        <TextField
                                            id={"3"}
                                            value={this.state.option3 || ''}
                                            hintText="Option 3"
                                            name="option3"
                                            fullWidth={true}
                                            onChange={this.handleInput}
                                            disabled={this.disableOptionsIfStatementOrMatchType()}
                                        />
                                        {
                                            this.state.option3Type === "math" &&
                                            <ListGroupItem>
                                                <MathQuestionViewer delimiter="$$" mathText={this.state.option3} />
                                            </ListGroupItem>
                                        }
                                    </Col>
                                    <Col md="2">
                                        <CheckBox
                                            label={'Math'}
                                            style={{ marginTop: '10px' }}
                                            checked={this.state.option3Type === "math"}
                                            onClick={() => {
                                                this.setState((oldState) => {

                                                    if (this.state.option3Type === "math") {
                                                        oldState.option3Type = "text"
                                                    } else {
                                                        oldState.option3Type = "math"
                                                    }

                                                    return oldState
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md="10">
                                <TextField
                                    id={"4"}
                                    value={this.state.option4 || ''}
                                    hintText="Option 4"
                                    name="option4"
                                    fullWidth={true}
                                    onChange={this.handleInput}
                                    disabled={this.disableOptionsIfStatementOrMatchType()}
                                />
                                        {
                                            this.state.option4Type === "math" &&
                                            <ListGroupItem>
                                                <MathQuestionViewer delimiter="$$" mathText={this.state.option4} />
                                            </ListGroupItem>
                                        }
                                    </Col>
                                    <Col md="2">
                                        <CheckBox
                                            label={'Math'}
                                            style={{ marginTop: '10px' }}
                                            checked={this.state.option4Type === "math"}
                                            onClick={() => {
                                                this.setState((oldState) => {

                                                    if (this.state.option4Type === "math") {
                                                        oldState.option4Type = "text"
                                                    } else {
                                                        oldState.option4Type = "math"
                                                    }

                                                    return oldState
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            {
                                isAdmin(this.props.auth.roleIds) &&
                                    <div>
                                        <TextField
                                            // id={"1"}
                                            value={this.state.wrongAttempt || ''}
                                            hintText="Wrong Attempt"
                                            floatingLabelText="Wrong Attempt"
                                            name="wrongAttempt"
                                            disabled={true}
                                            type="number"
                                            onChange={this.handleInput}
                                        />

                                        <TextField
                                            // id={"2"}
                                            value={this.state.totalAttempt || ''}
                                            hintText="Total Attempt"
                                            floatingLabelText="Total Attempt"
                                            name="totalAttempt"
                                            disabled={true}
                                            type="number"
                                            onChange={this.handleInput}
                                        />

                                        <TextField
                                            // id={"3"}
                                            value={
                                                this.state.wrongAttempt && this.state.totalAttempt ?
                                                    this.state.wrongAttempt / this.state.totalAttempt || '' : ''
                                            }
                                            floatingLabelText="Difficulty"
                                            hintText="Difficulty"
                                            name="difficulty"
                                            type="number"
                                            disabled={true}
                                            onChange={this.handleInput}
                                        />
                                    </div>
                            }

                            <div style={{ display: 'block' }}>
                                <SelectField
                                    value={this.state.answer}
                                    floatingLabelText="Correct Answer"
                                    onChange={(event, third, value) => this.handleMenuChange("answer", value)}
                                >
                                    <MenuItem value={"A"} primaryText="Option 1" />
                                    <MenuItem value={"B"} primaryText="Option 2" />
                                    <MenuItem value={"C"} primaryText="Option 3" />
                                    <MenuItem value={"D"} primaryText="Option 4" />
                                </SelectField>

                                {/* <SelectField
                                    floatingLabelText="Approved"
                                    value={this.state.approved}
                                    onChange={(event, index, value) => this.setState({ approved:value, error: false })}
                                >
                                    <MenuItem value={true} primaryText="Approved" />
                                    <MenuItem value={false} primaryText="Not Aprroved" />
                                </SelectField> */}
                            </div>

                            <Row>
                                <Col md="6">
                                    <TextField
                                        style={{ marginTop: 10, marginBottom: 20 }}
                                        id={"paragraphId"}
                                        value={this.state.paragraphId || ''}
                                        hintText="Paragraph ID (Optional)"
                                        name="paragraphId"
                                        fullWidth={true}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>

                            <Container fluid>
                                
                                <ExplanationEditor
                                    explanationType={this.state.explanationType}
                                    description={this.state.description}
                                    handleInput={this.handleInput}
                                    updateDescriptionState={(description) => this.setState({ description })}
                                    updateExplanationType={(explanationType) => this.setState({ explanationType })}
                                />

                                <Row>
                                    <Col md="4">
                                        <CheckBox
                                            label={'Show in Challenge'}
                                            style={{ marginTop: '10px' }}
                                            checked={this.state.showInChallenge}
                                            disabled={!isContentReviewerOrHigher(this.props.auth.roleIds)}
                                            onClick={() => {
                                                this.setState({ showInChallenge: !this.state.showInChallenge })
                                            }}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <CheckBox
                                            label={'Premium Question'}
                                            style={{ marginTop: '10px' }}
                                            checked={this.state.premiumFlag}
                                            disabled={!isContentReviewerOrHigher(this.props.auth.roleIds)}
                                            onClick={() => {
                                                this.setState({ premiumFlag: !this.state.premiumFlag })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 25 }}>
                                    <Col sm="12">
                                        <div>
                                            {
                                                this.state.descriptions && this.state.descriptions.length > 0 ?
                                                    <Table hover>
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Similar Explanations</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.descriptions.map((each, index) => {
                                                                    return (
                                                                        <tr key={index} onClick={() => this.setState({ description: each })} >
                                                                            <td>{index + 1}</td>
                                                                            <td>{each}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <Badge style={{ padding: 10, fontSize: 18 }}>No similar explanations fetched</Badge>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>

                        <Col sm="2">

                            {
                                isContentReviewerOrHigher(this.props.auth.roleIds) &&
                                <Col style={{ textAlign: 'right' }}>
                                    <p style={{ color: 'grey', marginTop: 10 }}>Added On: { this.state.addedOn && moment(this.state.addedOn).format('D MMM YYYY HH:mm:ss') }</p>
                                    <p style={{ color: 'grey' }}>Added By: { this.state.addedBy && this.state.addedBy.name }</p>
                                </Col>
                            }

                            {
                                isAdmin(this.props.auth.roleIds) &&
                                <Button
                                    color="primary"
                                    outline
                                    style={{ display: 'block', textAlign: 'center', marginBottom: 20 }}
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: "/generate-question-link",
                                            search: `questionid=${this.state._id}`,
                                        })
                                    } }
                                    disabled={!this.state.question}
                                >Generate Question Link</Button>
                            }

                            <Button
                                color="primary"
                                outline
                                style={{ textAlign:'center' }}
                                onClick={() => this.predictData()}
                                disabled={!this.state.question}
                            >Predict Subject & Section</Button>

                            <SelectField
                                floatingLabelText="Subject"
                                value={this.state.subjectId}
                                onChange={(event, third, value) => this.handleMenuChange("subjectId", value)}
                            >
                                {
                                    this.props.subjects ?
                                        this.props.subjects.map(subject => {
                                            return <MenuItem
                                                key={subject._id}
                                                value={subject._id}
                                                primaryText={subject.name}
                                                disabled={!subject.active && !this.state.showAllSubject}
                                                onClick={() => {
                                                    const clonedSections = cloneDeep(subject.sections)
                                                    const sortedSections = sortBy(clonedSections, ['name'])
                                                    this.setState({ sections: sortedSections, chapter: '' })
                                                }}
                                            />
                                        })
                                        :
                                        null
                                }
                            </SelectField>

                            <SelectField
                                disabled={!this.state.subjectId}
                                floatingLabelText={this.state.subjectId ? "Section" : "Section (Select Subject First)" }
                                value={this.state.sectionId}
                                onChange={(event, third, value) => this.handleMenuChange("sectionId", value)}
                            >
                                {
                                    this.state.sections ?
                                        this.state.sections.map(section => {
                                            return <MenuItem
                                                key={section._id}
                                                value={section._id}
                                                primaryText={section.name}
                                                disabled={!section.activeFlag && !this.state.showAllSubject}
                                            />
                                        })
                                        :
                                        null
                                }
                            </SelectField>

                            <SelectField
                                floatingLabelText="Language"
                                value={this.state.languageId}
                                onChange={(event, third, value) => this.handleMenuChange("languageId", value)}
                            >
                                {
                                    this.props.languages ?
                                        this.props.languages.map(languages => {
                                            return <MenuItem
                                                key={languages.id}
                                                value={languages.id}
                                                primaryText={languages.name}
                                            />
                                        })
                                        :
                                        null
                                }
                            </SelectField>

                            <Row style={{ marginTop: 20, marginBottom: 20 }}>
                                <Col md="12" >
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        Related Modules: {Array.isArray(this.state.relatedModules) ? this.state.relatedModules.length : "0"}
                                        <Button
                                            color="primary"
                                            onClick={() => this.setState({ showRelatedModulesModal: true })}
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <hr />

                            {
                                Array.isArray(this.state.examsIncludingThisQuestion) && this.state.examsIncludingThisQuestion.length > 0 &&
                                <div>
                                    <p style={{ marginTop: 10, marginBottom: -1 }}><strong>Exams</strong></p>
                                    { this.state.examsIncludingThisQuestion.map((each, index) => <p key={index}>- {each}</p>) }
                                </div>
                            }

                            <Button
                                block
                                color="primary"
                                onClick={()=> this.setState({ isExamListOpen : !this.state.isExamListOpen })}
                                style={{ marginBottom: '1rem' }}>
                                    Show Exam List
                            </Button>

                            <Collapse isOpen={this.state.isExamListOpen}>
                                <div style={{ overflowY: 'scroll', height:400 }}>
                                    <FormGroup>
                                        <Label for="tags">Exam</Label>
                                        <div>
                                            {
                                                this.props.posts && this.props.posts.map((post) => {
                                                    if (post.exams.length > 0) {
                                                        return <div key={post._id} style={{ border: '1px solid #000' }}>
                                                            <p>{post.name}</p>
                                                            {
                                                                post.exams && post.exams.length > 0 && post.exams.map(exam => {
                                                                    
                                                                    const checked = (Array.isArray(this.state.exams) && this.state.exams.includes(exam._id))
                                                                    const label = `${exam.description} - ${exam.code ? exam.code : ''}`
                                                                    
                                                                    return (
                                                                        <CustomInput
                                                                            onChange={ (event) => { this.addOrRemoveExam(event.target.value) } }
                                                                            id={exam._id + post._id}
                                                                            key={exam._id + post._id}
                                                                            value={exam._id}
                                                                            type="checkbox"
                                                                            label={label}
                                                                            checked={checked}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    } else {
                                                        return <span key={post._id}></span>
                                                    }
                                                })
                                            }
                                        </div>
                                    </FormGroup>
                                </div>
                            </Collapse>

                        </Col>
                        
                        {
                            (Array.isArray(this.state.pathUnitTemp) && this.state.pathUnitTemp.length > 0) &&
                            <Col sm="2">

                                {
                                    Array.isArray(this.state.pathUnitTemp) && 
                                    <UncontrolledDropdown style={{ margin: 10 }}>
                                        <DropdownToggle caret>{this.state.pathUnitTemp[this.state.selectedPathIndex] ? this.state.pathUnitTemp[this.state.selectedPathIndex].title : "Select a course"}</DropdownToggle>
                                        <DropdownMenu className="addScroll">
                                            {
                                                this.state.pathUnitTemp.map((each, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={each._id}
                                                            value={each._id}
                                                            onClick={() => this.setState({ selectedPathIndex: index })}
                                                        >
                                                            {each.title}
                                                        </DropdownItem>)
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                                
                                {
                                    this.state.pathUnitTemp && this.state.pathUnitTemp[this.state.selectedPathIndex] &&

                                    <div style={{ border: '1px solid #000' }}>

                                        <h5 style={{ margin: 5 }}>{this.state.pathUnitTemp[this.state.selectedPathIndex].title}</h5>

                                        <>
                                            Pinned Modules    
                                            { this.renderPinnedUnits(this.state.pathUnitTemp[this.state.selectedPathIndex].units) }
                                        </>
                                        
                                        <hr />

                                        { this.renderPathModules(this.state.pathUnitTemp[this.state.selectedPathIndex].units) }
                                        
                                    </div>
                                }

                            </Col>
                        }

                    </Row>

                </Container>
            )
        } else if (this.state.gotQuestion === false) {
            return <h3 style={{ textAlign: 'center' }}>Did not receive question.</h3>
        } else {
            return <h3 style={{ textAlign: 'center' }}>Loading....</h3>
        }
    }
}

const mapStateToProps = state => {

    const availablePathUnits = state.data.pathUnits.filter(each => Array.isArray(each.allowedMembers) && each.allowedMembers.includes(state.auth._id))

    return {
        auth: state.auth,
        questionData: state.data.questions,
        subjects: state.data.subjects,
        allTags: state.data.tags,
        packs: state.data.packs,
        posts: state.data.posts,
        languages: state.data.languages,
        pathUnits: availablePathUnits,
    }
}

export default connect(mapStateToProps, { updateQuestions, updatePosts, updatePathUnits })(QuestionForm)