import React from 'react'
import {
    Container, Col, Row, Input, Label, Form, FormGroup, Spinner,
    Button, ListGroup, ListGroupItem, Collapse, InputGroup,
    Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap'

import axios from 'axios'
import { connect } from 'react-redux'
import { cloneDeep, intersection } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import CheckBox from 'material-ui/Checkbox';
import { rolesNameList, isAdmin, isSalesManagerOrHigher, isContentManagerOrHigher  } from '../utils/dashboardRoles'
import Loader from '../Components/Loader'

class RolesManager extends React.Component {

    state = {
        isOpenIndex: null,
        isLoading: true,
        highLevelUsers: null,
        rolesNameList: this.filterRolesList(cloneDeep(rolesNameList)),
        createUserRolesName: this.filterRolesList(cloneDeep(rolesNameList)),

        showCreateUserModal: false,
        userName: '',
        email: '',
        password: '',
        selectedRoleIds: [],

        showDeletedUsers: false,
        selectedRole: '-1'
    }

    componentDidMount() {
        this.removeAdminsFromRolesNameList("createUserRolesName")
        this.getAllHighLevelUsers(false)
    }

    removeAdminsFromRolesNameList(rolesNameList) {
        this.setState(oldState => {
            oldState[`${rolesNameList}`] = oldState[`${rolesNameList}`].filter(role => !(role.id === 0 || role.id === 1))
            return oldState
        })
    }

    findRoleNamesAndAddWithRolesDetails() {
        this.rolesDetails = this.rolesDetails.map(item => {
            item.rolesNameList = []

            rolesNameList.forEach(eachRole => {
                if (Array.isArray(item.roleIds) && item.roleIds.includes(parseInt(eachRole.id)))   {
                    item.rolesNameList.push(eachRole.name)
                }
            })

            return item
        })
    }

    filterRolesList(filterState) {
        const { roleIds } = this.props
        let saleTeamRoleIds = [ 8, 9 ]
        let contentTeam = [ 13, 15, 16, 17, 18, 19]

        let filteredArr = filterState.filter(role => {

                if (isAdmin(roleIds)) {
                    return true
                } else if (isSalesManagerOrHigher(roleIds) && saleTeamRoleIds.includes(role.id)) {
                    return true
                } else if (isContentManagerOrHigher(roleIds) && contentTeam.includes(role.id)) {
                    return true
                }
            })
        return filteredArr
        
    }

    sortAndfilterHighLevelUsers() {
        const { roleIds } = this.props

        this.rolesDetails.sort(function SortArray(x, y) {
            if (x.name.toLowerCase() < y.name.toLowerCase()) { return -1 }
            if (x.name.toLowerCase() > y.name.toLowerCase()) { return 1 }
            return 0
        })

        const filteredHighLevelUsers = this.rolesDetails.filter(item => {
            if (isAdmin(roleIds) && (Array.isArray(item.roleIds) )) {
                return true
            } else if (isSalesManagerOrHigher(roleIds) && Array.isArray(item.roleIds) && !isAdmin(item.roleIds) && intersection(item.roleIds, [7, 8, 9]).length ){
                return true
            } else if (isContentManagerOrHigher(roleIds) && Array.isArray(item.roleIds) && !isAdmin(item.roleIds) && intersection(item.roleIds, [12, 13, 15]).length ) {
                return true
            }
        })

        return filteredHighLevelUsers
    }

    getAllHighLevelUsers(fetchDeletedUsers) {

        this.setState({ isLoading: true }, () => {
            axios.get('/high-level-users', { params: { fetchDeletedUsers } })
                .then(res => {
                    
                    this.rolesDetails = res.data.highLevelUsers
                    this.findRoleNamesAndAddWithRolesDetails()
                    const filteredHighLevelUsers = this.sortAndfilterHighLevelUsers()
                    this.setState({ highLevelUsers: filteredHighLevelUsers, isLoading: false })
                    
                })
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error, Please check console")
                })
        })
    }

    handleInput = (e) => {
        let name = e.target.name
        let value  = e.target.value
        this.setState({ [name]: value })
    }

    toggleRolePermission(userId, roleId) {
        this.setState({ isLoading: true }, () => {
            axios.post('/update-admin-role', { userId, roleId })
                .then(res => this.getAllHighLevelUsers(this.state.showDeletedUsers))
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error, Please check console")
                })
        })
    }

    createNewUserModal() {
        const { userName, email, password, selectedRoleIds } = this.state

        if (!userName.trim() || !email.trim() || !password.trim() || selectedRoleIds.length === 0) {
            alert("Please fill all fields")
            return 
        } 

        let postObj = {
            name: userName,
            email: email,
            password, 
            roles: selectedRoleIds
        }

        this.setState({ isLoading: true }, () => {
            axios.post('/admin-signup', postObj)
                .then(() => {
                    this.getAllHighLevelUsers(false)
                    this.setState({ userName: '', email: '', password: '', selectedRoleIds: [], isLoading: false, showCreateUserModal: false })
                    alert("User created successfully")
                })
                .catch(err => {
                    if (err && err.response && err.response.status === 400) {
                        alert(err.response.data.message)
                    } else {
                        alert("Error. Check console")
                        console.log(err)
                    }
                    this.setState({ isLoading: false })
                })
        })
    }

    deleteOrUndeleteUser(userId) {
        this.setState({ isLoading: true }, () => {
            axios.post('/toggle-admin-active-flag', { userId })
                .then(res => this.getAllHighLevelUsers(this.state.showDeletedUsers))
                .catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("Error, Please check console")
                })
        })
    }

    renderCreateNewUserModal() {
        return (
            <Modal isOpen={this.state.showCreateUserModal}>
                <ModalHeader>Create New User</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="userName">User Name</Label>
                            <Input
                                placeholder={"User name"}
                                name='userName'
                                type="text"
                                id='userName'
                                onBlur={(e) => this.handleInput(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <InputGroup>
                                <Input
                                    placeholder={"email@keralapsc.app"}
                                    name='email'
                                    type="email"
                                    onBlur={(e) => this.handleInput(e)}
                                    id='email'
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                placeholder={"Enter password"}
                                name='password'
                                id='password'
                                type="password"
                                onBlur={(e) => this.handleInput(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Choose user role permission</Label>
                            <Row>
                                {
                                    this.state.createUserRolesName.map((eachRole) => {
                                        return (
                                            <Col
                                                key={eachRole.id}
                                                md="12"
                                                style={{ paddingTop: 5, paddingBottom: 10 }}
                                            >
                                                <CheckBox
                                                    label={eachRole.name}
                                                    onClick={() => {
                                                        this.setState(oldState => {
                                                            if (this.state.selectedRoleIds.includes(eachRole.id)) { 
                                                                oldState.selectedRoleIds = oldState.selectedRoleIds.filter(item => item !== eachRole.id)
                                                            } else {
                                                                oldState.selectedRoleIds.push(eachRole.id) 
                                                            }
                                                            return { selectedRoleIds: oldState.selectedRoleIds }
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </FormGroup>
                    </Form>
                </ModalBody>    
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => this.createNewUserModal()}
                    >
                        { this.state.isLoading ? <Spinner color="light" size="sm" /> : "submit" }
                    </Button>
                    <Button
                        onClick={() => this.setState({ showCreateUserModal: false })}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
    
    selectRoleOnChangeHandler(value) {
        const { roleIds} = this.props

        if (value !== '-1') {
            const filteredHighLevelUsers = this.rolesDetails.filter(item => {
                if (isAdmin(roleIds) && (Array.isArray(item.roleIds) && item.roleIds.includes(parseInt(parseInt(value))))) {
                    return true
                } else if ((isSalesManagerOrHigher(roleIds) || isContentManagerOrHigher(roleIds)) && (Array.isArray(item.roleIds) && !isAdmin(item.roleIds) && item.roleIds.includes(parseInt(value)) )){
                    return true
                } else {
                    return false
                }
            })
            this.setState({ highLevelUsers: filteredHighLevelUsers, selectedRole: parseInt(value) })
        } else {

            const filteredHighLevelUsers = this.rolesDetails.filter(item => {
                if (isAdmin(roleIds) && (Array.isArray(item.roleIds) && item.roleIds.includes(parseInt(this.state.selectedRole)))) {
                    return true
                } else if (isSalesManagerOrHigher(roleIds) && Array.isArray(item.roleIds) && !isAdmin(item.roleIds) && intersection(item.roleIds, [7,8,9]).length) {
                    return true
                } else if (isContentManagerOrHigher(roleIds) && Array.isArray(item.roleIds) && !isAdmin(item.roleIds) && intersection(item.roleIds, [12,13,15]).length ) {
                    return true
                } else {
                    return false
                }
            })

            this.setState({ highLevelUsers: filteredHighLevelUsers, selectedRole: parseInt(value), isLoading: false })
        }
    }

    renderFilterDropDown() {
        return (
            <Col md="4">
                <Input
                    type="select"
                    name="selectedRole"
                    id="selectedRole"
                    value={this.state.selectedRole}
                    onChange={ (e) => this.selectRoleOnChangeHandler(e.target.value) }
                >
                    
                    {
                        [{ id: -1, name: 'All' }, ...this.state.rolesNameList].map((each) => (
                            <option
                                key={each.id}
                                value={each.id}
                            >
                                {each.name}
                            </option>
                        ))
                    }
                </Input>
            </Col>
        )
    }

    render() {
            return (
                <>
                    { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}
                    <Container style={{ paddingTop: 50 }}>

                        { this.state.showCreateUserModal && ( isSalesManagerOrHigher(this.props.roleIds) || isContentManagerOrHigher(this.props.roleIds) ) && this.renderCreateNewUserModal() }

                        {Array.isArray(this.state.highLevelUsers) && this.state.highLevelUsers.length > 0 ?
                            <>
                                <Row style={{ marginBottom: 20 }}>
                                    { this.renderFilterDropDown() }
                                    <Col md="6">
                                        <CheckBox
                                            label={'Show deleted users'}
                                            style={{ marginTop: '10px' }}
                                            checked={this.state.showDeletedUsers}
                                            onClick={() => this.setState({ showDeletedUsers: !this.state.showDeletedUsers, selectedRole: '-1' }, () => {
                                                this.getAllHighLevelUsers(this.state.showDeletedUsers)
                                            })}
                                        />
                                    </Col>
                                    { ( isSalesManagerOrHigher(this.props.roleIds) || isContentManagerOrHigher(this.props.roleIds) ) &&
                                        <Col md="2">
                                            <Button 
                                                color='primary'
                                                onClick={() => this.setState({ showCreateUserModal: true })}
                                            >
                                                + Create User
                                            </Button>    
                                        </Col>
                                    }
                                </Row>

                                <Row>
                                    <Col md="12">
                                        <ListGroup>
                                            {
                                                this.state.highLevelUsers.map((each, index) => {
                                                    const isOpen = index === this.state.isOpenIndex
                                                    return (
                                                        <ListGroupItem
                                                            key={each._id}
                                                            style={{ background: each.activeFlag ? 'white' : '#ffcccb' }}
                                                        >
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                                                                <div style={{ flex: 1 }}>
                                                                    <p style={{ marginBottom: 2 }}>{each.name}</p>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                        {each.rolesNameList.map((name, index) => {
                                                                            return (
                                                                                <p
                                                                                    key={name}
                                                                                    style={{ fontSize: 13, marginRight: 5, marginBottom: 0, color: 'grey' }}
                                                                                >
                                                                                    {(each.rolesNameList.length - 1) === index ? `${name}` : `${name},`}
                                                                                </p>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <p style={{ flex: 1 }}>{each.email}</p>
                                                                <Button
                                                                    size="sm"
                                                                    outline
                                                                    color={'danger'}
                                                                    style={{ paddingLeft: 15, paddingRight: 15, marginRight: 10 }}
                                                                    onClick={() => {
                                                                        if (window.confirm(`Are you sure you want to ${each.activeFlag ? 'delete' : 'undelet'} this user ?`)) {
                                                                            this.deleteOrUndeleteUser(each._id)
                                                                        }}
                                                                    }
                                                                >
                                                                    {each.activeFlag ? 'DELETE' : 'UNDELETE'}
                                                                </Button>
                                                                <Button
                                                                    size="sm"
                                                                    style={{ paddingLeft: 15, paddingRight: 15 }}
                                                                    color={isOpen ? "primary" : "secondary"}
                                                                    onClick={() => this.setState({ isOpenIndex: index === this.state.isOpenIndex ? null : index })}
                                                                >
                                                                    <FontAwesomeIcon icon={isOpen ? faArrowUp : faArrowDown} />
                                                                </Button>
                                                            </div>
                                                            <Collapse isOpen={isOpen} >
                                                                <Card>
                                                                    <CardBody>
                                                                        <Row>
                                                                            {
                                                                                this.state.rolesNameList.map((eachRole) => {
                                                                                    return (
                                                                                        <Col
                                                                                            key={eachRole.id}
                                                                                            md="12"
                                                                                            style={{ paddingTop: 5, paddingBottom: 10 }}
                                                                                        >
                                                                                            <CheckBox
                                                                                                label={eachRole.name}
                                                                                                disabled={(eachRole.id === 0 || eachRole.id === 1)}
                                                                                                checked={each.roleIds && each.roleIds.includes(parseInt(eachRole.id))}
                                                                                                onClick={() => this.toggleRolePermission(each._id, `${eachRole.id}`)}
                                                                                            />
                                                                                            <hr />
                                                                                        </Col>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Collapse>
                                                        </ListGroupItem>
                                                    )
                                                })
                                            }
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </>
                            : Array.isArray(this.state.highLevelUsers) && this.state.highLevelUsers.length === 0 ?
                                <Container>
                                    <Row>
                                        { this.renderFilterDropDown() }
                                    </Row>

                                    <Row>
                                        <Col md="12">
                                            <p style={{ marginTop: 10 }}>No high level users found</p>
                                        </Col>
                                    </Row>

                                </Container>
                            : null
                        }
                    </Container>
                </>
            )
    }
}

const mapStateToProps = state => {
    return {
        roleIds: state.auth.roleIds,
    }
}

export default connect(mapStateToProps)(RolesManager)