import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import {
    Container, Row, Col, Input, Button, Table,
    Modal, ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEdit } from '@fortawesome/free-solid-svg-icons'

import Loader from '../Components/Loader'
import { copyToClipboard } from '../utils'
import { toast } from 'react-toastify'


const ParagraphEditorModal = ({ modalTitle, value, onChange, submit, close }) => {
    return (
        <Modal
            isOpen={true}
            size="lg"
        >

            <ModalHeader>
                {modalTitle}
            </ModalHeader>

            <ModalBody>
                <Input
                    type="textarea"
                    id="paragraphText"
                    rows={10}
                    onChange={onChange}
                    value={value}
                />
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={submit}>Submit</Button>
                <Button onClick={close}>Close</Button>
            </ModalFooter>

        </Modal>
    )
}

function Paragraphs() {

    const [isLoading, setIsLoading] = useState(true)
    const [paragraphs, setParagraphs] = useState('')

    const [showNewParaModal, setShowNewParaModal] = useState(false)
    const [editingParaId, setEditingParaId] = useState(false)
    const [paraText, setParaText] = useState('')

    const [searchText, setSearchText] = useState('')

    const addNewParagraph = () => {
        if (paraText) {
            Axios.put('/paragraph', { paragraphText: paraText })
                .then(res => {
                    setShowNewParaModal(false)
                    setParaText('')
                    getAllParagraphs()
                })
                .catch(err => {
                    console.log(err)
                    alert("Error adding paragraphs. Check console")
                })
        } else {
            alert("Cannot add empty paragraph")
        }
    }

    const editParagraph = () => {
        if (paraText) {
            Axios.post('/paragraph', { paragraphText: paraText, paragraphId: editingParaId })
                .then(res => {
                    setEditingParaId(null)
                    getAllParagraphs()
                })
                .catch(err => {
                    console.log(err)
                    alert("Error editing paragraphs. Check console")
                })
        } else {
            alert("Please enter paragraph text")
        }
    }

    const getAllParagraphs = (searchId) => {
        setIsLoading(true)
        Axios.get('/paragraphs', { params: { searchId }})
            .then(res => {
                unstable_batchedUpdates(() => {
                    setIsLoading(false)
                    setParagraphs(res.data.paragraphs)
                })
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Error getting paragraphs. Check console")
            })
    }

    useEffect(() => {
        getAllParagraphs()
    }, [])

    return (
        <>

            {isLoading && <Loader />}

            <Container>

                {
                    showNewParaModal &&
                    <ParagraphEditorModal
                        modalTitle={"Create Paragraph"}
                        value={paraText}
                        close={() => {
                            unstable_batchedUpdates(() => {
                                setShowNewParaModal(false)
                            })
                        }}
                        onChange={(e) => setParaText(e.target.value)}
                        submit={addNewParagraph}
                    />
                }

                {
                    editingParaId &&
                    <ParagraphEditorModal
                        modalTitle={"Edit Paragraph"}
                        value={paraText}
                        close={() => {
                            unstable_batchedUpdates(() => {
                                setParaText('')
                                setEditingParaId(null)
                            })
                        }}
                        onChange={(e) => setParaText(e.target.value)}
                        submit={editParagraph}
                    />
                }

                <Row>
                    <Col md="6">

                        <Button
                            color="primary"
                            style={{ margin: 15 }}
                            onClick={() => {
                                unstable_batchedUpdates(() => {
                                    setParaText('')
                                    setShowNewParaModal(true)
                                })
                            }}
                        >
                            + Add Paragraph
                        </Button>
                    </Col>

                    <Col md="6">

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Input
                                placeholder="Search paragraph by ID"
                                style={{ margin: 15 }}
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText || ''}
                                name="paragraphIdSearch"
                            />

                            <Button
                                color="primary"
                                style={{ margin: 15 }}
                                onClick={() => getAllParagraphs(searchText)}
                            >
                                Search
                            </Button>
                        </div>
                    </Col>

                </Row>

                <Row>
                    {
                        Array.isArray(paragraphs) && paragraphs.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Paragraph</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paragraphs.map((each, index) => (
                                            <tr key={each._id}>
                                                <td>{index + 1}</td>
                                                <td>{each.paragraphText}</td>
                                                <td>
                                                    <Button
                                                        onClick={() => {
                                                            copyToClipboard(each._id)
                                                            toast("ID copied", {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                hideProgressBar: true
                                                                })
                                                        }}
                                                        outline
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            style={{ color: 'grey' }}
                                                            icon={faCopy}
                                                        />
                                                    </Button>

                                                    <Button
                                                        onClick={() => unstable_batchedUpdates(() => {
                                                            setEditingParaId(each._id)
                                                            setParaText(each.paragraphText)
                                                        })}
                                                        outline
                                                        color="primary"
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="iconButton"
                                                            icon={faEdit}
                                                        />
                                                    </Button>

                                                    {/* <Button
                                                    onClick={() => copyToClipboard(each._id)}
                                                    color="danger"
                                                    outline
                                                >
                                                    <FontAwesomeIcon
                                                        className="iconButton"
                                                        icon={faTrash}
                                                    />
                                                </Button> */}

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            :
                            Array.isArray(paragraphs) && paragraphs.length === 0 ?
                                <h5 style={{ textAlign: 'center', marginTop: 50 }}>No paragraphs found</h5>
                                :
                                null
                    }
                </Row>
            </Container>
        </>
    )
}

export default Paragraphs
