import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Aux from './Auxiliary'
import Home from '../Pages/Home'
import AddForm from './AddForm'
import EditForm from './EditForm'
import ViewQuestions from '../Pages/ViewQuestions/ViewQuestions'

import Exams from './Exams'
import ViewBots from '../Pages/Users/ViewBots'
import Referral from '../Pages/Users/Referral'
import Subjects from './Subjects'
import Tags from './Tags'
import ReportedQuestions from './ReportedQuestions'
import NameChange from './NameChange'
import Posts from './Posts'
import PostWebsiteEditor from '../Pages/PostWebsiteEditor'
import ImageUpload from '../Pages/imageUpload'
import Analytics from '../Pages/Analytics'
import DataEntryReports from '../Pages/DataEntryReports'
import DailyChallenges from '../Pages/DailyChallenges'
import FailedPayments from '../Pages/FailedPayments'
import ByteQuiz from '../Pages/ByteQuiz'
import ByteQuizPreferredTopics from '../Pages/ByteQuizPreferredTopics'
import NewUserQuestions from '../Pages/NewUserQuestions'
import ByteQuizReported from '../Pages/ByteQuizReported'
import Leads from '../Pages/Leads'
import AssignedLeads from '../Pages/AssignedLeads'
import LeadsConversionStats from '../Pages/LeadsConversionStats'
import TrialUsers from '../Pages/TrialUsers'

import NewMockExam from '../Pages/NewMockExam'
import EditExam from '../Pages/EditMockExam'
import OfferList from '../Pages/OfferList'
import RecentOtp from '../Pages/RecentOtp'
import ReportSlides from '../Pages/ReportSlide'
import RedisKeys from '../Pages/Redis'

import ImageEditor from '../Pages/ImageEditor'
import DocumentUpload from '../Pages/DocumentUpload'

import Learn from '../Pages/Learn/Learn'
import LearnModule from '../Pages/Learn/LearnModule'

import LiveExam from '../Pages/LiveExam'
import TopicExam from '../Pages/TopicExam'
import MathEditor from '../Pages/MathEditor'
import Teachers from '../Pages/Teachers'

import RolesManager from '../Pages/RolesManager'
import HtmlEditor from '../Pages/HtmlEditor'
import LearningPath from '../Pages/LearningPath'
import EditPath from '../Pages/EditPath'
import PathModules from '../Pages/PathModules'
import Packs from '../Pages/Packs'
import NewPathExam from '../Pages/NewPathExam'
import UserQuestions from '../Pages/UserQuestions'

import UserLearningPath from '../Pages/UserLearningPath'
import Comments from '../Pages/comments'
import PrelimsAttendedDetails from '../Pages/PrelimsAttendedDetails'
import SalesManagerHome from '../Pages/SalesManagerHome'
import MonthlyGoal from '../Pages/MonthlyGoal'
import FeatureToIndex from '../Pages/FeatureToIndex'
import DuplicateQuestions from '../Pages/DuplicateQuestions'
import DailyExams from '../Pages/DailyExams'
import ReportedChatDetails from '../Pages/ReportedChatDetails';

import BatchDays from '../Pages/Batch/BatchDays'
import BatchDayModules from '../Pages/Batch/BatchDayModules'
import BatchStudents from '../Pages/Batch/BatchStudents'
import StudyGroups from '../Pages/Batch/StudyGroups';
import StudyProgress from '../Pages/Batch/StudyProgress';

import SearchHistory from '../Pages/SearchHistory';
import UserActivity from '../Pages/UserActivity';
import Paragraphs from '../Pages/Paragraphs';
import SearchModule from '../Pages/SearchModule';
import CurrentAffairsReported from '../Pages/CurrentAffairsReported';
import CourseGroups from '../Pages/CourseGroups';
import InAppMessages from '../Pages/InAppMessages'
import StatementQuiz from '../Pages/StatementQuiz'
import PremiumValues from '../Pages/PremiumValues'
import BattleHistory from '../Pages/BattleHistory'
import ChallengesNoteViewer from '../Pages/ChallengesNoteViewer'
import EditHomeSlider from '../Pages/EditHomeSlider'
import UserNotesPage from '../Pages/UserNotesPage'
import ByteQuizLeaderboard from '../Pages/ByteQuizLeaderBoard'
import ManageVideoImageAds from '../Pages/ManageVideoImageAds'
import ExamsAttended from '../Pages/ExamsAttended'
import WrongTypeQuestions from '../Pages/WrongTypeQuestions'
import Awards from '../Pages/Awards'
import QuestionLinkGenerator from '../Pages/QuestionLinkGenerator'
import SiteNotes from '../Pages/SiteNotes'
import TextbookQuestions from '../Pages/TextbookQuestions'
import SalesReportPage from '../Pages/SalesReport'
import PopupManager from '../Pages/Website/PopupManager'
import MostStudiedModules from '../Pages/Analytics/MostStudiedModules'
import MostStudiedCourseTopics from '../Pages/Analytics/MostStudiedCourseTopics'
import BlogList from '../Pages/Website/BlogList'
import BlogEditor from '../Pages/Website/BlogEditor'
import ExamPostSites from '../Pages/ExamPostPages'
import Doubts from '../Pages/Doubts'

const Main = () => (
    <Aux>
        <Switch>

            <Route path="/" exact component={Home} />
            <Route path="/monthly-goal" exact component={MonthlyGoal} />
            <Route path="/features-to-index" exact component={FeatureToIndex} />
            <Route path="/questions" exact component={ViewQuestions} />
            <Route path="/questions/add" exact component={AddForm} />
            <Route path="/questions/edit" exact component={EditForm} />
            <Route path="/exams" exact component={Exams} />
            <Route path="/daily-exams" exact component={DailyExams} />

            <Route path="/bots/" exact component={ViewBots} />
            <Route path="/users/referral" exact component={Referral} />
            <Route path="/subjects" exact component={Subjects} />
            <Route path="/posts" exact component={Posts} />
            <Route path="/post-website-editor" exact component={PostWebsiteEditor} />
            <Route path="/reported-slides" exact component={ReportSlides} />
            <Route path="/reported-questions" exact component={ReportedQuestions} />
            <Route path="/name-change" exact component={NameChange} />
            <Route path="/tags" exact component={Tags} />
            <Route path="/image-upload" exact component={ImageUpload} />
            <Route path="/analytics" exact component={Analytics} />
            <Route path="/most-studied-modules" exact component={MostStudiedModules} />
            <Route path="/most-studied-course-topics" exact component={MostStudiedCourseTopics} />
            <Route path="/data-entry-reports/" exact component={DataEntryReports} />
            <Route path='/daily-challenges' exact component={DailyChallenges} />
            <Route path='/failed-payments' exact component={FailedPayments} />
            <Route path='/bytequiz' exact component={ByteQuiz} />
            <Route path='/bytequiz-preferred-topics' exact component={ByteQuizPreferredTopics} />
            <Route path='/new-user-questions' exact component={NewUserQuestions} />
            <Route path='/bytequiz-reported' exact component={ByteQuizReported} />
            <Route path='/current-affairs-reported' exact component={CurrentAffairsReported} />
            <Route path='/assign-leads' exact component={Leads} />
            {/* <Route path='/trial-users' exact component={TrialUsers} /> */}
            <Route path='/assigned-leads' exact component={AssignedLeads} />
            <Route path='/lead-conversions' exact component={LeadsConversionStats} />
            
            <Route path='/learn/:sectionId/:userIdAndPeriod' exact component={Learn} />
            <Route path='/module/:moduleId' exact component={LearnModule} />
            <Route path="/live-exam/" exact component={LiveExam} />
            <Route path="/topic-exam/" exact component={TopicExam} />
            <Route path="/sales-report-page/" exact component={SalesReportPage} />
            <Route path="/search-modules" exact component={SearchModule} />
            <Route path="/sales-manager-home" exact component={SalesManagerHome} />
            <Route path="/reported-chat" exact component={ReportedChatDetails} />

            <Route path="/image/" exact component={ImageEditor} />
            <Route path="/document-upload/" exact component={DocumentUpload} />
            
            <Route path="/newmockexam" exact component={NewMockExam} />
            <Route path="/new-path-exam" exact component={NewPathExam} />
            
            <Route path="/exams/edit" exact component={EditExam} />
            <Route path="/user-activity" exact component={UserActivity} />

            <Route path="/redis-keys" exact component={RedisKeys} />
            <Route path="/math-editor" exact component={MathEditor} />
            <Route path="/offer-list" exact component={OfferList} />   
            <Route path="/recent-otp" exact component={RecentOtp} />   
            <Route path="/packs-list" exact component={Packs} />   
            <Route path="/course-groups" exact component={CourseGroups} />   

            <Route path="/html-editor" exact component={HtmlEditor} />
            <Route path="/teachers" exact component={Teachers} />
            
            <Route path="/roles-manager" exact component={RolesManager} />
            
            <Route path="/search-history" exact component={SearchHistory} />
            
            <Route path="/learning-path" exact component={LearningPath} />
            <Route path="/edit-unit/:unitId" exact component={EditPath} />
            <Route path="/edit-unit/:unitId/:topicId" exact component={PathModules} />

            <Route path="/video-comments" exact component={Comments} />
            <Route path="/user-learning-path" exact component={UserLearningPath} />
            <Route path="/prelims-practice-user-count" exact component={PrelimsAttendedDetails}/>

            <Route path="/user-questions" exact component={UserQuestions} />
            <Route path="/duplicate-questions/:skip" exact component={DuplicateQuestions} />

            <Route path="/batch-days/" exact component={BatchDays} />
            <Route path="/batch-day-modules/:dayId" exact component={BatchDayModules}/>
            <Route path="/batch-study-groups" exact component={StudyGroups}/>
            <Route path="/batch-students/:productId/:groupId" exact component={BatchStudents}/>
            <Route path="/batch-study-progress/:groupId/:studentId" exact component={StudyProgress}/>
            
            <Route path="/paragraphs" exact component={Paragraphs}/>
            <Route path="/in-app-messages" exact component={InAppMessages}/>
            
            <Route path="/statement-quiz" exact component={StatementQuiz}/>
            <Route path="/premium-pricing" exact component={PremiumValues}/>

            <Route path="/battle-history" exact component={BattleHistory}/>

            <Route path="/all-challenge-notes" exact component={ChallengesNoteViewer}/>
            <Route path="/user-notes" exact component={UserNotesPage}/>
            <Route path="/edit-home-slider" exact component={EditHomeSlider}/>
            <Route path="/byte-quiz-leaderboard" exact component={ByteQuizLeaderboard}/>
            <Route path="/manage-video-image-ads" exact component={ManageVideoImageAds}/>
            <Route path="/exams-attended" exact component={ExamsAttended}/>
            <Route path="/wrong-type-questions" exact component={WrongTypeQuestions}/>
            <Route path="/awards" exact component={Awards}/>
            <Route path="/generate-question-link" exact component={QuestionLinkGenerator}/>
            <Route path="/site-notes" exact component={SiteNotes}/>
            <Route path="/popup-manager" exact component={PopupManager}/>
            <Route path="/blog-posts" exact component={BlogList}/>
            <Route path="/blog-editor" exact component={BlogEditor}/>
            <Route path="/exam-post-pages" exact component={ExamPostSites}/>

            <Route path="/textbook-questions/:sectionId" exact component={TextbookQuestions}/>
            <Route path="/doubts" exact component={Doubts}/>

        </Switch>
    </Aux>
)

export default Main
