import React from 'react'
import Axios from 'axios'
import { copyToClipboard } from '../../utils';
import { cloneDeep, intersection } from 'lodash';
import  { withRouter } from 'react-router-dom'
import { updateQuestions } from '../../actions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'


import {
    Col, Row, Container, Table, Button, ListGroup, ListGroupItem,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Input
} from 'reactstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCopy } from '@fortawesome/free-solid-svg-icons'

class EditQuizContent extends React.Component {

    state = {
        showAddRandomQuestionsModal:false,
        newQuestionIdsArray:'',
        questionCount:'',
        randomQuestionList: []
    }

    getRandomQuestionsAndSaveToModule() {

        const { moduleId } = this.props
        const search = this.props
        const topicId = new URLSearchParams(search).get('topicId');
        const unitId = new URLSearchParams(search).get('unitId');

        let paramObject = {
            moduleId,
            sectionId: this.props.moduleData.sectionId,
            questionCount: this.state.questionCount
        }

        if (topicId && unitId) {
            paramObject.topicId = topicId
            paramObject.unitId = unitId
        }

        if (this.state.questionCount) {
            Axios.get('/random-question', { params: paramObject })
                .then(res => {
                    if (res.data.questionData) {
                        this.setState({
                            randomQuestionList: res.data.questionData,
                            questionCount: null
                        })
                    } else {
                        alert("No questions available")
                    }
                })
                .catch(err => {
                    alert("Server error while getting random questions")
                    console.log(err)
                })
        } else {
            alert("Please enter question count")
        }
    }
    
    addRandomQuestionToModuleData() {
        let moduleData = cloneDeep(this.props.moduleData)
        let quizQuestions = cloneDeep(this.props.quizQuestions)

        this.state.randomQuestionList.forEach(item =>{           
            moduleData.content.questions.push(item._id)
            quizQuestions.push({ _id: item._id, question: item.question, activeFlag: item.activeFlag })
            this.props.updateQuizQuestionAndModuleData(moduleData,quizQuestions)
            this.setState({ showAddRandomQuestionsModal: false, questionCount: null , randomQuestionList:[]})
        })
    }

    renderAddRandomQuestionsModal() {
        return (
            <Modal
                size="lg"
                toggle={() => this.setState({ showAddRandomQuestionsModal: false })}
                isOpen={this.state.showAddRandomQuestionsModal}
            >
                <ModalHeader>Enter question count and get questions</ModalHeader>
                <ModalBody>
                    <Row style={{ marginBottom: '1rem' }}>
                        <Col>
                            <Input
                                type="number"
                                name="questionCount"
                                id="questionCount"
                                value={this.state.questionCount || ''}
                                placeholder="Enter Question Count"
                                onChange={this.handleInput}
                            />
                        </Col>
                        <Col>
                            <Button
                                onClick={() => this.getRandomQuestionsAndSaveToModule()}
                            >
                                Get Questions
                            </Button>
                        </Col>
                        <Col>
                            <p>Question Count: {this.state.randomQuestionList.length}</p>
                        </Col>
                    </Row>
                    <ListGroup>
                        { this.state.randomQuestionList.map((item) => {
                            return (
                                <ListGroupItem key={item._id}>
                                    <Row >
                                        <Col md='9'>
                                            <p style={{ marginBottom: 0 }}>{item.question}</p>
                                        </Col>
                                        <Col md='1'>
                                            <FontAwesomeIcon
                                                className="iconButton"
                                                onClick={() => {
                                                    copyToClipboard(item._id) 
                                                    toast("ID copied", {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        hideProgressBar: true
                                                        })
                                                }}
                                                style={{ color: 'grey', marginLeft: 5, marginRight: 5 }}
                                                icon={faCopy}
                                            />
                                        </Col>
                                        <Col md='1'>
                                            <Button
                                                color='danger'
                                                outline
                                                style={{ marginRight: 1 }}
                                                onClick={() => {
                                                    this.setState(oldState => {
                                                        let removedQuestions = oldState.randomQuestionList.filter(question => !(question._id === item._id))
                                                        return { randomQuestionList: removedQuestions }
                                                    })
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <Button
                                color='primary'
                                style={{ marginRight: 1 }}
                                onClick={() => this.addRandomQuestionToModuleData()}
                            >
                                Add
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                outline
                                onClick={() => this.setState({ showAddRandomQuestionsModal: false })}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeInput =() => {
        let moduleData = cloneDeep(this.props.moduleData)
        this.props.updateModuleDataAndSave(moduleData)
    }
    
    setQuestionsAndNavigate = () => {
        const { moduleId } = this.props
        this.props.history.push({ pathname: "/questions", state: { quizModuleId: moduleId, backRoute: 'learnModules' } })
    }


    render() {

        const { content } = this.props.moduleData
        const { quizQuestions } = this.props
        
        return (

            <Container >
            { this.state.showAddRandomQuestionsModal && this.renderAddRandomQuestionsModal() }
            <Form>
                <FormGroup>
                    <Row>
                        <Col md="4">
                            <Input
                                type="textarea"
                                rows="3"
                                name="newQuestionIdsArray"
                                id="newQuestionIdsArray"
                                value={this.state.newQuestionIdsArray}
                                placeholder="Enter Question IDs (One ID per line please)"
                                onChange={this.handleInput}
                            />

                            <Button
                                color="primary"
                                block
                                style={{ marginTop: 10 }}
                                onClick={() => {

                                    const questionIdsSplitted = this.state.newQuestionIdsArray.split("\n")
                                    const allItems = questionIdsSplitted.map(each => each.replace(/\s/g, ''))
                                    const questionIds = allItems.filter(each => each)

                                    if (Array.isArray(questionIds) && questionIds.length > 0) {

                                        if (Array.isArray(content.questions) && content.questions.length > 0) {


                                            const duplicateQuestion = intersection(content.questions, questionIds)

                                            if (duplicateQuestion && duplicateQuestion.length > 0) {
                                                return alert(`${duplicateQuestion[0]} already exists`)
                                            }

                                            {
                                                const questions = content.questions.concat(questionIds)
                                                content.questions = questions
                                                this.setState({newQuestionIdsArray:''})
                                                this.handleChangeInput()
                                            }

                                        } else {                                            
                                                content.questions = questionIds
                                                this.setState({newQuestionIdsArray:''})
                                                this.handleChangeInput()
                                        }

                                    } else {
                                        alert("Enter a valid question Ids")
                                    }

                                }}
                            >Add Questions</Button>
                        </Col>

                        <Col md={{ size: 4, offset: 4 }}>
                            <Button
                                color="primary"
                                outline
                                block
                                onClick={() => this.setState({ showAddRandomQuestionsModal: true })}
                            >
                                Add Random Quesitons
                            </Button>
                            <br />
                            <Button
                                color="primary"
                                outline
                                block
                                onClick={() => this.setQuestionsAndNavigate()}
                            >
                                View Questions
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
            </Form>

            {
                quizQuestions && quizQuestions.length > 0 ?
                    <Table>

                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Question ID</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                quizQuestions.map((eachQuestion, index) => {
                                    return (
                                        <tr
                                            key={eachQuestion._id}
                                            style={{ backgroundColor: !eachQuestion.activeFlag ? 'rgba(255,0,0,0.1)' : 'transparent' }}
                                        >
                                            <td style={{ width: '5%' }}>{index + 1}</td>
                                            <td style={{ width: '80%' }}>
                                                { eachQuestion.type === 'html' ? <div dangerouslySetInnerHTML={{ __html: eachQuestion.question.split("rgb(255,255,255)").join("rgb(0,0,0)") }} /> : eachQuestion.question  }
                                                <p style={{ fontSize: 14, marginTop: 1, color: 'grey' }}>{eachQuestion.answerText}</p>
                                            </td>
                                            <td style={{ width: '30%' }}>
                                                <FontAwesomeIcon
                                                    className="iconButton"
                                                    onClick={() => { copyToClipboard(eachQuestion._id) }}
                                                    style={{ color: 'grey', marginLeft: 5, marginRight: '1rem' }}
                                                    icon={faCopy}
                                                />
                                                <Button
                                                    outline
                                                    color="danger"
                                                    onClick={() => {
                                                        let moduleData = cloneDeep(this.props.moduleData)
                                                        const questionIdArr = moduleData.content.questions.filter(each => each !== eachQuestion._id)
                                                        moduleData.content.questions = questionIdArr
                                                        const otherQuestions = quizQuestions.filter(each => each._id !== eachQuestion._id)
                                                        this.props.updateQuizQuestionAndModuleData(moduleData, otherQuestions)
                                                    }}
                                                >Delete</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </Table>
                    :
                    <Row>
                        <Col>
                            <p>No questions added</p>
                        </Col>
                    </Row>
            }

        </Container>

        );
    }
}
 
export default withRouter( connect(null,{ updateQuestions })(EditQuizContent))
    