import React from 'react'
import {
    Container, Row, Col,
    Progress, Button,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment'
import { updateSalesmen, login } from '../actions'
import Loader from '../Components/Loader'
import Aux from '../Components/Auxiliary'
import { commafy, dropDownPeriods } from '../utils'
import { isAdmin } from '../utils/dashboardRoles'

class SalesManagerHome extends React.Component {

    state = {
        dropdownOpen: false,
        monthlyGoalAmount: 0,
        periodLabel: "Today",
        period: "today",
        revenueTillNowThisMonth: 0,
        progressValue: 0,
        salesmenDetails: [],
        isLoading: false,
    }

    getMonthlyGoalDetails(getFullDetails) {

        let jsDate = new Date()
        let month = ''

        if ((jsDate.getMonth() + 1) < 10) {
            month = `0${jsDate.getMonth() + 1}-${jsDate.getFullYear()}`
        } else {
            month = `${jsDate.getMonth() + 1}-${jsDate.getFullYear()}`
        }

        this.setState({ isLoading: true }, () => {
            axios.get('/sales-statistics', { timeout: 180000, params: { month, period: this.state.period, getFullDetails } })
                .then(res => {

                    const {
                        monthlyGoalAmount,
                        achievedDetails,
                        numberOfPayments,
                        salespersonDetails,
                        totalPremiumCount,
                        totalPremiumRevenue,
                        totalSilverCount,
                        totalSilverRevenue,
                        totalGoldCount,
                        totalGoldRevenue,
                        totalLeadsAssigned,
                        totalRevenue
                    } = res.data

                    const diffInMs = new Date() - new Date(moment().startOf('month').toDate())
                    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

                    let conversionPercentage = 0

                    if (totalLeadsAssigned > 0) {
                        conversionPercentage = ((numberOfPayments / totalLeadsAssigned) * 100).toFixed(2)
                    }

                    let setStateObject = {
                        totalRevenue,
                        order: numberOfPayments,
                        totalLeadsAssigned,
                        conversionPercentage,
                        salesmenDetails: salespersonDetails,
                        isLoading: false
                    }

                    if (getFullDetails) {

                        let averageNumberOfUnits = 0
                        let dailyAverageAmount = 0

                        if (days > 0) {
                            averageNumberOfUnits = Math.ceil((totalPremiumCount + totalSilverCount + totalGoldCount) / days)
                            dailyAverageAmount = Math.floor((totalPremiumRevenue + totalSilverRevenue + totalGoldRevenue) / days)
                        }

                        let forecastAmount = moment().daysInMonth() * dailyAverageAmount
                        let forecastUnits = moment().daysInMonth() * averageNumberOfUnits

                        const revenueTillNowThisMonth = (achievedDetails && achievedDetails[0]) ? achievedDetails[0].totalAmount : 0
                        
                        setStateObject.monthlyGoalAmount = monthlyGoalAmount
                        setStateObject.revenueTillNowThisMonth = revenueTillNowThisMonth
                        setStateObject.averageNumberOfUnits = averageNumberOfUnits
                        setStateObject.dailyAverageAmount = dailyAverageAmount
                        setStateObject.forecastAmount = forecastAmount
                        setStateObject.forecastUnits = forecastUnits
                        setStateObject.progressValue = (revenueTillNowThisMonth / monthlyGoalAmount) * 100
                        setStateObject.totalPremiumCount = totalPremiumCount
                        setStateObject.totalPremiumRevenue = totalPremiumRevenue
                        setStateObject.totalSilverCount = totalSilverCount
                        setStateObject.totalSilverRevenue = totalSilverRevenue
                        setStateObject.totalGoldCount = totalGoldCount
                        setStateObject.totalGoldRevenue = totalGoldRevenue

                        this.setState(setStateObject)

                    } else {
                        this.setState(setStateObject)
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert("Error. Please check console")
                })
        })

    }

    componentDidMount() {
        this.getMonthlyGoalDetails(true)
    }

    getForecastAmount = () => {
        const totalRevenueSoFar = this.state.totalPremiumRevenue + this.state.totalSilverRevenue + this.state.totalGoldRevenue
        const dayOfMonth = moment().date()
        const totalDaysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
        const forecastAmount = (totalRevenueSoFar/dayOfMonth)*totalDaysInMonth
        return  commafy(Math.round(forecastAmount))
    }

    getForecastUnits = () => {
        const totalUnitsSoFar = this.state.totalPremiumCount + this.state.totalSilverCount + this.state.totalGoldCount
        const dayOfMonth = moment().date()
        const totalDaysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
        const forecastUnits = (totalUnitsSoFar/dayOfMonth)*totalDaysInMonth
        return  commafy(Math.round(forecastUnits))
    }

    render() {
        return (
            <Aux>

                { this.state.isLoading && <Loader />}

                <Container>

                    <Row>
                        <Col style={{ marginTop: '3rem' }}>
                            <Row style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <h3><strong>Monthly Goal</strong></h3>
                                    <div style={{ display: 'flex', alignItems:'center' }}>
                                        <h4><strong>₹ {commafy(this.state.monthlyGoalAmount)}</strong></h4>
                                        {
                                            isAdmin(this.props.roleIds) &&
                                            <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                <Button
                                                    outline
                                                    color="primary"
                                                    onClick={() => this.props.history.push({ pathname: "/monthly-goal" })}
                                                >Update Monthly Goal</Button>
                                            </Col>
                                        }
                                    </div>
                                </div>
                                <div style={{ width: '100%', marginTop: 10 }}>
                                    <Progress multi>
                                        <Progress bar color="success" value={(this.state.totalPremiumRevenue/this.state.monthlyGoalAmount) * 100} />
                                        <Progress bar color="secondary" value={(this.state.totalSilverRevenue/this.state.monthlyGoalAmount) * 100} />
                                        <Progress bar color="warning" value={(this.state.totalGoldRevenue/this.state.monthlyGoalAmount) * 100} />
                                    </Progress>
                                </div>
                            </Row>
                            <Row style={{ flex: 1, marginTop: '2rem' }}>
                                <Col>
                                    <h5 style={{ color: 'grey' }}>Premium</h5>
                                    <h2 style={{ fontWeight: 'bolder' }}>₹ {commafy(this.state.totalPremiumRevenue)}</h2>
                                    <h5>{this.state.totalPremiumCount} Units</h5>
                                </Col>
                                <Col>
                                    <h5 style={{ color: 'grey' }}>Silver</h5>
                                    <h2 style={{ fontWeight: 'bolder' }}>₹ {commafy(this.state.totalSilverRevenue)}</h2>
                                    <h5>{this.state.totalSilverCount} Units</h5>
                                </Col>
                                <Col>
                                    <h5 style={{ color: 'grey' }}>Gold</h5>
                                    <h2 style={{ fontWeight: 'bolder' }}>₹ {commafy(this.state.totalGoldRevenue)}</h2>
                                    <h5>{this.state.totalGoldCount} Units</h5>
                                </Col>
                                <Col>
                                    <h5 style={{ color: 'grey' }}>Total Revenue</h5>
                                    <h2 style={{ fontWeight: 'bolder' }}>₹ {commafy(this.state.totalPremiumRevenue + this.state.totalSilverRevenue + this.state.totalGoldRevenue)}</h2>
                                    <h5>{this.state.totalPremiumCount + this.state.totalSilverCount + this.state.totalGoldCount} Total Units Sold</h5>
                                </Col>
                                <Col>
                                    <h5 style={{ color: 'grey' }}>Month-end Forecast</h5>
                                    <h2 style={{ fontWeight: 'bolder' }}>₹ {this.getForecastAmount()}</h2>
                                    <h5>{this.getForecastUnits()} Units</h5>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '4rem' }}>
                        <Col>
                            <Row>
                                <Dropdown isOpen={this.state.isOpen} toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                                    <DropdownToggle caret>{this.state.periodLabel}</DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            dropDownPeriods.slice(0,-1).map((each) => {
                                                return (
                                                    <DropdownItem
                                                        key={each.value}
                                                        onClick={() => this.setState({ period: each.value, isOpen: false, periodLabel: each.label, data: null, isLoading: true },
                                                            () => { this.getMonthlyGoalDetails(false) })}
                                                    >
                                                        {each.label}
                                                    </DropdownItem>
                                                )
                                            })
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                            </Row>
                            <Row style={{ marginTop: '1rem' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingRight: 10, paddingLeft: 10 }}>
                                    <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <h5 style={{ color: 'grey' }}>Total Revenue</h5>
                                        <h2 style={{ fontWeight: 'bolder', color: '#1569C7' }}>₹ {commafy(this.state.totalRevenue)}</h2>
                                    </div>
                                    <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <h5 style={{ color: 'grey' }}>Leads Assigned</h5>
                                        <h2 style={{ fontWeight: 'bolder' }}>{this.state.totalLeadsAssigned}</h2>
                                    </div>
                                    <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <h5 style={{ color: 'grey' }}>Orders</h5>
                                        <h2 style={{ fontWeight: 'bolder' }}>{this.state.order}</h2>
                                    </div>
                                    <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <h5 style={{ color: 'grey' }}>Conversion</h5>
                                        <h2 style={{ fontWeight: 'bolder', color: "red" }}>{this.state.conversionPercentage}%</h2>
                                    </div>
                                </div>
                            </Row>
                            <Row style={{ marginTop: '2rem' }}>
                                <div style={{ backgroundColor: '#F0F0F0', width: '100%', padding: '1rem', paddingLeft: '5rem', paddingRight: '5rem' }}>
                                    {
                                    this.state.salesmenDetails.map((item, index) => {
                                        return (
                                            <div
                                                key={item._id}
                                                style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}
                                            >
                                                <div style={{ flex: 2 }}>
                                                    <label style={{ fontSize: 17 }}><strong>{item.name} ({item.numberOfPayments})</strong></label>
                                                </div>
                                                <div style={{ flex: 7, width: `100%`, display: 'flex', height: 22 }}>
                                                    <div style={{ backgroundColor: "#8B008B", color: "#8B008B", width: `${item.amount ? ((item.amount / this.state.totalRevenue) * 100) : 0}%`, }}>

                                                    </div>
                                                    <div style={{ flex: 2, marginLeft: 5 }}>
                                                        <label style={{ fontSize: 17 }}><strong>₹{commafy(item.amount)}</strong></label>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Row>
                        </Col>
                    </Row>

                </Container>

            </Aux>)
    }
}

const mapStateToProps = state => {
    return {
        salesmen: state.data.salesmen,
        auth: state.auth,
        roleIds: state.auth.roleIds
    }
}

export default connect(mapStateToProps, { updateSalesmen, login })(SalesManagerHome)
