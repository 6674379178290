import React, { useState, useEffect } from 'react'
import { Container, Row, Table, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Axios from 'axios'
import { unstable_batchedUpdates } from 'react-dom'
import { DatePicker } from 'material-ui'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep } from 'lodash'
import FileDownload from 'js-file-download'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import Loader from '../../Components/Loader'
import Aux from '../../Components/Auxiliary'

const emptyBatchDetails = {
    groupName: '',
    batchStartingDate: new Date(),
    whatsappGroupLink: ''
}

const AddOrEditDayModal = ({ batchDetails, updateValues, submit, close, showLoader }) => {
    return (

        <Modal fade={true} isOpen={true}>

            {showLoader && <Loader />}
            <ModalHeader>Enter WhatsApp group link</ModalHeader>

            <ModalBody>

                <Input
                    placeholder="Group Name"
                    name="groupName"
                    value={batchDetails.groupName}
                    style={{ marginBottom: 20 }}
                    onChange={ (e) => updateValues("groupName", e.target.value)}
                />

                <div style={{ flex: 1, flexDirection: 'row' }}>
                    <span style={{ marginLeft: 10 }}>Batch Starting Date: </span>
                    <DatePicker
                        container="inline"
                        hintText="Class Starting Date"
                        defaultDate={batchDetails.batchStartingDate}
                        style={{ marginLeft: 20, display: 'inline' }}
                        onChange={(event, batchStartingDate) => updateValues("batchStartingDate", batchStartingDate)}
                    />
                </div>

                <Input
                    placeholder="Whatsapp Group Link"
                    name="whatsappGroupLink"
                    value={batchDetails.whatsappGroupLink}
                    style={{ marginBottom: 20 }}
                    onChange={ (e) => updateValues("whatsappGroupLink", e.target.value)}
                />

                <Input
                    placeholder="Mentor Whatsapp Number (10 Digits)"
                    name="mentorWhatsappNumber"
                    value={batchDetails.mentorWhatsappNumber}
                    style={{ marginBottom: 20 }}
                    onChange={ (e) => updateValues("mentorWhatsappNumber", e.target.value)}
                    type="number"
                />

            </ModalBody>

            <ModalFooter>
                <Button
                    onClick={submit}
                    color="primary"
                >
                    Submit
                </Button>

                <Button onClick={close} outline>Close</Button>

            </ModalFooter>
        </Modal>
    )
}

function StudyGroups({ history, location }) {

    const [isLoading, setIsLoading] = useState(true)
    const [studyGroups, setGroups] = useState(null)
    const [newBatchDetails, setNewBatchDetails] = useState(false)
    const [editingBatchDetails, setEditingBatchDetails] = useState(false)

    const { productId, packName } = queryString.parse(location.search)

    const getStudyGroups = () => {

        Axios.get('/batch/admin/study-groups', { params: { productId } })
            .then(res => {
                unstable_batchedUpdates(() => {
                    setGroups(res.data.studyGroups)
                    setIsLoading(false)
                })
            })
            .catch(err => {
                console.log(err)
                alert("Error. Check console")
            })
    }
    
    useEffect(() => {
        getStudyGroups()
    }, [])

    const addStudyGroup = () => {

        const { batchStartingDate, whatsappGroupLink, groupName, mentorWhatsappNumber } = newBatchDetails

        if (batchStartingDate && whatsappGroupLink && groupName) {
            
            setIsLoading(true)

            Axios.put('/batch/admin/study-groups', {
                groupName,
                whatsappGroupLink,
                batchStartingDate,
                mentorWhatsappNumber,
                productId
            }).then(() => {
                setNewBatchDetails(null)
                getStudyGroups()
            })
                .catch(err => {
                    console.log(err)
                    alert("Server error. Please check console")
                    setIsLoading(false)
                })

        } else {
            alert("Please enter all values")
            setIsLoading(false)
        }

    }

    const editStudyGroup = () => {
        const { batchStartingDate, whatsappGroupLink, groupName } = editingBatchDetails

        if (batchStartingDate && whatsappGroupLink && groupName) {
            setIsLoading(true)
            Axios.post('/batch/admin/study-groups', editingBatchDetails)
                .then(() => {
                    setEditingBatchDetails(null)
                    getStudyGroups()
                })
                .catch(err => {
                    console.log(err)
                    alert("Server error. Please check console")
                })
        } else {
            alert("Please fill all the details")
        }
    }

    const deleteStudyGroup = (groupId,status) => {
        setIsLoading(true)
        Axios.delete('/batch/admin/study-groups', { params: { groupId,status } })
            .then(() => {
                getStudyGroups()
            })
            .catch(err => {
                console.log(err)
                alert("Server error. Please check console or contact admin")
            })
    }

    const setGroupAsActive = (groupId) => {
        setIsLoading(true)
        Axios.post('/batch/admin/set-active-group', { groupId })
            .then(() => {
                getStudyGroups()
            })
            .catch(err => {
                console.log(err)
                alert("Server error. Please check console or contact admin")
            })
    }

    const exportAllUserCompletionStats = () => {
        setIsLoading(true)
        Axios.get('/batch/admin/day-completion-report', { params: { productId } })
            .then(res => {
                setIsLoading(false)
                FileDownload(res.data, 'report.csv')
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Please check console")
            })
    }

    return (
        <Aux>

            {isLoading && <Loader />}

            {
                newBatchDetails &&
                <AddOrEditDayModal
                    showLoader={isLoading}
                    submit={addStudyGroup}
                    batchDetails={newBatchDetails}
                    updateValues={(key, value) => {
                        const batchDetails = cloneDeep(newBatchDetails)
                        batchDetails[key] = value
                        setNewBatchDetails(batchDetails)
                    }}
                    close={() => setNewBatchDetails(null)}
                />
            }

            {
                editingBatchDetails &&
                <AddOrEditDayModal
                    submit={editStudyGroup}
                    batchDetails={editingBatchDetails}
                    updateValues={(key, value) => {
                        const batchDetails = cloneDeep(editingBatchDetails)
                        batchDetails[key] = value
                        setEditingBatchDetails(batchDetails)
                    }}
                    close={() => setEditingBatchDetails(null)}
                />
            }

            <Container style={{ paddingTop: 50 }} fluid>

                <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Button
                        outline
                        color="primary"
                        style={{ margin: 10, marginBottom: 25 }}
                        onClick={() => setNewBatchDetails(cloneDeep(emptyBatchDetails))}>
                        + CREATE NEW STUDY GROUP
                    </Button>

                    <div>
                        <Link to={`/prelims-practice-user-count`}>
                            <Button
                                color="primary"
                                style={{ margin: 10, marginBottom: 25 }}
                            >
                                All Users Practice Progress
                            </Button>
                        </Link>

                        <Link
                            to={{
                                pathname: '/user-learning-path',
                                search: `postUnitsToFetchId=${productId}&postUnitsToFetchName=${packName}`
                            }}
                        >
                            <Button
                                color="primary"
                                style={{ margin: 10, marginBottom: 25 }}
                            >
                                Single User Practice Progress
                            </Button>
                        </Link>

                        <Button
                            color="primary"
                            style={{ margin: 10, marginBottom: 25 }}
                            onClick={() => exportAllUserCompletionStats()}
                        >
                            Export All Users Stats
                        </Button>
                    </div>

                </Row>

                <Row>
                    {
                        Array.isArray(studyGroups) && studyGroups.length > 0 ?
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Group Name</th>
                                        <th>Class Starting Date</th>
                                        <th>Students Count</th>
                                        <th>Group Link</th>
                                        <th>Mentor Number</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        studyGroups.map((each, index) => {
                                            return (
                                                <tr
                                                    key={each._id}
                                                    style={{ background: each.active ? 'rgba(0,255,0,0.1)' : each.deletedGroup ? 'rgba(255,0,0,0.1)' : null }}
                                                >
                                                    <td>{index + 1}</td>
                                                    <td>{each.groupName}</td>
                                                    <td>{moment(each.batchStartingDate).format('DD MMM YYYY')}</td>
                                                    <td>{each.studentsCount}</td>
                                                    <td>{each.whatsappGroupLink}</td>
                                                    <td>{each.mentorWhatsappNumber}</td>
                                                    <td>
                                                        <Button
                                                            color="primary"
                                                            outline
                                                            style={{ marginRight: 10 }}
                                                            onClick={() => history.push({ pathname: `/batch-students/${productId}/${each._id}` })}
                                                        >
                                                            Students
                                                        </Button>

                                                        <Button
                                                            color="success"
                                                            outline
                                                            disabled={each.active}
                                                            style={{ marginRight: 10 }}
                                                            onClick={() => {
                                                                if (each.deletedGroup) {
                                                                    alert("Cannot set a deleted group as active group")
                                                                } else {
                                                                    setGroupAsActive(each._id)
                                                                }
                                                            }}>
                                                            Set as active
                                                        </Button>

                                                        <Button
                                                            onClick={() => setEditingBatchDetails({ ...each, batchStartingDate: new Date(each.batchStartingDate) })}
                                                            outline
                                                            style={{ marginRight: 10 }}
                                                            color="primary"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                icon={faPen}
                                                            />
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                if (each.active) {
                                                                    alert("Cannot delete an active group")
                                                                } else {
                                                                    deleteStudyGroup(each._id,each.deletedGroup)
                                                                }
                                                            }}
                                                            outline
                                                            color="danger"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="iconButton"
                                                                icon={faTrash}
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            Array.isArray(studyGroups) && studyGroups.length === 0 ?
                                <p>No study groups created in this batch</p>
                                : null
                    }
                </Row>

            </Container>

        </Aux>
    )
}

export default StudyGroups
