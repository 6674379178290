import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'

import Loader from '../../Components/Loader'
import Aux from '../../Components/Auxiliary'
import Axios from 'axios'
import { unstable_batchedUpdates } from 'react-dom'
import moment from 'moment'

function StudyProgress({ match }) {

    const [isLoading, setIsLoading] = useState(true)
    const [studyReport, setStudyReport] = useState(null)
    const [examResult, setExamResult] = useState(null)
    
    const getStudyProgress = () => {

        Axios.get('/batch/admin/student-study-progress', {
            params: {
                groupId: match.params.groupId,
                userId: match.params.studentId,
            }
        })
            .then(res => {
                unstable_batchedUpdates(() => {
                    setStudyReport(res.data.studyReport)
                    setIsLoading(false)
                })
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                alert("Server error. Please check console")
            })
    }

    const fetchExamResult = (userId, examId) => {
        Axios.get('/batch/user/exam-result', { params: { userId, examId } })
        .then(res => {
            if (res.status === 204) {
                alert("User has not attended this exam")
            } else {
                setExamResult(res.data.examResult)
            }
        })
        .catch(err => {
            console.log(err)
            alert("User has not attended this exam")
        })
    }

    useEffect(() => {
        getStudyProgress()
    }, [])

    return (
        <Aux>

            {isLoading && <Loader />}

            <Container style={{ paddingTop: 50 }}>

                {
                    examResult != null &&
                    <Modal
                        size="lg"
                        isOpen={true}
                    >
                        <ModalHeader>
                            Exam Details
                        </ModalHeader>

                        <ModalBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Date:</th>
                                        <th>Marks:</th>
                                        <th>Correct</th>
                                        <th>Wrong</th>
                                        <th>Unattended</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{moment(examResult.attendedDate).format("hh:MM A DD MMM YYYY")}</td>
                                        <td>{examResult.marks}</td>
                                        <td>{examResult.correctAnswer}</td>
                                        <td>{examResult.wrongAnswer}</td>
                                        <td>{examResult.unattended}</td>
                                        <td>{(examResult.timeTaken / 1000 / 60).toFixed(2)} minutes</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => setExamResult(null)}>Close</Button>
                        </ModalFooter>
                    </Modal>
                }

                <Row>
                    <Col>
                        {
                            Array.isArray(studyReport) && studyReport.length > 0 ?
                                studyReport.map(eachDay => (
                                    <React.Fragment key={eachDay._id}>
                                        <h5>{eachDay._id}</h5>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Index</th>
                                                    <th>Module Name</th>
                                                    <th>Module Type</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    eachDay.modules.map((eachModule, index) => (
                                                        <tr
                                                            key={eachModule._id}
                                                            style={{ background: eachModule.hasAttended ? 'rgba(0,255,0,0.2)' : null }}
                                                        >
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {(eachModule.moduleDetails && eachModule.moduleDetails.moduleTitle) ?
                                                                    <>
                                                                        {eachModule.moduleDetails.moduleTitle}<br />
                                                                        {eachModule.moduleDetails.activeFlag ? null : <p style={{ fontSize: 14, color: 'red' }}>Deleted module</p>}
                                                                    </>
                                                                    :
                                                                (eachModule.examDetails && eachModule.examDetails.description) ? eachModule.examDetails.description : ''}
                                                            </td>
                                                            <td>{eachModule.contentType}</td>
                                                            <td>
                                                                {
                                                                    (eachModule.hasAttended && (eachModule.contentType === "mockExam" || eachModule.contentType === "revisionExam")) ?
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={ () => fetchExamResult(match.params.studentId, eachModule.examDetails._id) }
                                                                    >
                                                                        Show Results
                                                                    </Button>
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        <hr />
                                    </React.Fragment>
                                ))
                                :
                                Array.isArray(studyReport) && studyReport.length === 0 ?
                                    <p style={{ textAlign: 'center' }}>User has not completed any modules</p>
                                    :
                                    <p style={{ textAlign: 'center' }}>Study report not found</p>
                        }
                    </Col>
                </Row>
            </Container>

        </Aux>
    )
}

export default StudyProgress