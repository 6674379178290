import React from 'react'
import axios from 'axios'
import {
    Container, Col, Table, Row, Button, Modal, ModalHeader, ModalBody,
    Input, ModalFooter, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap'
import { connect } from 'react-redux'
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faReply, faSort } from '@fortawesome/free-solid-svg-icons'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { cloneDeep, isFinite } from 'lodash';

import Loader from '../Components/Loader'


const recentlySelectedPostKey = "recentlySelectedPost"
const tenthPrelimsPathId = "5f690c88b44c94016aa02395"

const SortableItem = SortableElement(({ eachPath, listIndex, history, editUnit, deletePath, postUnitsToFetchName, isSortingEnabled }) => {
    return (
        <tr key={eachPath._id} style={{ background: eachPath.activeFlag ? 'white' : '#ffcccb' }}>
            <td>{eachPath.orderNumber}</td>
            <td>{eachPath.unitName}</td>
            <td>{isFinite(eachPath.unitMarks) ? `${eachPath.unitMarks} Marks` : ''}</td>
            <td>{eachPath.topics.length}</td>
            <td>
                { !isSortingEnabled ?
                    <>
                        <Button
                            style={{ marginRight: 10 }}
                            outline
                            color="primary"
                            onClick={() => history.push({ pathname: `/edit-unit/${eachPath._id}` })}
                        >
                            OPEN
                        </Button>
                        <Button
                            style={{ marginRight: 10 }} outline color="primary"
                            onClick={() => editUnit(eachPath._id, eachPath.unitName, eachPath.unitMarks)}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </Button>

                        {
                            <Button outline color="danger" onClick={() => deletePath(eachPath._id)} >
                                <FontAwesomeIcon icon={eachPath.activeFlag ? faTrash : faReply} />
                            </Button>
                        }
                    </>
                    : <FontAwesomeIcon
                        icon={faSort}
                        style={{ marginLeft: 10, color: 'grey' }}
                    />}

            </td>
        </tr>
    )
})
 
const SortableList = SortableContainer(({
    items, history, editUnit, deletePath, postUnitsToFetchName, isSortingEnabled,
    totalMarks, markExcludingDeletedTopics,
    totalTopics, topicsExcludingDeletedTopics
}) => {
    return (
        <tbody>
            {
                items.map((item, index) => (
                    <SortableItem
                        disabled={!isSortingEnabled}
                        isSortingEnabled={isSortingEnabled}
                        key={item._id}
                        index={index}
                        listIndex={index}
                        eachPath={item}
                        history={history}
                        editUnit={editUnit}
                        deletePath={deletePath}
                        postUnitsToFetchName={postUnitsToFetchName}
                    />
                ))
            }
            <tr>
                <th>Total</th>
                <th></th>
                <th>{markExcludingDeletedTopics}/{totalMarks}</th>
                <th>{topicsExcludingDeletedTopics}/{totalTopics}</th>
                <th></th>
            </tr>
        </tbody>
    )
})
class LearningPath extends React.Component {

    state = {
        isLoading: true,
        showNewUnitModal: false,
        editingUnitId: null,
        postUnitsToFetchId: null,
        paths: null,
        isSortingEnabled: false
    }

    componentDidMount() {

        let setObject = { isLoading: false }

        let selectedPostId = tenthPrelimsPathId
        const { postId } = queryString.parse(this.props.location.search)

        if (postId) {
            selectedPostId = postId
        } else {
            const recentlySelectedPost = localStorage.getItem(recentlySelectedPostKey)
            if (recentlySelectedPost) {
                selectedPostId = recentlySelectedPost
            }
        }

        const upcomingPosts = this.props.posts.filter(each => each.upcoming)
        setObject.upcomingPosts = upcomingPosts

        const foundPost = upcomingPosts.find(each => each._id === selectedPostId)

        if (foundPost) {
            setObject.postUnitsToFetchId = foundPost._id
            setObject.postUnitsToFetchName = foundPost.name
        }

        this.setState(setObject, () => {
            if (this.state.postUnitsToFetchId) {
                this.getAllLearningPaths(this.state.postUnitsToFetchId)
            }
        })

    }

    setQueryParams = (postId) => {
        this.props.history.push({
            pathname: "/learning-path/",
            search: `postId=${postId}`
        })
    }

    getAllLearningPaths(postId) {

       this.setQueryParams(postId)

        axios.get('/admin/learning-path/all', { params: { postId } })
            .then(res => {

                localStorage.setItem(recentlySelectedPostKey, postId)

                let totalMarks = 0
                let markExcludingDeletedTopics = 0

                let totalTopics = 0
                let topicsExcludingDeletedTopics = 0
    
                res.data.learningPaths.forEach(eachUnit => {
                    
                    if (isFinite(eachUnit.unitMarks)) {
                        totalMarks += eachUnit.unitMarks
                        if (eachUnit.activeFlag) { markExcludingDeletedTopics += eachUnit.unitMarks }
                    }

                    totalTopics += eachUnit.topics.length
                    if (eachUnit.activeFlag) { topicsExcludingDeletedTopics += eachUnit.topics.length }

                })

                this.setState({
                    paths: res.data.learningPaths,
                    totalMarks,
                    markExcludingDeletedTopics,
                    totalTopics,
                    topicsExcludingDeletedTopics,
                    isLoading: false
                })

            })
            .catch(err => {
                this.setState({ isLoading: false })
                console.log(err)
                alert("error, please check console")
            })
    }

    createOrEditUnit() {

        if (this.state.selectedPostId && this.state.selectedPostName && this.state.newUnitName && this.state.editingUnitId) {

            this.setState({ isLoading: true })
            axios.post('/admin/learning-path', {
                editingUnitId: this.state.editingUnitId, 
                postId: this.state.selectedPostId,
                unitName: this.state.newUnitName,
                unitMarks: this.state.newUnitMarks,
            })
                .then(() => {
                    this.setState({
                        selectedPostId: null,
                        selectedPostName: null,
                        newUnitName: null,
                        newUnitMarks: null,
                        showNewUnitModal: false,
                        editingUnitId: null,
                        isLoading: false,
                        postUnitsToFetchId: this.state.selectedPostId,
                    }, () => {
                        this.getAllLearningPaths(this.state.postUnitsToFetchId)
                    })
                }).catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("error, please check console")
                })

        } else if (this.state.postUnitsToFetchId && this.state.newUnitName) {
            axios.put('/admin/learning-path', {
                postId: this.state.postUnitsToFetchId,
                unitName: this.state.newUnitName,
                unitMarks: this.state.newUnitMarks,
            })
                .then(res => {
                    this.setState({
                        isLoading: false,
                        selectedPostId: null,
                        selectedPostName: null,
                        newUnitName: null,
                        newUnitMarks: null,
                        showNewUnitModal: false,
                        editingUnitId: null
                    }, () => {
                        this.getAllLearningPaths(this.state.postUnitsToFetchId)
                    })
                }).catch(err => {
                    this.setState({ isLoading: false })
                    console.log(err)
                    alert("error, please check console")
                })
        } else {
            this.setState({ isLoading: false })
            alert("Please enter new unit name and select post")
        }
    }

    editUnit = (editingUnitId, editingUnitName, editingUnitMarks) => {
        const { postUnitsToFetchName, postUnitsToFetchId } = this.state
        this.setState({
            showNewUnitModal: true,
            editingUnitId,
            newUnitName: editingUnitName,
            newUnitMarks: editingUnitMarks,
            selectedPostId: postUnitsToFetchId,
            selectedPostName: postUnitsToFetchName,
        })
    }

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value })

    renderNewUnitForm() {
        return (
            <Modal
                fade={true}
                isOpen={this.state.showNewUnitModal}
            >
                <ModalHeader>Select Post for New Unit</ModalHeader>

                <ModalBody>

                    <Input
                        placeholder="Enter Unit Name"
                        type="text"
                        name="newUnitName"
                        value={this.state.newUnitName || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                    <Input
                        placeholder="Marks"
                        type="number"
                        name="newUnitMarks"
                        value={this.state.newUnitMarks || ''}
                        onChange={this.handleInputChange}
                        style={{ marginBottom: 20 }}
                    />

                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => this.createOrEditUnit() } color="primary">{this.state.editingUnitId ? "Submit Edit" : "Create Unit"}</Button>
                    <Button onClick={() => this.setState({ showNewUnitModal: false, selectedPostId: null, selectedPostName: null, editingUnitId: null })} outline>Close</Button>
                </ModalFooter>

            </Modal>

        )
    }

    deletePath = (unitId) => {
        axios.delete('/admin/learning-path', { params: { unitId } })
            .then(res => {
                this.getAllLearningPaths(this.state.postUnitsToFetchId)
            }).catch(err => {
                console.log(err)
                alert("Error, check console")
            })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState((oldState) => {
            const reorderedList = arrayMove(this.state.paths, oldIndex, newIndex)
            return { paths: reorderedList }
        }, () => {

            const paths = cloneDeep(this.state.paths)
            const reorderedItem = { documentId: paths[newIndex]._id, newIndex }

            if (newIndex > oldIndex) {

                const data = paths.slice(oldIndex, newIndex).map((each) => each._id)
                const decrementByOneDocuments = data

                axios.post('/admin/learning-path/module/reorder', { decrementByOneDocuments, reorderedItem })
                    .catch(err => {
                        console.log(err)
                        alert("Error, check console")
                    })

            } else if (newIndex < oldIndex) {

                const incrementByOneDocuments = paths.slice(newIndex + 1, oldIndex + 1).map((each) => each._id)

                axios.post('/admin/learning-path/module/reorder', { incrementByOneDocuments, reorderedItem })
                    .catch(err => {
                        console.log(err)
                        alert("Error, check console")
                    })

            } else {
                //No change in array order
                return true
            }

        })
    }

    render() {
        return (
            <>
                
                { this.state.isLoading && <Loader customStyle={{ background: 'red' }} />}
                
                <Container fluid style={{ paddingTop: 50 }}>

                    {(this.state.postUnitsToFetchId) && this.renderNewUnitForm()}

                    <Row>
                        <UncontrolledDropdown style={{ margin: 10 }}>
                            <DropdownToggle caret color="primary"> {(this.state.postUnitsToFetchName && this.state.postUnitsToFetchId) ? this.state.postUnitsToFetchName : "Select Post"}</DropdownToggle>
                            <DropdownMenu className="addScroll">
                                {
                                    Array.isArray(this.state.upcomingPosts) && this.state.upcomingPosts.map((each) => {
                                        return (
                                            <DropdownItem
                                                key={each._id}
                                                value={each.title}
                                                onClick={() => this.setState({
                                                    postUnitsToFetchId: each._id,
                                                    postUnitsToFetchName: each.name,
                                                    isLoading: true
                                                }, () => {
                                                    this.getAllLearningPaths(this.state.postUnitsToFetchId)
                                                })}
                                            >
                                                {each.name}
                                            </DropdownItem>)
                                    })
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Row>

                    <Row>
                        {
                            this.state.postUnitsToFetchId ?
                                <>
                                    <hr />
                                    <Button
                                        color="primary"
                                        style={{ margin: 10 }}
                                        onClick={() => this.setState({ showNewUnitModal: true })}>
                                        + ADD NEW UNIT
                                    </Button>
                                    <Button
                                        outline
                                        style={{ margin: 10 }}
                                        color='success'
                                        onClick={() => this.setState({ isSortingEnabled: !this.state.isSortingEnabled })}
                                    >{this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                                    </Button>
                                </>
                                : null
                        }
                    </Row>

                    {
                        Array.isArray(this.state.paths) && this.state.paths.length > 0 ?
                            <Row>
                                <Col>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Index</th>
                                                <th>Name</th>
                                                <th>Marks</th>
                                                <th>Modules Count</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <SortableList
                                            isSortingEnabled={this.state.isSortingEnabled}
                                            items={this.state.paths}
                                            onSortEnd={this.onSortEnd}
                                            history={this.props.history}
                                            editUnit={this.editUnit}
                                            deletePath={this.deletePath}
                                            postUnitsToFetchName={this.state.postUnitsToFetchName}
                                            totalMarks={this.state.totalMarks}
                                            markExcludingDeletedTopics={this.state.markExcludingDeletedTopics}
                                            totalTopics={this.state.totalTopics}
                                            topicsExcludingDeletedTopics={this.state.topicsExcludingDeletedTopics}
                                        />
                                    </Table>
                                </Col>
                            </Row>
                            :
                            Array.isArray(this.state.paths) && this.state.paths.length === 0 ?
                                <Row style={{ marginTop: 50 }}>
                                    <Col>
                                        <h4>No units added in this post</h4>
                                    </Col>
                                </Row>
                                :

                                <Row style={{ marginTop: 50 }}>
                                    <Col>
                                        <h6>Please select a crash course</h6>
                                    </Col>
                                </Row>
                    }
                </Container>

            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.auth._id,
        posts: state.data.posts
    }
}

export default connect(mapStateToProps)(LearningPath)
