import React, { useState } from 'react'
import { Button, Modal, ModalBody,ModalFooter,ModalHeader } from 'reactstrap';
import CheckBox from 'material-ui/Checkbox';

export default function EditorOptionModal(props) {

    const [showEditorModal, setShowEditorModal] = useState(false)
    const [isShowAllSubjectChecked, setIsShowAllSubjectChecked] = useState(false)
    const [islanguagePredictionChecked, setIslanguagePredictionChecked] = useState(true)

    const renderEditorModal = () => {
        return (
            <Modal
                isOpen={showEditorModal}
                toggle={() => setShowEditorModal(!showEditorModal)}
            >

                <ModalHeader>
                    Editor Options
                </ModalHeader>

                <ModalBody>
                    <CheckBox
                        label={'Show All Subjects'}
                        style={{ marginTop: '10px' }}
                        checked={isShowAllSubjectChecked}
                        onClick={() => { setIsShowAllSubjectChecked(!isShowAllSubjectChecked)}}
                    />
                    <CheckBox
                        label={'Language Prediction'}
                        style={{ marginTop: '10px' }}
                        checked={islanguagePredictionChecked}
                        onClick={() => setIslanguagePredictionChecked(!islanguagePredictionChecked) }
                    />
                </ModalBody>

                <ModalFooter>

                    <Button color="primary"
                        onClick={() => {
                            props.onSubmit(isShowAllSubjectChecked, islanguagePredictionChecked)
                            setShowEditorModal(false)
                        }}
                    >
                        Submit
                    </Button>

                    <Button
                        outline
                        onClick={() => setShowEditorModal(false)}
                        color="secondary">
                        Cancel
                    </Button>

                </ModalFooter>

            </Modal>
        )
    }

    return (
        <div>
            { renderEditorModal() }
            <Button
                block={true}
                color="link"
                onClick={() => {
                    setShowEditorModal(true)
                    setIsShowAllSubjectChecked(props.showAllSubject)
                    setIslanguagePredictionChecked(props.islanguagePrediction)
                }}
            >
                Editor Option
            </Button> 
        </div>
    )
}
