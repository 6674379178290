import React, { useState, useEffect } from 'react'
import { cloneDeep, sample, shuffle, sortBy, uniqBy, isFinite } from 'lodash';
import { Row, Col, Form, Button, FormGroup, Input, ListGroup, ListGroupItem, Label } from 'reactstrap';
import { unstable_batchedUpdates } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { fixSpacesBetweenStrings } from '../../utils';

let generatedOptionsClickedCount = 0

const availableOptionTypes = [
    ["1", "2", "3", "4", "5"], // 0
    ["i", "ii", "iii", "iv", "v"], // 1
    ["ഒന്ന്", "രണ്ട്", "മൂന്ന്", "നാല്", "അഞ്ച്"], // 2
    ["ഒന്നും", "രണ്ടും", "മൂന്നും", "നാലും", "അഞ്ചും"], // 3
]

const NUMBER_OPTIONS = 0
const ROMAN_OPTIONS = 1
const MALAYALAM_OPTIONS = 2
const MALAYALAM_OPTIONS_JOINED = 3


const ENGLISH_LANGUAGE_ID = "5b0326f395409b0f800bd784"
const MALAYALAM_LANGUAGE_ID = "5b0326f395409b0f800bd785"

const getRandomNumber = (ceiling) => {
    return Math.floor(Math.random() * ceiling)
}

const getRandomBoolean = () => {
    const bool = Math.random() < 0.5
    return bool
}

const AUTO_SELECT = "auto_select"
const TRUE_TYPE = "ശരി"
const FALSE_TYPE = "തെറ്റ്"
const GENERAL_TYPE = "general"

// 2 WRONG OPTION AVAILABLE METHODS
// 2.1. "Ex: "എല്ലാം ശരി" Use when any option is wrong
const ALL_CORRECT = {
    multipleAllowed: false,
    name: "all_correct",
    used: false,
    generateString: (wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId) => {
        if (trueOrFalseOrGeneralType === TRUE_TYPE) {
            if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                return "All are correct"
            } else {
                return "എല്ലാം ശരി"
            }
        } else if (trueOrFalseOrGeneralType === GENERAL_TYPE) {
            if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                return "All"
            } else {
                return "എല്ലാം"
            }
        }
    }
}

// 2.2.  "Ex: എല്ലാം തെറ്റ്" Use when any option is correct
const ALL_WRONG = {
    multipleAllowed: false,
    name: "all_wrong",
    used: false,
    generateString: (wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId) => {
        if (trueOrFalseOrGeneralType === FALSE_TYPE) {
            if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                return "All are wrong"
            } else {
                return "എല്ലാം തെറ്റ്"
            }
        } else {
            if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                return "None of these"
            } else {
                return "ഇവയൊന്നുമല്ല"
            }
        }
    }
}

// 2.3. Ex: "... മാത്രം ശരി" // Use when more than one option is correct, or if the selected option is wrong
const ANY_ITEM_ONLY_CORRECT = {
    multipleAllowed: true,
    name: "any_item_only_correct",
    used: false,
    generateString: (wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId) => {

        const availableOptions = availableOptionTypes[selectedOptionTypeIndex]
        let onlyCorrectString = ""

        if (trueOrFalseOrGeneralType === TRUE_TYPE) {
            if (questionLanguageId === MALAYALAM_LANGUAGE_ID) {
                onlyCorrectString = " മാത്രം ശരി"
            } else {
                onlyCorrectString = " only correct"
            }
        } else if (trueOrFalseOrGeneralType === GENERAL_TYPE) {
            if (questionLanguageId === MALAYALAM_LANGUAGE_ID) {
                onlyCorrectString = " മാത്രം"
            } else {
                onlyCorrectString = " only"
            }
        }

        if (correctOptionIndexes.length > 1) {
            return `${availableOptions[sample(correctOptionIndexes)]}${onlyCorrectString}`
        } else if (wrongOptionIndexes.length > 0) {
            return `${availableOptions[sample(wrongOptionIndexes)]}${onlyCorrectString}`
        }
    }
}

// 2.4. Ex: "ഒന്നും രണ്ടും ... ശരി" // Either any of these or both of these or both should be worng, 
const MULTIPLE_ITEMS_CORRECT = {
    multipleAllowed: true,
    name: "multiple_items_correct",
    used: false,
    generateString: (wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId) => {

        // If malayalam options selected, change to malayalam joined options
        const availableOptions = selectedOptionTypeIndex === 2 ? availableOptionTypes[MALAYALAM_OPTIONS_JOINED] : availableOptionTypes[selectedOptionTypeIndex]
        let appendString = ""

        if (trueOrFalseOrGeneralType === TRUE_TYPE) {
            if (questionLanguageId === MALAYALAM_LANGUAGE_ID) {
                appendString = " ശരി"
            } else {
                appendString = " correct"
            }
        }

        if (wrongOptionIndexes.length > 1 && getRandomBoolean()) {

            // Both wrong // "ഒന്നും രണ്ടും ... ശരി"
            const firstItem = sample(wrongOptionIndexes.filter((each, index) => index < wrongOptionIndexes.length - 1))
            const secondItem = sample(wrongOptionIndexes.filter((each, index) => each > firstItem))
            return `${availableOptions[firstItem]}${selectedOptionTypeIndex !== 2 ? ",": ""} ${availableOptions[secondItem]}${appendString}`

        } else if (correctOptionIndexes.length > 0 && wrongOptionIndexes.length > 0 && getRandomBoolean()) {

            // Any one wrong
            const oneCorrectItem = sample(correctOptionIndexes)
            const oneWrongOption = sample(wrongOptionIndexes)
            const arrayOfOptions = sortBy([oneCorrectItem, oneWrongOption])
            return `${availableOptions[arrayOfOptions[0]]}${selectedOptionTypeIndex !== 2 ? ",": ""} ${availableOptions[arrayOfOptions[1]]}${appendString}`

        }
    }
}

// 2.5. Ex: "ഒന്ന് രണ്ട് തെറ്റ്" // Either any of these or both of these or both should be correct
const MULTIPLE_ITEMS_WRONG = {
    multipleAllowed: true,
    name: "multiple_items_wrong",
    used: false,
    generateString: (wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId) => {

        let selectedOptionsIndex = null

        if (selectedOptionTypeIndex === MALAYALAM_OPTIONS) {
            selectedOptionsIndex = MALAYALAM_OPTIONS_JOINED
        } else {
            selectedOptionsIndex = selectedOptionTypeIndex
        }

        let availableOptions = availableOptionTypes[selectedOptionsIndex]
        
        let wrongString = ""
        
        if (questionLanguageId === MALAYALAM_LANGUAGE_ID && trueOrFalseOrGeneralType === FALSE_TYPE) {
            wrongString = " തെറ്റ്"
        } else if (trueOrFalseOrGeneralType === FALSE_TYPE) {
            wrongString = " wrong"
        } else if (
            trueOrFalseOrGeneralType === GENERAL_TYPE &&
            (selectedOptionsIndex !== 2 && selectedOptionsIndex !== 3) &&
            questionLanguageId === MALAYALAM_LANGUAGE_ID
        ) {
            wrongString = " എന്നിവ"
        }

        if (correctOptionIndexes.length > 1 && getRandomBoolean()) {

            // Both wrong // "ഒന്നും രണ്ടും ... ശരി"
            const firstItem = sample(correctOptionIndexes.filter((each, index) => index < correctOptionIndexes.length - 1))
            const secondItem = sample(correctOptionIndexes.filter((each, index) => each > firstItem))
            return `${availableOptions[firstItem]}${selectedOptionsIndex !== 3 ? ",":""} ${availableOptions[secondItem]}${wrongString}`

        } else if (correctOptionIndexes.length > 0 && wrongOptionIndexes.length > 0 && getRandomBoolean()) {
            // Any one wrong
            const oneCorrectItem = sample(correctOptionIndexes)
            const oneWrongOption = sample(wrongOptionIndexes)
            const arrayOfOptions = sortBy([oneCorrectItem, oneWrongOption])
            return `${availableOptions[arrayOfOptions[0]]}${selectedOptionsIndex !== 3 ? ",":""} ${availableOptions[arrayOfOptions[1]]}${wrongString}`
        }
    }
}

// 2.6. Ex: .... ശരി, ... തെറ്റ് // Either statements should be wrong
const ONE_CORRECT_OTHER_WRONG = {
    multipleAllowed: true,
    name: "one_correct_another_wrong",
    used: true,
    generateString: (wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId) => {

        const availableOptions = availableOptionTypes[selectedOptionTypeIndex]

        const shariString = questionLanguageId === MALAYALAM_LANGUAGE_ID ? "ശരി" : "correct"
        const wrongString = questionLanguageId === MALAYALAM_LANGUAGE_ID ? "തെറ്റ്" : "wrong"

        if (trueOrFalseOrGeneralType === TRUE_TYPE) {
            if (wrongOptionIndexes.length > 0 && correctOptionIndexes.length > 0) {

                const oneCorrectItem = sample(correctOptionIndexes)
                const oneWrongOption = sample(wrongOptionIndexes)

                if (oneCorrectItem > oneWrongOption) {
                    const options = selectedOptionTypeIndex === 2 ? availableOptionTypes[3] : availableOptionTypes[selectedOptionTypeIndex]
                    return `${options[oneWrongOption]}, ${options[oneCorrectItem]} ${shariString}`
                } else {
                    return `${availableOptions[oneCorrectItem]} ${wrongString}, ${availableOptions[oneWrongOption]} ${shariString}`
                }

            } else if (wrongOptionIndexes.length > 0) {

                const oneWrongOption = sample(wrongOptionIndexes)

                let randomOption;

                if (correctOptionIndexes.length > 0 && (getRandomBoolean() || wrongOptionIndexes.length === 0)) {
                    randomOption = sample(correctOptionIndexes)
                } else {
                    randomOption = sample(wrongOptionIndexes.filter(each => each !== oneWrongOption))
                }

                const options = selectedOptionTypeIndex === 2 ? availableOptionTypes[3] : availableOptionTypes[selectedOptionTypeIndex]
                return `${options[oneWrongOption]}, ${options[randomOption]} ${shariString}`

            }
        }
    }
}

//  2.7. Ex: "... മാത്രം തെറ്റ്" // Use when more than one option is wrong, or if the selected option is correct
const ANY_ITEM_ONLY_WRONG = {
    multipleAllowed: true,
    name: "any_item_only_wrong",
    used: false,
    generateString: (wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId) => {

        const availableOptions = availableOptionTypes[selectedOptionTypeIndex]

        const randomCorrectOption = sample(correctOptionIndexes)
        
        let wrongString = " മാത്രം തെറ്റ്"
        let onlyString = " മാത്രം"

        if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
            onlyString = " only"
            wrongString = " only wrong"
        }

        if (isFinite(randomCorrectOption) && getRandomBoolean()) {
            return `${availableOptions[randomCorrectOption]}${trueOrFalseOrGeneralType === FALSE_TYPE ? wrongString : onlyString}`
        } else if (wrongOptionIndexes.length > 1 && getRandomBoolean()) {
            const randomWrongOption = sample(wrongOptionIndexes)
            return `${availableOptions[randomWrongOption]}${trueOrFalseOrGeneralType === FALSE_TYPE ? wrongString : onlyString}`
        }

    }
}

const getCorrectOptionString = (correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, allStatements, questionLanguageId) => {

    let newstring = ""
    let selectedOptionTypeIndexCopy = selectedOptionTypeIndex

    let options = availableOptionTypes[selectedOptionTypeIndexCopy]
    
    if (Array.isArray(correctOptionIndexes) && correctOptionIndexes.length === allStatements.length) {
        // All statements are correct
        if (selectedOptionTypeIndexCopy === MALAYALAM_OPTIONS) {

            if (trueOrFalseOrGeneralType === TRUE_TYPE) {
                newstring = "എല്ലാം ശരി"
            } else if (trueOrFalseOrGeneralType === FALSE_TYPE) {
                newstring = "എല്ലാം തെറ്റ്"
            } else if (trueOrFalseOrGeneralType === GENERAL_TYPE) {
                newstring = "ഇവയെല്ലാം"
            }

        } else if (selectedOptionTypeIndexCopy === ROMAN_OPTIONS || selectedOptionTypeIndexCopy === NUMBER_OPTIONS) {

            if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                if (trueOrFalseOrGeneralType === TRUE_TYPE) {
                    newstring = "All are correct"
                } else if (trueOrFalseOrGeneralType === FALSE_TYPE) {
                    newstring = "All are wrong"
                } else if (trueOrFalseOrGeneralType === GENERAL_TYPE) {
                    newstring = "All of these"
                }
            } else {
                if (trueOrFalseOrGeneralType === TRUE_TYPE) {
                    newstring = "എല്ലാം ശരി"
                } else if (trueOrFalseOrGeneralType === FALSE_TYPE) {
                    newstring = "എല്ലാം തെറ്റ്"
                } else if (trueOrFalseOrGeneralType === GENERAL_TYPE) {
                    newstring = "ഇവയെല്ലാം"
                }
            }
        }

    } else if (!Array.isArray(correctOptionIndexes) || correctOptionIndexes.length === 0) {
        // No correct statements
        if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
            newstring = "None of these"
        } else {
            newstring = "ഇവയൊന്നുമല്ല"
        }

    } else if (Array.isArray(correctOptionIndexes) && correctOptionIndexes.length > 0) {

        // True Types
        if (trueOrFalseOrGeneralType !== FALSE_TYPE) {
            // 1.1 "Ex: "ഒന്ന് മാത്രം ശരി" User when only one correct option
            if (correctOptionIndexes.length === 1) {

                let appendString = ""

                if (trueOrFalseOrGeneralType === TRUE_TYPE) {
                    if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                        appendString = " only correct"
                    } else {
                        appendString = " മാത്രം ശരി"
                    }
                } else {
                    if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                        appendString = " only"
                    } else {
                        appendString = " മാത്രം"
                    }
                }

                newstring = `${options[correctOptionIndexes]}${appendString}`

            } else if (correctOptionIndexes.length > 0 && correctOptionIndexes.length < allStatements.length) {

                // if malayalam options, switch to malayalam joined options
                if (selectedOptionTypeIndexCopy === 2 && correctOptionIndexes.length > 1) {
                    options = availableOptionTypes[MALAYALAM_OPTIONS_JOINED]
                    selectedOptionTypeIndexCopy = 3
                }
                
                // 1.2 "Ex: "ഒന്ന് രണ്ട് ശരി" Use when more than one but not all are correct
                let appendString = ""

                if (trueOrFalseOrGeneralType === TRUE_TYPE) {
                    if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                        appendString = " correct"
                    } else {
                        appendString = " ശരി"
                    }
                } else if (
                    trueOrFalseOrGeneralType === GENERAL_TYPE && 
                    questionLanguageId === MALAYALAM_LANGUAGE_ID &&
                    selectedOptionTypeIndexCopy !== MALAYALAM_OPTIONS_JOINED &&
                    correctOptionIndexes.length > 1
                ) {
                    appendString = " എന്നിവ"
                }

                if (correctOptionIndexes.length > 1) {
                    
                    correctOptionIndexes.forEach((item, index) => {
                        
                        if (newstring &&
                            selectedOptionTypeIndexCopy !== MALAYALAM_OPTIONS &&
                            selectedOptionTypeIndexCopy !== MALAYALAM_OPTIONS_JOINED
                        ) {
                            newstring = newstring + ","
                        }

                        if (newstring) {
                            newstring = `${newstring} ${options[item]}`
                        } else {
                            newstring = `${options[item]}`
                        }

                    })

                    newstring = `${newstring}${appendString}`

                } else {

                    let onlyString = ""
    
                    if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                        onlyString = " only"
                    } else {
                        onlyString = " മാത്രം"
                    }

                    newstring = `${options[0]}${onlyString}${appendString}`

                }

            }
        } else if (trueOrFalseOrGeneralType === FALSE_TYPE) {
            // False Type

            // 1.3 "Ex: ഒന്ന് തെറ്റ്" // When single option for thattaya utharam kandethuka
            if (correctOptionIndexes.length === 1) {
                let appendString = ""

                if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                    appendString = " only wrong"
                } else {
                    appendString = " മാത്രം തെറ്റ്"
                }

                newstring = `${options[correctOptionIndexes]}${appendString}`

            } else if (correctOptionIndexes.length > 0 && correctOptionIndexes.length < allStatements.length) {

                // if malayalam options, switch to malayalam joined options
                if (selectedOptionTypeIndexCopy === 2 && correctOptionIndexes.length > 1) {
                    options = availableOptionTypes[MALAYALAM_OPTIONS_JOINED]
                }
                
                // 1.2 "Ex: "ഒന്ന് രണ്ട് ശരി" Use when more than one but not all are correct
                let appendString = ""

                if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                    appendString = " wrong"
                } else {
                    appendString = " തെറ്റ്"
                }

                if (correctOptionIndexes.length > 1) {
                    
                    correctOptionIndexes.forEach((item, index) => {
                        if (newstring && selectedOptionTypeIndexCopy !== MALAYALAM_OPTIONS) {
                            newstring += ","
                        }
    
                        if (newstring) {
                            newstring = `${newstring} ${options[item]}`
                        } else {
                            newstring = `${options[item]}`
                        }
                        
                    })

                    newstring = `${newstring}${appendString}`
                    

                } else {

                    let onlyString = ""

                    if (questionLanguageId === ENGLISH_LANGUAGE_ID) {
                        onlyString = " only"
                    } else {
                        onlyString = " മാത്രം"
                    }

                    newstring = `${options[0]}${onlyString}${appendString}`
                }
                

            }
            // 1.4 "Ex: ഒന്ന് രണ്ട് തെറ്റ്" When more than one but not all are answers for thattaya utharam kandethuka

        }


    }

    return newstring;

}

const getWrongOptions = (
    wrongOptionIndexes,
    correctOptionIndexes,
    selectedOptionTypeIndex,
    trueOrFalseOrGeneralType,
    questionLanguageId,
    correctOptionString
    ) => {

    const selectedOptionsTone = []

    // GET AVAILABLE OPTIONS TYPE
    if (wrongOptionIndexes.length > 0) {
        // Wrong options available, so "ELLAM SHARIYANU" and "onn mathram shari" wrong option is available
        if (trueOrFalseOrGeneralType !== FALSE_TYPE) {
            selectedOptionsTone.push(ALL_CORRECT)
        }
    }

    if ((wrongOptionIndexes.length > 0 || correctOptionIndexes.length > 0) && trueOrFalseOrGeneralType !== FALSE_TYPE) {
        selectedOptionsTone.push(ANY_ITEM_ONLY_CORRECT)
    }

    if ((wrongOptionIndexes.length > 0 && correctOptionIndexes.length > 0) || wrongOptionIndexes.length > 1 || correctOptionIndexes.length > 1) {
        // if two wrong options, or atleast one wrong and one correct available, we can use "onn thett, matteth shari"
        if (trueOrFalseOrGeneralType !== FALSE_TYPE) {
            selectedOptionsTone.push(MULTIPLE_ITEMS_CORRECT)
        }
    }

    if ((wrongOptionIndexes.length > 0 && correctOptionIndexes.length > 0) || correctOptionIndexes.length > 1) {
        // if two correct options, or atleast one wrong and one correct available, we can use "onn thett, matteth shari"
        if (trueOrFalseOrGeneralType !== TRUE_TYPE) {
            selectedOptionsTone.push(MULTIPLE_ITEMS_WRONG)
        }
    }

    if (
        ((wrongOptionIndexes.length > 0 && correctOptionIndexes.length > 0) || wrongOptionIndexes.length > 1)
        || (wrongOptionIndexes.length === 0 && correctOptionIndexes.length > 0)
    ) {
        // Either one wrong and one correct available, or two or more wrong available
        // so we can use "ellam shariyaanu" and "onn sheri, matteth thett" wrong option is available
        // Or All correct options, so "onn sheri, matteth thett" wrong option is availble
        selectedOptionsTone.push(ONE_CORRECT_OTHER_WRONG)
    }

    if ((wrongOptionIndexes.length > 1 || correctOptionIndexes.length > 0) && trueOrFalseOrGeneralType !== TRUE_TYPE) {
        // if more than one wrong option and at least one correctOption, we can use "x mathram thett"
        selectedOptionsTone.push(ANY_ITEM_ONLY_WRONG)
    }

    if (
        (wrongOptionIndexes.length === 0 || correctOptionIndexes.length > 0) &&
        // "all_wrong" repeating too much, so remove it in every other iteration
        (generatedOptionsClickedCount % 2 === 0 || selectedOptionsTone.length < 4)
    ) {
        // No wrong options available, so "ellam thettanu" option is available
        selectedOptionsTone.push(ALL_WRONG)
    }


    if (selectedOptionsTone.length === 0) {
        return alert("Not enough options format available")
    }

    const uniqSelectedOptionTones = uniqBy(selectedOptionsTone, e => e.name)
    const uniqSelectedOptionTonesCloned = cloneDeep(uniqSelectedOptionTones)

    let wrongOptions = []
    let iterations = 0

    while (wrongOptions.length < 3) {

        iterations++

        const selectedOptionTone = sample(uniqSelectedOptionTonesCloned.filter(each => (each.multipleAllowed || !each.used)))

        if (!selectedOptionTone || iterations >= 100) {
            // No tone found or max iterations reached
            break;
        }

        const wrongOptionString = selectedOptionTone.generateString(wrongOptionIndexes, correctOptionIndexes, selectedOptionTypeIndex, trueOrFalseOrGeneralType, questionLanguageId)

        // If got string and not already exists as an option, push to options array
        const foundOption = wrongOptions.filter(each => each.text === wrongOptionString)

        if (wrongOptionString && foundOption.length === 0 && correctOptionString !== wrongOptionString) {
            const newWrongOption = { id: wrongOptions.length + 1, isCorrect: false, text: wrongOptionString }
            wrongOptions.push(newWrongOption)
        }

    }

    if (wrongOptions.length === 0) {
        alert("Was unable to generatoe enough wrong options")
    }

    return wrongOptions

}

const autoSelectToneType = (question) => {

    const trueStrings = [ "ശരി", "ശരിയായത്", "ശരിയായവ", "ശരിയായ" ]
    const falseStrings = [ "തെറ്റ്", "തെറ്റായവ", "തെറ്റായ" ]

    if (question) {
        if (trueStrings.some(eachString => question.includes(eachString))) {
            return TRUE_TYPE
        } else if (falseStrings.some(eachString => question.includes(eachString))) {
            return FALSE_TYPE
        } else {
            return GENERAL_TYPE
        }
    }

}

export const generateStatementHtmlQuestion = (question, statements) => {
        
    let htmlQuestion = `
        <p><span style="color: rgb(255,255,255)">${question}</p>
        <ol>
    `

    statements.forEach(eachStatement => {
        htmlQuestion = htmlQuestion + `<li>${eachStatement.text}</li>`
    })

    htmlQuestion = htmlQuestion + "</ol>"

    return htmlQuestion
}

function StatementType(props) {

    const {
        questionLanguageId,
        updateOptions,
        onBlurQuestion,
        currentStatements,
        currentBaseQuestion
    } = props

    const [questionTitle, setQuestionTitle] = useState(currentBaseQuestion)
    const [allStatements, setAllStatements] = useState(currentStatements)

    const [statementCurrentIndex, setStatementCurrentIndex] = useState(null)

    const [newStatement, setNewStatement] = useState('')
    const [trueOrFalseOrGeneralType, setTrueOrFalseOrGeneralType] = useState(AUTO_SELECT)

    useEffect(() => {
        unstable_batchedUpdates(() => {
            setQuestionTitle(currentBaseQuestion)
            setTrueOrFalseOrGeneralType(autoSelectToneType(currentBaseQuestion))
        })
        generatedOptionsClickedCount = 0
    }, [currentBaseQuestion])

    useEffect(() => {
        setAllStatements(currentStatements)
    }, [currentStatements])

    const generateOptions = () => {

        if (!questionTitle) {
            return alert("Enter question")
        }

        if (!questionLanguageId) {
            return alert("Please select question language")
        }

        let toneType = trueOrFalseOrGeneralType

        if (toneType === AUTO_SELECT) {
            toneType = autoSelectToneType(questionTitle)
        }

        // 0. Check at least more than one statement
        if (!Array.isArray(allStatements) || allStatements.length <= 0) {
            return alert("Please enter more than one statement")
        }

        // 1. collection of combinations in the options
        const availableOptionsIndex = [0, 1]
        
        if (questionLanguageId === MALAYALAM_LANGUAGE_ID) {
            availableOptionsIndex.push(MALAYALAM_OPTIONS)
        }

        // 1.1 pick a random option type
        const selectedOptionTypeIndex = availableOptionsIndex[getRandomNumber(availableOptionsIndex.length)]

        // 2. Get correct options indexes
        const correctOptionIndexes = []
        const wrongOptionIndexes = []

        allStatements.forEach((each, index) => {
            if (each.isCorrect) {
                correctOptionIndexes.push(index)
            } else {
                wrongOptionIndexes.push(index)
            }
        })

        if (correctOptionIndexes.length === 0) {
            return alert("Need at least one correct statement")
        }

        let correctOption = {
            id: 0,
            isCorrect: true,
            text: getCorrectOptionString(correctOptionIndexes, selectedOptionTypeIndex, toneType, allStatements, questionLanguageId)
        }

        const wrongOptions = getWrongOptions(
            wrongOptionIndexes,
            correctOptionIndexes,
            selectedOptionTypeIndex,
            toneType,
            questionLanguageId,
            correctOption.text
        )

        let allOptions = shuffle([correctOption, ...wrongOptions])

        updateOptions(questionTitle, allStatements, allOptions)
        generatedOptionsClickedCount++

    }

    const deleteStatement = (idToDelete) => {

        if (idToDelete >= 0) {

            let cloneAllStatements = cloneDeep(allStatements)
            const filteredAllStatements = cloneAllStatements.filter(each => each.id !== idToDelete)
            const mapFilteredAllStatements = filteredAllStatements.map((each, index) => {
                return (
                    { ...each, id: index }
                )
            })
            unstable_batchedUpdates(() => {

                setAllStatements(mapFilteredAllStatements)
                setStatementCurrentIndex(null)
                setNewStatement('')
            })

        }
    }

    return (
        <>
            
            <Row>
                <Col md="12">
                    <Form>
                        <Input
                            id={"questionTitle"}
                            value={questionTitle || ''}
                            placeholder="Main part of question"
                            name="questionTitle"
                            style={{ marginTop: 10, marginBottom: 5, backgroundColor: 'rgba(0, 34, 255,0.1)', borderRadius: 5, padding: 10 }}
                            onBlur={() => {
                                const spacesFixedQuestion = fixSpacesBetweenStrings(questionTitle)
                                onBlurQuestion(spacesFixedQuestion)

                                unstable_batchedUpdates(() => {
                                    setQuestionTitle(spacesFixedQuestion)
                                    setTrueOrFalseOrGeneralType(autoSelectToneType(spacesFixedQuestion))
                                })
                            }}
                            onChange={(e) => setQuestionTitle(e.target.value)}
                        />
                    </Form>
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <Col md="10">
                    <Input
                        id="newStatement"
                        name="newStatement"
                        value={newStatement || ''}
                        placeholder={`Enter statement`}
                        onChange={(e) => setNewStatement(e.target.value) }
                    />
                </Col>

                <Col md="2">
                    <Button
                        block
                        color="primary"
                        disabled={!newStatement}
                        onClick={() => {
                            if (isFinite(statementCurrentIndex)) {
                    
                                let cloneAllStatements = cloneDeep(allStatements)
                                cloneAllStatements[statementCurrentIndex].text = newStatement

                                unstable_batchedUpdates(() => {
                                    setAllStatements(cloneAllStatements)
                                    setStatementCurrentIndex(null)
                                    setNewStatement('')
                                })

                            } else {
                                if (Array.isArray(allStatements) && allStatements.length >=  5) {
                                    alert("Maximum 5 statements only allowed")
                                } else {
                                    setAllStatements([
                                        ...Array.isArray(allStatements) ? allStatements : [],
                                        { id: Array.isArray(allStatements) ? allStatements.length : 0, isCorrect: false, text: newStatement }
                                    ])
                                    setNewStatement('')
                                }
                            }
                        }}
                    > { !isFinite(statementCurrentIndex) ? "Add" : "Edit"}</Button>
                </Col>
            </Row>

            {
                Array.isArray(allStatements) && allStatements.length > 0 ?
                    <Row style={{marginTop: 20}}>
                        <Col md="12">
                            <ListGroup>
                                {
                                    allStatements.map((eachStatement, index) => (
                                        <ListGroupItem
                                            key={eachStatement.text}
                                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
                                        >
                                            <Col>
                                                <FormGroup check>
                                                    <Input
                                                        type="checkbox"
                                                        style={{ height: 20, width: 20 }}
                                                        checked={eachStatement.isCorrect}
                                                        onChange={(e) => {
                                                            let checked = e.target.checked
                                                            const clonedAllStatements = cloneDeep(allStatements)
                                                            clonedAllStatements[index].isCorrect = checked
                                                            setAllStatements(clonedAllStatements)
                                                        }}
                                                    />
                                                    <Label style={{ marginLeft: 10 }}>{eachStatement.text}</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                            >
                                                <Button
                                                    style={{ marginRight: 10 }}
                                                    color='primary'
                                                    outline
                                                    onClick={() => {
                                                        unstable_batchedUpdates(() => {
                                                            setStatementCurrentIndex(index)
                                                            setNewStatement(allStatements[index].text)
                                                        })
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPen} />
                                                </Button>
                                                <Button
                                                    color='danger'
                                                    outline
                                                    onClick={() => {

                                                        deleteStatement(index)

                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </Col>


                                        </ListGroupItem>
                                    ))
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                    :
                    <Row style={{display: "flex", justifyContent: "center"}}>
                        <p style={{ color: 'grey', marginTop: 20 }}>No statements added</p>
                    </Row>
            }

            <hr style={{ marginTop: 20, marginBottom: 20 }} />

            <Row >

                <Col md="4">
                    <FormGroup tag="fieldset">
                        <Input
                            type="select"
                            name="select"
                            id="trueOrFalseOrGeneralType"
                            value={trueOrFalseOrGeneralType}
                            onChange={(e) => setTrueOrFalseOrGeneralType(e.target.value)}
                        >
                            <option value={AUTO_SELECT}>Auto Select</option>
                            <option value={TRUE_TYPE}>TRUE TYPE</option>
                            <option value={FALSE_TYPE}>FALSE TYPE</option>
                            <option value={GENERAL_TYPE}>General</option>
                        </Input>
                    </FormGroup>
                </Col>

                <Col md="4">
                    <Button
                        outline
                        color={"primary"}
                        onClick={generateOptions}
                    >
                        Generate Options
                    </Button>
                </Col>

            </Row>
            
        </>
    )


}

export default StatementType
