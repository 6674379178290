import React, { useEffect, useState } from 'react'
import { Container, Button, Input, Form, FormGroup, Label, Row, Col } from "reactstrap";
import queryString from 'query-string'
import axios from 'axios'
import { DatePicker } from 'material-ui'
import { toast } from 'react-toastify'

import TinyEditor from '../../Components/TinyEditor'
import Loader from '../../Components/Loader'

function BlogEditor({ location }) {

    const [isLoading, setIsLoading] = useState(true)
    const [endpoint, setEndpoint] = useState('')
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [publishingDate, setPublishingDate] = useState(new Date())
    const [imageUri, setImageUri] = useState('')
    const [content, setContent] = useState('')

    const getBlogContent = (blogId) => {
        axios.get('/blog/admin/blog-post', { params: { blogId } })
        .then(res => {
            setEndpoint(res.data.endpoint)
            setTitle(res.data.title)
            setSubtitle(res.data.subtitle)
            setPublishingDate(new Date(res.data.publishingDate))
            setImageUri(res.data.imageUri)
            setContent(res.data.content)
            setIsLoading(false)
        })
        .catch(err => {
            console.error(err)
            setIsLoading(false)
            alert("Server error. Check console")
        })
    }

    const saveBlogContent = () => {

        setIsLoading(true)

        const postObject = { endpoint, title, subtitle, publishingDate, imageUri, content }

        const { blogId } = queryString.parse(location.search)
        if (blogId) {
            postObject.blogId = blogId
        }

        axios.post('/blog/admin/blog-post', postObject)
            .then(() => {
                setIsLoading(false)
                toast("Blog saved", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                })
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                alert("Error, check console")
            })

    }

    useEffect(() => {
        const { blogId } = queryString.parse(location.search)
        if (blogId) {
            getBlogContent(blogId)
        } else {
            setIsLoading(false)
        }
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <Container>
                <Form>
                    <FormGroup>
                        <Row style={{ marginTop: 20 }}>
                            <Col md={6}>
                                <Label>
                                    Blog Title
                                </Label>
                                <Input
                                    id="title"
                                    name="title"
                                    // placeholder="Enter Blog Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </Col>
                            <Col md={6}>
                                <Label>
                                    Blog Subtitle
                                </Label>
                                <Input
                                    id="subtitle"
                                    name="subtitle"
                                    // placeholder="Enter Blog Subtitle"
                                    onChange={(e) => setSubtitle(e.target.value)}
                                    value={subtitle}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            
                            <Col md="5">
                                <Label>
                                    Blog ImageUri
                                </Label>
                                <Input
                                    id="imageUri"
                                    name="imageUri"
                                    onChange={(e) => setImageUri(e.target.value)}
                                    value={imageUri}
                                />
                            </Col>
                            <Col md="5">
                                <Label>
                                    URL Endpoint
                                </Label>
                                <Input
                                    id="endpoint"
                                    name="endpoint"
                                    placeholder="Ex: /devaswom-2019"
                                    onChange={(e) => setEndpoint(e.target.value)}
                                    value={endpoint}
                                />
                            </Col>
                            <Col md="2">
                                <Label>
                                    Publishing Date
                                </Label>
                                <DatePicker
                                    hintText="Publishing Date"
                                    container="inline"
                                    value={publishingDate}
                                    onChange={(e, date) => setPublishingDate(date)}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col md="12">
                                <TinyEditor
                                    height={'60vh'}
                                    content={content}
                                    onHtmlChange={(newHtml) => setContent(newHtml)}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color="primary"
                                    style={{ marginRight: 10 }}
                                    onClick={saveBlogContent}
                                >
                                    SAVE
                                </Button>
                                <Button
                                    color="secondary"
                                    outline
                                    onClick={() => {
                                        if (window.confirm("Discard edits and go back?")) {
                                            window.history.back()
                                        }
                                    }}
                                >
                                    CLOSE
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Container>
        </>
    )
}

export default BlogEditor
