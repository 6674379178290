import React from 'react'
import { cloneDeep, isFinite } from 'lodash';


import {
    Col, Row, Container, Table, Button, 
    Modal, ModalHeader, ModalBody, ModalFooter, 
    Form, FormGroup, Input} from 'reactstrap';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import arrayMove from 'array-move';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSort } from '@fortawesome/free-solid-svg-icons'

const SortableCardItem = SortableElement(({ listIndex, each, onEditButton, onDeleteButton, isSortingEnabled }) => {
    return (
        <tr>
            <td>{parseInt(listIndex) + 1}</td>
            <td>{each.title}</td>
            <td>{each.subTitle}</td>
            <td>{each.explanation ? each.explanation.text : null}</td>
            <td>
                {!isSortingEnabled ?
                    <>
                        <Button
                            outline
                            style={{ marginRight: 5 }}
                            color="danger"
                            onClick={() => onEditButton(listIndex)}
                        >
                            Edit
                        </Button>
                        <Button
                            outline
                            color="danger"
                            onClick={() => onDeleteButton(listIndex)}
                        >
                            Delete
                        </Button>
                    </>

                    : (<FontAwesomeIcon
                        icon={faSort}
                        style={{ marginLeft: 10, color: 'grey' }}
                    />)
                }

            </td>
        </tr>
    )
})

const SortableList = SortableContainer(({ items, onEditButton, onDeleteButton, onEditHtmlButton, isSortingEnabled }) => {
    return (
        <tbody>
            {
                items.map((item, index) => {
                    return (
                        <SortableCardItem
                            disabled={!isSortingEnabled}
                            isSortingEnabled={isSortingEnabled}
                            key={`${index}`}
                            listIndex={index}
                            each={item}
                            index={index}
                            onEditButton={onEditButton}
                            onDeleteButton={onDeleteButton}
                        />
                    )
                }
            )}
        </tbody>
    );
});

class EditCardContent extends React.Component {

    state = {
        showCardModal:false,
        isSortingEnabled:false
    }

    renderAddNewCardModal() {
        let isEditCard = isFinite(this.state.selectedCardDetails.index)

        return (
            <Modal
                isOpen={this.state.showCardModal}
                size='lg'
            >
                <ModalHeader>
                    Add Card
                </ModalHeader>

                <ModalBody>
                    <Form>
                        <FormGroup>

                            <label>Pill Text</label>
                            <Input
                                type="text"
                                name="pillText"
                                placeholder="Pill Text"
                                id="pillText"
                                value={this.state.selectedCardDetails.pillText || ''}
                                onChange={this.handleInput}
                            />

                            <label>Title</label>
                            <Input
                                type="text"
                                name="title"
                                placeholder="Title"
                                id="title"
                                value={this.state.selectedCardDetails.title || ''}
                                onChange={this.handleInput}
                            />
                            <label>Subtitle</label>
                            <Input
                                type="text"
                                name="subTitle"
                                placeholder="Subtitle"
                                id="subtitle"
                                value={this.state.selectedCardDetails.subTitle || ''}
                                onChange={this.handleInput}
                            />
                            <label>Answer</label>
                            <Input
                                type="text"
                                name="answer"
                                placeholder="answer"
                                id="answer"
                                value={this.state.selectedCardDetails.answer || ''}
                                onChange={this.handleInput}

                            />
                            <label>Answer Subtitle</label>
                            <Input
                                type="text"
                                name="answerSubtitle"
                                placeholder="Answer Subtitle"
                                id="answerSubtitle"
                                value={this.state.selectedCardDetails.answerSubtitle || ''}
                                onChange={this.handleInput}

                            />
                        </FormGroup>

                        <FormGroup>
                            <label>Explanation</label>

                            <Input
                                placeholder="Explanation"
                                type="textarea"
                                name="question"
                                style={{ height: 100 }}
                                value={this.state.selectedCardDetails.explanation.text || ''}
                                onChange={(e) => {
                                    let text = e.target.value
                                    this.setState((oldState) => {
                                        oldState.selectedCardDetails.explanation.text = text
                                        return { selectedCardDetails: oldState.selectedCardDetails, changedData: true }
                                    })
                                }}

                            />
                        </FormGroup>

                        <label>Usage</label>
                        <Input
                            type="textarea"
                            name="usage"
                            placeholder="Usage"
                            id="usage"
                            style={{ height: 100 }}
                            value={this.state.selectedCardDetails.usage || ''}
                            onChange={this.handleInput}
                        />

                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ margin: 5 }}
                        color='primary'
                        onClick={() => {
                            const { title, explanation } = this.state.selectedCardDetails

                            if (title) {

                                let moduleData = cloneDeep(this.props.moduleData)

                                this.setState(oldState => {
                                    oldState.selectedCardDetails.explanation.text = explanation.text

                                    if (isEditCard) {
                                        moduleData.content.cards[oldState.selectedCardDetails.index] = oldState.selectedCardDetails
                                    } else if (Array.isArray(moduleData.content.cards)) {
                                        moduleData.content.cards.push(oldState.selectedCardDetails)
                                    } else {
                                        moduleData.content.cards = [oldState.selectedCardDetails]
                                    }

                                    return { showCardModal: false }

                                })

                                this.props.updateModuleData(moduleData)

                            } else {
                                alert("Fill in all required fields")
                            }

                        }}
                    >
                        {isEditCard ? 'Edit' : 'Add'}
                    </Button>
                    <Button
                        onClick={() => this.setState({ showCardModal: false })}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    handleInput = (e) => {

        let value = e.target.value
        let name = e.target.name
        this.setState((oldState) => {
            oldState.selectedCardDetails[`${name}`] = value
            return { selectedCardDetails: oldState.selectedCardDetails, changedData: true }
        })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        let moduleData = cloneDeep(this.props.moduleData)
        // const { moduleType } = moduleData.content

        // let contentType = 'slides'
        // if (moduleType === 'cards') { contentType = 'cards' }

        // this.setState((oldState) => {
            const reorderedList = arrayMove(moduleData.content.cards, oldIndex, newIndex)
            moduleData.content.cards= reorderedList
            this.props.updateModuleData(moduleData)
            // return { ...oldState, changedData: true }
        // })
    }
    
    render() { 
        return (

            <Container fluid>
            <Row>
                <h5 style={{ marginLeft: '1rem' }}>Edit Card Module Details</h5>
            </Row>
            <Row>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                        style={{ marginRight: "auto" }}
                        onClick={() => {
                            this.setState(oldState => {

                                oldState.selectedCardDetails = {
                                    title: '',
                                    pillText: '',
                                    subTitle: "",
                                    answer: "",
                                    answerSubtitle: "",
                                    usage: "",
                                    explanation: {
                                        text: '',
                                        type: 'text'
                                    }
                                }

                                return { selectedCardDetails: oldState.selectedCardDetails, showCardModal: true }
                            })
                        }}>+ Add New Card</Button>

                    <Button
                        outline
                        style={{ marginLeft: "auto" }}
                        color='success'
                        onClick={() => this.setState({ isSortingEnabled: !this.state.isSortingEnabled })}
                    >{this.state.isSortingEnabled ? "Disable Sorting" : "Enable Sorting"}
                    </Button>

                </Col>
            </Row>
            {this.state.showCardModal && this.renderAddNewCardModal()}
            <Table style={{ marginTop: 10 }}>
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Title</th>
                        <th>Sub Title</th>
                        <th>Explanation</th>
                        <th>Actions</th>
                    </tr>
                </thead>

                <SortableList
                    isSortingEnabled={ this.state.isSortingEnabled }
                    items={Array.isArray(this.props.moduleData.content.cards) ? this.props.moduleData.content.cards : []}
                    onSortEnd={this.onSortEnd}
                    // isCards={true}
                    onDeleteButton={ (index) =>
                        this.setState((oldState) => {
                            let moduleData = cloneDeep(this.props.moduleData)
                            moduleData.content.cards.splice(index, 1)
                            this.props.updateModuleData(moduleData)
                            // return { moduleData: oldState.moduleData, changedData: true }
                        }) }
                    onEditButton={(index) => {
                            let moduleData = cloneDeep(this.props.moduleData)
                            const item = moduleData.content.cards[index]
                                this.setState({
                                showCardModal: true,
                                selectedCardDetails: {
                                    index,
                                    title: item.title ? item.title : '',
                                    subTitle: item.subTitle ? item.answer : '',
                                    answer: item.answer ? item.answer : '',
                                    answerSubtitle: item.answerSubtitle ? item.answerSubtitle : "",
                                    usage: item.usage ? item.usage : '',
                                    pillText: item.pillText ? item.pillText : "",
                                    explanation: {
                                        text: item.explanation.text ? item.explanation.text : '',
                                        type: 'text'
                                    }
                                },
                            })
                            this.props.updateCardAndSlideModule(moduleData)
                    }

                }
                />

            </Table>
        </Container>
            
        );
    }
}
 
export default EditCardContent;

        
    
