import React, { Component } from 'react'
import axios from 'axios'
import { Container, Row, Col, Button, Badge, Form, FormGroup, Input, FormText } from 'reactstrap';
import CircularProgress from 'material-ui/CircularProgress'

import CheckBox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import { QUESTION_LANGUAGE_REGEX, REMOVE_CHARS_REGEX } from '../config/env'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote, faImages } from '@fortawesome/free-solid-svg-icons'
import striptags from 'striptags'

import { SEARCH_SERVER } from '../config/env'
import { connect } from 'react-redux'
import { isContentManagerOrHigher, isContentTeam } from '../utils/dashboardRoles';
import SimilarQuestions from '../Components/SimilarQuestions';

const initialState = {
    onlinePaper: false,
    allQuestionsCompleted: false,
    splittedWords: [], selectedWords: [],
    file: null,
    toggleModal: false,
    currentQuestionIndex: 0,
    answer: null,
    option1: null,
    option2: null,
    option3: null,
    option4: null,
    subjectId: null,
    sectionId: null,
    languageId: null,
    filePreview: null,
    question: null,
    dailyChallengeQuestion: false,
    approved: false,
    showInChallenge: true,
    premiumFlag: true,
    questionsArr: [],
    formErrors: { subject: '', section: '', exams: '', question: '', option1: '', option2: '', option3: '', option4: '', answer: '' },
    getTextOnly: false,
    textFromImage: null
};

class ImageUpload extends Component {

    state = {
        selectedPost: null,
        examPost: null,
        exam: null,
        file: null,
        toggleImageModal: false,
        ...initialState
    };

    handleMenuChange = (name, value) => {
        this.setState({ [name]: value })
    }

    clickedWord(eachItem) {

        let foundItem = this.state.selectedWords.find(e => e === eachItem)

        if (foundItem) {
            let afterRemoving = this.state.selectedWords.filter(e => e !== eachItem)
            let wordsFromThisQuestion = afterRemoving.filter(e => this.state.question.split(" ").includes(e))
            this.setState({ selectedWords: wordsFromThisQuestion })
        } else {
            let afterAdding = this.state.selectedWords.concat([eachItem])
            let wordsFromThisQuestion = afterAdding.filter(e => this.state.question.split(" ").includes(e))
            this.setState({ selectedWords: wordsFromThisQuestion })
        }
    }

    fetchSimilarQuestions() {
        if (this.state.question) {
            axios.get(`${SEARCH_SERVER}/search/similar-questions`, { params: { question: striptags(this.state.question) } })
                .then(res => this.setState({ similarQuestions: res.data }))
                .catch(err => console.log(err))
        }
    }

    predictData() {

        let text = this.state.question
        let selected = this.state.selectedWords
        let unselected = this.state.question.split(" ").filter(e => !(this.state.selectedWords.includes(e)))

        axios.post('/predict', { selected, unselected, text })
            .then(res => {

                const { sectionData, descriptions } = res.data

                if (descriptions && descriptions.length > 0) { this.setState({ descriptions }) }


                let subject = this.props.subjects.find(item => {
                    let found = item.sections.find(section => section._id === sectionData[0].sectionId)
                    return found
                })

                this.setState({ subjectId: subject._id, section: subject.sections }, () => {
                    this.setState({ sectionId: sectionData[0].sectionId }, () => {
                    })
                })
            })
    }

    setSubjectAndSection() {
        const { roleIds } = this.props.auth
        if (isContentManagerOrHigher(roleIds)) {
            this.predictData()
        } else if (isContentTeam(roleIds)) {

            let subject = this.props.subjects.find(item => item._id === "5c13eff60c59ab1db896ff9a")

            this.setState({
                subjectId: "5c13eff60c59ab1db896ff9a",
                section: subject.sections
            }, () => {
                this.setState({ sectionId: "5c13f0130c59ab1db896ff9d" })
            })

        }
    }

    onFormSubmit = (e) => {

        console.log("onFormSubmit")
        this.setState({ isLoading: true })

        e.preventDefault();
        const formData = new FormData();
        formData.append('myImage', this.state.file);
        formData.append('onlinePaper', this.state.onlinePaper)
        formData.append('notQuestionFormat', this.state.getTextOnly)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        axios.post("/upload-file", formData, config)
            .then((response) => {
                if (this.state.getTextOnly) {
                    this.setState({ textFromImage: response.data })
                }

                let data = response.data[this.state.currentQuestionIndex];

                let languageId = this.props.languages[1].id
                RegExp(QUESTION_LANGUAGE_REGEX).test(data.question.replace(REMOVE_CHARS_REGEX, "")) ? languageId = this.props.languages[1].id : languageId = this.props.languages[0].id

                this.setState({
                    isLoading: false,
                    raw_data: data.raw_data,
                    questionsArr: response.data,
                    splittedWords: data.question.split(" "),
                    question: data.question,
                    option1: data.options[0],
                    option2: data.options[1],
                    option3: data.options[2],
                    option4: data.options[3],
                    answer: data.answer !== 'undefined' ? data.answer : null,
                    languageId
                }, () => {
                    this.fetchSimilarQuestions()
                    this.setSubjectAndSection()
                })



            }).catch((error) => {
                this.setState({ isLoading: false })
            });
    }

    componentDidUpdate(prevProps, oldState) {
        if (this.state.question && oldState.question !== this.state.question && this.state.type === 'text') {
            this.setState({ splittedWords: this.state.question.split(" ") })
        }
    }
    
    onChange = (e) => {
        this.setState({ filePreview: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
    }

    handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState({ [name]: value });
    }

    renderQuestionForm = () => {
        return (
            <div>
                <Row style={{ marginTop: 25, color: 'white' }}>
                    <Col>
                        <Button
                            block
                            disabled={this.state.subjectId === null || this.state.sectionId === null || this.state.languageId === null || this.state.question === null || this.state.answer === null || this.state.option1 === null || this.state.option2 === null || this.state.option3 === null || this.state.option4 === null}
                            onClick={() => this.submitQuestion()}
                            color="primary"
                        >
                            Submit
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            color="link"
                            style={{ marginLeft: 20 }}
                            onClick={() => this.showNewQuestion()}
                        >
                            Skip 
                        </Button>
                    </Col>
                </Row>
                <div style={{ flex: 1, marginBottom: 25 }}>
                    <TextField
                        id={"4"}
                        hintText="Question"
                        name="question"
                        value={this.state.question || ''}
                        fullWidth={true}
                        errorText={this.state.formErrors.question}
                        onChange={this.handleInput}
                        onBlur={() => {
                            this.fetchSimilarQuestions()
                            RegExp(QUESTION_LANGUAGE_REGEX).test(this.state.question.replace(REMOVE_CHARS_REGEX, "")) ? this.setState({ languageId: this.props.languages[1].id }) : this.setState({ languageId: this.props.languages[0].id })
                        }}
                    />
                </div>

                <SimilarQuestions similarQuestions={this.state.similarQuestions} />

                {
                    isContentManagerOrHigher(this.props.auth.roleIds) &&
                    this.state.splittedWords.map(eachItem => {
                        let color = "secondary"
                        if (this.state.selectedWords.includes(eachItem)) { color = "primary" }
                        return <Badge onClick={() => this.clickedWord(eachItem)} style={{ margin: 2, fontSize: 16 }} color={color} key={eachItem}>{eachItem}</Badge>
                    })
                }

                <div style={{ flex: 1 }}>
                    <TextField
                        id={"1"}
                        value={this.state.option1 || ''}
                        hintText="Option 1"
                        name="option1"
                        fullWidth={true}
                        onChange={this.handleInput}
                    />
                </div>

                <div style={{ flex: 1 }}>
                    <TextField
                        id={"2"}
                        value={this.state.option2 || ''}
                        hintText="Option 2"
                        name="option2"
                        fullWidth={true}
                        errorText={this.state.formErrors.option2}
                        onChange={this.handleInput}
                    />
                </div>

                <div style={{ flex: 1 }}>
                    <TextField
                        id={"3"}
                        value={this.state.option3 || ''}
                        hintText="Option 3"
                        name="option3"
                        fullWidth={true}
                        errorText={this.state.formErrors.option3}
                        onChange={this.handleInput}
                    />
                </div>

                <div style={{ flex: 1 }}>
                    <TextField
                        id={"4"}
                        value={this.state.option4 || ''}
                        hintText="Option 4"
                        name="option4"
                        fullWidth={true}
                        errorText={this.state.formErrors.option4}
                        onChange={this.handleInput}
                    />
                </div>

                <SelectField
                    floatingLabelText="Correct Answer"
                    value={this.state.answer}
                    onChange={(event, third, value) => this.handleMenuChange("answer", value)}
                >
                    <MenuItem value={"A"} primaryText="Option 1" />
                    <MenuItem value={"B"} primaryText="Option 2" />
                    <MenuItem value={"C"} primaryText="Option 3" />
                    <MenuItem value={"D"} primaryText="Option 4" />
                </SelectField>

                {/* <SelectField
                    floatingLabelText="Approved"
                    value={this.state.approved}
                    disabled={!isContentManagerOrHigher(this.props.auth.roleIds)}
                    onChange={(event, index, value) => this.setState({ approved: value, error: false })}
                >
                    <MenuItem value={true} primaryText="Approved" />
                    <MenuItem value={false} primaryText="Not Aprroved" />
                </SelectField> */}

                <div style={{ flex: 1, marginTop: 10 }}>
                    <Form>
                        <FormGroup>
                            <Input
                                id={"4"}
                                placeholder="Description" rows="3" type="textarea"
                                onChange={this.handleInput}
                                value={this.state.description || ''}
                                name="description" />
                        </FormGroup>
                    </Form>
                </div>
                <Row>
                    <Col>
                        <CheckBox
                            label={'Show in Challenge'}
                            style={{ marginTop: '10px' }}
                            checked={this.state.showInChallenge}
                            disabled={isContentTeam(this.props.auth.roleIds)}
                            onClick={() => {
                                this.setState({ showInChallenge: !this.state.showInChallenge })
                            }}
                        />
                    </Col>
                    <Col>
                        <CheckBox
                            label={'Premium Question'}
                            style={{ marginTop: '10px' }}
                            disabled={isContentTeam(this.props.auth.roleIds)}
                            checked={this.state.premiumFlag}
                            onClick={() => {
                                this.setState({ premiumFlag: !this.state.premiumFlag })
                            }}
                        />
                    </Col>
                </Row>

            </div>
        )
    }

    submitQuestion() {
        this.setState({ isLoading: true })
        let data = {
            question: this.state.question,
            options: [
                {
                    id: 'A',
                    text: this.state.option1
                },
                {
                    id: 'B',
                    text: this.state.option2
                },
                {
                    id: 'C',
                    text: this.state.option3
                },
                {
                    id: 'D',
                    text: this.state.option4
                },
            ],
            answer: this.state.answer,
            subjectId: this.state.subjectId,
            sectionId: this.state.sectionId,
            languageId: this.state.languageId,
            dailyChallengeQuestion: false,
            exams: [this.state.exam],
            description: this.state.description,
            tags: [],
            type: 'text'
            // approved: this.state.approved
        }

        axios.post(`/add-question`, data)
            .then((response) => {
                this.showNewQuestion()
            })
            .catch((error) => {
                this.setState({ isLoading: false })
            })
    }

    showNewQuestion = () => {
        if (this.state.currentQuestionIndex + 1 === this.state.questionsArr.length && this.state.questionsArr.length > 0) {
            this.setState({ allQuestionsCompleted: true, isLoading: false })
        } else {
            let data = this.state.questionsArr[this.state.currentQuestionIndex + 1];
            if (data) {

                let languageId = this.props.languages[1].id

                RegExp(QUESTION_LANGUAGE_REGEX).test(data.question.replace(REMOVE_CHARS_REGEX, "")) ? languageId = this.props.languages[1].id : languageId = this.props.languages[0].id

                this.setState({
                    currentQuestionIndex: this.state.currentQuestionIndex + 1,
                    question: data.question,
                    splittedWords: data.question.split(" "),
                    option1: data.options[0],
                    option2: data.options[1],
                    option3: data.options[2],
                    option4: data.options[3],
                    isLoading: false,
                    answer: null,
                    description: null,
                    descriptions: null,
                    similarQuestions: [],
                    selectedWords: [],
                    languageId
                }, () => {
                    this.fetchSimilarQuestions()
                    this.setSubjectAndSection()
                })
            } else {
                this.setState({ isLoading: false })
            }
        }
    }

    handleMenuChange = (name, value) => {
        this.setState({ [name]: value })
    }

    render() {

        const deleteActionsImageModal = [
            <FlatButton
                label="Close"
                primary={true}
                onClick={() => this.setState({ toggleImageModal: false })}
            />
        ];

        const deleteActions = [
            <FlatButton
                label="Close"
                primary={true}
                onClick={() => this.setState({ toggleModal: false })}
            />
        ];

        const allDoneActions = [
            <FlatButton
                label="Go Home"
                primary={true}
                onClick={() => this.props.history.push('/')}
            />,
            <FlatButton
                label="Upload More"
                primary={true}
                onClick={() => this.setState({ ...initialState })}
            />
        ]

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>

                {
                    this.state.isLoading &&
                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        paddingTop: '30%',
                        paddingLeft: '47%',
                        zIndex: 999
                    }}>
                        <CircularProgress size={80} thickness={5} />
                    </div>
                }

                <Dialog
                    title="Copy text from here"
                    modal={true}
                    actions={deleteActions}
                    open={this.state.toggleModal}
                >
                    {this.state.raw_data}
                </Dialog>

                <Dialog
                    title="See the questions"
                    modal={true}
                    actions={deleteActionsImageModal}
                    open={this.state.toggleImageModal}
                >
                    <img style={{ maxWidth: 600, maxHeight: 500 }} alt="upload-preview" src={this.state.filePreview} id="previewImage" />
                </Dialog>

                <Dialog
                    title="All Questions Added"
                    modal={true}
                    actions={allDoneActions}
                    open={this.state.allQuestionsCompleted}
                >
                    { }
                </Dialog>

                {this.state.question === null ? <Container>
                    <Row>
                        <Col>
                            {!this.state.getTextOnly ? <><Row>
                                <Col>
                                    <p style={{ color: 'grey', fontSize: 22, fontWeight: '300' }}>Select an exam</p>
                                    <SelectField
                                        floatingLabelText="Exam Post"
                                        value={this.state.examPost}
                                        onChange={(event, third, value) => {
                                            this.handleMenuChange("examPost", value)
                                        }}
                                    >
                                        {
                                            this.props.posts ?
                                                this.props.posts.reverse().map(post => {
                                                    return <MenuItem
                                                        key={post._id}
                                                        value={post._id}
                                                        primaryText={post.name}
                                                        onClick={() => this.setState({ selectedPost: post })}
                                                    />
                                                })
                                                :
                                                null
                                        }
                                    </SelectField></Col>
                            </Row>

                                <Row>
                                    {this.state.selectedPost !== null && <Col>
                                        <SelectField
                                            floatingLabelText="Exam"
                                            value={this.state.exam}
                                            onChange={(event, third, value) => this.handleMenuChange("exam", value)}
                                        >
                                            {
                                                this.props.posts && this.state.selectedPost ?
                                                    this.state.selectedPost.exams.reverse().map(exam => {
                                                        return <MenuItem
                                                            key={exam._id}
                                                            value={exam._id}
                                                            primaryText={exam.description}
                                                            onClick={() => this.setState({ exam: exam._id })}
                                                        />
                                                    })
                                                    :
                                                    null
                                            }
                                        </SelectField>
                                    </Col>}
                                </Row></> : null}

                            <Row>
                                {this.state.exam !== null && this.state.getTextOnly === false ? <Col>
                                    <CheckBox
                                        label={'Online Question Paper'}
                                        style={{ marginTop: '20px' }}
                                        checked={this.state.onlinePaper}
                                        disabled={isContentTeam(this.props.auth.roleIds)}
                                        onClick={() => {
                                            this.setState({ onlinePaper: !this.state.onlinePaper })
                                        }}
                                    /></Col> : null}
                            </Row>
                            <Row style={{ marginTop: 20 }}>

                                {this.state.exam !== null || this.state.getTextOnly === true ? <Col>
                                    <Form>
                                        <FormGroup>
                                            <p style={{ color: 'grey', fontSize: 13 }}>Upload your question paper</p>
                                            <Input type="file" name="file" id="exampleFile" onChange={this.onChange} />
                                            <FormText color="muted"> Max 1 image. </FormText>
                                        </FormGroup>
                                        <Button
                                            disabled={this.state.filePreview === null}
                                            style={{ paddingLeft: 70, paddingRight: 70 }}
                                            type="submit"
                                            onClick={this.onFormSubmit}
                                            outline color="primary">
                                                + Upload Image
                                        </Button>
                                    </Form>
                                </Col> : null}
                            </Row>

                            {this.state.textFromImage && <Row style={{ padding: 20, marginTop: 20, marginBottom: 20, backgroundColor: 'gainsboro' }}>
                                {this.state.textFromImage}
                            </Row>}

                        </Col>
                        <Col>
                            <Row style={{ marginBottom: 20 }}>
                                {!this.state.getTextOnly ?
                                    <Button style={{ paddingLeft: 70, paddingRight: 70 }} type="submit" onClick={() => this.setState({ getTextOnly: true })} outline color="primary">
                                        Get Text
                                    </Button>
                                    :
                                    <Button style={{ paddingLeft: 70, paddingRight: 70 }} type="submit" onClick={() => this.setState({ getTextOnly: false })} outline color="primary">
                                        Add Question Paper
                                    </Button>
                                }
                            </Row>
                            <Row>
                                <h5>Preview</h5>
                                {this.state.filePreview && <>

                                    <img style={{ maxWidth: 600, maxHeight: 500 }} alt="upload-preview" src={this.state.filePreview} id="previewImage" /> </>
                                }
                            </Row>

                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid style={{ margin: '2rem' }}>
                        <Row style={{ paddingBottom: 40 }}>
                            <Col xs="9">
                                <Row style={{ textAlign: 'right' }}>
                                    <Col style={{ textAlign: 'left' }}>
                                        <p style={{ color: 'grey' }}>Selected Exam: {this.state.selectedPost.name} > {this.state.selectedPost.exams.find(e => e._id === this.state.exam).description}</p>
                                    </Col>
                                    <Col>
                                        <span style={{ margin: 10, color: 'grey' }}>{this.state.currentQuestionIndex + 1} of {this.state.questionsArr.length}</span>
                                        <FontAwesomeIcon className="iconButton" onClick={() => this.setState({ toggleImageModal: true })} style={{ color: '#007bff', margin: 10 }} icon={faImages} />
                                        <FontAwesomeIcon className="iconButton" onClick={() => this.setState({ toggleModal: true })} style={{ color: '#007bff', margin: 10 }} icon={faStickyNote} />
                                    </Col>
                                </Row>
                                <Row><Col>{this.renderQuestionForm()}</Col></Row>
                            </Col>
                            <Col xs="3">
                                <Button
                                    color="link"
                                    style={{ marginLeft: 20 }}
                                    onClick={() => this.predictData()}
                                >
                                    Predict Subject & Section
                                </Button>
                                <SelectField
                                    floatingLabelText="Subject"
                                    value={this.state.subjectId}
                                    onChange={(event, third, value) => this.handleMenuChange("subjectId", value)}
                                >
                                    {
                                        this.props.subjects ?
                                            this.props.subjects.map(subject => {
                                                return <MenuItem
                                                    key={subject._id}
                                                    value={subject._id}
                                                    primaryText={subject.name}
                                                    onClick={() => this.setState({ section: subject.sections })}
                                                />
                                            })
                                            :
                                            null
                                    }
                                </SelectField>

                                <SelectField
                                    floatingLabelText="Section"
                                    value={this.state.sectionId}
                                    onChange={(event, third, value) => this.handleMenuChange("sectionId", value)}
                                >
                                    {
                                        this.state.section ?
                                            this.state.section.map(section => {
                                                return <MenuItem
                                                    key={section._id}
                                                    value={section._id}
                                                    primaryText={section.name}
                                                />
                                            })
                                            :
                                            null
                                    }
                                </SelectField>
                                <SelectField
                                    floatingLabelText="Language"
                                    value={this.state.languageId}
                                    onChange={(event, third, value) => this.handleMenuChange("languageId", value)}
                                >
                                    {
                                        this.props.languages ?
                                            this.props.languages.map(languages => {
                                                return <MenuItem
                                                    key={languages.id}
                                                    value={languages.id}
                                                    primaryText={languages.name}
                                                />
                                            })
                                            :
                                            null
                                    }
                                </SelectField>
                            </Col>
                        </Row>
                    </Container>
                }

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        posts: state.data.posts,
        subjects: state.data.subjects,
        auth: state.auth,
        languages: state.data.languages
    }
}

export default connect(mapStateToProps, {})(ImageUpload)
