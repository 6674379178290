import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPen } from '@fortawesome/free-solid-svg-icons'
import { ListGroup, ListGroupItem, Button, Collapse, Card, CardBody } from 'reactstrap';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { copyToClipboard } from '../utils';
import { toast } from 'react-toastify'

class SimilarQuestions extends React.Component {
    state = {
        isOpen: true
    }

    render() {

        const { similarQuestions } = this.props

        if (Array.isArray(similarQuestions) && similarQuestions.length > 0) {
            return (
                <div style={{ paddingBottom: 10 }}>
                    <Card>
                        <CardBody>
                            <div style={{display: 'flex', justifyContent: "space-between"}}>
                                <p>{`Similar Questions (${similarQuestions.length})`}</p>
                                <Button
                                    size="sm"
                                    style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 5 }}
                                    color={this.state.isOpen ? "primary" : "secondary"}
                                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                >
                                    <FontAwesomeIcon icon={this.state.isOpen ? faArrowUp : faArrowDown} />
                                </Button>
                            </div>

                            <Collapse isOpen={this.state.isOpen} >
                                <ListGroup>
                                    {
                                        similarQuestions.map(question => {

                                            const correctAnswerObj = question.options.find(eachOption => eachOption.id === question.answer)

                                            return (
                                                <ListGroupItem
                                                    className="justify-content-between"
                                                    key={question._id}
                                                    style={{ backgroundColor: !question.activeFlag ? "rgba(255,0,0,0.3)" : null, display:'flex' }}
                                                >   
                                                    <div>
                                                        { question.type === 'html' ?
                                                            <div dangerouslySetInnerHTML={{ __html: question.question.split("rgb(255,255,255)").join("rgb(0,0,0)") }} />
                                                            :
                                                            question.question 
                                                        }
                                                        <div style={{ color:"grey", fontSize: 12 }}>
                                                            {correctAnswerObj ? correctAnswerObj.text : ''}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            outline
                                                            style={{ marginRight: 3 }}
                                                            onClick={() => {
                                                                copyToClipboard(question._id)
                                                                toast("ID copied", {
                                                                    position: toast.POSITION.TOP_CENTER,
                                                                    hideProgressBar: true
                                                                    })
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faCopy}
                                                                style={{ color: 'grey' }}
                                                            />
                                                        </Button>
                                                        <Button
                                                            outline
                                                            style={{ marginLeft: 3 }}
                                                        >
                                                            <Link
                                                                to={`/questions/edit?questionId=${question._id}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faPen}
                                                                    style={{  color: 'grey' }}
                                                                />
                                                            </Link>
                                                        </Button>
                                                    </div>
                                                </ListGroupItem>
                                            )
                                        })
                                    }
                                </ListGroup>
                            </Collapse>
                        </CardBody>
                    </Card>
                </div>
            )
        } else {
            return null
        }
    }
}

export default SimilarQuestions